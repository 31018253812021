import React from "react";
import VerticalUserSelector from "./VerticalUserSelector";
import { IAppointment } from "../../models/Appointment";
import User from "../../models/User";

interface LatriceDiscussionUsersProps {
  appointments: Partial<IAppointment[]>; // Adjust the type based on your data structure
  onSelect: (selectedUser: User | null) => void;
  currentUserIsProfessional: boolean;
}

export const LatriceDiscussionUsers: React.FC<LatriceDiscussionUsersProps> = ({
  appointments,
  onSelect,
  currentUserIsProfessional,
}) => {
  const filterUniqueUser = (users: User[]) => {
    const userMap = new Map();
    for (const user of users) {
      userMap.set(user._id, user);
    }
    return Array.from(userMap.values());
  };

  let users: User[] = [];

  if (currentUserIsProfessional) {
    users = appointments.map(({ user }: any) => user);
    users = filterUniqueUser(users);
  } else {
    users = appointments.map(({ professional }: any) => professional.user);
    users = filterUniqueUser(users);
  }
  console.log("current user is professional", currentUserIsProfessional);

  console.log("users implicate in the appointment", users);
  console.log("appointments data", appointments);

  return <VerticalUserSelector users={users} onSelect={onSelect} />;
};
