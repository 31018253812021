import React, { useState } from "react";
import Modal from "../modal/Modal";
import Button from "../Button";

interface SubscriptionType {
  show: boolean;
  submitBtnRef: React.ForwardedRef<HTMLButtonElement>;
  changeStatus?: () => void;
  onClose: () => void;
  suscribe: () => void;
  activateSuscription: boolean;
}

const SubscriptionModal = ({
  show,
  submitBtnRef,
  changeStatus,
  onClose,
  suscribe,
  activateSuscription,
}: SubscriptionType) => {
  return (
    <Modal onClose={onClose} open={show}>
      <div
        className="d-flex align-items-center justify-content-center p-4"
        style={{ flexDirection: "column" }}
      >
        <h2>Latrice Promotion</h2>
        <div>
          <p>
            You are about to subscribe to the promotions applied to the salon,
            please validate your choice
          </p>
        </div>
        <Button
          ref={submitBtnRef}
          onClick={suscribe}
          content={activateSuscription ? "unSubscribe" : "Subscribe"}
          className="btn-primary"
        />
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
