import { useState } from "react";

const TestPage = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarVisible, setCalendarVisible] = useState(false);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const getDaysInMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return new Date(year, month, 0).getDate();
  };

  const renderCalendar = () => {
    const daysInMonth = getDaysInMonth(selectedDate);
    const firstDayOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1
    ).getDay();

    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(
        <div
          key={i}
          className="calendar-day"
          onClick={() =>
            handleDateSelect(
              new Date(selectedDate.getFullYear(), selectedDate.getMonth(), i)
            )
          }
        >
          {i}
        </div>
      );
    }

    const blanks = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      blanks.push(<div key={-i} className="calendar-day blank"></div>);
    }

    //Calculate the number of remaining days in the week
    const lastDayOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      daysInMonth
    ).getDay();
    const remainingDays = 7 - lastDayOfMonth - 1;

    for (let i = 1; i <= remainingDays; i++) {
      days.push(
        <div
          key={`next-${i}`}
          className="calendar-day muted-day"
          onClick={() =>
            handleDateSelect(
              new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth() + 1,
                i
              )
            )
          }
        >
          {i}
        </div>
      );
    }

    const totalSlots = [...blanks, ...days];
    const rows: React.ReactNode[][] = [];
    let cells: React.ReactNode[] = [];

    totalSlots.forEach((day, i) => {
      if ((i % 7 !== 0 && i > 0) || i === 0) {
        cells.push(day);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(day);
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });

    const calendarDays = rows.map((d, i) => {
      return (
        <div key={i} className="calendar-row d-flex justify-content-between ">
          {d}
        </div>
      );
    });

    return (
      <div className="calendar-body">
        <div className="calendar-days d-flex">
          {daysOfWeek.map((day) => (
            <div key={day} className="calendar-day">
              <span className="fw-bold">{day}</span>
            </div>
          ))}
        </div>
        {calendarDays}
      </div>
    );
  };

  const handleDateSelect = (date: Date) => {
    setSelectedDate(date);
    setCalendarVisible(false);
  };

  const toggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  return (
    <div className="custom-calendar-dropdown mx-5 mt-5 ">
      <div className="selected-date position-relative" onClick={toggleCalendar}>
        <input
          className="w-100 rounded border bg-transparent p-2 cursor-pointer"
          type="text"
          value={selectedDate.toDateString()}
          readOnly
        />
        <span className="position-absolute translate-middle top-50 start-100 ">
          <i
            className={`fa-solid ${
              calendarVisible ? "fa-chevron-up" : "fa-chevron-down"
            }`}
          ></i>
        </span>
      </div>
      {calendarVisible && (
        <div className="calendar-dropdown mt-3 bg-white border rounded p-4">
          <div className="calendar-header d-flex justify-content-around">
            <i
              onClick={() =>
                setSelectedDate(
                  new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth() - 1,
                    1
                  )
                )
              }
              className="fa-solid fa-chevron-left"
            ></i>
            <span className="fw-bold">
              {selectedDate.toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}
            </span>
            <i
              onClick={() =>
                setSelectedDate(
                  new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth() + 1,
                    1
                  )
                )
              }
              className="fa-solid fa-chevron-right"
            ></i>
          </div>
          {renderCalendar()}
        </div>
      )}
    </div>
  );
};

export default TestPage;
