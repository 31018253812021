import axios from "axios";
import { IChair } from "../models/Chair";
import moment from "moment";

const getSalonChairs = (date: moment.Moment = moment()) => {
  return axios
    .get(
      `chairs/${localStorage.getItem("salonId")}?startDate=${date.format(
        "YYYY-MM-DD"
      )}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          "x-salon-id": `${localStorage.getItem("salonId")}`,
        },
      }
    )
    .then((data) => {
      return data;
    });
};

export const getChairs = (salonId: string) => {
  return axios
    .get(`chairs/${salonId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "x-salon-id": `${localStorage.getItem("salonId")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

export const addChair = (chair: Partial<IChair>) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}chairs`,
    method: "POST",
    data: chair,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

export const editChair = (chair: Partial<IChair>) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}chairs`,
    method: "PATCH",
    data: chair,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

export const deleteChair = (chair: Partial<IChair> , salonId:string) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}chairs/${chair._id}?salonId=${salonId}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

export const ChairService = {
  getChairs,
  addChair,
  editChair,
  deleteChair,
  getSalonChairs,
};
