import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import axios from "axios";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import BusinessProfilePage from "./pages/BusinessProfilePage";
import PersonnalInfoPage from "./pages/PersonnalInfoPage";
import BusinessInfoPage from "./pages/BusinessInfoPage";
import ServiceMenuPage from "./pages/ServiceMenuPage";
import RegisterProPage from "./pages/RegisterProPage";
import ClientProfilePage from "./pages/ClientProfilePage";
import PaymentPage from "./pages/PaymentPage";
import MessagePage from "./pages/MessagePage";
import LegalPage from "./pages/LegalPage";
import AppointmentPage from "./pages/AppointmentPage";
import TipSettingsPage from "./pages/TipSettingsPage.tsx";
import SeeProfessionalAccountPage from "./pages/SeeProfessionalAccountPage";
import PhotoPage from "./pages/PhotoPage";
import ManageAvailabilityPage from "./pages/ManageAvailabilityPage";
import PromotionPage from "./pages/PromotionPage";
import SearchPage from "./pages/SearchPage";
import ManageAppointmentPage from "./pages/ManageAppointmentPage";
import ServicePromotionPage from "./pages/ServicePromotionPage";
import TestPage from "./pages/Test";
import ErrorPage from "./pages/ErrorPage";
import LoginPageVerify from "./pages/LoginPageVerify";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AppointmentPageAdmin from "./pages/AppointmentPageAdmin";
import AuthRoute from "./components/auth/AuthRoute";
import { SupportPage } from "./pages/SupportPage";
import { ContactPage } from "./pages/ContactPage";
import EarningPage from "./pages/EarningPage";
import ClientPage from "./pages/ClientPage";
import MarketingPage from "./pages/MarketingPage";
import MoreSettingPage from "./pages/MoreSettingPage";
import AdminDashboard from "./pages/AdminDashboard";
import ManagerPage from "./pages/ManagerPage";
import CreateSalonPage from "./pages/CreateSalonPage";
import ChairManagementPage from "./pages/ChairManagementPage";
import ManageAvailabilityOldPage from "./pages/ManageAvailabilityPageOld";
import AuthLayout from "./components/auth/AuthLayout";
import HelpPage from "./pages/HelpPage";
import IndependentDashboardPage from "./pages/IndependentDashboardPage";
import App from "./App";
import ServicePromotionsPage from "./pages/ServicePromotionsPage";
import MemberPage from "./pages/MemberPage";
import HomePage from "./pages/HomePage";

const root = ReactDOM.createRoot(document.getElementById("root"));

// routes
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/login/verify",
      element: <LoginPageVerify />,
    },
    {
      path: "/register",
      element: <RegisterPage />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPasswordPage />,
    },
    {
      path: "/reset-password",
      element: <ResetPasswordPage />,
    },
    {
      path: "/business-profile",
      element: <BusinessProfilePage />,
    },
    {
      path: "/personnal-info",
      element: <PersonnalInfoPage />,
    },
    {
      path: "/business-info",
      element: <BusinessInfoPage />,
    },
    {
      path: "/service-menu",
      element: <ServiceMenuPage />,
    },
    {
      path: "/register-pro",
      element: <RegisterProPage />,
    },
    {
      path: "/client-profile",
      element: <ClientProfilePage />,
    },
    {
      path: "/payments",
      element: <PaymentPage />,
    },
    {
      path: "/message",
      element: <MessagePage />,
    },
    {
      path: "/legal",
      element: <LegalPage />,
    },
    {
      path: "/appointments",
      element: <AppointmentPage />,
    },
    {
      path: "/tip-settings",
      element: <TipSettingsPage />,
    },
    {
      path: "/see-professional-account/:accountId",
      element: <SeeProfessionalAccountPage />,
    },
    {
      path: "/photo",
      element: <PhotoPage />,
    },
    {
      path: "/manage-availability",
      element: <ManageAvailabilityPage />,
    },
    {
      path: "/manage-availability-old",
      element: <ManageAvailabilityOldPage />,
    },
    {
      path: "/promotions",
      element: <PromotionPage />,
    },
    {
      path: "/search",
      element: <SearchPage />,
    },
    {
      path: "/manage-appointment",
      element: <ManageAppointmentPage />,
    },
    {
      path: "/admin/manage-appointment",
      element: <AppointmentPageAdmin />,
    },
    {
      path: "/admin/dashboard",
      element: <AdminDashboard />,
    },
    {
      path: "/independent/dashboard",
      element: <IndependentDashboardPage />,
    },
    {
      path: "/admin/managers",
      element: <ManagerPage />,
    },
    {
      path: "/service-promotion",
      element: <ServicePromotionPage />,
    },
    {
      path: "service-promotions",
      element: <ServicePromotionsPage />,
    },
    {
      path: "/support",
      element: <SupportPage />,
    },
    {
      path: "/contact-us",
      element: <ContactPage />,
    },
    {
      path: "/earnings",
      element: <EarningPage />,
    },
    {
      path: "/clients",
      element: <ClientPage />,
    },
    {
      path: "/marketing",
      element: <MarketingPage />,
    },
    {
      path: "/more-setting",
      element: <MoreSettingPage/>
    },
    {
      path: "/help",
      element: <HelpPage />,
    },
    {
      path: "/setup-business",
      element: <CreateSalonPage />,
    },
    {
      path: "/setup-business/manage-chairs/:salonId",
      element: <ChairManagementPage />,
    },
    {
      path: "/client-profile/member/:memberId",
      element: <MemberPage />,
    },

    // Route for 404 page not found
    {
      path: "*",
      element: <ErrorPage />,
    },

    // created a test route to output some components
    {
      path: "/test-route",
      element: <TestPage />,
    },
  ].map((route) => ({
    ...route,
    element: <AuthRoute>{route.element}</AuthRoute>,
  }))
);

// default axios configs
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check if the error response matches the specified format
    if (
      error?.response?.data?.errors &&
      error.response.data.errors.code === "REQUIRE_2FA_OTP_VERIFICATION"
    ) {
      // Access the request data
      const requestData = JSON.parse(error.config.data);
      localStorage.setItem("email", requestData.email);
      localStorage.setItem("password", requestData.password);

      // Perform your desired action, such as redirecting to a specific page
      // or displaying an error message
      const queryParams = new URLSearchParams(window.location.search);
      const ref = queryParams.get("ref");
      if (ref) {
        window.location.href = `/login/verify?ref=${ref}`;
      } else {
        window.location.href = "/login/verify";
      }
    }

    return Promise.reject(error);
  }
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router}>
        <AuthLayout>
          <AuthRoute>
            <App />
          </AuthRoute>
        </AuthLayout>
      </RouterProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
