import { useSelector } from "react-redux";
import { ServiceState } from "../redux/slices/serviceSlice";
import toast from "react-hot-toast";

export const useErrors = () => {
  const errors = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).errors;

  return errors;
};

//notify is a toast that is sent to the front whenever needed, to output an error message
export const errorToast = (message: string) => {
  return toast.error(message);
};

//sucessMessage is a toast that is sent to the front whenever needed, to output a success message
export const sucessToast = (message: string) => {
  return toast.success(message);
};
