import React, { LegacyRef, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "./../styles/MakeReviewModal.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { log } from "../../utils/utils";
import { ReviewService } from "../../services/ReviewService";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import { AddUserReviews } from "../../redux/slices/reviewSlice";
import { IAppointment } from "../../models/Appointment";
import {   salonReviewDataBad, salonReviewDataGood, userReviewDataBad, userReviewDataGood } from "../../utils/data";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUser } from "../../hooks/UseUser";
import socket from "../../socket/socket";
import { EVENT_CODE } from "../../socket/event";

type MakeReviewModal = {
  appointment?: IAppointment;
  show: boolean;
  onClose: () => void;
  handleMakeReviewFunction: (data: string) => void;
};

const MakeReviewModal: React.FC<MakeReviewModal> = ({
  appointment,
  show,
  onClose,
  handleMakeReviewFunction,
}) => {
  const dispatch = useDispatch();
  const submitBtnRef = useRef<HTMLButtonElement>();
  const user = useUser();

  const sameUserMakeAppointment = user?._id === appointment?.user;

  const formik = useFormik({
    initialValues: {
      rating: 0,
      comment: "",
    },

    validationSchema: Yup.object({
      rating: Yup.number().required("Rating is required"),
      comment: Yup.string().required("Comment is required"),
    }),
    onSubmit: (values) => {
      log(JSON.stringify(values, null, 2));
    },
  });

  const formSubmit = (e: any) => {
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0) {
      handleSubmit(e);
    }
  };

  const [rating, setRating] = useState<number>(0);

  const [reviewIssue, setReviewIssue] = useState<string[]>([]);

  const handleClose = () => {
    onClose();
  };

  const handleRatingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRating(Number(e.target.value));
    console.log("rating", e.target.value);
    const rating = Number(e.target.value);
    formik.setFieldValue("rating", rating);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = "Sending...";

      console.log("test work");
      console.log("formik data", formik.values);

      const review = {
        ...formik.values,
        appointment: appointment,
        reviewIssue: reviewIssue,
      };

      // handleSocketReview(review)

      dispatch(AddUserReviews(review) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log(res);
          toast.success("Thank you for your review");
          // console.log("The res" , res)
          // setRating(res.ratin)



          handleClose();
        })
        .catch((err: any) => {
          toast.error("Something went wrong, please try again");
        })
        .finally(() => {});
    }
  };

  const toggleReview = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    console.log("checked", checked);

    setReviewIssue((reviewIssue) => {
      if (checked) {
        const updateReviewIssue: string[] = [...reviewIssue, value];
        console.log("here is the value of reviewIssue", updateReviewIssue);

        return updateReviewIssue;
      } else {
        const updateReviewIssue: string[] = reviewIssue.filter(
          (item) => item !== value
        );
        console.log("here is the value of reviewIssue", updateReviewIssue);

        return updateReviewIssue;
      }
    });
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      centered
      dialogClassName="modal-90w"
      onHide={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-1 p-3 justify-content-center align-items-center">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              <img
                src={require("../../assets/images/review.png")}
                alt=""
                style={{ width: "100px" }}
              />
            </div>
            <div className="d-flex flex-column gap-2 p-3 justify-content-center align-items-center">
              <h4 className="fw-500  " style={{ color: "#0f2359" }}>
                Add a comment
              </h4>
              <p className=" text-center " style={{ color: "" }}>
                You have just completed your service, please rate it and leave a
                comment on the qu
              </p>
            </div>
            <div className="comment-box">
              <div className="rating">
                <input
                  onChange={handleRatingChange}
                  type="radio"
                  name="rating"
                  value="5"
                  id="5"
                />
                <label htmlFor="5">☆</label>{" "}
                <input
                  onChange={handleRatingChange}
                  type="radio"
                  name="rating"
                  value="4"
                  id="4"
                />
                <label htmlFor="4">☆</label>{" "}
                <input
                  onChange={handleRatingChange}
                  type="radio"
                  name="rating"
                  value="3"
                  id="3"
                />
                <label htmlFor="3">☆</label>{" "}
                <input
                  onChange={handleRatingChange}
                  type="radio"
                  name="rating"
                  value="2"
                  id="2"
                />
                <label htmlFor="2">☆</label>{" "}
                <input
                  onChange={handleRatingChange}
                  type="radio"
                  name="rating"
                  value="1"
                  id="1"
                />
                <label htmlFor="1">☆</label>
              </div>
              {formik.touched.rating && formik.errors.rating ? (
                <div style={{ color: "var(--primaryColor)" }}>
                  {formik.errors.rating}
                </div>
              ) : null}
              <div className="button-system">
                <h1 className="text-center fw-300 fs-5 fw-normal lh-base">
                  What could have been better ?
                </h1>
                <div className="  review-button-container">
                  {(sameUserMakeAppointment ? (rating<3 ? userReviewDataBad:userReviewDataGood) : (rating<3?salonReviewDataBad:salonReviewDataGood)).map(
                    (data, idx) => (
                      <label key={data.id}>
                        <div
                          className={`  review-button ${
                            reviewIssue.includes(data.title)
                              ? "review-button-checked"
                              : ""
                          } `}
                        >
                          {/* <FontAwesomeIcon
                          icon={faCheck}
                          className={`mr-2 ${true ? "text-success" : ""}`}
                        /> */}
                          <span>{data.title}</span>
                          <input
                            type="checkbox"
                            name="size"
                            value={data.title}
                            className="hidden-radio"
                            onChange={toggleReview}
                            // checked={selectedSizes.includes(size.value)}
                          />
                        </div>
                      </label>
                    )
                  )}
                </div>
              </div>
              <div className="comment-area" style={{ marginTop: "10px" }}>
                <textarea
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.comment ?? ""}
                  id="comment"
                  name="comment"
                  required
                  className="form-control"
                  placeholder="what is your view?"
                  rows={4}
                ></textarea>
                {formik.touched.comment && formik.errors.comment ? (
                  <div style={{ color: "var(--primaryColor)" }}>
                    {formik.errors.comment}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="d-flex flex-row gap-3 p-3 justify-content-center  align-items-center "
            style={{ width: "100%" }}
          >
            <button
              ref={submitBtnRef as LegacyRef<HTMLButtonElement>}
              style={{
                padding: "10px",
                paddingLeft: "30px",
                paddingRight: "30px",
                borderRadius: "5px",
                border: "none",
                color: "red",
                backgroundColor: "#ffe9e8",
              }}
              type="submit"
            >
              Send message
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default MakeReviewModal;
