import axios from "axios";
import User, { IUserRegistration, IUserRegistrationPro } from "../models/User";
import { ISalon } from "../models/SalonX";
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

let referralCode = localStorage.getItem("referralCode");

const referralCodeQuery = referralCode ? `?referralCode=${referralCode}` : "";

const registerUrl = `${process.env.REACT_APP_API_BASE_URL}auth/register/${referralCodeQuery}`;

const getCurrentUser = (token: string) => {
  return axios
    .get(`users`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      console.log("the current user", data);
      return data;
    });
};

const register = (user: IUserRegistration) => {
  console.log("referralCode", referralCode);

  return axios({
    url: registerUrl,
    method: "post",
    data: {
      ...user,
    },
  }).then((data) => {
    console.log("data", data);
    return data;
  });
};

const registerPro = (user: IUserRegistrationPro) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}auth/register-pro`,
    method: "post",
    data: {
      ...user,
    },
  }).then((data) => {
    console.log("data", data);
    return data;
  });
};

const login = (user: Partial<User>) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}auth/login`,
    method: "POST",
    data: {
      ...user,
    },
  }).then((data) => {
    return data;
  });
};

const logout = () => {
  return axios.get(`auth/logout`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });
};

const resendOtpCode = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}auth/resend-otp-code`,
    method: "POST",
    data: { email, password },
    // headers: {
    //   "Authorization": `Bearer ${localStorage.getItem("authToken")}`
    // }
  }).then((data) => {
    return data;
  });
};

const verifyOtpCode = ({
  email,
  password,
  otp,
}: {
  email: string;
  password: string;
  otp: string | number;
}) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}auth/verify-otp`,
    method: "POST",
    data: { email, password, otp },
    // headers: {
    //   'Authorization': `Bearer ${localStorage.getItem("authToken")}`
    // }
  }).then((data) => {
    return data;
  });
};

const update = (user: FormData) => {
  return axios({
    url: `users`,
    method: "PATCH",
    data: user,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    console.log("dataaa", data);

    return data;
  });
};

const updateSalon = (salon: FormData) => {
  console.log("data salon", salon);
  return axios({
    url: `salons`,
    method: "PATCH",
    data: salon,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    console.log("data", data);
    return data;
  });
};
const adminUpdateSalon = (salon: Partial<ISalon>) => {
  console.log("salon data that i wanna send", salon);
  return axios({
    url: `salons/admin`,
    method: "PATCH",
    data: salon,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => data);
};

const changePassword = (data: Partial<User>) => {
  return axios({
    url: `users/change-password`,
    method: "PATCH",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => data);
};

const passwordReset = (data: Partial<User>) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}auth/password-reset-request`,
    method: "post",
    data: data,
  }).then((data) => {
    return data;
  });
};

const verifyPasswordToken = (password: string) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}auth/verify-password-reset-token`,
    method: "post",
    data: password,
  }).then((data) => {
    return data;
  });
};

const deletePortfolioImage = (portfolioKey: number) => {
  return axios({
    url: `salons/delete-portfolio/${portfolioKey}`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => data);
};

const getUserBookmarks = () => {
  return axios({
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

const getReferralLink = () => {
  return axios({
    url: `user/referral-link`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => data);
};

const deleteBookmark = (elementKey: any) => {
  return axios({
    url: `salons/delete-bookmark`,
    method: "PATCH",
    data: elementKey,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => data);
};

export const AuthService = {
  getCurrentUser,
  register,
  logout,
  resendOtpCode,
  verifyOtpCode,
  login,
  update,
  changePassword,
  passwordReset,
  verifyPasswordToken,
  registerPro,
  deletePortfolioImage,
  deleteBookmark,
  updateSalon,
  adminUpdateSalon,
  getUserBookmarks,
  getReferralLink,
};
