import {
  RatingChangedEventArgs,
  RatingComponent,
} from "@syncfusion/ej2-react-inputs";
import React, { useEffect, useState } from "react";
import "./../styles/Profile.css";
import Star from "./Star";
import { useDispatch, useSelector } from "react-redux";
import { AddUserReviews } from "../redux/slices/reviewSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { useSalon } from "../context/SalonContext";
import { AuthState } from "../redux/slices/authSlice";
import toast from "react-hot-toast";

export interface StarsOptions {
  stars: number;
  canUpdate:boolean;
  salonId?: string;
  notice: number;
}

const Stars: React.FC<StarsOptions> = ({ stars,canUpdate, salonId, notice }) => {
  const dispatch = useDispatch();
  const [rating, setRating] = useState<number | null>(null);
  const [hover, setHover] = useState<number | null>(null);
  const [totalStars, setTotalStars] = useState(5);

  const currentUser = useSelector<unknown, AuthState>(
    (state: any) => state.auth
  ).currentUser;

  const valueChanged = (e: any) => {
    // alert("Previous Value:" + args.previousValue + "\nValue:" + args.value);
    setRating(e.target.value);
    console.log("rating", e.target.value);
    const review = {
      rating: e.target.value as number,
      salonId: salonId,
    };

    dispatch(AddUserReviews(review) as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        console.log(res);
        toast.success("Thank you for your review");
        // console.log("The res" , res)
        // setRating(res.ratin)
      })
      .catch((err: any) => {
        toast.error("Something went wrong, please try again");
      })
      .finally(() => {});
  };

  // useEffect(() => {
  //   setRating(localStorage.getItem("rating") as any);
  // }, [rating]);

  return (
    <div className="d-flex align-items-center flex-wrap">
      <div className="starsContainer d-flex align-items-center">
        {/* <Star fill={stars} /> */}
        {/* <RatingComponent
          id="rating"
          valueChanged={valueChanged}
        ></RatingComponent> */}
        <>
          {[...Array(totalStars)].map((star, index) => {
            const currentRating = index + 1;

            return (
              <label key={index}>
                <input
                  className="inputRation"
                  type="radio"
                  name="rating"
                  value={currentRating}
                  onChange={canUpdate ? valueChanged:undefined}
                />
                <span
                  className="star"
                  style={{
                    color: canUpdate ? (
                      currentRating  <= (hover || rating || stars)!! && canUpdate
                        ? "#ffc107"
                        : "#e4e5e9") : currentRating <= stars ? "#ffc107" : "#e4e5e9",
                  }}
                  onMouseEnter={() => setHover(currentRating)}
                  onMouseLeave={() => setHover(null)}
                >
                  &#9733;
                </span>
              </label>
            );
          })}
        </>
      </div>
      <div className="ms-2">
        <span className="fw-bold">{stars}</span>
        <span className="text-muted small ms-1">({notice})</span>
      </div>
    </div>
  );
};

export { Stars };
