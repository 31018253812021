import MainNav from "../components/navigation/MainNav";
import "./../styles/LoginPage.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LegacyRef, useRef, useState } from "react";
import { useFormik } from "formik";
import {
  initialValues,
  validationSchema,
} from "../components/validation/LoginValidation";
import { log } from "../utils/utils";
import ImageSlider from "../components/reusable/imageSlider/ImageSlider";
import { AuthService } from "../services/AuthService";
import { slides } from "../components/reusable/imageSlider/Slides";
import DropDown from "../components/reusable/DropDown";
import { FaUserTie, FaUser } from 'react-icons/fa';


const LoginPage = () => {
  const submitBtnRef = useRef<HTMLButtonElement>();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      log(JSON.stringify(values, null, 2));
    },
  });

  const formSubmit = (e: any) => {
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0) {
      handleSubmit(e);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get("ref");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = "signin...";

      AuthService.login(formik.values)
        .then((response) => {
          log(response);
          if (response?.headers?.authorization) {
            const authHeader = response.headers.authorization as string;
            const authHeaderToken = authHeader.replace("Bearer ", "");
            localStorage.setItem("authToken", authHeaderToken);
            if (ref) {
              window.location.href = ref;
              return;
            }
            if (response.data?.account_type) {
              window.location.href = '/business-profile';
            } else {
              window.location.href = '/client-profile';
            }
          }
        })
        .catch((err) => {
          log(err);
          if (err?.response?.data?.errors) {
            const errors = err.response.data.errors;
            formik.setErrors(errors);
            if (errors.code && errors.code === "REQUIRE_2FA_OTP_VERIFICATION") {
            }
          }
        })
        .finally(() => {
          if (submitBtnRef.current) {
            submitBtnRef.current.removeAttribute("disabled");
            submitBtnRef.current.innerHTML = "Sign in";
          }
        });
    }
  };

  return (
    <>
      <MainNav navType={"homeNav"} />
      <div className="d-flex p-0 m-0 Login mx-0">
        <div className="position-relative loginLeftSide">
          <ImageSlider slides={slides} />
        </div>
        <div className="mx-auto p-0 m-0 col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 loginRightSide align-items-start align-items-lg-center mt-5 mt-lg-0">
          <div className="container p-lg-0 my-3">
            <div className="d-flex flex-column justify-content-around">
              <div className="d-flex justify-content-end">
                <div className="d-flex gap-1">
                  <span style={{ whiteSpace: 'nowrap' }}>New user?</span>{" "}
                  <DropDown
                    title={<span className="nav-link nav-link-inline nav-link-underline-hover active">Create an account </span>}
                    items={[
                      <NavLink
                        style={{ whiteSpace: 'nowrap' }}
                        className="nav-link nav-link-inline"
                        to="/register-pro"
                      >
                        <FaUserTie className="mr-2 mb-1" /> Professional account
                      </NavLink>,
                      <NavLink
                        style={{ whiteSpace: 'nowrap' }}
                        className="nav-link nav-link-inline"
                        to="/register"
                      >
                        <FaUser className="mr-2 mb-1" /> Client account
                      </NavLink>,
                    ]}
                    closeOnClickOutside
                  />

                </div>
              </div>
              <div className="row d-flex align-items-center mx-0 mx-lg-5">
                <div className="col-12 col-lg-10 col-xl-6">
                  <h6 className="mt-3">Sign in to Latrice</h6>
                  <div className="my-3">
                    <i
                      title="sign in with Google"
                      className="fa-brands fa-google"
                    ></i>
                    <i
                      title="sign in with Facebook"
                      className="fa-brands fa-facebook mx-2"
                    ></i>
                  </div>
                  <div className="separationBar my-3">
                    <div>
                      <span>or</span>
                    </div>
                    <hr />
                  </div>
                  <div>
                    {(formik.errors.message && !formik.errors.message.includes('2FA')) ? (
                      <div className="alert alert-danger d-flex gap-2 align-items-center">
                        <i className="fa fa-info-circle"></i>
                        <div>{formik.errors.message}</div>
                      </div>
                    ) : null}
                    <form onSubmit={handleSubmit}>
                      <input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email ?? ""}
                        required
                        type="email"
                        className="form-control fw-500"
                        id="email"
                        placeholder="email"
                        name="email"
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div style={{ color: "var(--primaryColor)" }}>
                          {formik.errors.email}
                        </div>
                      ) : null}
                      <div
                        className="input-group my-4"
                        aria-autocomplete="none"
                      >
                        <input
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password ?? ""}
                          required
                          type={passwordVisible ? "text" : "password"}
                          className="form-control fw-500"
                          id="password"
                          name="password"
                          placeholder="password"
                        />
                        <button
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          className="btn"
                          type="button"
                          id="togglePasswordVisibility"
                        >
                          <i
                            className={
                              passwordVisible ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          ></i>
                        </button>
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div style={{ color: "var(--primaryColor)" }}>
                          {formik.errors.password}
                        </div>
                      ) : null}
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <NavLink className="nav-link" to={"/forgot-password"}>
                            Forgot password?
                          </NavLink>
                        </div>
                        <div>
                          <button
                            ref={submitBtnRef as LegacyRef<HTMLButtonElement>}
                            className="btn btn-primary px-4"
                            type="submit"
                          >
                            Sign in
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="small mt-5">
                      Protected by reCAPTCHA and subject to the{" "}
                      <NavLink
                        className="nav-link nav-link-underline-hover active nav-link-inline"
                        to={"/forgot-password"}
                      >
                        Latrice privacy policy{" "}
                      </NavLink>
                      and{" "}
                      <NavLink
                        className="nav-link nav-link-inline nav-link-underline-hover active"
                        to={"/forgot-password"}
                      >
                        Terms of service.
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
