import { useEffect, useState } from "react";
import SalonContainer from "./SalonContainer";
import { useUser } from "../../hooks/UseUser";
import { isUserProfessional } from "../../models/User";
import { useDispatch, useSelector } from "react-redux";
import { getManagers, selectManagers } from "../../redux/slices/managerSlice";
import { AnyAction } from "@reduxjs/toolkit";
import PromotionNewComerSetting from "./PromotionNewComerSetting";
import PromotionSalonSetting from "./PromotionSalonSetting";
import PromotionReferralLinkSetting from "./PromotionReferralLinkSetting";

type Props = {};

const MoreSettingDashboard = (props: Props) => {
  const [showManagerModal, setShowManagerModal] = useState(false);
  const user = useUser();
  const UserManagers = useSelector(selectManagers).managers;
  const dispatch = useDispatch();

  return (
    <div>
      <div className="d-flex gap-2 align-items-center  mt-4 ">
        <img
          src={require("../../assets/images/hair.png")}
          className="img"
          style={{ height: "25px" }}
          alt=""
        />
        <span className="text-black fs-5 fw-bold">Your Admin dashboard</span>
      </div>
      <div className=" fs-6" style={{ marginTop: "10px" }}>
        Latrice welcome to your administration <br /> area! here you can manage
        the application's global configuration, such as activating or
        deactivating a . promotion.
      </div>
      <div
        className="my-2 "
        style={{
          display: "flex",
          justifyContent: "flex-start",

          alignItems: "flex-start",
          // flexWrap: "wrap",
          gap: "10px",
        }}
      >
        <PromotionSalonSetting />
        <PromotionNewComerSetting />
        <PromotionReferralLinkSetting />
      </div>
    </div>
  );
};

export default MoreSettingDashboard;
