import React from "react";
import { v4 as uuidv4 } from "uuid";

type Props = {};

const ChairLoader = (props: Props) => {
  const ChairElementLoader = () => {
    return (
      <div
        style={{ gridTemplateColumns: "1fr 1fr 1fr", gap: "10px" }}
        className="d-grid"
      >
        {Array(3)
          .fill(0)
          .map((_, key) => {
            return (
              <div key={uuidv4()} className="working-table-element__item p-3">
                <div
                  style={{ width: "100%", height: "50px" }}
                  className="shimmer shimmer__square"
                ></div>
              </div>
            );
          })}
      </div>
    );
  };
  return (
    <div className={`working-table w-100`}>
      <div className="working-table__header"></div>
      <div className="working-table__body mt-5">
        {Array(4)
          .fill(0)
          .map((_, key) => (
            <ChairElementLoader key={uuidv4()} />
          ))}
      </div>
    </div>
  );
};

export default ChairLoader;
