import Modal from "react-bootstrap/Modal";
import "../styles/ImageEditorModal.css";
import { ChangeEvent, useState } from "react";
import { toast } from "react-hot-toast";
import "react-image-crop/dist/ReactCrop.css";
import { IUserRegistrationPro } from "../../models/User";
import { useDispatch } from "react-redux";
import { AddManager } from "../../redux/slices/managerSlice";
import { AnyAction } from "redux";
import { useFormik } from "formik";
import {
  managerInitialValues,
  managerValidationSchema,
} from "../validation/SalonValidation";
import { ModalLoader } from "./commons/ModalLoader";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export interface AddManagerModalProps {
  show: boolean;
  onClose: () => void;
  onCreate: (formData: FormData) => void;
}

const AddManagerModal: React.FC<AddManagerModalProps> = ({
  show = false,
  onClose,
  onCreate,
}: AddManagerModalProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmPasswordVisible, setconfirmPasswordVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(true);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: managerInitialValues,
    validationSchema: managerValidationSchema,
    onSubmit: (values) => {},
  });
  const handleClose = () => {
    formik.setValues(managerInitialValues);
    onClose();
  };

  const handleLoading = (state: boolean) => {
    setLoading(state);
  };
  const handleSave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (loading) return;
    handleLoading(true);
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0 && formik.dirty) {
      const managerValues: IUserRegistrationPro = {
        first_name: formik.values.first_name,
        last_name: formik.values.last_name,
        email: formik.values.email,
        password: formik.values.password,
        phone: formik.values.phone,
        account_type: "independent",
        pro: true,
      };
      dispatch(AddManager(managerValues) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          toast.success("Manager created succesfully");
          handleClose();
        })
        .catch((err: any) => {
          const errors = err.errors;
          if (err.status === 422) {
            const errorMessages = Object.values(errors).map((error: any) => (
              <div>* {error}</div>
            ));

            return toast.error(<div>{errorMessages}</div>, {
              duration: 7000,
            });
          }
        })
        .finally((res: any) => {
          handleLoading(false);
        });

      return;
    }
    toast.error("You need to fill all the required fields");
    handleLoading(false);
  };

  const handleChangePhoneNumber = (
    value: string,
    data: CountryData,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    // const input = event.target.value;
    console.log("input number", value);
    setPhoneNumber(value);
    formik.setFieldValue("phone", value);
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={handleClose}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <div className="text-black fw-500 fs-5">Add a new manager</div>
      </Modal.Header>
      {loading && <ModalLoader />}
      <Modal.Body className="border-top border-bottom">
        <div className="w-100 d-flex px-3 py-4 align-items-center flex-column justify-content-center gap-2">
          <div className="salon-grid justify-content-between w-100">
            <div className="d-flex p-0 flex-column salon-input gap-1 ">
              <span className="text-primary">
                First name *<i className="ms-2 fa-solid fa-user text-muted"></i>
              </span>
              <input
                type="text"
                className="bg-white border rounded"
                placeholder="first name"
                id="first_name"
                name="first_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                required
              />
              {formik.touched.first_name && formik.errors.first_name ? (
                <div style={{ color: "var(--primaryColor)" }}>
                  {formik.errors.first_name}
                </div>
              ) : null}
            </div>
            <div className="d-flex p-0 flex-column salon-input gap-1 ">
              <span className="text-primary">
                Last name * <i className="ms-2 fa-solid fa-user text-muted"></i>
              </span>
              <input
                type="text"
                className="bg-white border rounded"
                placeholder="last name"
                id="last_name"
                name="last_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
              />
              {formik.touched.last_name && formik.errors.last_name ? (
                <div style={{ color: "var(--primaryColor)" }}>
                  {formik.errors.last_name}
                </div>
              ) : null}
            </div>
          </div>
          <div className="w-100">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
          <div
            className="d-flex flex-column gap-1 w-100 salon-input"
            aria-autocomplete="none"
          >
            <span className="text-primary">
              Phone number *{" "}
              <i className="ms-2 fa-solid fa-phone text-muted"></i>
            </span>
            <PhoneInput
              country="us"
              autoFormat
              onlyCountries={["us"]}
              value={phoneNumber}
              onChange={handleChangePhoneNumber}
              inputProps={{
                required: true,
              }}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div style={{ color: "var(--primaryColor)" }}>
                {formik.errors.phone}
              </div>
            ) : null}
          </div>
          <div
            className="d-flex flex-column gap-1 w-100 salon-input"
            aria-autocomplete="none"
          >
            <span className="text-primary">
              Email *<i className="ms-2 text-muted fa-solid fa-envelope"></i>
            </span>

            <input
              aria-autocomplete="none"
              className="border rounded bg-white"
              type="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              required
              id="email"
              placeholder="@manager's email"
            />
            {formik.touched.email && formik.errors.email ? (
              <div style={{ color: "var(--primaryColor)" }}>
                {formik.errors.email}
              </div>
            ) : null}
          </div>
          <div
            className="d-flex flex-column gap-1 w-100 salon-input"
            aria-autocomplete="none"
          >
            <span className="text-primary">
              password *<i className="ms-2 text-muted fa-solid fa-lock"></i>
            </span>
            <div className="input-group" aria-autocomplete="none">
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                required
                type={passwordVisible ? "text" : "password"}
                className="form-control fw-500"
                id="password"
                placeholder="password*"
                name="password"
              />
              <button
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="btn"
                type="button"
                id="togglePasswordVisibility"
              >
                <i
                  className={passwordVisible ? "fa fa-eye-slash" : "fa fa-eye"}
                ></i>
              </button>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div style={{ color: "var(--primaryColor)" }}>
                {formik.errors.password}
              </div>
            ) : null}
          </div>
          <div
            className="d-flex flex-column gap-1 w-100 salon-input"
            aria-autocomplete="none"
          >
            <span className="text-primary">
              Confirm password *
              <i className="ms-2 text-muted fa-solid fa-lock"></i>
            </span>
            <div className="input-group" aria-autocomplete="none">
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                required
                type={confirmPasswordVisible ? "text" : "password"}
                className="form-control fw-500"
                id="confpassword"
                placeholder="confirm password*"
                name="confirmPassword"
              />
              <button
                onClick={() =>
                  setconfirmPasswordVisible(!confirmPasswordVisible)
                }
                className="btn"
                type="button"
                id="togglePasswordVisibility"
              >
                <i
                  className={
                    confirmPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"
                  }
                ></i>
              </button>
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div style={{ color: "var(--primaryColor)" }}>
                {formik.errors.confirmPassword}
              </div>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <div className="mx-3 my-1 d-flex justify-content-end align-items-center gap-3">
          <button onClick={onClose} className="working-table-outline-button">
            Cancel
          </button>
          <button onClick={handleSave} className="working-table-primary-button">
            Add
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddManagerModal;
