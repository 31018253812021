import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Discussion, Message, UserDiscussion } from "../../models/Discussion";
import { DiscussionService } from "../../services/DiscussionService";
import { editCustomAppointment, editCustomAppointmentSocket } from "./customAppointmentSlice";



type DiscussionType = {
  user: UserDiscussion[] | null,
  system: {
    customAppointments: Discussion[] | null,
    [key: string]: any
  }
} | null;

export interface DiscussionState {
  discussions: DiscussionType;
}

const initialState: DiscussionState = {
  discussions: null,
};

export const getAvailableDiscussions = createAsyncThunk<DiscussionType>(
  "discussions/list",
  async () => {
    const res = await DiscussionService.getAvailableDiscussion();
    return res;
  }
);

export const getDiscussionsMessages = createAsyncThunk<Message[]>(
  "discussions/get-messages",
  async () => {
    const res = await DiscussionService.getDiscussionMessages();
    return res;
  }
);

export const addDiscussionsMessage = createAsyncThunk<Message, Message>(
  "discussions/add-message",
  async (message: Message) => {
    const res = message;
    return res;
  }
);

const reviewsSlice = createSlice({
  name: "discussions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAvailableDiscussions.fulfilled, (state, action) => {
      state.discussions = action.payload;
    }).addCase(editCustomAppointment.fulfilled, (state, action) => {
      if (state.discussions?.system?.customAppointments) {

        const discussions = state.discussions;
        let customAppointments = state.discussions.system.customAppointments.slice();
        const data = action.payload as any;

        customAppointments = customAppointments.map((appointment) => {
          if (appointment._id === data._id) return data;
          return appointment;
        });

        if (discussions?.system?.customAppointments) {
          discussions.system.customAppointments = customAppointments;
        }

        state.discussions = discussions;

      }
    }).addCase(editCustomAppointmentSocket.fulfilled, (state, action) => {
      if (state.discussions?.system?.customAppointments) {

        const discussions = state.discussions;
        let customAppointments = state.discussions.system.customAppointments.slice();
        const data = action.payload as any;

        customAppointments = customAppointments.map((appointment) => {
          if (appointment._id === data._id) return data;
          return appointment;
        });

        if (discussions?.system?.customAppointments) {
          discussions.system.customAppointments = customAppointments;
        }

        state.discussions = discussions;

      }
    }).addCase(getDiscussionsMessages.fulfilled, (state, action) => {
      let userDiscussions = ((state.discussions?.user ?? []) as UserDiscussion[]).slice();
      const messages = action.payload;
      if (messages.length > 0) {
        const { sender, receiver } = messages[0];
        console.log({ sender, receiver });
        console.log(userDiscussions);
        userDiscussions = userDiscussions.map((discussion) => {
          let youId = discussion.you._id;
          let withId = discussion.with._id;

          console.log({ youId, withId })
          if (
            (sender === youId && receiver === withId) || (sender === withId && receiver === youId)
          ) {
            return { ...discussion, messages }
          }
          return discussion;
        })
        if (state.discussions?.user)
          state.discussions.user = userDiscussions;

      }

      // const hasSameId = action.payload?.
    }).addCase(addDiscussionsMessage.fulfilled, (state, action) => {
      let userDiscussions = ((state.discussions?.user ?? []) as UserDiscussion[]).slice();
      const message = action.payload;
      const { sender, receiver } = message;
      userDiscussions = userDiscussions.map((discussion) => {
        if (discussion.with._id === sender || discussion.with._id === receiver) {
          let messages = [...(discussion.messages ?? [])];

          const hasMessage = messages.find((m) => message._id === m._id) !== undefined;

          messages = messages.map((m) => m._id === message._id ? message : m)

          if (!hasMessage) messages.push(message)

          return { ...discussion, messages }
        }
        return discussion;
      })
      if (state.discussions)
        state.discussions.user = userDiscussions
    })
  },
});

export default reviewsSlice.reducer;
