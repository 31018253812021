import { useState, useEffect, useRef } from "react";
import OtpInput from "react18-input-otp";
import { useFormik } from "formik";

import {
  initialValues,
  validationSchema,
} from "../../validation/RegisterValidation";
import { AuthService } from "../../../services/AuthService";

interface RegisterProStep0Props {
  onNextStep: () => void;
  onPrevStep?:()=>void;

}

const RegisterProStep1 = ({
  onNextStep,onPrevStep
}: RegisterProStep0Props): JSX.Element => {
  const confirmBtnRef = useRef<HTMLButtonElement>(null);
  const [otp, setOtp] = useState("");
  const [otpTimer, setOtpTimer] = useState(0);
  const [onSendBackOTP, setOnSendBackOTP] = useState(false);

  const formik = useFormik({
    initialValues, validationSchema, onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });

  // Function to start the timer
  const startTimer = () => {
    const OTP_TIMEOUT = 60; // Timeout in seconds
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const lastSendBackOTPDate = localStorage.getItem("lastSendBackOTPDate");
    const timeDifference = currentTime - Number(lastSendBackOTPDate);

    if (timeDifference < OTP_TIMEOUT) {
      const remainingTime = OTP_TIMEOUT - timeDifference;
      setOtpTimer(remainingTime);
    } else {
      setOtpTimer(0);
    }
  };

  const handleResendOTP = () => {
    if (otpTimer > 0 || onSendBackOTP) return;
    // Handle the logic to resend the OTP validation code
    // ...
    const email = localStorage.getItem('email');
    const password = localStorage.getItem('password');
    AuthService.resendOtpCode({ email: email as string, password: password as string })
      .then(() => {
        // Update the timer after resending the OTP
        setOtpTimer(60); // Set the timer to 60 seconds
        localStorage.setItem(
          "lastSendBackOTPDate",
          `${Math.floor(Date.now() / 1000)}`
        );
      })
      .catch((err) => {
        console.log(err);

      })
      .finally(() => {
        setOnSendBackOTP(false);
      });
  };

  const handleConfirm = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (confirmBtnRef.current) {
      confirmBtnRef.current.setAttribute("disabled", "true");
      confirmBtnRef.current.innerHTML = "loading...";

      const password = localStorage.getItem("password") as string;
      const email = localStorage.getItem("email") as string;

      AuthService.verifyOtpCode({ email, password, otp }).then((data) => {
        localStorage.removeItem('password');
        localStorage.removeItem('email');
        localStorage.removeItem('lastSendBackOTPDate');
        onNextStep();
      }).catch((error) => {
        formik.setErrors(error.response.data.errors);
      }).finally(() => {
        if (confirmBtnRef.current) {
          confirmBtnRef.current.removeAttribute("disabled");
          confirmBtnRef.current.innerHTML = "Confirm your registration";
        }
      });
    }
  };

  useEffect(() => {
    startTimer(); // Start the timer on component mount
  }, []);

  useEffect(() => {
    // Update the timer every second
    if (otpTimer > 0) {
      const timerId = setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
      return () => clearTimeout(timerId); // Clear the timer on component unmount
    }
  }, [otpTimer]);

  return (
    <form>
      <h6 className="mt-5">
        Please enter the validation code received in your
        email address
      </h6>
      <div className="p-0 m-0 mt-5">
        <OtpInput
          value={otp}
          onChange={(code: any) => {
            setOtp(code);
          }}
          numInputs={5}
          separator={<span className="m  x-3"></span>}
          containerStyle={"d-flex justify-content-between"}
          inputStyle={"w-100 mx-2 form-control"}
        />
        {formik.errors.otp ? (
          <div style={{ color: "var(--primaryColor)" }}>
            {formik.errors.otp}
          </div>
        ) : null}
      </div>
      <div className="small mt-3">
        <span
          onClick={handleResendOTP}
          role={"button"}
          className={`nav-link fw-500 ${otpTimer > 0
            ? ""
            : "active nav-link-underline-hover"
            }`}
          style={{ cursor: "pointer" }}
        >
          {otpTimer > 0
            ? `Resend OTP in ${otpTimer} seconds`
            : onSendBackOTP
              ? "sending..."
              : "Send back the validation code"}
        </span>
      </div>
      <div className="d-flex justify-content-end align-items-end mt-4">
        <div>
          <button
            disabled={
              !(otp !== null && (otp + "").length === 5)
            }
            ref={confirmBtnRef}
            type='button'
            onClick={handleConfirm}
            className="btn btn-primary px-4"
          >
            Confirm your registration
          </button>
        </div>
      </div>
    </form>
  );
};

export default RegisterProStep1;
