import React, { CSSProperties } from 'react'
import { DiscussionSystemActionType, DiscussionSystemProps, SYSTEM_PENDING_RESPONSE_DURATION } from '../../LatriceDiscussionMessage'
import moment from 'moment'
import { Tooltip } from 'react-tooltip'
import { formatSecondsToTime, timeToTimezone, formatUSPrice } from '../../../../utils/utils'

export type DisputedDiscussionBase = {
    OnDisputedAction?: (action: Extract<DiscussionSystemActionType, 'accept' | 'refuse'>) => void;
    actionContainer?: JSX.Element;
    alertContainer: JSX.Element;
}

export const AcceptedDiscussionBase = (props: DiscussionSystemProps & DisputedDiscussionBase) => {
    const [elapsedTime, setElapsedTime] = React.useState(0);

    return <div>
        <div
            style={
                {
                    '--RESPONSE_DURATION': SYSTEM_PENDING_RESPONSE_DURATION,
                    '--elapsedTime': (SYSTEM_PENDING_RESPONSE_DURATION - elapsedTime),
                    '--elapsedTimeColor': `var(--yellowColor)`
                } as CSSProperties
            }

            className={`w-75 LatricePendingAppointment shadow`}>

            <div className="rce-mbox">
                <div className="rce-mbox-body">
                    <div className="rce-mbox-forward rce-mbox-forward-right"></div>
                    <div className="d-flex justify-content-between rce-mbox-title rce-mbox-title--clear">
                        <span className="text-uppercase">
                            You have a pending custom appointment
                        </span>
                        <Tooltip id='pending-custom-appointment-tooltip' />
                        <span
                            data-tooltip-content={'Time remaining to accept this appointment.'}
                            data-tooltip-id='pending-custom-appointment-tooltip'
                            style={{ 'color': 'var(--primaryColor)', textDecoration: 'none !important' } as CSSProperties}>
                            {formatSecondsToTime((SYSTEM_PENDING_RESPONSE_DURATION - elapsedTime) / 1000)}</span>
                    </div>
                    <div className="rce-mbox-text left d-flex flex-column gap-1">
                        <img
                            style={{ width: '100px' }}
                            alt=""
                            className="img-fluid rounded"
                            src={props.appointment.photo}
                        />
                        <div className="d-flex align-items-center">
                            <h6 className="text-underline">
                                The service is like {props.appointment.serviceName}
                            </h6>
                            <span className="small">({props.appointment.size})</span>
                        </div>
                        {
                            props.appointment.disputeReasons ?
                                <div className="d-flex gap-1 align-items-center w-100 fw-500 mb-2">
                                    <div className="d-flex align-items-center gap-1">
                                        <i className="fa fa-calendar" />
                                        <span className={`${props.appointment.disputeReasons.badDate ? 'text-decoration-line-through' : ''}`}>
                                            {moment(props.appointment.day, 'YYYY-MM-DD').format(
                                                'DD MMM YYYY'
                                            )}
                                        </span>
                                        <span className={`${props.appointment.disputeReasons.badDate ? 'text-decoration-line-through' : ''}`}>{timeToTimezone(props.appointment.startTime)}</span>
                                        {props.appointment.disputeReasons.badDate !== null && <>
                                            {/* <i className="fa fa-circle-exclamation text-warning"></i> */}
                                            <span className={`d-flex gap-1 text-underline text-warning`}>
                                                <span>{moment(props.appointment.disputeReasons.badDate, 'YYYY-MM-DD').format(
                                                    'DD MMM YYYY'
                                                )}</span>
                                                <span> {timeToTimezone(moment(props.appointment.day, 'YYYY-MM-DD').format(
                                                    'HH:mm'
                                                ))}</span>
                                            </span>
                                        </>
                                        }
                                    </div>
                                    <div className={`ms-auto d-flex gap-1`}>
                                        {props.appointment.disputeReasons.priceTooLow &&
                                            <span className={`text-warning text-underline`}>
                                                {formatUSPrice(props.appointment.disputeReasons.priceTooLow)}
                                            </span>
                                        }
                                        <span className={`${props.appointment.disputeReasons.priceTooLow ? 'text-decoration-line-through' : ''}`}>
                                            {formatUSPrice(props.appointment.price)}
                                        </span>
                                    </div>
                                </div>
                                :
                                <div className="d-flex gap-1 align-items-center w-100 fw-500 mb-2">
                                    <div className="d-flex align-items-center gap-1">
                                        <i className="fa fa-calendar" />
                                        <span>
                                            {moment(props.appointment.day, 'YYYY-MM-DD').format(
                                                'DD MMM YYYY'
                                            )}
                                        </span>
                                        <span>{timeToTimezone(props.appointment.startTime)}</span>
                                    </div>
                                    <div className="ms-auto">
                                        {formatUSPrice(props.appointment.price)}
                                    </div>
                                </div>
                        }
                        <p>{props.appointment.description}</p>
                    </div>
                </div>
                {
                    // ALERT CONTAINER
                    props.alertContainer

                }
                <div className='d-flex justify-content-between align-items-end'>
                    {
                        // ACTION CONTAINER
                        props.actionContainer

                    }
                    <div
                        className="rce-mbox-time non-copiable ms-auto"
                        data-text={moment
                            .utc(props.appointment.createdAt)
                            .tz(moment.tz.guess())
                            .fromNow()}
                    >
                        <span className="rce-mbox-status"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
