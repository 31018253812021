import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCommentDots,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";

export const PROFESSIONAL_ROUTES = [
  "/business-profile",
  "/business-info",
  "/service-menu",
  "/manage-availability",
  "/photo",
  "/promotions",
  "/growth",
  "/marketing",
  "/admin/manage-appointment",
];

export const ADMIN_ROUTES = ["/more-setting"];

export const UNAUTHENTICATED_ROUTES = [
  "/",
  "/register",
  "/register-pro",
  "/contact-us",
  "/login",
  "/search",
];

export const restrictUserRoutes = (
  routes: { to: string; label: string; [key: string]: string | IconProp }[],
  professional: boolean = false,
  LatriceUser?: any
) => {
  if (professional && LatriceUser) {
    return routes;
  } else if (professional) {
    return routes.filter((route) => {
      return !ADMIN_ROUTES.includes(route.to);
    });
  } else {
    return routes.filter((route) => {
      return !PROFESSIONAL_ROUTES.includes(route.to);
    });
  }
};

const navigationLinks1 = [
  { to: "/", label: "Home" },
  { to: "/register", label: "Client account" },
  { to: "/register-pro", label: "Professional account" },
  { to: "/login", label: "Login" },
  { to: "/contact-us", label: "Contact us" },
  // { to: "/client-profile", label: "Account" },
];

const navigationLinks2 = [
  { to: "/client-profile", label: "Profile" },
  { to: "/appointments", label: "MY BOOKINGS" },
  { to: "/earnings", label: "Earnings" },
  { to: "/clients", label: "Clients" },
  { to: "/marketing", label: "Marketing" },
  { to: "/more-setting", label: "More Setting" },
];

const navigationLinks3 = [
  { to: "/business-profile", label: "Edit" },
  // { to: "/personnal-info", label: "Personal Info" },
  { to: "/business-info", label: "Business Info" },
  { to: "/service-menu", label: "Service Menu" },
  { to: "/manage-availability", label: "Manage Availability" },
  // { to: "/photo", label: "Gallery" },
];

const navigationLinks4 = [
  { to: "/client-profile", label: "Profile" },
  { to: "/appointments", label: "MY BOOKINGS" },
  { to: "/payments", label: "Payments" },
  { to: "/tip-settings", label: "Tips Settings" },
  { to: "/promotions", label: "Promotions" },
  { to: "/support", label: "Support" },
  { to: "/legal", label: "Legal" },
];

const navigationLinks5 = [
  {
    to: "/message",
    icon: faCommentDots,
    className: "nav-link text-white d-none d-lg-block",
    label: "Message",
    // count: 38,
  },
  {
    to: "/signin",
    icon: faCartShopping,
    className: "nav-link text-white  d-none d-lg-block",
    label: "Signin",
    // count: null,
  },
];

export {
  navigationLinks1,
  navigationLinks2,
  navigationLinks3,
  navigationLinks4,
  navigationLinks5,
};
