import axios from "axios";

const addCard = (card: any) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}payment/method/add-card`,
    method: "POST",
    data: card,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

const getAllCardPaymentMethod = () => {
  return axios
    .get(`payment/method`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const updatePaymentMethod = (paymentMethod: any) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}payment/method/update-card`,
    method: "PATCH",
    data: paymentMethod,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

export const PaymentMethodService = {
  addCard,
  getAllCardPaymentMethod,
  updatePaymentMethod,
};
