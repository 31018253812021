import React, { useEffect, useState } from "react";
import Manager from "../models/Manager";
import AddManagerModal from "../components/modal/AddManagerModal";
import AuthLayout from "../components/auth/AuthLayout";
import MainNav from "../components/navigation/MainNav";
import { useDispatch, useSelector } from "react-redux";
import {
  getManagers,
  selectManagers,
  selectManagersWithoutSalon,
} from "../redux/slices/managerSlice";
import { AnyAction } from "redux";
import PulseLoader from "../components/loader/PulseLoader";
import { Toaster } from "react-hot-toast";
import ConfirmDeleteModal from "../components/reusable/ConfirmDeleteModal";
import ManagerContainer from "../components/adminDashboard/ManagerContainer";

type ManagerPageProps = {};

const ManagerPage: React.FC<ManagerPageProps> = ({}) => {
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState<string | null>(null);
  const [showManagerModal, setShowManagerModal] = useState(false);
  const [managerToDelete, setManagerToDelete] = useState<Manager | null>(null);
  const dispatch = useDispatch();
  const managers = useSelector(selectManagers).managers;
  const unAssignedManagers = useSelector(selectManagersWithoutSalon);

  const handleDelete = () => {
    // setLoading(true);
    // dispatch(
    //   deleteService({
    //     _id: serviceToDelete?._id,
    //   }) as unknown as AnyAction
    // )
    //   .unwrap()
    //   .then(() => {
    //     toast.loading("Deleting service...", { duration: 3000 });
    //     toast.remove();
    //     toast.success("Service deleted successfully!", { duration: 3000 });
    //   })
    //   .catch((err: any) => {
    //     toast.error(err);
    //   })
    //   .finally(() => {
    //     setServiceToDelete(null);
    //     setLoading(false);
    //   });
  };

  const filtered =
    managers &&
    managers?.filter((manager) =>
      q === null
        ? true
        : manager.user.first_name.toLowerCase().includes(q.toLowerCase()) ||
          manager.user.last_name.toLowerCase().includes(q.toLowerCase())
    );

  useEffect(() => {
    dispatch(getManagers("") as unknown as AnyAction);
  }, []);

  return (
    <AuthLayout>
      <MainNav navType={"connectedNav"} />
      <Toaster position="bottom-right" />
      <div className=" mt-4 p-4  manager-container d-flex flex-column gap-2">
        <div className="container p-lg-0">
          <div className="d-flex flex-wrap  justify-content-between">
            <div className="d-flex gap-3 align-items-center">
              <img
                style={{ height: "40px" }}
                src={require("../assets/images/manager.png")}
                alt=""
              />
              <span className="text-black fw-bold">Managers</span>
            </div>
            <button
              onClick={() => setShowManagerModal(true)}
              className="manage-btn rounded small-text text-white py-2 border-0 "
            >
              Add a new manager
            </button>
          </div>
          <div className="mt-2">
            Explore and manage your team of salon managers here. View, assign,
            or modify roles to ensure smooth operations across your multiple
            salons. Access contact details, oversee responsibilities, and make
            necessary adjustments effortlessly.
          </div>
          <div className="mt-4 d-flex gap-4">
            <div className="action-square d-flex align-items-center justify-content-between ">
              <div className=" d-flex align-items-center flex-wrap">
                <i className="fa-regular fa-user text-primary fa-2x me-3"></i>
                <div className=" text-black  fw-bold me-2 fs-3">
                  {managers?.length}
                </div>
                <div className="">Managers</div>
              </div>
              <i className="fa-solid fa-ellipsis text-black"></i>
            </div>
            <div className="action-square d-flex align-items-center justify-content-between ">
              <div className=" d-flex align-items-center flex-wrap">
                <i className="fa-regular fa-user text-primary fa-2x me-3"></i>
                <div className=" text-black  fw-bold me-2 fs-3">
                  {unAssignedManagers.length}
                </div>
                <div className="">Unassigned</div>
              </div>
              <i className="fa-solid fa-ellipsis text-black"></i>
            </div>
          </div>
          <div
            className=" px-0 d-flex m-0 input-group col-md-6 col-12 w-50 mb-2 mt-5 align-items-center "
            aria-autocomplete="none"
          >
            <button
              style={{
                backgroundColor: "white",
                height: "45px",
                zIndex: "1",
              }}
              className="btn border-0"
              type="button"
            >
              <i className="fa-solid fa-search"></i>
            </button>
            <input
              value={q ?? ""}
              onChange={(e) => setQ(e.target.value)}
              placeholder="Search"
              type="text"
              className="form-control border-0  no-decoration fw-500"
            />
          </div>
          <div className="p-3 mt-4 table-responsive table-responsive-sm ">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Manager</th>
                  <th scope="col">Salon</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {managers &&
                  filtered?.map((manager, index) => (
                    <ManagerContainer index={index} manager={manager} />
                  ))}
              </tbody>
            </table>

            <div className="d-flex flex-column gap-2 mt-2 "></div>
          </div>

          {managers && managers.length === 0 && (
            <div className="d-flex user-select-none text-muted gap-3 align-items-center justify-content-center mt-5">
              <i className="fa fa-search text-muted fa-2x"></i>
              <div className="text-center">
                You have no managers yet
                <br />
                Add managers to help you supervise your different salons.
              </div>
            </div>
          )}
          {managers && managers.length !== 0 && filtered?.length === 0 && (
            <div className="d-flex user-select-none text-muted gap-3 align-items-center justify-content-center mt-5">
              <i className="fa fa-search text-muted fa-2x"></i>
              <div className="text-center">
                No manager matches your search. Check your spelling or maybe
                <br />
                you didn't add it to your managers list.
              </div>
            </div>
          )}
          {managers === null && <PulseLoader />}
          <AddManagerModal
            show={showManagerModal}
            onClose={() => setShowManagerModal(false)}
            onCreate={function (formData: FormData): void {
              throw new Error("Function not implemented.");
            }}
          />
          <ConfirmDeleteModal
            show={managerToDelete !== null}
            setServiceToDelete={() => {
              setManagerToDelete(null);
            }}
            handleServiceDelete={handleDelete}
            text1={`Do you really want to remove : ${managerToDelete?.user.first_name} ${managerToDelete?.user.last_name} from your managers list?`}
            text2={
              "If you remove them, all information about them will be lost"
            }
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default ManagerPage;
