import axios from "axios";
import { DayTimes } from "../components/UpcomingHours";
import { USATimezone } from "../pages/ManageAvailabilityPage";

const getUnAvailabilities = () => {
  return axios
    .get(`unavailabilities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "x-salon-id": `${localStorage.getItem("salonId")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const addUnAvailability = (changes: {
  edited: DayTimes;
  removed: DayTimes;
  timezone: USATimezone;
}) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}unavailabilities`,
    method: "POST",
    data: changes,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

const createBulkUnAvailability = (changes: {
  edited: DayTimes;
  removed: DayTimes;
  timezone: USATimezone;
  repeatUntil: moment.Moment;
}) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}unavailabilities/create-bulk-unavailability`,
    method: "POST",
    data: changes,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

const removeUnAvailability = (dayTimes: DayTimes) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}unavailabilities`,
    method: "DELETE",
    data: Object.keys(dayTimes),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

export const UnAvailabilityService = {
  getUnAvailabilities,
  addUnAvailability,
  removeUnAvailability,
  createBulkUnAvailability,
};
