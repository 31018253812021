import React from 'react'

export const SalonPolicy = () => {


    return (
        <div className="d-flex flex-column my-3 read_me">
            <h5 className="fw-bold text-black">Important Please Read</h5>

            <span className="text-primary fw-500">
                Cancellation Policy
            </span>

            <div>
                Le lorem ipsum est, en imprimerie, une suite de mots sans
                signification utilisée à titre provisoire pour calibrer une
                mise en page, le texte définitif venant remplacer le
                faux-texte
            </div>

            <h5 className="fw-bold text-black mt-2">Share Profile</h5>
            <div className="d-flex shareProfileBrands  gap-4 text-black mt-3">
                <i className="fa-brands fa-facebook-f fa-1x"></i>
                <i className="fa-brands fa-twitter"></i>
                <i className="fa-regular fa-envelope"></i>
            </div>
        </div>
    )
}
