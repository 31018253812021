import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromotionLinkService } from "../../services/PromotionLinkService";

export interface PromotionLinkState {
  promotionLinks: any;
}

const initialState: PromotionLinkState = {
  promotionLinks: null,
};

export const getPromotionLink = createAsyncThunk(
  "links/getPromotionLinks",
  async () => {
    const response = await PromotionLinkService.getPromotionLink();
    console.log("redux promotions link data", response.data);
    return response.data;
  }
);

// export const getPromotionUserLink = createAsyncThunk(
//   "links/getPromotionUserLinks",
//   async () => {
//     const response = await PromotionLinkService.getPromotionUserLinks();
//     console.log("redux promotions user link data", response.data);
//     return response.data;
//   }
// );

const promotionLinkSlice = createSlice({
  name: "links/getPromotionLink",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPromotionLink.fulfilled, (state, action) => {
      state.promotionLinks = action.payload;
    });
    // .addCase(getPromotionUserLink.fulfilled, (state, action) => {
    //   state.promotionLinks = action.payload;
    // });
  },
});

export default promotionLinkSlice.reducer;
