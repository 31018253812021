import { useState } from "react";
import MainNav from "../components/navigation/MainNav";
import "./../styles/BusinessProfilePage.css";
import AuthLayout from "../components/auth/AuthLayout";
import ChairContainer from "../components/adminDashboard/ChairContainer";
import AddChairModal from "../components/adminDashboard/modal/AddChairModal";

const ClientPage = () => {
  const [showModal, setShowModal] = useState(false);

  return <div>hey</div>;
};

export default ClientPage;
