import { WorkingTableProps } from "../WorkingTable";
import WorkingTableElementLoader from "./WorkingTableElementLoader";
import { v4 as uuidv4 } from 'uuid';


const WorkingTableLoader = ({ className }: Pick<WorkingTableProps, 'className'>) => {

    return <div className={`working-table w-100 ${className ?? ''}`}>
        <div className="working-table__header">
        </div>
        <div className="working-table__body mt-5">
            {
                Array(4).fill(0).map((_, key) =>
                    <WorkingTableElementLoader key={uuidv4()} />
                )
            }
        </div>
    </div>
}

export default WorkingTableLoader;