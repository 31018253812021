import {
  ButtonHTMLAttributes,
  forwardRef,
  LegacyRef,
} from "react";
import "./../styles/Button.css";

export interface BookmarkButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "content"> {
  marked?: boolean;
  onClick?: (e: any) => void;
}


export interface IBookMakable {
  bookMarked?: boolean;
  onBookmark?: (e: any) => void;
}


const BookmarkButton = forwardRef<HTMLButtonElement, BookmarkButtonProps>(
  (
    { marked = false, onClick, ...props }: BookmarkButtonProps,
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    return (
      <button
        onClick={onClick}
        ref={ref}
        style={{ height: "38px" }}
        className={`Button m-0 shadow bookMarkButton ${marked ? "bookMarkButton--marked" : ""
          }`}
      >
        <i className="fa-regular fa-bookmark mx-2"></i>
        <span className="me-2">BOOKMARK</span>
      </button>
    );
  }
);
const ShareButton = forwardRef<HTMLButtonElement, BookmarkButtonProps>(
  (
    { marked = false, ...props }: BookmarkButtonProps,
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    return (
      <button
        ref={ref}
        style={{ height: "38px" }}
        className={`Button m-0 shadow bookMarkButton ${marked ? "bookMarkButton--marked" : ""
          }`}
      >
        <i className="fa-regular fa-bookmark mx-2"></i>
        <span className="me-2">BOOKMARK</span>
      </button>
    );
  }
);

export default BookmarkButton;
