import MainNav from "../components/navigation/MainNav";
import "./../styles/BusinessProfilePage.css";
import "./../styles/MarketingPage.css";
import AuthLayout from "../components/auth/AuthLayout";
import "./../styles/PromotionPage.css";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { CardPromotion } from "../components/promotion/CardPromotion";
import Footer from "../components/Footer";
import { useSalon } from "../context/SalonContext";
import SubscriptionModal from "../components/promotion/SubscriptionModal";
import { useRef, useState } from "react";
import { SalonService } from "../services/SalonService";
import toast from "react-hot-toast";
import { set } from "lodash";

const MarketingPage = () => {
  const salon = useSalon();

  console.log("salon", salon);

  const [showModal, setShowModal] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const [mySalon, setMySalon] = useState(salon);
  const [loading, setLoading] = useState(false);
  const [activatePromotion, setActivatePromotion] = useState(true);

  const handlerChangesStatus = () => {
    setActivatePromotion((prev) => !prev);
  };

  const handleSubmit = async () => {
    console.log("sending data", activatePromotion);
    setLoading(true);
    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute("disabled", "true");

      await SalonService.applyPromotion()
        .then(async (result: any) => {
          toast.success("Promotion Successfully");
          console.log("result", result);
          setMySalon(result.data);
          window.location.reload();
        })
        .catch((err: any) => {
          toast.error("Promotion Failed");
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
          setShowModal(false);
          setTimeout(() => {
            if (submitBtnRef.current) {
              submitBtnRef.current.removeAttribute("disabled");
            }
          }, 2000);
        });
    }
  };

  return (
    <AuthLayout>
      <MainNav navType={"connectedNav"} isSearch={false} />
      <div className="row p-0 m-0 BusinessProfilePage">
        <div className="row p-0 m-0 BusinessProfilePage">
          <div className="container p-lg-0 marketing">
            <div className="marketing-text">
              <h5 className="title-marketing">
                Earn More with Latrice: Rewards for Every{" "}
                <span className="text-primary">Service Rendered!</span>
              </h5>
              <p className="paraph-marketing">
                Boost your earnings with Latrice! Whether you're an independent
                professional or manage a salon, Latrice lets you earn more by
                completing services. The more you do, the more you earn!
              </p>

              <div className="d-flex justify-content-start w-100 mt-8">
                <Button
                  mode="outlined"
                  type="button"
                  content={"Get started"}
                  className="text-primary flex flex-end ms-8 btn-marketing"
                />
              </div>
            </div>
            <div className="marketing-image">
              <img src={require("../assets/images/marketing.jpg")} alt="" />
            </div>
          </div>
          <div className="container">
            <div className="d-flex flex-column align-items-center">
              {salon.salon?.salon_rewards?.activatePromotion ? (
                <div className="d-flex my-4">
                  <h4 className="fw-500 text-primary">Available Promotions</h4>
                  <i className="fa fa-info-circle text-primary"></i>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center p-4 w-100 border-2">
                  <h4 className="fw-500 text-primary">No Promotion avaible</h4>
                  <i className="fa fa-info-circle text-primary"></i>
                </div>
              )}

              {salon.salon?.salon_rewards?.activatePromotion ? (
                <div className="d-flex align-items-center  justify-content-between gap-3 card-container">
                  <CardPromotion
                    status="standard"
                    type="professionals"
                    title={
                      <h4 className="d-flex flex-column ">
                        <span className="promot-per">10$</span>
                        <span>Discount</span>
                      </h4>
                    }
                    activateSuscription={
                      salon.salon?.salon_rewards?.activeSubscription
                    }
                    description={
                      <div>
                        <p className="d-flex justify-content-center align-items-center gap-2 text-white  ">
                          <FontAwesomeIcon icon={faCircleCheck} size="1x" />
                          <span>Lorem ipsum dolor sit amet</span>
                        </p>
                        <p className="d-flex justify-content-center align-items-center gap-2 text-white  ">
                          <FontAwesomeIcon icon={faCircleCheck} size="1x" />
                          <span>Lorem ipsum dolor sit amet</span>
                        </p>
                        <p className="d-flex justify-content-center align-items-center gap-2 text-white  ">
                          <FontAwesomeIcon icon={faCircleCheck} size="1x" />
                          <span>Lorem ipsum dolor sit amet</span>
                        </p>
                        <p className="d-flex justify-content-center align-items-center gap-2 text-white  ">
                          <FontAwesomeIcon icon={faCircleCheck} size="1x" />
                          <span>Lorem ipsum dolor sit amet</span>
                        </p>
                      </div>
                    }
                    setShowModal={() => setShowModal(true)}
                  />

                  {/* <CardPromotion
                    status="gold"
                    type="salon"
                    title={
                      <h4 className="d-flex flex-column ">
                        <span className="promot-per">20$</span>
                        <span>Discountt</span>
                      </h4>
                    }
                    description={
                      <div>
                        <p className="d-flex justify-content-center align-items-center gap-2 text-white  ">
                          <FontAwesomeIcon icon={faCircleCheck} size="1x" />
                          <span>Lorem ipsum dolor sit amet</span>
                        </p>
                        <p className="d-flex justify-content-center align-items-center gap-2 text-white  ">
                          <FontAwesomeIcon icon={faCircleCheck} size="1x" />
                          <span>Lorem ipsum dolor sit amet</span>
                        </p>
                        <p className="d-flex justify-content-center align-items-center gap-2 text-white  ">
                          <FontAwesomeIcon icon={faCircleCheck} size="1x" />
                          <span>Lorem ipsum dolor sit amet</span>
                        </p>
                        <p className="d-flex justify-content-center align-items-center gap-2 text-white  ">
                          <FontAwesomeIcon icon={faCircleCheck} size="1x" />
                          <span>Lorem ipsum dolor sit amet</span>
                        </p>
                      </div>
                    }
                    setShowModal={() => setShowModal(true)}
                  /> */}

                  <SubscriptionModal
                    show={showModal}
                    submitBtnRef={submitBtnRef}
                    changeStatus={handlerChangesStatus}
                    onClose={() => setShowModal(false)}
                    suscribe={handleSubmit}
                    activateSuscription={
                      salon.salon?.salon_rewards?.activeSubscription
                    }
                  />
                </div>
              ) : (
                <div>
                  <div className="">
                    <img
                      src={require("../assets/images/promotion-salon.png")}
                      alt="latrice promotion salon"
                      width={300}
                      height={300}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </AuthLayout>
  );
};

export default MarketingPage;
