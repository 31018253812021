import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IUserManager, IUserRegistrationPro } from "../../models/User";
import { ManagerService } from "../../services/ManagerService";
import Manager from "../../models/Manager";
import { AddSalon, adminUpdateSalon } from "./authSlice";
import { ISalon } from "../../models/SalonX";

export interface ManagerState {
  managers: Manager[] | null;
  errors: any;
}

const initialState: ManagerState = {
  managers: null,
  errors: null,
};

export const getManagers = createAsyncThunk<Manager[], string>(
  "managers/get",
  async () => {
    const res = await ManagerService.getManagers();
    return res.data;
  }
);

export const AddManager = createAsyncThunk<IUserManager, IUserRegistrationPro>(
  "managers/add",
  async (user, { rejectWithValue }) => {
    try {
      const res = await ManagerService.addManager(user);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);
export const UnassignManager = createAsyncThunk<IUserManager, string>(
  "managers/unassign",
  async (managerId, { rejectWithValue }) => {
    try {
      const res = await ManagerService.unassignManager(managerId);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

const managerSlice = createSlice({
  name: "managers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getManagers.fulfilled, (state, action) => {
        state.managers = action.payload;
        state.errors = null;
      })
      .addCase(AddManager.fulfilled, (state, action) => {
        if (state.managers) {
          const managers = state.managers.slice();
          managers.push(action.payload as any);
          state.managers = managers;
        } else {
          state.managers = [action.payload as any];
        }
      })
      .addCase(adminUpdateSalon.fulfilled, (state, action) => {
        const payload = action.payload as unknown as {
          salon: ISalon;
          manager?: Manager;
        };
        if (payload.manager) {
          if (state.managers) {
            const index = state.managers.findIndex(
              (manager) => manager._id === payload.manager?._id
            );
            if (index !== -1) {
              state.managers[index] = {
                ...state.managers[index],
                ...payload.manager,
              };
            }
          }
        }
      })
      .addCase(AddSalon.fulfilled, (state, action) => {
        const payload = action.payload as unknown as {
          salon: ISalon;
          manager?: Manager;
        };
        if (payload.manager) {
          if (state.managers) {
            const index = state.managers.findIndex(
              (manager) => manager._id === payload.manager?._id
            );
            if (index !== -1) {
              state.managers[index] = {
                ...state.managers[index],
                ...payload.manager,
              };
            }
          }
        }
      })
      .addCase(UnassignManager.fulfilled, (state, action) => {
        if (state.managers) {
          const index = state.managers.findIndex(
            (manager) => manager._id === action.payload._id
          );
          if (index !== -1) {
            state.managers[index] = {
              ...state.managers[index],
              ...action.payload,
            };
          }
        }
      })
      .addCase(AddManager.rejected, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(UnassignManager.rejected, (state, action) => {
        state.errors = action.payload;
      });
  },
});

// Selector to get managers from state
export const selectManagers = (state: { managers: ManagerState }) =>
  state.managers;

// Selector to get managers without a linked_salon_id
export const selectManagersWithoutSalon = (state: {
  managers: ManagerState;
}) => {
  const managers = state.managers.managers;
  return managers?.filter((manager) => !manager.linked_salon_id) || [];
};

// Selector to get a manager by salonId
export const selectManagerBySalonId = (
  state: { managers: ManagerState },
  salonId: string
) => {
  const managers = state.managers.managers;
  return (
    managers?.find(
      (manager) =>
        manager.linked_salon_id && manager.linked_salon_id?._id === salonId
    ) || null
  );
};

export default managerSlice.reducer;
