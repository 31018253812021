import React, { useEffect, useState } from 'react'
import { IAppointment } from '../../../models/Appointment';
import moment from 'moment';

interface GraphAppointmentRenderProps {
    appointment: IAppointment,
    interval: moment.Moment[],
    period: 'day' | 'week' | 'month',
    styles: CSSModule,
    render: (appointment: IAppointment) => React.JSX.Element;
}


export const GraphAppointmentRender: React.FC<GraphAppointmentRenderProps> = (
    {
        appointment,
        interval,
        period,
        styles,
        render
    }
) => {

    const [coordinates, setCoordinates] = useState<{ row: number, column: number }>();

    useEffect(() => {
        const filteredInterval = interval.filter((value) => {
            const startAt = moment(`${appointment.day} ${appointment.startedAt}`, 'YYYY-MM-DD HH:mm');
            return startAt.diff(value, 'days') === 0;
        });

        if (filteredInterval.length > 0) {
            let appointmentDay = filteredInterval[0].day() + 1;
            let appointmentDate = filteredInterval[0].date() + 1;

            const startAt = moment(`${appointment.day} ${appointment.startTime}`, 'YYYY-MM-DD HH:mm A');

            const timeDetails = {
                time: startAt.format("A"),
                hour: startAt.format("HH"),
                minute: startAt.format("mm"),
            };

            // convert day or date to graph column
            const column = period === "week" ? appointmentDay + 1 : appointmentDate;
            const row = Number(timeDetails.hour);
            setCoordinates({
                column, row
            })
        }

    }, [appointment, interval, period])


    if (!coordinates) return null


    return <div
        key={`${appointment._id}`}
        style={{ gridColumn: `${coordinates.column}`, gridRow: `${coordinates.row + 1}` }}
        className={styles["AppointmentGraph__Item"]}
    >
        {render(appointment)}
    </div>
}