import { NextButton } from "./NextButton";

const svg = require('../../assets/images/undraw_Post_re_mtr4.png');

interface WelcomeProps {
    onNext: () => void
}


export default function Welcome({ onNext }: Readonly<WelcomeProps>) {


    return <div className="container">
        <div className="d-flex flex-column align-items-center gap-3">
            <h3 className="text-black">Welcome to our Service Cart - Showcase Your Hairdressing Services and Get Expert Responses!</h3>
            <img alt='' className='img-fluid w-50' src={svg} />
            <p className="fs-5">
                Unlock the full potential of our platform by adding your hairdressing services to the cart. Experience the convenience of receiving prompt responses from our skilled professionals. Choose from a variety of tailored proposals and find the perfect match for your unique needs. Your ideal hairdressing solution is just a click away!
            </p>
        </div>
        <div className="d-flex justify-content-end">
            <NextButton onClick={onNext} />
        </div>
    </div>
}
