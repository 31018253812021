import React from 'react';

export interface WithTimerProps {
    refererDate: number;
    duration: number;
    elapsedTime: number;
}

export type WithTimerComponentType<T> = T & WithTimerProps;

const withTIMER = <T extends object>(
    WrappedComponent: React.ComponentType<WithTimerComponentType<T>>
) => {
    return (props: WithTimerComponentType<T>) => {
        const [state, setState] = React.useState({
            refererDate: props.refererDate,
            duration: props.duration,
            elapsedTime: Math.max(0, Date.now() - props.refererDate),
        });

        const updateElapsedTime = () => {
            setState((prevState) => {
                const { duration, elapsedTime } = prevState;

                if (elapsedTime < duration) {
                    // Calculate the elapsedTime in seconds
                    return {
                        ...prevState,
                        elapsedTime: Math.min(elapsedTime + 1000, duration),
                    };
                }

                return prevState;
            });
        };

        React.useEffect(() => {
            // Start the timer when the component mounts
            const intervalId = setInterval(updateElapsedTime, 1000);

            return () => {
                // Clear the timer when the component unmounts
                clearInterval(intervalId);
            };
        }, []);

        return <WrappedComponent {...props} {...state} />;
    };
};

export default withTIMER;
