import { faEnvelopeOpen, faDollar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TransactionHistorySkeleton } from './TransactionHistorySkeleton'

export const TransactionSkeleton = () => {


    return (
        <div className="d-flex flex-column justify-content-center align-items-center gap-4">

            <div className="d-flex flex-column bg-white rounded-2 w-100">
                <div className="d-flex justify-content-between align-items-center w-100 border-bottom p-3">
                    <div className="d-flex flex-column justify-content-between">
                        <span className="text-muted">Total Balance</span>
                        <span className="fs-3 fw-semibold">
                            <div
                                style={{ height: "18px", marginBottom: "1rem" }}
                                className="shimmer mt-3 shimmer__line w-75 mx-0 "
                            ></div>
                        </span>
                    </div>
                    <div className="d-flex gap-2">
                        <button disabled

                            className="btn bg-white rounded border-primary p-2 d-flex align-items-center justify-content-center gap-1"
                        >
                            <span className="fw-500">Transfer funds</span>
                            <FontAwesomeIcon className="text-primary" icon={faEnvelopeOpen} />
                        </button>
                        <button
                            disabled
                            className="btn bg-white rounded border-primary p-2 d-flex align-items-center justify-content-center gap-1"
                        >
                            <span className="fw-500">Add funds</span>
                            <FontAwesomeIcon className="text-primary" icon={faDollar} />
                        </button>
                    </div>
                </div>
                <div>
                    <TransactionHistorySkeleton />
                </div>
            </div>

        </div>
    )
}