import axios from "axios";
import { EditCustomAppointmentProps, ReassignCustomAppointmentProps } from "../redux/slices/customAppointmentSlice";
import { DiscussionSystemActionType } from "../components/chat/LatriceDiscussionMessage";

const getCustomAppointments = () => {
  return axios
    .get(`appointments/custom`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const addCustomAppointment = (service: FormData) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}appointments/custom`,
    method: "POST",
    data: service,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

const editCustomAppointment = ({ customAppointment, currentUserIsInitiatorOfAppointment }: EditCustomAppointmentProps) => {
  const json: Record<string, string | Blob> = {};
  const data = customAppointment;
  const type: DiscussionSystemActionType = customAppointment.get('type') as DiscussionSystemActionType;

  let url = `${process.env.REACT_APP_API_BASE_URL}appointments/custom/edit/${data.get('id')}`;

  if (currentUserIsInitiatorOfAppointment && (type === 'accept' || type === 'refuse')) {
    url = `${process.env.REACT_APP_API_BASE_URL}appointments/custom/${type === 'accept' ? 'validate' : 'decline'}/${data.get('id')}`;
  }

  data.forEach((value, key) => {
    json[key] = value;
  });

  return axios({
    url: url,
    method: "POST",
    data: json,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

const reassignCustomAppointment = ({ customAppointmentId, professionalId }: ReassignCustomAppointmentProps) => {

  let url = `${process.env.REACT_APP_API_BASE_URL}appointments/custom/reassign/${customAppointmentId}`;

  return axios({
    url: url,
    method: "POST",
    data: { professionalId },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

const markAsStarted = (appointmentId: string) => {
  return axios({
    url: `appointments/custom/mark-as-started/${appointmentId}`,
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  })
}

const markAsEnded = (appointmentId: string) => {
  return axios({
    url: `appointments/custom/mark-as-ended/${appointmentId}`,
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  })
}


export const CustomAppointmentService = {
  getCustomAppointments,
  addCustomAppointment,
  editCustomAppointment,
  reassignCustomAppointment,
  markAsEnded,
  markAsStarted
};
