export const AvailabilitySkeleton = () => {
  return (
    <div className="d-flex flex-column justify-content-start gap-2">
      <div
        style={{
          width: "200px",
          height: "15px",
          borderWidth: "1px",
          borderRadius: "0px",
        }}
        className="me-auto shimmer shimmer__line shimmer__shadow"
      ></div>
      <div
        style={{
          width: "200px",
          height: "15px",
          borderWidth: "1px",
          borderRadius: "0px",
        }}
        className="shimmer me-auto shimmer__line shimmer__shadow"
      ></div>
      <div
        style={{
          width: "200px",
          height: "15px",
          borderWidth: "1px",
          borderRadius: "0px",
        }}
        className="shimmer me-auto shimmer__line shimmer__shadow"
      ></div>
    </div>
  );
};
