const CITIES = [
  "Miami",
  "Los Angeles",
  "Orlando",
  "Houston",
  "San Antonio",
  "New-York City",
  "Phoenix",
  "Dallas",
  "Chicago",
  "Jacksonville",
  "Philadepia",
  "Colombus",
  "Austin",
  "Atlanta",
  "San Jose",
  "Philadepia",
  "Columbus",
  "Austin",
  "Atlanta",
  "San Jose",
];

const WEEK_DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const REGISTER_PRO_STEPS = [
  {
    title: "Your personal details",
    details: "name, surname, email, password",
    icon: "fa fa-user-circle",
  },
  {
    title: "Verification",
    details: "Code received in your email",
    icon: "fa fa-envelope",
  },

  {
    title: "Profile image",
    details: "Add a profile and cover photo ",
    icon: "fa fa-image",
  },
  {
    title: "Place of Business",
    details: "location",
    icon: "fa fa-user-group",
  },

  {
    title: "Services",
    details: "Add your services",
    icon: "fa fa-shopping-bag ",
  },
];

const CREATE_SALON_STEPS = [
  {
    title: "Business name",
    details: "Add your business name",
    icon: "fa fa-shop ",
  },
  {
    title: "Location",
    details: "Business location",
    icon: "fa fa-location-dot ",
  },

  {
    title: "Manager",
    details: "assign a manager",
    icon: "fa fa-user-gear ",
  },
  {
    title: "Amenities",
    details: "Select your services",
    icon: "fa fa-pen-nib ",
  },
];

const CONSTANTS = {
  CITIES,
  REGISTER_PRO_STEPS,
  WEEK_DAYS,
  CREATE_SALON_STEPS,
};

export { CONSTANTS };
