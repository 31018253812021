import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import ModalLoader from "../../loader/ModalLoader";
import { IChair } from "../../../models/Chair";
import toast from "react-hot-toast";
import axios from "axios";
import { deleteChair, editChair } from "../../../services/ChairService";

type DeleteChairModalProps = {
  show: boolean;
  onClose: () => void;
  chair: IChair;
  salonId: string;
  handleDelete: (chair: { data: IChair }) => void;
};

const DeleteChairModal: FC<DeleteChairModalProps> = ({
  show,
  onClose,
  chair,
  salonId,
  handleDelete,
}) => {
  const [loading, setLoading] = useState(false);
  //   const [chairData, setChairData] = useState<Partial<IChair>>({
  //     label: chair.label,
  //     _id: chair._id,
  //   });

  const handleSaveDeleteFunc = () => {
    setLoading(true);

    deleteChair(chair, salonId)
      .then((res: any) => {
        console.log(
          "here is the response for my deleteChair request",
          res.data
        );
        handleDelete(res);

        setLoading(false);
        onClose();
      })
      .catch((err: any) => {
        toast.error(
          "There was an error while editing. Please try again later."
        );
      });
  };

  return (
    <Modal backdrop="static" show={show} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <div className="text-black fw-500 fs-5">Delete seat</div>
      </Modal.Header>
      {loading && <ModalLoader />}
      <Modal.Body>
        <div className="d-flex flex-column gap-1 p-3 justify-content-center align-items-center">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%" }}
          >
            <img
              src={require("../../../assets/images/delete.png")}
              alt=""
              style={{ width: "100px" }}
            />
          </div>
          <div className="d-flex flex-column gap-2 p-3 justify-content-center align-items-center">
            <h4 className="fw-500  " style={{ color: "#0f2359" }}>
              Are you sure ?
            </h4>
            <p className=" text-center " style={{ color: "" }}>
              This action is irreversible and your chair will be <br />{" "}
              permanently deleted.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <div className="mx-3 my-1 d-flex justify-content-end align-items-center gap-3">
          <button onClick={onClose} className="working-table-outline-button">
            Cancel
          </button>
          <button
            onClick={handleSaveDeleteFunc}
            className="working-table-primary-button"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteChairModal;
