import axios from "axios";
import Review from "../models/Review";

const getUserReviews = () => {
  return axios
    .get(`reviews`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((res) => {
      return res.data;
    });
};

const addUserReviews = (review: any) => {
  console.log("the review ", review);
  return axios({
    method: "post",
    url: `reviews`,
    data: {
      ...review,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    console.log("review data ", data);
    return data;
  });
};

export const ReviewService = {
  getUserReviews,
  addUserReviews,
};
