import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

interface SelectPercentageHelpProps {
  onChange: (percentage: string) => void;
  onBlur: (e: any) => void;
  selectedPercentage: string;
}

const SelectPercentageHelp: React.FC<SelectPercentageHelpProps> = ({
  onChange,
  onBlur,
  selectedPercentage,
}) => {
  const [selectPercentage, setSelectPercentage] = useState<string>("");

  const percentagesData = [
    { value: "1/4", label: "1/4" },
    { value: "1/3", label: "1/3" },
    { value: "1/2", label: "1/2" },
    { value: "Full head", label: "Full head" },
  ];

  const togglePercentage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    console.log("yess value ", name, value);

    setSelectPercentage(value);

    onChange(value);
  };

  return (
    <div className="dropdown form-control p-0 rounded d-flex">
      <div
        className="input-group rounded size-input-container"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        role="button"
      >
        <input
          type="text"
          className="form-control border border-0 bg-transparent text-black"
          disabled
          data-bs-offset="10,20"
          placeholder={
            selectedPercentage
              ? `${selectedPercentage}`
              : "Select your percentage help"
          }
        />
        <span className="input-group-text border border-0 bg-transparent">
          <i className="fa-solid fa-angle-down"></i>
        </span>
      </div>
      <ul className="dropdown-menu">
        <div className="d-flex gap-3">
          <div className="d-flex flex-column">
            {percentagesData.map((percentage) => (
              <label
                key={percentage.value}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="dropdown-item " style={{ fontSize: "14px" }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={`mr-2 ${
                      "selectedPercentage" == percentage.value
                        ? "text-success"
                        : ""
                    }`}
                  />
                  {percentage.label}
                </span>
                <input
                  type="radio"
                  name="percentage"
                  value={percentage.value}
                  onChange={togglePercentage}
                  onBlur={onBlur}
                />
              </label>
            ))}
          </div>
        </div>

        <button
          className="btn btn-outline-danger mt-2 mx-auto"
          onClick={() => console.log("test first")}
        >
          Clear Selection
        </button>
      </ul>
    </div>
  );
};

export default SelectPercentageHelp;
