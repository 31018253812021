import { useEffect, useState } from "react";
import Favs from "../reusable/Favs";
import { useUser } from "../../hooks/UseUser";

const Favorites = () => {
  const [searchItem, setSearchItem] = useState<string | null>(null);
  const currentUser = useUser();
  const favorites = currentUser?.favorites;

  const filter = (items: any[]) => {
    const searchResults = !searchItem
      ? items
      : items.filter((item: any) =>
          item.name.toLowerCase().includes(searchItem.toLowerCase())
        );

    return searchResults;
  };

  useEffect(() => {
    console.log("user", currentUser);
    console.log("my favorites", currentUser?.favorites);
  }, []);

  return (
    <div className="Favorites">
      <h3 className="text-primary text-center">See All Favorites</h3>
      <div className="d-flex FavoritesContainer gap-3 flex-wrap">
        <div
          className="input-group d-flex m-0  mb-2 align-items-center w-100"
          aria-autocomplete="none"
        >
          <button
            style={{ backgroundColor: "white", height: "45px" }}
            className="btn border-0"
            type="button"
          >
            <i className="fa-solid fa-search"></i>
          </button>
          <input
            value={searchItem ?? ""}
            onChange={(e) => setSearchItem(e.target.value)}
            placeholder="Search for favorites..."
            type="text"
            className="form-control border-0  no-decoration fw-500"
          />
        </div>
        <div
          className="d-flex flex-wrap  w-100 gap-1"
          style={{ height: "60vh", overflow: "auto" }}
        >
          {favorites !== undefined && favorites.length > 0 ? (
            filter(favorites).map((favorite: any) => {
              return (
                <Favs
                  profile={favorite.portfolio[0]}
                  localization={favorite.address}
                  name={`${favorite.name}`}
                  available={true}
                  id={favorite._id}
                />
              );
            })
          ) : (
            <div
              style={{ minHeight: "50vh" }}
              className="w-100 d-flex justify-content-center align-items-center small text-muted flex-column fs-6"
            >
              <i className="fas fa-search-minus fa-3x mb-2"></i>
              <h4> No favorites added yet</h4>
              {/* <span className="text-center">
                We couldn't find anything based on your search.
              </span> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Favorites;
