import { useEffect, useRef, useState } from "react";
import styles from "../../styles/AppointmentPage.module.css";
import AppointmentDetails from "./AppointmentDetails";
import moment from "moment";
import { toProjectLabel, toServiceLabel } from "../../utils/utils";
import { AnyAction } from "@reduxjs/toolkit";
import { cancelAppointment } from "../../redux/slices/appointmentSlice";
import { useDispatch } from "react-redux";
import { DatePeriod } from "./AppointmentGraph";
import { IProject } from "../../models/Project";
import Salon from "../../models/Salon";

const ProjectItem = ({
  project,
  mode = null,
  period = "day",
}: {
  project: IProject;
  mode?: "group" | null;
  period?: DatePeriod;
}) => {
  const [show, setShow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const detailRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const handleShow = (value: boolean) => setShow(value);

  const handleCancelAppointment = (project: IProject): any => {
    // return dispatch(cancelAppointment(project) as unknown as AnyAction).unwrap();
  };

  const getAppointmentColor = (project: IProject) => {
    const item = document.querySelector(
      `[data-project-id='${project._id}']`
    ) as HTMLDivElement;
    if (item) {
      return `${item.style.borderColor}`;
    }
    return "var(--primaryColor)";
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        if (
          !(event.target as HTMLElement).classList.contains("cancelHandler")
        ) {
          handleShow(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      data-project-id={`"${project._id}"`}
      ref={containerRef}
      style={{ position: "relative", userSelect: "none" }}
    >
      <div
        onClick={() => setShow(!show)}
        style={{ borderLeft: `3px solid ${getAppointmentColor(project)}` }}
        className={`${
          mode === "group"
            ? "d-flex flex-column border justify-content-between gap-5 align-items-center"
            : " d-flex flex-column justify-content-center "
        } ${styles.AppointmentItem} ${
          show ? styles["AppointmentItem--active"] : ""
        }`}
      >
        <div className="d-flex gap-2 fw-normal">
          <span>Start at</span>
          {moment(project.startTime, "HH:mm").format("h:mm A")}
          {/* <span>-</span>
          {moment(project.endTime, 'HH:mm').format('h:mm A')} */}
        </div>
        <div className={`fw-bold`}>{toProjectLabel(project)}</div>

        <div className={`fw-normal`}>{(project.salon as Salon)?.name}</div>
      </div>

      <div ref={detailRef}>
        {/* <AppointmentDetails
          show={show}
          handleShow={setShow}
          project={project}
          onCancel={handleCancelAppointment}
        /> */}
      </div>
    </div>
  );
};

export default ProjectItem;
