import React from "react";

interface CardPreviewProps {
  name: string;
  cardNumber: string;
  expiryDate: string;
  onEditClick?: () => void;
}

const CardPreview = ({
  name,
  cardNumber,
  expiryDate,
  onEditClick,
}: CardPreviewProps) => {
  const detectCardType = (cardNumber: string) => {
    const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const masterCardRegex = /^5[1-5][0-9]{14}$/;
    // const amexRegex = /^3[47][0-9]{13}$/;
    // const discoverRegex = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
    // const jcbRegex = /^35(?:2[89]|[3-8][0-9])[0-9]{12}$/;
    // const dinersRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
    // const unionPayRegex = /^62[0-9]{14,17}$/;
    // const maestroRegex = /^5[0678]\d{14,16}$/;

    if (visaRegex.test(cardNumber)) {
      return "Visa";
    } else if (masterCardRegex.test(cardNumber)) {
      return "MasterCard";
    } else {
      return "Unknown";
    }
  };

  const maskCardNumber = (cardNumber: string) => {
    const lastFourDigits = cardNumber.slice(-4);
    return `**** **** **** ${lastFourDigits}`;
  };
  return (
    <div className="d-flex justify-content-between gap-4 w-100 p-2 px-4  align-content-center text-white rounded cardPreview">
      <span className=" text-white bold">{maskCardNumber(cardNumber)}</span>
      <span className=" text-white bold">{expiryDate}</span>
      <span className=" text-white bold">{name}</span>
      <button className="textPayement fs-6" onClick={onEditClick}>
        edit
      </button>
    </div>
  );
};

export default CardPreview;
