import React, { useEffect, useRef, useState } from "react";
import "../styles/MessagePage.css";
import "react-chat-elements/dist/main.css";
import AuthLayout from "../components/auth/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  DiscussionState,
  addDiscussionsMessage,
  getAvailableDiscussions,
  getDiscussionsMessages,
} from "../redux/slices/discussionSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { useUser } from "../hooks/UseUser";
import User from "../models/User";
import LatriceDiscussionMessages, {
  RestrictedAppointStatus,
} from "../components/chat/LatriceDiscussionMessage";
import ReactSimpleImageViewer from "react-simple-image-viewer";
import ChatUserList from "../components/chat/ChatUserList";
import ChatInterface from "../components/chat/ChatInterface";
import { EVENT_CODE } from "../socket/event";
import { socket } from "../socket/socket";
import { Message } from "../models/Discussion";
import { MessageBox } from "react-chat-elements";
import { IAppointment } from "../models/Appointment";
import MainNav from "../components/navigation/MainNav";
import {
  markAppointmentAsEnded,
  markAppointmentAsStarted,
} from "../redux/slices/appointmentSlice";
import toast from "react-hot-toast";
import {
  markCustomAppointmentAsEnded,
  markCustomAppointmentAsStarted,
} from "../redux/slices/customAppointmentSlice";
import Footer from "../components/Footer";
import MakeReviewModal from "../components/reusable/MakeReviewModal";

const MessagePage: React.FC = () => {
  const userData = useUser();
  const discussions = useSelector<unknown, DiscussionState>(
    (state: any) => state.discussions
  ).discussions;

  const dispatch = useDispatch();
  const socketIo = useRef(socket());
  // const [myAppoitment, setMyAppoitment] = useState<IAppointment>();
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [imageViewerIndex, setImageViewerIndex] = useState(0);
  // State to toggle recent chats visibility
  const [isVisible, setIsVisible] = useState(true);
  const [selectedDiscussion, setSelectedDiscussion] = useState<any | null>(
    null
  );
  const [q, setQ] = useState<string | null>(null);
  const [onLoadingDiscussionMessages, setOnLoadingDiscussionMessages] =
    useState(false);

  // const [makeReview, setMakeReview] = useState(false);

  // The item key will be the combination
  // of sender and receiver _id
  // and the value the list of discussion messages
  // const messages = useRef(new Map<string, Array<any>>());
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const handleOpenLatriceDiscussion = (discussionSystem: any) => {
    setSelectedDiscussion(null);
    const meta = {
      discussionId: "latrice-system-message",
      last_name: "",
      first_name: "Latrice",
      profile: require("../assets/images/latrice.png"),
      system: true,
      appointments: discussionSystem.customAppointments,
    };
    console.log("selection discussion data in message page", meta);

    setSelectedDiscussion({ meta });
    setOnLoadingDiscussionMessages(true);
  };

  const handleOpenDiscussion = (discussion: any) => {
    const chatWith = discussion.discussion.with;
    const discussionId = chatWith._id + "-" + discussion.discussion.you._id;
    const isUser = chatWith.user === null || chatWith.user === undefined;

    const meta = {
      discussionId,
      last_name: isUser
        ? `${chatWith.last_name}`
        : `${chatWith.user.last_name}`,
      first_name: isUser
        ? `${chatWith.first_name}`
        : `${chatWith.user.first_name}`,
      profile: isUser ? `${chatWith.profile}` : `${chatWith.user.profile}`,
      receiverId: chatWith._id as string,
    };

    console.log("selection discussion data in handleOpenDiscussion", meta);
    setSelectedDiscussion({ ...discussion, meta });
    setOnLoadingDiscussionMessages(true);
  };

  const handleCloseDiscussion = () => {
    setSelectedDiscussion(null);
    setSelectedUser(null);
  };

  const renderDiscussionListMessages = (discussion: any) => {
    const defaultDiscussionJSX = (
      <div
        style={{ height: "100%" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div className="alert alert-light user-select-none">
          <div className="text-muted">
            Start the chat with {selectedDiscussion.meta.first_name}
            <i className="fas fa-hand-paper smallHandRotation ms-1"></i>
          </div>
        </div>
      </div>
    );

    if (discussion.discussion.messages) {
      if (discussion.discussion.messages.length === 0) {
        return defaultDiscussionJSX;
      }

      const currentUserId = userData?._id;
      const messages = discussion.discussion.messages;

      return messages.map((message: Message) => {
        const currentUserIsSender = message.sender === currentUserId;

        return (
          <MessageBox
            key={message._id}
            id={message._id}
            position={!currentUserIsSender ? "left" : "right"}
            type={"text"}
            title={""}
            focus
            titleColor="#333"
            forwarded={false}
            replyButton={false}
            removeButton={false}
            status={message.status}
            retracted={false}
            notch={false}
            date={new Date(message.timestamp)}
            text={message.content}
          />
        );
      });
    }

    return defaultDiscussionJSX;
  };

  const renderLatriceDiscussionMessages = (selected: any) => {
    let appointments = selected.meta.appointments as (IAppointment & {
      status: RestrictedAppointStatus;
    })[];

    if (!selectedUser || !userData || appointments.length === 0) {
      return (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="alert alert-light user-select-none">
            <div className="text-muted">
              Select a profile on the top
              <i className="fas fa-hand-paper smallHandRotation ms-1"></i>
            </div>
          </div>
        </div>
      );
    } else if (selectedUser !== null && userData !== null) {
      appointments = appointments.filter((appointment: IAppointment) => {
        return [
          appointment.user._id,
          appointment.professional.user._id,
        ].includes(selectedUser._id);
      });
      console.log("just all appointments fo specifque user", appointments);

      return (
        <div>
          <>
            {appointments.map((appointment: any) => {
              return (
                <LatriceDiscussionMessages
                  userData={userData}
                  selectedUser={selectedUser}
                  appointment={appointment}
                />
              );
            })}
            {/* <LatriceDiscussionMessages
                userData={userData}
                selectedUser={selectedUser}
                appointment={appointments[0]}
              />;
              <LatriceDiscussionMessages
                userData={userData}
                selectedUser={selectedUser}
                appointment={appointments[1]}
              />; */}
          </>
        </div>
      );
    }
  };
  const handleSendMessage = (message: string) => {
    console.log("Here is the message i wanna send", message);
    console.log("receiver of my message", selectedDiscussion.meta.receiverId);
    const receiverId = selectedDiscussion.meta.receiverId;
    socket().emit(EVENT_CODE.CHAT_MESSAGE, {
      receiver: receiverId,
      content: message,
    });
  };

  // useEffect(() => {
  //   console.log("simple test");
  //   socket().on(EVENT_CODE.MAKE_REVIEW, (appointment) => {
  //     console.log(
  //       "here is the args after emitting the make review on backend",
  //       appointment
  //     );

  //     setMyAppoitment(appointment);

  //     setMakeReview(true);
  //   });
  // });

  const handleSocketMessageReceived = React.useCallback(
    (...args: any[]) => {
      const message = args[0] as Message;
      dispatch(addDiscussionsMessage(message) as unknown as AnyAction);
    },
    [dispatch]
  );

  const handleMarkAppointmentHasStarted = (appointment: IAppointment) => {
    console.log("handleMarkAppointmentHasStarted...");
    return ((appointment: IAppointment) => {
      const isCustomAppointment = Object.hasOwn(appointment, "photo");
      if (isCustomAppointment) {
        return dispatch(
          markCustomAppointmentAsStarted(
            appointment._id
          ) as unknown as AnyAction
        );
      } else {
        return dispatch(
          markAppointmentAsStarted(appointment._id) as unknown as AnyAction
        );
      }
    })(appointment)
      .unwrap()
      .then(() => {
        toast.remove();
        toast.success("Your appointment has been marked as started");
        return true;
      })
      .catch((err: any) => {
        toast.remove();
        toast.error("Something went wrong.");
        return false;
      });
  };

  const handleMarkAppointmentHasEnded = (appointment: IAppointment) => {
    return ((appointment: IAppointment) => {
      const isCustomAppointment = Object.hasOwn(appointment, "photo");
      if (isCustomAppointment) {
        return dispatch(
          markCustomAppointmentAsEnded(appointment._id) as unknown as AnyAction
        );
      } else {
        return dispatch(
          markAppointmentAsEnded(appointment._id) as unknown as AnyAction
        );
      }
    })(appointment)
      .unwrap()
      .then(() => {
        toast.remove();
        toast.success("Your appointment has been marked as ended");
        return true;
      })
      .catch((err: any) => {
        toast.remove();
        toast.error("Something went wrong.");
        return false;
      });
  };

  useEffect(() => {
    dispatch(getAvailableDiscussions() as unknown as AnyAction);
  }, []);

  useEffect(() => {
    if (selectedDiscussion) {
      const hasDiscussionMessages =
        selectedDiscussion?.discussion?.messages !== null;
      console.log("Here is hasDiscussionMessages", hasDiscussionMessages);

      if (hasDiscussionMessages) {
        // Other message will be added via socket
        setOnLoadingDiscussionMessages(false);
      } else {
        // get selected discussion's messages
        dispatch(getDiscussionsMessages() as unknown as AnyAction)
          .unwrap()
          .then((value: any) => {
            console.log(value);
          })
          .catch((err: any) => {
            console.error(err);
          })
          .finally(() => {
            setOnLoadingDiscussionMessages(false);
          });
      }
    }
  }, [selectedDiscussion]);

  useEffect(() => {
    if (selectedDiscussion) {
      if (selectedDiscussion.meta.system && discussions?.system) {
        const discussionSystem = { ...selectedDiscussion };
        discussionSystem.meta.appointments =
          discussions.system.customAppointments;
        setSelectedDiscussion(null);
        setSelectedDiscussion(discussionSystem);
      } else if (discussions?.user) {
        discussions.user.forEach((discussion) => {
          const withId = selectedDiscussion.discussion.with._id;
          const youId = selectedDiscussion.discussion.you._id;

          if (discussion.with._id === withId || discussion.with._id === youId) {
            setSelectedDiscussion({
              ...selectedDiscussion,
              discussion: {
                ...selectedDiscussion.discussion,
                messages: discussion.messages,
              },
            });
          }

          return discussion;
        });
      }
    }
  }, [discussions]);

  useEffect(() => {
    socket().on(EVENT_CODE.MESSAGE_RECEIVED, handleSocketMessageReceived);

    return () => {
      socket().disconnect();
    };
  }, [handleSocketMessageReceived]);
  useEffect(() => {
    console.log("nature of user", userData);
    console.log("all discussions data", discussions);
  }, []);

  return (
    <AuthLayout>
      <div className="MessagePage">
        <MainNav navType={"connectedNav"} isSearch={true} />
        <div
          style={{ height: "calc(85vh - 70px)" }}
          className="container p-lg-0"
        >
          <div className="MessageContainer gap-1 w-100 h-100">
            {/* Discussion users list */}
            <ChatUserList
              discussions={discussions}
              isVisible={isVisible}
              handleOpenLatriceDiscussion={handleOpenLatriceDiscussion}
              handleOpenDiscussion={handleOpenDiscussion}
              onLoadingDiscussionMessages={onLoadingDiscussionMessages}
              setQ={setQ}
            />

            {/* Discussion dialog */}
            <div
              style={{ height: "calc(85vh - 70px)" }}
              className={isVisible ? "chat" : "ChatContainer"}
            >
              <div
                className={`d-flex flex-column align-items-center ${
                  selectedDiscussion ? "" : "justify-content-center"
                }`}
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#f6f8fc",
                }}
              >
                <ChatInterface
                  selectedDiscussion={selectedDiscussion}
                  onLoadingDiscussionMessages={onLoadingDiscussionMessages}
                  handleCloseDiscussion={handleCloseDiscussion}
                  renderDiscussionListMessages={renderDiscussionListMessages}
                  renderLatriceDiscussionMessages={
                    renderLatriceDiscussionMessages
                  }
                  onSelectUser={(user) => {
                    setSelectedUser(user);
                  }}
                  currentUserIsProfessional={Object.hasOwn(
                    userData ?? {},
                    "salons"
                  )}
                  onSendMessage={handleSendMessage}
                  onMarkAppointmentHasStarted={handleMarkAppointmentHasStarted}
                  onMarkAppointmentHasEnded={handleMarkAppointmentHasEnded}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      {openImageViewer && selectedDiscussion && (
        <ReactSimpleImageViewer
          src={selectedDiscussion.meta.appointments.map((x: any) => x.photo)}
          currentIndex={imageViewerIndex}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setOpenImageViewer(false)}
        />
      )}
      {/* {makeReview && (
        <MakeReviewModal
          appointment={myAppoitment}
          show={makeReview}
          onClose={() => setMakeReview(false)}
          handleMakeReviewFunction={() => console.log("test")}
        />
      )} */}
    </AuthLayout>
  );
};

export default MessagePage;
