import { useEffect, useRef, useState } from "react";
import "./../../styles/SearchBar.css";
import Button from "../Button";
import { useSearchParams } from "react-router-dom";
import SearchDrawer from "../SearchDrawer";
import { ServiceService } from "../../services/ServiceService";
import ExtraFilter from "./ExtraFilter";
import Filters from "./Filters";
import GoogleAutocomplete, {
  GoogleAutocompleteLocation,
} from "./GoogleAutocomplete";
import { SalonService } from "../../services/SalonService";

export interface SearchValuesOptions {
  service: string | null;
  salon: string | null;
  where: string | null;
  lat: string | null;
  lng: string | null;
  price: string | null;
  account_type: string | null;
  drive_to_your_place: string | null;
  day: string | null;
  wheelchair_accessible: string | null;
  kids_service: string | null;
  kids_friendly: string | null;
  blowdry: string | null;
  hairwash: string | null;
  undo_hair: string | null;
  rating: string | null;
  bestMatch: string | null;
  [key: string]: string | null;
}

interface SearchBarOptions {
  showLoader?: boolean;
  onLoadStart?: () => void;
  onLoadEnd?: () => void;
  onSearch?: (value: SearchValuesOptions) => void;
  onChange?: (value: SearchValuesOptions) => void;
  location?: string;
  optionValue?: "Price" | "Location" | "Amenities";
}

const SearchBar: React.FC<SearchBarOptions> = ({
  showLoader = false,
  onLoadStart,
  onLoadEnd,
  onSearch,
  onChange,
  location,
  optionValue,
}) => {
  const dateRef = useRef<HTMLInputElement>(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [services, setServices] = useState<string[]>([]);
  const [salons, setSalons] = useState<string[]>([]);
  const [isSalon, setIsSalon] = useState(false);
  const [isService, setIsService] = useState(false);
  const [autoCompleteServices, setAutoCompleteServices] = useState<
    string[] | null
  >(null);
  const [autoCompleteSalons, setAutoCompleteSalons] = useState<string[] | null>(
    null
  );

  const [searchValues, setSearchValues] = useState<SearchValuesOptions>({
    service: searchParams.get("service"),
    salon: searchParams.get("salon"),
    where: searchParams.get("where"),
    lat: searchParams.get("lat"),
    lng: searchParams.get("lng"),
    price: searchParams.get("price"),
    account_type: searchParams.get("account_type"),
    drive_to_your_place: searchParams.get("drive_to_your_place"),
    day: searchParams.get("day"),
    wheelchair_accessible: searchParams.get("wheelchair_accessible"),
    kids_service: searchParams.get("kids_service"),
    kids_friendly: searchParams.get("kids_friendly"),
    free_parking_for_client: searchParams.get("free_parking_for_client"),
    blowdry: searchParams.get("blowdry"),
    hairwash: searchParams.get("hairwash"),
    undo_hair: searchParams.get("undo_hair"),
    rating: searchParams.get("rating"),
    bestMatch: searchParams.get("bestMatch"),
  });

  const handleChange = (e: any) => {
    const { name, type, value, checked } = e.target;

    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      [name]: type === "checkbox" ? (checked ? "true" : "false") : value,
    }));
  };

  const handleDataChange = (name: string, value: string | null) => {
    setSearchValues((prevSearchValues) => {
      const updatesData = {
        ...prevSearchValues,
        [name]: value,
      };
      if (onChange) onChange(updatesData);
      return updatesData;
    });
  };

  useEffect(() => {
    const searchObject = Object.fromEntries(searchParams.entries());
    setSearchValues((prevSearchValues) => ({
      ...prevSearchValues,
      ...searchObject,
    }));
  }, [searchParams]);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setSelectedDate(e.target.value);
  };

  const handleBestMatchChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchValues({ ...searchValues, bestMatch: e.target.value });
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onSearch && onSearch(searchValues);
    return false;
  };
  const detectSearchType = (value: string) => {
    if (!value) return null;
    const lowerValue = value.toLocaleLowerCase();
    const isService = services.some((service) =>
      service?.toLocaleLowerCase().includes(lowerValue)
    );
    const isSalon = salons.some((salon) =>
      salon?.toLocaleLowerCase().includes(lowerValue)
    );

    if (isService && !isSalon) return "service";
    if (!isService && !isSalon) return "salon";

    return null;
  };

  const handleLocationSelect = (location: GoogleAutocompleteLocation) => {
    setSearchValues((values: any) => {
      return {
        ...values,
        lat: location.latLng.lat,
        lng: location.latLng.lng,
        where: location.address,
      };
    });
  };

  const handleAutocompleteServices = (value: string) => {
    const searchedService = value;
    if (searchedService && searchedService.length > 0) {
      if (
        services
          .map((s) => s.toLocaleLowerCase())
          .includes(searchedService.toLocaleLowerCase())
      ) {
        setAutoCompleteServices(null);
      } else {
        const results: typeof services = services.filter((service: string) => {
          return service
            .toLocaleLowerCase()
            .includes(searchedService.toLocaleLowerCase());
        });

        setAutoCompleteServices(results);
        if (results) {
          setSearchValues((values) => ({ ...values, salon: "" }));
        }
        setIsService(true);
      }
    } else if (searchedService?.length === 0) {
      setAutoCompleteServices(null);
    }
  };

  const handleAutoCompleteSalon = (value: string) => {
    const searchedSalon = value;
    if (searchedSalon && searchedSalon.length > 0) {
      if (
        salons
          .map((salon) => salon.toLocaleLowerCase())
          .includes(searchedSalon.toLocaleLowerCase())
      ) {
        setAutoCompleteSalons(null);
      } else {
        const results: typeof salons = salons.filter((salon: string) => {
          return salon
            .toLocaleLowerCase()
            .includes(searchedSalon.toLocaleLowerCase());
        });

        setAutoCompleteSalons(results);
        if (results) {
          setSearchValues((values) => ({ ...values, service: "" }));
        }
        setIsSalon(true);
      }
    } else if (searchedSalon?.length === 0) {
      setAutoCompleteSalons(null);
    }
  };

  const handleComplete = (value: string) => {
    const searchType = detectSearchType(value);
    if (searchType === "service") {
      handleAutocompleteServices(value);
      setIsService(true);
      setIsSalon(false);
    } else if (searchType === "salon") {
      handleAutoCompleteSalon(value);
      setIsService(false);
      setIsSalon(true);
    } else {
      setIsService(false);
      setIsSalon(false);
    }
  };

  useEffect(() => {
    if (searchValues.service && searchValues.service !== "" && onLoadStart) {
      // onLoadStart();
    }
    if (searchValues.salon && searchValues.salon !== "" && onLoadStart) {
    }
    if (
      (searchValues.salon === null || searchValues.salon === "") &&
      onLoadEnd
    ) {
      onLoadEnd();
    }
    if (
      (searchValues.service === null || searchValues.service === "") &&
      onLoadEnd
    ) {
      onLoadEnd();
    }
  }, [onLoadEnd, onLoadStart, searchValues.service, searchValues.salon]);

  useEffect(() => {
    if (services.length === 0) {
      ServiceService.getAllServiceNames().then((names) => {
        if (Array.isArray(names)) {
          setServices(names);
        }
      });
    }
  }, [searchValues.service]);

  useEffect(() => {
    if (salons.length === 0) {
      SalonService.getSalonName().then((names) => {
        if (Array.isArray(names)) {
          setSalons(names);
        }
      });
    }
  }, [searchValues.salon]);

  useEffect(() => {
    if (onChange) onChange(searchValues);
  }, [searchValues]);

  useEffect(() => {});

  return (
    <div
      className={`SearchBar mx-md-3 mx-lg-0 rounded-lg-4  ${
        location === "navBar" ? "border border-white" : ""
      }`}
    >
      <div
        className={`${
          location === "searchPage" ? " SearchInput--light shadow" : " s"
        } d-md-flex p-1 p-lg-3 justify-content-between align-items-center SearchBarContainer d-none`}
      >
        <div
          className={
            "d-flex align-items-center searchEntries w-100" +
            (location === "searchPage" && " searchEntries_mobile")
          }
        >
          <div className={`SearchInput w-100`}>
            <div className="input-group align-items-center">
              <div className="d-flex align-items-center px-1">
                <i className="fa fa-search"></i>
              </div>

              <input
                value={searchValues.service ?? ""}
                onChange={(e) => {
                  handleAutocompleteServices(e.target.value);
                  handleChange(e);
                }}
                onBlur={() =>
                  setTimeout(() => setAutoCompleteServices(null), 200)
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e);
                    setAutoCompleteServices(null);
                  }
                }}
                type="text"
                className="form-control no-decoration  "
                placeholder="Service, Independent, Business..."
                name="service"
              />
            </div>
          </div>
          <div className="verticalSeparator"></div>
          <GoogleAutocomplete onSelectLocation={handleLocationSelect} />
        </div>

        <div className="SearchSelection d-flex w-100 justify-content-between align-items-center gap-3 ms-2">
          <input
            value={searchValues.price ?? ""}
            onChange={handleChange}
            type="text"
            className={`form-control bg-transparent rounded h-auto ${
              location === "navBar"
                ? "border-white text-white  "
                : "border_black text-black"
            }`}
            placeholder="Any price"
            name="price"
          />
          <select
            value={(searchValues.bestMatch ?? "").toLocaleLowerCase()}
            onChange={handleBestMatchChange}
            className={`form-select form-select-md text-black ${
              location === "navBar"
                ? "border-white text-white"
                : "border_black text-black"
            }`}
          >
            <option>Best Match</option>
            <option value="price">Price</option>
            <option value="location">Location</option>
            <option value="amenities">Amenities</option>
          </select>
          <input
            ref={dateRef}
            className={`text-black bg-transparent rounded form-control h-auto ${
              location === "navBar"
                ? "border-white text-white"
                : "border_black text-black"
            }`}
            type="date"
            name="day"
            id="date"
            onChange={handleDateChange}
          />
          <ExtraFilter
            searchValues={searchValues}
            setSearchValues={setSearchValues}
            handleChange={handleChange}
            location={location as string}
          />
          <Filters
            location={location as string}
            handleChange={handleChange}
            setSearchValues={setSearchValues}
            searchValues={searchValues}
          />
          <div className="d-flex align-items-center" style={{ height: "35px" }}>
            <Button
              onClick={handleSearch}
              className={`searchButton shadow border ${
                location === "searchPage"
                  ? "searchButton--dark"
                  : location === "homePage"
                  ? ""
                  : "btn-secondary"
              }`}
              content={<i className="fa fa-search fs-4"></i>}
            />
          </div>
        </div>

        {/* header of search page on mobile  */}

        {location !== "searchPage" && (
          <div
            className=" align-items-center mobile-btn"
            style={{ height: "35px" }}
          >
            <Button
              onClick={handleSearch}
              className={`searchButton shadow border ${
                location === "searchPage"
                  ? "bg-white"
                  : location === "homePage"
                  ? ""
                  : "btn-secondary"
              }`}
              content={<i className="fa fa-search fs-4"></i>}
            />
          </div>
        )}
      </div>

      {location === "searchPage" && (
        <div className="justify-content-between search_mobile_header w-100 mt-3">
          <button
            className="border-0 bg-transparent"
            onClick={() => (window.location.href = "/")}
          >
            <i className="fa-solid fa-angle-left text-black"></i>
          </button>
          <span>{searchValues.service ?? "Back to home"}</span>
          <div
            role="button"
            onClick={() => setIsOpen(true)}
            className="d-flex gap-1 align-items-center"
          >
            <i className="fa-solid fa-sliders text-black "></i>
            <span>Filters</span>
          </div>
        </div>
      )}

      {autoCompleteServices && autoCompleteServices.length > 0 && (
        <div className="SearchBar__loader border-top p-1 m-1">
          <div className="d-flex gap-2 mt-1">
            {autoCompleteServices.slice(0, 9).map((service) => {
              return (
                <div
                  key={service}
                  onClick={() => {
                    setSearchValues((values) => ({ ...values, service }));
                    setAutoCompleteServices(null);
                    setSearchValues((values) => ({ ...values, salon: "" }));
                  }}
                  className="text-center text-primary border-primary cursor-pointer rounded p-1 border text-dark"
                >
                  {service}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {autoCompleteSalons && autoCompleteSalons.length > 0 && (
        <div className="SearchBar__loader border-top p-1 m-1">
          <div className="d-flex gap-2 mt-1">
            {autoCompleteSalons.slice(0, 9).map((salon) => {
              return (
                <div
                  key={salon}
                  onClick={() => {
                    setSearchValues((value) => ({ ...value, salon }));
                    setAutoCompleteSalons(null);
                    setSearchValues((value) => ({ ...value, service: "" }));
                  }}
                  className="text-center text-primary border-primary cursor-pointer rounded p-1 border text-dark"
                >
                  {salon}
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div
        onClick={() => setIsOpen(true)}
        style={{ height: "45px" }}
        className="p-2 d-flex  d-lg-none w-100 bg-white align-items-center gap-2"
      >
        <i className="fa fa-search"></i>
        <div className="text-muted">
          Search services, independent, business...
        </div>
      </div>

      <SearchDrawer
        searchValues={searchValues}
        setSearchValues={setSearchValues}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleSearch={handleSearch}
      />
    </div>
  );
};

export default SearchBar;
