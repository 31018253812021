import { faPencil } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import WorkingTableElementItem from "./WorkingTableElementItem"
import { IAvailability } from "../../models/Availability"
import moment from "moment"
import { v4 as uuidv4 } from 'uuid';
import { IChair } from "../../models/Chair"
import { formatTime } from "../../utils/utils"
import { useSalon } from "../../context/SalonContext"
import NavigationLink from "../navigation/NavigationLink"
import { useUser } from "../../hooks/UseUser"
import { isUserBusinessOwner } from "../../models/User"
import { Moment } from 'moment'

type TWeekAvailability = [
    IAvailability | null,
    IAvailability | null,
    IAvailability | null,
    IAvailability | null,
    IAvailability | null,
    IAvailability | null,
    IAvailability | null
]

export type TWorkingTableElement = IChair & {
    shifts: TWeekAvailability,
    timeOffs: TWeekAvailability,
}


export type TWorkingTableElementProps = {
    element: TWorkingTableElement,
    dateOfWeek:Moment[],
    onAddShift: (element: TWorkingTableElement, dateKey: number) => void
    onAddTimeOff: (element: TWorkingTableElement, dateKey: number) => void

    onEditShift: (shift: IAvailability) => void
    onEditTimeOff: (timeOff: IAvailability) => void

    onDeleteShift: (shift: IAvailability) => void
    onDeleteTimeOff: (timeOff: IAvailability) => void
}


const WorkingTableElement: React.FC<TWorkingTableElementProps> = (
    { element,dateOfWeek, onAddShift, onEditShift, onAddTimeOff, onEditTimeOff, onDeleteShift, onDeleteTimeOff }
) => {

    const [totalHours, setTotalHours] = React.useState(0);

    const user = useUser();
    const salon = useSalon();


    React.useEffect(() => {
        const hours = element.shifts.reduce((prev, curr, key) => {
            if (curr) {
                const [shiftStartTime, shiftEndTime] = [
                    moment(curr.startTime, 'h:mm A'),
                    moment(curr.endTime, 'h:mm A')
                ];

                let time = shiftEndTime.diff(shiftStartTime, 'minutes');

                const timeOff = element.timeOffs[key];

                if (timeOff) {
                    const [timeOffStartTime, timeOffEndTime] = [
                        moment(timeOff.startTime, 'h:mm A'),
                        moment(timeOff.endTime, 'h:mm A')
                    ];
                    time -= timeOffEndTime.diff(timeOffStartTime, 'minutes')
                }

                time = time < 0 ? 0 : time
                return prev + time / 60
            }
            return prev;
        }, 0)
        setTotalHours(hours)
    }, [element])


    return <div className='working-table-element'>
        <div className='working-table-element__item user-select-none justify-content-start'>
            <div className='d-flex align-items-center justify-content-between w-100 gap-2 mx-2 my-1'>
                <div className='d-flex gap-2 align-items-center'>
                    <div className='working-table-element__item__img-container'>
                        <div>
                            {element.label.charAt(0)}
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div style={{ whiteSpace: 'nowrap' }} className='fw-500 text-black'>{element.label}</div>
                        <small className='text-muted'>
                            {totalHours === 0 ? "No shifts" : formatTime(totalHours * 60)}
                        </small>
                    </div>
                </div>
                <div className='ms-2'>
                    {isUserBusinessOwner(user) && <NavigationLink
                        to={`/setup-business/manage-chairs/${salon?.salon?._id}?action=edit&chair_id=${element._id}`}
                        label={
                            <div className='edit-icon ms-2'>
                                <FontAwesomeIcon icon={faPencil} />
                            </div>
                        }
                        className=""
                    />}
                </div>
            </div>
        </div>
        {
            element.shifts.map((shift, key) => {
                return <WorkingTableElementItem
                    key={uuidv4()}
                    shift={shift}
                    timeOff={element.timeOffs[key]}
                    element={element}
                    dateOfWeek={dateOfWeek[key]}
                    onAddShift={(element) => onAddShift(element, key)}
                    onEditShift={onEditShift}
                    onAddTimeOff={(element) => onAddTimeOff(element, key)}
                    onEditTimeOff={onEditTimeOff}
                    onDeleteShift={onDeleteShift}
                    onDeleteTimeOff={onDeleteTimeOff}
                />
            })
        }
    </div>
}

export default WorkingTableElement;