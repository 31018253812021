import { DiscussionSystemActionType, DiscussionSystemProps } from '../../LatriceDiscussionMessage'
import moment from 'moment'
import { Tooltip } from 'react-tooltip'
import { timeToTimezone, formatUSPrice } from '../../../../utils/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { AppointmentReassign } from '../../AppointmentReassigner'
import React from 'react'

export type PendingDiscussionBase = {
    appointmentInitiator?: boolean;
    onPendingAction?: (action: DiscussionSystemActionType) => void;
    actionContainer?: JSX.Element;
    alertContainer?: JSX.Element;
}

export const RejectedDiscussionBase = (props: DiscussionSystemProps & PendingDiscussionBase) => {

    const [onReassign, setOnReassign] = React.useState(false);


    return <div
        className={`w-75 LatricePendingAppointment LatricePendingAppointment- -disabled border`}>
        <div
            data-expiration-text={`This appointment has been rejected and it's no longer available !`}
            className="rce-mbox">
            <div className="rce-mbox-body">
                <div className="rce-mbox-forward rce-mbox-forward-right"></div>
                <div className="d-flex justify-content-between rce-mbox-title rce-mbox-title--clear">
                    <span className="text-uppercase">
                        You have a pending custom appointment
                    </span>
                    <Tooltip id='pending-custom-appointment-tooltip' />
                </div>
                <div className="rce-mbox-text left d-flex flex-column gap-1">
                    <img
                        style={{ width: '100px' }}
                        alt=""
                        className="img-fluid rounded"
                        src={props.appointment.photo}
                    />
                    <div className="d-flex align-items-center">
                        <h6 className="text-underline">
                            The service is like {props.appointment.serviceName}
                        </h6>
                        <span className="small">({props.appointment.size})</span>
                    </div>
                    <div className="d-flex gap-1 align-items-center w-100 fw-500 mb-2">
                        <div className="d-flex align-items-center gap-1">
                            <i className="fa fa-calendar" />
                            <span>
                                {moment(props.appointment.day, 'YYYY-MM-DD').format(
                                    'DD MMM YYYY'
                                )}
                            </span>
                            <span>{timeToTimezone(props.appointment.startTime)}</span>
                        </div>
                        <div className="ms-auto">
                            {formatUSPrice(props.appointment.price)}
                        </div>
                    </div>
                    <p>{props.appointment.description}</p>
                </div>
            </div>
            {
                // ALERT CONTAINER
                props.alertContainer
            }
            <div className='d-flex justify-content-between align-items-end'>
                {
                    // ACTION CONTAINER
                    props.actionContainer

                }
                <div
                    className="rce-mbox-time non-copiable ms-auto"
                    data-text={moment
                        .utc(props.appointment.createdAt)
                        .tz(moment.tz.guess())
                        .fromNow()}
                >
                    <span className="rce-mbox-status"></span>
                </div>
            </div>
            <div className="LatricePendingAppointment__Disable d-flex flex-column">
                {!onReassign && <div className='d-flex align-items-center gap-1 justify-content-center'>
                    <FontAwesomeIcon className='mb-1' icon={faTriangleExclamation} />
                    {`This appointment has been rejected and it's no longer available !`}
                </div>}
                {
                    !props.appointmentInitiator ? null :
                        <AppointmentReassign onReassign={(value:boolean) => setOnReassign(value)} className='mt-2' appointment={props.appointment} />
                }
            </div>
        </div>
    </div>
}