import MainNav from "../components/navigation/MainNav";
import "./../styles/LoginPage.css";
import "./../styles/RegisterProPage.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import RegisterProStep0 from "../components/form/register-pro/RegisterProStep0";
import RegisterProStep1 from "../components/form/register-pro/RegisterProStep1";
import { RegisterValues } from "../components/validation/RegisterValidation";
import RegisterProStep2 from "../components/form/register-pro/RegisterProStep2";
import RegisterProStep3 from "../components/form/register-pro/RegisterProStep3";
import { AuthService } from "../services/AuthService";
import { toast } from "react-hot-toast";
import { CONSTANTS } from "../utils/constants";
import { Line } from "../components/reusable/Line";
import StepIndicator from "../components/reusable/StepIndicator";
import StepList from "../components/reusable/StepList";
import RegisterProStep4 from "../components/form/register-pro/RegisterProStep4";
import { ISalon } from "../models/SalonX";
import { useDispatch, useSelector } from "react-redux";
import { addService } from "../redux/slices/serviceSlice";
import { AnyAction } from "@reduxjs/toolkit";
import {
  ServiceNameState,
  getServiceNames,
} from "../redux/slices/serviceNameSlice";
import { ServiceNameService } from "../services/ServiceNamesService";

const RegisterProPage = () => {
  const submitBtnRef = useRef();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<Partial<ISalon>>({
    accept_custom_orders: false,
    kids_service: false,
    kids_friendly: false,
    blow_dry: false,
    hair_wash: false,
    undo_hair: false,
    free_parking_for_client: false,
    drive_to_client: false,
  });

  const dispatch = useDispatch();

  const serviceNameData = useSelector<unknown, ServiceNameState>(
    (state: any) => state.serviceNames
  ).serviceNames;

  const [activeStep, setActiveStep] = useState(0);
  const [serviceDetailsNames, setServiceDetailsNames] = useState<any[]>([]);

  useEffect(() => {
    if (serviceNameData) {
      const detailsNames: any[] = [];
      serviceNameData.forEach((name: any) => {
        if (name?.servicesDetails) {
          detailsNames.push(...name.servicesDetails);
        }
      });
      console.log("all services names details", detailsNames);
      setServiceDetailsNames(detailsNames);
    }
  }, [serviceNameData]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.setAttribute("disabled", true);
    e.target.innerHTML = "loading...";

    const formData = new FormData();
    formData.append(
      "kids_service",
      formValues.kids_service as unknown as string
    );
    formData.append(
      "kids_friendly",
      formValues.kids_friendly as unknown as string
    );
    formData.append(
      "accept_custom_orders",
      formValues.accept_custom_orders as unknown as string
    );
    formData.append("blow_dry", formValues.blow_dry as unknown as string);
    formData.append("hair_wash", formValues.hair_wash as unknown as string);
    formData.append("undo_hair", formValues.undo_hair as unknown as string);
    formData.append(
      "free_parking_for_client",
      formValues.free_parking_for_client as unknown as string
    );
    formData.append(
      "drive_to_client",
      formValues.drive_to_client as unknown as string
    );

    if (formValues.blow_dry) {
      // console.log("res.data.salon.blow_dry value", res.data.salon.blow_dry);
      const newService = {
        name: "blow_dry",
        description: "blow_dry",
        service_detail: serviceDetailsNames[193]._id,
        service_categorie: serviceDetailsNames[193].service_categorie,
        price: 50,
        size: "",
        duration: 0,
        extra: true,
      };
      dispatch(addService(newService) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log("service i add ", res);
        })
        .catch((err: any) => {
          const errors = err.errors;
          const errorMessages = Object.values(errors).map((error: any) => (
            <div>{error}</div>
          ));

          return toast.error(<div>{errorMessages}</div>, {
            duration: 7000,
          });
        });
    }
    if (formValues.hair_wash) {
      const newService = {
        name: "hair_wash",
        description: "hair_wash",
        service_detail: serviceDetailsNames[194]._id,
        service_categorie: serviceDetailsNames[194].service_categorie,
        price: 50,
        size: "",
        duration: 0,
        extra: true,
      };

      dispatch(addService(newService) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log("service i add ", res);
        })
        .catch((err: any) => {
          const errors = err.errors;
          const errorMessages = Object.values(errors).map((error: any) => (
            <div>{error}</div>
          ));

          return toast.error(<div>{errorMessages}</div>, {
            duration: 7000,
          });
        });
    }
    if (formValues.undo_hair) {
      const newService = {
        name: "undo_hair",
        description: "undo_hair",
        service_detail: serviceDetailsNames[195]._id,
        service_categorie: serviceDetailsNames[195].service_categorie,
        price: 50,
        size: "",
        duration: 0,
        extra: true,
      };
      dispatch(addService(newService) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log("service i add ", res);
        })
        .catch((err: any) => {
          const errors = err.errors;
          const errorMessages = Object.values(errors).map((error: any) => (
            <div>{error}</div>
          ));

          return toast.error(<div>{errorMessages}</div>, {
            duration: 7000,
          });
        });
    }

    console.log("form data", formValues);
    AuthService.adminUpdateSalon(formValues)
      .then((res) => {
        e.target.innerHTML = "Done";
        toast.success("We have updated the provided information.");
        console.log("new salon data", res);
      })
      .catch((err) => {})
      .finally(() => {
        navigate("/business-profile");
      });
  };

  const nextStep = () => {
    setActiveStep(() => {
      let nextStep = activeStep + 1;
      return nextStep > CONSTANTS.REGISTER_PRO_STEPS.length - 1 ? 0 : nextStep;
    });
  };

  const prevStep = () => {
    setActiveStep(() => {
      let prevStep = activeStep - 1;
      return prevStep < 0 ? 0 : prevStep;
    });
  };

  const StepComponents = [
    RegisterProStep0,
    RegisterProStep1,
    RegisterProStep2,
    RegisterProStep3,
  ];

  const StepComponent = StepComponents[activeStep];

  return (
    <>
      <MainNav navType={"homeNav"} />
      <div className="row p-0 m-0 Login">
        <div className="container p-lg-0">
          <div className="d-flex flex-column justify-content-start align-items-between w-100 justify-content-md-between   my-3">
            <div>
              <h1 className="text-black d-inline">Step By Step </h1>
              <span className="small"> Please Fill In Your Details </span>
            </div>
            {activeStep === 0 && (
              <div className=" ms-md-auto ">
                <span>
                  <NavLink
                    className="nav-link nav-link-inline nav-link-underline-hover fw-500"
                    to={"/login"}
                  >
                    Already have an account?{" "}
                  </NavLink>
                </span>
              </div>
            )}
          </div>
          <Line className="p-0 m-0 mt-1 col-12 col-lg-10" />
        </div>

        <div className="container p-lg-0">
          <div className="d-flex w-100">
            <div className="justify-content-between flex-row p-0 m-0 d-none d-lg-flex col-3 col-md-4 col-lg-4 col-xl-3">
              <div className="d-flex flex-column align-items-center h-100">
                <StepList
                  activeStep={activeStep}
                  steps={CONSTANTS.REGISTER_PRO_STEPS}
                />
              </div>
              <StepIndicator
                activeStep={activeStep}
                steps={CONSTANTS.REGISTER_PRO_STEPS}
              />
            </div>

            <div className="d-flex justify-content-start align-items-center w-100 p-0 p-md-2">
              <div className="col-12 col-md-9 mt-2 col-lg-7">
                {activeStep === 4 ? (
                  <RegisterProStep4
                    onPrevStep={() => prevStep()}
                    submitBtnRef={submitBtnRef}
                    formValues={formValues}
                    handleSubmit={handleSubmit}
                    setFormValues={setFormValues}
                  />
                ) : (
                  <StepComponent
                    onNextStep={() => nextStep()}
                    onPrevStep={() => prevStep()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterProPage;

// salon
// :
// accept_custom_orders
// :
// false
// active
// :
// false
// address
// :
// "Bundesstraße 5 7, 10178 Berlin, Germany"
// blow_dry
// :
// true
// cover
// :
// "public/covers/cover_1712660444990.png"
// free_parking_for_client
// :
// false
// hair_wash
// :
// true
// kids_friendly
// :
// false
// kids_service
// :
// false
// location
// :
// {type: 'Point', coordinates: Array(2)}
// name
// :
// "Test Hair"
// number_shown
// :
// "salon_number"
// photos
// :
// []
// portfolio
// :
// []
// professional_id
// :
// "66151f9b5385b1804c85a68c"
// profile
// :
// "public/profiles/profile_1712660452491.png"
// service_radius
// :
// 1
// undo_hair
// :
// true
// wheelchair_accessible
// :
// false
// __v
// :
// 0
// _id
// :
// "66151f9c5385
