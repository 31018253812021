import React, { useState } from "react";
import { ListAppointmentModal } from "../appointment/ListAppointmentModal";
import Button from "../Button";
import "../styles/Cancellation.css";
import { formatUSPrice, toServiceLabel } from "../../utils/utils";
import moment from "moment";
import { faCalendarTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Service from "../../models/Service";
import { IAppointmentCancellation } from "../../models/AppointmentCancellation";
import ReBookModal from "../modal/ReBook";

export interface CancellationOptions {
  appointments: IAppointmentCancellation[];
}

const NoSelectedAppointment: React.FC = () => {
  return (
    <div className="text-center">
      <FontAwesomeIcon
        icon={faCalendarTimes}
        size="4x"
        className="text-secondary mb-3 text-muted"
      />
      <p className="lead text-muted">
        No appointment selected. Please select an appointment to view details.
        You can also take a new appointment with the button on the right panel.
      </p>
    </div>
  );
};

const Cancellation: React.FC<CancellationOptions> = ({ appointments }) => {
  const [selected, setSelected] = useState<IAppointmentCancellation | null>(
    null
  );
  const [openRebookModal, setOpenRebookModal] = useState(false);

  const handleSelect = (value: boolean, key: number) => {};

  const handleSelectAll = () => {};

  const toServiceDetails = (appointment: IAppointmentCancellation) => {};

  const formatTime = (time: string): string => {
    return moment(time, "HH:mm").format("h:mm A");
  };

  const formatAppointmentDescription = (
    appointmentCancellation: IAppointmentCancellation
  ): JSX.Element => {
    const {
      appointment,
      salon,
      cancelledBy,
      cancellationDate,
      amountRetained,
      latriceShare,
      optionChosen,
      newDateProposed,
    } = appointmentCancellation;
    const { service, day, startTime, endTime, status, price } = appointment;

    const services = service
      .map((s: Service) => s.service_detail.name)
      .join(", ");
    const formattedStartTime = formatTime(startTime);
    const formattedEndTime = formatTime(endTime);
    const formattedDate = moment(day).format("dddd, MMMM D, YYYY");

    return (
      <p>
        Your appointment with {salon.name} on {formattedDate} has been canceled.
        The appointment was scheduled from {formattedStartTime} to{" "}
        {formattedEndTime}. You had selected the following services: {services}.
        The total price for the appointment was ${price}. If you have any
        questions or need further assistance, please contact us.
      </p>
    );
  };

  const handleRebook = (e: any) => {
    setOpenRebookModal(selected !== null);
  };

  return (
    <div className="Cancellation w-100">
      <div
        className={
          " col col-lg-7 mx-lg-auto appointment_details" +
          (selected ? "" : " appointment_list")
        }
      >
        <div>
          <div onClick={() => setSelected(null)} className={"back"}>
            <i className="fa-solid fa-arrow-left"></i>
          </div>
          {selected !== null ? (
            <>
              <h3 className="text-primary  appointment_list">Detail</h3>
              <h5 className="appointment_list">
                More details on the cancelled appointment select
              </h5>
              <div className="mt-5 d-flex flex-column gap-2">
                <h4>{toServiceLabel(selected.appointment, false)}</h4>
                <p>{formatAppointmentDescription(selected)}</p>
                <div className="d-flex justify-content-between align-items-end">
                  <div className="d-flex justify-content-between align-items-end gap-2">
                    <div
                      className="bg-white shadow d-flex align-items-center"
                      style={{
                        padding: ".2em",
                        width: "80px",
                        borderRadius: "100%",
                      }}
                    >
                      <img
                        style={{ width: "80px", borderRadius: "100%" }}
                        className="img-fluid"
                        src={
                          selected.appointment.chairId.salonId.profile ??
                          require("../../assets/images/profile.jpg")
                        }
                        alt={selected.appointment.chairId.salonId.name}
                      />
                    </div>
                    <div className="d-flex flex-column detail_text">
                      <div className="fw-bold">
                        {selected.appointment.chairId.salonId.name}
                      </div>
                      <div className="d-flex justify-content-between gap-2 fw-bold">
                        <div>
                          {formatTime(selected.appointment.startTime)}-{" "}
                          {formatTime(selected.appointment.endTime)}
                        </div>
                        <div>{formatUSPrice(selected.appointment.price)}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={handleRebook}
                      mode={"primary"}
                      content={"Re-Book"}
                      className="br-6 detail_btn"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between p-0 m-0 mt-4">
                  <Button
                    style={{
                      border: "1px solid var(--primaryColor)",
                      color: "#333",
                    }}
                    className="detail_btn detail_text"
                    mode={"outlined"}
                    content={"Report this service"}
                  />
                  <Button
                    className="detail_btn detail_text"
                    mode={"primary"}
                    content={"Promote Profile"}
                  />
                </div>
              </div>
            </>
          ) : (
            <div
              style={{ height: "50vh" }}
              className="d-flex user-select-none justify-content-center align-items-center text-muted"
            >
              <NoSelectedAppointment />
            </div>
          )}
        </div>
      </div>
      <div
        className={
          selected ? "appointment_list" : "appointment_details_container"
        }
      >
        <ListAppointmentModal
          appointments={appointments ?? []}
          onSelect={setSelected}
        />
      </div>
      {openRebookModal && selected && (
        <ReBookModal
          onClose={() => {
            setOpenRebookModal(false);
          }}
          salon={selected.appointment.chairId.salonId}
          open={openRebookModal}
          backdrop={openRebookModal}
          appointmentCancellation={selected}
          style={{ height: "100vh !important" }}
          onBook={() => {}}
        />
      )}
    </div>
  );
};

export default Cancellation;
