import './../styles/CertificationBadge.css';

const CertificationBadge = () => {
    return (
        <div className="position-relative">
            <i className="fa-solid fa-circle-check" style={{color:'var(--primaryColor)'}}></i>
        </div>
    );
};

export default CertificationBadge;
