import './../styles/Stepper.css'

const Stepper = ({ steps, active = 0, primaryColor = null, accentColor = null, activeColor = null, className }) => {

    return <div className={"Stepper w-100 user-select-none " + className}>
        <div className='StepperContainer w-100 d-flex'>
            {
                steps.map((step, key) => {
                    return <div key={step} className={'step d-flex flex-column w-100 ' + (key === active ? 'active' : '')}>
                        <div className={'stepArea d-flex justify-content-center align-items-center w-100 '}>
                            <div className='stepAreaIndicator'><span>{key + 1}</span></div>
                        </div>
                        <div className='stepLabel mt-3 text-uppercase'>{step}</div>
                    </div>
                })
            }
        </div>
    </div>
}

export default Stepper;