import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useEffect, useState } from "react";
import styles from './styles/CancelHandler.module.css';
import { Modal, Table } from "react-bootstrap";
import { IAppointment } from "../models/Appointment";
import SlidePicker from "./SlidePicker";
import Service from "../models/Service";
import { formatUSPrice } from "../utils/utils";
import moment from "moment";
import { Tooltip } from "react-tooltip";

export interface CancelHandlerOptions {
    child: ReactNode,
    onChangeState?: (state: CancelHandlerState) => any,
    cancel: any,
    appointment?: IAppointment
}

export type CancelHandlerState = 'default' | 'ask' | 'cancelling' | 'canceled';

const CancelHandler: React.FC<CancelHandlerOptions> = ({ child, cancel, onChangeState }) => {
    const [state, setState] = useState<CancelHandlerState>('default');

    useEffect(() => {
        if (onChangeState) onChangeState(state);

    }, [state, cancel, onChangeState])

    const handleCancel = () => {
        setState('cancelling');
        cancel().then(() => setState('canceled'))
            .catch((err: any) => {
                setState('default');
            })
    }


    return <div className={`${styles.CancelHandler}`}>
        {state === 'default' && <div onClick={(e) => setState('ask')}>{child}</div>}
        {state === 'ask' &&
            <div className="d-flex gap-3">
                <FontAwesomeIcon className="cancelHandler" title="cancel" onClick={(e) => setState('default')} icon={faTimes} />
                <FontAwesomeIcon className="cancelHandler" title="confirm" onClick={(e) => handleCancel()} icon={faCheck} />
            </div>
        }
        {state === 'cancelling' &&
            <div>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-primary spinner-border-sm"
                        role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        }
    </div>
}

const CancelHandlerModal: React.FC<CancelHandlerOptions> = ({ child, cancel, onChangeState }) => {
    const [state, setState] = useState<CancelHandlerState>('default');
    const [modalOpen, setModalOpen] = useState(false);
    const [cancelChoice, setCancelChoice] = useState<'CANCEL_THE_APPOINTMENT' | 'LET_LATRICE_SEARCH_FOR_YOU' | null>(null);

    useEffect(() => {
        if (onChangeState) onChangeState(state);

    }, [state, cancel, onChangeState])

    const __handleCancel = () => {
        setState('cancelling');
        cancel().then(() => setState('canceled'))
            .catch((err: any) => {
                setState('default');
            })
    }

    const handleCancel = () => {
        setState('cancelling');
        setModalOpen(true);
    }

    const handleHideModal = () => {
        setModalOpen(false);
        setState('default');
    }


    return <div className={`${styles.CancelHandler}`}>
        {state === 'default' && <div onClick={(e) => setState('ask')}>{child}</div>}
        {state === 'ask' &&
            <div className="d-flex gap-3">
                <FontAwesomeIcon className="cancelHandler" title="cancel" onClick={(e) => setState('default')} icon={faTimes} />
                <FontAwesomeIcon className="cancelHandler" title="confirm" onClick={(e) => handleCancel()} icon={faCheck} />
            </div>
        }
        {state === 'cancelling' &&
            <div>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-primary spinner-border-sm"
                        role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        }

        <Modal show={modalOpen} centered backdrop='static' onHide={handleHideModal}>
            <Modal.Header closeButton>
                <div className="d-flex p-0 m-0 w-100 gap-2 align-items-center justify-content-start">
                    <i className="fa fa-circle-exclamation fa-2x"></i>
                    <div className="fs-4">Cancellation</div>
                </div>
            </Modal.Header>
            <Modal.Body className="">
                <div className="mx-3 mt-3 d-flex flex-column gap-2 mb-3">
                    <h5>Choose your cancellation decision</h5>
                    <div className="d-flex w-100 justify-content-between">
                        <button onClick={(e) => { setCancelChoice('CANCEL_THE_APPOINTMENT') }}
                            className={`btn mx-1 cancel-btn text-uppercase ${cancelChoice === 'CANCEL_THE_APPOINTMENT' ? 'active' : ''}`}>
                            cancel the appointment
                        </button>
                        <button onClick={(e) => { setCancelChoice('LET_LATRICE_SEARCH_FOR_YOU') }}
                            className={`btn mx-1 cancel-btn text-uppercase ${cancelChoice === 'LET_LATRICE_SEARCH_FOR_YOU' ? 'active' : ''}`} >
                            let latrice search for me
                        </button>
                    </div>
                    <div className={`cancelOptions ${cancelChoice === 'CANCEL_THE_APPOINTMENT' ? 'cancelOptions--open' : ''}`}>
                        <div className={`p-2 m-0`}>
                            <div className="mb-3">
                                <label htmlFor="" className="form-label">Select a new appointment date ? (optional)</label>
                                <input type="date"
                                    className="form-control" name="" id="" placeholder="" />
                                <small id="helpId" className="form-text text-muted">this date will be </small>
                            </div>
                            <button type="submit" style={{ height: '40px' }} className="btn btn-primary w-100">validate</button>
                        </div>
                    </div>

                    <div className={`cancelOptions ${cancelChoice === 'LET_LATRICE_SEARCH_FOR_YOU' ? 'cancelOptions--open' : ''}`}>
                        <div className={`p-2 m-0`}>
                            <div className="mb-3">
                                Latrice will search best result for you !
                            </div>
                            <button type="submit" style={{ height: '40px' }} className="btn btn-primary w-100">validate</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </div >
}

export  const toRemainingTime = (appointment: IAppointment, check24Hours = false): string | boolean => {
    const startTime = moment.utc(`${appointment.day} ${appointment.startTime}`, 'YYYY-MM-DD HH:mm').tz(moment.tz.guess());
    const remainingTime = startTime.diff(moment(), 'minutes');

    if (remainingTime <= 0) {
        return "Appointment has started";
    }
    if (check24Hours) return remainingTime / 60 >= 24;

    return moment.duration(remainingTime, 'minutes').humanize(false);
};

const AppointmentCancelHandlerModalDialog: React.FC<CancelHandlerOptions> = ({ child, cancel, appointment, onChangeState }) => {
    const [state, setState] = useState<CancelHandlerState>('default');
    const [modalOpen, setModalOpen] = useState(false);

    const __handleCancel = (e: any) => {
        setState('cancelling');
        cancel().then(() => setState('canceled'))
            .catch((err: any) => {
                setState('default');
            }).finally(() => setModalOpen(false))
    }

    const handleCancel = () => {
        setModalOpen(true);
    }

    const handleHideModal = () => {
        setModalOpen(false);
        setState('default');
    }


    useEffect(() => {
        if (onChangeState) onChangeState(state);

    }, [state, cancel, onChangeState])
    

    return <div className={`${styles.CancelHandler}`}>
        {state === 'default' && <div onClick={(e) => setState('ask')}>{child}</div>}
        {state === 'ask' &&
            <div className="d-flex gap-3">
                <FontAwesomeIcon className="cancelHandler" title="cancel" onClick={(e) => setState('default')} icon={faTimes} />
                <FontAwesomeIcon className="cancelHandler" title="confirm" onClick={(e) => handleCancel()} icon={faCheck} />
            </div>
        }
        {state === 'cancelling' &&
            <div>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-primary spinner-border-sm"
                        role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        }

        <Modal show={modalOpen} centered backdrop='static' onHide={handleHideModal}>
            <Modal.Header closeButton>
                <div className="d-flex p-0 m-0 w-100 gap-2 align-items-center justify-content-start">
                    <i className="fa fa-circle-exclamation fa-2x"></i>
                    <div className="fs-4">Cancellation</div>
                </div>
            </Modal.Header>
            <Modal.Body className="">
                {appointment && appointment.service && <div className="mx-3 mt-3 d-flex flex-column gap-2 mb-3">
                    <h5>Services({appointment.service.length})</h5>
                    <div>
                        <SlidePicker
                            data={appointment.service}
                            renderItem={(service: Service) => {
                                return <div className="CancelServiceItem text-center rounded p-2">
                                    <div>{service.service_detail.name} {formatUSPrice(service.price)}</div>
                                </div>
                            }}
                            keyExtractor={(service: Service) => service._id as string}
                            separator={<span className="mx-2"></span>}
                            maxShow={appointment.service.length - 1}
                        />
                    </div>
                    <div>
                        <Table bordered responsive>
                            <thead>
                                <tr className="text-uppercase">
                                    <th>Cost</th>
                                    <th>Fee</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-uppercase">
                                    <td>{formatUSPrice(appointment.service.reduce((prev, curr) => prev + curr.price, 0))}</td>
                                    <td>{formatUSPrice(appointment.service.reduce((prev, curr) => prev + curr.price, 0) - appointment.price)}</td>
                                    <td>{formatUSPrice(appointment.price)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div>
                        <Table bordered responsive>
                            <thead>
                                <tr className="text-uppercase">
                                    <th>Time remaining</th>
                                    <th>Service</th>
                                    <th>Service Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="text-uppercase">
                                    <td>
                                        {toRemainingTime(appointment)}
                                    </td>
                                    <td>{toRemainingTime(appointment, true) ? formatUSPrice(0) : formatUSPrice(appointment.price * 22.5 / 100)}</td>
                                    <td>{formatUSPrice(appointment.price * 2.5 / 100)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="text-uppercase">
                        <i className="fa fa-triangle-exclamation"></i>
                        <span> total cancellation fee =</span>
                        <span> {toRemainingTime(appointment, true) ? formatUSPrice(0) : formatUSPrice(appointment.price * 22.5 / 100)} </span>
                        <span>+</span>
                        <span> {formatUSPrice(appointment.price * 2.5 / 100)}  =</span>
                        <span
                            style={{ cursor: 'pointer' }}
                            data-tooltip-id="cancelTooltip"
                            data-tooltip-place="top"
                            data-for="cancelTooltip"
                            className="text-primary fw-bold text-underline">
                            {formatUSPrice((toRemainingTime(appointment, true) ? 0 : appointment.price * 22.5 / 100) + appointment.price * 2.5 / 100)}
                        </span>
                    </div>
                    <div className={`cancelOptions cancelOptions--open`}>
                        <button disabled={state === 'cancelling'} onClick={__handleCancel} type="submit" style={{ height: '40px' }} className="btn btn-primary w-100">validate</button>
                    </div>
                </div>}

                <Tooltip id="cancelTooltip">
                    <p className="text-center">
                        This fee will be removed from the previous appointment's total<br /> price and
                        Latrice will return you the remaining.
                    </p>
                </Tooltip>
            </Modal.Body>
        </Modal>
    </div >
}

export {
    CancelHandler,
    CancelHandlerModal,
    AppointmentCancelHandlerModalDialog
}