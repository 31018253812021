import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Notification } from "../../models/Notification";
import { NotificationService } from "../../services/NotificationService";

export interface NotificationState {
  notifications: Notification[] | null;
  unReadNotifications: number | null;
}

const initialState: NotificationState = {
  notifications: null,
  unReadNotifications: null,
};

export const markAsRead = createAsyncThunk<Notification, any>(
  "notifications/markAsRead",
  async (notificationId) => {
    const res = await NotificationService.markAsRead(notificationId);
    return res;
  }
);

export const getNotifications = createAsyncThunk<any[], any>(
  "notifications/getNotifications",
  async () => {
    const res = await NotificationService.getNotifications();
    return res;
  }
);

export const receiveSocketNotification = createAsyncThunk<
  Notification,
  Notification
>("notifications/receiveSocketNotification", async (notification) => {
  return notification;
});

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.fulfilled, (state, action) => {
        if (action.payload) {
          state.notifications = action.payload;

          const unRead = action.payload.filter(
            (notification: any) => !notification.isRead
          );
          state.unReadNotifications = unRead.length;
        }
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        if (state.notifications && state.unReadNotifications) {
          // Find the index of the notification to update in the array
          const index = state.notifications.findIndex(
            (notification) => notification._id === action.payload._id
          );

          // If the notification is found in the array, update it
          if (index !== -1) {
            // Create a new array with the updated notification
            state.notifications = [
              ...state.notifications.slice(0, index),
              action.payload, // Replace the old notification with the updated one
              ...state.notifications.slice(index + 1),
            ];
          }

          state.unReadNotifications = state.unReadNotifications - 1;
        }
      })
      .addCase(receiveSocketNotification.fulfilled, (state, action) => {
        const newNotification = action.payload;
        const t = state.notifications?.filter(
          (x) => x._id === newNotification._id
        );
        if (t && t.length === 0) {
          // Check if t is defined and has a length of 0
          const notifications = [
            action.payload,
            ...(state.notifications ?? []),
          ]; // Add it at the beginning of the array

          const unRead = notifications.filter((n) => !n.isRead).length;
          state.unReadNotifications = unRead;
          state.notifications = notifications;
        }
      });
  },
});

export default notificationSlice.reducer;
