import React, { useState, useEffect, useRef } from "react";
import { ISalon } from "../../../models/SalonX";
import { InputBoolean } from "../../input";
import { useDispatch, useSelector } from "react-redux";
import { AddSalon } from "../../../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { ManagerState } from "../../../redux/slices/managerSlice";
import ModalLoader from "../../loader/ModalLoader";

type CreateSalonStep4Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  salonValues: Partial<ISalon>;
  setSalonValues: (values: Partial<ISalon>) => void;
};

const CreateSalonStep4: React.FC<CreateSalonStep4Props> = ({
  onChange,
  salonValues,
  setSalonValues,
}) => {
  return (
    <form className="w-100 p-3 ps-4 mt-3">
      <span className="mb-4">Step 4/4</span>
      <h3 className="text-black mb-3">Add the services you propose</h3>
      <span className=" mb-3">
        Please let us know what type of business describes you
      </span>
      <hr />
      <div
        className="flex-column gap-1 rounded salon-input w-100 mb-5 "
        aria-autocomplete="none"
      >
        <div className="d-flex flex-wrap justify-content-between w-100">
          <InputBoolean
            title={
              <span className="text-primary">
                WheelChair accessible? *{" "}
                <i className="fa-solid text-muted fa-wheelchair-move"></i>
              </span>
            }
            name={"wheelchair_accessible"}
            onChange={(val: boolean | null) =>
              setSalonValues({
                ...salonValues,
                wheelchair_accessible: val ?? false,
              })
            }
            value={salonValues.wheelchair_accessible}
          />
          <InputBoolean
            title={
              <span className="text-primary">
                Accept custom orders? *{" "}
                <i className="fa-regular text-muted fa-calendar-check"></i>
              </span>
            }
            onChange={(val: boolean | null) =>
              setSalonValues({
                ...salonValues,
                accept_custom_orders: val ?? false,
              })
            }
            name={"accept_custom_orders"}
            value={salonValues.accept_custom_orders}
          />
          <InputBoolean
            title={
              <span className="text-primary">
                Kids friendly? *{" "}
                <i className="fa-solid text-muted fa-child-reaching"></i>
              </span>
            }
            onChange={(val: boolean | null) =>
              setSalonValues({
                ...salonValues,
                kids_friendly: val ?? false,
              })
            }
            name={"kids_friendly"}
            value={salonValues.kids_friendly}
          />
        </div>
        <div className="d-flex flex-wrap justify-content-between w-100 align-items-center mt-4">
          <InputBoolean
            title={
              <span className="text-primary">
                Kids service? *{" "}
                <i className="fa-solid text-muted fa-children"></i>
              </span>
            }
            onChange={(val: boolean | null) =>
              setSalonValues({
                ...salonValues,
                kids_service: val ?? false,
              })
            }
            name={"kids_service"}
            value={salonValues.kids_service}
          />
          <div className="d-flex gap-2">
            <label className="list-group-item">
              <input
                className="form-check-input me-1"
                type="checkbox"
                name="blow_dry"
                onChange={onChange}
                checked={salonValues.blow_dry}
              />
              Blowdry
            </label>
            <label className="list-group-item">
              <input
                className="form-check-input me-1"
                type="checkbox"
                name="hair_wash"
                onChange={onChange}
                checked={salonValues.hair_wash}
              />
              Hairwash
            </label>
            <label className="list-group-item">
              <input
                className="form-check-input me-1"
                type="checkbox"
                name="undo_hair"
                onChange={onChange}
                checked={salonValues.undo_hair}
              />
              Undo hair
            </label>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateSalonStep4;
