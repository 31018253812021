import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { log } from "../../utils/utils";
import Service from "../../models/Service";
import { ServiceNameService } from "../../services/ServiceNamesService";
import { addServiceDetail } from "./serviceSlice";

export interface ServiceNameState {
  serviceNames: Service[] | null;
}

const initialState: ServiceNameState = {
  serviceNames: null,
};

export const getServiceNames = createAsyncThunk<Service[], string>(
  "serviceNames/list",
  async () => {
    const res = await ServiceNameService.getServiceNames();
    console.log("response after getting serviceName data", res.data);
    return res.data;
  }
);

const serviceNamesSlice = createSlice({
  name: "serviceNames",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServiceNames.fulfilled, (state, action) => {
        state.serviceNames = action.payload;
      })
      .addCase(addServiceDetail.fulfilled, (state, action) => {
        if (state.serviceNames) {
          const serviceNames = state.serviceNames.slice();
          serviceNames.push(action.payload as Service);
          state.serviceNames = serviceNames;
        } else {
          state.serviceNames = [action.payload as Service];
        }
      });
  },
});

export default serviceNamesSlice.reducer;
