import axios from "axios";
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;



const AddContact = (data:any) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}contact/create`,
    method: "post",
    data: {
      ...data,
    },
  }).then((data) => {
    return data;
  });
};


export const ContactService = {
    AddContact
};
