import React, { useState } from "react";
import { ListAppointmentModal } from "../appointment/ListAppointmentModal";
import Button from "../Button";
import "../styles/Cancellation.css";
import { formatUSPrice, toServiceLabel } from "../../utils/utils";
import moment from "moment";
import {
  faCalendarTimes,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Service from "../../models/Service";
import { IAppointmentCancellation } from "../../models/AppointmentCancellation";
import ReBookModal from "../modal/ReBook";
import ModalLoader from "../loader/ModalLoader";
import { useDispatch } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import {
  acceptAppointmentCancellation,
  cancelAppointmentCancellation,
} from "../../redux/slices/appointmentCancellationSlice";
import { toast } from "react-hot-toast";

export interface CancellationOptions {
  appointments: IAppointmentCancellation[];
}

const NoSelectedAppointment: React.FC = () => {
  return (
    <div className="text-center">
      <FontAwesomeIcon
        icon={faCalendarTimes}
        size="4x"
        className="text-secondary mb-3 text-muted"
      />
      <p className="lead text-muted">
        No appointment selected. Please select an appointment to view details.
        You can also take a new appointment with the button on the right panel.
      </p>
    </div>
  );
};

const Dispute: React.FC<CancellationOptions> = ({ appointments }) => {
  const [selected, setSelected] = useState<IAppointmentCancellation | null>(
    null
  );

  console.log("appointments",appointments)
  const [openRebookModal, setOpenRebookModal] = useState(false);
  const [onLoading, setOnLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSelect = (value: boolean, key: number) => {};

  const handleSelectAll = () => {};

  const formatTime = (time: string): string => {
    return moment(time, "HH:mm").format("h:mm A");
  };

  const formatAppointmentDescription = (
    appointmentCancellation: IAppointmentCancellation
  ): JSX.Element => {
    const {
      appointment,
      cancelledBy,
      cancellationDate,
      amountRetained,
      latriceShare,
      optionChosen,
      newDateProposed,
    } = appointmentCancellation;
    const { user, service, day, startTime, endTime, status, price } =
      appointment;

    const services = service
      .map((s: Service) => s.service_detail.name)
      .join(", ");
    const formattedStartTime = formatTime(startTime);
    const formattedEndTime = formatTime(endTime);
    const formattedDate = moment(day).format("dddd, MMMM D, YYYY");

    return (
      <div className="alert alert-warning">
        <h2>
          <FontAwesomeIcon icon={faTriangleExclamation} size="1x" /> Dispute
        </h2>
        <p>
          Your appointment with {user.first_name}{" "}
          {user.last_name} on {formattedDate} has been canceled.
          The appointment was scheduled from {formattedStartTime} to{" "}
          {formattedEndTime}. You had selected the following services:{" "}
          {services}. The total price for the appointment was ${price}.
        </p>

        <p className="alert alert-light">
          <span>
            {" "}
            New date proposed :{" "}
            <span className="fw-bold">
              {moment(
                `${newDateProposed.day} ${newDateProposed.startTime}`,
                "YYYY-MM-DD HH:mm"
              ).format("DD MMMM YYYY at HH:mm A")}
            </span>
          </span>
        </p>
      </div>
    );
  };

  const handleRebook = (e: any) => {
    setOpenRebookModal(selected !== null);
  };

  const handleDecline = () => {
    setOnLoading(true);
    dispatch(
      cancelAppointmentCancellation(
        selected as IAppointmentCancellation
      ) as unknown as AnyAction
    )
      .unwrap()
      .then((res: any) => {
        setSelected(null);
        return toast.success("cancellation done.");
      })
      .catch((err: any) => {
        if (err.errors && err.errors.message)
          return toast.error(err.errors.message);
        return toast.error("Something went wrong, please try again.");
      })
      .finally(() => setOnLoading(false));
  };

  const handleAccept = () => {
    setOnLoading(true);
    dispatch(
      acceptAppointmentCancellation(
        selected as IAppointmentCancellation
      ) as unknown as AnyAction
    )
      .unwrap()
      .then((res: any) => {
        setSelected(null);
        return toast.success("You have approved the new date.");
      })
      .catch((err: any) => {
        if (err.errors && err.errors.message)
          return toast.error(err.errors.message);
        return toast.error("Something went wrong, please try again.");
      })
      .finally(() => setOnLoading(false));
  };

  return (
    <>
      <ModalLoader show={onLoading} />
      <div className="Cancellation w-100">
        <div
          className={
            " col col-lg-7 mx-lg-auto appointment_details" +
            (selected ? "" : " appointment_list")
          }
        >
          <div>
            <div onClick={() => setSelected(null)} className={"back"}>
              <i className="fa-solid fa-arrow-left"></i>
            </div>
            {selected !== null ? (
              <>
                {/* <h3 className="text-primary  appointment_list">Detail</h3>
              <h5 className="appointment_list">
                More details on the cancelled appointment select
              </h5> */}
                <div className="mt-5 d-flex flex-column gap-2">
                  <h4>{toServiceLabel(selected.appointment, false)}</h4>
                  <p>{formatAppointmentDescription(selected)}</p>
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex justify-content-between align-items-end gap-2">
                      <div
                        className="bg-white shadow d-flex align-items-center"
                        style={{
                          padding: ".2em",
                          width: "80px",
                          borderRadius: "100%",
                        }}
                      >
                        <img
                          style={{ width: "80px", borderRadius: "100%" }}
                          className="img-fluid"
                          src={
                            selected.appointment.user.profile ??
                            require("../../assets/images/profile.jpg")
                          }
                          alt={
                            selected.appointment.user.first_name
                          }
                        />
                      </div>
                      <div className="d-flex flex-column detail_text">
                        <div className="fw-bold">
                          {selected.appointment.professional?.user?.first_name}{" "}
                          {selected.professional?.user?.last_name}
                        </div>
                        <div className="d-flex justify-content-between gap-2 fw-bold">
                          <div>
                            {formatTime(selected.appointment.startTime)}-{" "}
                            {formatTime(selected.appointment.endTime)}
                          </div>
                          <div>{formatUSPrice(selected.appointment.price)}</div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                      <Button
                        disabled={onLoading}
                        style={{ border: "none" }}
                        onClick={handleDecline}
                        mode={"outlined"}
                        content={
                          <span className="mx-4 text-danger">
                            {" "}
                            <i className="fa fa-trash"></i> Decline
                          </span>
                        }
                        className="br-6 detail_btn"
                      />
                      <Button
                        disabled={onLoading}
                        style={{ border: "none" }}
                        onClick={handleAccept}
                        mode={"outlined"}
                        content={
                          <span className="mx-4 text-success">
                            {" "}
                            <i className="fa fa-check-circle"></i> Accept
                          </span>
                        }
                        className="br-6 detail_btn"
                      />
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between p-0 m-0 mt-4">
                  <Button
                    style={{
                      border: "1px solid var(--primaryColor)",
                      color: "#333",
                    }}
                    className="detail_btn detail_text"
                    mode={"outlined"}
                    content={"Report this service"}
                  />
                  <Button
                    className="detail_btn detail_text"
                    mode={"primary"}
                    content={"Promote Profile"}
                  />
                </div> */}
                </div>
              </>
            ) : (
              <div
                style={{ height: "50vh" }}
                className="d-flex user-select-none justify-content-center align-items-center text-muted"
              >
                <NoSelectedAppointment />
              </div>
            )}
          </div>
        </div>
        <div
          className={
            selected ? "appointment_list" : "appointment_details_container"
          }
        >
          <ListAppointmentModal
            appointments={appointments ?? []}
            onSelect={setSelected}
          />
        </div>
        {openRebookModal && selected && (
          <ReBookModal
            onClose={() => {
              setOpenRebookModal(false);
            }}
            salon={selected.appointment.chairId.salonId}
            onBook={() => {}}
            open={openRebookModal}
            backdrop={openRebookModal}
            appointmentCancellation={selected}
            style={{ height: "100vh !important" }}
          />
        )}
      </div>
    </>
  );
};

export default Dispute;
