import React, { useEffect, useState } from "react";
import InputStars from "../InputStars";
import { SearchValuesOptions } from "./SearchBar";
import { InputBoolean } from "../input";
import SimpleDropdown from "../reusable/SimpleDropdown";
import DropDown from "../reusable/DropDown";
import { SalonService } from "../../services/SalonService";

type ExtraFilterProps = {
  searchValues: SearchValuesOptions;
  setSearchValues: (value: any) => void;
  handleChange: (value: any) => void;
  location: string;
};

const ExtraFilter: React.FC<ExtraFilterProps> = ({
  searchValues,
  setSearchValues,
  handleChange,
  location,
}) => {
  const [selectedService, setSelectesService] = useState();
  const [kidService, setKitService] = useState<boolean | null>(null);
  const [kidFriendly, setKitFriendLy] = useState<boolean | null>(null);
  const [wheelchair, setWheelchair] = useState<boolean | null>(null);
  const [ratings, setRatings] = useState<string[]>([]);
  const handleClear = () => {
    setSearchValues((values: SearchValuesOptions) => ({
      ...values,
      rating: null,
      wheelchair_accessible: null,
      kids_friendly: null,
      kids_service: null,
      free_parking_for_client: null,
      blowdry: null,
      hairwash: null,
      undo_hair: null,
    }));
  };

  useEffect(() => {
    if (ratings.length === 0) {
      SalonService.getSalonByRating().then((rating) => {
        if (Array.isArray(rating)) {
          setRatings(rating);
        }
      });
    }
  }, [searchValues.rating]);

  const handleKidFriendly = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setKitFriendLy(e.target.checked);
  };
  const handleKidService = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setKitService(e.target.checked);
  };
  const handleFreeParkingForClient = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange(e);
    setKitService(e.target.checked);
  };

  const handleWheelchair = (val: boolean | null) => {
    setSearchValues({
      ...searchValues,
      wheelchair_accessible: (val as unknown as string) ?? val === null,
    });
  };
  const handleBlowdry = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setKitService(e.target.checked);
  };
  const handeleSelectService = (e: React.ChangeEvent<HTMLSelectElement>) => {};

  const extraItems = [
    <label>
      <span className="">Rating score:</span>
      <InputStars
        defaultValue={(searchValues.rating as unknown as number) ?? 0}
        onChange={(value) => {
          setSearchValues((prev: any) => {
            return { ...prev, rating: value ?? null };
          });
        }}
      />
    </label>,
    <label>
      <InputBoolean
        onChange={(val: boolean | null) =>
          setSearchValues({
            ...searchValues,
            wheelchair_accessible: (val as unknown as string) ?? null,
          })
        }
        // onChange={() => handleWheelchair(wheelchair)}
        value={searchValues.wheelchair_accessible as unknown as boolean}
        title={
          <div style={{ whiteSpace: "nowrap" }}>Wheelchair accessible ?</div>
        }
        // name="wheelchair"
      />
    </label>,
    <label>
      <InputBoolean
        title={"Kids friendly ?"}
        onChange={(val: boolean | null) =>
          setSearchValues({
            ...searchValues,
            kids_friendly: (val as unknown as string) ?? null,
          })
        }
        value={searchValues.kids_friendly as unknown as boolean}
        // name="kids_friendly"
      />
    </label>,
    <label>
      <InputBoolean
        title={"Kids Service ?"}
        onChange={(val: boolean | null) =>
          setSearchValues({
            ...searchValues,
            kids_service: (val as unknown as string) ?? null,
          })
        }
        value={searchValues.kids_service as unknown as boolean}
        // name="kids_service"
      />
    </label>,
    <label>
      <InputBoolean
        title={"Free parking for client ?"}
        onChange={(val: boolean | null) =>
          setSearchValues({
            ...searchValues,
            free_parking_for_client: (val as unknown as string) ?? false,
          })
        }
        value={searchValues.free_parking_for_client as unknown as boolean}
        // name="kids_service"
      />
    </label>,
    // <div className="d-flex flex-column gap-2">
    <span>Select services</span>,

    <label className="d-flex align-items-center gap-1 mb-2 ">
      <input
        type="checkbox"
        name="blowdry"
        onChange={handleChange}
        checked={searchValues.blowdry === "true"}
      />
      <span>Blow dry</span>
    </label>,
    <label className="d-flex align-items-center gap-1 mb-2">
      <input
        type="checkbox"
        name="hairwash"
        onChange={handleChange}
        checked={searchValues.hairwash === "true"}
      />
      <span>Hair wash</span>
    </label>,
    <label className="d-flex align-items-center gap-1">
      <input
        type="checkbox"
        name="undo_hair"
        onChange={handleChange}
        checked={searchValues.undo_hair === "true"}
      />
      <span>Undo hair</span>
    </label>,

    <div className="mt-3 d-flex gap-2 justify-content-center">
      <button onClick={handleClear} className="small-text button-27 w-100">
        CLEAR
      </button>
    </div>,
  ];

  const btnClass = `bg-white bg-transparent rounded p-1 px-3 ${
    location === "navBar"
      ? "border-white text-white  "
      : "border_black text-black"
  }`;

  return (
    <DropDown
      title={<div className={btnClass}>Extra</div>}
      items={extraItems}
      closeOnClickOutside
    />
  );
};

export default ExtraFilter;
