import { useEffect, useState } from "react";
import Countdown from "./CountDown";

interface DateDifferenceCountdownProps {
  date1: Date;
  date2: Date;
}

const DateDifferenceCountdown = ({
  date1,
  date2,
}: DateDifferenceCountdownProps) => {
  const [targetDate, setTargetDate] = useState<Date | null>(null);

  const calculateNewDateBasedOnDifference = (d1: Date, d2: Date): Date => {
    const timeDifference = d2.getTime() - d1.getTime();
    const dayDifference = timeDifference / (1000 * 3600 * 24);
    const result = new Date(d1);
    result.setDate(result.getDate() + dayDifference);
    return result;
  };

  useEffect(() => {
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
      const resultDate = calculateNewDateBasedOnDifference(startDate, endDate);
      setTargetDate(resultDate);
    } else {
      console.error("Invalid dates provided");
    }
  }, [date1, date2]);

  return <>{targetDate && <Countdown targetDate={targetDate} />}</>;
};

export default DateDifferenceCountdown;
