import React from "react";
import { Modal } from "react-bootstrap";
import { IAppointment } from "../../../models/Appointment";
import { CustomAppointment } from "../../../models/CustomAppointment";
import { formatUSPrice, timeToTimezone } from "../../../utils/utils";
import moment from "moment";
import { MapMarker } from "../../map/MapMarker";

interface ModalChatHeaderAppointmentProps {
    appointment: CustomAppointment | IAppointment;
    onHide: () => void;
}

const ModalChatHeaderAppointment: React.FC<ModalChatHeaderAppointmentProps> = (props) => {
    return (
        <Modal show={true} onHide={props.onHide} centered size="lg" backdrop="static">
            <Modal.Header closeButton>
                {/* <Modal.Title>Appointment Details</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <div
                    className="rce-mbox shadow-none">
                    <div className="rce-mbox-body">
                        <div className="rce-mbox-forward rce-mbox-forward-right"></div>
                        <div className="d-flex justify-content-between rce-mbox-title rce-mbox-title--clear">
                            <span className="text-uppercase">
                                Details about your appointment
                            </span>
                        </div>
                        <div className="rce-mbox-text left d-flex flex-column gap-1">
                            <img
                                style={{ width: '100px' }}
                                alt="Appointment similar"
                                className="img-fluid rounded"
                                src={props.appointment.photo}
                            />
                            <div className="d-flex align-items-center">
                                <h6 className="text-underline">
                                    The service is similar to {props.appointment.serviceName}
                                </h6>
                                <span className="small">({props.appointment.size})</span>
                            </div>
                            <div className="d-flex gap-1 align-items-center w-100 fw-500 mb-2">
                                <div className="d-flex align-items-center gap-1">
                                    <i className="fa fa-calendar" />
                                    <span>
                                        {moment(props.appointment.day, 'YYYY-MM-DD').format(
                                            'DD MMM YYYY'
                                        )}
                                    </span>
                                    <span>{timeToTimezone(props.appointment.startTime)}</span>
                                </div>
                                <div className="ms-auto">
                                    {formatUSPrice(props.appointment.price)}
                                </div>
                            </div>
                            <p>{props.appointment.description}</p>
                        </div>
                    </div>
                    <MapMarker
                        dimensions={{ width: '100%', height: '250px' }}
                        center={{ lat: props.appointment.lat ?? 11, lng: props.appointment.lng ?? 11 }}
                        infoWindowText="Appointment location" />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export { ModalChatHeaderAppointment };
