import "../styles/VerticalMenu.css";

const VerticalMenu = ({ items, active = 0, onItemClick }) => {
  return (
    <div className="verticalMenu_container shadow-sm d-flex flex-column justify-content-start">
      {/* <div className="verticalMenu__container__toggle rounded d-md-none bg-dark text-light gap-1 d-flex justify-content-center align-content-center position-absolute mt-3">
        <span>menu</span>
        <i className="fa fa-arrow-alt-circle-right mt-1" />
      </div> */}

      <div className="verticalMenu_container__menu">
        {items.map((item, key) => {
          return (
            <div
              key={key}
              onClick={() => {
                onItemClick(item, key);
              }}
              className={"VerticalMenuItem " + (key === active ? "active" : "")}
            >
              <i className={item.faIcon}></i>
              <span style={{ whiteSpace: "nowrap" }} className="item_title">
                {item.title}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VerticalMenu;
