import React, { useEffect, useState } from "react";
import moment from "moment";

interface TimeRemainingProps {
  targetDate: moment.Moment;
}

const TimeRemaining: React.FC<TimeRemainingProps> = ({ targetDate }) => {
  const [remainingTime, setRemainingTime] = useState<string>(
    calculateRemainingTime()
  );

  function calculateRemainingTime() {
    const now = moment();
    const duration = moment.duration(targetDate.diff(now));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${days} d ${hours} h ${minutes} m ${seconds} s`;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="TimeRemaining">
      <div className="TimeRemaining__label">Appointment start in:</div>
      <div className="TimeRemaining__container shadow border">{remainingTime}</div>
    </div>
  );
};

export default TimeRemaining;
