import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import User, {
  IUserRegistration,
  IUserManager,
  IUserRegistrationPro,
  isUserBusinessOwner,
  isUserProfessional,
} from "../../models/User";
import { AuthService } from "../../services/AuthService";
import { ISalon } from "../../models/SalonX";
import { SalonService } from "../../services/SalonService";
import Manager from "../../models/Manager";
import { ManagerState } from "./managerSlice";
import Review from "../../models/Review";

export interface AuthState {
  currentUser: User | null;
  referralLink: string | null;
  errors: any;
}

const initialState: AuthState = {
  currentUser: null,
  referralLink: null,
  errors: null,
};

export const getCurrentUser = createAsyncThunk<User, string>(
  "auth/current",
  async (token) => {
    const res = await AuthService.getCurrentUser(token);
    return res.data;
  }
);

export const registerUser = createAsyncThunk<User, User>(
  "auth/register",
  async (user, { rejectWithValue }) => {
    try {
      const res = await AuthService.register(user as IUserRegistration);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const updateUser = createAsyncThunk<User, FormData>(
  "auth/update",
  async (user, { rejectWithValue }) => {
    try {
      const res = await AuthService.update(user);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const updateSalon = createAsyncThunk<ISalon, FormData>(
  "auth/salon/update",
  async (salon, { rejectWithValue }) => {
    try {
      const res = await AuthService.updateSalon(salon);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);
export const adminUpdateSalon = createAsyncThunk<ISalon, Partial<ISalon>>(
  "auth/salon/admin/update",
  async (salon, { rejectWithValue }) => {
    try {
      const res = await AuthService.adminUpdateSalon(salon);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);
export const AddSalon = createAsyncThunk<ISalon, ISalon>(
  "auth/salon/add",
  async (salon, { rejectWithValue }) => {
    try {
      const res = await SalonService.addSalon(salon);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const getReferralLink = createAsyncThunk(
  "auth/referralLink",
  async () => {
    try {
      const res = await AuthService.getReferralLink();
      return res.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const changePassword = createAsyncThunk<Partial<User>, Partial<User>>(
  "auth/changePassword",
  async (data, { rejectWithValue }) => {
    try {
      const res = await AuthService.changePassword(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const passwordReset = createAsyncThunk<Partial<User>, Partial<User>>(
  "auth/passwordReset",
  async (data, { rejectWithValue }) => {
    try {
      const res = await AuthService.passwordReset(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const verifyPasswordToken = createAsyncThunk<string, string>(
  "auth/verifyPasswordToken",
  async (data, { rejectWithValue }) => {
    try {
      const res = await AuthService.verifyPasswordToken(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const deletePortfolioImage = createAsyncThunk<string[], number>(
  "auth/deletePortfolioImage",
  async (data, { rejectWithValue }) => {
    try {
      const res = await AuthService.deletePortfolioImage(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const getUserBookmarks = createAsyncThunk<User>(
  "auth/getAllBookmak",
  async () => {
    const res = await AuthService.getUserBookmarks();
    return res.data;
  }
);

export const deleteBookmark = createAsyncThunk<User, any>(
  "auth/deleteBookmark",
  async (elementKey, { rejectWithValue }) => {
    try {
      const res = await AuthService.deleteBookmark(elementKey);
      console.log("bookmark data", res.data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const clearErrors = createAction("auth/clearErrors");
export const logout = createAsyncThunk<null>("auth/logout", async () => {
  const res = await AuthService.logout();
  return res.data;
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.errors = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.errors = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.errors = null;
      })
      .addCase(AddSalon.fulfilled, (state, action) => {
        if (isUserProfessional(state.currentUser)) {
          const payload = action.payload as unknown as {
            salon: ISalon;
            manager?: Manager;
          };
          if (state.currentUser.salons) {
            const salons = state.currentUser.salons.slice();
            salons.push(payload.salon as any);
            state.currentUser.salons = salons;
          } else {
            state.currentUser.salons = [payload.salon as any];
          }
        }
        state.errors = null;
      })
      .addCase(updateSalon.fulfilled, (state, action) => {
        if (isUserProfessional(state.currentUser)) {
          const salons = (state.currentUser.salons as ISalon[]).map(
            (salon: ISalon) => {
              if (salon._id === action.payload._id) {
                return action.payload;
              }
              return salon;
            }
          );

          state.currentUser = {
            ...state.currentUser,
            salons,
          };
        }
      })
      .addCase(adminUpdateSalon.fulfilled, (state, action) => {
        if (isUserProfessional(state.currentUser)) {
          const payload = action.payload as unknown as {
            salon: ISalon;
            manager?: Manager;
          };

          const salons = (state.currentUser.salons as ISalon[]).map(
            (salon: ISalon) => {
              if (salon._id === payload.salon._id) {
                return { ...salon, ...payload.salon };
              }
              return salon;
            }
          );

          state.currentUser = {
            ...state.currentUser,
            salons,
          };
        }
      })
      .addCase(deletePortfolioImage.fulfilled, (state, action) => {
        const salonId = localStorage.getItem("salonId");
        if (salonId && isUserProfessional(state.currentUser)) {
          const salons = ((state.currentUser as any).salons as ISalon[]).map(
            (salon: ISalon) => {
              if (salon._id === salonId)
                return { ...salon, portfolio: action.payload };
              return salon;
            }
          );
          state.currentUser = {
            ...state.currentUser,
            salons,
          };
          state.errors = null;
        }
      })
      .addCase(getUserBookmarks.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.errors = null;
      })
      .addCase(deleteBookmark.fulfilled, (state, action) => {
        console.log("bookmark payload data", action.payload);
        console.log("current data hey", action.payload);
        const payload = action.payload as unknown as {
          bookmarks: Review[];
        };
        console.log("action payload", payload.bookmarks);
        if (state.currentUser) {
          state.currentUser.bookmarks = payload.bookmarks;
        }

        state.errors = null;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(verifyPasswordToken.rejected, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(clearErrors, (state, action) => {
        state.errors = null;
      })
      .addCase(getReferralLink.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.errors = null;
      })
      .addCase(getReferralLink.rejected, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.currentUser = null;
      });
  },
});

export const selectSalonsWithoutManagers = (
  authState: AuthState,
  managerState: ManagerState
) => {
  const user = authState.currentUser;
  if (isUserBusinessOwner(user)) {
    const salons = user.salons;
    const managers = managerState.managers;

    // Filter salons that have no linked manager
    return (
      salons?.filter(
        (salon) =>
          !managers?.some(
            (manager) =>
              manager.linked_salon_id &&
              manager.linked_salon_id._id === salon._id
          )
      ) || []
    );

    // manager.linked_salon_id === salon._id
  }
};

export default authSlice.reducer;
