import React from "react";

const Icons = () => {
  return (
    <div className="my-service-icons d-flex flex-column gap-1 mt-3 float-end">
      <i
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px}",
        }}
        className="fa fa-globe "
      ></i>
      <i
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
        }}
        className="fa fa-user btn-secondary"
      ></i>
    </div>
  );
};

export default Icons;
