import React from "react";
import styles from "../styles/ModalLoader.css";
import { Modal } from "react-bootstrap";


export interface DefaultLoaderProps {
    title?: string;
    show?: boolean
}


const ModalLoader: React.FC<DefaultLoaderProps> = ({ show = true, title = '' }) => {
    return <Modal
        style={{ backgroundColor: 'red !important' }}
        show={show}
        centered backdrop='static'
        className={`${styles["ModalLoader"]} d-flex flex-column align-items-center justify-content-center`}
    >
        <div className="spinner"></div>
        <span>{title}</span>
    </Modal>
}

export default ModalLoader;