import MainNav from "../components/navigation/MainNav";
import "./../styles/BusinessProfilePage.css";
import { useEffect, useRef, useState } from "react";
import Button from "../components/Button";
import AuthLayout from "../components/auth/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../redux/slices/authSlice";
import { AnyAction } from "redux";
import { useFormik } from "formik";
import { log } from "../utils/utils";
import {
  ProfileValues,
  validationSchema,
} from "../components/validation/InfoPageValidation";
import { useUser } from "../hooks/UseUser";
import toast from "react-hot-toast";
import { ServiceNameState } from "../redux/slices/serviceNameSlice";
import User from "../models/User";
import { useSalon } from "../context/SalonContext";

const PersonnalInfoPage: React.FC = () => {
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const user = useUser();
  const serviceNameData = useSelector<unknown, ServiceNameState>(
    (state: any) => state.serviceNames
  ).serviceNames;

  const salon = useSalon();

  const dispatch = useDispatch();

  const formik = useFormik<Partial<User>>({
    initialValues: {
      first_name: user?.first_name as string,
      last_name: user?.last_name as string,
      // bio: user?.bio as string,
      phone: user?.phone as string,
      email: user?.email as string,
      // speciality: "",
      // account_type: user?.account_type,
    },
    validationSchema,
    onSubmit: (values) => {
      log(JSON.stringify(values, null, 2));
    },
  });

  const formValues: ProfileValues = {
    first_name: formik.values.first_name as string,
    last_name: formik.values.last_name as string,
    email: formik.values.email as string,
    phone: formik.values.phone as string,
    // bio: formik.values.bio as string,
    bio:"",
    // speciality: formik.values.speciality as string,
    speciality:"",
    // account_type: formik.values.account_type as string,
    account_type:""
  };

  const handleSave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (submitBtnRef.current) {
      const formData = new FormData();
      formData.append("email", formValues.email);
      formData.append("phone", formValues.phone);
      formData.append("bio", formValues.bio);
      formData.append("first_name", formValues.first_name);
      formData.append("last_name", formValues.last_name);
      formData.append("speciality", formValues.speciality);
      formData.append("account_type", formValues.account_type as string);

      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = "loading...";

      dispatch(updateUser(formData) as unknown as AnyAction)
        .then(() => {
          setTimeout(() => {
            if (submitBtnRef.current) {
              submitBtnRef.current.removeAttribute("disabled");
              submitBtnRef.current.innerHTML = "Save";
            }
          }, 2000);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const formSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0) {
      handleSave(e);
      toast.success("Successfully Saved!");
    }
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (user) {
      formik.setValues(user);
    }
  }, [user]);

  useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  return (
    <AuthLayout>
      <MainNav navType={"connectedNav"} isSearch={false} />
      <div className="row p-0 m-0 BusinessProfilePage">
        <div className="container p-lg-0">
          <form className="w-100">
            <div className="d-flex my-4 gap-2 justify-content-center justify-content-md-around flex-column flex-md-row">
              <div className="d-flex flex-column ProfileLeftSide justify-content-start">
                <div className="mb-3">
                  <label htmlFor="" className="form-label text-black fw-500">
                    First Name
                  </label>
                  <input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values?.first_name ?? ""}
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="form-control w-100"
                    placeholder="Julia"
                    aria-describedby="helpId"
                  />
                </div>
                {formik.touched.first_name && formik.errors.first_name ? (
                  <div style={{ color: "var(--primaryColor)" }}>
                    {formik.errors.first_name}
                  </div>
                ) : null}
                <div className="mb-3">
                  <label htmlFor="" className="form-label text-black fw-500">
                    Last Name
                  </label>
                  <input
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name ?? ""}
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="form-control w-100"
                    placeholder="Doe"
                    aria-describedby="helpId"
                  />
                </div>
                {formik.touched.last_name && formik.errors.last_name ? (
                  <div style={{ color: "var(--primaryColor)" }}>
                    {formik.errors.last_name}
                  </div>
                ) : null}
                <div className="mb-3">
                  <label htmlFor="" className="form-label text-black fw-500">
                    Account Type
                  </label>
                  <input
                    className="form-control w-100"
                    name="account_type"
                    id="account_type"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // value={formik.values.account_type ?? ""}
                    disabled
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="" className="form-label text-black fw-500">
                    My Speciality
                  </label>
                  <select
                    className="form-select form-select-lg"
                    name="speciality"
                    id="speciality"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // value={formik.values.speciality ?? ""}
                  >
                    <option selected>
                      Add services to add your specialities
                    </option>

                    {serviceNameData?.map((option, key: any) => {
                      return (
                        <option value={option.name as string} key={key}>
                          {option.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="" className="form-label text-black fw-500">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control w-100"
                    placeholder="juliadoe@gmail.com"
                    aria-describedby="helpId"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email ?? ""}
                  />
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div style={{ color: "var(--primaryColor)" }}>
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              <div className="d-flex flex-column ProfileRightSide justify-content-start mt-3 mt-lg-0">
                <div className="mb-3">
                  <label htmlFor="" className="form-label text-black fw-500">
                    Tell us a bit more about you
                  </label>
                  <textarea
                    placeholder="A bit more about you..."
                    style={{ minWidth: "350px" }}
                    className="form-control"
                    name="bio"
                    id="bio"
                    rows={9}
                    maxLength={350}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // value={formik.values.bio ?? ""}
                  ></textarea>
                  <p className="text-black m-0 small-text text-end my-1">
                    {/* {formik.values.bio?.length} / 350 characters */}
                  </p>
                </div>
                <div className="mb-3">
                  <label htmlFor="" className="form-label text-black fw-500">
                    Personal Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    className="form-control w-100"
                    placeholder="+12505550199"
                    aria-describedby="helpId"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone ?? ""}
                  />
                </div>
                {formik.touched.phone && formik.errors.phone ? (
                  <div style={{ color: "var(--primaryColor)" }}>
                    {formik.errors.phone}
                  </div>
                ) : null}
                <div className="d-flex justify-content-end gap-2 mt-4">
                  <Button
                    onClick={handleCancel}
                    mode="outlined"
                    content={<span className="mx-2">Cancel</span>}
                  />
                  <Button
                    type="submit"
                    ref={submitBtnRef}
                    onClick={(e: any) => {
                      formSubmit(e);
                    }}
                    content={<span className="mx-3">Save</span>}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end "></div>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default PersonnalInfoPage;
