import React, { useRef, useEffect, useState } from "react";
import "./../styles/DropDown.css";
import { v4 as uuidv4 } from "uuid";

interface DropDownProps {
  title: React.ReactNode;
  items: React.ReactNode[];
  addEffect?: boolean;
  closeOnItemClick?: boolean;
  closeOnClickOutside?: boolean;
  borderColor?: Pick<React.CSSProperties, "borderColor">;
  backgroundColor?: Pick<React.CSSProperties, "backgroundColor">;
}

const DropDown: React.FC<DropDownProps> = ({
  title,
  items,
  borderColor = "#dee2e6",
  backgroundColor = "white",
  addEffect = false,
  ...props
}) => {
  const lDropdownTitleRef = useRef<HTMLDivElement>(null);
  const lDropdownItemsRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        lDropdownTitleRef.current &&
        !lDropdownTitleRef.current.contains(event.target as Node)
      ) {
        if (
          lDropdownItemsRef.current &&
          !lDropdownItemsRef.current.contains(event.target as Node)
        ) {
          if (props.closeOnClickOutside) {
            setOpen(false);
          }
        } else {
          if (props.closeOnItemClick) {
            setOpen(false);
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="l-dropdown w-100"
      style={
        {
          "--borderColor": borderColor,
          "--bgColor": backgroundColor,
        } as React.CSSProperties
      }
    >
      <div
        ref={lDropdownTitleRef}
        className="l-dropdown__title"
        onClick={toggleDropdown}
      >
        {title}
      </div>
      <div
        ref={lDropdownItemsRef}
        className={`l-dropdown__items ${open ? "show" : ""}`}
      >
        <div className="l-dropdown__triangle"></div>
        <div className="l-dropdown__items__container shadow-sm">
          {items.map((item, i) => {
            return (
              <div
                key={uuidv4()}
                className={`l-dropdown__item ${addEffect ? "effect" : ""}`}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DropDown;
