import { useSelector } from "react-redux";
import { AuthState } from "../redux/slices/authSlice";

export const useLatriceUser = () => {
  const latriceUser = useSelector<unknown, AuthState>(
    (state: any) => state.auth
  ).currentUser;

  console.log("latrice user", latriceUser);

  if (latriceUser && latriceUser._id === "66bc80f5ba633599c14b5e2e") {
    return latriceUser;
  }
  return null;
};
