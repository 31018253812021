import React, { useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import {
  POSITIVE_TRANSACTION_TYPES,
  Transaction,
  TransactionType,
} from "../../models/Account";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { formatUSPrice } from "../../utils/utils";
import { TransitionType } from "react-bootstrap/esm/helpers";

export type TransactionHistoryProps = {
  transactions: Transaction[];
};

export const RecentPayment: React.FC<TransactionHistoryProps> = ({
  transactions,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const renderTypeIcon = (type: TransactionType) => {
    return POSITIVE_TRANSACTION_TYPES.includes(type) ? (
      <FontAwesomeIcon icon={faArrowUp} style={{ color: "green" }} />
    ) : (
      <FontAwesomeIcon icon={faArrowDown} style={{ color: "red" }} />
    );
  };

  const renderDate = (date: Date) => {
    return moment(date).format("MMM .DD H:mm A");
  };

  const columns: TableColumn<Transaction>[] = [
    {
      name: "ID",
      cell: (row: Transaction, rowIndex: number) => (
        <div className="">#{rowIndex + 1}</div>
      ),
      sortable: true,
    },
    {
      name: "Amount",
      cell: (row: Transaction) => (
        <span className="fw-bold">
          {POSITIVE_TRANSACTION_TYPES.includes(row.type) ? "+" : "-"}
          {formatUSPrice(row.amount)}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Type",
      cell: (row: Transaction) => (
        <div className="d-flex align-items-center gap-1">
          <span>{renderTypeIcon(row.type)}</span>
          <span className="bg-dark text-light rounded-5 small px-2">
            {row.type}
          </span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Date",
      cell: (row: Transaction) => (
        <span className="text-muted">{renderDate(row.created_at)}</span>
      ),
      sortable: true,
    },
  ];

  const filteredTransactions = transactions.filter((transaction) =>
    Object.values(transaction).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <DataTable
      className="w-100"
      title={
        <div className="d-flex justify-content-between">
          <div>Payment History</div>
        </div>
      }
      paginationRowsPerPageOptions={[10]}
      columns={columns}
      data={filteredTransactions}
      pagination
      highlightOnHover
    />
  );
};
