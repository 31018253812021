import { HTMLProps, useEffect, useState } from "react";
import Button from "../Button";
import Modal from "./Modal";
import "./../styles/RequestCustomModal.css";
import "./../styles/BookNowModal.css";
import { IBookingService } from "../../models/Service";
import { getAllDatesInMonth, groupTimeSlotsByDate } from "../../utils/utils";
import moment from "moment";
import { ProfessionalService } from "../../services/ProfessionalService";
import { TimeSlot, TimeSlotGroup } from "../../models/TimeSlot";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addAppointment } from "../../redux/slices/appointmentSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { IAppointmentCancellation } from "../../models/AppointmentCancellation";
import { TotalAndContinueSection } from "./commons/TotalAndContinueSection ";
import { BookingForm } from "./commons/BookingForm";
import { ModalLoader } from "./commons/ModalLoader";
import { IBookMakable } from "../BookmarkButton";
import { ISalonProfile } from "../../pages/SeeProfessionalAccountPage";
import { IChair } from "../../models/Chair";

type ReBookProps = HTMLProps<HTMLDivElement> & {
  backdrop: boolean;
  appointmentCancellation: IAppointmentCancellation;
  salon: ISalonProfile;
  onClose: () => void;
  onBook: () => void;
} & IBookMakable;

const ReBookModal: React.FC<ReBookProps> = ({
  appointmentCancellation,
  salon,
  ...props
}: ReBookProps) => {
  const { appointment, ...rest } = appointmentCancellation;
  // const { user } = professional;

  const currentUser = localStorage.getItem("authToken");

  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment());
  const [selectedDate, setSelectedDate] = useState<moment.Moment | undefined>(
    undefined
  );
  const [selectedTime, setSelectedTime] = useState<moment.Moment | undefined>(
    undefined
  );
  // const [selectedChair, setSelectedChair] = useState<IChair | undefined>(
  //   undefined
  // );
  const [selectedMonthDates, setSelectedMonthDates] = useState<moment.Moment[]>(
    []
  );
  const [selectedDateTimes, setSelectedDateTimes] = useState<moment.Moment[]>(
    []
  );

  const [time, setTime] = useState("morning");
  const [selectedServices, setSelectedServices] = useState<IBookingService[]>(
    []
  );
  // const [selectedServices, setSelectedServices] = useState<IBookingService[]>(service.map((s, key: number) => { return { ...s, position: key } }));
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalHours, setTotalHours] = useState<number>(0);
  const [onSelectService, setOnSelectService] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [timeSlots, setTimeSlots] = useState<TimeSlotGroup | null>(null);
  const [noSlots, setNoSlots] = useState(false);
  const [q, setQ] = useState<string | null>(null);
  const [onLogin, setOnLogin] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const removeService = (service: IBookingService) => {
    handleLoading(true);
    const services = selectedServices
      .slice()
      .filter((item) => {
        return item.position !== service.position;
      })
      .map((item, key) => {
        return { ...item, position: key };
      });
    setSelectedServices(services);
    __handleSelectDay(selectedDate as moment.Moment, timeSlots, services);
    handleLoading(false);
  };

  const getSlotInterval = (
    service: IBookingService,
    searchPosition: number,
    daySlots: Omit<TimeSlot, "date">[]
  ): {
    time: { start: moment.Moment | null; end: moment.Moment | null };
    position: { start: number; end: number };
  } => {
    const duration = service.duration as unknown as number;
    let time: { start: moment.Moment | null; end: moment.Moment | null } = {
      start: null,
      end: null,
    };
    let position = { start: 0, end: 0 };

    for (let i = searchPosition; i < daySlots.length; i++) {
      const slot = daySlots[i];

      if (slot.isAvailable) {
        if (time.start === null) {
          position.start = i;
          time.start = moment(slot.time, "HH:mm A");
        } else {
          position.end = i;
          time.end = moment(slot.time, "HH:mm A");
        }

        if (
          time.end !== null &&
          time.start !== null &&
          time.end.diff(time.start, "minutes") >= duration
        ) {
          break;
        }
      } else {
        break;
      }
    }
    if (
      time.end === null &&
      time.start === null &&
      searchPosition < daySlots.length
    )
      return getSlotInterval(service, searchPosition + 1, daySlots);

    return { time, position };
  };

  // Get possible start time based on total services duration
  // And current time slots
  const getPossibleStartTime = (
    duration: number,
    slots: moment.Moment[]
  ): moment.Moment[] => {
    return slots.filter((slot) => moment().diff(slot, "minutes") < 0);
  };

  const __handleSelectDay = (
    day: moment.Moment,
    _timeSlots: typeof timeSlots,
    defaultServices: IBookingService[] | null = null
  ) => {
    if (_timeSlots) {
      let currentDaySlots = _timeSlots[day.format("YYYY-MM-DD")];
      if (currentDaySlots) {
        currentDaySlots = currentDaySlots.filter((slot) => slot.isAvailable);
      } else {
        currentDaySlots = [];
      }

      if (currentDaySlots && currentDaySlots.length > 0) {
        let dates = currentDaySlots
          .slice()
          .map((slot) =>
            moment(
              `${day.format("YYYY-MM-DD")} ${slot.time}`,
              "YYYY-MM-DD HH:mm A"
            )
          );
        dates = getPossibleStartTime(totalHours, dates);
        setSelectedDateTimes(dates);
        setSelectedDate(dates[0]);

        let lastSlotPosition = 0;
        const daySlots = currentDaySlots.slice().filter((slot) => {
          const slotMoment = moment(
            `${day.format("YYYY-MM-DD")} ${slot.time}`,
            "YYYY-MM-DD HH:mm A"
          );
          return slotMoment.diff(dates[0], "minutes") >= 0;
        });

        let services: IBookingService[] = [];
        console.log("selected:", selectedServices);
        console.log("default:", defaultServices);
        (defaultServices ?? selectedServices).forEach((service, key) => {
          const { time, position } = getSlotInterval(
            service,
            lastSlotPosition,
            daySlots.filter((slot) => {
              return (
                moment(
                  `${day.format("YYYY-MM-DD")} ${slot.time}`,
                  "YYYY-MM-DD HH:mm A"
                ).diff(selectedTime, "minutes") >= 0
              );
            })
          );
          console.log("hey");
          if (time.start && time.end) {
            lastSlotPosition = position.end;
            services.push({
              ...service,
              startTime: time.start,
              endTime: time.end,
              position: key,
            });
          } else {
            services.push({ ...service, position: key });
          }
        });
        setSelectedServices(services);
      } else {
        setSelectedDateTimes([]);
      }
    }
    setSelectedDate(day);
    return 0;
  };

  const handleSelectDay = async (day: moment.Moment) => {
    handleLoading(true);
    const chairId = appointmentCancellation.appointment.chairId._id;
    const durationInMinutes =
      totalHours === 0 ? appointmentCancellation.duration : totalHours;
    await ProfessionalService.getProfessionalTimeSlots(
      salon._id,
      moment(day),
      durationInMinutes,
      chairId
    )
      .then(async (res) => {
        const slots = groupTimeSlotsByDate(res.slots);
        setTimeSlots(slots);
        await selectDay(day, slots).then(() => {
          handleLoading(false);
        });
      })
      .catch(async (err) => {
        await selectDay(day, {}).then(() => {
          handleLoading(false);
        });
        toast.error("Something went wrong, please try again.", {
          duration: 7000,
        });
      });
  };

  const selectDay = async (day: moment.Moment, slots: typeof timeSlots) => {
    return new Promise((resolve) => {
      __handleSelectDay(day, slots);
      resolve({
        selectedDate,
        selectedDateTimes,
        selectedServices,
      });
    });
  };

  const handleSelectTime = async (time: moment.Moment) => {
    setSelectedTime(time);
  };

  const handleLoading = (state: boolean) => {
    setLoading(state);
  };

  const canContinue = (
    dateTimes: moment.Moment[],
    time: moment.Moment | undefined,
    service: IBookingService
  ): boolean => {
    if (
      dateTimes &&
      dateTimes.length > 0 &&
      service &&
      service.startTime &&
      time
    ) {
      const lastTime = dateTimes.at(-1);
      if (lastTime) {
        return !(lastTime.diff(time, "minutes") < 0);
      }
    }
    return false;
  };

  const handleLogin = () => {
    window.location.href = "/login";
  };

  const handleRegister = () => {
    window.location.href = "/register";
  };

  const handleDashboard = () => {
    navigate("/appointments");
  };

  const handleBookServices = () => {
    if (!currentUser) {
      return toast.success(
        (t) => (
          <div className="d-flex flex-column gap-2 justify-content-center">
            <p>
              You must login first before booking services. If you don't have an
              account you can{" "}
              <span
                role="button"
                onClick={handleRegister}
                className="createAccountLink"
              >
                create one here.
              </span>
            </p>
            <div className="d-flex gap-2 justify-content-end">
              <Button
                className="rounded mx-2"
                mode="default"
                onClick={handleLogin}
              >
                {" "}
                Login{" "}
              </Button>
            </div>
          </div>
        ),
        {
          icon: null,
        }
      );
    }

    const startTime = selectedServices[0].startTime;

    const bookData = {
      day: selectedDate?.format("YYYY-MM-DD"),
      startTime: startTime?.tz("UTC").format("H:mm"),
      salonId: salon._id,
      chairId: appointmentCancellation.appointment.chairId,
      serviceIds: selectedServices.map((service) => service._id),
    };

    handleLoading(true);

    dispatch(addAppointment(bookData) as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        const backdrop = document.querySelector(".modal-backdrop.fade.show");
        if (backdrop) backdrop.remove();
        props.onClose();
        return toast.success(
          (t) => (
            <div className="d-flex flex-column gap-2 justify-content-center">
              <p>
                Success! Your booking for the selected services was completed
                successfully{" "}
              </p>
              <div className="d-flex gap-2 justify-content-end">
                <Button
                  className="rounded mx-2"
                  mode="default"
                  onClick={handleDashboard}
                >
                  {" "}
                  See my dashboard{" "}
                </Button>
              </div>
            </div>
          ),
          {
            duration: 10000,
          }
        );
      })
      .catch((err: any) => {
        const errors = err.errors;
        if (err.status) {
          if (err.status === 500) {
            return toast.error(errors.message, {
              duration: 3000,
            });
          } else {
            if (errors.code === "INSUFFICIENT_BALANCE") {
              return toast.error(
                "Insufficient Balance! Please add funds to your account to book the selected services.",
                {
                  duration: 3000,
                }
              );
            }
            return toast.error(errors.message, {
              duration: 3000,
            });
          }
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  useEffect(() => {
    const datesInMonth = getAllDatesInMonth(selectedMonth);
    setSelectedMonthDates(datesInMonth);
    return () => {
      setSelectedMonthDates([]);
    };
  }, [selectedMonth]);

  useEffect(() => {
    setTotalPrice(
      selectedServices.slice().reduce((prev, curr) => prev + curr.price, 0)
    );
    setTotalHours(
      selectedServices
        .slice()
        .reduce((prev, curr) => prev + (curr.duration as unknown as number), 0)
    );
    return () => {
      setTotalPrice(0);
      setTotalHours(0);
    };
  }, [selectedServices]);

  useEffect(() => {
    __handleSelectDay(selectedDate as moment.Moment, timeSlots);
    console.log("Selected Services:", selectedServices);
    return () => {
      setSelectedDate(undefined);
    };
  }, [selectedTime]);

  useEffect(() => {
    setSelectedDateTimes(getPossibleStartTime(totalHours, selectedDateTimes));
    return () => {
      setSelectedDateTimes([]);
    };
  }, [totalHours]);

  const handleSelectMember = ()=>{
    console.log("test")
  }

  return (
    <Modal {...props} className="modal-lg">
      <div className="BookNowModal__container">
        {loading && <ModalLoader />}
        {!onLogin && !onSelectService && (
          <>
            {" "}
            <div className="BookNowModal__container__content px-3">
              <BookingForm
                onRemoveService={removeService}
                onSelectTime={handleSelectTime}
                onSelectDay={handleSelectDay}
                services={selectedServices}
                salon={salon}
                times={getPossibleStartTime(totalHours, selectedDateTimes)}
                onAddService={() => setOnSelectService(true)}
                bookMarked={props.bookMarked}
                onBookmark={props.onBookmark}
              />
            </div>
            <TotalAndContinueSection
              price={totalPrice}
              duration={totalHours}
              canContinue={canContinue(
                selectedDateTimes,
                selectedTime,
                selectedServices[0]
              )}
              onContinue={handleBookServices}
              onSelectMember={handleSelectMember}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default ReBookModal;
