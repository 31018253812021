import React from "react"
import { ISalon } from "../../models/SalonX"
import { Stars } from "../Stars"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChair, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";


type SwitchSalonItemProps = {
    salon: ISalon
    onClick: (salon: ISalon) => void
    selected?: boolean
}


const SwitchSalonItem: React.FC<SwitchSalonItemProps> = ({ salon, onClick, selected }) => {

    return <div
        onClick={() => onClick(salon)}
        key={salon._id}
        className={`d-flex gap-3 rounded p-3 align-items-center switch-salon-item ${selected ? 'active' : ''}`}>
        <div>
            <img src={salon.profile ?? require('../../assets/images/profile.jpg')} alt={salon.name} />
        </div>
        <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-4 align-items-center">
                <span>{salon.name}</span>
                <div>
                    <Stars
                    canUpdate={false}
                        stars={salon.reviewsStats.averageStars}
                        notice={salon.reviewsStats.reviewCount} />
                </div>
            </div>
            <div className="d-flex gap-4">
                <small className="text-muted">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
                    {salon.address}
                </small>
                <small className="text-muted">
                    <FontAwesomeIcon icon={faChair} className="me-2" />
                    {salon.seating_places}{" "} seating places
                </small>
            </div>
        </div>
    </div>
}


export default React.memo(SwitchSalonItem)