import React, { useEffect } from 'react';
import moment from 'moment';
import MonthPicker from '../../reusable/MonthPicker';
import SlidePicker from '../../SlidePicker';
import { getAllDatesInMonth } from '../../../utils/utils';

interface DateSelectionFormProps {
    onDateChange: (date: moment.Moment) => void;
}

const DateSelectionForm: React.FC<DateSelectionFormProps> = ({
    onDateChange,
}) => {

    const [selectedMonthDates, setSelectedMonthDates] = React.useState<moment.Moment[]>([])
    const [selectedDate, setSelectedDate] = React.useState<moment.Moment>(moment());

    const handleDateChange = (date: moment.Moment) => {
        onDateChange(date)
    }

    const handleMonthChange = (date: moment.Moment) => {
        const datesInMonth: moment.Moment[] = getAllDatesInMonth(date);

        // Set the selected month dates and the default selected date
        setSelectedMonthDates(datesInMonth);
        setSelectedDate(datesInMonth[0]);
    };

    React.useEffect(() => {
        if(selectedDate)
        setSelectedMonthDates(getAllDatesInMonth(selectedDate))
    }, [selectedDate])

    useEffect(()=>{
        console.log("month day",selectedMonthDates)
    },[])

    return (
        <div className="mb-3">
            <label htmlFor="" className="form-label text-primary fw-500">
                For what date? *
            </label>
            <div className="d-flex flex-column justify-content-center">
                <div className="mb-3 d-flex justify-content-center">
                    <MonthPicker onChange={handleMonthChange} />
                </div>
                <div className="w-100">
                    <SlidePicker
                        data={selectedMonthDates}
                        // selected={selectedDate}
                        maxShow={7}
                        keyExtractor={(item) => item.format("YYYY-MM-DD")}
                        separator={<span className="mx-1"></span>}
                        renderItem={(item) => (
                            <div className="dateItem d-flex flex-column fw-bold p-2 br-6 btn-secondary">
                                <div>{item.format("ddd")}</div>
                                <div>{item.date()}</div>
                            </div>
                        )}
                        onSelectItem={handleDateChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default DateSelectionForm;
