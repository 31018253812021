import { useState } from "react";
import { IChair } from "../../models/Chair";
import "../styles/VerticalUserSelector.css";

export interface VerticalChairSelectorProps {
  chairs: IChair[];
  defaultSelectedChair?: IChair;
  onSelectChair: (chair: IChair) => void;
}

const VerticalChairSelector: React.FC<VerticalChairSelectorProps> = ({
  chairs,
  onSelectChair,
  defaultSelectedChair
}) => {
  const [selectedChair, setSelectedChair] = useState<IChair | null>(defaultSelectedChair ?? null);
  const [showChairs, setShowChairs] = useState(false);

  const headChair = selectedChair ?? chairs[0];

  const handleOnSelectChair = (chair: IChair) => {
    setSelectedChair(chair);
    setShowChairs(false);
    onSelectChair(chair);
  };

  const handleShowChairs = () => {
    setShowChairs(!showChairs);
  };


  return (
    <div className={"d-flex justify-content-end gap-4 align-items-center "}>
      {chairs.map((chair, key) => {
        return (
          <div
            key={chair._id}
            onClick={(e: any) => {
              handleOnSelectChair(chair);
            }}
            className={`selectUserItem rounded d-flex flex-column justify-content-center align-items-center ${chair._id === (selectedChair?._id)
              ? "selectUserItem--active"
              : ""
              }`}
          >
            <div className="selectUserItem__imgContent">
              <div style={chair._id === 'any-chair-id' ? { borderStyle: 'dashed' } : {}} className='working-table-element__item__img-container'>
                <div>
                  {chair._id === 'any-chair-id' ? '?' : chair.label.charAt(0)}
                </div>
              </div>
            </div>
            <div>
              {chair.label}
            </div>
          </div>
        );
      })}
    </div>
  )
};

export default VerticalChairSelector;