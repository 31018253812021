import React, { HTMLAttributes, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripeElementChangeEvent, loadStripe } from "@stripe/stripe-js";
import "../styles/TopUpAccountModal.css";
import { formatUSPrice } from "../../utils/utils";
import { ModalLoader } from "./commons/ModalLoader";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { depositFunds, transfertFunds } from "../../redux/slices/accountSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { AccountService } from "../../services/AccountService";

export interface TransfertAccountModalOptions
  extends HTMLAttributes<HTMLDivElement> {
  showTransfert?: boolean;
  onBack: () => void;
}

const TransfertAccountModal: React.FC<TransfertAccountModalOptions> = ({
  showTransfert = false,
  className,
  ...props
}: TransfertAccountModalOptions) => {
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isCardValid, setIsCardValid] = useState(false);

  const dispatch = useDispatch();

  const handleAmountChange = (e: any) => {
    setAmount(e.target.value);
  };

  const handleTransfertFunds = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      amount,
    };
    console.log("data", data);
    // AccountService.transfertunds(data)
    //   .then((data: any) => {
    //     console.log("data", data);
    //     props.onBack();
    //     toast.success(
    //       `${formatUSPrice(
    //         amount
    //       )} has been successfully added to your balance !`,
    //       { duration: 15000 }
    //     );
    //   })
    //   .catch((error: any) => {
    //     console.log("error", error);
    //     toast.error("Error: unable to perform the transaction !", {
    //       duration: 10000,
    //     });
    //   }).finally(() => {
    //         setLoading(false);
    //       });
    dispatch(transfertFunds(data) as unknown as AnyAction)
      .unwrap()
      .then(() => {
        props.onBack();
        toast.success(
          `${formatUSPrice(
            amount
          )} has been successfully withdraw from your account !`,
          { duration: 15000 }
        );
      })
      .catch((error: any) => {
        console.log(error);
        toast.error("Error: unable to perform the transaction !", {
          duration: 10000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    !showTransfert && setAmount(0);
  }, [showTransfert]);

  return (
    <Modal
      size="lg"
      centered
      show={showTransfert}
      onHide={props.onBack}
      backdrop="static"
      keyboard={false}
      {...props}
    >
      <Modal.Header closeButton>
        <div className="d-flex gap-1 align-items-center">
          <i className="fa-solid fa-credit-card"></i>
          <span className="text-uppercase">Withdraw your funds</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        {(loading || stripe === null) && (
          <ModalLoader label={"Please wait..."} />
        )}
        <div className="custom-card-element d-flex flex-column align-items-center p-2 gap-3">
          <div className="d-flex w-100 p-2"></div>
          <form className="w-100 mx-5">
            <div className="w-100 pt-3 gap-3 d-flex flex-column">
              <div>
                <label htmlFor="amount" className="form-label">
                  Amount to add
                </label>
                <input
                  id="amount"
                  min={10}
                  max={1000}
                  step={5}
                  type="number"
                  className="border rounded p-2 px-3 small-text w-100"
                  placeholder="Enter amount"
                  onChange={handleAmountChange}
                />
              </div>
            </div>
            <div className="p-2 d-flex justify-content-end w-100">
              <button
                onClick={handleTransfertFunds}
                disabled={amount === 0}
                className="pay-btn rounded p-2 mt-3 small-text"
              >
                Send {formatUSPrice(amount)}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const AddFundModal: React.FC<TransfertAccountModalOptions> = (props) => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
  );

  return (
    <Elements stripe={stripePromise}>
      <TransfertAccountModal {...props} showTransfert />
    </Elements>
  );
};

export default TransfertAccountModal;
