import moment from "moment";
import React, { useState } from "react";

interface MonthPickerProps {
    onChange: (selectedDate: moment.Moment) => void;
}

const MonthPicker: React.FC<MonthPickerProps> = ({ onChange }) => {
    const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment());

    const handlePrevMonth = () => {
        const prevDate = selectedDate.clone().subtract(1, "month");
        if (prevDate.isSameOrAfter(moment(), "month")) {
          setSelectedDate(prevDate);
          handleMonthChange(prevDate);
        }
      };
    
      const handleNextMonth = () => {
        const nextDate = selectedDate.clone().add(1, "month");
        if (nextDate.isSameOrAfter(moment(), "month")) {
          setSelectedDate(nextDate);
          handleMonthChange(nextDate);
        }
      };

    const getFormattedMonth = (date: moment.Moment): string => {
        return date.format("MMMM YYYY");
    };

    const handleMonthChange = (month: moment.Moment) => {
        onChange(month);
    };

    return (
        <div className="d-flex align-items-center justify-content-center gap-2">
            <div role="button" onClick={handlePrevMonth} className="Arrow ArrowLeft d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-angle-left fa-1x"></i>
            </div>
            <div style={{ minWidth: '130px' }} className="text-center">{getFormattedMonth(selectedDate)}</div>
            <div role="button" onClick={handleNextMonth} className="Arrow ArrowRight d-flex align-items-center justify-content-center">
                <i className="fa-solid fa-angle-right fa-1x"></i>
            </div>
        </div>
    );
};

export default MonthPicker;