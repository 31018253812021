const CardLoader = () => {
  return (
    <div
      style={{ width: "420px" }}
      className="d-flex align-items-center gap-1 shadow bg-white rounded flex-column pb-1"
    >
      <div
        style={{ height: "190px" }}
        className="cover_img  w-100 position-relative shimmer shimmer__square"
      >
        <div
          style={{
            width: "100px",
            height: "100px",
            boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
            border: "2px solid white",
          }}
          className="profile_img position-absolute shimmer shimmer__circle top-100 start-50 translate-middle"
        ></div>
      </div>
      <div
        style={{ marginTop: "50px" }}
        className="card_content w-100 d-flex flex-column justify-content_center align-items-center"
      >
        <div
          style={{ height: "18px", marginBottom: "1rem" }}
          className="shimmer mt-3 shimmer__line w-75 mx-0 "
        ></div>
        <div
          style={{
            height: "16px",
            width: "90%",
            marginLeft: "0px",
            marginBottom: "0.8rem",
          }}
          className="shimmer shimmer__line "
        ></div>
        <div
          style={{ height: "16px", marginBottom: "0.8rem" }}
          className="shimmer shimmer__line w-75 mx-0"
        ></div>
        <div className="d-flex w-100% align-items-center px-1 gap-2 w-100">
          <div
            style={{ height: "16px", marginLeft: "0" }}
            className="shimmer shimmer__line"
          ></div>
          <div
            style={{ height: "16px", marginLeft: "0" }}
            className="shimmer shimmer__line "
          ></div>
        </div>
        <div
          style={{
            width: "89px",
            height: "31px",
            marginTop: "1.3rem",
            borderRadius: "1rem",
          }}
          className="shimmer shimmer__circle"
        ></div>
      </div>
    </div>
  );
};

export default CardLoader;
