import React from "react";
import { SearchValuesOptions } from "./SearchBar";
import { InputBoolean } from "../input";
import DropDown from "../reusable/DropDown";

type FiltersProps = {
  location: string;
  handleChange: (value: any) => void;
  setSearchValues: (value: any) => void;
  searchValues: SearchValuesOptions;
};

const Filters: React.FC<FiltersProps> = ({
  location,
  handleChange,
  searchValues,
  setSearchValues,
}) => {
  const handleClear = () => {
    setSearchValues((values: SearchValuesOptions) => ({
      ...values,
      account_type: null,
      drive_to_your_place: null,
    }));
  };

  const filterItems = [
    <div>
      <p className="text-primary mb-1">Service type :</p>
      <label className="d-flex align-items-center gap-1">
        <input
          className="form-check-input mt-0"
          type="radio"
          name="account_type"
          value="independent"
          id="filterOption1"
          onChange={handleChange}
          checked={searchValues.account_type === "independent"}
        />
        <span>Independent</span>
      </label>
      <label className="d-flex align-items-center gap-1">
        <input
          className="form-check-input mt-0"
          type="radio"
          name="account_type"
          value="business_owner"
          id="filterOption2"
          onChange={handleChange}
          checked={searchValues.account_type === "business_owner"}
        />
        <span>Salon owner</span>
      </label>
      <label className="d-flex align-items-center gap-1">
        <input
          className="form-check-input mt-0"
          type="radio"
          name="account_type"
          value="both"
          id="filterOption3"
          onChange={handleChange}
          checked={searchValues.account_type === "both"}
        />
        <span>Both</span>
      </label>
    </div>,
    <label>
      <InputBoolean
        onChange={(val: boolean | null) =>
          setSearchValues({
            ...searchValues,
            drive_to_your_place: (val as unknown as string) ?? false,
          })
        }
        value={searchValues.drive_to_your_place as unknown as boolean}
        title={<div className="text-primary d-inline-flex">Home service ?</div>}
        name="want_me_to_drive"
      />
    </label>,
    <div className="mt-3 d-flex gap-2 justify-content-center">
      <button onClick={handleClear} className="small-text button-27 w-100">
        CLEAR
      </button>
    </div>,
  ];

  const btnClass = `d-flex gap-2 align-items-center border-2 bg-transparent ${
    location === "navBar" ? "text-white" : "text-black"
  }`;


  
  return (
    <DropDown
      title={
        <div className={btnClass}>
          <i
            className={`fa-solid fa-sliders  ${
              location === "navBar" ? "text-white" : "text-black"
            }`}
          ></i>
          <span>Filters</span>
        </div>
      }
      items={filterItems}
      closeOnClickOutside
    />
  );
};

export default Filters;
