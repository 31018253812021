import MainNav from "../components/navigation/MainNav";
import "./../styles/LoginPage.css";
import { useRef, useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  initialValues,
  validationSchema,
} from "../components/validation/LoginValidation";
import { log } from "../utils/utils";
import { AuthService } from "../services/AuthService";
import OtpInput from "react18-input-otp";
import { useLocation, useNavigate } from "react-router-dom";
import ImageSlider from "../components/reusable/imageSlider/ImageSlider";
import { slides } from "../components/reusable/imageSlider/Slides";

const LoginPageVerify = () => {
  const confirmBtnRef = useRef<HTMLButtonElement>(null);

  const [otp, setOtp] = useState("");
  const [otpTimer, setOtpTimer] = useState(0);
  const [onSendBackOTP, setOnSendBackOTP] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const ref = queryParams.get("ref");

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      log(JSON.stringify(values, null, 2));
    },
  });

  const formSubmit = (e: any) => {
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0) {
      // handleSubmit(e);
    }
  };

  const handleConfirm = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    formik.setErrors({});
    if (confirmBtnRef.current) {
      confirmBtnRef.current.setAttribute("disabled", "true");
      confirmBtnRef.current.innerHTML = "loading...";

      const password = localStorage.getItem("password") as string;
      const email = localStorage.getItem("email") as string;

      AuthService.verifyOtpCode({ email, password, otp })
        .then((response) => {
          localStorage.removeItem("password");
          localStorage.removeItem("email");
          localStorage.removeItem("lastSendBackOTPDate");
          const authorizationHeader = response.headers.authorization;
          localStorage.setItem(
            "authToken",
            authorizationHeader.replace("Bearer ", "")
          );
          if (ref) {
            window.location.href = ref;
            return;
          }
          if (response.data?.account_type) {
            navigate("/business-profile");
          } else {
            navigate("/client-profile");
          }
        })
        .catch((error) => {
          formik.setErrors(error.response.data.errors);
        })
        .finally(() => {
          if (confirmBtnRef.current) {
            confirmBtnRef.current.removeAttribute("disabled");
            confirmBtnRef.current.innerHTML = "verify";
          }
        });
    }
  };

  const handleResendOTP = () => {
    if (otpTimer > 0 || onSendBackOTP) return;
    // Handle the logic to resend the OTP validation code
    // ...
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    AuthService.resendOtpCode({
      email: email as string,
      password: password as string,
    })
      .then(() => {
        // Update the timer after resending the OTP
        setOtpTimer(60); // Set the timer to 60 seconds
        localStorage.setItem(
          "lastSendBackOTPDate",
          `${Math.floor(Date.now() / 1000)}`
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setOnSendBackOTP(false);
      });
  };

  // Function to start the timer
  const startTimer = () => {
    const OTP_TIMEOUT = 60; // Timeout in seconds
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const lastSendBackOTPDate = localStorage.getItem("lastSendBackOTPDate");
    const timeDifference = currentTime - Number(lastSendBackOTPDate);

    if (timeDifference < OTP_TIMEOUT) {
      const remainingTime = OTP_TIMEOUT - timeDifference;
      setOtpTimer(remainingTime);
    } else {
      setOtpTimer(0);
    }
  };

  useEffect(() => {
    startTimer(); // Start the timer on component mount
  }, []);

  useEffect(() => {
    // Update the timer every second
    if (otpTimer > 0) {
      const timerId = setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
      return () => clearTimeout(timerId); // Clear the timer on component unmount
    }
  }, [otpTimer]);

  return (
    <>
      <MainNav navType={"homeNav"} />
      <div className="row p-0 m-0 Login">
        <div className="position-relative loginLeftSide">
          <ImageSlider slides={slides} />
        </div>
        <div className="mx-auto p-0 m-0 col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 loginRightSide align-items-start align-items-lg-center mt-5 mt-lg-0">
          <div className="container p-lg-0 my-3">
            <div className="d-flex flex-column justify-content-around">
              <div className="row d-flex align-items-center mx-0 mx-lg-5">
                <h4 className="mt-3 mb-3">Verify your email</h4>
                <div className="col-12 col-lg-10 col-xl-6">
                  <div>
                    {formik.errors.message ? (
                      <div className="alert alert-danger d-flex gap-2 align-items-center">
                        <i className="fa fa-info-circle"></i>
                        <div>{formik.errors.message}</div>
                      </div>
                    ) : null}
                    <form onSubmit={handleConfirm}>
                      <div>
                        Check your mail messages for a code. Codes requested via
                        EMAIL may take up to a minute to appear.
                      </div>

                      <div className="p-0 m-0 mt-5">
                        <OtpInput
                          value={otp}
                          onChange={(code: any) => {
                            setOtp(code);
                          }}
                          numInputs={5}
                          separator={<span className="m  x-3"></span>}
                          containerStyle={"d-flex justify-content-between"}
                          inputStyle={"w-100 mx-2 form-control"}
                        />
                        {formik.errors.otp ? (
                          <div style={{ color: "var(--primaryColor)" }}>
                            {formik.errors.otp}
                          </div>
                        ) : null}
                      </div>
                      <div className="small mt-3">
                        <span
                          onClick={handleResendOTP}
                          role={"button"}
                          className={`nav-link fw-500 ${
                            otpTimer > 0
                              ? ""
                              : "active nav-link-underline-hover"
                          }`}
                          style={{ cursor: "pointer" }}
                        >
                          {otpTimer > 0
                            ? `Resend OTP in ${otpTimer} seconds`
                            : onSendBackOTP
                            ? "sending..."
                            : "Send back the validation code"}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end align-items-end mt-4">
                        <div>
                          <button
                            disabled={
                              !(otp !== null && (otp + "").length === 5)
                            }
                            ref={confirmBtnRef}
                            type="button"
                            onClick={handleConfirm}
                            className="btn btn-primary px-4 text-uppercase fw-bold w-100"
                          >
                            Verify
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPageVerify;
