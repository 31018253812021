import React, { FC, useState } from "react";
import { IChair } from "../../models/Chair";
import DropDown from "../reusable/DropDown";
import EditChairModal from "./modal/EditChairModal";
import SimpleDropdown from "../reusable/SimpleDropdown";
import { v4 as uuidv4 } from "uuid";
import DeleteChairModal from "./modal/DeleteChairModal";

type ChairContainerEditableProps = {
  chairToEdit?: IChair;
  onCloseChairToEdit?: () => void;
};

type ChairContainerProps = {
  // chair: IChair;
  // index: number;
  setChairs: (chairs: IChair[]) => void;
  handleDelete: (chair: { data: IChair }) => void;
  handleEdit: (editedChair: IChair) => void;
  chairs: IChair[];
  salonId?: string;
} & ChairContainerEditableProps;

const ChairContainer: FC<ChairContainerProps> = ({
  // chair,
  handleEdit,
  handleDelete,
  chairs,
  chairToEdit,
  salonId,
  onCloseChairToEdit,
  // index,
}) => {
  const [showEditChair, setShowEditChair] = useState(false);
  const [showDeleteChair, setShowDeleteChair] = useState(false);
  const actions = [
    <button
      key={uuidv4()}
      onClick={() => setShowEditChair(true)}
      className="bg-transparent small-text d-flex align-items-center gap-2 border-0 rounded"
    >
      <i className="fa-solid fa-pen"></i>
      <span>Edit</span>
    </button>,
    <button
      key={uuidv4()}
      onClick={() => setShowDeleteChair(true)}
      className=" bg-transparent d-flex align-items-center gap-2 small-text border-0 rounded"
    >
      <i className="fa-regular fa-trash-can "></i>
      <span>Delete</span>
    </button>,

  ];

  const handleCloseChairToEdit = () => {
    if (onCloseChairToEdit) onCloseChairToEdit();
    setShowEditChair(false);
  };

  return (
    <div
      style={{ minHeight: "60vh" }}
      className="mt-4 table-responsive table-responsive-sm "
    >
      <table className="table table-hover">
        <thead className="d-none d-lg-table-header-group  ">
          <tr>
            <th scope="col">Label</th>
            <th scope="col">Active</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {chairs.map((chair, index) => (
            <tr
              key={chair._id}
              className="px-0  w-100 bg-transparent py-2 align-items-center"
            >
              <td className="text-black fw-bold  text-truncate">
                <div
                  className="chair rounded-circle"
                  data-label={chair.label.charAt(0).toLocaleUpperCase()}
                ></div>
                <span className="ms-3">{chair.label}</span>
                <i
                  onClick={() => {
                    setShowEditChair(true);
                  }}
                  className="fa-solid fa-pencil text-muted ms-2 cursor-pointer"
                ></i>
              </td>
              <td className="d-none d-lg-table-cell ">
                {chair.active ? "Active" : "Not active"}
              </td>
              <td>
                <SimpleDropdown
                  name={
                    <>
                      <button className="working-table-outline-button py-1 px-2 d-flex align-items-center rounded-pill bg-transparent d-none d-lg-flex">
                        <span>Actions</span>
                        <i className="fa fa-angle-down ms-2"></i>
                      </button>
                      <i className="fa-solid fa-ellipsis-vertical d-block d-lg-none"></i>
                    </>
                  }
                  children={actions}
                />
              </td>
              <EditChairModal
                show={showEditChair}
                onClose={() => setShowEditChair(false)}
                chair={chair}
                handleEdit={handleEdit}
              />
              <DeleteChairModal
                show={showDeleteChair}
                onClose={() => setShowDeleteChair(false)}
                chair={chair}
                salonId={salonId!!}
                handleDelete={handleDelete}
              />
            </tr>
          ))}
        </tbody>
      </table>
      {chairToEdit && (
        <EditChairModal
          show={true}
          onClose={handleCloseChairToEdit}
          chair={chairToEdit}
          handleEdit={handleEdit}
        />
      )}
    </div>
  );
};

export default ChairContainer;
