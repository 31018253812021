import React from "react";
import styles from "../styles/PulseLoader.module.css";

export interface DiscussionLoaderProps {
  title?: string | any;
}

const DiscussionLoader: React.FC<DiscussionLoaderProps> = ({ title = "" }) => {
  return (
    <div
      className={`${styles["PulseLoader"]} d-flex flex-column align-items-center justify-content-center `}
    >
      <p className=" text-center w-75 text-muted  ">
        Welcome to Latrice! We are delighted to welcome you here. Whether to
        book or chat, our team is there to make your experience enjoyable.
      </p>
      <div className={`${styles["PulseLoader__logo"]}`}>
        <img alt="Latrice" src={require("../../assets/images/latrice.png")} />
      </div>
      <div className="text-muted">{title}</div>
    </div>
  );
};

export default DiscussionLoader;
