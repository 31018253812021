import axios from "axios";

let config = {
  method: "get",
  maxBodyLength: Infinity,
  url: "http://localhost:8000/api/notifications",
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  },
};

const getNotifications = () => {
  return axios
    .get(`notifications`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((response) => {
      // console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const markAsRead = (notificationId: string) => {
  return axios
    .get(`notifications/read/${notificationId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const NotificationService = {
  getNotifications,
  markAsRead,
};
