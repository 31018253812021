import React, { useState } from "react";
import { formatTime, formatUSPrice } from "../../utils/utils";
import Service from "../../models/Service";
import { useDispatch } from "react-redux";
import { deleteService } from "../../redux/slices/serviceSlice";
import { AnyAction } from "@reduxjs/toolkit";
import toast, { Toaster } from "react-hot-toast";
import ConfirmDeleteModal from "../reusable/ConfirmDeleteModal";
import PulseLoader from "../loader/PulseLoader";
import ModalLoader from "../loader/ModalLoader";
import EditServiceForm from "./EditServiceForm";
import SearchInput from "../reusable/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

type ServicesListProps = {
  services: Service[] | null;
};

const ServicesList: React.FC<ServicesListProps> = ({ services }) => {
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState<string | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [serviceToDelete, setServiceToDelete] = useState<Service | null>(null);
  const [currentService, setCurrentService] = useState<Service | null>(null);
  const [editedService, setEditedService] = useState<Partial<Service>>();
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const dispatch = useDispatch();

  const filteredServices =
    services &&
    services?.filter((service) =>
      q === null
        ? true
        : service.service_detail.name.toLowerCase().includes(q.toLowerCase())
    );

  const handleEdit = (index: number, service: Service) => {
    console.log("index", index);
    setEditingIndex(index);
    setCurrentService(service);
    setEditedService({
      ...editedService,
      _id: service._id,
      name: service.service_detail.name,
      size: service.size,
      duration: service.duration,
      price: service.price,
      description: service.description,
    });
  };

  const handleServiceDelete = () => {
    setLoading(true);
    dispatch(
      deleteService({
        _id: serviceToDelete?._id,
      }) as unknown as AnyAction
    )
      .unwrap()
      .then(() => {
        toast.loading("Deleting service...", { duration: 3000 });
        toast.remove();
        toast.success("Service deleted successfully!", { duration: 3000 });
      })
      .catch((err: any) => {
        toast.error(err);
      })
      .finally(() => {
        setServiceToDelete(null);
        setLoading(false);
      });
  };

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="">
      {loading && <ModalLoader />}
      <div className="d-flex flex-column ">
        <SearchInput
          searchItem={q}
          setSearchItem={setQ}
          placeholder="Search service name"
        />
        {services && (
          <span className="text-end text-primary my-3 text-end ">
            {services.length} services
          </span>
        )}
      </div>
      <div
        className="services-list-container mb-4 justify-content-center align-items-center "
        style={{ width: "100%" }}
      >
        {services &&
          Object.values(
            filteredServices?.reduce((acc: any, service, index) => {
              const serviceWithIndex = { ...service, index };
              if (!acc[service.service_detail.name]) {
                acc[service.service_detail.name] = [serviceWithIndex];
              } else {
                acc[service.service_detail.name].push(serviceWithIndex);
              }
              return acc;
            }, {})
          ).map((servicesWithSameName: any, groupIndex) => (
            <div key={groupIndex} style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <div
                  key={servicesWithSameName[0]?._id}
                  className={` ${
                    servicesWithSameName[0].extra
                      ? "   rounded mb-2 border p-3 services-list extra-service "
                      : "    rounded mb-2 border p-3 services-list bg-white "
                  } ${
                    openIndex === groupIndex
                      ? "accordion-item-open"
                      : "accordion-item-closed"
                  }  `}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  {editedService &&
                  editingIndex === servicesWithSameName[0].index ? (
                    <EditServiceForm
                      service={servicesWithSameName[0]}
                      editedService={editedService}
                      setEditedService={setEditedService}
                      setEditingIndex={setEditingIndex}
                      setLoading={setLoading}
                      currentService={currentService}
                      setCurrentService={setCurrentService}
                    />
                  ) : (
                    <>
                      <div
                        className=" gap-2 fw-bold text-black col-sm text-truncate"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="col-sm">
                          {servicesWithSameName[0].service_detail.name}
                        </div>
                        {servicesWithSameName.length > 1 ? (
                          <div
                            className="col-sm"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => toggleAccordion(groupIndex)}
                          >
                            <FontAwesomeIcon
                              icon={
                                openIndex === groupIndex
                                  ? faChevronUp
                                  : faChevronDown
                              }
                              className="accordion-icon"
                            />
                          </div>
                        ) : (
                          <div
                            className="col-sm"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => toggleAccordion(groupIndex)}
                          ></div>
                        )}
                      </div>

                      <div className="d-flex gap-2 col-sm ">
                        {servicesWithSameName[0].size ? (
                          servicesWithSameName[0].size.length > 1 ? (
                            <>
                              <span>
                                {servicesWithSameName[0].size[0]},{" "}
                                {servicesWithSameName[0].size[1]}
                              </span>
                            </>
                          ) : (
                            <>
                              <span>{servicesWithSameName[0].size[0]}</span>
                            </>
                          )
                        ) : null}
                      </div>
                      <div className=" d-flex gap-2 col-sm">
                        <i className="fa-solid fa-money-bill-wave me-2"></i>
                        {formatUSPrice(servicesWithSameName[0].price)}
                      </div>
                      <div className="col-sm">
                        <i className="fa-solid fa-hourglass-end me-2"></i>
                        {formatTime(
                          servicesWithSameName[0].duration as unknown as number
                        )}
                      </div>

                      <div className="col-sm">
                        {servicesWithSameName[0].description}
                      </div>

                      <div className="d-flex gap-2 col-sm">
                        <div
                          onClick={() =>
                            setServiceToDelete(servicesWithSameName[0])
                          }
                          className="text-primary service-btn"
                        >
                          delete
                        </div>
                      </div>
                      <div className="d-flex gap-2 col-sm">
                        <div
                          onClick={() => {
                            handleEdit(
                              servicesWithSameName[0].index,
                              servicesWithSameName[0]
                            );
                          }}
                          className="text-success service-btn"
                        >
                          edit
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {openIndex === groupIndex && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "end",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {servicesWithSameName
                      .slice(1)
                      .map((service: Service, innerIndex: number) => (
                        <div
                          key={service?._id}
                          className="rounded mb-2 border p-3 services-list   "
                          // style={{
                          //   display: "flex",
                          //   justifyContent: "center",
                          //   alignItems: "center",
                          //   marginBottom: "20px",
                          //   width: "100%",
                          // }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          {editedService && editingIndex === service.index ? (
                            <EditServiceForm
                              service={service}
                              editedService={editedService}
                              setEditedService={setEditedService}
                              setEditingIndex={setEditingIndex}
                              setLoading={setLoading}
                              currentService={currentService}
                              setCurrentService={setCurrentService}
                            />
                          ) : (
                            <>
                              <div
                                className=" gap-2 fw-bold text-black col-sm text-truncate"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div className="fw-bold text-black col-sm text-truncate "></div>
                                <div
                                  className="col-sm"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => toggleAccordion(groupIndex)}
                                ></div>
                              </div>

                              <div className="col-sm ">
                                {service.size ? (
                                  service.size.length > 1 ? (
                                    <>
                                      <span>
                                        {service.size[0]}, {service.size[1]}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span>{service.size[0]}</span>
                                    </>
                                  )
                                ) : null}
                              </div>
                              <div className="col-sm">
                                <i className="fa-solid fa-money-bill-wave me-2"></i>
                                {formatUSPrice(service.price)}
                              </div>
                              <div className="col-sm">
                                <i className="fa-solid fa-hourglass-end me-2"></i>
                                {formatTime(
                                  service.duration as unknown as number
                                )}
                              </div>
                              <div className="col-sm">
                                {service.description}
                              </div>
                              <div className="d-flex gap-2 col-sm">
                                <div
                                  onClick={() => setServiceToDelete(service)}
                                  className="text-primary service-btn"
                                >
                                  delete
                                </div>
                              </div>
                              <div className="d-flex gap-2 col-sm">
                                <div
                                  onClick={() => {
                                    handleEdit(service.index!!, service);
                                  }}
                                  className="text-success service-btn"
                                >
                                  edit
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          ))}

        {services && services.length === 0 && (
          <div className="d-flex user-select-none text-muted gap-3 align-items-center justify-content-center mt-5">
            <i className="fas fa-search-minus fa-3x mb-2"></i>
            <div className="text-center fs-4">
              You have no services yet
              <br />
              Add the services you propose.
            </div>
          </div>
        )}
        {services &&
          services.length !== 0 &&
          filteredServices?.length === 0 && (
            <div className="d-flex user-select-none text-muted gap-3 align-items-center justify-content-center mt-5">
              <i className="fas fa-search-minus fa-3x mb-2"></i>
              <div className="text-center fs-4">
                No service matches your search. Check your spelling or maybe
                <br />
                you didn't add it to your service list.
              </div>
            </div>
          )}
        {services === null && <PulseLoader />}
      </div>
      <ConfirmDeleteModal
        show={serviceToDelete !== null}
        setServiceToDelete={() => {
          setServiceToDelete(null);
        }}
        handleServiceDelete={handleServiceDelete}
        text1={`Do you really want to delete the service: ${serviceToDelete?.service_detail.name} ?`}
        text2={"If you delete it you won't be able to recover it"}
      />
    </div>
  );
};

export default ServicesList;
