import { DiscussionSystemProps } from '../../LatriceDiscussionMessage'
import { AcceptedDiscussionBase } from './AcceptedDiscussionBase'
import { Alert } from 'react-bootstrap'



export const AcceptedDiscussionProfessional = (props: DiscussionSystemProps) => {

    if(props.appointment.acceptedByClient){
        return <AcceptedDiscussionBase
            appointment={props.appointment}
            alertContainer={
                <Alert className='alert-success--bordered d-flex gap-1 align-items-center' key={'success'} variant={'success'}>
                    <i className="fa fa-check-circle fa-1x"></i>
                    <span>
                        The client has accepted the appointment.
                        The appointment has been scheduled and the chat with the client will be open as soon as possible.
                    </span>
                </Alert>
            }
        />
    }

    return <AcceptedDiscussionBase
        appointment={props.appointment}
        alertContainer={
            <Alert className='alert-success--bordered d-flex gap-1 align-items-center' key={'success'} variant={'success'}>
                <i className="fa fa-check-circle fa-1x"></i>
                <span>
                    We are currently awaiting for client's approbation to continue further.
                </span>
            </Alert>
        }
    />
}
