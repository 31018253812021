import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from '../styles/AddAppointmentModal.module.css';
import '../styles/Calendar.css';
import { IAppointment } from '../../models/Appointment';
import moment from 'moment';
import { Appointment } from './Appointment';
import { IProject } from '../../models/Project';
import { ProjectModalItem } from './Project';


export interface AddAppointmentModalOptions {
    open?: boolean,
    projects: IProject[] | null,
    appointments: IAppointment[] | null,
    onChange: (value: moment.Moment) => void,
    onCancel: (appointment: IAppointment) => Promise<any>,
    onClose?: () => void
}


const AddAppointmentModal = (options: AddAppointmentModalOptions) => {
    const [calendarDate, setCalendarDate] = useState<moment.Moment>(moment());
    const [filteredAppointments, setFilteredAppointments] = useState<IAppointment[]>(options.appointments ?? []);
    const [filteredProjects, setFilteredProjects] = useState<IProject[]>(options.projects ?? []);

    const handleCloseModalAppointment = (e: any) => {
        if (typeof options.onClose === 'function') {
            options.onClose();
        }
    }

    const handleCancelAppointment = (appointment: IAppointment) => {
        return options.onCancel(appointment)
    }

    const handleCancelProject = (project: IProject) => {
        return Promise.resolve(0);
    }

    const handleDateChange = (value: moment.Moment) => {
        options.onChange(moment(value));
        setCalendarDate(moment(value));
    }

    useEffect(() => {
        if (options.appointments) {
            setFilteredAppointments(options.appointments.filter((appointment) => {
                return calendarDate.isSame(moment(`${appointment.day} ${appointment.startTime}`, 'YYYY-MM-DD HH:mm'), 'days')
            }));
        } else {
            setFilteredAppointments([]);
        }
    }, [calendarDate, options.appointments]);


    useEffect(() => {
        if (options.projects) {
            setFilteredProjects(options.projects.filter((project) => {
                return calendarDate.isSame(moment(`${project.day} ${project.startTime}`, 'YYYY-MM-DD HH:mm'), 'days')
            }));
        } else {
            setFilteredProjects([]);
        }
    }, [calendarDate, options.projects])



    return <div className={`${styles.AddAppointmentModal} p-3 ${options.open ? `${styles['AddAppointmentModal--open']}` : ``} d-flex flex-column justify-content-center p-2`}>
        <div onClick={handleCloseModalAppointment} className={`${styles['AddAppointmentModal__closeBtn']} d-md-none`}>x</div>
        <div className={`d-flex flex-column gap-2 w-100`}>
            <button className={`${styles['AddAppointmentModal__btnTutorial']} ms-auto tutorial`}>Calendar Tutorial</button>
            <div>
                <div className="form-check">
                    <input checked className="form-check-input" type="checkbox" value="" id="listByDay" />
                    <label className="form-check-label" htmlFor="listByDay">
                        List by day
                    </label>
                </div>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="listByRegular" />
                    <label className="form-check-label" htmlFor="listByRegular">
                        List my regular appointments
                    </label>
                </div>
            </div>
            <Calendar className={`AddAppointmentModal__Calendar`} onChange={(value, e) => handleDateChange(moment(value as Date))} value={calendarDate.toDate()} />
            <h5 className='fw-600'>Your appointments</h5>
            <div className={`d-flex flex-column gap-2`}>
                {filteredAppointments.length > 0 && filteredAppointments.map((appointment) => {
                    return <Appointment
                        key={appointment._id}
                        appointment={appointment}
                        onCancel={handleCancelAppointment}
                    />
                })}

                {filteredProjects.length > 0 && filteredProjects.map((project) => {
                    return <ProjectModalItem
                        key={project._id}
                        project={project}
                        onCancel={handleCancelProject}
                    />
                })}


                {filteredAppointments.length === 0 && filteredProjects.length === 0 &&
                    <div
                        className="w-100 user-select-none d-flex justify-content-center align-items-center small text-muted flex-column fs-6"
                    >
                        <i className="fas fa-exclamation-triangle fa-2x mb-2"></i>
                        <span className="text-center">
                            We couldn't find anything on {calendarDate.format("DD MMM YYYY")}.
                        </span>
                    </div>
                }

            </div>

        </div>
    </div>
}

export {
    AddAppointmentModal
}