import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ModalLoader from "../../loader/ModalLoader";

type FilterModalProps = {
  show: boolean;
  onClose: () => void;
};

const FilterChairsModal: React.FC<FilterModalProps> = ({ show, onClose }) => {
  const [loading, setLoading] = useState(false);

  const hanldeFiltering = () => {};
  return (
    <Modal backdrop="static" show={show} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <div className="text-black fw-500 fs-5">Filters</div>
      </Modal.Header>
      {loading && <ModalLoader />}
      <Modal.Body className="d-flex flex-column gap-2 border-top border-bottom">
        <div className="w-100 px-3 py-4">
          <div className="d-flex p-0 flex-column salon-input gap-1 ">
            <span className="text-black">Status</span>
            <select
              name="status"
              id="status"
              className="form-select salon-input py-2 text-black"
            >
              <option selected value="active">
                Active
              </option>
              <option value="all">All seats</option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <div className="mx-3 my-1 d-flex justify-content-between align-items-center w-100">
          <span className="text-primary">Clear all filters</span>
          <div className="d-flex gap-3 align-items-center">
            <button onClick={onClose} className="working-table-outline-button">
              Cancel
            </button>
            <button
              onClick={hanldeFiltering}
              className="working-table-primary-button"
            >
              Apply
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterChairsModal;
