const EVENT_CODE = {
  // User-related events
  USER_CONNECTED: "USER_CONNECTED",
  USER_DISCONNECTED: "USER_DISCONNECTED",

  // Chat message events
  CHAT_MESSAGE: "CHAT_MESSAGE",
  TYPING: "TYPING",
  MESSAGE_RECEIVED: "MESSAGE_RECEIVED",
  MESSAGE_DELIVERED: "MESSAGE_DELIVERED",
  MESSAGE_READ: "MESSAGE_READ",
  MAKE_REVIEW: "MAKE_REVIEW",
  // Notification events
  NOTIFICATION_RECEIVED: "NOTIFICATION_RECEIVED",

  BLOCKED_USER: "BLOCKED_USER",

  BONUS_UNLOCKED: "BONUS_UNLOCKED",

  // Error events
  ERROR: "ERROR",

  // Availability
  ASK_FOR_AVAILABILITY: "ASK_FOR_AVAILABILITY",

  SET_PROFESSIONAL_AVAILABILITY_STATE: "SET_PROFESSIONAL_AVAILABILITY_STATE",
  ASK_PROJECT_ITEMS: "ASK_PROJECT_ITEMS",
  SEND_PROJECT_ITEMS: "SEND_PROJECT_ITEMS",
};

export { EVENT_CODE };
