import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AppointmentService } from "../../services/AppointmentService";
import { IAppointment } from "../../models/Appointment";
import { IAppointmentCancellation } from "../../models/AppointmentCancellation";
import { AppointmentCancellationService } from "../../services/AppointmentCancellationService";
import { cancelAppointment } from "./appointmentSlice";

export interface AppointmentCancellationState {
  appointmentCancellations: IAppointmentCancellation[] | null;
  errors: any;
}

const initialState: AppointmentCancellationState = {
  appointmentCancellations: null,
  errors: null,
};

export const getAppointmentCancellations = createAsyncThunk<
  Partial<IAppointment>[]
>(
  "appointments/getAppointmentCancellations",
  async (_, { rejectWithValue }) => {
    try {
      const res =
        await AppointmentCancellationService.getAppointmentCancellations();
      console.log("res", res);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const cancelAppointmentCancellation = createAsyncThunk<
  Partial<IAppointmentCancellation>,
  Partial<IAppointmentCancellation>
>(
  "appointments/cancelappointment",
  async (appointment, { rejectWithValue }) => {
    try {
      const res =
        await AppointmentCancellationService.cancelAppointmentCancellation(
          appointment
        );
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const acceptAppointmentCancellation = createAsyncThunk<
  Partial<IAppointment>,
  Partial<IAppointmentCancellation>
>("appointments/cancelaccept", async (appointment, { rejectWithValue }) => {
  try {
    const res =
      await AppointmentCancellationService.acceptAppointmentCancellation(
        appointment
      );
    return res.data;
  } catch (error: any) {
    return rejectWithValue({
      errors: error.response.data.errors,
      status: error.response.status,
    });
  }
});

const appointmentCancellationSlice = createSlice({
  name: "appointmentCancellations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppointmentCancellations.fulfilled, (state, action) => {
        state.appointmentCancellations = action.payload as any;
      })
      .addCase(cancelAppointment.fulfilled, (state, action) => {
        if (state.appointmentCancellations) {
          const appointmentCancellations =
            state.appointmentCancellations.slice();
          appointmentCancellations.push(action.payload as any);
          state.appointmentCancellations = appointmentCancellations;
        } else {
          state.appointmentCancellations = [action.payload as any];
        }
        state.errors = null;
      })
      .addCase(cancelAppointmentCancellation.fulfilled, (state, action) => {
        if (state.appointmentCancellations) {
          const cancelled = action.payload as any;
          delete cancelled["user"];
          let appointmentCancellations = state.appointmentCancellations
            .slice()
            .map((appointment) => {
              if (appointment._id === cancelled._id) {
                return { ...appointment, status: "cancelled" } as any;
              }
              return appointment;
            });
          state.appointmentCancellations = appointmentCancellations;
        } else {
          state.appointmentCancellations = [action.payload as any];
        }
        state.errors = null;
      })

      .addCase(acceptAppointmentCancellation.fulfilled, (state, action) => {
        if (state.appointmentCancellations) {
          const appointmentId: string = action.payload._id as any;
          let appointmentCancellations = state.appointmentCancellations
            .slice()
            .map((appointment) => {
              if (appointment.appointment._id === appointmentId) {
                appointment.status = "cancelled";
              }
              return appointment;
            });
          state.appointmentCancellations = appointmentCancellations;
        } else {
          state.appointmentCancellations = [action.payload as any];
        }
        state.errors = null;
      });
  },
});

export default appointmentCancellationSlice.reducer;
