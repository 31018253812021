import React from "react";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";

type FavsProps = {
  profile: string;
  localization: string;
  available?: boolean;
  name: string;
  id: string;
};

const Favs: React.FC<FavsProps> = ({
  profile,
  localization,
  available,
  name,
  id,
}) => {
  const navigate = useNavigate();
  const slug = slugify(name + "-" + id);

  return (
    <div className="d-flex bg-white rounded align-items-start p-3 justify-content-between gap-3 shadow w-100 favs" >
      <div className="d-flex align-items-center justify-content-between gap-3">
        <div className="img_container ">
          <img
            className="favs_img"
            src={
              profile !== null
                ? profile
                : require("../../assets/images/profile.jpg")
            }
            alt="profile "
          />
        </div>
        <div className="flex-fill">
          <div>{name}</div>
          <div>{localization}</div>
          <div className="d-flex justify-content-center gap-3 py-2">
            <div className={"text-muted " + (available ? "text-primary" : "")}>
              <i className="fa fa-calendar-check"></i> Avaible
            </div>
            <div className="border-start"></div>
            <div className={"text-muted " + (!available ? "text-primary" : "")}>
              <i className="fa fa-calendar-xmark"></i> Busy
            </div>
          </div>
        </div>
      </div>
      <div className="favs_book">
        {available ? (
          <div className="d-flex justify-content-center  gap-3 py-2">
            <Button
              className="ms-auto"
              style={{ fontSize: ".7em" }}
              mode={"primary"}
              content={<span className="mx-1 ">Book Now</span>}
              onClick={() => {
                navigate(`/see-professional-account/${slug}`);
              }}
            />
          </div>
        ) : (
          <div className="d-flex justify-content-center gap-3 py-2 favs_busy">
            <div className="justify-content-center align-items-center">
              <div className="fw-500 small">
                <i className="fa-solid fa-gauge"></i> Coming soon
              </div>
              <div className="text-primary text-center small">01:27:00 min</div>
            </div>
            <div className="text-muted text-primary">
              <Button
                style={{ fontSize: ".7em" }}
                mode={"primary"}
                content={<span className="mx-1">See availability</span>}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Favs;
