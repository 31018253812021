import { useEffect, useState } from "react";
import MainNav from "../components/navigation/MainNav";
import "./../styles/BusinessProfilePage.css";
import AuthLayout from "../components/auth/AuthLayout";
import ChairContainer from "../components/adminDashboard/ChairContainer";
import AddChairModal from "../components/adminDashboard/modal/AddChairModal";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ChairLoader from "../components/adminDashboard/loader/ChairLoader";
import FilterChairsModal from "../components/adminDashboard/modal/FilterChairsModal";
import axios from "axios";
import { IChair } from "../models/Chair";
import { deleteChair, editChair, getChairs } from "../services/ChairService";
import toast from "react-hot-toast";
import SearchInput from "../components/reusable/SearchInput";

type Props = {};

const ChairManagementPage = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchItem, setSearchItem] = useState<string | null>(null);
  const [chairs, setChairs] = useState<IChair[] | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const salonId = location.pathname.split("/").pop(); // Extracting salonId from the URL

  const handleEdit = (chairData: IChair) => {
    editChair(chairData)
      .then((res) => {
        if (chairs) {
          const updatedChairs = chairs?.map((chair) =>
            chair._id === res.data._id ? { ...chair, ...res.data } : chair
          );

          if (updatedChairs) {
            setChairs(updatedChairs);
            toast.success("Chair edited successfully");
          }
        }
      })
      .catch((err: any) => {
        toast.error(
          "There was an error while editing. Please try again later."
        );
      });
  };
  const handleDelete = (chairData: { data: IChair }) => {
    if (chairs) {
      console.log("the default chairs", chairs);
      const filterChairs = chairs.filter(
        (chair) => chair._id !== chairData.data._id
      );
      if (filterChairs) {
        console.log("the filter data", filterChairs);
        setChairs(filterChairs);
        toast.success("Chair deleted successfully");
      }

      console.log("chair data", chairData);
      // console.log("filter chair", filterChairs);
      // if (filterChairs) {
      //   setChairs(chairData.data);
      // }
    }
  };

  const filteredChairs = chairs?.filter((chair) =>
    searchItem === null
      ? true
      : chair.label.toLowerCase().includes(searchItem.toLowerCase())
  );

  const [deepChairToEdit, setDeepChairToEit] = useState<IChair>();

  const handleOpenDeepEditionModal = (chairId: string) => {
    const chair = chairs?.find((chair) => chair._id === chairId);
    if (chair) {
      setDeepChairToEit(chair);
    }
  };

  const handleOpenDeepCreateModal = () => {
    setShowModal(true);
  };

  const handleCloseChairToEditModal = () => {
    // Remove related search params data
    // to avoid reloading reproduce the same action
    removeDeepSearchParams();
    setDeepChairToEit(undefined);
  };

  const removeDeepSearchParams = () => {
    searchParams.delete("action");
    searchParams.delete("chair_id");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (salonId && salonId !== "") {
      getChairs(salonId)
        .then((res) => {
          setChairs(res.data);
        })
        .catch((err) => {})
        .finally(() => {});
    }
  }, []);

  // useEffect(() => {
  //   if (salonId && salonId !== "") {
  //     dispatch(getChairs(salonId) as unknown as AnyAction);
  //   }
  // }, [salonId]);

  // Listening for deep link chair edit
  useEffect(() => {
    // Check whether link contains information
    // About chair edition
    // link?action=edit&chair_id=...
    if (chairs) {
      const [action, chairId] = [
        searchParams.get("action"),
        searchParams.get("chair_id"),
      ];
      if (action) {
        if (action === "edit" && chairId) {
          handleOpenDeepEditionModal(chairId);
        } else if (action === "create") {
          // opening create modal
          handleOpenDeepCreateModal();
        }
      }
    }
  }, [chairs]);

  return (
    <AuthLayout>
      <MainNav navType={"connectedNav"} isSearch={false} />
      <div className="row p-0 m-0 BusinessProfilePage">
        <div className="container p-lg-0">
          <div className="my-2 d-flex gap-2 align-items-center  mt-4">
            <span
              onClick={() => navigate("/admin/dashboard")}
              className="cursor-pointer"
            >
              Dashboard
            </span>
            <span className="back-dot"></span>
            <span className="text-black">Seats Allocations</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-2 align-items-center ">
              <h4 className="text-black m-0">Manage seat allocations</h4>
              <span
                style={{ border: "2px solid #e10984" }}
                className="text-primary badge px-2 py-1 rounded-pill"
              >
                {chairs?.length}
              </span>
            </div>
            <div className="d-none d-lg-flex gap-2 align-items-center">
              <button className="working-table-outline-button bg-transparent " onClick={()=>navigate("/manage-availability")} >
                Manage Availability
                {/* <i className="fa fa-angle-down ms-2"></i> */}
              </button>
              <button
                onClick={() => setShowModal(true)}
                className="working-table-primary-button"
              >
                Add New
              </button>
            </div>
            <i className="fa-solid fa-ellipsis-vertical d-block  d-lg-none text-black cursor-pointer"></i>
          </div>
          <div
            style={{ backgroundColor: "#e4e6e9" }}
            className="d-none px-3 py-2 justify-content-between mt-4 d-lg-flex"
          >
            <div className="d-flex align-items-center gap-4">
              <SearchInput
                searchItem={searchItem}
                setSearchItem={setSearchItem}
                placeholder="Search staff members"
                inputClassName="rounded-inpt rounded-end-circle"
                btnBorder="15px 0 0 15px"
              />
              <button
                onClick={() => setShowFilterModal(true)}
                className="rounded-pill py-2 gap-3 px-4 bg-white border d-flex align-items-center text-primary"
              >
                <span>Filter</span>
                <i className="fa fa-sliders"></i>
              </button>
            </div>
            <button className="rounded-pill text-primary gap-3 px-4 bg-white border d-flex align-items-center">
              <span>Name(A-Z)</span>
              <i className="fa fa-arrow-down-wide-short"></i>
            </button>
          </div>
          <div className="d-flex d-lg-none mt-4 gap-2 justify-content-between">
            <SearchInput
              className="w-auto"
              placeholder="Search staff members"
              searchItem={searchItem}
              setSearchItem={setSearchItem}
            />
            <i
              className="fa-sharp fa-thin fa-solid fa-sliders fa-rotate-270 cursor-pointer"
              onClick={() => setShowFilterModal(true)}
            ></i>
          </div>
          {chairs === null && <ChairLoader />}
          {chairs?.length === 0 && (
            <div
              style={{ height: "50vh" }}
              className="d-flex user-select-none text-muted gap-3 align-items-center justify-content-center"
            >
              <i className="fas fa-chair fa-3x mb-2"></i>
              <div className="text-center fs-4">
                You have no seats yet
                <br />
                Manage your seat allocations.
              </div>
            </div>
          )}
          {chairs && (
            <>
              {/* {filteredChairs?.map((chair, index) => ( */}
              <ChairContainer
                chairToEdit={deepChairToEdit}
                onCloseChairToEdit={handleCloseChairToEditModal}
                chairs={chairs ?? []}
                setChairs={setChairs}
                // index={index}
                // chair={chair}
                salonId={salonId}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
              {/* ))} */}
            </>
          )}
          {chairs && chairs.length !== 0 && filteredChairs?.length === 0 && (
            <div className="d-flex user-select-none text-muted gap-2 align-items-center justify-content-center mt-5">
              <i className="fas fa-search-minus fa-3x mb-2"></i>
              <div className="text-center">
                No chair matches your search. Check your spelling or maybe
                <br />
                you didn't add it to your chair list.
              </div>
            </div>
          )}
          <div className="w-100 d-flex d-lg-none my-2 justify-content-end">
            <button
              onClick={() => setShowModal(true)}
              className="btn-primary align-items-center rounded border-0 p-2 d-flex gap-2 text-white"
            >
              <i className="fa fa-plus"></i>
              <span>Add new</span>
            </button>
          </div>
        </div>
        <AddChairModal
          chairs={chairs ?? []}
          setChairs={setChairs}
          show={showModal}
          onClose={() => {
            removeDeepSearchParams();
            setShowModal(false);
          }}
        />
        <FilterChairsModal
          show={showFilterModal}
          onClose={() => setShowFilterModal(false)}
        />
      </div>
    </AuthLayout>
  );
};

export default ChairManagementPage;
