import React from "react";
import { DiscussionSystemProps } from "../../LatriceDiscussionMessage";
import { DisputedDiscussionBase } from "./DisputedDiscussionBase";
import { Alert } from "react-bootstrap";

export type DisputedDiscussionUser = Required<
  Pick<DisputedDiscussionBase, "OnDisputedAction">
>;

export const DisputedDiscussionUser = (
  props: DiscussionSystemProps & DisputedDiscussionUser
) => {
  return (
    <DisputedDiscussionBase
      appointment={props.appointment}
      OnDisputedAction={props.OnDisputedAction}
      alertContainer={
        <Alert
          className="alert-warning--bordered"
          key={"warning"}
          variant={"warning"}
        >
          <i className="fa fa-triangle-exclamation fa-1x"></i>
          The professional has requested changes before accepting your
          appointment for{" "}
          {[
            props.appointment.disputeReasons.badDate ? (
              <strong>date</strong>
            ) : null,
            props.appointment.disputeReasons.badDuration ? (
              <strong> duration</strong>
            ) : null,
            props.appointment.disputeReasons.badDescription ? (
              <strong> description</strong>
            ) : null,
            props.appointment.disputeReasons.priceTooLow ? (
              <strong>price</strong>
            ) : null,
          ]
            .filter((item) => item !== null)
            .reduce((prev, curr, index) => (
              <>
                {prev}
                {index > 0 ? " and " : ""}
                {curr}
              </>
            ))}
          . We are currently awaiting your response to proceed further.
        </Alert>
      }
      actionContainer={
        <div className="d-flex justify-content-start align-items-end">
          <button
            onClick={() => {
              props.OnDisputedAction("accept");
            }}
            className={`LatricePendingAppointment__button accept-button`}
          >
            <i className="fas fa-check"></i> Accept the changes
          </button>
          <button
            onClick={() => {
              props.OnDisputedAction("refuse");
            }}
            className={`LatricePendingAppointment__button refuse-button`}
          >
            <i className="fas fa-times"></i> Refuse the changes
          </button>
        </div>
      }
    />
  );
};
