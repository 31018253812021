import axios from "axios";

// const getPromotionLinks = () => {
//   return axios
//     .get(`links`, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("authToken")}`,
//       },
//     })
//     .then((data) => {
//       return data;
//     });
// };

const getPromotionLink = () => {
  return axios
    .get(`links`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

export const PromotionLinkService = {
  getPromotionLink,
  // getPromotionUserLinks,
};
