import React, { useEffect, useRef, useState } from "react";
import { InputBoolean } from "./input";
import InputStars from "./InputStars";
import { SearchValuesOptions } from "./searchBar/SearchBar";
import GoogleAutocomplete, {
  GoogleAutocompleteLocation,
} from "./searchBar/GoogleAutocomplete";
import { SalonService } from "../services/SalonService";

type SearchDrawerProps = {
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  searchValues: SearchValuesOptions;
  setSearchValues: (value: any) => void;
  handleSearch: (e: any) => void;
};

const SearchDrawer: React.FC<SearchDrawerProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [ratings, setRatings] = useState<string[]>([]);

  const { searchValues, setSearchValues } = props;
  const handleClickOutside = (e: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as Node)
    ) {
      props.setIsOpen(false);
    }
  };

  const handleLocationSelect = (location: GoogleAutocompleteLocation) => {
    props.setSearchValues((values: any) => {
      return {
        ...values,
        lat: location.latLng.lat,
        lng: location.latLng.lat,
        where: location.address,
      };
    });
  };

  const handleChange = (e: any) => {
    const { name, value, type } = e.target;
    props.setSearchValues((prev: any) => ({
      ...prev,
      [name]: type === "checkbox" ? e.target.checked : value,
    }));
  };

  const handleClick = (e: any) => {
    props.setIsOpen(false);
    props.handleSearch(e);
  };

  useEffect(() => {
    if (ratings.length === 0) {
      SalonService.getSalonByRating().then((rating) => {
        if (Array.isArray(rating)) {
          setRatings(rating);
        }
      });
    }
  }, [searchValues.rating]);
  // Add a click event listener to the document
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={
        "drawer bg-white d-flex flex-column gap-3 p-2 " +
        (props.isOpen ? " open" : "")
      }
    >
      <div className="d-flex w-100 justify-content-between">
        <button
          className="border-0 bg-transparent"
          onClick={() => props.setIsOpen(false)}
        >
          <i className="fa-solid fa-xmark  fs-5"></i>
        </button>
        <div>Search by filter</div>
      </div>
      <div className="d-flex gap-2">
        <input
          style={{
            width: "8rem",
          }}
          name="service"
          type="text"
          placeholder="service name"
          className="border p-2 bg-transparent from-control rounded text-black"
          onChange={handleChange}
        />
        <div className="border rounded w-100">
          <GoogleAutocomplete onSelectLocation={handleLocationSelect} />
        </div>
      </div>
      <div className="d-flex gap-2">
        <input
          style={{
            width: "7rem",
          }}
          name="price"
          type="number"
          placeholder="Any price"
          className="border p-2 bg-transparent from-control rounded text-black"
          onChange={handleChange}
        />
        <input
          style={
            {
              // width: "8rem",
            }
          }
          onChange={handleChange}
          type="date"
          name="day"
          className="border p-2 bg-transparent from-control rounded text-black"
          id=""
        />
      </div>
      <div className="d-flex gap-1">
        <label className="d-flex align-items-center gap-1">
          <input
            type="checkbox"
            name="blowdry"
            onChange={handleChange}

            // onChange={(e) => {
            //   setSearchValues({
            //     ...searchValues,
            //     blowdry: e.target.checked ? "true" : "false",
            //   });
            // }}
          />
          <span className="">Blowdry</span>
        </label>
        <label className="d-flex align-items-center gap-1">
          <input
            type="checkbox"
            name="hairwash"
            onChange={handleChange}

            // onChange={(e) => {
            //   setSearchValues({
            //     ...searchValues,
            //     hairwash: e.target.checked ? "true" : "false",
            //   });
            // }}
          />
          <span className="">Hairwash</span>
        </label>
        <label className="d-flex align-items-center gap-1">
          <input
            type="checkbox"
            name="undo_hair"
            onChange={handleChange}

            // onChange={(e) => {
            //   setSearchValues({
            //     ...searchValues,
            //     undo_hair: e.target.checked ? "true" : "false",
            //   });
            // }}
          />
          <span className="">Undo hair</span>
        </label>
      </div>
      <div className="d-flex gap-1">
        <label className="d-flex align-items-center gap-2">
          <input
            className="form-check-input mt-0"
            type="radio"
            name="account_type"
            value="business_owner"
            id="filterOption2"
            onChange={handleChange}

            // onChange={(e: any) =>
            //   setSearchValues({
            //     ...searchValues,
            //     account_type: e.target.value,
            //   })
            // }
          />
          <span className="">Business owner</span>
        </label>
        <label className="d-flex align-items-center gap-1">
          <input
            className="form-check-input mt-0"
            type="radio"
            name="account_type"
            value="Independent"
            id="filterOption1"
            onChange={handleChange}

            // onChange={(e: any) =>
            //   setSearchValues({
            //     ...searchValues,
            //     account_type: e.target.value,
            //   })
            // }
          />
          <span className="">Independent</span>
        </label>
      </div>
      <label>
        <span className="">Rating:</span>
        <InputStars
          defaultValue={0} // Set your default rating value
          onChange={(value) => {
            props.setSearchValues((prev: any) => {
              return { ...prev, rating: value };
            });
          }}
        />
      </label>
      <label>
        <InputBoolean
          onChange={(value) => {
            props.setSearchValues((prev: any) => {
              return { ...prev, wheelchair_accessible: value };
            });
          }}
          value={false}
          title={"Wheelchair accessible?"}
          name="wheelchair_accessible"
        />
      </label>
      <div className="d-flex gap-2">
        <label>
          <InputBoolean
            onChange={(value) => {
              props.setSearchValues((prev: any) => {
                return { ...prev, kids_friendly: value };
              });
            }}
            title={"Kids friendly ?"}
            value={false}
            name="kids_friendly"
          />
        </label>
        <label>
          <InputBoolean
            title={"Kids Service ?"}
            onChange={(value) => {
              props.setSearchValues((prev: any) => {
                return { ...prev, kids_service: value };
              });
            }}
            value={false}
            name="kids_service"
          />
        </label>
      </div>
      <label>
        <InputBoolean
          onChange={(value) => {
            props.setSearchValues((prev: any) => {
              return { ...prev, drive_to_your_place: value };
            });
          }}
          value={false}
          title={
            <div className="text-primary d-inline-flex">Home service ?</div>
          }
          name="drive_to_your_place"
        />
      </label>
      <div className="py-3 d-flex justify-content-center w-100">
        <button
          onClick={handleClick}
          className="pay-btn w-100 p-2 rounded small-text"
        >
          Validate
        </button>
      </div>
    </div>
  );
};

export default SearchDrawer;
