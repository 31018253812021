import { NavLink } from "react-router-dom";

export interface NavigationLinkProps {
  to: string;
  label: any;
  className: string;
}

const NavigationLink = ({ to, label, className }: NavigationLinkProps) => (
  <NavLink className={className} to={to}>
    {label}
  </NavLink>
);

export default NavigationLink;
