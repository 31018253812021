import React, { HTMLProps, useState, useEffect } from "react";
import Modal from "./Modal";
import "./../styles/RequestCustomModal.css";
import moment from "moment";
import { ModalLoader } from "./commons/ModalLoader";
import {
  IProject,
  IProjectFormData,
  TProjectService,
  TProjectTarget,
} from "../../models/Project";
import Welcome from "../post-project/Welcome";
import { Step, Stepper } from "react-form-stepper";
import { StepperProps } from "react-form-stepper/dist/components/Stepper/StepperTypes";
import { NextButton, PrevButton } from "../post-project/NextButton";
import { AddServiceStep } from "../post-project/Step/AddServiceStep";
import {
  ServiceDetailsStep,
  TServiceDetailsData,
} from "../post-project/Step/ServiceDetailsStep";
import { DateTimeStep } from "../post-project/Step/DateTimeStep";
import { SelectProfessionalStep } from "../post-project/Step/SelectProfessionalStep";
import {
  AdditionalInfoStep,
  AdditionalInfoStepProps,
} from "../post-project/Step/AdditionnalInfoStep";
import { AddingService } from "../post-project/AddingService";
import toast from "react-hot-toast";
import { TSalonAccountType } from "../../utils/types";
import ValidationStep from "../post-project/Step/ValidationStep";
import { ProjectService } from "../../services/ProjectService";
import ValidationSuccessStep from "../post-project/Step/ValidationSuccessStep";
import { isUserProfessional } from "../../models/User";
import { useUser } from "../../hooks/UseUser";

interface PostProjectModalProps extends HTMLProps<HTMLDivElement> {
  backdrop: boolean;
  onClose: () => void;
  project: IProject;
  updateProduct: (project: IProject) => void;
}

const EditProjectModal: React.FC<PostProjectModalProps> = ({
  ...props
}: PostProjectModalProps) => {
  const user = useUser();
  const userIsProfessional = isUserProfessional(user);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment());
  const [selectedDate, setSelectedDate] = useState<moment.Moment | undefined>(
    undefined
  );
  const [selectedTime, setSelectedTime] = useState<moment.Moment | undefined>(
    undefined
  );

  const [selectedMonthDates, setSelectedMonthDates] = useState<moment.Moment[]>(
    []
  );

  const getAllDatesInMonth = (
    selectedMonth: moment.Moment
  ): moment.Moment[] => {
    const startOfMonth = moment(selectedMonth).startOf("month");
    const endOfMonth = moment(selectedMonth).endOf("month");
    const currentDate = moment().startOf("day"); // Get the current date with time set to midnight
    const datesInMonth: moment.Moment[] = [];

    let currentDay = startOfMonth.clone();
    while (currentDay.isSameOrBefore(endOfMonth, "day")) {
      // Check if the current day is before the current date
      if (currentDay.isSameOrAfter(currentDate, "day")) {
        datesInMonth.push(currentDay.clone());
      }
      currentDay.add(1, "day");
    }

    return datesInMonth;
  };

  const handleSelectDay = (day: moment.Moment) => {
    setSelectedDate(day);
    handleLoading(false);
  };

  const handleLoading = (state: boolean) => {
    setLoading(state);
  };

  useEffect(() => {
    const datesInMonth = getAllDatesInMonth(selectedMonth);
    setSelectedMonthDates(datesInMonth);
    return () => {
      setSelectedMonthDates([]);
    };
  }, [selectedMonth]);

  const [activeStep, setStep] = useState(0);

  const styleConfig: StepperProps["styleConfig"] = {
    completedBgColor: "#e10984",
    completedTextColor: "#ffffff",
    activeBgColor: "#000",
    activeTextColor: "#fff",
    inactiveBgColor: "#e10984",
    inactiveTextColor: "#ffffff",
    size: "2em",
    circleFontSize: "1rem",
    labelFontSize: "0.875rem",
    borderRadius: "50%",
    fontWeight: 500,
  };

  const stepsList = [
    "Add services",
    "Service details",
    "Date & Time",
    "Select target",
    "Additional infos",
    "Validation",
  ];

  const [addingService, setAddingService] = useState(false);

  const [addedServices, setAddedServices] = useState<TProjectService[]>([]);

  const [servicesDetails, setServicesDetails] = useState<TServiceDetailsData>({
    description: null,
    file: null,
  });

  const [accountType, setAccountType] = useState<TSalonAccountType>();

  const [additionalInfos, setAdditionalInfos] = useState<
    Omit<AdditionalInfoStepProps, "accountType">
  >({
    driveToYourPlace: undefined,
    freeParkingSpot: undefined,
    specialEvent: undefined,
    additionalExpense: undefined,
    location: null,
  });

  const project: IProjectFormData = {
    drive_to_your_place:
      accountType === "business_owner"
        ? false
        : (additionalInfos.driveToYourPlace as boolean),
    price: addedServices.reduce((prev, curr) => prev + curr.price, 0),
    image: servicesDetails.file as File,
    day: selectedDate?.format("YYYY-MM-DD") as string,
    startTime: selectedTime?.format("H:mm") as string,
    lat: additionalInfos.location?.latLng.lat,
    lng: additionalInfos.location?.latLng.lng,
    address: "",
    professional_type: accountType as TProjectTarget,
    services: addedServices,
    user: "",
    status: "selected",
    is_special_event: additionalInfos.specialEvent as boolean,
    description: servicesDetails.description as string,
  };

  const [errors, setErrors] = useState<string[]>();
  const [projectSubmitted, setProjectSubmitted] = useState(false);

  const handleDeleteService = (key: number) => {
    setAddedServices(addedServices.filter((_, n) => n !== key));
  };

  const handleChangeAccountType = (type: TSalonAccountType) => {
    setAccountType(type);
  };

  const handleAdditionalInfoChange = (data: AdditionalInfoStepProps) => {
    setAdditionalInfos(data);
  };

  const handlePostProject = () => {
    setErrors(undefined);
    toast.remove();
    setLoading(true);

    const projectFormData = new FormData();

    // projectFormData.append("id", props.project._id);

    projectFormData.append(
      "drive_to_your_place",
      String(
        accountType === "business_owner"
          ? false
          : additionalInfos.driveToYourPlace
      )
    );
    projectFormData.append(
      "price",
      String(addedServices.reduce((prev, curr) => prev + curr.price, 0))
    );
    if (servicesDetails.file) {
      projectFormData.append("image", servicesDetails.file);
    }
    projectFormData.append("day", selectedDate?.format("YYYY-MM-DD") as string);
    projectFormData.append(
      "startTime",
      moment.utc(moment(project.startTime, "H:mm")).format("H:mm")
    );
    if (additionalInfos.location?.latLng.lat) {
      projectFormData.append(
        "lat",
        String(additionalInfos.location?.latLng.lat)
      );
    }
    if (additionalInfos.location?.latLng.lng) {
      projectFormData.append(
        "lng",
        String(additionalInfos.location?.latLng.lng)
      );
    }
    projectFormData.append("professional_type", accountType as TProjectTarget);
    projectFormData.append(
      "is_special_event",
      String(additionalInfos.specialEvent as boolean)
    );
    if (additionalInfos.additionalExpense) {
      projectFormData.append(
        "event_extra_fee",
        String(additionalInfos.additionalExpense)
      );
    }
    if (servicesDetails.description) {
      projectFormData.append(
        "description",
        String(servicesDetails.description)
      );
    }
    projectFormData.append("services", JSON.stringify(addedServices));

    if (userIsProfessional) {
      projectFormData.append("IsProfessional", "true");
    }

    ProjectService.editProject(projectFormData, props.project._id!)
      .then((res: any) => {
        props.updateProduct(res.data);
        props.onClose();
        setProjectSubmitted(true);
      })
      .catch((err: any) => {
        const errors = err?.response?.data?.errors;
        if (errors) {
          const errorsEntries = Object.entries(errors).map(([_, message]) => {
            return message as string;
          });
          setErrors(errorsEntries);
        } else {
          toast.error("An error occurred, please retry again.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const stepListComponents = [
    <AddServiceStep
      onRemoveService={handleDeleteService}
      onAdd={() => setAddingService(true)}
      services={addedServices}
      key={"step-AddServiceStep"}
    />,
    <ServiceDetailsStep
      defaultValue={servicesDetails}
      onChange={setServicesDetails}
      key={"step-ServiceDetailsStep"}
    />,
    <DateTimeStep
      selectedDate={selectedDate}
      selectedMonthDates={selectedMonthDates}
      onSelectDay={handleSelectDay}
      onMonthChange={setSelectedMonth}
      selectedTime={selectedTime}
      onSelectTime={setSelectedTime}
      key={"step-DateTimeStep"}
    />,
    <SelectProfessionalStep
      selected={accountType}
      onSelect={handleChangeAccountType}
      key={"step-SelectProfessionalStep"}
    />,
    <AdditionalInfoStep
      key={"step-AdditionalInfoStep"}
      {...additionalInfos}
      accountType={accountType as TSalonAccountType}
      onDataChange={handleAdditionalInfoChange}
    />,
    <ValidationStep
      key={"step-ValidationStep"}
      errors={errors}
      projectDetails={{
        numberOfProjects: addedServices.length,
        date: selectedDate as moment.Moment,
        time: selectedTime as moment.Moment,
        target: accountType as TSalonAccountType,
        addedServices: addedServices as TProjectService[],
        totalPrice:
          addedServices.reduce((prev, curr) => {
            return prev + curr.price;
          }, 0) + (additionalInfos.additionalExpense ?? 0),
      }}
      onPost={handlePostProject}
      editState={true}
    />,
  ];

  const stepErrorMessages = [
    "Please add minimum one service",
    "Please fill in the correct services details",
    "Please select time and day",
    "Please select target account",
    "Please fill in the correct additional information",
  ];

  const handleNextStep = () => {
    setStep(activeStep === stepsList.length - 1 ? activeStep : activeStep + 1);
  };

  const handlePrevStep = () => {
    setStep(activeStep === 0 ? 0 : activeStep - 1);
  };

  const handleAddService = (project: TProjectService) => {
    setAddedServices([...addedServices, project]);
    setAddingService(false);
  };

  const handleNextClick = () => {
    toast.remove();
    setErrors(undefined);
    if (canGoToNextStep()) {
      console.log("yess can go next ...");
      return handleNextStep();
    }
    toast.error(stepErrorMessages[activeStep]);
  };

  const canGoToNextStep = () => {
    if (activeStep === 0) {
      return addedServices.length !== 0;
    } else if (activeStep === 1) {
      return servicesDetails.description !== null ? true : true;
    } else if (activeStep === 2) {
      return selectedDate && selectedTime;
    } else if (activeStep === 3) {
      return accountType;
    } else if (activeStep === 4) {
      let canGo = true;
      if (accountType === "business_owner") {
        canGo &&=
          additionalInfos.freeParkingSpot !== undefined &&
          additionalInfos.specialEvent !== undefined;
      } else {
        canGo &&=
          additionalInfos.freeParkingSpot !== undefined &&
          additionalInfos.driveToYourPlace !== undefined &&
          additionalInfos.specialEvent !== undefined;
      }

      if (additionalInfos.specialEvent) {
        canGo &&= additionalInfos.additionalExpense !== undefined;
      }

      if (additionalInfos.driveToYourPlace) {
        canGo &&= additionalInfos.location !== null;
      }

      return canGo;
    }
    return false;
  };

  //   React.useEffect(() => {
  //     if (activeStep === 0 && addedServices.length === 0) {
  //       setAddingService(true);
  //     }
  //   }, [activeStep, addedServices.length]);

  React.useEffect(() => {
    return () => {
      setErrors(undefined);
    };
  }, []);

  React.useEffect(() => {
    setAddedServices(props.project.services);
    setServicesDetails({
      description: props.project.description!,
      file: null,
    });
    setSelectedDate(moment(props.project.day));
    setSelectedMonth(moment(props.project.day));
    setSelectedTime(moment(props.project.startTime));
    setAccountType(props.project.professional_type);
    setAdditionalInfos({
      driveToYourPlace: props.project.drive_to_your_place,
      freeParkingSpot: props.project.drive_to_your_place,
      specialEvent: props.project.is_special_event,
      additionalExpense: props.project.traveling_expenses,
      location: {
        latLng: { lat: props.project.lat!, lng: props.project.lng! },
        address: "point",
      },
    });
  }, []);

  return (
    <Modal {...props} className="modal-lg" showCloseButton={!loading}>
      {loading && <ModalLoader />}

      <div className="position-relative" style={{ minHeight: "70vh" }}>
        {addingService && (
          <AddingService
            onAddService={handleAddService}
            onBack={() => setAddingService(false)}
          />
        )}

        {!projectSubmitted && (
          <div
            style={{ minHeight: "70vh", width: "95%" }}
            className="mx-auto d-flex flex-column   align-items-between"
          >
            <Stepper
              connectorStateColors
              styleConfig={styleConfig}
              activeStep={activeStep}
            >
              {stepsList.map((item, key) => (
                <Step key={key} label={item} />
              ))}
            </Stepper>
            <div style={{ flex: 1 }} className="flex-1">
              {stepListComponents[activeStep]}
            </div>
            <div className="w-100  mt-auto d-flex justify-content-between align-items-center">
              <div>
                {activeStep !== 0 && <PrevButton onClick={handlePrevStep} />}
              </div>
              <div>
                {activeStep !== stepsList.length - 1 && (
                  <NextButton onClick={handleNextClick} />
                )}
              </div>
            </div>
          </div>
        )}

        {projectSubmitted && (
          <div style={{ minHeight: "70vh" }} className="container">
            <ValidationSuccessStep editState={true} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default React.memo(EditProjectModal);
