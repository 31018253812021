import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import authReducer from "./slices/authSlice";
import serviceNamesReducer from "./slices/serviceNameSlice";
import serviceSlice from "./slices/serviceSlice";
import appointmentSlice from "./slices/appointmentSlice";
import availabilitySlice from "./slices/availabilitySlice";
import unAvailabilitySlice from "./slices/unAvailabilitySlice";
import reviewSlice from "./slices/reviewSlice";
import accountSlice from "./slices/accountSlice";
import appointmentCancellationSlice from "./slices/appointmentCancellationSlice";
import customAppointmentSlice from "./slices/customAppointmentSlice";
import notificationSlice from "./slices/notificationSlice";
import discussionSlice from "./slices/discussionSlice";
import projectSlice from "./slices/projectSlice";
import managerSlice from "./slices/managerSlice";
import chairSlice from "./slices/chairSlice";
import memberSlice from "./slices/memberSlice";
import salonSlice from "./slices/salonSlice";
import PromotionLinkSlice from "./slices/PromotionLinkSlice";
import paymentMethodSlice from "./slices/paymentMethodSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    serviceNames: serviceNamesReducer,
    services: serviceSlice,
    appointments: appointmentSlice,
    customAppointments: customAppointmentSlice,
    reviews: reviewSlice,
    account: accountSlice,
    appointmentCancellation: appointmentCancellationSlice,
    availabilities: availabilitySlice,
    unavailabilities: unAvailabilitySlice,
    notifications: notificationSlice,
    discussions: discussionSlice,
    projects: projectSlice,
    managers: managerSlice,
    chairs: chairSlice,
    myMember: memberSlice,
    salons: salonSlice,
    promotionLinks: PromotionLinkSlice,
    paymentMethod: paymentMethodSlice,
  },
  devTools: process.env.NODE_ENV === "development",
});

setupListeners(store.dispatch);
