import React, { useEffect } from "react";
import CertificationBadge from "./CertificationBadge";
import { LatriceDiscussionUsers } from "./LatriceDiscussionUsers";
import User from "../../models/User";
import socket from "../../socket/socket";
import { EVENT_CODE } from "../../socket/event";
import { Dropdown } from "react-bootstrap";
import "./../styles/ChatHeader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faLocationCrosshairs,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { ModalChatHeaderAppointment } from "./modal/ModalChatHeaderAppointment";
import {
  ChatHeaderActionProps,
  ChatHeaderAppointmentAction,
} from "./ChatHeaderAction";
import { USATimezone } from "../../utils/types";
import { timeToTimezone } from "../../utils/utils";

export type ChatHeaderProps = {
  selectedDiscussion: any;
  currentUserIsProfessional: boolean;
  handleCloseDiscussion: () => void;
  onSelectUser: (selectedUser: User | null) => void;
} & Partial<
  Pick<
    ChatHeaderActionProps,
    "onMarkAppointmentHasEnded" | "onMarkAppointmentHasStarted"
  >
>;

export const ChatHeader: React.FC<ChatHeaderProps> = ({
  selectedDiscussion,
  currentUserIsProfessional,
  onSelectUser,
  handleCloseDiscussion,
  ...props
}) => {
  const [timezone, setTimezone] = React.useState<USATimezone>("US/Eastern");

  const appointment = {
    ...selectedDiscussion?.discussion?.appointment,
    startTime: timeToTimezone(
      selectedDiscussion?.discussion?.appointment.startTime,
      timezone
    ),
    endTime: timeToTimezone(
      selectedDiscussion?.discussion?.appointment.endTime,
      timezone
    ),
  };

  //   appointment.startTime = timeToTimezone(selectedDiscussion?.discussion?.appointment.startTime, timezone);
  //   appointment.endTime = timeToTimezone(selectedDiscussion?.discussion?.appointment.endTime, timezone);

  console.log("selectedDiscussion", selectedDiscussion);
  const [openedModal, setOpenedModal] = React.useState({
    appointment: false,
    location: false,
  });

  const handleOpenAppointment = () => {
    setOpenedModal((opened) => {
      return { ...opened, appointment: true };
    });
  };

  const handleOpenLocation = () => {
    setOpenedModal((opened) => {
      return { ...opened, location: true };
    });
  };

  React.useEffect(() => {
    socket().on(EVENT_CODE.TYPING, (...args: any[]) => {
      console.log(args);
    });
  }, []);





  return (
    <div className="ChatHeader d-flex w-100 justify-content-between shadow align-items-center">
      <div className="d-flex flex-row align-items-center gap-3 p-2">
        <div onClick={handleCloseDiscussion} className="cursor-pointer">
          <i className="fa-solid fa-arrow-left"></i>
        </div>
        <img
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
          }}
          alt={`${selectedDiscussion.meta.first_name} ${selectedDiscussion.meta.last_name}`}
          src={selectedDiscussion.meta.profile}
        />
        <div className="d-flex flex-column">
          <span
            className={`text-black d-flex gap-1 ${
              selectedDiscussion.meta.system ? "fw-500" : ""
            }`}
          >
            {`${selectedDiscussion.meta.first_name} ${selectedDiscussion.meta.last_name}`}
            {selectedDiscussion.meta.system ? <CertificationBadge /> : null}
          </span>
          <span className="onlineStatus onlineStatus__active small">
            online
          </span>
        </div>
      </div>

      {selectedDiscussion.meta.system ? (
        <div className="ms-auto w-100 p-1">
          <LatriceDiscussionUsers
            appointments={selectedDiscussion.meta.appointments}
            onSelect={onSelectUser}
            currentUserIsProfessional={currentUserIsProfessional}
          />
        </div>
      ) : (
        <div className="ms-auto">
          {props.onMarkAppointmentHasStarted &&
            props.onMarkAppointmentHasEnded && (
              <ChatHeaderAppointmentAction
                targetDate={moment(
                  `${appointment.day} ${appointment.startTime}`,
                  "YYYY-MM-DD H:mm A"
                )}
                appointment={appointment}
                onMarkAppointmentHasStarted={props.onMarkAppointmentHasStarted}
                onMarkAppointmentHasEnded={props.onMarkAppointmentHasEnded}
              />
            )}
        </div>
      )}

      {!selectedDiscussion.meta.system && (
        <div className="p-2 me-3">
          <Dropdown>
            <Dropdown.Toggle
              id="dropdown-discussion-menu"
              variant={"outline-light"}
            >
              <i className="fa-solid fa-ellipsis-vertical text-primary"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={handleOpenAppointment}
                className="d-flex gap-1 justify-content-start align-items-center"
              >
                <FontAwesomeIcon icon={faLocationCrosshairs} />
                See location
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={handleOpenAppointment}
                className="d-flex gap-1 justify-content-start align-items-center"
              >
                <FontAwesomeIcon icon={faBookOpen} />
                Appointment
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      {openedModal.appointment && (
        <ModalChatHeaderAppointment
          appointment={appointment}
          onHide={() =>
            setOpenedModal((prev) => {
              return { ...prev, appointment: false };
            })
          }
        />
      )}
    </div>
  );
};

export default ChatHeader;
