
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

export interface TokenExpiredModalProps {
    title: string,
    message: string,
    errorCode?: string,
}


const TokenExpiredModal: React.FC<TokenExpiredModalProps> = ({ title, message }: TokenExpiredModalProps) => {

    const navigate = useNavigate();
    const currentLocation = window.location.href;

    const handleLogin = () => navigate(`/login?ref=${currentLocation}`);

    return <Modal style={{ backdropFilter: 'blur(2px)' }} backdrop='static' show={true} centered>
        <Modal.Header>
            <Modal.Title> <i className="fa fa-triangle-exclamation"></i> {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='p-3 pt-3'>{message}</div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-dark" onClick={handleLogin} className='w-50'>
                Login
            </Button>
        </Modal.Footer>
    </Modal>
}

export default TokenExpiredModal;