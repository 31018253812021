import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import {
  navigationLinks2,
  navigationLinks4,
  navigationLinks5,
  restrictUserRoutes,
} from ".";
import SearchBar from "../searchBar/SearchBar";
import NavbarSearch from "./NavbarSearch";
import NavigationLink from "./NavigationLink";
import { useDispatch, useSelector } from "react-redux";
import NotificationDrawer from "../NotificationDrawer";
import { faBell, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { CartModal } from "../modal/CartModal";
import { useUser } from "../../hooks/UseUser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { logout } from "../../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import UserOptions from "./UserOptions";
import {
  isUserBusinessOwner,
  isUserIndependent,
  isUserManager,
  isUserProfessional,
} from "../../models/User";
import { v4 as uuidv4 } from "uuid";
import { useSalon } from "../../context/SalonContext";
import { useLatriceUser } from "../../hooks/useLatriceUser";

type ConnectedNavBarProps = {
  search: boolean;
};

const ConnectedNavBar: React.FC<ConnectedNavBarProps> = ({ search }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openCartModal, setOpenCartModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUser();
  const { salon } = useSalon();
  const LatriceUser = useLatriceUser();

  const isProfessional =
    isUserManager(user) || isUserIndependent(user) || isUserBusinessOwner(user);

  const notifications = useSelector(
    (state: any) => state.notifications
  ).notifications;
  const unReadNotifications = useSelector(
    (state: any) => state.notifications
  ).unReadNotifications;

  const handleLogout = async () => {
    toast.loading("Signing out...");
    dispatch(logout() as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        window.localStorage.removeItem("salonId");
        window.localStorage.removeItem("authToken");
        navigate("/");
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        toast.remove();
      });
  };

  return (
    <div className="w-100">
      <div className="d-flex flex-lg-row flex-column align-items-center w-100 justify-content-center gap-6">
        <div className="d-flex flex-column w-100">
          {!search && (
            <div className="navbar-nav ml-md-auto justify-content-center">
              {restrictUserRoutes(
                navigationLinks2,
                isUserProfessional(user),
                LatriceUser,
              ).map((link, index: number) => (
                <NavigationLink
                  key={uuidv4()}
                  className="nav-link text-white mx-lg-3"
                  to={link.to}
                  label={link.label}
                />
              ))}
            </div>
          )}
          <hr
            className="m-0 ms-2"
            style={{ height: "1px", backgroundColor: "green" }}
          />

          {/* SearchBar */}

          {search ? (
            <div className="d-none d-lg-block">
              <SearchBar location="navBar" />
            </div>
          ) : (
            <NavbarSearch />
          )}

          {search && (
            <>
              <hr className="m-0 ms-2 text-white" style={{ height: "1px" }} />
              <div className="navbar-nav ml-md-auto d-flex justify-content-between">
                {restrictUserRoutes(
                  navigationLinks4,
                  isUserProfessional(user)
                ).map((link, index: number) => (
                  <NavigationLink
                    key={uuidv4()}
                    className={`nav-link text-white${
                      index === 0 ? "" : " mx-lg-3"
                    }`}
                    to={link.to}
                    label={link.label}
                  />
                ))}
              </div>
            </>
          )}
        </div>

        {/* Notification elements */}
        <div
          className="search_resposive d-lg-flex align-items-center text-nowrap row-gap-2 accordion             "
          style={{ width: "auto" }}
        >
          <UserOptions className="mb-1" handleLogout={handleLogout} />
          <div>
            {isProfessional
              ? [
                  <span className=" text-white ">
                    {user?.first_name} {user?.last_name}
                  </span>,
                ]
              : []}
            {/* {user?.first_name} {user?.last_name} */}
          </div>
        </div>
        <div className="d-flex px-3">
          {restrictUserRoutes(navigationLinks5, isUserProfessional(user)).map(
            (link, index) => {
              if (link.to === "/signin") {
                return (
                  <div
                    key={uuidv4()}
                    onClick={() => setOpenCartModal(true)}
                    className="nav-link text-white d-none d-lg-block"
                  >
                    <FontAwesomeIcon
                      icon={faCartShopping}
                      className="nav-link-icon nav-link-icon-badge flex-fill mx-3"
                      data-count="38"
                    />
                  </div>
                );
              }

              return (
                <NavigationLink
                  key={uuidv4()}
                  className={link.className as string}
                  to={link.to}
                  label={
                    <FontAwesomeIcon
                      icon={link.icon as IconProp}
                      className="nav-link-icon nav-link-icon-badge flex-fill"
                      data-count={link.count}
                    />
                  }
                />
              );
            }
          )}
          <div className="position-relative">
            <div
              className="nav-link text-white px-0 m-0 mr-lg-3 d-none d-lg-block"
              onClick={() => setIsOpen(true)}
            >
              <FontAwesomeIcon
                icon={faBell}
                className="nav-link-icon flex-fill"
              />
            </div>
            {notifications && unReadNotifications !== 0 && (
              <div
                style={{
                  height: "1rem",
                  width: "1rem",
                  border: "1px solid var(--primaryColor)",
                  top: "0",
                  right: "-10px",
                }}
                className="bg-white p-2 rounded-circle d-flex align-items-center small-text justify-content-center position-absolute "
              >
                {unReadNotifications < 100 ? unReadNotifications : "99+"}
              </div>
            )}
          </div>
        </div>
      </div>
      <Tooltip />
      <Toaster
        position="bottom-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#f51e91",
              secondary: "#e10984",
            },
          },
        }}
      />
      <NotificationDrawer
        isOpen={isOpen}
        notifications={notifications}
        setIsOpen={setIsOpen}
        unReadNotifications={unReadNotifications}
      />
      <CartModal
        show={openCartModal}
        className="modal-lg"
        style={{ display: "block !important" }}
        onBack={() => setOpenCartModal(false)}
      />
    </div>
  );
};

export default ConnectedNavBar;
