import './App.css';
import { log } from './utils/utils';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    log('App mounted');
  }, []);
  

  
  return (
    <div className="App">
      salut the app is mounted haha !!!
    </div>
  );
}

export default App;
