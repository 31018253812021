import { HTMLProps, useState, useEffect, useRef } from "react";
import Button from "../Button";
import { InputBoolean } from "../input";
import Modal from "./Modal";
import { toast } from "react-hot-toast";
import "./../styles/RequestCustomModal.css";
import "./../styles/BookNowModal.css";
import Time, { mutedRangesType, TimeOfDay } from "../reusable/Time";
import ProfileInfo from "../reusable/ProfileInfo";
import {
  calculateTravelExpenses,
  formatUSPrice,
  groupTimeSlotsByDate,
} from "../../utils/utils";
import { ProfessionalService } from "../../services/ProfessionalService";
import moment from "moment";
import Service from "../../models/Service";
import { TimeSlotGroup, TimeSlot } from "../../models/TimeSlot";
import { useDispatch } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import CustomDropdown, { Item } from "../reusable/CustomDropdown";
import SelectSize from "../SelectSize";
import { addCustomAppointment } from "../../redux/slices/customAppointmentSlice";
import MapSelection from "../map/MapSelection";
import {
  CustomAppointment,
  CustomAppointmentValues,
} from "../../models/CustomAppointment";
import DatePicker from "../dates&time/DatePicker";
import TimePicker from "../dates&time/TimePicker";
import { IBookMakable } from "../BookmarkButton";
import React from "react";
import { ISalonProfile } from "../../pages/SeeProfessionalAccountPage";
import { ISalon } from "../../models/SalonX";
import { IChair } from "../../models/Chair";
import ChairSelectionForm from "./commons/ChairSelection";


type RequestCustomModalProps = HTMLProps<HTMLDivElement> & {
  backdrop: boolean;
  showChairSelectionForm?: boolean;
  onSelectChair?: (chair: IChair) => void;
  salon: ISalonProfile;
  onClose: () => void;
} & IBookMakable


type RangeInterval = {
  morning: { start: string; end: string };
  afternoon: { start: string; end: string };
  evening: { start: string; end: string };
};


const RequestCustomModal: React.FC<RequestCustomModalProps> = ({
  salon,
  ...props
}: RequestCustomModalProps) => {
  const [time, setTime] = useState<TimeOfDay>("morning");
  const [mutedRanges, setMutedRanges] = useState<mutedRangesType>({
    morning: false,
    afternoon: false,
    evening: false,
  });
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [totalHours, setTotalHours] = useState<number>(4 * 60);
  const [selectedChair, setSelectedChair] = useState<IChair | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [timeSlots, setTimeSlots] = useState<TimeSlotGroup | null>(null);
  const [noSlots, setNoSlots] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment());
  const [selectedDate, setSelectedDate] = useState<moment.Moment | undefined>(
    undefined
  );
  const [selectedTime, setSelectedTime] = useState<moment.Moment | undefined>(
    undefined
  );
  const [selectedMonthDates, setSelectedMonthDates] = useState<moment.Moment[]>(
    []
  );
  const [selectedDateTimes, setSelectedDateTimes] = useState<moment.Moment[]>(
    []
  );
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [position, setPosition] = useState(
    salon !== null
      ? {
        center: {
          lat: salon.location.coordinates[0],
          lng: salon.location.coordinates[1],
        },
        zoom: 9,
      }
      : null
  );
  const [filteredArray, setFilteredArray] = useState<moment.Moment[]>([]);
  const [customAppointmentData, setCustomAppointmentData] =
    useState<Partial<CustomAppointment> | null>(CustomAppointmentValues);

  const serviceNameData = salon.services;

  const dispatch = useDispatch();

  const getAllDatesInMonth = (
    selectedMonth: moment.Moment
  ): moment.Moment[] => {
    const startOfMonth = moment(selectedMonth).startOf("month");
    const endOfMonth = moment(selectedMonth).endOf("month");
    const currentDate = moment().startOf("day"); // Get the current date with time set to midnight
    const datesInMonth: moment.Moment[] = [];

    let currentDay = startOfMonth.clone();
    while (currentDay.isSameOrBefore(endOfMonth, "day")) {
      // Check if the current day is before the current date
      if (currentDay.isSameOrAfter(currentDate, "day")) {
        datesInMonth.push(currentDay.clone());
      }
      currentDay.add(1, "day");
    }

    return datesInMonth;
  };

  const getSlotInterval = (
    service: Service,
    searchPosition: number,
    daySlots: Omit<TimeSlot, "date">[]
  ): {
    time: { start: moment.Moment | null; end: moment.Moment | null };
    position: { start: number; end: number };
  } => {
    const duration = service.duration as unknown as number;
    let time: { start: moment.Moment | null; end: moment.Moment | null } = {
      start: null,
      end: null,
    };
    let position = { start: 0, end: 0 };

    for (let i = searchPosition; i < daySlots.length; i++) {
      const slot = daySlots[i];

      if (slot.isAvailable) {
        if (time.start === null) {
          position.start = i;
          time.start = moment(slot.time, "HH:mm A");
        } else {
          position.end = i;
          time.end = moment(slot.time, "HH:mm A");
        }

        if (
          time.end !== null &&
          time.start !== null &&
          time.end.diff(time.start, "minutes") >= duration
        ) {
          break;
        }
      } else {
        break;
      }
    }
    if (
      time.end === null &&
      time.start === null &&
      searchPosition < daySlots.length
    )
      return getSlotInterval(service, searchPosition + 1, daySlots);

    return { time, position };
  };

  const isPossibleStartTime = (
    duration: number,
    slot: moment.Moment,
    slots: moment.Moment[]
  ) => {
    let possible = false;
    let time = 0;
    let lastSlot = slot;

    for (const item of slots) {
      const diffInMinutes = item.diff(lastSlot, "minutes");

      if (diffInMinutes === 15) {
        time += 15;
      } else if (diffInMinutes < 15) {
        time += diffInMinutes;
      } else {
        break;
      }

      lastSlot = item;

      if (time === duration) {
        possible = true;
        break;
      }
    }

    return possible;
  };

  // Get possible start time based on total services duration
  // and current time slots
  const getPossibleStartTime = (
    duration: number,
    slots: moment.Moment[]
  ): moment.Moment[] => {
    const possibleStartTimes: moment.Moment[] = [];

    for (let i = 0; i < slots.length; i++) {
      if (isPossibleStartTime(duration, slots[i], slots.slice(i + 1))) {
        possibleStartTimes.push(slots[i]);
      }
    }

    return slots.filter((slot) => moment().diff(slot, "minutes") < 0);
  };

  const __handleSelectDay = (
    day: moment.Moment,
    _timeSlots: typeof timeSlots,
    defaultServices: Service[] | null = null
  ) => {
    if (_timeSlots) {
      let currentDaySlots = _timeSlots[day.format("YYYY-MM-DD")];
      if (currentDaySlots) {
        currentDaySlots = currentDaySlots.filter(
          (slot) => slot.isAvailable === true
        );
      } else {
        currentDaySlots = [];
      }

      if (currentDaySlots && currentDaySlots.length > 0) {
        let dates = currentDaySlots
          .slice()
          .map((slot) =>
            moment(
              `${day.format("YYYY-MM-DD")} ${slot.time}`,
              "YYYY-MM-DD HH:mm A"
            )
          );
        dates = getPossibleStartTime(4 * 60, dates);
        setSelectedDateTimes(dates);
        setSelectedDate(dates[0]);

        let lastSlotPosition = 0;
        const daySlots = currentDaySlots.slice().filter((slot) => {
          const slotMoment = moment(
            `${day.format("YYYY-MM-DD")} ${slot.time}`,
            "YYYY-MM-DD HH:mm A"
          );
          return slotMoment.diff(dates[0], "minutes") >= 0;
        });

        let services: Service[] = [];

        (defaultServices ?? selectedServices).forEach((service, key) => {
          const { time, position } = getSlotInterval(
            service,
            lastSlotPosition,
            daySlots.filter((slot) => {
              return (
                moment(
                  `${day.format("YYYY-MM-DD")} ${slot.time}`,
                  "YYYY-MM-DD HH:mm A"
                ).diff(selectedTime, "minutes") >= 0
              );
            })
          );
          if (time.start && time.end) {
            lastSlotPosition = position.end;
            services.push({
              ...service,
              startTime: time.start,
              endTime: time.end,
              position: key,
            });
          } else {
            services.push({ ...service, position: key });
          }
        });

        setSelectedServices(services);
      } else {
        setSelectedDateTimes([]);
        setNoSlots(true);
      }
    }
    setSelectedDate(day);
    return 0;
  };

  const handleSelectDay = async (day: moment.Moment) => {
    setCustomAppointmentData((data) => {
      return { ...data, day: day.format("YYYY-MM-DD"), startTime: undefined };
    });
    handleLoading(true);
    const chairId = selectedChair?._id === 'any-chair-id' ? null : selectedChair?._id;
    await ProfessionalService.getProfessionalTimeSlots(salon._id, moment(day), 0, chairId )
      .then(async (res) => {
        const slots = groupTimeSlotsByDate(res.slots);

        const chair = salon.chairs.find((chair) => chair._id === res.chairId)

        setSelectedChair(chair);
        setTimeSlots(slots);
        await selectDay(day, slots).then(() => {
          handleLoading(false)
        });
      })
      .catch(async (err) => {
        await selectDay(day, {}).then(() => {
          handleLoading(false);
        });
        toast.error("Something went wrong, please try again !", {
          duration: 7000,
        });
      });
  };

  const selectDay = async (day: moment.Moment, slots: typeof timeSlots) => {
    return new Promise((resolve) => {
      __handleSelectDay(day, slots);
      resolve({
        selectedDate,
        selectedDateTimes,
        selectedServices,
      });
    });
  };

  const handleSelectTime = async (time: moment.Moment) => {
    setCustomAppointmentData((data) => {
      return { ...data, startTime: time.format("HH:mm A") };
    });
    setSelectedTime(time);
  };

  const handleLoading = (state: boolean) => {
    setLoading(state);
  };

  const filterServiceNames = (services: any[]) => {
    const serviceDetails = services.flatMap((service) => {
      const serviceDetails = service.service_detail;
      return serviceDetails;
    });
    let _services = serviceDetails.filter(
      (serviceDetail) => serviceDetail !== undefined
    );

    _services = _services.map((service) => {
      return { ...service, service_details: service._id };
    });

    return _services;
  };

  const toCustomItem = (value: Service[]) => {
    return value.map((item) => {
      return {
        ...item,
        id: item._id as string,
        value: item.name,
      };
    });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setCustomAppointmentData((data) => {
        return { ...data, photo: file };
      });
      setSelectedImage(URL.createObjectURL(file));
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    handleLoading(true);

    const formData = new FormData();
    
    if (customAppointmentData) {
      formData.append("chairId", selectedChair?._id as string);
      for (const [key, value] of Object.entries(customAppointmentData)) {
        formData.append(key, value as any);
      }
      formData.set('size', formData.get('sizes') as string)
    }

    dispatch(addCustomAppointment(formData) as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        props.onClose();
        return toast.success(
          (t) => (
            <div className="d-flex flex-column gap-2 justify-content-center">
              <p>
                Your request for a custom appointment was sent successfully{" "}
              </p>
            </div>
          ),
          {
            duration: 10000,
          }
        );
      })
      .catch((err: any) => {
        const errors = err.errors;
        if (err.status) {
          if (err.status === 500) {
            return toast.error(errors.message, {
              duration: 3000,
            });
          } else {
            if (errors.code === "INSUFFICIENT_BALANCE") {
              return toast.error(
                "Insufficient Balance! Please add funds to your account to book the selected services.",
                {
                  duration: 3000,
                }
              );
            }
            return toast.error(errors.message, {
              duration: 3000,
            });
          }
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const canContinue = (data: Partial<CustomAppointment> | null): boolean => {

    if (data !== null) {
      let appointmentAttrs = [
        data.serviceName,
        data.description,
        data.price,
        // data.sizes,
        // data.drive_to_your_place,
        data.day,
        data.startTime,
        data.photo,
      ];
      if (data.drive_to_your_place) {
        appointmentAttrs.push(...[data.lat, data.lng, data.address]);
      }

      return appointmentAttrs
        .map((attr) => {
          return attr !== undefined && attr.toString().trim() !== "";
        })
        .reduce((prev, next) => prev && next, true);
    }

    return false;
  };

  const [timeInterval, setTimeInterval] = useState(getPossibleStartTime(totalHours, selectedDateTimes));


  const timeRangeMap: RangeInterval = {
    morning: { start: "5:00 AM", end: "11:59 AM" },
    afternoon: { start: "12:00 PM", end: "4:59 PM" },
    evening: { start: "5:00 PM", end: "11:59 PM" },
  };


  // Function to filter moments for a specific time range
  const filterByTimeRange = React.useCallback((startTime: string, endTime: string): moment.Moment[] => {
    const startMoment = moment(startTime, "h:mm A");
    const endMoment = moment(endTime, "h:mm A");

    return timeInterval.filter((momentObj) => {
      const time = momentObj.format("h:mm A");
      const momentTime = moment(time, "h:mm A");

      return momentTime.isBetween(startMoment, endMoment, "minutes", "[]");
    });
  }, [timeInterval])

  const handleChairChange = (chair: IChair) => {
    setSelectedChair(chair);
  }

  const anyChair = { label: "Any", _id: "any-chair-id", active: true, salonId: "" };


  useEffect(() => {
    setTimeInterval(getPossibleStartTime(totalHours, selectedDateTimes));
  }, [totalHours, selectedDateTimes])

  // Filter the timeArray based on the active range
  useEffect(() => {
    if (time === "morning") {
      setFilteredArray(filterByTimeRange("5:00 AM", "11:59 AM"))
    } else if (time === "afternoon") {
      setFilteredArray(filterByTimeRange("12:00 PM", "4:59 PM"))
    } else {
      setFilteredArray(filterByTimeRange("5:00 PM", "11:59 PM"))
    }

  }, [filterByTimeRange, time]);

  useEffect(() => {
    const datesInMonth = getAllDatesInMonth(selectedMonth);
    setSelectedMonthDates(datesInMonth);
    return () => {
      setSelectedMonthDates([]);
    };
  }, [selectedMonth]);

  useEffect(() => {
    __handleSelectDay(selectedDate as moment.Moment, timeSlots);
    return () => {
      setSelectedDate(undefined);
    };
  }, [selectedTime]);

  useEffect(() => {
    setSelectedDateTimes(getPossibleStartTime(totalHours, selectedDateTimes));
    return () => {
      setSelectedDateTimes([]);
    };
  }, [totalHours]);

  useEffect(() => {
    handleSelectDay(moment());
  }, []);

  useEffect(() => {
    setCustomAppointmentData({
      ...customAppointmentData,
      traveling_expenses: calculateTravelExpenses(
        salon.location.coordinates[1],
        salon.location.coordinates[0],
        position?.center.lng as number,
        position?.center.lat as number
      ),
    });
  }, [position]);

  // Determine the default active range based on the first item in timeArray
  useEffect(() => {
    // Check if each time range has at least one entry in timeArray
    const updatedMutedRanges: mutedRangesType = {
      morning: false,
      afternoon: false,
      evening: false,
    };
    for (const range of Object.entries(timeRangeMap)) {
      const [name, { start, end }] = range;
      const filteredTimes = timeInterval.filter((momentObj) =>
        moment(momentObj.format("h:mm A"), "h:mm A").isBetween(
          moment(start, "h:mm A"),
          moment(end, "h:mm A"),
          "minutes",
          "[]"
        )
      );
      updatedMutedRanges[name as keyof mutedRangesType] =
        filteredTimes.length === 0;
    }
    setMutedRanges(updatedMutedRanges);

  }, [selectedDateTimes, totalHours]);


  React.useEffect(() => {
    if (timeInterval.length > 0) {
      const firstTime = moment(timeInterval[0]);
      for (const range of Object.entries(timeRangeMap)) {
        const [name, { start, end }] = range;
        if (
          moment(firstTime.format("h:mm A"), "h:mm A").isBetween(
            moment(start, "h:mm A"),
            moment(end, "h:mm A"),
            "minutes",
            "[]"
          )
        ) {
          setTime(name as TimeOfDay);
          break;
        }
      }
    }
  }, [timeInterval, timeRangeMap])


  return (
    <Modal {...props} className="modal-lg modal-centered">
      <div className="container position-relative p-lg-0">
        {loading && (
          <div className="BookNowModal__container__loader d-flex justify-content-center align-items-center">
            <div className="spinner"></div>
          </div>
        )}
        {loading && (
          <div className="BookNowModal__container__loader d-flex justify-content-center align-items-center">
            <div className="spinner"></div>
          </div>
        )}
        <ProfileInfo
          salon={salon as ISalon}
          onBookmark={props.onBookmark}
          bookMarked={props.bookMarked}
        />
        <h4 className="fw-500 text-black my-4">Request Form</h4>
        <form>
          <div className="row">
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="" className="form-label text-primary fw-500">
                Similar to ?
              </label>
              <CustomDropdown
                items={
                  serviceNameData === null
                    ? []
                    : toCustomItem(
                      filterServiceNames(serviceNameData as Service[])
                    )
                }
                keyExtractor={(item: Item) => item.id}
                render={(item: Item) => item.name}
                placeholder={"Select service name"}
                name={""}
                onChange={(item: any) => {
                  setCustomAppointmentData((data) => {
                    return {
                      ...data,
                      serviceName: (item.name as string) ?? item,
                    };
                  });
                }}
                searchItem={customAppointmentData?.serviceName ?? ""}
                setSearchItem={function (value: string): void {

                }}
              />
            </div>
            <div className="mb-3 col-12 col-lg-6">
              <label htmlFor="" className="form-label text-primary fw-500">
                What size would you like *
              </label>
              <SelectSize
                onChange={(sizes: string[]) => {
                  setCustomAppointmentData((data) => {
                    return { ...data, sizes };
                  });
                }}
                name={"size"}
                onBlur={console.log}
                selectedSizes={
                  customAppointmentData?.size as unknown as string[]
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="" className="form-label text-primary fw-500">
                Service details *
              </label>
              <textarea
                className="form-control"
                name=""
                rows={6}
                placeholder="Please describe the hairstyle or service you're looking for. Include any specific preferences, details, or inspirations. The more information you provide, the better our hairstylists can meet your needs."
                onChange={(e: any) => {
                  setCustomAppointmentData((data) => {
                    return { ...data, description: e.target.value };
                  });
                }}
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="" className="form-label text-primary fw-500">
                Send a Photo *
              </label>
              <div className="d-flex justify-content-center border rounded p-2">
                {selectedImage ? (
                  <div className="position-relative ">
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <div
                      style={{
                        right: "-15px",
                        top: "15px",
                      }}
                      role={"button"}
                      className="position-absolute border d-flex align-items-center justify-center bg-white  translate-middle rounded-circle m-2 p-2"
                      onClick={() => {
                        if (inputRef.current) {
                          inputRef.current.click();
                        }
                      }}
                    >
                      <i className="fa-solid fa-pen"></i>
                    </div>
                  </div>
                ) : (
                  <i
                    role={"button"}
                    onClick={() => {
                      if (inputRef.current) {
                        inputRef.current.click();
                      }
                    }}
                    className="fa-solid fa-cloud-arrow-up text-primary"
                  ></i>
                )}
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
                ref={inputRef}
                hidden
              />
            </div>
            {
              props.showChairSelectionForm ?
                <ChairSelectionForm onChairChange={handleChairChange} chairs={[anyChair, ...salon.chairs]} defaultSelectedChair={anyChair} />
                : null
            }
            <div className="mb-3">
              <label htmlFor="" className="form-label text-primary fw-500">
                For what date ? *
              </label>
              <DatePicker
                setSelectedMonth={setSelectedMonth}
                selectedMonthDates={selectedMonthDates}
                selectedDate={selectedDate}
                handleSelectDay={handleSelectDay}
              />
            </div>
            <label htmlFor="" className="form-label text-primary fw-500">
              What Time of the day? *
            </label>
            <div className="mb-3 d-flex justify-content-center w-100">
              <Time
                value={time}
                onChange={setTime}
                mutedRanges={mutedRanges}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="" className="form-label text-primary fw-500">
                At what time ? *
              </label>
              <TimePicker
                filteredArray={filteredArray}
                selectedDateTimes={selectedDateTimes}
                selectedTime={selectedTime}
                selectedDate={selectedDate}
                handleSelectTime={handleSelectTime}
              />
            </div>
            <div className="d-flex justify-content-between">
              <InputBoolean
                title={
                  <div className="text-primary d-inline-flex fw-500">
                    Do you want me to drive to your place ? *{" "}
                    <i className="fa fa-info-circle"></i>
                  </div>
                }
                name="want_me_to_drive"
                onChange={(val: boolean | null) => {
                  if (val) {
                    setCustomAppointmentData((data) => {
                      return { ...data, drive_to_your_place: val };
                    });
                  } else {
                    setCustomAppointmentData((data) => {
                      return {
                        ...data,
                        drive_to_your_place: val ?? false,
                        traveling_expenses: 0,
                      };
                    });
                  }
                }}
                value={customAppointmentData?.drive_to_your_place ?? false}
              />
            </div>
            {customAppointmentData?.drive_to_your_place && (
              <div className="mb-3 searchbar w-100">
                <label
                  htmlFor="business_location"
                  className="form-label text-black fw-500 mt-2"
                >
                  Your City
                </label>

                <MapSelection
                  containerStyle={{ height: "50vh", width: "100%" }}
                  onLocationSelect={(latLng, address) => {
                    setCustomAppointmentData((data) => {
                      return {
                        ...data,
                        lat: latLng?.lat,
                        lng: latLng?.lng,
                        address,
                      };
                    });
                    setAddress(address);
                    setPosition((pos: any) => {
                      return { ...pos, center: latLng };
                    });
                  }}
                  radius={"1"}
                  showRadius={false}
                />
                {position?.center.lat && (
                  <div className={"d-flex gap-2 align-items-center"}>
                    <div className="">Traveling expenses :</div>
                    <div className="fw-500 text-black fs-4">
                      {formatUSPrice(
                        calculateTravelExpenses(
                          salon.location.coordinates[1],
                          salon.location.coordinates[0],
                          position?.center.lng,
                          position?.center.lat
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className={"d-flex flex-column "}>
              <label
                htmlFor="basic-url"
                className="form-label text-primary fw-500"
              >
                Price offer
              </label>
              <div className="form-input mb-3 w-100">
                <input
                  type="number"
                  onChange={(e: any) => {
                    setCustomAppointmentData((data) => {
                      return {
                        ...data,
                        price: e.target.value as unknown as number,
                      };
                    });
                  }}
                  min={5}
                  className="form-control "
                  placeholder="Type Your Price"
                />
              </div>
            </div>
            <div className="d-flex align-items-center w-100">
              <Button
                onClick={handleSubmit}
                disabled={!canContinue(customAppointmentData) || loading}
                className="primary w-100 br-6 mb-3"
                content={"Submit Request"}
              />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default RequestCustomModal;
