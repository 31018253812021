import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import React from 'react'

interface NextButtonProps {
    onClick: () => void,
    label?: string
    disabled?: boolean,
    icon?: FontAwesomeIconProps['icon'];
}

export const NextButton: React.FC<NextButtonProps> = ({ onClick, label, icon, disabled = false }) => {
    return (
        <button disabled={disabled} onClick={onClick} className="modal_next_button d-flex gap-2 my-4 border p-2 px-4 rounded bg-transparent align-items-center">
            <span>{label ?? 'Next'}</span>
            <FontAwesomeIcon icon={icon ?? faArrowRightLong} />
        </button>
    )
}

export const PrevButton: React.FC<NextButtonProps> = ({ onClick, label, icon, disabled }) => {
    return (
        <button disabled={disabled} onClick={onClick} className="modal_next_button prev d-flex gap-2 my-4 border p-2 px-4 rounded bg-transparent align-items-center">
            <FontAwesomeIcon icon={icon ?? faArrowLeftLong} />
            <span>{label ?? 'Prev'}</span>
        </button>
    )
}
