import React from 'react';
import { IBookingService } from '../../../models/Service';
import { formatUSPrice, formatTimeRange } from '../../../utils/utils';


interface IServicesAddedContainerProps {
    selectedServices: IBookingService[];
    onRemoveService: (service: IBookingService) => void
}


const renderServices = (services: IBookingService[], onRemove: (service: IBookingService) => void) => {
    return services.map((service, key) => {
        return <div key={service._id} className="ServiceAdded p-3 my-3 d-flex justify-content-between text-light fw-500">
            <div>
                <div>{service.service_detail?.name}</div>
            </div>
            <div className="d-flex justify-content-between gap-2 align-items-center">
                <div className="d-flex flex-column justify-content-around text-end">
                    <div>{formatUSPrice(service.price)}</div>
                    <div className="small text-muted">
                        {(service.startTime && service.endTime) ? formatTimeRange(
                            service.startTime.format('HH:mm'),
                            service.endTime.format('HH:mm')
                        ) : 'Nothing'}
                    </div>

                </div>
                {key > 0 && <div>
                    <i onClick={(e: any) => onRemove(service)} className="fa fa-trash"></i>
                </div>}
            </div>
        </div>
    })
}


const ServicesAddedContainer: React.FC<IServicesAddedContainerProps> = ({ selectedServices, ...props }) => {

    console.log(selectedServices);
    const contentToRender = renderServices(selectedServices, props.onRemoveService)

    return (
        <div className="ServicesAddedContainer">
            {selectedServices.length > 0 &&
                contentToRender}
        </div>
    );
};


export default ServicesAddedContainer;