import React, { useEffect, useState } from "react";
import "../../styles/UnsaveData.css";
import Modal from "./Modal";

interface UnsaveDataProps {
  message: string;
  open: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onCancel: () => void;
  onSave: (e: any) => void;
  submitIsChangeBtnRef:any;
}

const UnsaveData = ({
  message,
  open,
  onClose,
  onOpen,
  onCancel,
  onSave,
  submitIsChangeBtnRef
}: UnsaveDataProps) => {
  const [openModal, setOpenModal] = useState<boolean>(open);


  

  return (
    <>
      {openModal && (
        <Modal
          open={open}
          onClose={onClose}
          showCloseButton
          className="closed position-absolute top-50 start-50 translate-middle modal-width"
        >
          <div className="d-flex justify-content-center flex-column  align-items-sm-centergap-1 p-3 text-center">
            <div
              className="w-100 text-center "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "15px",
              }}
            >
              <div className="d-flex justify-content-center align-items-center w-100">
                <img
                  src={require("../../assets/images/icons8-warning-48.png")}
                  alt=""
                  style={{ width: "100px" }}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-2 p-3 justify-content-center align-items-center">
              <h4 className="fw-500  " style={{ color: "#0f2359" }}>
                Unsave Modification ?
              </h4>
              <p className=" text-center">{message}</p>
            </div>

            <div className="w-100 d-flex flex-row gap-3 p-3 justify-content-center  align-items-center ">
              <button
                className=" rounded-2 bg-cancel text-white "
                onClick={onCancel}
              >
                cancel
              </button>
              <button
                className=" rounded-2 bg-save  text-white"
                onClick={onSave}
                ref={submitIsChangeBtnRef}
              >
                save
              </button>
            </div>
          </div>
          ;
        </Modal>
      )}
    </>
  );
};

export default UnsaveData;
