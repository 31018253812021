import { faTriangleExclamation, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "react-calendar/dist/Calendar.css";
import styles from "../styles/AddAppointmentModal.module.css";
import "../styles/Calendar.css";
import { IAppointment } from "../../models/Appointment";
import moment from "moment";
import { formatTime, formatUSPrice, numberToHexColor, toServiceLabel } from "../../utils/utils";

export type GroupedAppointment = { [key: string]: IAppointment[] };

export interface AddAppointmentModalOptions {
  open?: boolean;
  mode?: 'day' | 'week' | 'month';
  appointments: IAppointment[];
  onSelect: (value: IAppointment | null) => void;
  onClose?: () => void
}

const NoCancelledAppointments: React.FC = () => {
  return (
    <div className="d-flex flex-column align-items-center w-100 justify-content-center h-100 user-select-none">
      <FontAwesomeIcon icon={faTriangleExclamation} size="4x" className="text-secondary mb-3 text-muted" />
      <div className="text-muted w-75 text-center">No Appointment</div>
    </div>
  );
};

const AppointmentModalItem: React.FC<{
  appointment: IAppointment,
  onClick: (value: IAppointment) => void
}>
  = ({ appointment, onClick }) => {

    const start = moment(appointment.startTime, 'HH:mm');
    const end = moment(appointment.endTime, 'HH:mm');
    const duration = end.diff(start, 'minute');
    const color = numberToHexColor();

    const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation();
      if (onClick) onClick(appointment);
    }

    return <div onClick={(e) => handleOnClick(e)} className={`${styles.AppointmentModalItem} d-flex gap-2 justify-content-start rounded p-2`}>
      <div className="d-flex flex-column justify-content-around align-items-center">
        <span className="fw-bold">{appointment.startTime}</span>
        <span className="text-muted">{duration}min</span>
      </div>
      <div data-appointment-id={`${appointment._id}`} style={{ border: `2px solid ${color}` }}></div>
      <div className="d-flex flex-column w-100">
        <div className="d-flex justify-content-between">
          <div className="fw-bold">{toServiceLabel(appointment)}</div>
          <div className="fw-500">{formatUSPrice(appointment.price)}</div>
        </div>
        <div className="fw-100">{appointment.user.first_name} {appointment.user.last_name}</div>
      </div>
      <div className="">
        <img style={{ height: '30px', width: '30px', borderRadius: '50%' }} src={appointment.user.profile ?? require('./../../assets/images/profile.jpg')} alt={``} />
      </div>
    </div>
  }

const GroupAppointmentModal: React.FC<AddAppointmentModalOptions> = ({
  open = false,
  mode = 'day',
  appointments,
  onSelect,
  onClose,
}) => {
  const [selectedAppointment, setSelectedAppointment] =
    useState<IAppointment | null>(null);

  const handleSelectAppointment = (value: IAppointment) => {
    if (value === selectedAppointment) {
      setSelectedAppointment(null);
      onSelect(null);
    } else {
      setSelectedAppointment(value);
      onSelect(value);
    }
  };


  const renderAppointmentGroup = (appointments: IAppointment[], mode: 'day' | 'week' | 'month') => {
    if (mode === 'day') return renderAppointmentGroupDay(appointments);
    else if (mode === 'week') return renderAppointmentGroupWeek(appointments);
    else return renderAppointmentGroupMonth(appointments);
  }

  const groupAndSortBy = (appointments: IAppointment[], groupBy: "day" | "week" | "month"): GroupedAppointment => {
    const grouped: GroupedAppointment = {};

    appointments.forEach(appointment => {
      const groupStart =
        groupBy === "day"
          ? appointment.day
          : groupBy === "week"
            ? moment(appointment.day).startOf("isoWeek").format("YYYY-MM-DD")
            : moment(appointment.day).startOf("month").format("YYYY-MM-DD");

      if (!grouped[groupStart]) {
        grouped[groupStart] = [];
      }

      grouped[groupStart].push(appointment);
    });

    // Sort appointments within each group by startTime
    Object.keys(grouped).forEach(groupKey => {
      grouped[groupKey].sort((a, b) =>
        a.startTime.localeCompare(b.startTime)
      );
    });

    return grouped;
  };

  const renderAppointmentGroupDay = (appointments: IAppointment[]) => {
    const groupedByDay = groupAndSortBy(appointments, 'day');
    const today = moment().format('YYYY-MM-DD');

    return <div className="d-flex flex-column gap-5">
      {Object.keys(groupedByDay).map((day, key) => {
        const startTime = moment(groupedByDay[day][0].startTime, 'HH:mm A').format('HH:mm');
        const endTime = moment(groupedByDay[day][groupedByDay[day].length - 1].endTime, 'HH:mm').format('HH:mm A');
        const amount = groupedByDay[day].reduce((prev, next) => prev + next.price, 0)

        return (
          <div key={day} className="d-flex flex-column gap-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="fs-5 fw-500">{today === day ? 'Today' : moment(day, 'YYYY-MM-DD').format('ddd')}</div>
              <div className="d-flex fw-500 justify-content-between align-items-center gap-1 rounded border p-2">
                <span>{startTime} - {endTime}</span>
                <FontAwesomeIcon icon={faPencil} className="mb-1" />
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between border rounded p-2">
                <div className="d-flex flex-column justify-content-around align-items-center">
                  <span className="fw-500">Amount</span>
                  <span className="fw-bold">{formatUSPrice(amount)}</span>
                </div>
                <div className="border-end"></div>
                <div className="d-flex flex-column justify-content-around align-items-center">
                  <span className="fw-500">Appointment</span>
                  <span className="fw-bold">{groupedByDay[day].length}</span>
                </div>
                <div className="border-end"></div>
                <div className="d-flex flex-column justify-content-around align-items-center">
                  <span className="fw-500">New Client</span>
                  <span className="fw-bold">{groupedByDay[day].length - 1}</span>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              {
                groupedByDay[day].map((appointment, key) => {
                  return <AppointmentModalItem
                    onClick={handleSelectAppointment}
                    key={`groupedByDay-${appointment._id}-${key}`}
                    appointment={appointment} />
                })
              }
            </div>
          </div>)

      })}
    </div>
  }

  const renderAppointmentGroupWeek = (appointments: IAppointment[]) => {
    const groupedByWeek = groupAndSortBy(appointments, 'week');
    const today = moment().startOf('isoWeek').format('YYYY-MM-DD'); // Start of the current week

    return (
      <div className="d-flex flex-column gap-5">
        {Object.keys(groupedByWeek).map((week, key) => {
          const startDate = moment(groupedByWeek[week][0].day, 'YYYY-MM-DD').format('DD');
          const endDate = moment(groupedByWeek[week][0].day, 'YYYY-MM-DD').endOf('isoWeek').format('DD MMM YYYY');

          const weekStartDate = moment(week, 'YYYY-MM-DD').startOf('isoWeek').format('YYYY-MM-DD'); // Start of the week
          const weekEndDate = moment(week, 'YYYY-MM-DD').endOf('isoWeek').format('YYYY-MM-DD'); // End of the week
          const amount = groupedByWeek[week].reduce((prev, next) => prev + next.price, 0);
          const time = groupedByWeek[week].reduce((prev, appointment) => {
            const start = moment(appointment.startTime, 'HH:mm');
            const end = moment(appointment.endTime, 'HH:mm');
            const duration = end.diff(start, 'minute');
            return prev + duration
          }, 0);

          return (
            <div key={week} className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="fs-6 fw-500">
                  {today === weekStartDate ? 'This Week' : (key === 1 ? 'Next Week' : `${moment(weekStartDate).format('MMM D')} - ${moment(weekEndDate).format('MMM D')}`)}
                </div>
                <div className="d-flex fw-500 justify-content-between align-items-center gap-1 rounded border p-2">
                  <span>{startDate} - {endDate}</span>
                  <FontAwesomeIcon icon={faPencil} className="mb-1" />
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between border rounded p-2">
                  <div className="d-flex flex-column justify-content-around align-items-center">
                    <span className="fw-500">Amount</span>
                    <span className="fw-bold">{formatUSPrice(amount)}</span>
                  </div>
                  <div className="border-end"></div>
                  <div className="d-flex flex-column justify-content-around align-items-center">
                    <span className="fw-500">Appointment</span>
                    <span className="fw-bold">{groupedByWeek[week].length}</span>
                  </div>
                  <div className="border-end"></div>
                  <div className="d-flex flex-column justify-content-around align-items-center">
                    <span className="fw-500">Time</span>
                    <span className="fw-bold">{`${formatTime(time).replace('hours', 'h').replace('minutes', 'min')}`}</span>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                {groupedByWeek[week].map((appointment, key) => {
                  return <AppointmentModalItem
                    onClick={handleSelectAppointment}
                    key={`groupedByDay-${appointment._id}-${key}`}
                    appointment={appointment} />
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  const renderAppointmentGroupMonth = (appointments: IAppointment[]) => {
    const groupedByMonth = groupAndSortBy(appointments, 'month');
    const today = moment().startOf('month').format('YYYY-MM-DD'); // Start of the current month

    return (
      <div className="d-flex flex-column gap-5">
        {Object.keys(groupedByMonth).map((month, key) => {
          const startDate = moment(groupedByMonth[month][0].day, 'YYYY-MM-DD').format('DD');
          const endDate = moment(groupedByMonth[month][0].day, 'YYYY-MM-DD').endOf('month').format('DD MMM YYYY');

          const monthStartDate = moment(month, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD'); // Start of the month
          const monthEndDate = moment(month, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD'); // End of the month
          const amount = groupedByMonth[month].reduce((prev, next) => prev + next.price, 0);
          const time = groupedByMonth[month].reduce((prev, appointment) => {
            const start = moment(appointment.startTime, 'HH:mm');
            const end = moment(appointment.endTime, 'HH:mm');
            const duration = end.diff(start, 'minute');
            return prev + duration
          }, 0);

          return (
            <div key={month} className="d-flex flex-column gap-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="fs-6 fw-500">
                  {today === monthStartDate ? 'This month' : (key === 1 ? 'Next month' : `${moment(monthStartDate).format('MMM D')} - ${moment(monthEndDate).format('MMM D')}`)}
                </div>
                <div className="d-flex fw-500 justify-content-between align-items-center gap-1 rounded border p-2">
                  <span>{startDate} - {endDate}</span>
                  <FontAwesomeIcon icon={faPencil} className="mb-1" />
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between border rounded p-2">
                  <div className="d-flex flex-column justify-content-around align-items-center">
                    <span className="fw-500">Amount</span>
                    <span className="fw-bold">{formatUSPrice(amount)}</span>
                  </div>
                  <div className="border-end"></div>
                  <div className="d-flex flex-column justify-content-around align-items-center">
                    <span className="fw-500">Appointment</span>
                    <span className="fw-bold">{groupedByMonth[month].length}</span>
                  </div>
                  <div className="border-end"></div>
                  <div className="d-flex flex-column justify-content-around align-items-center">
                    <span className="fw-500">Time</span>
                    <span className="fw-bold">{`${formatTime(time).replace('hours', 'h').replace('minutes', 'min')}`}</span>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                {groupedByMonth[month].map((appointment, key) => {
                  return <AppointmentModalItem
                    key={`groupedByDay-${appointment._id}-${key}`}
                    onClick={handleSelectAppointment}
                    appointment={appointment} />
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      className={`${styles.AddAppointmentModal} bg-white p-2 ${open ? `${styles["AddAppointmentModal--open"]}` : ``
        } d-flex justify-content-center p-2`}
    >
      <div className={`d-flex flex-column gap-2 w-100`}>
        <h4 className="fw-500 border-bottom pb-1">Appointment List</h4>
        <div style={{ minHeight: '60vh', maxHeight: '75vh', overflowY: 'scroll' }} className="position-relative">

          {
            appointments.length > 0 ? <>
              {renderAppointmentGroup(appointments, mode)}
            </> :
              <NoCancelledAppointments />
          }

        </div>
      </div>
    </div>
  );
};

export { GroupAppointmentModal };