import React, { useEffect, useRef, useState } from "react";
import MainNav from "../components/navigation/MainNav";
import AuthLayout from "../components/auth/AuthLayout";
import Footer from "../components/Footer";

const MemberPage: React.FC = () => {
  return (
    <AuthLayout>
      <div className="MessagePage">
        <MainNav navType={"connectedNav"} isSearch={true} />
        <div
          style={{ height: "calc(85vh - 70px)" }}
          className="container p-lg-0"
        >
          Member page
        </div>
        <Footer />
      </div>
    </AuthLayout>
  );
};

export default MemberPage;
