import * as Yup from "yup";
import Service from "../../models/Service";

export interface Services {
  name: string;
  size: string;
  price: string;
  duration: string;
  description: string;
}

export const initialServiceValues: Partial<Service> = {
  size: "",
  price: 0,
  duration: 0,
  description: "",
  name: "",
  promote: {
    isPromote: false,
    price_promotion: 0,
    startTime: Date.now().toLocaleString(),
    endTime: Date.now().toLocaleString(),
  },
};
export const initialServiceDetailValues: Partial<Service> = {
  service_detail: "",
  service_categorie: "",
};

export interface Errors {
  service_categorie: string;
  service_detail: string;
}

export const serviceSchema = Yup.object({
  name: Yup.string().required("You must specify the service name"),
  size: Yup.string().required("You must specify the service size"),
  price: Yup.number().required("You must add the service price"),
  duration: Yup.string().required("The duration of the service is required"),
});

export const schema = Yup.object().shape({
  service_categorie: Yup.string().required("You must add a service name"),
  service_detail: Yup.string().required("You must add a service category"),
});

export const servicePromotionSchema = Yup.object({
  price_promotion: Yup.number()
    .required("New price is required")
    .min(0, "Price must be greater than 0"),
  startTime: Yup.date().required("Start date is required"),
  endTime: Yup.date().required("End date is required"),
});
