
import { v4 as uuidv4 } from 'uuid';

const WorkingTableElementLoader = () => {
    return <div className='working-table-element'>
        {
            Array(8).fill(0).map((_, key) => {
                return <div key={uuidv4()} className='working-table-element__item p-3'>
                    <div style={{ width: '100%', height: '50px' }} className="shimmer shimmer__square"></div>
                </div>
            })
        }
    </div>
}

export default WorkingTableElementLoader;