import * as Yup from "yup";
import { ILocation, ISalon } from "../../models/SalonX";
export interface SalonValues {
  seating_places: number;
  name: string;
  accept_custom_orders?: boolean;
  kids_service?: boolean;
  kids_friendly?: boolean;
  wheelchair_accessible?: boolean;
  blow_dry?: boolean;
  hair_wash?: boolean;
  undo_hair?: boolean;
  address: string;
  location: ILocation;
}

export interface ManagerValidation {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirmPassword: string;
  phone: string;
}
export const managerInitialValues: ManagerValidation = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone: "",
};

export const SalonInitialValues: SalonValues = {
  name: "",
  accept_custom_orders: false,
  kids_service: false,
  kids_friendly: false,
  blow_dry: false,
  hair_wash: false,
  undo_hair: false,
  wheelchair_accessible: false,
  location: {
    type: "Point",
    coordinates: [0, 0],
  },
  seating_places: "" as unknown as number,
  address: "",
};

export const editSalonValues: Partial<ISalon> = {
  name: "",
  seating_places: 0,
};

export const managerValidationSchema = Yup.object({
  first_name: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  last_name: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),

  password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Required"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm Password is required"),
});

export const SalonValidationSchema = Yup.object({
  name: Yup.string()
    .max(30, "Must be 30 characters or less")
    .required("Required"),
  seating_places: Yup.number().required("Required"),
  address: Yup.string().required("Required"),
});
