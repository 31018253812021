import { useState, useRef, useEffect } from "react";
import { InputMedia } from "../../input";
import Button from "../../Button";
import toast from "react-hot-toast";
import { AuthService } from "../../../services/AuthService";
import ImageEditorModal from "../../modal/ImageEditorModal";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";

interface RegisterProStep0Props {
  onNextStep: () => void;
  onPrevStep?: () => void;
}

const RegisterProStep2 = ({
  onNextStep,
  onPrevStep,
}: RegisterProStep0Props): JSX.Element => {
  const coverRef = useRef<HTMLInputElement>();
  const profileRef = useRef<HTMLInputElement>();
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const spanProfilRef = useRef<HTMLSpanElement>(null);
  const profileButtonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const [mediasValues, setMediaValues] = useState(Array(2).fill(null));
  const [isEdited, setIsEditing] = useState(false);
  const [openEditorModal, setOpenEditorModal] = useState(false);
  const [onUpload, setOnUpload] = useState({
    profile: false,
    cover: false,
  });
  const [uploaded, setUploaded] = useState({
    profile: false,
    cover: false,
  });
  const handleUpload = async (formData: FormData) => {
    const imageName = "profile";
    formData.append(imageName, formData.get("file") as File);
    formData.delete("file");

    return dispatch(updateUser(formData) as unknown as AnyAction).unwrap();
  };

  const handleMediaChange = (e: any, key: any) => {
    try {
      const reader = new FileReader();
      reader.onload = function (e) {
        let mediaValueSlice = mediasValues.slice();
        mediaValueSlice[key] = e.target?.result;
        setMediaValues(mediaValueSlice);
      };
      console.log("valeur de profileRef", profileRef.current?.files);
      reader.readAsDataURL(e.target.files[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSkip = (e?: any) => {
    if (profileRef?.current || coverRef?.current) {
      if (
        ((profileRef.current?.files?.length ?? 1 > 0) && !uploaded.profile) ||
        ((coverRef.current?.files?.length ?? 1 > 0) && !uploaded.cover)
      ) {
        toast.remove();
        toast.loading(
          (t) => (
            <div className="d-flex flex-column gap-2 justify-content-center">
              <p>You have an unsaved work, do you really want to skip ?</p>
              <div className="d-flex gap-2 justify-content-end">
                <Button
                  className="rounded mx-2"
                  mode="default"
                  onClick={() => {
                    toast.remove();
                    onNextStep();
                  }}
                >
                  {" "}
                  Let me skip{" "}
                </Button>
              </div>
            </div>
          ),
          {
            icon: null,
            duration: 10000,
          }
        );
        return false;
      }
    }
    onNextStep();
  };

  const handleUploadProfile = () => {
    if (profileRef?.current?.files && profileRef.current.files.length > 0) {
      setOnUpload({ ...onUpload, profile: true });
      const formData = new FormData();
      formData.set("profile", profileRef.current.files[0]);
      toast.remove();
      AuthService.updateSalon(formData)
        .then((res) => {
          setOnUpload({ ...onUpload, profile: false });
          toast.success("We have uploaded your profile.");
          setUploaded({ ...uploaded, profile: true });
        })
        .catch((err) => {
          console.log(err);
          toast.error("something went wrong, please try again.");
        });
    }
  };

  const handleUploadCover = () => {
    if (coverRef?.current?.files && coverRef.current.files.length > 0) {
      setOnUpload({ ...onUpload, cover: true });
      const formData = new FormData();
      formData.set("cover", coverRef.current.files[0]);
      toast.remove();
      AuthService.updateSalon(formData)
        .then((res) => {
          setOnUpload({ ...onUpload, cover: false });
          toast.success("We have uploaded your cover.");
          setUploaded({ ...uploaded, cover: true });
        })
        .catch((err) => {
          console.log(err);
          toast.error("something went wrong, please try again.");
        });
    }
  };

  const handleVerifAndSubmitProfile = (e: any, key: any) => {
    try {
      const reader = new FileReader();
      reader.onload = function (e) {
        let mediaValueSlice = mediasValues.slice();
        mediaValueSlice[key] = e.target?.result;

        setMediaValues(mediaValueSlice);
      };
      console.log("valeur de profileRef", profileRef.current?.files);
      reader.readAsDataURL(e.target.files[0]);

      handleUploadProfile();
    } catch (err) {
      console.log(err);
    }
  };

  const handleVerifAndSubmitCover = (e: any, key: any) => {
    try {
      const reader = new FileReader();
      reader.onload = function (e) {
        let mediaValueSlice = mediasValues.slice();
        mediaValueSlice[key] = e.target?.result;
        setMediaValues(mediaValueSlice);
      };
      console.log("valeur de profileRef", profileRef.current?.files);
      reader.readAsDataURL(e.target.files[0]);

      handleUploadCover();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = () => {
    handleSkip();
  };

  const handleEdited = () => {
    setIsEditing(!isEdited);
  };

  useEffect(() => {
    if (spanProfilRef && spanProfilRef.current?.innerText === "Edit") {
      spanProfilRef.current.addEventListener("click", () => {});
    }
  });

  return (
    <div className="w-100 d-flex flex-column flex-md-row gap-5">
      <div className="d-flex justify-content-around w-100">
        <div className="d-flex flex-column align-items-center gap-2 justify-content-between">
          <InputMedia
            onChange={(e: any) => handleVerifAndSubmitProfile(e, 0)}
            ref={profileRef as any}
            width="120px"
            height="120px"
            borderRadius="100%"
            borderColor="var(--primaryColor)"
            value={mediasValues[0]}
            placeholder="Pick a Profile"
            icon="fa fa-image fa-2x"
            accept={"image/*"}
            isEdited={isEdited}
          />
          {/* <Button
            disabled={onUpload.profile}
            onClick={handleUploadProfile}
            mode={"neutral"}
            style={{ minWidth: "65px" }}
            content={
              <span className="mx-0" style={{ fontSize: ".8em" }}>
                {
                  onUpload.profile
                  ? "uploading profile..."
                  : uploaded.profile
                  ? "Edit"
                  : "Upload"
                }
              </span>
            }
          /> */}
          <span
            className="mx-0"
            style={{ fontSize: ".8em" }}
            ref={spanProfilRef}
            onClick={(e: any) => handleVerifAndSubmitProfile(e, 0)}
          >
            {onUpload.profile ? "uploading profile..." : uploaded.profile && ""}
          </span>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-between">
          <InputMedia
            onChange={(e) => handleVerifAndSubmitCover(e, 1)}
            ref={coverRef as any}
            width="120px"
            height="120px"
            borderRadius="0px"
            borderColor="var(--primaryColor)"
            value={mediasValues[1]}
            placeholder="Pick a Cover"
            icon="fa fa-image fa-2x"
            accept={"image/*"}
          />
        </div>
      </div>
      <div className="d-flex flex-md-column ms-auto ms-md-none align-items-center justify-content-center gap-2">
        <Button
          onClick={handleSkip}
          disabled={onUpload.profile || onUpload.cover}
          mode={"neutral"}
          style={{ width: "100px" }}
          content={
            <span className="mx-0" style={{ fontSize: ".8em" }}>
              Skip
            </span>
          }
        />
        <Button
          ref={submitBtnRef as any}
          onClick={handleSubmit}
          disabled={onUpload.profile || onUpload.cover}
          mode={"primary"}
          style={{ width: "100px" }}
          content={
            <span className="mx-0" style={{ fontSize: ".8em" }}>
              Next Step
            </span>
          }
        />
      </div>
      <ImageEditorModal
        onUpload={handleUpload}
        message={"CLICK TO UPLOAD OR DROP A PROFILE IMAGE"}
        onClose={() => setOpenEditorModal(false)}
        show={openEditorModal}
        rounded={true}
        ratio={1}
        title={"Edit profile image"}
      />
    </div>
  );
};

export default RegisterProStep2;
