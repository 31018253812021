import * as Yup from "yup";
export interface RegisterValues {
  firstname: string;
  lastname: string;
  email: string;
  password?: string;
  confirmPassword?: string;
  phone?: string;
  image?: File;
  typeOfAccount?: string;
  salonName?: string;
  accept_custom_orders?: boolean;
  kids_service?: boolean;
  kids_friendly?: boolean;
  blow_dry?: boolean;
  hair_wash?: boolean;
  undo_hair?: boolean;
  free_parking_for_client?: boolean;
  drive_to_client?: boolean;
  otp?: string;
  salonNumber?: string;
  bio?: string;
}

// interface for errors customized in backend
interface RegisterErrors {
  email?: string;
  password?: string;
  phone?: string;
  image?: string;
  otp?: string;
}

let errors: RegisterErrors = {};
errors.email = "Invalid email from backend";

// supported formats for profile and cover
// Ehdj54Wkmd#
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export const initialValues: RegisterValues = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone: "",
  image: undefined,
  typeOfAccount: "",
  salonName: "",
  accept_custom_orders: false,
  kids_service: false,
  kids_friendly: false,
  blow_dry: false,
  hair_wash: false,
  undo_hair: false,
  free_parking_for_client: false,
  otp: "",
  bio: "",
};

const phoneRegExp = /^(\+1|1)?[-.\s]?\(?([2-9][0-9]{2})\)?[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/;


export const validationSchema = Yup.object({
  firstname: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  lastname: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Required"),
  email: Yup.string().email(errors.email).required("Required"),

  phone: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .required("fill out this field"),

  password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Required"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords must match")
    .required("Confirm Password is required"),

  otp: Yup.string().matches(/[0-9]/, "Enter a number"),

  image: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "fileFormat",
      "Provide an image of type jpeg/jpg/png",
      (value) =>
        !value || (value && SUPPORTED_FORMATS.includes((value as File).type))
    ),
});
