import axios from "axios";
import Service from "../models/Service";
import Salon from "../models/Salon";
import Manager from "../models/Manager";
import { IUserRegistrationPro } from "../models/User";

const getManagers = () => {
  return axios
    .get(`managers`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const addManager = (manager: IUserRegistrationPro) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}managers`,
    method: "post",
    data: {
      ...manager,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

const unassignManager = (managerId: string) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}managers`,
    method: "patch",
    data: {
      managerId,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

export const ManagerService = {
  getManagers,
  addManager,
  unassignManager,
};
