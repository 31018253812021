import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import "../styles/SelectPeriod.css";
import moment from "moment";

export interface SelectPeriodProps {
    mode: 'day' | 'week' | 'month';
    label?: string | null;
    defaultDate?: moment.Moment;
    onSelect: () => void;
    onChange: (value: moment.Moment) => void;
}

const SelectPeriod: React.FC<SelectPeriodProps> = ({
    mode,
    defaultDate = moment(),
    onChange,
}) => {
    const [activeDate, setActiveDate] = useState<moment.Moment>(defaultDate);
    const [text, setText] = useState<string>('');

    const handlePrevPeriod = () => {
        switch (mode) {
            case 'day':
                setActiveDate(activeDate.clone().subtract(1, 'day'));
                break;
            case 'week':
                setActiveDate(activeDate.clone().subtract(1, 'week'));
                break;
            case 'month':
                setActiveDate(activeDate.clone().subtract(1, 'month'));
                break;
            default:
                break;
        }
    };

    const handleNextPeriod = () => {
        switch (mode) {
            case 'day':
                setActiveDate(activeDate.clone().add(1, 'day'));
                break;
            case 'week':
                setActiveDate(activeDate.clone().add(1, 'week'));
                break;
            case 'month':
                setActiveDate(activeDate.clone().add(1, 'month'));
                break;
            default:
                break;
        }
    };


    useEffect(() => {
        // Update the text based on the selected mode.
        switch (mode) {
            case 'day':
                setText(activeDate.format('ddd, DD MMM'));
                break;
            case 'week':
                const endDate = activeDate.clone().add(6, 'days');
                setText(`${activeDate.format('DD')} - ${endDate.format('DD MMM')}`);
                break;
            case 'month':
                setText(activeDate.format('MMMM'));
                break;
            default:
                break;
        }
    }, [mode, activeDate]);

    useEffect(() => {
        if (activeDate && onChange) {
          onChange(activeDate);
        }
      }, [activeDate, onChange]);

    return (
        <div className="SelectPeriod">
            <div className="d-flex gap-1 align-items-center">
                <div className="SelectPeriod__actionBtn">
                    <button className="" onClick={handlePrevPeriod}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                </div>
                <div className="SelectPeriod__container d-flex justify-content-center align-items-center border">
                    <div className="SelectPeriod__container__text text-center w-100 fw-bold">
                        {text}
                    </div>
                </div>
                <div className="SelectPeriod__actionBtn">
                    <button className="" onClick={handleNextPeriod}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SelectPeriod;
