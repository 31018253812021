import { faBell, faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Button";
import { navigationLinks1, navigationLinks5, restrictUserRoutes } from ".";
import NavigationLink from "./NavigationLink";
import { CartModal } from "../modal/CartModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  receiveSocketNotification,
} from "../../redux/slices/notificationSlice";
import { AnyAction } from "@reduxjs/toolkit";
import NotificationDrawer from "../NotificationDrawer";
import PostProjectModal from "../modal/PostProjectModal";
import socket from "../../socket/socket";
import { useUser } from "../../hooks/UseUser";
import UserOptions from "./UserOptions";
import toast from "react-hot-toast";
import { logout } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router";
import { isUserProfessional } from "../../models/User";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface NavbarConnectedProps {
  withLinks?: boolean;
  connected?: boolean;
}

const NavbarHome: React.FC<NavbarConnectedProps> = ({
  withLinks = true,
  connected = false,
}: NavbarConnectedProps) => {
  const notifications = useSelector(
    (state: any) => state.notifications
  ).notifications;
  const unReadNotifications = useSelector(
    (state: any) => state.notifications
  ).unReadNotifications;

  const dispatch = useDispatch();
  const user = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [onOpen, setOnOpen] = useState(false);

  const [openProjectModal, setOpenProjectModal] = useState(false);

  const navigate = useNavigate();

  const handleLogout = async () => {
    toast.loading("Signing out...");
    dispatch(logout() as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        window.localStorage.removeItem("salonId");
        window.localStorage.removeItem("authToken");
        navigate("/");
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        toast.remove();
      });
  };

  useEffect(() => {
    dispatch(getNotifications("") as unknown as AnyAction);
  }, []);

  useEffect(() => {
    socket().on("NEW_NOTIFICATION", (data) => {
      const { notification, userId } = data;
      if (user && userId === user._id) {
        dispatch(
          receiveSocketNotification(notification) as unknown as AnyAction
        );
      }
    });
    return () => {
      socket().off("NEW_NOTIFICATION");
    };
  }, []);

  return (
    <div className="navbar-nav ml-lg-auto justify-content-end w-100">
      {connected && (
        <div className="d-flex d-lg-none align-items-center flex-column">
          <div className="justify-content-between d-flex align-items-center w-100">
            <Button
              mode="default"
              className=" text-primary post-project-btn mx-2"
              content={"Post a project"}
              onClick={() => setOnOpen(true)}
            />
            <div className="d-flex gap-2">
              <div
                onClick={() => setOpenProjectModal(true)}
                className=" text-white"
              >
                <FontAwesomeIcon
                  icon={faCartShopping}
                  className="fs-5 me-1"
                  data-count="38"
                />
              </div>
              <div className="position-relative">
                <div
                  role={"button"}
                  className=" text-white px-0 m-0"
                  onClick={() => setIsOpen(true)}
                >
                  <FontAwesomeIcon icon={faBell} className="fs-5" />
                </div>
                {notifications && unReadNotifications !== 0 && (
                  <div
                    style={{
                      height: "1rem",
                      width: "1rem",
                      border: "1px solid var(--primaryColor)",
                      top: "0",
                      right: "-10px",
                    }}
                    className="bg-white p-2 rounded-circle d-flex align-items-center small-text justify-content-center position-absolute "
                  >
                    {unReadNotifications < 100 ? unReadNotifications : "99+"}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-100">
            <UserOptions handleLogout={handleLogout} />
            <NavigationLink
              to={"/client-profile"}
              label={"Profile"}
              className="nav-link text-white mx-lg-3"
            />
          </div>
        </div>
      )}
      {withLinks &&
        restrictUserRoutes(navigationLinks1, isUserProfessional(user)).map(
          (link, index) => {
            if (
              ["/login", "/register", "/register-pro"].includes(link.to) &&
              connected
            ) {
              return <></>;
            }

            if (link.to === "/client-profile") {
              if (isUserProfessional(user)) {
                return <></>;
              }
              return (
                <NavigationLink
                  key={index}
                  to={"/business-profile"}
                  label={link.label}
                  className="nav-link text-white mx-lg-3"
                />
              );
            }

            return (
              <NavigationLink
                key={index}
                to={link.to}
                label={link.label}
                className="nav-link text-white mx-lg-3"
              />
            );
          }
        )}

      {connected && (
        <div className="d-none d-lg-flex align-items-center gap-2">
          <Button
            mode="default"
            className=" text-primary post-project-btn mx-2"
            content={"Post a project"}
            onClick={() => setOnOpen(true)}
          />

          <UserOptions handleLogout={handleLogout} />
          <div>
            <span className=" text-white ">
              {user?.first_name} {user?.last_name}
            </span>
          </div>

          <NavigationLink
            className="nav-link text-white d-none d-lg-block"
            to="/message"
            label={
              <FontAwesomeIcon
                icon={navigationLinks5[0].icon as IconProp}
                className="nav-link-icon nav-link-icon-badge flex-fill"
                data-count=""
              />
            }
          />

          <div
            onClick={() => setOpenProjectModal(true)}
            className=" text-white"
          >
            <FontAwesomeIcon
              icon={faCartShopping}
              className="fs-5 me-1"
              data-count="38"
            />
          </div>
          <div className="position-relative">
            <div
              role={"button"}
              className=" text-white px-0 m-0"
              onClick={() => setIsOpen(true)}
            >
              <FontAwesomeIcon icon={faBell} className="fs-5" />
            </div>
            {notifications && unReadNotifications !== 0 && (
              <div
                style={{
                  height: "1rem",
                  width: "1rem",
                  border: "1px solid var(--primaryColor)",
                  top: "0",
                  right: "-10px",
                }}
                className="bg-white p-2 rounded-circle d-flex align-items-center small-text justify-content-center position-absolute "
              >
                {unReadNotifications < 100 ? unReadNotifications : "99+"}
              </div>
            )}
          </div>
        </div>
      )}

      {openProjectModal && (
        <CartModal
          show={openProjectModal}
          className="modal-lg"
          style={{ display: "block !important" }}
          onBack={() => setOpenProjectModal(false)}
        />
      )}
      <NotificationDrawer
        isOpen={isOpen}
        notifications={notifications}
        setIsOpen={setIsOpen}
        unReadNotifications={unReadNotifications}
      />
      {onOpen && (
        <PostProjectModal backdrop={true} onClose={() => setOnOpen(false)} />
      )}
    </div>
  );
};

export default NavbarHome;
