import { ChangeEvent, useState } from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface phoneNumberValidationProps {
  onChangeValue: (value: string) => void;
}

const PhoneNumberValidation: React.FC<phoneNumberValidationProps> = ({
  onChangeValue,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(true);

  const handleChange = (
    value: string,
    data: CountryData,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    setPhoneNumber(value);
    setValid(validatePhoneNumber(value));
    onChangeValue(value);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneNumberPattern = /^\d{10}$/; // Validates a 10-digit phone number

    return phoneNumberPattern.test(phoneNumber);
  };

  return (
    <div>
      <label htmlFor="number">
        <div className="mb-2">Enter your us phone number</div>
        <PhoneInput
          country="us"
          onlyCountries={["us"]}
          autoFormat
          value={phoneNumber}
          onChange={handleChange}
          inputProps={{
            required: true,
          }}
        />{" "}
      </label>
    </div>
  );
};

export default PhoneNumberValidation;
