import React from 'react'
import '../styles/WorkingHours.css'
import { WeekSelector } from './WeekSelector'
import { WorkingTable } from './WorkingTable'
import { Moment } from 'moment'
import useWeekSelector from '../../hooks/useWeekSelector'
import { TWorkingTableElement } from './WorkingTableElement'
import { USATimezone } from '../../utils/types'
import { timeToTimezone } from '../../utils/utils'
import { useUser } from '../../hooks/UseUser'
import { isUserBusinessOwner, isUserProfessional } from '../../models/User'


type WorkingHoursProps = {
    chairs: TWorkingTableElement[] | null,
    onWeekChange: (startDate: moment.Moment) => Promise<any>
    onAddNewChair: () => void
}

export const WorkingHours: React.FC<WorkingHoursProps> = (
    { chairs, onWeekChange, onAddNewChair }
) => {

    const weekSelector = useWeekSelector();
    const [weekDates, setWeekDates] = React.useState<Moment[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [timezone, setTimezone] = React.useState<USATimezone>("US/Pacific")

    const user = useUser()

    const handleWeekChange = (dates: Moment[], timezone: USATimezone) => {
        setWeekDates(dates)
        setTimezone(timezone);
        setLoading(true)
        onWeekChange(dates[0])
            .then(() => {
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
        setTimeout(() => { setLoading(false) }, 1500)
    }

    React.useEffect(() => {
        setWeekDates(weekSelector.weekDates)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weekSelector.weekDates])


    /**
     * We will filter the shifts based on the weekDates
     * or maybe only fetch (and add to the current) shifts related with the weekDates
     */

    const chairsToTimeZone: TWorkingTableElement[] = (chairs ?? []).map((chair) => {

        return {
            ...chair,
            shifts: chair.shifts.map((shift) => {
                if (shift) {
                    return {
                        ...shift,
                        startTime: timeToTimezone(shift.startTime, timezone),
                        endTime: timeToTimezone(shift.endTime, timezone)
                    }
                }
                return shift;
            }),
            timeOffs: chair.timeOffs.map((timeOff) => {
                if (timeOff) {
                    return {
                        ...timeOff,
                        startTime: timeToTimezone(timeOff.startTime, timezone),
                        endTime: timeToTimezone(timeOff.endTime, timezone)
                    }
                }
                return timeOff;
            }),
        }
    }) as TWorkingTableElement[];

    console.log("chairsToTimeZone",chairsToTimeZone)

    return (
        <div className='working-hours p-0 m-0 mt-4 d-flex flex-column justify-content-end'>
            <div className="working-hours__menu d-flex justify-content-between align-items-center">
                <div className='text-black fw-500'><i className="fa fa-calendar-days"></i> Manage schedule & break time</div>
                {isUserBusinessOwner(user) &&
                    <button onClick={() => onAddNewChair()} className='working-table-primary-button'>New chair</button>}
            </div>
            <div className="working-hours__selector-container mt-4 p-3">
                <WeekSelector className='ms-auto' onChange={handleWeekChange} />
            </div>
            <WorkingTable
                loading={loading}
                className='mt-5'
                weekDates={weekDates}
                timezone={timezone}
                elements={chairsToTimeZone}
            />
        </div>
    )
}