
import React, { CSSProperties, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { geocodeByLatLng } from "react-google-places-autocomplete";
import { toast } from "react-hot-toast";
import LocationSearchInput from "../input/LocationSearchInput";

export interface JustMapLocationProps {
  containerStyle: CSSProperties;
  onLocationSelect: (
    latLng: { lat: number; lng: number },
    address: string
  ) => void;
  radius: string | null;
  defaultLocation: { center: { lat: number; lng: number }; zoom: number };
  defaultAddress?: string;
  onRadiusChange?: (value: string) => void;
  showRadius?: boolean;
  showSearch?: boolean;
}

const JustMapLocation: React.FC<JustMapLocationProps> = ({
  onLocationSelect,
  radius,
  onRadiusChange,
  containerStyle,
  defaultLocation,
  defaultAddress,
  showRadius = true,
  showSearch = true,
}) => {

console.log("my defaut location" , defaultLocation)
  const [areaRadius, setAreaRadius] = useState<string | null>(radius);
  const [address, setAddress] = useState<string | null>(null);
  const [markerPosition, setMarkerPosition] = useState<any>(null);
  const [markerCircle, setMarkerCircle] = useState<any>(null);
  const [markerInfo, setMarkerInfo] = useState<any>(null);
  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const [position, setPosition] = useState<typeof defaultLocation | null>(null);

  const updateMapLoadedElements = (map: any, maps: any, center: any) => {
    if (markerPosition) {
      markerPosition.setPosition(center);
    }
    if (markerCircle) {
      markerCircle.setRadius(Number(areaRadius) * 1609.34);
      markerCircle.setCenter(center);
    }
  };

  const handleApiLoaded = (gMap: any, gMaps: any) => {
    // put marker in current position
    if (!map) setMap(gMap);
    if (!maps) setMaps(gMaps);

    if (position && gMap && gMaps) {
      const center = { lat: position.center.lat, lng: position.center.lng };

      if (markerPosition) {
        return updateMapLoadedElements(gMap, gMaps, center);
      }

      const infoWindow = new gMaps.InfoWindow({
        content: "Your position",
        ariaLabel: "Position",
        disableAutoPan: true,
      });

      setMarkerInfo(infoWindow);

      let marker = new gMaps.Marker({
        position: center,
        map: gMap,
        title: "Your position",
      });

      infoWindow.open({
        anchor: marker,
        map: gMap,
      });

      marker.addListener("click", () => {
        infoWindow.open({
          anchor: marker,
          map: gMap,
        });
      });

      setMarkerPosition(marker);

      if (markerPosition) {
        markerPosition.setPosition({
          lat: position.center.lat,
          lng: position.center.lng,
        });
      }

      const cityCircle = new gMaps.Circle({
        strokeColor: "#e10984",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#e10984",
        fillOpacity: 0.35,
        map: gMap,
        center: center,
        radius: Number(areaRadius),
        // editable: true,
      });

      setMarkerCircle(cityCircle);

      gMap.panTo(marker.getPosition());

      const circleBounds = cityCircle.getBounds();

      gMap.fitBounds(circleBounds);
      gMap.setZoom(13);
    }
  };

  const handleChangeRadius = (e: any) => {
    setAreaRadius(e.target.value);
    if (typeof onRadiusChange === "function") onRadiusChange(e.target.value);
  };

  useEffect(() => {
    position && markerPosition && markerPosition.setPosition(position.center);
    markerInfo && address && markerInfo.setContent(address);
    if (markerCircle && position) {
      markerCircle.setRadius(Number(areaRadius) * 1609.34);
      markerCircle.setCenter(position.center);
    }
  }, [address, markerInfo, areaRadius, markerCircle]);

  useEffect(() => {
    if (map && markerPosition && markerCircle) {
      map.panTo(markerPosition.getPosition());
      const circleBounds = markerCircle.getBounds();
      map.fitBounds(circleBounds);
    }
  }, [position]);

  useEffect(() => {
    handleApiLoaded(map, maps);
  }, [position]);

  useEffect(() => {
    if (defaultLocation) {
      setPosition(defaultLocation);
      if (defaultAddress) {
        setAddress(defaultAddress);
      } else {
        const geocodeByLatLng2 = async () => {
          try {
            const results = await geocodeByLatLng(defaultLocation.center);
            if (typeof results === "object") {
              setAddress(results[0].formatted_address);
            }
          } catch (error) {
            console.error("Error", error);
          }
        };
        geocodeByLatLng2();
      }
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          let lat = pos.coords.latitude;
          let long = pos.coords.longitude;

          let currentPosition = {
            center: {
              lat: lat,
              lng: long,
            },
            zoom: 9,
          };

          setPosition(currentPosition);
          const geocodeByLatLng2 = async () => {
            try {
              const results = await geocodeByLatLng(currentPosition.center);
              if (typeof results === "object") {
                setAddress(results[0].formatted_address);
              }
            } catch (error) {
              console.error("Error", error);
            }
          };
          geocodeByLatLng2();
        },
        function (error) {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.log("The request to get user location timed out.");
              break;
            default:
              console.log("An unknown error occurred.");
              break;
          }
          setPosition(defaultLocation);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      toast.error("Votre navigateur ne supporte pas la géolocalisation.");
    }
  }, []);

  return (
    <div className="map-selection">
      <div className="mb-3 searchbar w-100">
        <div className="mt-2" style={containerStyle}>
          <GoogleMapReact
            defaultCenter={defaultLocation.center}
            defaultZoom={defaultLocation.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          ></GoogleMapReact>
        </div>
        {showRadius && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              <span className="form-label text-black fw-500 mt-2">
                Service Radius
              </span>
              <div className="d-flex gap-1 align-items-center justify-content-start">
                <input
                  value={areaRadius as string}
                  onChange={handleChangeRadius}
                  style={{ accentColor: "var(--primaryColor)" }}
                  type="range"
                  min={1}
                  step={1}
                  max={100}
                />
                <span>{areaRadius} miles</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JustMapLocation;
