import React from "react";
import { ISalon } from "../../models/SalonX";
import { Stars } from "../Stars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChair, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import MemberType from "../../models/Member";

type SwitchMemberItemProps = {
  member: MemberType;
  onClick: (member: MemberType) => void;
  selected?: boolean;
};

const SwitchMemberItem: React.FC<SwitchMemberItemProps> = ({
  member,
  onClick,
  selected,
}) => {
  return (
    <div
      onClick={() => onClick(member)}
      key={member._id}
      className={`d-flex gap-3 rounded p-3 align-items-center switch-salon-item ${
        selected ? "active" : ""
      }`}
      style={{ cursor: "pointer" }}
    >
      <div>
        {/* <img
          src={
            member.profileMember ?? require("../../assets/images/profile.jpg")
          }
          alt={member.userName}
        /> */}
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-4 align-items-center">
          <span>{member.userName}</span>
          {/* <div>
                    <Stars
                    canUpdate={false}
                        stars={member.reviewsStats.averageStars}
                        notice={member.reviewsStats.reviewCount} />
                </div> */}
        </div>
        <div className="d-flex gap-4">
          <small className="text-muted">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
            {member.relation}
          </small>
          <small className="text-muted">{member.email} seating places</small>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SwitchMemberItem);
