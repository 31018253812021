import React, { useState } from "react";
import { formatUSPrice, formatMinutes } from "../../../utils/utils";
import Button from "../../Button";
import { ISalonProfile } from "../../../pages/SeeProfessionalAccountPage";

export type TTotalAndContinueSection = {
  price: number;
  duration: number;
  salon?: ISalonProfile;
  canContinue: boolean;
  onContinue: () => void;
  onSelectMember: () => void;
};

export const TotalAndContinueSection: React.FC<TTotalAndContinueSection> = (
  props
) => {
  const [screenHeight, setScreenHeight] = useState(0);
  React.useEffect(() => {
    const onResize = () => {
      const height = window.screen.height;
      setScreenHeight(height);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div className="actionContainer border-top bg-white w-100 position-absolute bottom-0 p-2">
      <div className="">
        <div
          className={`d-flex justify-content-end flex-${
            screenHeight >= 1080
              ? "column align-items-end"
              : "row gap-2 align-items-center"
          } text-end  w-100 my-3`}
        >
          <span className="text-muted small">TOTAL</span>
          <h6 className="fw-500 text-black text-uppercase mb-1">
            {formatUSPrice(props.price)}
          </h6>
          <span className="text-muted small">
            {formatMinutes(props.duration)}
          </span>
        </div>
        <div
          className="d-flex align-items-center w-100"
          style={{ gap: "10px" }}
        >
          <Button
            onClick={props.onContinue}
            disabled={!props.canContinue}
            className="primary w-100 br-6"
            content={"Make appoitnment"}
          />
          <span>Or</span>
          <Button
            onClick={props.onSelectMember}
            disabled={!props.canContinue}
            className="secondary w-50 br-6"
            content={"Send to member"}
          />
        </div>
      </div>
    </div>
  );
};
