import { useRef, useState } from "react";
import MainNav from "../components/navigation/MainNav";
import Button from "../components/Button";
import { useFormik } from "formik";
import {
  PasswordValues,
  resetValidationSchema,
} from "../components/validation/PasswordValidation";
import { log } from "../utils/utils";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { verifyPasswordToken } from "../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import ImageSlider from "../components/reusable/imageSlider/ImageSlider";
import { slides } from "../components/reusable/imageSlider/Slides";

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const url = new URL(window.location.href); // create a URL object
  const urlParams = new URLSearchParams(url.search); // create a URLSearchParams object
  const token = urlParams.get("reset_token"); // get the value of reset_token

  const [inputType2, setInputType2] = useState(false);
  const [inputType3, setInputType3] = useState(false);

  const formik = useFormik<Partial<PasswordValues>>({
    initialValues: {
      confPassword: "",
      password: "",
    },
    validationSchema: resetValidationSchema,
    onSubmit: (values) => {
      log(JSON.stringify(values, null, 2));
      formik.resetForm();
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (submitBtnRef.current) {
      const data = {
        password: formik.values.password,
        reset_token: token,
      };
      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = "loading...";

      dispatch(
        verifyPasswordToken(data as unknown as string) as unknown as AnyAction
      ).unwrap().then(() => {
        if (submitBtnRef.current) {
          submitBtnRef.current.innerHTML =
            "<span className='mx-3'>redirecting...</span>";
        }
        toast.loading("Your password has been successfully reset.You will be automatically redirected to the login page shortly.", {
          duration: 3000,
        })
        setTimeout(() => {
          navigate("/login")
        }, 3500);
      }).catch((err: any) => {
        toast.error("Something went wrong.")
        console.log(err)
        if (submitBtnRef.current) {
          submitBtnRef.current.removeAttribute("disabled");
          submitBtnRef.current.innerHTML =
            "<span className='mx-3'>Save</span>";
        }
      });
    }
  };

  // toast.loading("Your password has been successfully reset.You will be automatically redirected to the login page shortly.")
  const handleSave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0) {
      handleSubmit(e);
    }
  };

  return (
    <>
      <MainNav navType={"homeNav"} />
      <div className="d-flex p-0 m-0 Login mx-0">
        <div className="position-relative loginLeftSide">
          <ImageSlider slides={slides} />
        </div>
        <div className="p-5 w-100">
          <h4 className="text-primary">Reset Password</h4>
          <h6>
            Please enter a new password and confirm it. Make sure your password
            is strong and easy to remember.
          </h6>
          <form>
            <div className=" mb-3">
              <label htmlFor="newPassword" className="fw-500">
                New Password
              </label>
              <div
                aria-autocomplete="none"
                className="input-group bg-white border border-1 rounded"
              >
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  required
                  type={inputType2 ? "text" : "password"}
                  className="form-control fw-500 border-0"
                  id="password"
                />
                <button
                  className="btn border-0"
                  type="button"
                  id="button-addon2"
                  onClick={() => setInputType2(!inputType2)}
                >
                  <i
                    className={!inputType2 ? "fa fa-eye " : "fa fa-eye-slash "}
                  ></i>
                </button>
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div style={{ color: "var(--primaryColor)" }}>
                {formik.errors.password}
              </div>
            ) : null}
            <div className=" mb-3">
              <label htmlFor="newPasswordRepeat" className="fw-500">
                Confirm Password
              </label>
              <div
                aria-autocomplete="none"
                className="input-group bg-white border border-1 rounded"
              >
                <input
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confPassword}
                  required
                  type={inputType3 ? "text" : "password"}
                  className="form-control fw-500 border-0"
                  id="confPassword"
                />
                <button
                  className="btn border-0"
                  onClick={() => setInputType3(!inputType3)}
                  type="button"
                  id="button-addon3"
                >
                  <i
                    className={!inputType3 ? "fa fa-eye " : "fa fa-eye-slash "}
                  ></i>
                </button>
              </div>
            </div>
            {formik.touched.confPassword && formik.errors.confPassword ? (
              <div style={{ color: "var(--primaryColor)" }}>
                {formik.errors.confPassword}
              </div>
            ) : null}

            <div className="d-flex justify-content-between mt-2">
              <Button
                className="ms-auto"
                ref={submitBtnRef}
                onClick={handleSave}
                mode={"primary"}
                content={<span className="mx-3">Save</span>}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordPage;
