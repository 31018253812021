import { Modal, ModalProps, Table } from "react-bootstrap";
import { IAppointment } from "../../models/Appointment";
import { formatUSPrice, toServiceLabel } from "../../utils/utils";
import SlidePicker from "../SlidePicker";
import { Tooltip } from "react-tooltip";
import Service from "../../models/Service";
import {
  CancelHandler,
  CancelHandlerState,
  toRemainingTime,
} from "../CancelHandler";
import { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import { disputeAppointment } from "../../redux/slices/appointmentSlice";

export interface AppointmentDetailModalProps extends ModalProps {
  appointment: IAppointment;
  onCancel: (appointment: IAppointment) => Promise<any>;
  onCancelCallback: () => void;
}

const AppointmentDetailModal: React.FC<AppointmentDetailModalProps> = ({
  appointment,
  onCancel,
  onCancelCallback,
  ...props
}) => {
  const [state, setState] = useState<CancelHandlerState>("default");
  const [modalOpen, setModalOpen] = useState(false);
  const [showDisputeModal, setShowDisputeModal] = useState(false);
  const [newDateProposal, setNewDateProposal] = useState<string | null>(null);

  const dispatch = useDispatch();

  const __handleCancel = (e: any) => {
    return Promise.resolve(0);
  };
  const handleShowDisputeModal = (e: any) => {
    setShowDisputeModal(true);
  };
  const handleConfirmDispute = () => {
    if (newDateProposal) {
      const data = { appointment, newDateProposal };
      dispatch(disputeAppointment(data) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log(res);
          toast.success("success");
          setShowDisputeModal(false);
          return Promise.resolve(0);
        })
        .catch((err: any) => {
          console.log(err);
          return Promise.reject(0);
        });
    } else {
      toast.error("You need to propose a new date");
    }
  };

  const handleCancel = () => {
    return onCancel(appointment)
      .then((res) => {
        toast.success("cancellation success");
        onCancelCallback();
        return Promise.resolve(0);
      })
      .catch((err) => {
        if (err.errors && err.errors.message) {
          toast.error(err.errors.message);
        } else {
          toast.error("cancellation failed");
        }
        return Promise.reject(0);
      });
  };

  const handleHideModal = () => {};

  useEffect(() => {}, []);

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <div className="d-flex p-0 m-0 w-100 gap-2 align-items-center justify-content-start">
          <div className="fs-4">{toServiceLabel(appointment, false)}</div>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        {appointment && appointment.service && (
          <div className="mx-3 mt-3 d-flex flex-column gap-2 mb-3">
            <h5>Services({appointment.service.length})</h5>
            <div>
              <SlidePicker
                data={appointment.service}
                renderItem={(service: Service) => {
                  return (
                    <div className="CancelServiceItem text-center rounded p-2">
                      <div>
                        {service.service_detail.name}{" "}
                        {formatUSPrice(service.price)}
                      </div>
                    </div>
                  );
                }}
                keyExtractor={(service: Service) => service._id as string}
                separator={<span className="mx-2"></span>}
                maxShow={appointment.service.length - 1}
              />
            </div>

            <div>
              <Table bordered responsive>
                <thead>
                  <tr className="text-uppercase">
                    <th>Start time</th>
                    <th>Time remaining</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-uppercase">
                    <td>
                      {moment(
                        `${appointment.day} ${appointment.startTime}`
                      ).format("DD MMM YYYY HH:mm a")}
                    </td>
                    <td>{toRemainingTime(appointment)}</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div
              className={`cancelOptions cancelOptions--open d-flex justify-content-space-between gap-5`}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {/* <CancelHandler
                  cancel={handleCancel}
                  appointment={appointment}
                  child={
                   
                  }
                /> */}
                <div
                  onClick={handleShowDisputeModal}
                  style={{ height: "40px", fontSize: "1rem" }}
                  className="btn-danger--outlined--link d-flex justify-content-end align-items-end gap-2"
                >
                  <div>Dispute</div>
                </div>
                {showDisputeModal && (
                  <div
                    className={
                      " d-flex flex-column align-items-start justify-content-between"
                    }
                  >
                    <div className="input-group mb-3 w-100">
                      <input
                        placeholder="propose a new date"
                        type="datetime-local"
                        className="form-control"
                        name="bad_date"
                        onChange={(e) => {
                          console.log(e.target.value);
                          setNewDateProposal(e.target.value);
                        }}
                      />
                    </div>
                    <div className="d-flex gap-3">
                      <button
                        className="btn"
                        onClick={() => setShowDisputeModal(false)}
                      >
                        Cancel
                      </button>
                      <button className="btn" onClick={handleConfirmDispute}>
                        Confirm
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <CancelHandler
                cancel={handleCancel}
                appointment={appointment}
                child={
                  <div
                    onClick={__handleCancel}
                    style={{ height: "40px", fontSize: "1rem" }}
                    className="btn-danger--outlined--link d-flex justify-content-end align-items-end gap-2"
                  >
                    <div>cancel it</div>
                  </div>
                }
              />
            </div>
          </div>
        )}

        <Tooltip id="cancelTooltip">
          <p className="text-center">
            This fee will be removed from the previous appointment's total
            <br /> price and Latrice will return you the remaining.
          </p>
        </Tooltip>
      </Modal.Body>
    </Modal>
  );
};

export default AppointmentDetailModal;
