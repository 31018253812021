import React from 'react'


type LineProps = Pick<React.CSSProperties, 'height' | 'background'> & { className?: string }


export const Line: React.FC<LineProps> = (
    {
        background = "var(--borderColor)",
        height = "3px",
        className = "p-0 m-0 mt-2"
    }
) => {
    return (
        <div
            style={{
                background,
                height,
            }}
            className={className}
        ></div>
    )
}
