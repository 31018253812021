import { useEffect, useRef, useState } from "react";
import Button from "../Button";
import { useFormik } from "formik";
import {
  PasswordValues,
  validationSchema,
} from "../validation/PasswordValidation";
import { log } from "../../utils/utils";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  AuthState,
  changePassword,
  clearErrors,
} from "../../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import User from "../../models/User";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const [inputType1, setInputType1] = useState(false);
  const [inputType2, setInputType2] = useState(false);
  const [inputType3, setInputType3] = useState(false);
  const [hasChange, setHasChange] = useState(false);

  const authErrors = useSelector<unknown, AuthState>(
    (state: any) => state.auth
  ).errors;

  const formik = useFormik<PasswordValues>({
    initialValues: {
      oldPassword: "",
      confPassword: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      log(JSON.stringify(values, null, 2));
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0) {
      if (submitBtnRef.current) {
        const data: Pick<User, "password"> & { oldPassword: string } = {
          oldPassword: formik.values.oldPassword,
          password: formik.values.password,
        };
        submitBtnRef.current.setAttribute("disabled", "true");
        submitBtnRef.current.innerHTML = "loading...";

        dispatch(changePassword(data) as unknown as AnyAction)
          .unwrap()
          .then((res: any) => {
            toast.success("Password changed successfully!");
            formik.resetForm();
            setHasChange(false);
          })
          .catch((err: any) => {
            formik.setErrors(err.errors);
          })
          .finally(() => {
            if (submitBtnRef.current) {
              submitBtnRef.current.removeAttribute("disabled");
              submitBtnRef.current.innerHTML =
                "<span className='mx-3'>Save</span>";
            }
          });
      }
    }
  };

  useEffect(() => {
    if (authErrors) {
      let errorMessage;
      if (authErrors.status === 422) {
        errorMessage = authErrors.errors.password;
      }
      toast.error(errorMessage);
    }
  }, [authErrors]);

  useEffect(() => {
    // This function will run when the component unmounts
    return () => {
      // Dispatch an action to clear the state.errors
      dispatch(clearErrors());
    };
  }, []);

  // useEffect(() => {
  //   // console.log("The difference", formik.values, user);
  //   // console.log("Did i make change ?", !isDeepEqual);
  //   setHasChange(true);
  // }, [formik.values]);

  return (
    <div>
      <h3 className="text-primary">Change Password</h3>
      <h6>Please enter your current password</h6>
      <form>
        <div className=" mb-3">
          <label htmlFor="oldPassword" className="fw-500">
            Old Password
          </label>
          <div
            aria-autocomplete="none"
            className="input-group bg-white border border-1 rounded"
          >
            <input
              onChange={(event) => {
                formik.handleChange(event);
                setHasChange(true);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.oldPassword}
              required
              type={inputType1 ? "text" : "password"}
              className="form-control fw-500 border-0"
              id="oldPassword"
            />
            <button
              className="btn border-0"
              type="button"
              id="button-addon1"
              onClick={() => setInputType1(!inputType1)}
            >
              <i className={inputType1 ? "fa fa-eye-slash " : "fa fa-eye "}></i>
            </button>
          </div>
        </div>
        {formik.touched.oldPassword && formik.errors.oldPassword ? (
          <div style={{ color: "var(--primaryColor)" }}>
            {formik.errors.oldPassword}
          </div>
        ) : null}
        <div className=" mb-3">
          <label htmlFor="newPassword" className="fw-500">
            New Password
          </label>
          <div
            aria-autocomplete="none"
            className="input-group bg-white border border-1 rounded"
          >
            <input
              onChange={(event) => {
                formik.handleChange(event);
                setHasChange(true);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              required
              type={inputType2 ? "text" : "password"}
              className="form-control fw-500 border-0"
              id="password"
            />
            <button
              className="btn border-0"
              type="button"
              id="button-addon1"
              onClick={() => setInputType2(!inputType2)}
            >
              <i
                className={!inputType2 ? "fa fa-eye " : "fa fa-eye-slash "}
              ></i>
            </button>
          </div>
        </div>
        {formik.touched.password && formik.errors.password ? (
          <div style={{ color: "var(--primaryColor)" }}>
            {formik.errors.password}
          </div>
        ) : null}
        <div className=" mb-3">
          <label htmlFor="newPasswordRepeat" className="fw-500">
            Retype New Password
          </label>
          <div
            aria-autocomplete="none"
            className="input-group bg-white border border-1 rounded"
          >
            <input
              onChange={(event) => {
                formik.handleChange(event);
                setHasChange(true);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.confPassword}
              required
              type={inputType3 ? "text" : "password"}
              className="form-control fw-500 border-0"
              id="confPassword"
            />
            <button
              className="btn border-0"
              onClick={() => setInputType3(!inputType3)}
              type="button"
              id="button-addon1"
            >
              <i
                className={!inputType3 ? "fa fa-eye " : "fa fa-eye-slash "}
              ></i>
            </button>
          </div>
        </div>
        {formik.touched.confPassword && formik.errors.confPassword ? (
          <div style={{ color: "var(--primaryColor)" }}>
            {formik.errors.confPassword}
          </div>
        ) : null}

        <div className="d-flex justify-content-between mt-2">
          <h6
            onClick={() => navigate("/forgot-password")}
            className="text-primary deleteAccountBtn"
          >
            Forgot Password ?
          </h6>
          <Button
            ref={submitBtnRef}
            onClick={handleSubmit}
            disabled={hasChange ? false : true}
            mode={"primary"}
            content={<span className="mx-3">Save</span>}
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
