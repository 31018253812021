import MainNav from "../components/navigation/MainNav";
import "./../styles/BusinessProfilePage.css";
import AuthLayout from "../components/auth/AuthLayout";
import { WorkingHours } from "../components/availability/WorkingHours";
import { useDispatch, useSelector } from "react-redux";
import { ChairsState, getChairs } from "../redux/slices/chairSlice";
import React, { useEffect, useState } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { useSalon } from "../context/SalonContext";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import moment, { Moment } from "moment";
import useYearSelector from "../hooks/useYearSelector";
import useWeekSelector from "../hooks/useWeekSelector";
import { USATimezone } from "./ManageAvailabilityPage";
import { useUser } from "../hooks/UseUser";
import {
  AppointmentState,
  getAppointments,
} from "../redux/slices/appointmentSlice";
import { filterAppointment } from "../utils/utils";
import { IService } from "../models/Service";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartEvent,
  ActiveElement,
} from "chart.js";
import DefaultLoader from "../components/loader/DefaultLoader";
import FilterStats from "../components/earning/FilterStats";
import { da } from "date-fns/locale";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Appointment {
  id: string;
  user: {
    profile: string;
    first_name: string;
    last_name: string;
  };
  service: IService[];
  price: number;
  day: string;
  status: string;
  tip?: number;
}

const EarningPage = () => {
  const yearSelector = useYearSelector();
  const weekSelector = useWeekSelector();

  const [weekDates, setWeekDate] = useState<Moment[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedBarData, setSelectedBarData] = useState<any>(null);
  const [timezone, setTimezone] = React.useState<USATimezone>("US/Pacific");
  const [filters, setFilters] = useState({
    day: "",
    week: "",
    month: "",
    year: "",
    status: "",
  });
  const [viewMode, setViewMode] = useState<"table" | "chart">("table");
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const user = useUser();
  const dispatch = useDispatch();

  const appointmentData = filterAppointment(
    useSelector<unknown, AppointmentState>((state: any) => state.appointments)
      .appointments,
    "confirmed"
  );
  // const [filteredData, setFilteredData] = useState(appointmentData);

  useEffect(() => {
    if (!appointmentData) {
      dispatch(getAppointments("dashboard") as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log(res);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, []);

  const handlerVerifyDataApproval = () => {
    console.log(appointmentData);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (appointmentData) {
      if (currentPage < Math.ceil(appointmentData.length / itemsPerPage)) {
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const filterAppointments = (appointments: any[]) => {
    return appointments.filter((appointment) => {
      const appointmentDate = moment(appointment.day);

      const matchDay = filters.day
        ? appointmentDate.isSame(filters.day, "day")
        : true;
      const matchWeek = filters.week
        ? appointmentDate.isBetween(
            moment(filters.week).startOf("week"),
            moment(filters.week).endOf("week")
          )
        : true;
      const matchMonth = filters.month
        ? appointmentDate.isSame(filters.month, "month")
        : true;
      const matchYear = filters.year
        ? appointmentDate.isSame(filters.year, "year")
        : true;
      const matchStatus = filters.status
        ? appointment.status === filters.status
        : true;

      return matchDay && matchWeek && matchMonth && matchYear && matchStatus;
    });
  };

  const getPromotion = (service: IService) => {
    let promotion;
    if (service.promote && service.promote.isPromote) {
      if (service.promote.isPromote === true) {
        promotion = (service.promote.price_promotion * 100) / service.price;
      } else if (service.promote.isPromote === false) {
        promotion = service.price;
      } else {
        promotion = service.price;
      }
      console.log(promotion, "promotion");

      return ` -${service.promote.price_promotion}$  (${promotion}%)`;
    }
  };

  const prepareChartData = (appointments: Appointment[]) => {
    const labels: string[] = [];
    const servicesCount: number[] = [];
    const clientsCount: number[] = [];
    const promotions: number[] = [];
    const tips: number[] = [];
    const confirmedCount: number[] = [];
    const canceledCount: number[] = [];
    const serviceEarnings: number[] = [];

    appointments.forEach((appointment) => {
      const appointmentDate = moment(appointment.day).format("YYYY-MM-DD");

      if (!labels.includes(appointmentDate)) {
        labels.push(appointmentDate);
        servicesCount.push(0);
        clientsCount.push(0);
        promotions.push(0);
        tips.push(0);
        confirmedCount.push(0);
        canceledCount.push(0);
        serviceEarnings.push(0);
      }

      const index = labels.indexOf(appointmentDate);
      servicesCount[index] += appointment.service.length;
      clientsCount[index] += 1; // Assuming one appointment per client
      tips[index] += appointment.tip || 0;
      serviceEarnings[index] += appointment.price;

      appointment.service.forEach((service) => {
        promotions[index] += service.price;
      });

      if (appointment.status === "confirmed") {
        confirmedCount[index] += 1;
      }

      if (appointment.status === "confirmed") {
        confirmedCount[index] += 1;
      } else if (appointment.status === "canceled") {
        canceledCount[index] += 1;
      }
    });

    return {
      labels,
      datasets: [
        {
          label: "Money from Services",
          data: serviceEarnings,
          backgroundColor: "rgba(225, 9, 132, 0.2)",
          borderColor: "rgba(225, 9, 132, 1)",
          borderWidth: 1,
        },
      ],
    };
  };

  const handleChartClick = (
    event: ChartEvent,
    elements: ActiveElement[],
    chart: any
  ) => {
    if (elements.length > 0) {
      let totals = 0;
      const element = elements[0];
      const dataIndex = element.index;
      const selectedDate = chart.data.labels[dataIndex];

      // Trouver les détails des services pour la date sélectionnée
      const dateAppointments =
        appointmentData &&
        appointmentData.filter(
          (appointment) =>
            moment(appointment.day).format("YYYY-MM-DD") === selectedDate
        );

      const totalServices =
        dateAppointments &&
        dateAppointments.reduce(
          (sum, appointment) => sum + appointment.service.length,
          0
        );
      const totalPrice =
        dateAppointments &&
        dateAppointments.reduce(
          (sum, appointment) => sum + appointment.price,
          0
        );
      const totalPromotions =
        dateAppointments &&
        dateAppointments.reduce(
          (sum, appointment) =>
            sum +
            appointment.service.reduce(
              (acc, service) =>
                acc +
                (service.promote && service.promote.isPromote
                  ? service.promote.price_promotion
                  : 0),
              0
            ),
          0
        );

      const totalTips =
        dateAppointments &&
        dateAppointments.reduce(
          (sum, appointment) => sum + (appointment.tip || 0),
          0
        );
      let totalServicePromotions = 0;
      if (totalPrice && totalPromotions && totalTips && dateAppointments) {
        dateAppointments.forEach((appointment) => {
          if (appointment.service.length > 0) {
            const service = appointment.service[0];
            if (service.promote && service.promote.isPromote) {
              totalServicePromotions += service.promote.price_promotion;
            } else {
              totalServicePromotions += service.price;
            }
          }
          totals = totalServicePromotions + totalTips;
        });
      }

      setSelectedBarData({
        date: selectedDate,
        totalServices,
        totalPrice,
        totalPromotions,
        totalTips,
        totals,
      });
    }
  };

  // Get current items for the page
  const filteredAppointments =
    appointmentData && filterAppointments(appointmentData);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    filteredAppointments &&
    filteredAppointments.slice(indexOfFirstItem, indexOfLastItem);

  const chartData = prepareChartData(filteredAppointments || []);

  return (
    <AuthLayout>
      <MainNav navType={"connectedNav"} isSearch={false} />
      <div style={{ height: "100vh" }}>
        <div>
          <div className="working-hours__selector-container mt-4 p-3">
            <FilterStats
              filters={filters}
              setFilters={setFilters}
              className="ms-auto"
            />
          </div>
          <div className="d-flex justify-content-end mb-3 mt-3">
            <button
              className="btn btn-primary me-2"
              onClick={() => setViewMode("table")}
            >
              Table View
            </button>
            <button
              className="btn btn-primary"
              onClick={() => setViewMode("chart")}
            >
              Chart View
            </button>
          </div>
        </div>
        {viewMode === "table" ? (
          <div className="mt-5 w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex align-items-start justify-content-start">
              <h4 className="fw-bold text-primary">View your statistic</h4>
            </div>
            <table
              className="table table-striped table-hover table-hover-primary"
              style={{ width: "90%" }}
            >
              <thead className="table-dark">
                <tr>
                  <th>#</th>
                  <th>Client profile</th>
                  <th>Client name</th>
                  <th onClick={() => handlerVerifyDataApproval()}>
                    Service name
                  </th>
                  <th>Price</th>
                  <th>Promotion</th>
                  <th>Tip</th>
                  <th>Date</th>
                  <th>Chair</th>
                  <th>Status</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {currentItems &&
                  currentItems.length > 0 &&
                  currentItems.map((appointmentData, index) => (
                    <tr key={appointmentData.id} className="">
                      <td>{indexOfFirstItem + index + 1}</td>
                      <td>
                        {" "}
                        <img
                          src={
                            appointmentData.user.profile ??
                            require("../assets/images/profile.jpg")
                          }
                          alt="client placeholder"
                          className="rounded-circle"
                          width="50"
                          height="50"
                        />{" "}
                      </td>
                      <td>
                        {appointmentData.user.first_name +
                          " " +
                          appointmentData.user.last_name}
                      </td>
                      <td>
                        {appointmentData.service
                          .map(
                            (service: IService) => service.service_detail.name
                          )
                          .join(", ")}
                      </td>
                      <td>{appointmentData.price}</td>
                      <td>
                        {appointmentData.service.map((service: IService) =>
                          getPromotion(service)
                        )}
                      </td>
                      <td>{+0}</td>
                      <td>{appointmentData.day}</td>
                      <td>{appointmentData.user.lastName}</td>
                      <td>
                        <span className="badge bg-success">
                          {appointmentData.status}
                        </span>
                      </td>
                      <td>{appointmentData.price}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {currentItems && currentItems.length === 0 && (
              <div className="d-flex justify-content-center align-items-center flex-column text-center w-100">
                <p>You did not perform any service today.</p>
                <img
                  src={require("../assets/images/noData.png")}
                  alt="no data"
                  className="img-fluid"
                  width="300px"
                  height="auto"
                />
              </div>
            )}
            {filteredAppointments === null && (
              <div className="d-flex justify-content-center align-items-center">
                <DefaultLoader title="We are fetching your earning" />
              </div>
            )}
            {filteredAppointments &&
              currentItems &&
              currentItems.length > 0 && (
                <div className="pagination">
                  <button
                    className="btn btn-primary"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span className="mx-3">Page {currentPage}</span>
                  <button
                    className="btn btn-primary"
                    onClick={handleNextPage}
                    disabled={
                      currentPage ===
                      Math.ceil(filteredAppointments.length / itemsPerPage)
                    }
                  >
                    Next
                  </button>
                </div>
              )}
          </div>
        ) : (
          <div className="chart-container mt-5 w-75 d-flex flex-column justify-content-center align-items-center mx-auto">
            {filteredAppointments && (
              <>
                {selectedBarData && (
                  <div className="mt-3 ">
                    <h5 className="text-primary">
                      Details for {selectedBarData.date}:
                    </h5>
                    <div className="d-flex flex-row gap-3">
                      <p className="p-4 bg-black text-primary rounded-4">
                        <strong className="text-white">
                          Number of Services:
                        </strong>{" "}
                        {selectedBarData.totalServices}
                      </p>

                      <p className="p-4 bg-black text-primary rounded-4">
                        <strong className="text-white">Total Prices:</strong> $
                        {selectedBarData.totalPrice.toFixed(2)}
                      </p>
                      <p className="p-4 bg-black text-primary rounded-4">
                        <strong className="text-white">
                          Total Promotions:
                        </strong>{" "}
                        ${selectedBarData.totalPromotions.toFixed(2)}
                      </p>
                      <p className="p-4 bg-black text-primary rounded-4">
                        <strong className="text-white">Total Tips:</strong> $
                        {selectedBarData.totalTips.toFixed(2)}
                      </p>
                      <p className="p-4 bg-black text-primary rounded-4">
                        <strong className="text-white">Totals:</strong> $
                        {selectedBarData.totals.toFixed(2)}
                      </p>
                    </div>
                  </div>
                )}
                <Bar
                  data={chartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: { position: "bottom" },
                      tooltip: {
                        callbacks: {
                          title: (items) => {
                            if (items.length > 0) {
                              const dataIndex = items[0].dataIndex;
                              return `Date: ${chartData.labels[dataIndex]}`;
                            }
                            return "";
                          },
                          label: (context) => {
                            const dataIndex = context.dataIndex;
                            const labels = chartData.datasets.map((dataset) => {
                              const value = dataset.data[dataIndex];
                              return `${dataset.label}: ${value}`;
                            });
                            return labels;
                          },
                        },
                      },
                    },
                    onClick: handleChartClick,
                  }}
                />
              </>
            )}
          </div>
        )}
      </div>
      <Footer />
    </AuthLayout>
  );
};

export default EarningPage;
