import React from "react";
import "../styles/AdminDashboard.css";
import Manager from "../models/Manager";
import MainDashboard from "../components/adminDashboard/MainDashboard";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../components/auth/AuthLayout";
import MainNav from "../components/navigation/MainNav";
import Footer from "../components/Footer";

type AdminDashboardProps = {};

const AdminDashboard: React.FC<AdminDashboardProps> = () => {
  return (
    <AuthLayout>
      <MainNav navType={"connectedNav"} />
      <div className=" d-flex flex-column main-container mt-md-3 container ">
        <MainDashboard />
      </div>
      <Footer />
    </AuthLayout>
  );
};

export default AdminDashboard;
