import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import ModalLoader from "../../loader/ModalLoader";
import { IChair } from "../../../models/Chair";
import toast from "react-hot-toast";
import axios from "axios";
import { editChair } from "../../../services/ChairService";

type EditChairModalProps = {
  show: boolean;
  onClose: () => void;
  chair: IChair;
  handleEdit: (editedChair: IChair) => void;
};

const EditChairModal: FC<EditChairModalProps> = ({
  show,
  onClose,
  chair,
  handleEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [chairData, setChairData] = useState<Partial<IChair>>({
    label: chair.label,
    active: chair.active,
    _id: chair._id,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setChairData({
      ...chairData,
      [name]: value,
    });
  };

  const handleSave = () => {
    setLoading(true);
    handleEdit(chairData as IChair);

    editChair(chairData)
      .then((res) => {
        setLoading(false);
        onClose();
      })
      .catch((err: any) => {
        toast.error(
          "There was an error while editing. Please try again later."
        );
      });
  };

  return (
    <Modal backdrop="static" show={show} onHide={onClose} centered size="lg">
      <Modal.Header closeButton>
        <div className="text-black fw-500 fs-5">Edit seat</div>
      </Modal.Header>
      {loading && <ModalLoader />}
      <Modal.Body className="d-flex flex-column gap-2 border-top border-bottom">
        <div className="w-100 px-3 py-4">
          <div className="d-flex p-0 flex-column salon-input gap-1 mb-3">
            <span className="text-primary">
              Label <i className="ms-2 fa-solid fa-tag text-primary"></i>
            </span>
            <input
              type="text"
              className="bg-white border rounded"
              placeholder="label"
              id="label"
              name="label"
              onChange={handleChange}
              value={chairData.label}
              required
            />
          </div>
          <div className="d-flex p-0 flex-column salon-input gap-1 ">
            <span className="text-primary">
              Notes
              <i className="ms-2 fa-regular fa-note-sticky text-primary"></i>
            </span>
            <textarea
              placeholder="Add a note"
              name="note"
              id="note"
              className="border rounded p-2"
              // onChange={handleChange}
              cols={30}
              maxLength={150}
              // value={ch}
            ></textarea>
          </div>
          <div className="d-flex p-0 flex-column salon-input gap-1 mt-3">
            <span className="text-primary">
              Update status
              <i className="ms-2 fa-regular fa-note-sticky text-primary"></i>
            </span>

            <select
              onChange={handleChange}
              style={{ fontSize: "1em !important" }}
              className="form-select form-select-lg"
              name="active"
              id=""
            >
              <option value="" selected disabled hidden>
                Update status of your chair
              </option>
              <option value="true">Active</option>
              <option value="false">Not Active</option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <div className="mx-3 my-1 d-flex justify-content-end align-items-center gap-3">
          <button onClick={onClose} className="working-table-outline-button">
            Cancel
          </button>
          <button onClick={handleSave} className="working-table-primary-button">
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditChairModal;
