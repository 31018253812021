import React from "react";
import User from "../../models/User";
import { formatName } from "../../utils/utils";
import { AccountAvailableState } from "../account/AccountAvailableState";
import { AvailableState } from "../../models/Availability";
import { Stars } from "../Stars";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCalendarPlus,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import ProfessionalProfileLoader from "../loader/ProfessionalProfileLoader";
import { ISalon } from "../../models/SalonX";

type ProfessionalProfileProps = {
  salon: ISalon | null;
  salonId?: string;
  handleRequestCustom: (value: any) => void;
};

const ProfessionalProfile: React.FC<ProfessionalProfileProps> = ({
  salon,
  salonId,
  handleRequestCustom,
}) => {
  return (
    <div>
      {salon !== null && (
        <div className="d-flex flex-column gap-2">
          <div className="professional__profile d-flex align-items-center gap-2 pro_profile_info">
            <div className="img_container ">
              <img
                className="img-fluid"
                src={
                  salon.profile ?? require("../../assets/images/profile.jpg")
                }
                alt={salon.name}
              />
            </div>
            <div className="d-flex flex-column text_container">
              <div className="text-white fw-bold fs-4">
                {formatName(salon.name as string, "")}
              </div>
              <div className="text-white text-muted d-flex gap-1 align-items-center small">
                <i className="fa-solid fa-location-dot"></i>
                <div className=" ">
                  {salon.address ?? "No location provided"}
                </div>
              </div>
              <div className="d-flex ">
                {/* {
                  <AccountAvailableState
                    available={professional.available as AvailableState}
                    id={professional._id}
                    name={
                      professional.first_name + "-" + professional.last_name
                    }
                  />
                } */}
              </div>
              <div className="starsContainer mt-2 d-flex justify-content-start">
                <Stars
                  canUpdate={true}
                  stars={salon.reviewsStats?.averageStars as number}
                  salonId={salonId}
                  notice={salon.reviewsStats?.reviewCount as number}
                />
              </div>
              <div>
                <span className="fw-bold ms-1 text-black">
                  Wheel Chair Accessible ? *{" "}
                  <i className="fa fa-info-circle text-primary"></i>{" "}
                  <span className="text-primary">
                    {salon.wheelchair_accessible ? "Yes" : "No"}
                  </span>
                </span>
                <span className="fw-bold ms-1 text-black">
                  &nbsp; kids friendly ? *{" "}
                  <i className="fa fa-info-circle text-primary"></i>{" "}
                  <span className="text-primary">
                    {salon.kids_friendly ? "Yes" : "No"}
                  </span>
                </span>
                <span className="fw-bold ms-1 text-black">
                  &nbsp; kids service ? *{" "}
                  <i className="fa fa-info-circle text-primary"></i>{" "}
                  <span className="text-primary">
                    {salon.kids_service ? "Yes" : "No"}
                  </span>
                </span>
                <span className="fw-bold ms-1 text-black">
                  &nbsp; Drive to client ? *{" "}
                  <i className="fa fa-info-circle text-primary"></i>{" "}
                  <span className="text-primary">
                    {salon.drive_to_client ? "Yes" : "No"}
                  </span>
                </span>
                <span className="fw-bold ms-1 text-black">
                  &nbsp; free parking for client ? *{" "}
                  <i className="fa fa-info-circle text-primary"></i>{" "}
                  <span className="text-primary">
                    {salon.free_parking_for_client ? "Yes" : "No"}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="w-100">
            <p className="m-0">{salon.bio}</p>
          </div>
          <div className="d-flex my-3 p-2 left_btns gap-5">
            <Button
              disabled={salon === null}
              mode="outlined"
              type={"button"}
              style={{ width: "100%", borderRadius: "13px" }}
              content={
                <div className="text-black">
                  <FontAwesomeIcon icon={faCalendarCheck} /> See Availability
                </div>
              }
            />
            <Button
              style={{ width: "100%", borderRadius: "13px" }}
              disabled={salon === null}
              onClick={
                salon && salon.accept_custom_orders
                  ? handleRequestCustom
                  : () => {
                      toast.remove();
                      toast.loading(
                        (t) => (
                          <div className="d-flex flex-column gap-2 justify-content-center">
                            <p>
                              {`${salon.name} is currently not accepting custom bookings. You can either book from the available services or try again later.`}
                            </p>
                          </div>
                        ),
                        {
                          icon: null,
                          duration: 10000,
                        }
                      );
                    }
              }
              type={"button"}
              content={
                <div className="d-flex w-100 justify-content-center gap-2 align-items-center">
                  <FontAwesomeIcon icon={faCalendarPlus} /> Request Custom
                </div>
              }
            />
          </div>
        </div>
      )}

      {salon === null && <ProfessionalProfileLoader />}
    </div>
  );
};

export default ProfessionalProfile;
