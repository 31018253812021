import axios from "axios";
import Member from "../models/Member";

const createMember = async (member: any) => {
  console.log("member i pass", member);
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}members`,
    method: "POST",
    data: {
      ...member,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    console.log(data);
    return data;
  });
};
const deleteMember = async (member: any) =>{
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}members/${member._id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    console.log("deleted data",data);
    return data;
  });


}
const sendAppointmentRequest = async (data: any) => {
  console.log("appointmentdata i pass to member", data);
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}members/send_ppointment_request`,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    console.log(data);
    return data;
  });
};
const acceptRequestAppointment = async (data: any) => {
  console.log("appointmentdata i pass to member", data);
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}members/accept_ppointment_request`,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    console.log(data);
    return data;
  });
};
const rejectRequestAppointment = async (data: any) => {
  console.log("appointmentdata i pass to member", data);
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}members/reject_ppointment_request`,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    console.log(data);
    return data;
  });
};

const getMemberNames = () => {
  return axios
    .get(`members/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const getMember = () => {
  return axios
    .get(`members`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const acceptMember = (member: any) => {
  console.log("member", member);
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}members/accept`,
    method: "PATCH",
    data: member,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};
const rejectMember = (member: any) => {
  console.log("member", member);
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}members/reject`,
    method: "PATCH",
    data: member,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

export const MemberService = {
  createMember,
  deleteMember,
  getMemberNames,
  getMember,
  acceptMember,
  rejectMember,
  sendAppointmentRequest,
  acceptRequestAppointment,
  rejectRequestAppointment,
};
