import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ChairService } from "../../services/ChairService";
import { TWorkingTableElement } from "../../components/availability/WorkingTableElement";
import { addAvailability, addBulkAvailability } from "./availabilitySlice";
import Availability from "../../models/Availability";
import moment from "moment";
import {
  addBulkUnAvailability,
  addUnAvailability,
} from "./unAvailabilitySlice";
import { Transaction } from "../../models/Account";
import { AccountService } from "../../services/AccountService";

export interface AccountState {
  balance: number | null;
  transactions: Transaction[] | null;
}

const initialState: AccountState = {
  balance: null,
  transactions: null,
};

export const getBalance = createAsyncThunk<number>(
  "account/getBalance",
  async () => {
    const res = await AccountService.getBalance();
    console.log("res", res);
    return res;
  }
);
export const getTransactions = createAsyncThunk<Transaction[], string>(
  "account/getTransactions",
  async () => {
    const res = await AccountService.getTransactions();
    return res;
  }
);

export const depositFunds = createAsyncThunk<Transaction, object>(
  "account/depositFunds",
  //@ts-ignore
  async (data) => {
    console.log("data", data);
    const res = await AccountService.depositFunds(data);
    return res;
  }
);
export const transfertFunds = createAsyncThunk<Transaction, object>(
  "account/transfertFunds",
  //@ts-ignore
  async (data) => {
    const res = await AccountService.transfertunds(data);
    return res;
  }
);

export const sendTipsFunds = createAsyncThunk<Transaction, object>(
  "account/sendTips",
  // @ts-ignore
  async (data) => {
    const res = await AccountService.sendTipsFunds(data);
    return res;
  }
);

export const deleteAccount = createAsyncThunk<null>(
  "account/deleteAccount",
  //@ts-ignore
  async () => {
    const res = await AccountService.deleteAccount();
    return res;
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBalance.fulfilled, (state, action) => {
        state.balance = action.payload;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload;
      })
      .addCase(depositFunds.fulfilled, (state, action) => {
        state.transactions = [...(state.transactions ?? []), action.payload];
        if (state.balance) {
          state.balance = state.balance + action.payload.amount;
        } else {
          state.balance = action.payload.amount;
        }
      })
      .addCase(transfertFunds.fulfilled, (state, action) => {
        state.transactions = [...(state.transactions ?? []), action.payload];
        if (state.balance) {
          state.balance = state.balance - action.payload.amount;
        } else {
          state.balance = action.payload.amount;
        }
      })
      .addCase(sendTipsFunds.fulfilled, (state, action) => {
        state.transactions = [...(state.transactions ?? []), action.payload];
        if (state.balance) {
          state.balance = state.balance - action.payload.amount;
        } else {
          state.balance = action.payload.amount;
        }
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.balance = null;
      });
  },
});

export default accountSlice.reducer;
