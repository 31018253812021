import { Ref, useEffect, useRef, useState } from "react";
import Button from "../components/Button";
import Footer from "../components/Footer";
import { RequestCustomModal } from "../components/modal";
import BookNowModal from "../components/modal/BookNowModal";
import MainNav from "../components/navigation/MainNav";
import "../styles/ClientProfilePage.css";
import "../styles/SeeProfessionalAccountPage.css";
import Icons from "../components/reusable/Icons";
import { useDispatch } from "react-redux";
import { IBookingService, IService } from "../models/Service";
import { useParams } from "react-router";
import { ProfessionalService } from "../services/ProfessionalService";
import toast, { Toaster } from "react-hot-toast";
import { shareContent } from "../utils/utils";
import ServiceList from "../components/seeProAccount/ServiceList";
import ReactSimpleImageViewer from "react-simple-image-viewer";
import BookmarkButton from "../components/BookmarkButton";
import { updateUser } from "../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import socket from "../socket/socket";
import ProfessionalProfile from "../components/seeProAccount/ProfessionalProfile";
import ScrollButton from "../components/seeProAccount/ScrollButton";
import { ISalon } from "../models/SalonX";
import { IReviewStat } from "../utils/types";
import SalonDetail from "../components/page/home/SalonDetail";
import { SalonPolicy } from "../components/page/home/SalonPolicy";
import { IChair } from "../models/Chair";
import ServiceLoader from "../components/loader/ServiceLoader";
import { ServiceListSkeleton } from "../skeletons/ServiceListSkeleton";
import { AddPromotion } from "../components/promotion/AddPromotion";
import { useUser } from "../hooks/UseUser";

export type ISalonProfile = ISalon & {
  services: IService[];
  reviewsStats: IReviewStat;
  unavailabilities: any[];
  availabilities: any[];
  chairs: IChair[];
};

const SeeProfessionalAccountPage = () => {
  const dispatch = useDispatch();
  const submitBtnRef = useRef<HTMLButtonElement>();

  const currentUser = localStorage.getItem("authToken");
  const user = useUser();

  const [openModal, setOpenModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [reRrender, setReRender] = useState(false);
  const [openCustomRequestModal, setOpenCustomRequestModal] = useState(false);
  const [bookNowService, setBookNowService] = useState<IBookingService | null>(
    null
  );

  const { accountId } = useParams();
  const professionalId = accountId?.split("-").at(-1);

  const handleBookNow = (service: IBookingService) => {
    setBookNowService(service);
  };

  const handleRequestCustom = (e: any) => {
    setOpenCustomRequestModal(true);
  };

  const handleLogin = () => {
    window.location.href = "/login";
  };

  const handleRegister = () => {
    window.location.href = "/register";
  };

  const addToBookmarks = (e: any) => {
    if (!currentUser) {
      return toast.error(
        (t) => (
          <div className="d-flex flex-column gap-2 justify-content-center">
            <p>
              You must login first before adding bookmark. If you don't have an
              account you can{" "}
              <span onClick={handleRegister} className="createAccountLink">
                create one here.
              </span>
            </p>
            <div className="d-flex gap-2 justify-content-end">
              <Button
                className="rounded mx-2"
                mode="default"
                onClick={handleLogin}
              >
                {" "}
                Login{" "}
              </Button>
            </div>
          </div>
        ),
        {
          icon: null,
        }
      );
    }

    if (submitBtnRef.current && professionalProfileData) {
      const professionalId = professionalProfileData?._id;
      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = "loading...";

      const formData = new FormData();
      formData.append("bookmarks", professionalId);
      dispatch(updateUser(formData) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          const bookmarks: any[] = res.bookmarks;

          const test = bookmarks.filter((x) => x === professionalId);
          if (test.length > 0) {
            toast.success("Added to bookmarks");
            setBookmarked(true);
          } else {
            toast.success("Removed from bookmarks");
            setBookmarked(false);
          }
        })
        .finally(() => {
          if (submitBtnRef.current) {
            submitBtnRef.current.removeAttribute("disabled");
            submitBtnRef.current.innerHTML = `<div>
            <i class="fa-regular fa-bookmark mx-2"></i>
            <span class="me-2">BOOKMARK</span>
            </div>
            `;
          }
        });
    }
  };

  const [professionalProfileData, setProfessionalProfileData] =
    useState<ISalonProfile | null>(null);
  const [errorOnGetttingProfileData, setErrorOnGettingProfileData] =
    useState(false);

  const handleRetry = (t: any) => {
    toast.dismiss(t.id);
    getProfessionalProfile();
  };

  const getProfessionalProfile = () => {
    ProfessionalService.getProfessionalById(professionalId as string)
      .then((res) => {
        console.log(
          "response after getting professionalData",
          res,
          professionalId
        );
        setProfessionalProfileData(res);
      })
      .catch((err) => {
        // Add
        setErrorOnGettingProfileData(true);
        toast.loading(
          (t) => (
            <div className="d-flex flex-column gap-2 justify-content-center">
              <p>
                Unable to get professional profile at this time. Maybe your
                profile doesn't exist.
              </p>
              <div className="d-flex gap-2 justify-content-end">
                <Button
                  className="rounded mx-2"
                  mode="default"
                  onClick={handleRetry}
                >
                  Retry
                </Button>
              </div>
            </div>
          ),
          {
            icon: null,
          }
        );
      });
  };

  const handleShareProfile = () => {
    if (!professionalProfileData) return;
    shareContent({
      title: "Latrice",
      text: `${professionalProfileData?.name}`,
      url: window.location.href,
    });
  };

  const navigateToDashboard = () => {
    window.location.href = "/appointments";
  };

  const handleSendBookRequestSuccess = () => {
    toast.success(
      (t) => (
        <div className="d-flex flex-column gap-2 justify-content-center">
          <p>You have successfully send your book request to your member</p>
        </div>
      ),
      {
        duration: 10000,
      }
    );
    setBookNowService(null);
  };
  const handleBookSuccess = () => {
    toast.success(
      (t) => (
        <div className="d-flex flex-column gap-2 justify-content-center">
          <p>
            Success! Your booking for the selected services was completed
            successfully{" "}
          </p>
          <div className="d-flex gap-2 justify-content-end">
            <Button
              className="rounded mx-2"
              mode="default"
              onClick={navigateToDashboard}
            >
              {" "}
              See my dashboard{" "}
            </Button>
          </div>
        </div>
      ),
      {
        duration: 10000,
      }
    );
    setBookNowService(null);
    setOpenModal(true);
  };

  useEffect(() => {
    getProfessionalProfile();
  }, []);

  // useEffect(() => {
  //   if (user?.coupon?.actived === true) {
  //     setOpenModal(false);
  //   }
  // });

  useEffect(() => {
    socket().on("SET_AVAILABLE", (...args) => {
      const { available, professionalId } = args[0];
      setProfessionalProfileData((professional: any) => {
        if (professional)
          return { ...professional, available: available as number | boolean };
      });
    });
    return () => {
      socket().off("NEW_NOTIFICATION");
    };
  }, []);

  useEffect(() => {
    console.log("professionalProfileData salons", professionalProfileData);
  }, []);

  return (
    <>
      <MainNav navType={"homeNav"} />
      <Toaster position="bottom-right" />
      <div className="PageContainer">
        <div className="CoverContainer"></div>
        <div className="container p-lg-0">
          <div className="DetailsContainer d-flex justify-content-start gap-2">
            <div className="d-flex justify-content-between w-100 gap-2 page_main">
              <div className="left d-flex flex-column gap-2 col-6 col-xl-6">
                <ProfessionalProfile
                  salon={professionalProfileData}
                  handleRequestCustom={handleRequestCustom}
                  salonId={professionalId}
                />
                <hr />
                <ServiceList
                  services={professionalProfileData?.services}
                  onSelect={handleBookNow}
                  scrollable={false}
                />
              </div>

              <div className="right d-flex flex-column">
                <SalonDetail
                  ref={submitBtnRef as any}
                  salonProfileData={{
                    availabilities: professionalProfileData?.availabilities,
                    unavailabilities: professionalProfileData?.unavailabilities,
                    portfolio: professionalProfileData?.portfolio,
                    location: professionalProfileData?.location,
                  }}
                  actions={{
                    bookmarked: bookmarked,
                    onAddToBookmark: addToBookmarks,
                    onShareProfile: handleShareProfile,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <ScrollButton />
      </div>

      <Footer />
      {openCustomRequestModal && professionalProfileData && (
        <RequestCustomModal
          onBookmark={addToBookmarks}
          bookMarked={bookmarked}
          salon={professionalProfileData}
          onClose={() => {
            setOpenCustomRequestModal(false);
          }}
          backdrop={true}
          showChairSelectionForm={professionalProfileData.chairs.length !== 0}
        />
      )}

      {bookNowService && professionalProfileData && (
        <BookNowModal
          onClose={() => {
            setBookNowService(null);
          }}
          onBook={handleBookSuccess}
          onSendBookRequest={handleSendBookRequestSuccess}
          open={bookNowService !== null}
          backdrop={bookNowService !== null}
          service={bookNowService}
          salon={professionalProfileData}
          style={{ height: "100vh !important" }}
          onBookmark={addToBookmarks}
          bookMarked={bookmarked}
        />
      )}
      {user && (
        <AddPromotion show={openModal} onClose={() => setOpenModal(false)} />
      )}
      {professionalProfileData &&
        professionalProfileData.portfolio &&
        showModal && (
          <ReactSimpleImageViewer
            src={professionalProfileData.portfolio}
            currentIndex={imageIndex}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={() => setShowModal(false)}
          />
        )}
    </>
  );
};

export default SeeProfessionalAccountPage;
