import React from "react";

type Props = {};

const ProfessionalProfileLoader = (props: Props) => {
  return (
    <div className="d-flex gap-2 align-items-center">
      <div className="img_container">
        <div
          style={{
            width: "200px",
            height: "200px",
          }}
          className="profile_img shimmer shimmer__circle shimmer__shadow"
        ></div>
      </div>
      <div className="d-flex flex-column gap-2 w-100">
        <div
          style={{
            borderRadius: "0",
            height: "22px",
            width: "60%",
            borderWidth: "1px",
          }}
          className="shimmer mt-1 shimmer__line shimmer__shadow align-self-start"
        ></div>
        <div
          style={{
            borderRadius: "0",
            height: "15px",
            width: "40%",
            borderWidth: "1px",
          }}
          className="shimmer mt-1 shimmer__line shimmer__shadow align-self-start"
        ></div>
        <div
          style={{
            borderRadius: "0",
            height: "15px",
            width: "50%",
            borderWidth: "1px",
          }}
          className="shimmer mt-1 shimmer__line shimmer__shadow align-self-start"
        ></div>
        <div
          style={{
            borderRadius: "0",
            height: "15px",
            width: "70%",
            borderWidth: "1px",
          }}
          className="shimmer mt-1 shimmer__line shimmer__shadow align-self-start"
        ></div>
      </div>
    </div>
  );
};

export default ProfessionalProfileLoader;
