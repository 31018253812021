import { SetStateAction, useState } from "react";
import Navbar from "../components/Navbar";
import VerticalMenu from "../components/VerticalMenu";
import "../styles/ClientProfilePage.css";
import styles from "../styles/AppointmentPage.module.css";
import Button from "../components/Button";
import Bookmark from "../components/account/Bookmark";
import Footer from "../components/Footer";

const ManageAppointmentPage = () => {
  const [activeMenu, setActiveMenu] = useState(1);
  const verticalMenuItems = [
    {
      faIcon: "fa-solid fa-calendar-days",
      title: "Dates",
    },
    // {
    //   faIcon: "fa-solid fa-calendar-xmark",
    //   title: "Package Deals",
    // },
    // {
    //   faIcon: "fa-solid fa-bookmark",
    //   title: "Freeze your calendar",
    // },
    {
      faIcon: "fa-solid fa-triangle-exclamation",
      title: "Cancelled Appointments",
    },
    {
      faIcon: "fa fa-book",
      title: "Disputes  ",
    },
    // {
    //   faIcon: "fa fa-book",
    //   title: "Report  ",
    // },
  ];

  const handleVerticalMenuItemClick = (
    item: any,
    key: SetStateAction<number>
  ) => {
    setActiveMenu(key);
  };

  return (
    <>
      <Navbar connected={true} search={true} />
      <div className="container p-lg-0">
        <div className="d-flex p-0 gap-2 m-0 w-100">
          <div className={`${styles["verticalMenu__container"]} border-end`}>
            <div className="">
              <VerticalMenu
                active={activeMenu}
                items={verticalMenuItems}
                onItemClick={handleVerticalMenuItemClick}
              />
            </div>
          </div>
          {activeMenu === 0 && (
            <>
              <div className="col">
                <div className="">
                  <Button
                    className="br-6 ms-auto mt-2"
                    mode={"primary"}
                    content={<span className="mx-3">Manage</span>}
                  />
                  {/* <AppointmentGraph
                                appointments={[]}
                                appointmentRender={(value) => AppointmentItem(value)}
                                startAt={new Date()}
                                maxWidth='50vw'
                            /> */}
                </div>
              </div>
              {/* <AddAppointmentModal open={false} /> */}
            </>
          )}

          {/** BOOKMARK */}

          {activeMenu === 2 && (
            <div className={`d-flex  p-2`}>
              <Bookmark />
            </div>
          )}

          {/** BOOKMARK */}

          {/** CANCELLATION */}

          {activeMenu === 1 && (
            <div className={`d-flex p-2 w-100`}>{/* <Cancellation /> */}</div>
          )}

          {/** CANCELLATION */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ManageAppointmentPage;
