import { useEffect, useRef, useState } from "react";
import MainNav from "../components/navigation/MainNav";
import "../styles/ServicePromotionPage.css";
import SponsorView from "../components/promotion/SponsorView";
import Slider from "react-slick";
import { settings } from "../components/customArrows/Settings";
import Icons from "../components/reusable/Icons";
import { ISalonProfile } from "./SeeProfessionalAccountPage";
import ServiceList from "../components/seeProAccount/ServiceList";
import { ProfessionalService } from "../services/ProfessionalService";
import toast from "react-hot-toast";
import Button from "../components/Button";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ServiceState,
  addServicePromotion,
  editService,
} from "../redux/slices/serviceSlice";
import { AddPromotion } from "../components/promotion/AddPromotion";
import Service, { IService } from "../models/Service";
import ServicePromotionList from "../components/promotion/servicePromotionList";
import { formatTime, formatUSPrice, log } from "../utils/utils";
import PromoCodeGenerator from "../components/promotion/PromoCodeGenerator";
import MemberType from "../models/Member";
import { MemberState } from "../redux/slices/memberSlice";
import { MemberService } from "../services/MemberService";
import CustomDropdown, { Item } from "../components/reusable/CustomDropdown";
import { AnyAction } from "@reduxjs/toolkit";
import {
  initialServiceValues,
  servicePromotionSchema,
  serviceSchema,
} from "../components/validation/ServiceMenuValidation";
import { useFormik } from "formik";
import moment from "moment";
import PromotionModal from "../components/promotion/PromotionModal";

const ServicePromotionPage = () => {
  const [professionalProfileData, setProfessionalProfileData] =
    useState<ISalonProfile | null>(null);
  const [errorOnGetttingProfileData, setErrorOnGettingProfileData] =
    useState(false);
  const { accountId } = useParams();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [q, setQ] = useState<string | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [currentService, setCurrentService] = useState<Service | null>(null);
  const [editedService, setEditedService] = useState<Partial<IService>>();
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [date, setDate] = useState<string | any>("");
  const [memberNames, setMemberName] = useState<any[]>([]);
  const [newService, setNewService] =
    useState<Partial<Service>>(initialServiceValues);
  const [isCheck, setIsCheck] = useState(true);
  const dispatch = useDispatch();
  const submitBtnRef = useRef<any>();

  const targetDate = new Date();

  const MemberNameData = useSelector<unknown, MemberState>(
    (state: any) => state.myMember
  ).members;

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setEditedService({
      ...editedService,
      [name]: value,
    });
  };

  const professionalId = accountId?.split("-").at(-1);
  const userServices = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).userServices;

  const toDate = (value: string) => {
    const date = new Date(value);
    return moment(
      `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`,
      "YYYY-MM-DD"
    ).format("YYYY-MM-DD");
  };

  const getProfessionalProfile = () => {
    ProfessionalService.getProfessionalById(professionalId as string)
      .then((res) => {
        console.log(
          "response after getting professionalData",
          res,
          professionalId
        );
        setProfessionalProfileData(res);
      })
      .catch((err) => {
        // Add
        setErrorOnGettingProfileData(true);
        toast.loading(
          (t) => (
            <div className="d-flex flex-column gap-2 justify-content-center">
              <p>
                Unable to get professional profile at this time. Maybe your
                profile doesn't exist.
              </p>
            </div>
          ),
          {
            icon: null,
          }
        );
      });
  };

  const handleShow = () => {
    setShow(true);
  };

  // if (submitBtnRef) {
  //   console.log(submitBtnRef.current);
  // }

  // const handleSubmit = (e: any) => {
  //   e.stopPropagation();
  //   e.preventDefault();

  //   //if (!selectedService || !editedService) return;

  //   if (selectedService && editedService) {
  //     setDate(editedService.promote?.startTime);
  //     const updatedService = {
  //       ...selectedService,
  //       promote: {
  //         isPromote: true,
  //         price_promotion: editedService.promote
  //           ?.price_promotion as unknown as number,
  //         startTime: new Date(editedService.promote?.startTime as string),
  //         endTime: new Date(editedService.promote?.endTime as string),
  //       },
  //     };
  //     setLoading(true);

  //     if (submitBtnRef) {
  //       submitBtnRef.current.setAttribute("disabled", "true");
  //       dispatch(
  //         addServicePromotion(updatedService as Service) as unknown as AnyAction
  //       )
  //         .unwrap()
  //         .then(() => {
  //           toast.success("Promotion create successfully!");
  //           setSelectedService(updatedService);
  //           console.log("baka", setSelectedService(updatedService));
  //         })
  //         .catch((err: any) => {
  //           toast.error(
  //             "An error occured during edition, please try again later"
  //           );
  //         })
  //         .finally(() => {
  //           setLoading(false);
  //           setTimeout(() => {
  //             if (submitBtnRef.current) {
  //               submitBtnRef.current.removeAttribute("disabled");
  //             }
  //           }, 2000);

  //           setCurrentService(null);
  //         });
  //     }
  //   }
  // };
  const handleSelectService = (service: Service) => {
    setSelectedService(service);
    formik.setValues({
      ...formik.values,
      promote: {
        isPromote: true,
        price_promotion: service.promote?.price_promotion || 0,
        startTime: service.promote?.startTime
          ? moment(service.promote.startTime).format("YYYY-MM-DD")
          : "",
        endTime: service.promote?.endTime
          ? moment(service.promote.endTime).format("YYYY-MM-DD")
          : "",
      },
    });
  };

  const formik = useFormik<Partial<Service>>({
    initialValues: {
      promote: {
        isPromote: false,
        price_promotion: 0,
        startTime: "",
        endTime: "",
      },
    },
    validationSchema: servicePromotionSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("Form submitted");
      if (!selectedService) return;
      // Debugging output
      console.log("Selected Service:", selectedService);
      console.log("Form Values:", values);
      const updatedService = {
        ...selectedService,
        promote: {
          isPromote: true,
          price_promotion: Number(values.promote?.price_promotion),
          startTime: new Date(values.promote?.startTime as string),
          endTime: new Date(values.promote?.endTime as string),
        },
      };
      try {
        //   dispatch(
        //     addServicePromotion(
        //       updatedService as Service[]
        //     ) as unknown as AnyAction
        //   ).unwrap();
        //   console.log("Result:", updatedService);
        toast.success("Promotion created successfully!");
        //   setSelectedService(updatedService);
        // } catch (err) {
        //   console.error("Error:", err); // Debugging output
        //   toast.error(
        //     "An error occurred during edition, please try again later i don't"
        //   );
      } finally {
        setLoading(false);
        setTimeout(() => {
          if (submitBtnRef.current) {
            submitBtnRef.current.removeAttribute("disabled");
          }
        }, 2000);
        setSubmitting(false);
      }
    },
  });

  // useEffect(() => {
  //   handleSubmit();
  // }, [selectedService]);

  // useEffect(() => {
  //   if (selectedService) {
  //     formik.setValues(selectedService);
  //   }
  // }, [selectedService]);

  useEffect(() => {
    if (MemberNameData) {
      MemberService.getMemberNames().then((MemberNames) => {
        if (Array.isArray(MemberNames.data)) {
          setMemberName(MemberNames.data);
        }
      });
    }
  }, [MemberNameData]);

  return (
    <>
      <MainNav navType={"connectedNav"} isSearch={true} />
      <div className="service-promotion  ">
        <div className="popular-service align-items-start d-flex flex-column overflow-y-scroll ">
          <h5 className="text-break mb-4">Your popular services</h5>
          <div className="popular-service-container gap-3 d-flex  flex-column w-100">
            <div className="w-100  ">
              {selectedService ? (
                <div className="service-details w-100  gap-4">
                  <div className="w-100 d-flex gap-4">
                    <div>
                      <label htmlFor="name">Service Name</label>
                      <input
                        id="name"
                        className="form-control w-100 flex-grow-1  "
                        type="text"
                        value={selectedService.service_detail.name ?? ""}
                        disabled
                      />
                    </div>
                    <div>
                      <label htmlFor="time">Service Time</label>
                      <input
                        id="time"
                        className="form-control w-100 flex-grow-1  "
                        type="text"
                        value={formatTime(
                          selectedService?.duration as unknown as number
                        )}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="pt-3">
                    {selectedService.size ? (
                      selectedService.size.length > 1 ? (
                        <>
                          <label htmlFor="size">Service Size</label>
                          <span
                            aria-disabled
                            id="size"
                            className="form-control w-100"
                          >
                            {selectedService.size[0]}, {selectedService.size[1]}
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            aria-disabled
                            id="size"
                            className="form-control w-100"
                          >
                            {selectedService.size[0]}
                          </span>
                        </>
                      )
                    ) : null}
                  </div>
                  <div className="pt-3">
                    <label htmlFor="description">Description</label>
                    <input
                      id="description"
                      className="form-control w-100 text-start flex-none  "
                      style={{ height: "100px" }}
                      type="text"
                      value={selectedService.description ?? ""}
                      disabled
                    />
                  </div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="d-flex gap-2 pt-3">
                      <div>
                        <label htmlFor="price">initial Price</label>
                        <input
                          id="price"
                          className="form-control w-100  "
                          type="text"
                          value={formatUSPrice(selectedService.price) ?? ""}
                          disabled
                        />
                      </div>
                      <div>
                        <label htmlFor="price_promotion">New Price</label>
                        <input
                          id="price_promotion"
                          autoComplete="off"
                          className="form-control w-100"
                          type="number"
                          step={4}
                          min={1}
                          required
                          {...formik.getFieldProps("promote.price_promotion")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="promote.price_promotion"
                          placeholder="New Price"
                        />
                      </div>
                    </div>
                    <div className="d-flex gap-2 pt-3 ">
                      <div className="w-100">
                        <label htmlFor="promote.startTime">
                          Start Promotion
                        </label>
                        <input
                          id="promote.startTime"
                          className="form-control w-100"
                          type="date"
                          {...formik.getFieldProps("promote.startTime")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Enter a new Date"
                          name="promote.startTime"
                        />
                      </div>
                      <div className="w-100">
                        <label htmlFor="promote.endTime">End Promotion</label>
                        <input
                          id="promote.endTime"
                          className="form-control w-100"
                          type="date"
                          {...formik.getFieldProps("promote.endTime")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="promote.endTime"
                        />
                      </div>
                    </div>
                    {/* <div className="pt-3">
                    <span>Apply to all customers who have : </span>
                    <div
                      className="w-100ck m-3 d-flex justify-content-center align-items-center gap-4"
                      style={{ height: 80, border: "1px solid #333" }}
                    >
                      <div>
                        <label htmlFor="Fiend">Friend Family</label>
                        <input 
                        
                          type="checkbox"
                          name="Frind"
                          id="Friend"
                          checked={isCheck}
                          onClick={() => setIsCheck(!isCheck)}
                        />
                        {isCheck ? (
                          <p>Hello</p>
                        ) : // <CustomDropdown
                        //   items={
                        //     MemberNameData === null
                        //       ? []
                        //       : toCustomItem(MemberNameData as MemberType[])
                        //   }
                        //   keyExtractor={(item: Item) => item._id}
                        //   render={(item: Item) => item.value}
                        //   placeholder={"Select a Member"}
                        //   name={"Member"}
                        // />
                        null}
                      </div>
                      <div>
                        <label htmlFor="Fiend">Friend Family</label>
                        <input type="checkbox" name="Frind" id="Friend" />
                      </div>
                    </div>
                  </div> */}
                    <div className="d-flex">
                      <PromoCodeGenerator />
                    </div>

                    {/* <button
                      style={{ height: "40px" }}
                      className="btn btn-primary mt-4"
                      ref={submitBtnRef}
                      type="submit"
                      onClick={formik.handleSubmit}
                      disabled={selectedService === editedService}
                    >
                      Get promoted your profile
                    </button> */}
                    <Button
                      type="submit"
                      style={{
                        height: "40px",
                        alignSelf: "end",
                        marginBottom: "1rem",
                      }}
                      ref={submitBtnRef}
                      disabled={formik.isSubmitting}
                      mode={"primary"}
                      content={"Save"}
                      className="mt-2"
                    />
                  </form>
                </div>
              ) : (
                <p>No service selected</p>
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="your-service">
          <h5 className="mb-4">Your services</h5>
          {/* <button onClick={handleShow}>kjhkjh</button> */}

          {userServices?.length ? (
            <div>
              {/* <ServiceContainer services = {userServices} /> */}
              <ServicePromotionList
                services={userServices as any}
                scrollable={true}
                onseclectable={true}
                onSelect={handleSelectService}
              />
            </div>
          ) : (
            <NavLink to={"/service-menu"}>
              <span className="deeplink">Add service</span>
            </NavLink>
          )}
          <Icons />
        </div>
        <AddPromotion show={show} onClose={handleShow} />
        {selectedService && (
          <PromotionModal
            show={show}
            service={selectedService}
            onClose={handleShow}
            onSelect={handleSelectService}
          />
        )}
      </div>
    </>
  );
};

export default ServicePromotionPage;
