import React, { useState, useRef, useEffect } from "react";
import CustomDropdown, { Item } from "../reusable/CustomDropdown";
import SelectSize from "../SelectSize";
import Service from "../../models/Service";
import { useDispatch, useSelector } from "react-redux";
import { ServiceNameState } from "../../redux/slices/serviceNameSlice";
import {
  ServiceState,
  addService,
  addServiceDetail,
} from "../../redux/slices/serviceSlice";
import { AnyAction, AsyncThunkAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import {
  serviceSchema,
  initialServiceValues,
  initialServiceDetailValues,
} from "../validation/ServiceMenuValidation";
import { log } from "../../utils/utils";
import ModalLoader from "../loader/ModalLoader";

type AddServiceProps = {
  showAddService: boolean;
  setShowAddService: (value: any) => void;
};

const AddService: React.FC<AddServiceProps> = ({
  setShowAddService,
  showAddService,
}) => {
  const [isDetailName, setDetailName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceNameItem, setServiceNameItem] = useState("");
  const [serviceCategoryItem, setServiceCategoryItem] = useState("");
  const [newService, setNewService] =
    useState<Partial<Service>>(initialServiceValues);
  const [newServiceDetail, setNewServiceDetail] = useState<Partial<Service>>(
    initialServiceDetailValues
  );

  const serviceNameData = useSelector<unknown, ServiceNameState>(
    (state: any) => state.serviceNames
  ).serviceNames;

  const [serviceDetailsNames, setServiceDetailsNames] = useState<any[]>([]);

  const servicesErrors = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).errors;

  const submitBtnRef = useRef<any>();
  const dispatch = useDispatch();

  const formik = useFormik<Partial<Service>>({
    initialValues: initialServiceValues,
    validationSchema: serviceSchema,
    onSubmit: (values) => {
      log(JSON.stringify(values, null, 2));
      formik.resetForm();
    },
  });

  const filterServiceNames = (services: any[]) => {
    const serviceDetails = services.flatMap((service) => {
      const serviceDetails = service.service_detail;
      return serviceDetails;
    });
    let _services = serviceDetails.filter(
      (serviceDetail) => serviceDetail !== undefined
    );

    _services = _services.map((service) => {
      return { ...service, service_detail: service._id };
    });

    return _services;
  };

  const toCustomItem = (value: Service[]) => {
    return value.map((item) => {
      return {
        ...item,
        id: item._id as string,
        value: item.name,
      };
    });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setNewService({
      ...newService,
      [name]: value,
    });
  };

  const canContinue = (
    data: Partial<Service> | null,
    additionalData: Partial<Service> | null
  ): boolean => {
    console.log("the additionalData", additionalData);
    if (data !== null) {
      if (data.price && data.price <= 0) {
        toast.error("Price must be greater than zero");
        return false;
      }
      if (data.duration && data.duration <= 4) {
        toast.error("Duration must be at least 5 minutes");
        return false;
      }
      //   if(data.duration && (data.duration % 10 !== 0 && data.duration % 15 !== 0))
      //   {
      //    toast.error("Duration must be a multiple of 15 minutes or 10 minutes");
      //    return false;
      //  }
      let serviceAttrs = [data.price, data.description, data.size];

      if (!additionalData?.service_categorie && additionalData?.name) {
        toast.error("You need to select a service category");
        return false;
      }

      if (additionalData?.service_categorie && additionalData.name) {
        serviceAttrs.push(
          ...[additionalData.name, additionalData.service_categorie]
        );
      } else {
        serviceAttrs.push(...[data.service_categorie, data.service_detail]);
      }

      return serviceAttrs
        .map((attr) => {
          return attr !== undefined && attr.toString().trim() !== "";
        })
        .reduce((prev, next) => prev && next, true);
    }
    return false;
  };

  const handleSubmit = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setLoading(true);
    formik.handleSubmit();
    if (canContinue(newService, newServiceDetail)) {
      if (submitBtnRef.current) {
        submitBtnRef.current.setAttribute("disabled", "true");
        submitBtnRef.current.innerHTML = "loading...";
        // initialize the action to dispatch (add a new service or creating a new service and a new service detail )
        let actionToDispatch: AsyncThunkAction<
          Partial<Service>,
          Partial<Service>,
          any
        >;
        if (newServiceDetail.name && newServiceDetail.service_categorie) {
          actionToDispatch = addServiceDetail({
            ...newService,
            name: newServiceDetail.name,
            service_categorie: newServiceDetail.service_categorie,
          });
        } else {
          actionToDispatch = addService(newService);
        }

        dispatch(actionToDispatch as unknown as AnyAction)
          .unwrap()
          .then(() => {
            toast.success("Service created successfully!!");
            window.location.reload();
           
          })
          .catch((err: any) => {
            const errors = err.errors;
            const errorMessages = Object.values(errors).map((error: any) => (
              <div>{error}</div>
            ));

            return toast.error(<div>{errorMessages}</div>, {
              duration: 7000,
            });
          })
          .finally((res: any) => {
            if (submitBtnRef.current) {
              submitBtnRef.current.removeAttribute("disabled");
              submitBtnRef.current.innerHTML = `
              <span class="text">Validate</span>
              <span>Add Service</span>
         `;
            }
            setLoading(false);
            setNewService(initialServiceValues);
            setServiceCategoryItem("");
            setServiceNameItem("");
            setNewServiceDetail(initialServiceDetailValues);
          });
      }
    } else {
      if (!servicesErrors)
        toast.error(
          "You must fill all the fields correctly to create a new service"
        );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (serviceNameData) {
      const detailsNames: any[] = [];
      serviceNameData.forEach((name: any) => {
        if (name?.servicesDetails) {
          detailsNames.push(...name.servicesDetails);
        }
      });
      setServiceDetailsNames(detailsNames);
    }
  }, [serviceNameData]);

  return (
    <div
      className={`showAddService px-0 d-flex flex-column gap-3 ${
        showAddService ? " display" : ""
      }`}
    >
      {loading && <ModalLoader />}
      <div className="row gap-1">
        <div className="col-sm">
          <CustomDropdown
            onChange={(item) => {
              setDetailName(typeof item === "string");
              if (typeof item === "string") {
                setNewServiceDetail({
                  ...newServiceDetail,
                  name: item,
                });
              } else {
                setNewService({
                  ...newService,
                  name: item.name,
                  service_detail: item._id,
                  service_categorie: item.service_categorie,
                });
                setNewServiceDetail({});
              }
            }}
            items={
              serviceNameData === null
                ? []
                : toCustomItem(serviceDetailsNames as Service[])
            }
            keyExtractor={(item: Item) => item.id}
            render={(item: Item) => item.name}
            placeholder={"Select or type your service Name"}
            name={"name"}
            searchItem={serviceNameItem}
            setSearchItem={setServiceNameItem}
          />
        </div>
        {isDetailName && (
          <div className="col-sm">
            <CustomDropdown
              onChange={(item) => {
                console.log(typeof item);
                if (typeof item !== "string") {
                  setNewServiceDetail({
                    ...newServiceDetail,
                    service_categorie: item._id,
                  });
                }
              }}
              items={
                serviceNameData === null
                  ? []
                  : toCustomItem(serviceNameData as Service[])
              }
              keyExtractor={(item: Item) => item.id}
              render={(item: Item) => item.name}
              placeholder={"Select or type your Category"}
              name={"category"}
              searchItem={serviceCategoryItem}
              setSearchItem={setServiceCategoryItem}
            />
          </div>
        )}
        <div className="col-sm">
          <SelectSize
            onChange={(sizes: string[]) => {
              console.log("size after selection ", sizes);
              setNewService({
                ...newService,
                size: sizes,
              });
            }}
            name={"size"}
            onBlur={console.log}
            selectedSizes={newService.size as string[]}
          />
        </div>
        <div className="col-sm input-group">
          <input
            autoComplete="off"
            placeholder="Price"
            className="form-control h-100"
            type="number"
            name="price"
            id="price"
            step={5}
            min={1}
            required
            onChange={handleChange}
            value={newService.price === 0 ? "" : newService.price}
          />
          <span className="input-group-text">$</span>
        </div>
        <div className="col-sm input-group">
          <input
            autoComplete="off"
            placeholder="Duration"
            className="form-control h-100"
            type="number"
            name="duration"
            id="duration"
            min={5}
            onChange={handleChange}
            value={newService.duration === 0 ? "" : newService.duration}
          />
          <span className="input-group-text">mins</span>
        </div>
      </div>
      <div className="row row-cols-auto align-items-center">
        <div className="col">
          <textarea
            placeholder="Add a description"
            name="description"
            id="description"
            className="border rounded p-2"
            onChange={handleChange}
            cols={30}
            maxLength={150}
            value={newService.description}
          ></textarea>
        </div>
        <div className="col d-flex gap-3 ">
          <button
            ref={submitBtnRef}
            onClick={handleSubmit}
            className="button-57 p-2 rounded px-4 "
          >
            <span className="text ">Validate</span>
            <span>Add Service</span>
          </button>
          <button
            onClick={() => {
              setShowAddService(false);
              setNewService(initialServiceValues);
            }}
            className="button-57 p-2 rounded px-4 "
          >
            <span className="text ">Close</span>
            <span>Discard Changes</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddService;
