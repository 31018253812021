import React, { useEffect } from "react";
import { RegisterValues } from "../../validation/RegisterValidation";
import InputBoolean from "../../input/InputBoolean";
import Button from "../../Button";
import { getServiceNames } from "../../../redux/slices/serviceNameSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

interface RegisterProStep4Props {
  formValues: Partial<RegisterValues>;
  setFormValues: (values: Partial<RegisterValues>) => void;
  submitBtnRef: React.RefObject<any>;
  handleSubmit: (e: any) => void;
  onPrevStep?: () => void;
}

const RegisterProStep4: React.FC<RegisterProStep4Props> = ({
  formValues,
  setFormValues,
  submitBtnRef,
  handleSubmit,
  onPrevStep,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServiceNames("") as unknown as AnyAction).then((res: any) => {
      console.log("service data", res);
    });
  }, []);

  return (
    <div className="w-100 d-flex flex-column gap-3 ">
      <h4 className="fw-500 text-black">Select your services</h4>
      <div className="d-flex gap-4 w-100">
        <label className="list-group-item">
          <input
            className="form-check-input me-1"
            type="checkbox"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues({
                ...formValues,
                blow_dry: e.target.checked,
              });
            }}
          />
          Blowdry
        </label>
        <label className="list-group-item">
          <input
            className="form-check-input me-1"
            type="checkbox"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues({
                ...formValues,
                hair_wash: e.target.checked,
              });
            }}
          />
          Hairwash
        </label>
        <label className="list-group-item">
          <input
            className="form-check-input me-1"
            type="checkbox"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormValues({
                ...formValues,
                undo_hair: e.target.checked,
              });
            }}
          />
          Undo hair
        </label>
      </div>
      <div className="d-flex flex-column gap-4 w-100">
        <InputBoolean
          onChange={(val: any) =>
            setFormValues({
              ...formValues,
              accept_custom_orders: val,
            })
          }
          value={formValues.accept_custom_orders as boolean}
          title={
            <div className="text-primary d-inline-flex">
              <h5 className="fw-500 text-black">
                Do you accept custom orders?
              </h5>
              <i className="fa fa-info-circle text-primary ms-1"></i>
            </div>
          }
          name="accept_custom_orders"
        />

        <InputBoolean
          onChange={(val) =>
            setFormValues({
              ...formValues,
              kids_friendly: val as boolean,
            })
          }
          value={formValues.kids_friendly}
          title={
            <div className="text-primary d-inline-flex">
              <h5 className="fw-500 text-black">Kids friendly?</h5>
              <i className="fa fa-info-circle text-primary ms-1"></i>
            </div>
          }
          name="kids_age_below_4"
        />

        <InputBoolean
          onChange={(val) =>
            setFormValues({
              ...formValues,
              kids_service: val as boolean,
            })
          }
          value={formValues.kids_service}
          title={
            <div className="text-primary d-inline-flex">
              <h5 className="fw-500 text-black">Kids service?</h5>
              <i className="fa fa-info-circle text-primary ms-1"></i>
            </div>
          }
          name="kids_services"
        />

        <InputBoolean
          onChange={(val) =>
            setFormValues({
              ...formValues,
              free_parking_for_client: val as boolean,
            })
          }
          value={formValues.free_parking_for_client}
          title={
            <div className="text-primary d-inline-flex">
              <h5 className="fw-500 text-black">
                do you have free parking spots for clients ?
              </h5>
              {/* <i className="fa fa-info-circle text-primary ms-1"></i> */}
            </div>
          }
          name="kids_services"
        />
        <InputBoolean
          onChange={(val) =>
            setFormValues({
              ...formValues,
              drive_to_client: val as boolean,
            })
          }
          value={formValues.drive_to_client}
          title={
            <div className="text-primary d-inline-flex">
              <h5 className="fw-500 text-black">
                {" "}
                Do you drive to client ?
              </h5>
              {/* <i className="fa fa-info-circle text-primary ms-1"></i> */}
            </div>
          }
          name="kids_services"
        />
      </div>
      <div className="d-flex justify-content-around mt-5 align-items-center w-100 ">
        <Button
          onClick={() => {
            onPrevStep && onPrevStep();
          }}
          mode={"primary"}
          style={{ width: "90px" }}
          content={
            <span className="mx-0" style={{ fontSize: ".8em" }}>
              Back
            </span>
          }
        />
        <Button
          disabled={formValues.typeOfAccount === null}
          ref={submitBtnRef}
          onClick={handleSubmit}
          mode="primary"
          style={{ width: "90px" }}
          content={
            <span className="mx-0" style={{ fontSize: ".8em" }}>
              Let's go
            </span>
          }
        />
      </div>
    </div>
  );
};

export default RegisterProStep4;
