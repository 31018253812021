import React, { useState } from "react"; // Replace this with the actual button component you are using
import Button from "../Button";
import { IService } from "../../models/Service";
import { formatUSPrice, formatTime } from "../../utils/utils";
import { ServiceListSkeleton } from "../../skeletons/ServiceListSkeleton";
import Countdown from "../promotion/CountDown";
import DateDifferenceCountdown from "../promotion/DifferenceCountDown";
import { toDate } from "date-fns";

interface ServiceListProps {
  services?: IService[] | null;
  searchable?: boolean;
  scrollable?: boolean;
  onSelect?: (service: IService) => void;
  onSelected?: (service: IService) => void;
}

const ServiceList: React.FC<ServiceListProps> = ({
  services,
  onSelect,
  onSelected,
  searchable = true,
  scrollable = true,
}) => {
  const [q, setQ] = useState<string | null>(null);
  const [showPromotion, setShowPromotion] = useState<boolean>(false);
  const targetDate = new Date();
  targetDate.setDate(targetDate.getDate() + 2);

  function differenceInMinutes(date1: Date, date2: Date) {
    const timeDifference = date2.getTime() - date1.getTime();
    const dayDifference = timeDifference / (1000 * 3600 * 24);
    const result = new Date(date1);
    result.setDate(result.getDate() + dayDifference);
    return result;
  }

  const handleGetPercentage = (val1: number, val2: number) => {
    let resultStart = (val2 * 100) / val1;
    let result = 100 - resultStart;
    return (
      <span className="text-primary ps-2" style={{ fontSize: "1rem" }}>
        {Math.round(result)}% off
      </span>
    );
  };

  const filteredServices =
    services &&
    services?.filter((service) =>
      q === null
        ? true
        : service.service_detail?.name.toLowerCase().includes(q.toLowerCase())
    );
  const hightlightServiceName = (name: string, search: string) => {
    const parts = name.split(new RegExp(`(${search})`, "gi"));
    return parts.map((part, index) => {
      if (part.toLowerCase() === search.toLowerCase()) {
        return (
          <span key={index} className="text-white bg-primary">
            {part}
          </span>
        );
      } else {
        return part;
      }
    });
  };

  const handleShowPromotion = () => {
    setShowPromotion(!showPromotion);
    const servicesPromotion = services?.find((service) => {
      if (
        service.promote?.isPromote === true &&
        service.promote?.endTime > new Date()
      ) {
        return service;
      }
    });
  };

  return (
    <>
      {searchable && (
        <div className="d-flex justify-content-between p-0 m-0 align-items-center professional_services">
          <h5>Services provided</h5>
          <div
            className="input-group d-flex m-0  mb-2 align-items-center w-50"
            aria-autocomplete="none"
          >
            <button
              style={{
                backgroundColor: "white",
                height: "45px",
                zIndex: "1",
              }}
              className="btn border-0"
              type="button"
            >
              <i className="fa-solid fa-search"></i>
            </button>
            <input
              value={q ?? ""}
              onChange={(e) => setQ(e.target.value)}
              placeholder="Search for service..."
              type="text"
              className="form-control border-0  no-decoration fw-500"
            />
          </div>
        </div>
      )}

      <div
        className={`d-flex flex-column mt-2 see-pro-service-list ${
          scrollable && "scrollable"
        }`}
      >
        {!services && <ServiceListSkeleton />}

        {filteredServices && (
          <>
            {filteredServices.length > 0 ? (
              filteredServices.map((service) => (
                <div key={service._id} className="d-flex flex-column w-100">
                  <div className="d-flex justify-content-between professional_service align-items-center w-100">
                    <div className="d-flex flex-column">
                      <h5 className="text-black">
                        {hightlightServiceName(
                          service.service_detail?.name,
                          q ?? ""
                        )}
                        {service.promote?.isPromote === true &&
                          handleGetPercentage(
                            service.price,
                            service.promote.price_promotion
                          )}
                      </h5>
                      <div className="professional_service__description">
                        {service.description}
                      </div>
                      {service.promote?.isPromote ||
                        (service.promote?.isPromote === false && (
                          <>
                            <span className="text-muted ">
                              {formatUSPrice(service.price)} for{" "}
                              {formatTime(
                                service.duration as unknown as number
                              )}
                            </span>
                          </>
                        ))}
                      {service.promote?.isPromote === true && (
                        <>
                          <div className="d-flex gap-2">
                            <span className=" text-primary">
                              {formatUSPrice(service.promote.price_promotion)}{" "}
                              for{" "}
                              {formatTime(
                                service.duration as unknown as number
                              )}
                            </span>

                            <span>
                              <DateDifferenceCountdown
                                date1={
                                  new Date(
                                    service.promote
                                      .startTime as unknown as string
                                  )
                                }
                                date2={
                                  new Date(
                                    service.promote.endTime as unknown as string
                                  )
                                }
                              />
                            </span>
                          </div>
                        </>
                      )}

                      <>
                        {service.promote?.isPromote === true && (
                          <span className="text-muted text-decoration-line-through ">
                            {formatUSPrice(service.price)} for{" "}
                            {formatTime(service.duration as unknown as number)}
                          </span>
                        )}
                      </>
                    </div>
                    {onSelect && (
                      <div>
                        <Button
                          onClick={(e) => onSelect(service)}
                          className="br-6"
                          type={"button"}
                          content={
                            <span className="mx-3 text-white">Book</span>
                          }
                        />
                      </div>
                    )}
                    {onSelected && (
                      <div>
                        <Button
                          onClick={() => onSelected(service)}
                          className="br-6"
                          type="button"
                          content={<span className="mx-3">Add promotion</span>}
                        />
                      </div>
                    )}
                  </div>
                  <hr className="w-100" />
                </div>
              ))
            ) : (
              <div
                style={{ minHeight: "50vh" }}
                className="w-100 d-flex justify-content-center align-items-center small text-muted flex-column fs-6"
              >
                <i className="fas fa-search-minus fa-3x mb-2"></i>
                <h4>Oops! No Results Found</h4>
                <span className="text-center">
                  We couldn't find anything based on your search.
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ServiceList;
