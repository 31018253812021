import * as Yup from "yup";
import Member from "../../models/Member";

export interface MemberType {
  relation: string;
  userName: string;
  email: string;
}

export const FamilyFriendInitialValues: Partial<Member> = {
  relation: "",
  userName: "",
  email: "",
};

export const FamilyFriendValidationSchema = Yup.object({
  relation: Yup.string().required("Required"),
  userName: Yup.string().required("Required"),
  email: Yup.string().required("Required").email(),
});
