import React, { useEffect, useState } from "react";
import InputBoolean from "../../input/InputBoolean";
import MapSelection from "../../map/MapSelection";
import { TSalonAccountType } from "../../../utils/types";
import { useUser } from "../../../hooks/UseUser";
import { isUserProfessional } from "../../../models/User";
import SelectPercentageHelp from "../../SelectPercentageHelp";

export type AdditionalInfoStepProps = {
  driveToYourPlace?: boolean;
  travelingExpenses?: number;
  freeParkingSpot?: boolean;
  helpPercentage?: string;
  specialEvent?: boolean;
  additionalExpense?: number;
  accountType?: TSalonAccountType;
  location?: {
    latLng: { lat: number; lng: number };
    address: string;
  } | null;
};

type AdditionalInfoStepPropsChange = {
  onDataChange: (data: AdditionalInfoStepProps) => void;
};

export const AdditionalInfoStep: React.FC<
  AdditionalInfoStepProps &
    AdditionalInfoStepPropsChange & {
      default?: null | AdditionalInfoStepProps;
    }
> = ({ ...props }) => {
  const [data, setData] = useState<AdditionalInfoStepProps>({
    ...props,
  });

  const handleChange = (values: AdditionalInfoStepProps) => {
    setData(values);
    console.log("here is my value", values);
    props.onDataChange(values);
  };
  const user = useUser();

  const userIsProfessional = isUserProfessional(user);
  //   useEffect(()=>{

  //   })

  return (
    <div className="mt-4 d-flex flex-column gap-4">
      <div className="">
        <div className="d-flex justify-content-between">
          {data.accountType !== "business_owner" && (
            <div>
              <InputBoolean
                title={
                  <div className="text-primary d-inline-flex fw-500">
                    Do you want the service provider to drive to you ? *{" "}
                    <i className="fa fa-info-circle"></i>
                  </div>
                }
                name="drive_to_your_place"
                value={data.driveToYourPlace as boolean}
                onChange={(value: boolean | null) => {
                  handleChange({
                    ...data,
                    driveToYourPlace:
                      (userIsProfessional ? true : value) ?? false,
                  });
                }}
                userIsProfessional={userIsProfessional}
              />
              {userIsProfessional && (
                <>
                  {data.driveToYourPlace && (
                    <div className={"d-flex flex-column col-lg-6 col-12"}>
                      <label
                        htmlFor="basic-url"
                        className="form-label fw-500 text-primary"
                      >
                        traveling expenses
                      </label>
                      <div className="input-group mb-3 w-100">
                        <span className="input-group-text" id="basic-addon3">
                          $
                        </span>
                        <input
                          type="number"
                          min={50}
                          className="form-control "
                          // defaultValue={data.additionalExpense}
                          onChange={(e) => {
                            handleChange({
                              ...data,
                              travelingExpenses: Number(e.target.value),
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          <InputBoolean
            title={
              <div className="text-primary d-inline-flex fw-500">
                Free parking spot ? * <i className="fa fa-info-circle"></i>
              </div>
            }
            name="parking"
            value={data.freeParkingSpot}
            onChange={(value: boolean | null) => {
              handleChange({ ...data, freeParkingSpot: value ?? false });
            }}
          />
        </div>
        {data.accountType !== "business_owner" && data.driveToYourPlace && (
          <div className="mt-2 w-100">
            <MapSelection
              showRadius={false}
              containerStyle={{ height: "30vh" }}
              onLocationSelect={(latLng, address) => {
                handleChange({ ...data, location: { latLng, address } });
              }}
              radius={"1"}
            />
          </div>
        )}
      </div>

      <div className="d-flex justify-content-between align-items-start">
        <InputBoolean
          title={
            <div className="text-primary d-inline-flex fw-500">
              Special event ? * <i className="fa fa-info-circle"></i>
            </div>
          }
          name="special_event"
          // value={data.specialEvent}
          onChange={(value: boolean | null) => {
            handleChange({ ...data, specialEvent: value ?? false });
          }}
        />
        {data.specialEvent && (
          <div className={"d-flex flex-column col-lg-6 col-12"}>
            <label
              htmlFor="basic-url"
              className="form-label fw-500 text-primary"
            >
              Additional expenses
            </label>
            <div className="input-group mb-3 w-100">
              <span className="input-group-text" id="basic-addon3">
                $
              </span>
              <input
                type="number"
                min={1}
                className="form-control "
                // defaultValue={data.additionalExpense}
                onChange={(e) => {
                  handleChange({
                    ...data,
                    additionalExpense: Number(e.target.value),
                  });
                }}
              />
            </div>
          </div>
        )}
      </div>
      {userIsProfessional && (
        <div className="mb-3">
          <label htmlFor="serviceSize" className="form-label">
            percentage of help
          </label>
          <SelectPercentageHelp
            onChange={(helpPercentage: string) => {
              handleChange({ ...data, helpPercentage: helpPercentage });
            }}
            onBlur={console.log}
            selectedPercentage={data.helpPercentage!}
          />
        </div>
      )}
    </div>
  );
};
