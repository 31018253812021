import React from "react";
import { Tooltip } from "react-tooltip";
import "./../styles/Navbar.css";
import SearchBar from "./searchBar/SearchBar";
import NavigationLink from "./navigation/NavigationLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  navigationLinks2,
  navigationLinks4,
  navigationLinks5,
  restrictUserRoutes,
} from "./navigation";
import NavbarConnected from "./navigation/NavbarHome";
import NavbarSearch from "./navigation/NavbarSearch";
import NavbarToggleButton from "./navigation/NavbarToggleButton";
import { Toaster } from "react-hot-toast";
import { useUser } from "../hooks/UseUser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { isUserProfessional } from "../models/User";


interface NavbarProps {
  connected?: boolean;
  search?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({
  connected = false,
  search = false,
}: NavbarProps) => {
  const logo = (
    <img
      src={require("../assets/images/latrice.png")}
      className="img-fluid"
      width="80"
      height="80"
      alt="Latrice"
    />
  );
  const user = useUser();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark">
      <div className="container p-lg-0">
        <NavigationLink to={"/"} label={logo} className="navbar-brand" />
        <NavbarToggleButton />
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          {!connected ? (
            <NavbarConnected connected />
          ) : (
            <div className="d-flex flex-row align-items-center w-100 justify-content-center gap-6">
              <div className="d-flex flex-column">
                {!search && (
                  <div className="navbar-nav ml-md-auto justify-content-center">
                    {navigationLinks2.map((link, index) => (
                      <NavigationLink
                        key={index}
                        className="nav-link text-white mx-lg-3"
                        to={link.to}
                        label={link.label}
                      />
                    ))}
                  </div>
                )}
                <hr
                  className="m-0 ms-2"
                  style={{ height: "1px", backgroundColor: "green" }}
                />

                {/* SearchBar */}

                {search ? (
                  <div>
                    <SearchBar location="navBar" />
                  </div>
                ) : (
                  <NavbarSearch />
                )}

                {search && (
                  <>
                    <hr
                      className="m-0 ms-2 text-white"
                      style={{ height: "1px" }}
                    />
                    <div className="navbar-nav ml-md-auto d-flex justify-content-between">
                      {restrictUserRoutes(
                        navigationLinks4,
                        isUserProfessional(user)
                      ).map((link, index) => (
                        <NavigationLink
                          key={index}
                          className={`nav-link text-white${
                            index === 0 ? "" : " mx-lg-3"
                          }`}
                          to={link.to}
                          label={link.label}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>

              {/* Notification elements */}

              <div className="d-flex px-3 gap-4">
                {restrictUserRoutes(
                  navigationLinks5,
                  isUserProfessional(user)
                ).map((link, index) => (
                  <NavigationLink
                    key={index}
                    className={link.className as string}
                    to={link.to}
                    label={
                      <FontAwesomeIcon
                        icon={link.icon as IconProp}
                        className="nav-link-icon nav-link-icon-badge"
                        data-count={link.count}
                      />
                    }
                  />
                ))}
                <NavbarConnected withLinks={false} />
              </div>
            </div>
          )}
        </div>
      </div>
      <Tooltip />
      <Toaster position="bottom-right" />
    </nav>
  );
};

export default Navbar;
