import moment, { Moment } from "moment";
import Service, { IBookingService } from "../../../models/Service";
import { TimeSlot } from "../../../models/TimeSlot";
import { TimeOfDay } from "../../reusable/Time";

export const getSlotInterval = (service: IBookingService, searchPosition: number, daySlots: Omit<TimeSlot, "date">[]): {
  time: { start: moment.Moment | null, end: moment.Moment | null },
  position: { start: number, end: number }
} => {

  const duration = service.duration as unknown as number;
  let time: { start: moment.Moment | null, end: moment.Moment | null } = { start: null, end: null };
  let position = { start: 0, end: 0 };

  for (let i = searchPosition; i < daySlots.length; i++) {
    const slot = daySlots[i];

    if (slot.isAvailable) {

      if (time.start === null) {
        position.start = i;
        time.start = moment(slot.time, 'HH:mm A');
      } else {
        position.end = i;
        time.end = moment(slot.time, 'HH:mm A');
      }

      if (time.end !== null && time.start !== null && time.end.diff(time.start, 'minutes') >= duration) {
        break;
      }

    } else {
      break;
    }
  }
  if (time.end === null && time.start === null && searchPosition < daySlots.length) return getSlotInterval(service, searchPosition + 1, daySlots);

  return { time, position };

}


const isPossibleStartTime = (duration: number, slot: moment.Moment, slots: moment.Moment[]): boolean => {
  let time = 0;
  let lastSlot = slot;

  for (const item of slots) {
    const diffInMinutes = item.diff(lastSlot, 'minutes');

    if (diffInMinutes === 15) {
      time += 15;
      lastSlot = item;

      if (time === duration) {
        return true;
      }
    } else {
      return false; // If any slot doesn't have a 15-minute difference, it's not possible
    }
  }

  return time === duration; // Ensure that the total time matches the required duration
};


// Get possible start time based on total services duration
// And current time slots
export const getPossibleStartTime = (duration: number, slots: moment.Moment[]): moment.Moment[] => {
  const possibleStartTimes: moment.Moment[] = [];

  for (let i = 0; i < slots.length; i++) {
    if (isPossibleStartTime(duration, slots[i], slots.slice(i + 1))) {
      possibleStartTimes.push(slots[i]);
    }
  }
  return possibleStartTimes;
}

export function filterByTimeOfDay(timeOfDay: TimeOfDay, times: Moment[]): Moment[] {
  switch (timeOfDay) {
    case 'morning':
      return times.filter((time) => {
        return time.hour() < 12;
      });

    case 'afternoon':
      return times.filter((time) => {
        const hours = time.hour();
        return hours >= 12 && hours < 18;
      });

    case 'evening':
      return times.filter((time) => {
        const hours = time.hour();
        return hours > 18;
      });

    default:
      return times;
  }
}


