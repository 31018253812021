import React, { useEffect, useState } from "react";
import "../styles/WorkingTable.css";
import WorkingTableLoader from "./loader/WorkingTableLoader";
import WorkingTableElement, {
  TWorkingTableElement,
} from "./WorkingTableElement";
import moment from "moment";
import { AddShiftModal, ShiftTimeInterval } from "./modal/AddShiftModal";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { AnyAction } from "@reduxjs/toolkit";
import {
  addAvailability,
  addBulkAvailability,
  removeAvailability,
} from "../../redux/slices/availabilitySlice";
import { USATimezone } from "../../utils/types";
import { IAvailability } from "../../models/Availability";
import { EditShiftModal } from "./modal/EditShiftModal";
import { formatTime } from "../../utils/utils";
import {
  addBulkUnAvailability,
  addUnAvailability,
  removeUnAvailability,
} from "../../redux/slices/unAvailabilitySlice";
import { DeleteShiftModal } from "./modal/DeleteShiftModal";
import NavigationLink from "../navigation/NavigationLink";
import { useSalon } from "../../context/SalonContext";
import { useUser } from "../../hooks/UseUser";
import { isUserBusinessOwner, isUserProfessional } from "../../models/User";

export type WorkingTableProps = {
  loading?: boolean;
  weekDates: moment.Moment[];
  elements: TWorkingTableElement[];
  className?: string;
  timezone: USATimezone;
};

export type TElementType = "shift" | "timeOff";

export type TElementEditable = {
  element: TWorkingTableElement;
  source: IAvailability; // time off or shift
  type: TElementType;
};

export const WorkingTable: React.FC<WorkingTableProps> = ({
  elements,
  weekDates,
  loading,
  className,
  ...props
}) => {
  const [totalWeekDayHours, setTotalWeekDayHours] = useState(Array(7).fill(0));

  const [toAdd, setToAdd] = useState<{
    element: TWorkingTableElement;
    day: moment.Moment;
    type: TElementType;
  } | null>(null);

  const [toEdit, setToEdit] = useState<TElementEditable | null>(null);
  const [toDelete, setToDelete] = useState<TElementEditable | null>(null);

  const user = useUser();
  const salon = useSalon();

  const dispatch = useDispatch();

  const handleAddShift = (
    value: ShiftTimeInterval,
    timezone: USATimezone,
    type: TElementType
  ) => {
    if (toAdd) {
      const edited: any = {};
      edited[toAdd.day.format("YYYY-MM-DD")] = {
        start: value.startTime,
        end: value.endTime,
      };

      const changes: any = {
        edited,
        removed: {},
        timezone: timezone,
        chairs_ids: [toAdd.element._id],
      };

      if (type === "timeOff") {
        return dispatch(
          addUnAvailability(changes) as unknown as AnyAction
        ).unwrap();
      }

      return dispatch(
        addAvailability(changes) as unknown as AnyAction
      ).unwrap();
    }
  };

  const handleBulkAddShift = (
    value: ShiftTimeInterval,
    timezone: USATimezone,
    type: TElementType,
    repeatUntil: moment.Moment[]
  ) => {
    if (toAdd) {
      const edited: any = {};
      edited[toAdd.day.format("YYYY:MM:DD")] = {
        start: value.startTime,
        end: value.endTime,
      };

      const changes: any = {
        edited,
        removed: {},
        timezone: timezone,
        chairs_ids: [toAdd.element._id],
        repeatUntil,
      };

      if (type === "timeOff") {
        return dispatch(
          addBulkUnAvailability(changes) as unknown as AnyAction
        ).unwrap();
      }

      return dispatch(
        addBulkAvailability(changes) as unknown as AnyAction
      ).unwrap();
    }
  };

  const handleEditShift = (
    value: ShiftTimeInterval,
    timezone: USATimezone,
    type: TElementType
  ) => {
    console.log("all data i edited", value, timezone, type);
    if (toEdit) {
      const edited: any = {};
      edited[toEdit.source.day] = {
        start: value.startTime,
        end: value.endTime,
      };

      const changes: any = {
        edited,
        removed: {},
        timezone: timezone,
        chairs_ids: [toEdit.element._id],
      };

      if (type === "timeOff") {
        return dispatch(
          addUnAvailability(changes) as unknown as AnyAction
        ).unwrap();
      }

      return dispatch(
        addAvailability(changes) as unknown as AnyAction
      ).unwrap();
    }
  };

  const handleDeleteShift = (
    value: ShiftTimeInterval,
    timezone: USATimezone,
    type: TElementType
  ) => {
    if (toDelete) {
      const removed: any = {};
      removed[toDelete.source.day] = {
        start: value.startTime,
        end: value.endTime,
      };

      const changes: any = {
        edited: {},
        removed,
        timezone: timezone,
        chairs_ids: [toDelete.element._id],
      };

      if (type === "timeOff") {
        return dispatch(
          addUnAvailability(changes) as unknown as AnyAction
        ).unwrap();
      }

      return dispatch(
        addAvailability(changes) as unknown as AnyAction
      ).unwrap();
    }
  };

  const handleOpenAddShiftModal = (
    element: TWorkingTableElement,
    day: moment.Moment,
    type: TElementType
  ) => {
    setToAdd(null);
    setTimeout(() => setToAdd({ element, day, type }), 1);
  };

  const handleOpenEditShiftModal = (
    shift: IAvailability,
    element: TWorkingTableElement,
    type: TElementType
  ) => {
    console.log("data ", shift, element, type);

    setToEdit({ source: shift, element, type });
  };

  const handleOpenDeleteShiftModal = (
    shift: IAvailability,
    element: TWorkingTableElement,
    type: TElementType
  ) => {
    setToDelete({ source: shift, element, type });
  };

  useEffect(() => {
    if (Array.isArray(elements)) {
      const dayHours = totalWeekDayHours.map((_, key) => {
        return elements.reduce((prev, curr) => {
          const availability = curr.shifts[key];

          let time = 0;
          const timeOff = curr.timeOffs[key];

          if (availability) {
            const [shiftStartTime, shiftEndTime] = [
              moment(availability.startTime, "h:mm A"),
              moment(availability.endTime, "h:mm A"),
            ];

            time = shiftEndTime.diff(shiftStartTime, "minutes");

            if (timeOff) {
              const [timeOffStartTime, timeOffEndTime] = [
                moment(timeOff.startTime, "h:mm A"),
                moment(timeOff.endTime, "h:mm A"),
              ];
              time -= timeOffEndTime.diff(timeOffStartTime, "minutes");
            }

            time = time < 0 ? 0 : time;
            return prev + time / 60;
          }
          return prev;
        }, 0);
      });

      setTotalWeekDayHours(dayHours);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  useEffect(() => {
    console.log("date of week", weekDates);
    console.log("all the elements", elements);
  }, [elements]);

  return (
    <>
      {loading || !salon.salon ? (
        <WorkingTableLoader />
      ) : (
        <div className={`working-table w-100 ${className ?? ""}`}>
          <div className="working-table__header">
            <div className="d-flex gap-2">
              {isUserProfessional(user) && (
                <>
                  <span className="fw-500 text-black">Salon's chair</span>
                  <span className="fw-500 text-primary cursor-pointer">
                    <NavigationLink
                      to={`/setup-business/manage-chairs/${salon.salon._id}`}
                      label={"Manage"}
                      className="text-primary"
                    />
                  </span>
                </>
              )}
            </div>
            {weekDates.map((date, key) => (
              <div
                className="d-flex flex-column align-items-center justify-content-center fw-500 text-black"
                key={uuidv4()}
              >
                <span>{date.format("ddd, DD MMM")}</span>
                <small
                  style={{ fontWeight: "normal" }}
                  className="text-muted fw-normal"
                >
                  {formatTime(totalWeekDayHours[key] * 60)}
                </small>
              </div>
            ))}
          </div>
          <div className="working-table__body">
            {elements?.map((element, key) => (
              <WorkingTableElement
                key={uuidv4()}
                element={element}
                dateOfWeek={weekDates}
                onAddShift={(element, dateKey) =>
                  handleOpenAddShiftModal(element, weekDates[dateKey], "shift")
                }
                onAddTimeOff={(element, dateKey) =>
                  handleOpenAddShiftModal(
                    element,
                    weekDates[dateKey],
                    "timeOff"
                  )
                }
                onEditShift={(shift) =>
                  handleOpenEditShiftModal(shift, element, "shift")
                }
                onEditTimeOff={(timeOff) =>
                  handleOpenEditShiftModal(timeOff, element, "timeOff")
                }
                onDeleteShift={(shift) =>
                  handleOpenDeleteShiftModal(shift, element, "shift")
                }
                onDeleteTimeOff={(timeOff) =>
                  handleOpenDeleteShiftModal(timeOff, element, "timeOff")
                }
              />
            ))}
          </div>
        </div>
      )}

      {toAdd && (
        <AddShiftModal
          timezone={props.timezone}
          shift={toAdd}
          onHide={() => setToAdd(null)}
          onSave={(value: ShiftTimeInterval, timezone: USATimezone) =>
            handleAddShift(value, timezone, toAdd.type)
          }
          onSaveBulk={(
            value: ShiftTimeInterval,
            timezone: USATimezone,
            repeatUntil: moment.Moment[]
          ) => handleBulkAddShift(value, timezone, toAdd.type, repeatUntil)}
          elements={elements}
          type={toAdd.type}
        />
      )}

      {toEdit && (
        <EditShiftModal
          timezone={props.timezone}
          data={toEdit}
          onHide={() => setToEdit(null)}
          onSave={(value: ShiftTimeInterval, timezone: USATimezone) =>
            handleEditShift(value, timezone, toEdit.type)
          }
          type={toEdit.type}
        />
      )}

      {toDelete && (
        <DeleteShiftModal
          timezone={props.timezone}
          data={toDelete}
          onHide={() => setToDelete(null)}
          onDelete={(value: ShiftTimeInterval, timezone: USATimezone) =>
            handleDeleteShift(value, timezone, toDelete.type)
          }
          type={toDelete.type}
        />
      )}
    </>
  );
};
