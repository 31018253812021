import { useEffect, useRef, useState } from "react";
import "../styles/ServicePromotionPage.css";
import MainNav from "../components/navigation/MainNav";
import ServicePromotionList from "../components/promotion/servicePromotionList";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ServiceState,
  addServicePromotion,
} from "../redux/slices/serviceSlice";
import Icons from "../components/reusable/Icons";
import Service, { IService } from "../models/Service";
import { formatTime, formatUSPrice } from "../utils/utils";
import ServicePromotionsList from "../components/promotion/ServicePromotionsList";
import Button from "../components/Button";
import CustomDropdownPrice, {
  Item,
} from "../components/promotion/CustomDropdownPrice";
import { percentageValues } from "../utils/data";
import { AnyAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

interface Promotion {
  isPromote: boolean;
  price_promotion: number;
  startTime: string | Date;
  endTime: string | Date;
}

function ServicePromotionsPage() {
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [searchItem, setSearchItem] = useState("");
  const [loading, setLoading] = useState(false);
  const [promotions, setPromotions] = useState<Record<string, Promotion>>({});
  const userServices = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).userServices;

  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const dispatch = useDispatch();

  const handleSelectService = (services: Service[]) => {
    setSelectedServices(services);
    // Initialize promotions for selected services
    const initialPromotions = services.reduce((acc, service) => {
      if (service._id) {
        acc[service._id] = service.promote || {
          isPromote: false,
          price_promotion: 0,
          startTime: "",
          endTime: "",
        };
      }
      return acc;
    }, {} as Record<string, Promotion>);
    setPromotions(initialPromotions);
  };
  const handleChange = (serviceId: string, key: string, value: any) => {
    setPromotions((prevPromotions) => ({
      ...prevPromotions,
      [serviceId]: {
        ...prevPromotions[serviceId],
        [key]: value,
      },
    }));
  };
  const renderPercentage = (item: Item) => <span>{item.value}</span>;

  const keyExtractor = (item: Item) => item.id;

  const handlePercentageChange = (serviceId: string, percentage: number) => {
    const initialPrice =
      selectedServices.find((service) => service._id === serviceId)?.price || 0;
    const percentagePrice = (initialPrice * percentage) / 100;
    const newPrice = initialPrice - percentagePrice;
    handleChange(serviceId, "price_promotion", newPrice);
  };

  const handleChangePrice = (item: Item | string, serviceId: string) => {
    if (typeof item === "string") {
      handleChange(serviceId, "price_promotion", parseFloat(item));
    } else {
      const percentage = parseFloat(item.value.replace("%", ""));
      handlePercentageChange(serviceId, percentage);
    }
  };
  const handleSubmitPromotion = (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedServices.length === 0) return;
    setLoading(true);
    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute("disabled", "true");

      if (selectedServices.length) {
        const updateServices = selectedServices.map((service) => {
          const updateService: Service = {
            ...service,
            price: service.price,
            name: service.service_detail.name as string,
            promote: {
              isPromote: true,
              price_promotion:
                promotions[service._id as string]?.price_promotion ||
                (service.promote?.price_promotion as number),
              startTime: new Date(
                promotions[service._id as string]?.startTime
              ).toISOString(),
              endTime: new Date(
                promotions[service._id as string]?.endTime
              ).toISOString() as string,
            },
          };
          return updateService;
        });

        dispatch(addServicePromotion(updateServices) as unknown as AnyAction)
          .unwrap()
          .then(() => {
            toast.success("Promotion create successfully");
            setSelectedServices(updateServices);
            console.log("Promotions submitted:", updateServices);
          })
          .catch((err: any) => {
            toast.error(
              "An error occured during edition, please try egain later"
            );
          })
          .finally(() => {
            setLoading(false);
            setTimeout(() => {
              if (submitBtnRef.current) {
                submitBtnRef.current.removeAttribute("disabled");
              }
            }, 2000);
          });
      }
      console.log("hols", promotions);
    }
  };

  return (
    <>
      <MainNav navType={"connectedNav"} isSearch={true} />
      <div className="service-promotion">
        <div className="popular-service align-items-start d-flex flex-column overflow-y-scroll">
          <div
            className="w-100 bg-body-secondary"
            style={{ height: 250, objectFit: "cover", width: "100%" }}
          >
            <img
              src={require("../assets/images/banner-promotion.png")}
              alt="banner-promotion"
              className="w-100 h-100"
            />
          </div>
          {/* <h2>Hello Promotion</h2> */}
          <div className=" gap-3 d-flex flex-column w-100 h-auto">
            {selectedServices.length > 0 ? (
              selectedServices.length <= 1 ? (
                selectedServices.map((selectedService, idx) => (
                  <div
                    className="service-details d-flex w-100 gap-4"
                    // style={{ height: "50vh" }}
                    key={idx}
                  >
                    <div className="w-100  gap-4">
                      <div className="d-flex gap-2 form-control">
                        <div className="w-100">
                          <label htmlFor={`name-${idx}`}>Service Name</label>
                          <input
                            id={`name-${idx}`}
                            className="form-control w-100 flex-grow-1"
                            type="text"
                            value={selectedService.service_detail.name ?? ""}
                            disabled
                          />
                        </div>
                        <div className="w-100">
                          <label htmlFor={`time-${idx}`}>Service Time</label>
                          <input
                            id={`time-${idx}`}
                            className="form-control w-100 flex-grow-1"
                            type="text"
                            value={formatTime(
                              selectedService?.duration as unknown as number
                            )}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="pt-3 form-control">
                        {selectedService.size ? (
                          selectedService.size.length > 1 ? (
                            <>
                              <label htmlFor={`size-${idx}`}>
                                Service Size
                              </label>
                              <span
                                aria-disabled
                                id={`size-${idx}`}
                                className="form-control w-100"
                              >
                                {selectedService.size[0]},{" "}
                                {selectedService.size[1]}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                aria-disabled
                                id={`size-${idx}`}
                                className=""
                              >
                                {selectedService.size[0]}
                              </span>
                            </>
                          )
                        ) : null}
                      </div>
                      <div className="pt-3 form-control">
                        <label htmlFor={`description-${idx}`}>
                          Description
                        </label>
                        <input
                          id={`description-${idx}`}
                          className="form-control w-100 text-start flex-none"
                          style={{ height: "100px" }}
                          type="text"
                          value={selectedService.description ?? ""}
                          disabled
                        />
                      </div>
                      <form onSubmit={handleSubmitPromotion}>
                        <div className="d-flex gap-2 pt-3 form-control">
                          <div className="w-100">
                            <label htmlFor={`price-${idx}`}>
                              Initial Price
                            </label>
                            <input
                              id={`price-${idx}`}
                              className="form-control w-100"
                              type="text"
                              value={formatUSPrice(selectedService.price) ?? ""}
                              disabled
                            />
                          </div>
                          <div className="w-100">
                            <label htmlFor="price_promotion">
                              Create a new price or choose percentage
                            </label>
                            <CustomDropdownPrice
                              searchItem={searchItem}
                              setSearchItem={setSearchItem}
                              items={percentageValues}
                              onChange={(item) => {
                                if (typeof item === "string") {
                                  return item;
                                }
                                const percentage = parseFloat(
                                  item.value.replace("%", "")
                                );
                                selectedServices.forEach((service) => {
                                  handlePercentageChange(
                                    service._id as string,
                                    percentage
                                  );
                                });
                              }}
                              keyExtractor={keyExtractor}
                              render={renderPercentage}
                              placeholder="Select or enter a percentage"
                              name="promote.price_promotion"
                            />
                          </div>
                        </div>
                        <div className="d-flex gap-2 pt-3 form-control">
                          <div className="w-100">
                            <label htmlFor={`promote.startTime-${idx}`}>
                              Start Promotion
                            </label>
                            <input
                              id={`promote.startTime-${idx}`}
                              className="form-control w-100"
                              type="date"
                              value={
                                //
                                selectedService.promote?.startTime as string
                              }
                              onChange={(e) =>
                                handleChange(
                                  selectedService._id as string,
                                  "startTime",
                                  e.target.value
                                )
                              }
                              placeholder="Enter a new Date"
                              name="promote.startTime"
                            />
                          </div>
                          <div className="w-100">
                            <label htmlFor={`promote.endTime-${idx}`}>
                              End Promotion
                            </label>
                            <input
                              id={`promote.endTime-${idx}`}
                              className="form-control w-100"
                              type="date"
                              value={
                                // promotions[selectedService._id]?.endTime || ""
                                selectedService.promote?.endTime as string
                              }
                              onChange={(e) =>
                                handleChange(
                                  selectedService._id as string,
                                  "endTime",
                                  e.target.value
                                )
                              }
                              name="promote.endTime"
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          <Button
                            type="submit"
                            mode="primary"
                            ref={submitBtnRef}
                            disabled={loading}
                            //className="br-6"
                            content="Create promotion"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <h5>Selected Services:</h5>
                  <div className="d-flex flex-row flex-wrap ">
                    {selectedServices.map((selectedService, idx) => (
                      <div key={idx} className="badge bg-primary m-1 p-3">
                        <span className=" mx-2 text-white">
                          {selectedService.service_detail.name}
                        </span>
                        <i className="fa-regular fa-circle-xmark"></i>
                      </div>
                    ))}
                  </div>
                  <div className="promotion-form">
                    <form onSubmit={handleSubmitPromotion}>
                      <div className="d-flex gap-2 pt-3 form-control">
                        <div className="w-100">
                          <label htmlFor="price_promotion">
                            Create a new price or choose percentage
                          </label>
                          <CustomDropdownPrice
                            searchItem={searchItem}
                            setSearchItem={setSearchItem}
                            items={percentageValues}
                            onChange={(item) => {
                              if (typeof item === "string") {
                                return item;
                              }
                              const percentage = parseFloat(
                                item.value.replace("%", "")
                              );
                              selectedServices.forEach((service) => {
                                handlePercentageChange(
                                  service._id as string,
                                  percentage
                                );
                              });
                            }}
                            keyExtractor={keyExtractor}
                            render={renderPercentage}
                            placeholder="Select or enter a percentage"
                            name="promote.price_promotion"
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-2 pt-3 form-control">
                        <div className="w-100">
                          <label htmlFor="promote.startTime">
                            Start Promotion
                          </label>
                          <input
                            id="promote.startTime"
                            className="form-control w-100"
                            type="date"
                            onChange={(e) => {
                              const newStatTime = e.target.value;
                              selectedServices.forEach((service) => {
                                handleChange(
                                  service._id as string,
                                  "startTime",
                                  newStatTime
                                );
                              });
                            }}
                            placeholder="Enter a new Date"
                            name="promote.startTime"
                          />
                        </div>
                        <div className="w-100">
                          <label htmlFor="promote.endTime">End Promotion</label>
                          <input
                            id="promote.endTime"
                            className="form-control w-100"
                            type="date"
                            onChange={(e) => {
                              const newEndTime = e.target.value;
                              selectedServices.forEach((service) => {
                                handleChange(
                                  service._id as string,
                                  "endTime",
                                  newEndTime
                                );
                              });
                            }}
                            name="promote.endTime"
                          />
                        </div>
                      </div>

                      <div className="d-flex justify-content-end mt-3">
                        <Button
                          type="submit"
                          mode="primary"
                          ref={submitBtnRef}
                          disabled={loading}
                          //className="br-6"
                          content="Create promotion"
                        />
                      </div>
                    </form>
                  </div>
                </>
              )
            ) : (
              <div
                data-aos="fade-up"
                data-aos-delay="500"
                className="d-flex justify-content-center align-items-center flex-column mt-5"
              >
                <h5 className="text-center text-break">No service selected</h5>
                <img
                  src={require("../assets/images/no-service.png")}
                  alt="no-service"
                  height="400px"
                  width="400px"
                />
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className="your-service">
          <h2 className="mb-8 mt-1">Your services</h2>
          {userServices?.length ? (
            <div
              className="d-flex flex-column justify-content-end"
              //style={{ maxWidth: 450 }}
            >
              <ServicePromotionsList
                services={userServices as any}
                scrollable={true}
                onseclectable={true}
                onSelect={handleSelectService}
              />
            </div>
          ) : (
            <NavLink to={"/service-menu"}>
              <span className="deeplink">Add service</span>
            </NavLink>
          )}
          <Icons />
        </div>
      </div>
    </>
  );
}

export default ServicePromotionsPage;
