import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { TWorkingTableElement } from "../WorkingTableElement";
import { ModalLoader } from "../../modal/commons/ModalLoader";
import useTimeOptions from "../../../hooks/useTimeOptions";
import { IAvailability } from "../../../models/Availability";
import moment from "moment";
import { USATimezone } from "../../../utils/types";
import { formatTime } from "../../../utils/utils";
import { TElementType } from "../WorkingTable";
import CalendarMultipleSelection from "../CalendarMultipleSelection";

export type AddShiftModalProps = {
  type: TElementType;
  shift: { element: TWorkingTableElement; day: moment.Moment };
  timezone: USATimezone;
  elements: TWorkingTableElement[];
  onHide: () => void;
  onSave: (value: ShiftTimeInterval, timezone: USATimezone) => Promise<any>;
  onSaveBulk: (
    value: ShiftTimeInterval,
    timezone: USATimezone,
    repeatUntil: moment.Moment[]
  ) => Promise<any>;
};

export type ShiftTimeInterval = Pick<IAvailability, "startTime" | "endTime">;

export const AddShiftModal: React.FC<AddShiftModalProps> = ({ ...props }) => {
  const [shiftInterval, setShiftInterval] = React.useState<ShiftTimeInterval>({
    startTime: moment()
      .startOf("hours")
      .add(Math.round(moment().minute() / 30) * 30, "minutes")
      .format("hh:mm A"),
    endTime: "05:00 PM",
  });
  const [loading, setLoading] = React.useState(false);
  const [repeat, setRepeat] = React.useState(false);
  const [repeatUntil, setRepeatUntil] = React.useState<moment.Moment[]>([
    moment(),
  ]);

  const timeOptions = useTimeOptions({ interval: 15 });
  const [error, setError] = React.useState([]);

  const startTimeOptions = timeOptions.generateOptions(
    shiftInterval.endTime,
    null,
    props.shift
  );
  const endTimeOptions = timeOptions.generateOptions(
    null,
    shiftInterval.startTime,
    props.shift
  );

  console.log("shift", props);

  const duration =
    moment(shiftInterval.endTime, "hh:mm A").diff(
      moment(shiftInterval.startTime, "hh:mm A"),
      "minutes"
    ) / 60.0;

  const handleRepeatUntilSelect = (date: moment.Moment[]) => {
    console.log("selectedDates", date);
    setRepeatUntil(date);
  };

  const handleSave = async () => {
    setError([]);
    setLoading(true);
    if (repeat) {
      await props
        .onSaveBulk(shiftInterval, props.timezone, repeatUntil)
        .then(() => {
          props.onHide();
        })
        .catch((err) => {
          console.log(err);
          setError(err.errors);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      await props
        .onSave(shiftInterval, props.timezone)
        .then(() => {
          props.onHide();
        })
        .catch((err) => {
          console.log(err);
          setError(err.errors);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    console.log("element datas", props.elements, props.shift);
  }, []);

  return (
    <Modal
      backdrop="static"
      onHide={props.onHide}
      show={true}
      centered
      size="lg"
    >
      {loading && <ModalLoader />}
      <Modal.Header closeButton>
        <div className="text-black fw-500 fs-5">
          {props.shift.element.label}'s{" "}
          {props.type.split("O").join(" o").toLowerCase()}{" "}
          {props.shift.day.format("ddd, DD MMM")}
        </div>
      </Modal.Header>
      <Modal.Body className="border-top border-bottom">
        {error.length > 0 && (
          <div
            style={{ borderRadius: "0" }}
            className="alert alert-danger w-100 p-0 m-0 pt-1"
          >
            <ul className="">
              {error.map
                ? error.map((message) => {
                    return <li>{message}</li>;
                  })
                : error.toString()}
            </ul>
          </div>
        )}
        <div className="mx-3 my-4 working-table-add-shift-container gap-3">
          <div className="d-flex gap-4">
            <div className="mb-3 w-50">
              <label htmlFor="" className="form-label fw-500 text-black">
                Start time
              </label>
              <select
                value={shiftInterval.startTime}
                onChange={(e) =>
                  setShiftInterval((prev) => ({
                    ...prev,
                    startTime: e.target.value,
                  }))
                }
                className="form-select form-select-lg"
              >
                {startTimeOptions}
              </select>
            </div>
            <div className="mt-auto mb-4 fw-500 text-black">-</div>
            <div className="mb-3 w-50">
              <label htmlFor="" className="form-label fw-500 text-black">
                End time
              </label>
              <select
                value={shiftInterval.endTime}
                onChange={(e) =>
                  setShiftInterval((prev) => ({
                    ...prev,
                    endTime: e.target.value,
                  }))
                }
                className="form-select form-select-lg"
              >
                {endTimeOptions}
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <span className="fw-500 text-black">
              {formatTime(duration * 60)}
            </span>
          </div>
          <div className="mb-2 d-flex align-items-center gap-2 text-black">
            <div
              onClick={() => setRepeat(!repeat)}
              className={`repeatbox d-flex justify-content-center align-items-center ${
                repeat ? "repeat" : ""
              }`}
            >
              {/* <input type="checkbox" name="repeat" id="repeat" className="" /> */}
              <i className="fa-solid fa-check fa-sm text-white"></i>
            </div>
            <span className="fw-500">Repeat</span>
          </div>
          {repeat && (
            <div className="d-flex flex-column mt-4">
              <span className="text-black fw-500 mb-2">Repeat until</span>
              <CalendarMultipleSelection
                selectedDates={repeatUntil}
                setSelectedDates={handleRepeatUntilSelect}
              />
            </div>
          )}

          <small
            style={{ color: "#757676" }}
            className="mt-2 fw-400 text-muted"
          >
            You are editing this day's{" "}
            {props.type.split("O").join(" o").toLowerCase()} on{" "}
            <span className="text-primary cursor-pointer text-decoration-underline">
              {props.timezone}
            </span>{" "}
            timezone.
          </small>
        </div>
      </Modal.Body>
      <Modal.Body>
        <div className="mx-3 my-3 d-flex justify-content-end align-items-center gap-3">
          <button
            onClick={() => props.onHide()}
            className="working-table-outline-button"
          >
            Cancel
          </button>
          <button
            onClick={() => handleSave()}
            className="working-table-primary-button"
          >
            Save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
