import React, { ReactNode, useEffect, useState } from "react";
import styles from "./styles/Selector.module.css";

export interface SelectorOptions {
  child: ReactNode;
  selected?: boolean;
  selectedAll?: boolean;
  onSelect: (value: boolean) => any;
}

const Selector: React.FC<SelectorOptions> = ({
  child,
  selected = false,
  onSelect,
  selectedAll,
}) => {
  const [select, setSelect] = useState(selected);

  useEffect(() => {
    // Only update the select state if selectedAll is false
    if (!selectedAll) {
      setSelect(selected);
    }
  }, [selected, selectedAll]);

  return (
    <div className={`${styles.Selector}`}>
      <div
        className={`${styles["Selector__select"]} form-check form-check-inline`}
      >
        <input
          onClick={(e) => {
            onSelect(!select);
            // setSelect(!select);
          }}
          checked={select || (selectedAll && selected)}
          className="form-check-input"
          type="checkbox"
        />
      </div>
      <div
        className={`${
          select || (selectedAll && selected) // Add selectedAll check here
            ? styles["Selector__select--active"]
            : " "
        }`}
      >
        {child}
      </div>
    </div>
  );
};

export { Selector };
