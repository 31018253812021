import React, { useEffect, useState } from "react";

interface CountdownProps {
  targetDate: Date;
}

const Countdown = ({ targetDate }: CountdownProps) => {
  const calculateTimeRemaining = (targetNewDate: Date) => {
    return targetNewDate.getTime() - new Date().getTime();
  };

  const [timeRemaining, setTimeRemaining] = useState<number>(
    calculateTimeRemaining(targetDate)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate.getTime() - now;
      setTimeRemaining(distance);
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const formatTime = (time: number) => {
    if (time <= 0) {
      return "Promotion ended";
    }

    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    if (days > 7) {
      return `${days} days remaining`;
    } else if (days <= 7 && days > 1) {
      return `${days} days ${hours} h ${minutes} min ${seconds} sec remaining`;
    } else {
      return `${hours} h ${minutes} min ${seconds} sec`;
    }
  };

  return <span>{formatTime(timeRemaining)}</span>;
};

export default Countdown;
