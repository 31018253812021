import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountState,
  getBalance,
  getTransactions,
} from "../../redux/slices/accountSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { formatUSPrice } from "../../utils/utils";
import TopUpAccountModal from "../modal/TopUpAccountModal";
import { TransactionHistory } from "./TransactionHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollar, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { TransactionHistorySkeleton } from "../../skeletons/TransactionHistorySkeleton";
import { TransactionSkeleton } from "../../skeletons/TransactionSkeleton";
import TransfertAccountModal from "../modal/TransfertAccountModal";

type Props = {};

export const AccountBalance = (props: Props) => {
  const [show, setShow] = useState(false);
  const [showTransfert, setShowTransfert] = useState(false);
  const dispatch = useDispatch();

  const balance = useSelector<unknown, AccountState>(
    (state: any) => state.account
  ).balance;
  const transactions = useSelector<unknown, AccountState>(
    (state: any) => state.account
  ).transactions;

  useEffect(() => {
    if (!balance) dispatch(getBalance() as unknown as AnyAction);
    if (!transactions) dispatch(getTransactions("") as unknown as AnyAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {true && (
        <>
          {transactions && (
            <div className="d-flex flex-column justify-content-center align-items-center gap-4">
              <div className="d-flex flex-column bg-white rounded-2 w-100">
                <div className="d-flex justify-content-between align-items-center w-100 border-bottom p-3">
                  <div className="d-flex flex-column justify-content-between">
                    <span className="text-muted">Total Balance</span>
                    <span className="fs-3 fw-semibold">
                      {formatUSPrice(balance as number)}
                    </span>
                  </div>
                  <div className="d-flex gap-2">
                    <button
                      onClick={() => setShowTransfert(true)}
                      className="btn bg-white rounded border-primary p-2 d-flex align-items-center justify-content-center gap-1"
                    >
                      <span className="fw-500">Transfer funds</span>
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faEnvelopeOpen}
                      />
                    </button>
                    <button
                      onClick={() => setShow(true)}
                      className="btn bg-white rounded border-primary p-2 d-flex align-items-center justify-content-center gap-1"
                    >
                      <span className="fw-500">Add funds</span>
                      <FontAwesomeIcon
                        className="text-primary"
                        icon={faDollar}
                      />
                    </button>
                  </div>
                </div>
                <div>
                  {transactions && (
                    <TransactionHistory transactions={transactions} />
                  )}
                </div>
              </div>

              <TopUpAccountModal show={show} onBack={() => setShow(false)} />
              <TransfertAccountModal
                showTransfert={showTransfert}
                onBack={() => setShowTransfert(false)}
              />
            </div>
          )}
          {!transactions && <TransactionSkeleton />}
        </>
      )}
    </>
  );
};
