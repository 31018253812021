import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import SpinnerLoader from "../loader/SpinnerLoader";
import { toast } from "react-hot-toast";
import moment from "moment";
import { DiscussionSystemActionType } from "../chat/LatriceDiscussionMessage";
import { AppointmentStatus } from "../../models/Appointment";

export interface LatriceDisputeModalProps {
  title: string;
  errorCode?: string;
  onHide: () => void;
  type: DiscussionSystemActionType;
  onConfirm: (data: FormData) => Promise<any>;
  appointmentStatus?: AppointmentStatus;
}

const LatriceDisputeModal: React.FC<LatriceDisputeModalProps> = ({
  title,
  onConfirm,
  type,
  onHide,
  appointmentStatus,
}: LatriceDisputeModalProps) => {
  const navigate = useNavigate();
  const currentLocation = window.location.href;
  const formRef = useRef<HTMLFormElement>(null);
  const [message, setMessage] = useState(<div>{type}</div>);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState();

  const handleLogin = () => navigate(`/login?ref=${currentLocation}`);

  const handleConfirm = (e: any) => {
    setLoading(true);

    if (formRef.current) {
      const formData = new FormData(formRef.current);
      console.log("formData", formData);
      if (
        type === "bad_date" &&
        (formData.get("bad_date") === null || formData.get("bad_date") === "")
      )
        return toast.error("You need to provide a valid date.");
      else if (
        type === "bad_description" &&
        formData.get("bad_description") === null
      )
        return toast.error("You need to provide a valid description.");
      else if (
        type === "price_too_low" &&
        formData.get("price_too_low") === null
      )
        return toast.error("You need to provide a valid price.");
      else if (
        type === "price_too_low & bad_date" &&
        formData.get("price_too_low") === null
      )
        return toast.error("You need to provide a valid price.");
      else if (
        type === "price_too_low & bad_date" &&
        formData.get("bad_date") === null
      )
        return toast.error("You need to provide a valid date.");

      formData.set("timezone", moment.tz.guess());
      console.log("the formData", formData);
      onConfirm(formData)
        .then(() => {
          setLoading(false);
          onHide();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    switch (type) {
      case "accept":
        setMessage(<div>You are going to accept this custom appointment.</div>);
        break;
      case "bad_date":
        setMessage(<div>Please propose a new date</div>);
        break;
      case "bad_duration":
        setMessage(<div>Suggest the time needed to complete the service</div>);
        break;
      case "bad_description":
        setMessage(<div>Please propose a new description of the service</div>);
        break;
      case "price_too_low":
        setMessage(<div>Please propose a new price</div>);
        break;
      case "price_too_low & bad_date":
        setMessage(<div>Please propose a new date & price</div>);
        break;
      case "refuse":
        if (appointmentStatus !== "pending") {
          setMessage(
            <div>Your are going to refuse this custom appointment</div>
          );
        } else {
          setMessage(<div>Why do you refuse this custom appointment ?</div>);
        }
        break;

      default:
        break;
    }
  }, []);

  return (
    <Modal
      style={{ backdropFilter: "blur(2px)" }}
      backdrop="static"
      onHide={onHide}
      show={true}
      centered
    >
      {loading && <SpinnerLoader />}
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          <i className="fa fa-triangle-exclamation"></i> {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="container my-2 p-lg-0">
        <p className=" ">{message}</p>
        <form ref={formRef} className="">
          {type === "refuse" && appointmentStatus === "pending" && (
            <div className="d-flex flex-wrap">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="distance_too_far"
                  id="distance_too_far"
                  value="distance_too_far"
                />
                <label className="form-check-label" htmlFor="distance_too_far">
                  Distance too far
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="unable_to_do_it"
                  id="unable_to_do_it"
                  value="unable_to_do_it"
                />
                <label className="form-check-label" htmlFor="unable_to_do_it">
                  Unable to do it
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="picture_unclear"
                  id="picture_unclear"
                  value="picture_unclear"
                />
                <label className="form-check-label" htmlFor="picture_unclear">
                  Picture unclear
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="used_foul_language"
                  id="used_foul_language"
                  value="used_foul_language"
                />
                <label
                  className="form-check-label"
                  htmlFor="used_foul_language"
                >
                  Used foul language
                </label>
              </div>
            </div>
          )}
          {type === "accept" && <div className="d-flex flex-wrap"></div>}
          {type === "bad_date" && (
            <div className={"d-flex flex-column"}>
              <div className="input-group mb-3 w-100">
                <input
                  placeholder="propose a new date"
                  type="datetime-local"
                  className="form-control"
                  name="bad_date"
                />
              </div>
            </div>
          )}
          {type === "bad_duration" && (
            <div className={"d-flex flex-column"}>
              <div className="input-group mb-3 w-100">
                <input
                  autoComplete="off"
                  placeholder="Duration"
                  className="form-control h-100"
                  type="number"
                  name="bad_duration"
                  id="bad_duration"
                  min={5}
                  //   onChange={handleChange}
                  //   value={newService.duration === 0 ? "" : newService.duration}
                />
              </div>
            </div>
          )}
          {type === "bad_description" && (
            <div className={"d-flex flex-column"}>
              <div className="input-group mb-3 w-100">
                <input
                  autoComplete="off"
                  placeholder="Your description"
                  className="form-control h-100"
                  type="text"
                  name="bad_description"
                  id="bad_description"
                />
              </div>
            </div>
          )}
          {type === "price_too_low" && (
            <div className={"d-flex flex-column"}>
              <div className="input-group mb-3 w-100">
                <span className="input-group-text" id="price_too_low">
                  $
                </span>
                <input
                  name="price_too_low"
                  placeholder="propose a new price"
                  type="number"
                  min={1}
                  className="form-control"
                />
              </div>
            </div>
          )}
          {type === "price_too_low & bad_date" && (
            <div>
              <div className={"d-flex flex-column"}>
                <div className="input-group mb-3 w-100">
                  <input
                    name="bad_date"
                    placeholder="propose a new date"
                    type="datetime-local"
                    className="form-control"
                  />
                </div>
              </div>
              <div className={"d-flex flex-column"}>
                <div className="input-group mb-3 w-100">
                  <span className="input-group-text" id="price_too_low">
                    $
                  </span>
                  <input
                    name="price_too_low"
                    placeholder="propose a new price"
                    type="number"
                    min={1}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirm} variant="outline-dark" className="w-25">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LatriceDisputeModal;
