import React, { useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import "../../styles/LocalizationInput.css";

interface LocationSearchInputProps {
  defaultValue?: string;
  onLocationSelect: (
    latLng: { lat: number; lng: number },
    address: string
  ) => void;
}

const LocationSearchInput: React.FC<LocationSearchInputProps> = ({
  defaultValue = null,
  onLocationSelect,
}) => {
  const [address, setAddress] = useState<string | null>(defaultValue);

  const handleSelect = async (selected: any) => {
    setAddress(selected);
    try {
      const results = await geocodeByAddress(selected.value.description);
      const latLng = await getLatLng(results[0]);
      onLocationSelect(latLng, selected.value.description);
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <div className="w-100">
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_SERVER_GOOGLE_MAP_API_KEY as string}
        selectProps={{
          onChange: handleSelect,
          placeholder: " Enter the location where you operate your business ...",
          styles: {
            input: (provided) => ({
              ...provided,
              height: "30px", // Set the height to 35px
              border: "none !important",
              outline: "none", // Remove the default focus outline
              borderBottom: "2px solid #000000", // Add a border when focused
              transition: "border-color 0.2s ease-in-out", // Add transition for smooth effect
              // color: 'var(--primaryColor) !important',
            }),
            option: (provided) => ({
              ...provided,
              // color: 'var(--primaryColor) !important',
            }),
            singleValue: (provided) => ({
              ...provided,
              // color: 'var(--primaryColor) !important',
            }),
          },
        }}
      />
    </div>
  );
};

export default LocationSearchInput;