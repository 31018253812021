import React from "react";
import styles from "../styles/Appointment.module.css";
import moment from "moment";
import { formatUSPrice, toProjectLabel } from "../../utils/utils";
import { toast } from "react-hot-toast";
import slugify from "slugify";
import { Tooltip } from "react-tooltip";
import { IProject } from "../../models/Project";
import { ISalon } from "../../models/SalonX";

export interface ProjectProps {
  project: IProject;
  selected?: boolean;
  mode?: "default" | "nocancel" | "other";
  onClick?: (event: any) => void;
  onCancel: (project: IProject) => Promise<any>;
}

const ProjectModalItem: React.FC<ProjectProps> = ({
  project,
  mode = "default",
  selected = false,
  onClick,
  onCancel,
}) => {
  const handleCancelProject = (): Promise<any> => {
    return onCancel(project)
      .then((res) => {
        toast.success("cancellation success");
        return Promise.resolve(0);
      })
      .catch((err) => {
        if (err.errors && err.errors.message) {
          toast.error(err.errors.message);
        } else {
          toast.error("cancellation failed");
        }
        return Promise.reject(0);
      });
  };

  const nameSlug = slugify(`${(project?.salon as unknown as ISalon)?.name}`);
  const professionalLink = `/see-professional-account/${nameSlug}-${
    (project?.salon as unknown as ISalon)?._id
  }`;

  console.log("project",project)

  return (
    <div className={`${styles.Appointment} d-flex flex-column`}>
      <div
        onClick={onClick}
        className={`d-flex justify-content-between fw-bold ${
          selected ? "text-primary" : ""
        }`}
      >
        <div
          data-tooltip-id="serviceLabel"
          data-tooltip-place="top"
          data-tooltip-content={toProjectLabel(project)}
          className="w-25"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {toProjectLabel(project)}
          <Tooltip id="serviceLabel" />
        </div>
        <div>{moment(project.startTime, "HH:mm").format("h:mm A")}</div>
        <div className="fw-500">{formatUSPrice(project.price)}</div>
      </div>
      <div className={`d-flex justify-content-between align-items-end gap-4`}>
        <div
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          className="text-muted text-primary text-nowrap"
        >
          <a
            target="__blank"
            className={styles.deepLinkProfile}
            href={professionalLink}
          >
            <span className="text-muted text-decoration-none me-2">with</span>
            <span className="text-decoration-underline">
              {(project?.salon as unknown as ISalon)?.name}
            </span>
          </a>
        </div>
        {/* {mode !== "nocancel" && (
          <AppointmentCancelHandlerModalDialog
            child={<div className={`text-danger`}>Cancel</div>}
            cancel={handleCancelAppointment}
            appointment={appointment}
          />
        )} */}
      </div>
    </div>
  );
};

export { ProjectModalItem };
