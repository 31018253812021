import { forwardRef, useEffect, useState, ChangeEvent } from "react";
import { useFormik } from "formik";
import {
  initialValues,
  validationSchema,
} from "../validation/RegisterValidation";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";

interface InputMediaProps {
  placeholder: string;
  accept: string;
  containerClassname?: string;
  value?: string | null;
  icon?: string | null;
  width?: string;
  height?: string;
  borderRadius?: string;
  onClick?: () => void;
  borderColor?: string;
  isEdited?: boolean;
  onChange?: (...args: any[]) => any;
}

const InputMedia = forwardRef<HTMLInputElement, InputMediaProps>(
  (
    {
      placeholder,
      accept,
      containerClassname,
      value,
      icon = null,
      width,
      height,
      onClick,
      borderRadius,
      borderColor,
      onChange,
      isEdited = false,
      ...params
    },
    ref: any
  ) => {
    const [url, setUrl] = useState<string | null>(value ?? null);
    const [isEditing, setIsEditing] = useState(isEdited);

    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();

      if (isEditing) {
        ref?.current?.click();
      } else {
        setIsEditing(true);
      }
      //ref?.current?.click();
    };

    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        alert(JSON.stringify(values, null, 2));
      },
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
      if (onChange) {
        formik.setFieldValue("image", e.target.files?.[0]);
      }
    };

    useEffect(() => {
      setUrl(value as string);
    }, [value]);

    return (
      <>
        <div
          className={
            "InputMedia d-flex flex-column justify-content-center align-items-center  " +
            containerClassname
          }
          style={{
            width: width ?? "75px",
            height: height ?? "60px",
            borderRadius: borderRadius ?? "10px",
            userSelect: "none",
            backgroundColor: "var(--whiteColor)",
            cursor: "pointer",
            border: "1px solid " + (borderColor ?? "var(--borderColor)"),
            boxSizing: "content-box",
          }}
          onClick={onClick ? () => onClick() : handleClick}
        >
          {url === null ? (
            <>
              <div>
                <i className={icon ?? "fa fa-camera"}></i>
              </div>
              <div style={{ fontSize: ".68em" }} className="">
                {placeholder}
              </div>
            </>
          ) : (
            <>
              <img
                alt="Media"
                src={url}
                style={{
                  width: width ?? "75px",
                  boxSizing: "border-box",
                  height: height ?? "60px",
                  borderRadius: borderRadius ?? "10px",
                  userSelect: "none",
                }}
              />
            </>
          )}
        </div>
        {!isEdited && (
          <>
            <input
              onChange={handleChange}
              onBlur={formik.handleBlur}
              ref={ref}
              hidden
              type="file"
              accept={accept}
              {...params}
            />

            <Button
              onClick={handleClick}
              mode={"neutral"}
              style={{ minWidth: "65px" }}
              content={
                <span className="mx-0" style={{ fontSize: ".8em" }}>
                  {isEdited ? " " : "Edit"}
                </span>
              }
            />
          </>
        )}
        <input
          onChange={handleChange}
          onBlur={formik.handleBlur}
          ref={ref}
          hidden
          type="file"
          accept={accept}
          {...params}
        />

        {formik.touched.image && formik.errors.image ? (
          <div style={{ color: "var(--primaryColor)" }}>
            {formik.errors.image}
          </div>
        ) : null}

        {/* <input
          onChange={handleChange}
          onBlur={formik.handleBlur}
          ref={ref}
          hidden
          type="file"
          accept={accept}
          {...params}
        />
        {formik.touched.image && formik.errors.image ? (
          <div style={{ color: "var(--primaryColor)" }}>
            {formik.errors.image}
          </div>
        ) : null} */}
      </>
    );
  }
);

export default InputMedia;
