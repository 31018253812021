import Modal from "../modal/Modal";

import { useState } from "react";
import { useUser } from "../../hooks/UseUser";
import ShareLinkPromotion from "./ShareLinkPromotion";

export interface AddPromotionSeviceModalProps {
  show: boolean;
  onClose: () => void;
  onCreate?: (formData: FormData) => void;
}

export const AddPromotion: React.FC<AddPromotionSeviceModalProps> = ({
  show,
  onClose,
  onCreate,
}: AddPromotionSeviceModalProps) => {
  const [open, setOpen] = useState<boolean>(show);
  const handleClose = () => {
    setOpen(false);
  };

  const currentUser = useUser();

  return (
    <Modal open={show} onClose={handleClose} className="p-4">
      <div className="d-flex justify-content-center align-items-center flex-column">
        <div
          className=" object-cover"
          style={{ height: "400px", width: "400px" }}
        >
          <img
            src={require("../../assets/images/latrice-promotion.png")}
            className="h-100 w-100 object-cover"
            alt="Latrice Promotion"
          />
        </div>
        {currentUser && currentUser.coupon && (
          <ShareLinkPromotion
            link={currentUser.coupon.link}
            title="Latrice Promotion"
          />
        )}
      </div>
    </Modal>
  );
};
