import moment from "moment";
import React from "react";
import SlidePicker from "../SlidePicker";

type TimePickerProps = {
  filteredArray: moment.Moment[];
  selectedDateTimes: any[];
  selectedTime: any;
  selectedDate: moment.Moment | undefined;
  handleSelectTime: (selectedDate: moment.Moment) => void;
};

const TimePicker: React.FC<TimePickerProps> = ({
  filteredArray,
  handleSelectTime,
  selectedTime,
  selectedDateTimes,
  selectedDate,
}) => {
  // console.log(filteredArray);
  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="w-100">
        {!(selectedDateTimes && selectedDateTimes.length > 0) ? (
          <div className="small text-muted d-flex flex-column gap-2 justify-items-center align-items-center w-100">
            <i className="fa fa-calendar-days fa-3x"></i>
            <span className="col-md-9 text-center">
              Sorry, there are no available slots on{" "}
              {selectedDate?.format("dddd, MMMM DD")}.<br />
              Please select another day
            </span>
          </div>
        ) : (
          <SlidePicker
            data={filteredArray}
            selected={selectedTime}
            keyExtractor={(item) => item.format("YYYY-MM-DD-HH-mm-A")}
            separator={<span className="mx-1"></span>}
            renderItem={(item) => (
              <div className="hourItem gap-1 d-flex flex-row fw-bold p-2 br-6 btn-secondary">
                <div>{item.format("HH:mm")}</div>
                <div>{item.format("A")}</div>
              </div>
            )}
            onSelectItem={handleSelectTime}
          />
        )}
      </div>
    </div>
  );
};

export default TimePicker;
