import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Payment from "../../models/Payment";

export type AccountVAlue = "paypal" | "googlePay";

type Props = {
  Payment: Payment;
  OnSave: (value: Payment) => any;
  value: AccountVAlue;
  show: boolean;
  handleShow: any;
};

const AddPaymentAccount = (props: Props) => {
  const activeIndex = props.value;

  const handleSave = () => {
    if (typeof props.OnSave === "function") props.OnSave(props.Payment);
  };

  return (
    <div>
      <Modal show={props.show} onHide={props.handleShow}>
        <Modal.Header closeButton>
          <Modal.Title>
            Enter your {activeIndex === "paypal" ? "Paypal" : "GooglePay"}{" "}
            account email
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{ display: activeIndex === "paypal" ? "block" : "none" }}
            className="paypal"
          >
            <input
              style={{ outline: "none", border: "2px solid #dee2e6" }}
              className="input w-100 rounded-2 px-2"
              type="email"
              name="paypal"
              id="paypal"
              placeholder="johnDoe@gmail.com"
            />
          </div>
          <div
            style={{ display: activeIndex === "googlePay" ? "block" : "none" }}
            className="googlePay"
          >
            <input
              style={{ outline: "none", border: "2px solid #dee2e6" }}
              className="input w-100 rounded-2 px-2"
              type="email"
              name="googlePay"
              id="googlePay"
              placeholder="johnDoe@gmail.com"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSave} className="btn btn-primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddPaymentAccount;
