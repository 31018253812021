import axios from "axios";
import { IAppointmentCancellation } from "../models/AppointmentCancellation";

const getAppointmentCancellations = async () => {
    const data = await axios
        .get(`appointments/cancelled`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
        });
    return data;
};

const cancelAppointmentCancellation = (appointment: Partial<IAppointmentCancellation>) => {
    return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}appointments/cancelled/cancel/${appointment._id}`,
        method: "PUT",
        data: appointment,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    }).then((data) => {
        return data;
    });
};

const acceptAppointmentCancellation = (appointment: Partial<IAppointmentCancellation>) => {
    return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}appointments/cancelled/accept/${appointment._id}`,
        method: "PUT",
        data: appointment,
        headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
    }).then((data) => {
        return data;
    });
};


export const AppointmentCancellationService = {
    getAppointmentCancellations,
    cancelAppointmentCancellation,
    acceptAppointmentCancellation
}
