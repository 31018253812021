import React, { useState } from 'react';
import '../styles/WeekSelector.css';
import moment, { Moment } from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import useWeekSelector from '../../hooks/useWeekSelector';
import { USATimezone, USATimezones } from '../../utils/types';
import ReactDatePicker from 'react-datepicker';


type WeekSelectorProps = {
    initialDate?: moment.Moment;
    onChange?: (dates: Moment[], timezone: USATimezone) => void;
    className?: string;
};

export const WeekSelector: React.FC<WeekSelectorProps> = ({ onChange, className, initialDate }) => {
    const datePickerInputRef = React.useRef<HTMLInputElement>(null);
    const [defaultTimezone, setDefaultTimezone] = React.useState<USATimezone>("US/Eastern");
    const [timezone, setTimezone] = React.useState<USATimezone | null>(defaultTimezone);
    const [openDatePicker, setOpenDatePicker] = useState(false);

    const { startDate, endDate, setStartDate, handleNextWeek, handlePrevWeek, weekDates, resetCurrent } = useWeekSelector();

    const isStartDateCurrentWeek = moment().startOf('week').isSame(startDate.startOf('week'), 'day');

    const handleOpenPicker = () => {
        setOpenDatePicker(true)
    }

    const handleResetWeek = () => {
        if (!isStartDateCurrentWeek)
            resetCurrent()
    }

    const handleChangeTimezone = (e: any) => {
        setTimezone(e.target.value);
    };

    const handlePickDate = (date: Date | null) => {
        if (!date) return;

        const pickedStartDate = moment(date).startOf('week');
        setStartDate(pickedStartDate)

        setOpenDatePicker(false);
    }


    React.useEffect(() => {
        if (onChange) onChange(weekDates, timezone as USATimezone)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, timezone])


    return (
        <div className={`week-selector cursor-pointer ${className ?? ''}`}>
            <div className="week-selector__container d-flex justify-content-between h-100">
                <div className="week-selector__button prev border" onClick={handlePrevWeek}>
                    <i className="fa fa-chevron-left" />
                </div>
                <div
                    onClick={handleResetWeek}
                    className={`week-selector__week border px-3 ${isStartDateCurrentWeek ? 'active' : ''}`}>
                    <div>This week</div>
                </div>
                <div className={`week-selector__timezone border`}>
                    <select
                        value={timezone as string}
                        onChange={handleChangeTimezone}
                        style={{ borderRadius: 0 }}
                        className="form-select border-0 h-100">
                        {USATimezones.map((timezone, index) => (
                            <option key={index} value={timezone.value}>
                                {timezone.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='h-100'>
                    <ReactDatePicker
                        className='h-100'
                        autoFocus
                        onClickOutside={() => setOpenDatePicker(false)}
                        open={openDatePicker}
                        onInputClick={handleOpenPicker}
                        customInput={
                            <div className="week-selector__current h-100 border px-5">
                                <div>{`${weekDates[0].format('D')} - ${weekDates[6].format('D MMM, YYYY')}`}</div>
                            </div>
                        }

                        dayClassName={(date: Date) => {
                            if (moment(date).isBetween(startDate, startDate.clone().add(8, 'day'), 'minute', '()')) {
                                return 'text-primary fw-bold border rounded border-primary bg-white'
                            }
                            return ""
                        }}
                        onChange={(date) => {
                            handlePickDate(date)
                        }} />
                </div>
                <div className="week-selector__button next border" onClick={handleNextWeek}>
                    <i className="fa fa-chevron-right" />
                </div>
            </div>
        </div>
    );
};