import React, { CSSProperties, useState } from "react";
import "../styles/Note.css";
import { Link } from "react-router-dom";
import MemberType from "../models/Member";
import { Alert, Tooltip } from "react-bootstrap";
import moment from "moment";
import { useUser } from "../hooks/UseUser";
import AcceptMemberModel from "./modal/AcceptMemberModel";
import RejectMemberModal from "./modal/RejectMemberModal";

type IMember = {
  data: MemberType;
  handleMemberDetails: (data: MemberType) => void;
  onAccept: (data: MemberType) => void;
  onReject: (data: MemberType) => void;
};

const Member: React.FC<IMember> = ({
  data,
  handleMemberDetails,
  onAccept,
  onReject,
}) => {
  const user = useUser();
  console.log("user in member card", user, data);

  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);

  return (
    <div
      className="rce-mbox"
      style={{ width: "100%", height: "100%", padding: "15px" }}
    >
      <div className="rce-mbox-body">
        <div className="rce-mbox-forward rce-mbox-forward-right"></div>
        <div className="d-flex justify-content-between rce-mbox-title rce-mbox-title--clear">
          <span className="text-uppercase">
            {data.status == "pending"
              ? "You have a pending member request"
              : "You have a confirmed member request"}
          </span>
          <Tooltip id="pending-custom-appointment-tooltip" />
          <div style={{ textDecoration: "none !important" } as CSSProperties}>
            <img
              style={{ width: "100px" }}
              alt=""
              className="img-fluid rounded"
              src={
                `${
                  user?._id === data.userId._id
                    ? data.userMemberId.profile
                    : data.userId.profile
                }` ??
                "https://cdn.pixabay.com/photo/2018/08/23/22/29/girl-3626901_960_720.jpg"
              }
            />
          </div>
        </div>
        <div className="rce-mbox-text left d-flex flex-column gap-1">
          <div className="d-flex align-items-center">
            <h6 className="text-underline">
              {user?._id === data.userId._id
                ? data.userName
                : data.userId.first_name}{" "}
            </h6>
          </div>
          <div className="d-flex gap-1 align-items-center w-100 fw-500 mb-2">
            <div className="d-flex align-items-center gap-1">
              <span>
                {user?._id === data.userId._id ? data.email : data.userId.email}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-end">
        {data.status === "pending" ? (
          <>
            {user?._id === data.userId._id ? (
              <Alert className="alert-info" key={"info"} variant={"info"}>
                <i className="fa fa-triangle-exclamation fa-0.4x"></i>
                You have requested for adding a member. We are currently
                awaiting the member response.
              </Alert>
            ) : (
              <div className="d-flex justify-content-start align-items-end">
                <button
                  onClick={() => setOpenAcceptModal(true)}
                  className={`LatricePendingAppointment__button accept-button`}
                >
                  <i className="fas fa-check"></i> Accept
                </button>
                <button
                  onClick={() => setOpenRejectModal(true)}
                  className={`LatricePendingAppointment__button refuse-button`}
                >
                  <i className="fas fa-times"></i> Reject
                </button>
              </div>
            )}
          </>
        ) : (
          <div className={`LatricePendingAppointment__button accept-button`}>
            <i className="fas fa-check"></i> Accepted
          </div>
        )}
      </div>
      <div className="rce-mbox-time non-copiable ms-auto">
        <span
          className="rce-mbox-status"
          onClick={() => handleMemberDetails(data)}
        >
          See more
        </span>
      </div>
      <AcceptMemberModel
        onAccept={() => onAccept(data)}
        show={openAcceptModal}
        onClose={() => setOpenAcceptModal(false)}
      />
      <RejectMemberModal
        onReject={() => onReject(data)}
        show={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
      />
    </div>
  );
};

export default Member;
