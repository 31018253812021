import React, { useEffect, useState } from "react";
import Modal from "./Modal";

interface PasswordErrorProps {
  message: string;
  open: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

const PasswordErrors = ({
  message,
  open,
  onClose,
  onOpen,
}: PasswordErrorProps) => {
  const [openModal, setOpenModal] = useState<boolean>(open);

  return (
    <>
      {openModal && (
        <Modal
          open={open}
          onClose={onClose}
          showCloseButton
          className="closed position-absolute top-50 start-50 translate-middle"
        >
          <div className="d-flex justify-content-center flex-column  align-items-sm-center p-2 text-center">
            <div
              className="w-100 text-center "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h5 className="text-black">Error</h5>
              <i className="fa fa-exclamation-triangle fa-xl text-danger"></i>
            </div>
            <p>{message}</p>

            <button
              className=" rounded-2 bg-danger text-white p-2 w-25  "
              onClick={() => setOpenModal(false)}
            >
              Ok
            </button>
          </div>
          ;
        </Modal>
      )}
    </>
  );
};

export default PasswordErrors;
