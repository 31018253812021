import axios from "axios";

const newcomerPromotion = (data: any) => {
  console.log("data i send for ", data);
  return axios({
    url: `admin/new-comer-promotion`,
    method: "PATCH",
    data: data,
  }).then((data) => {
    return data;
  });
};
const referralLinkPromotion = (data: any) => {
  console.log("data i send for ", data);
  return axios({
    url: `admin/referral-link-promotion`,
    method: "PATCH",
    data: data,
  }).then((data) => {
    return data;
  });
};
const salonRewardPromotion = (data: any) => {
  console.log("data i send for ", data);
  return axios({
    url: `admin/salon-reward-promotion`,
    method: "PATCH",
    data: data,
  }).then((data) => {
    return data;
  });
};

export const AdminSettingService = {
  newcomerPromotion,
  referralLinkPromotion,
  salonRewardPromotion,
};
