import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { TProjectService } from "../../models/Project";
import SelectSize from "../SelectSize";
import toast from "react-hot-toast";
import CustomDropdown, { Item } from "../reusable/CustomDropdown";
import { useSelector } from "react-redux";
import { ServiceNameState } from "../../redux/slices/serviceNameSlice";
import Service from "../../models/Service";

interface AddingServiceProps {
  onAddService: (project: TProjectService) => void;
  onBack: () => void;
}

export const AddingService: React.FC<AddingServiceProps> = ({
  onAddService,
  onBack,
}) => {
  const toCustomItem = (value: Service[]) => {
    return value.map((item) => {
      return {
        ...item,
        id: item._id as string,
        value: item.name,
      };
    });
  };

  const serviceNameData = useSelector<unknown, ServiceNameState>(
    (state: any) => state.serviceNames
  ).serviceNames;

  const [serviceDetailsNames, setServiceDetailsNames] = useState<any[]>([]);

  const [serviceNameItem, setServiceNameItem] = useState("");

  const [data, setData] = useState<{
    name: string | null;
    price: number;
    size: string[];
    descriptionService?: string | null;
  }>({
    name: null,
    price: 0,
    size: [],
    descriptionService: null,
  });

  const handleAdd = () => {
    toast.remove();
    if (!canAdd(data)) {
      toast.error("Please fill the form correctly");
    }
    onAddService(data as TProjectService);
  };

  const canAdd = (values: typeof data) => {
    return (
      typeof values.name === "string" &&
      values.name.length > 2 &&
      values.price > 10 &&
      values.size.length > 0
    );
  };

  useEffect(() => {
    if (serviceNameData) {
      const detailsNames: any[] = [];
      serviceNameData.forEach((name: any) => {
        if (name?.servicesDetails) {
          detailsNames.push(...name.servicesDetails);
        }
      });
      setServiceDetailsNames(detailsNames);
    }
  }, [serviceNameData]);

  console.log("the service name data", serviceNameData);

  return (
    <div
      style={{ zIndex: 2 }}
      className="bg-white w-100 position-absolute h-100"
    >
      <div className="container h-100 d-flex flex-column gap-4">
        <div className="d-flex gap-2 align-items-center">
          <FontAwesomeIcon
            onClick={onBack}
            className="cursor-pointer"
            icon={faLongArrowLeft}
          />
          <span className="fs-5">Add services </span>
        </div>
        <div className="">
        
          <div className="col-sm mb-3 col-12">
            <label htmlFor="serviceName" className="form-label">
              Service Name
            </label>

            <CustomDropdown
              onChange={(item) => {
                if (typeof item === "string") {
                  setData({ ...data, name: item });
                } else {
                  setData({
                    ...data,
                    name: item.name,
                    descriptionService: item._id,
                  });
                }
              }}
              items={
                serviceNameData === null
                  ? []
                  : toCustomItem(serviceDetailsNames as Service[])
              }
              keyExtractor={(item: Item) => item.id}
              render={(item: Item) => item.name}
              placeholder={"Select or type your service Name"}
              name={"name"}
              searchItem={serviceNameItem}
              setSearchItem={setServiceNameItem}
            />
          </div>
          <div className="col-12 mb-3">
            <label htmlFor="servicePrice" className="form-label">
              Proposed Price
            </label>
            <div className="input-group">
              <input
                name="price"
                type="number"
                min={0}
                value={data.price === 0 ? "" : data.price}
                onChange={(e) =>
                  setData({ ...data, price: Number(e.target.value) })
                }
                className="form-control rounded-start"
                placeholder="Proposed price"
                aria-label="Proposed price"
              />
              <span className="input-group-text">$</span>
            </div>
          </div>

          <div className="mb-3">
            <label htmlFor="serviceSize" className="form-label">
              Select Size
            </label>
            <SelectSize
              id="serviceSize"
              onChange={(sizes: string[]) => {
                setData({ ...data, size: sizes });
              }}
              name={"size"}
              onBlur={console.log}
              isProject={true}
              selectedSizes={data.size}
            />
          </div>
        </div>
        <div className="mt-auto">
          <button
            disabled={!canAdd(data)}
            onClick={handleAdd}
            className="modal_next_button fw-bold ms-auto prev d-flex gap-2 my-4 border p-2 px-5 rounded bg-transparent align-items-center"
          >
            Add the service
          </button>
        </div>
      </div>
    </div>
  );
};
