import React, { useEffect } from "react";
import ChatHeader, { ChatHeaderProps } from "./ChatHeader";
import ChatContent from "./ChatContent";
import ChatInput, { ChatInputProps } from "./ChatInput";
import DiscussionLoader from "../loader/DiscussionLoader";
import { ChatHeaderActionProps } from "./ChatHeaderAction";

export type ChatInterfaceProps = {
  selectedDiscussion: any;
  onLoadingDiscussionMessages: boolean;
  handleCloseDiscussion: () => void;
  renderDiscussionListMessages: (discussionId: string) => React.ReactNode;
  renderLatriceDiscussionMessages: (selected: any) => React.ReactNode;
  currentUserIsProfessional: boolean;
} & Partial<
  Pick<
    ChatHeaderActionProps,
    "onMarkAppointmentHasEnded" | "onMarkAppointmentHasStarted"
  >
>;

const ChatInterface: React.FC<
  ChatInterfaceProps &
    Pick<ChatHeaderProps, "onSelectUser"> &
    Pick<ChatInputProps, "onSendMessage">
> = ({
  selectedDiscussion,
  onLoadingDiscussionMessages,
  handleCloseDiscussion,
  renderDiscussionListMessages,
  renderLatriceDiscussionMessages,
  currentUserIsProfessional,
  onSelectUser,
  onSendMessage,
  ...props
}) => {
  useEffect(() => {
    console.log("currentUserIsProfessional", currentUserIsProfessional);
  }, []);

  console.log("selectedDiscussion", selectedDiscussion);

  return (
    <div
      className={`ChatInterface h-100 d-flex flex-column align-items-center ${
        selectedDiscussion ? "" : "justify-content-center"
      }`}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#f6f8fc",
      }}
    >
      {selectedDiscussion !== null ? (
        <>
          <ChatHeader
            currentUserIsProfessional={currentUserIsProfessional}
            selectedDiscussion={selectedDiscussion}
            handleCloseDiscussion={handleCloseDiscussion}
            onSelectUser={onSelectUser}
            onMarkAppointmentHasStarted={props.onMarkAppointmentHasStarted}
            onMarkAppointmentHasEnded={props.onMarkAppointmentHasEnded}
          />
          <ChatContent
            selectedDiscussion={selectedDiscussion}
            onLoadingDiscussionMessages={onLoadingDiscussionMessages}
            renderDiscussionListMessages={renderDiscussionListMessages}
            renderLatriceDiscussionMessages={renderLatriceDiscussionMessages}
          />
          {!selectedDiscussion.meta.system && (
            <ChatInput
              onSendMessage={onSendMessage}
              disabled={onLoadingDiscussionMessages}
            />
          )}
        </>
      ) : (
        <DiscussionLoader title={onLoadingDiscussionMessages ? null : ""} />
      )}
    </div>
  );
};

export default ChatInterface;
