import { useEffect, useRef, useState } from "react";
import Button from "../Button";
import AddNewCard from "../AddNewCard";
import AddPaymentAccount, { AccountVAlue } from "../reusable/AddPaymentAccount";
import Payment from "../../models/Payment";
import {
  getAllCardPaymentMethod,
  PaymentMethodState,
} from "../../redux/slices/paymentMethodSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { IPaymentMethod } from "../../models/PaymentMethod";
import CardPreview from "./CardPreview";
import { log } from "console";

const PaymentMethod = () => {
  // const submitBtnRef = useRef();
  const [cardCredit, setCardCredit] = useState<IPaymentMethod[] | null>(null);
  const [refetchCard, setRefetchCard] = useState(false);
  // const [cardImage, setCardImage] = useState<string>("");
  const cardPaymentMethod = useSelector<unknown, PaymentMethodState>(
    (state: any) => state.paymentMethod
  ).paymentMethod;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCardPaymentMethod() as unknown as AnyAction);
  }, [refetchCard, dispatch]);

  const handleRefetchCard = () => {
    console.log("i refetch my data");
    setRefetchCard(!refetchCard);
  };

  useEffect(() => {
    if (cardPaymentMethod) setCardCredit(cardPaymentMethod);
  });

  const [formValues, setFormValues] = useState({
    email: { value: null, error: null },
    username: { value: null, error: null },
    phone: { value: null, error: null },
    birthday: { value: "1998-03-23", error: null },
    typeOfAccount: { value: null, error: null },
    salonName: { value: null, error: null },
    acceptCustomOrders: { value: null, error: null },
    minKidsAgeFour: { value: null, error: null },
  });

  let payment: Payment = {
    name: "",
    cardNumber: 0,
  };

  const [show, setShow] = useState(false);
  const [isOpen, setISOpen] = useState(false);
  const [index, setIndex] = useState("");

  return (
    <>
      <div className="AccountSetting">
        <h3 className="text-primary mb-3">Payment Methods</h3>
        <div className="d-flex gap-4 align-items-center flex-column ">
        
          <>

            {cardCredit && (

              
              <>
            {cardCredit?.map((card, index) => (
              <div className="" key={index}>
                {card.card
                  ?.map((card) => (
                    <>
                      <div className="d-flex justify-content-between gap-4 mb-3 w-100 p-2 px-4 align-content-center text-white rounded cardPreview">
                        <CardPreview
                          name={card.cardHolderName}
                          cardNumber={card.cardNumber}
                          expiryDate={card.expirationDate}
                          onEditClick={() => console.log("Hello")}
                          />
                      </div>
                    </>
                  ))
                  .slice(0, 3)}
              </div>
            ))}
            </>
          )}

            <div className="w-100 d-flex flex-row gap-4 justify-content-between align-items-center">
              <label htmlFor="payement " className="text-primary text-nowrap">
                Default Payment
              </label>
              <select id="payement" className="form-select">
                <option value="0">Select default Payment</option>
                <option value="1">Card</option>
                <option value="2">Bank Link</option>
                <option value="3">Paypal</option>
                <option value="4">GooglePay</option>
              </select>
            </div>
          </>
          <Button
            onClick={() => setShow(!show)}
            className="w-100 br-6"
            content={
              <div className="d-flex justify-content-center align-items-center gap-1">
                <i className="fa fa-plus fa-1x"></i> Add New Card{" "}
              </div>
            }
          />
          {/* <Button
            className="w-100 br-6"
            content={
              <div className="d-flex justify-content-center align-items-center gap-1">
                <i className="fa fa-plus fa-1x"></i> Link Bank Account{" "}
              </div>
            }
          /> */}
          {/* <Button
            className="w-100 br-6"
            onClick={() => {
              setISOpen(true);
              setIndex("paypal");
            }}
            content={
              <div className="d-flex justify-content-center align-items-center gap-1">
                <i className="fa fa-plus fa-1x"></i> Paypal Account{" "}
              </div>
            }
          />
          <Button
            onClick={() => {
              setISOpen(true);
              setIndex("googlePay");
            }}
            className="w-100 br-6"
            content={
              <div className="d-flex justify-content-center align-items-center gap-1">
                <i className="fa fa-plus fa-1x"></i> Google Pay{" "}
              </div>
            }
          /> */}
        </div>
      </div>
      <AddNewCard Show={show} HandleShow={() => setShow(!show)} />
      <AddPaymentAccount
        show={isOpen}
        handleShow={() => setISOpen(!isOpen)}
        value={index as AccountVAlue}
        OnSave={console.log}
        Payment={payment}
      />
    </>
  );
};

export default PaymentMethod;
