import MainNav from "../components/navigation/MainNav";
import "./../styles/BusinessProfilePage.css";
import AuthLayout from "../components/auth/AuthLayout";
import { WorkingHours } from "../components/availability/WorkingHours";
import { useDispatch, useSelector } from "react-redux";
import { ChairsState, getChairs } from "../redux/slices/chairSlice";
import React, { useEffect, useState } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { useSalon } from "../context/SalonContext";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import AddChairModal from "../components/adminDashboard/modal/AddChairModal";
import { IChair } from "../models/Chair";
import moment from "moment";
// import { editChair, getChairs  } from "../services/ChairService";

export type USATimezone =
  | "US/Eastern"
  | "US/Central"
  | "US/Mountain"
  | "US/Pacific"
  | "US/Alaska"
  | "US/Hawaii"
  | "US/Samoa";

export const USATimezones = [
  { value: "US/Pacific", label: "US/Pacific (GMT-8)" },
  { value: "US/Mountain", label: "US/Mountain (GMT-7)" },
  { value: "US/Central", label: "US/Central (GMT-6)" },
  { value: "US/Eastern", label: "US/Eastern (GMT-5)" },
  { value: "US/Alaska", label: "US/Alaska (GMT-9)" },
  { value: "US/Hawaii", label: "US/Hawaii (GMT-10)" },
  { value: "US/Arizona", label: "US/Arizona (GMT-7)" },
  { value: "US/Indiana-Starke", label: "US/Indiana-Starke (GMT-6)" },
  { value: "Africa/Douala", label: "Africa/Douala (GMT+1)" },
  {
    value: "US/Indiana/Indianapolis",
    label: "US/Indiana/Indianapolis (GMT-5)",
  },
  { value: "US/Michigan", label: "US/Michigan (GMT-5)" },
];

const ManageAvailabilityPage = () => {
  const chairs = useSelector<unknown, ChairsState>(
    (state: any) => state.chairs
  ).chairs;
  const dispatch = useDispatch();

  const salon = useSalon();
  const navigate = useNavigate();
  const [allChairs, setAllChairs] = useState<IChair[] | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState<moment.Moment>(moment());
  const handleWeekChange = async (startDate: moment.Moment) => {
    setDate(startDate);
    return dispatch(getChairs(startDate) as unknown as AnyAction).unwrap();
  };

  const handleOpenDeepCreateModal = () => {
    setShowModal(true);
  };

  const handleAddNewChair = () => {
    handleOpenDeepCreateModal();
    // navigate(
    //   `/setup-business/manage-chairs/${salon?.salon?._id}?action=create`
    // );
  };

  // useEffect(() => {
  //   setAllChairs(chairs);
  // }, [chairs]);

  useEffect(() => {
    if (salon && salon.salon?._id) {
      dispatch(getChairs(date) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log("res.data all chair", res.data);
          setAllChairs(res.data);
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  }, [allChairs]);

  useEffect(() => {
    console.log("handleWeekChange", handleWeekChange);
    console.log("chairs", chairs);
    console.log("all chairs",allChairs)
  }, []);

  return (
    <AuthLayout>
      <MainNav navType={"connectedNav"} isSearch={false} />
      <div className="container">
        <WorkingHours
          onWeekChange={handleWeekChange}
          onAddNewChair={handleAddNewChair}
          chairs={chairs}
        />
      </div>
      <div className="row p-0 m-0 BusinessProfilePage">
        <div className="row p-0 m-0 BusinessProfilePage">
          <div className="  d-flex justify-content-center align-items-center p-lg-0"></div>
        </div>
      </div>
      <AddChairModal
        chairs={chairs ?? []}
        setChairs={setAllChairs}
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      />
      <Footer />
    </AuthLayout>
  );
};

export default ManageAvailabilityPage;
