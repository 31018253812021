import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import styles from "../styles/AppointmentGraph.module.css";
import React, {
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { getDatesRange } from "../../utils/utils";
import { IAppointment } from "../../models/Appointment";
import moment from "moment";
import { IProject } from "../../models/Project";
import { GraphAppointmentRender } from "./graph/GraphAppointmentRender";
import { GraphProjectRender, GraphProjectRenderProps } from "./graph/GraphProjectRender";

export type DatePeriod = "day" | "week" | "month";


export interface AppointmentGraphOptions {
  maxWidth: string | number;
  maxHeight?: string | number;
  startAt: moment.Moment;
  appointments?: IAppointment[];
  projects?: IProject[];
  appointmentRender: (appointment: IAppointment) => React.JSX.Element;
  projectRender?: (project: IProject) => React.JSX.Element;
}


/**
 * Show a 2D graph based on appointments options
 *
 * this component will be used to show in two dimensions
 * appointments according to her time.
 *
 * x = appointment date (ex: 10 May)
 *
 * y = appointment start date (ex: 06:30 AM)
 *
 * @author Mentalists
 * @param options
 * @returns
 */
const AppointmentGraph: React.FC<AppointmentGraphOptions> = ({
  startAt,
  appointments,
  projects,
  appointmentRender,
  projectRender,
  maxWidth,
  maxHeight,
}) => {
  const options = { startAt, appointments, projects, appointmentRender, projectRender };

  const [activeRangeDate, setActiveRangeDate] = useState<moment.Moment>(options.startAt);
  const [activeDates, setActiveDates] = useState<moment.Moment[]>([]);
  const [activeHours, setActiveHours] = useState<string[]>([]);
  const [datePeriod, setDatePeriod] = useState<DatePeriod>("week");
  const [rangeDates, setRangeDates] = useState<moment.Moment[]>([]);


  const handlePrevPeriod = () => {
    const daysToAdd = datePeriod === "week" ? -7 : -30;
    const currentDate = moment(activeRangeDate).add(daysToAdd, 'days');
    setActiveRangeDate(currentDate);
  };

  const handleNextPeriod = () => {
    const daysToAdd = datePeriod === "week" ? 7 : 30;
    const currentDate = moment(activeRangeDate).add(daysToAdd, 'days');
    setActiveRangeDate(currentDate);
  };

  const isActiveDate = (date: moment.Moment) => {
    return activeDates.indexOf(date) !== -1;
  };

  const isActiveHour = (hour: string) => {
    return activeHours.indexOf(hour) !== -1;
  };

  const filterData = <T extends { day: string }>(data: T[], dates: moment.Moment[]): T[] => {
    return data.filter((item) => {
      const itemDate = moment(item.day, 'YYYY-MM-DD');
      return dates.some((date) => date.isSame(itemDate, 'day'));
    });
  };

  useEffect(() => {
    setActiveRangeDate(options.startAt);
  }, [options.startAt])

  useEffect(() => {
    const dates = getDatesRange(activeRangeDate, datePeriod);
    setRangeDates(dates);

    // Extract active Date & Hours for user highlighting
    const _dates: SetStateAction<moment.Moment[]> = [];
    const _hours: SetStateAction<string[]> = [];
    dates.forEach((date) => {
      options.appointments?.forEach((appointment) => {
        let startedAt = moment(`${appointment.day} ${appointment.startTime}`, 'YYYY-MM-DD HH:mm');
        let endedAt = moment(`${appointment.day} ${appointment.endTime}`, 'YYYY-MM-DD HH:mm');

        if (startedAt.isSame(date, 'day')) {
          _dates.push(date);

          let startHour = Number(startedAt.format("h"));
          let endHour = Number(endedAt.format("h"));

          for (let i = 0; i < endHour - startHour + 1; i++) {
            const currentDate = moment(startedAt).add(i, 'hours');

            const meridian = currentDate.format("a");
            const hour = Number(currentDate.format("h"));

            _hours.push(`${hour} ${meridian?.toUpperCase()}`);
          }
        }
      });
    });

    setActiveDates(_dates);
    setActiveHours(_hours);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRangeDate, datePeriod, options.appointments, options.startAt]);

  useEffect(() => {

  }, [])


  return (
    <div className={`${styles.AppointmentGraph}`} style={{ maxHeight, maxWidth }}>
      <div
        className={`${styles["AppointmentGraph__Header"]} d-flex flex-column`}
      >
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div className="fw-bold fs-5 text-black">
            {activeRangeDate.format("MMM yyyy")}
          </div>
          <div
            className={`${styles["AppointmentGraph__PeriodSelector"]} d-flex align-items-center gap-2`}
          >
            <span>Show 1</span>
            <select
              className="mb-1"
              defaultValue={datePeriod}
              onChange={(e) => setDatePeriod(e.target.value as DatePeriod)}
            >
              <option value={"week"}>Week</option>
              <option value={"month"}>Month</option>
            </select>
          </div>
          <div
            className={`${styles["AppointmentGraph__DateSelector"]} d-flex border align-items-center gap-2`}
          >
            <div className={"d-flex gap-1"}>
              <button onClick={handlePrevPeriod}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button onClick={handleNextPeriod}>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div
          style={{
            gridTemplateColumns: `repeat(${rangeDates.length + 1}, 1fr)`,
          }}
          className={`${styles["AppointmentGraph__Grid"]} mt-2`}
        >
          {rangeDates.map((date, key) => {
            let currentDate = date;
            let active = isActiveDate(currentDate)
              ? styles["AppointmentGraph__Grid__Abscissa--active"]
              : "";

            return (
              <div
                data-tooltip-id="abscissaTooltip"
                data-tooltip-place="top"
                data-tooltip-content={currentDate.format("DD MMMM yyyy")}
                style={{ gridColumn: key + 2 }}
                className={`${styles["AppointmentGraph__Grid__Abscissa"]} ${active}
                                    d-flex flex-column align-items-center gap-0 justify-content-center`}
                key={`${currentDate.format("YYYY-MM-DD")}-${key}`}
              >
                <span>{currentDate.format("DD")}</span>
                <span>{currentDate.format("ddd")}</span>
              </div>
            );
          })}
          <Tooltip id="abscissaTooltip" />
          {Array(24)
            .fill(0)
            .map((_, key) => {
              const label = key < 12 ? `${key + 1} AM` : `${key - 11} PM`;
              let active = isActiveHour(label)
                ? styles["AppointmentGraph__Grid__Order--active"]
                : "";
              return (
                <div
                  className={`${styles["AppointmentGraph__Grid__Order"]} ${active}`}
                  key={label}
                  style={{ grid: `${key + 1} 1` }}
                >
                  {label}
                </div>
              );
            })}

          {/* Render All appointments on the Graph */}

          {
            options.appointments &&
            filterData(options.appointments, rangeDates).map((value) => {
              return <GraphAppointmentRender
                key={value._id}
                appointment={value}
                interval={rangeDates}
                period={datePeriod}
                styles={styles}
                render={options.appointmentRender}
              />
            })

          }

          {
            options.projects &&
            filterData(options.projects, rangeDates).map((value) => {
              return <GraphProjectRender
                key={value._id}
                project={value}
                interval={rangeDates}
                period={datePeriod}
                styles={styles}
                render={projectRender as GraphProjectRenderProps['render']}
              />
            })}
        </div>
      </div>
    </div>
  );
};

export { AppointmentGraph };