import React from 'react'


export type TMenuItem = {
    id: string
    label: string
}

export type TMenuProps = {
    menuItems: TMenuItem[];
    styles: any
    activeMenu: TMenuItem,
    onChangeActiveMenu: (menu: TMenuItem) => void
}

export const Menu: React.FC<TMenuProps> = ({ styles, menuItems, onChangeActiveMenu, activeMenu }) => {


    return (
        <div className="d-flex">
            <div
                className={`${styles["PostProjectModal__Header"]} d-flex justify-content-between user-select-none gap-5 align-items-center`}
            >
                {menuItems.map((menu: TMenuItem) => {
                    return <div
                        key={menu.id}
                        onClick={(e) => onChangeActiveMenu(menu)}
                        className={`${styles["PostProjectModal__Header__Item"]} ${activeMenu.id === menu.id
                            ? styles["PostProjectModal__Header__Item--active"]
                            : ""
                            }`}
                    >
                        {menu.label}
                    </div>
                })}
            </div>
        </div>
    )
}