import { RefObject, useCallback, useEffect, useState } from "react";


interface UseGraphWidthProps {
    verticalMenuRef: RefObject<HTMLElement>;
    modalRef: RefObject<HTMLElement>;

}


export const useGraphWidth = ({ verticalMenuRef, modalRef }: UseGraphWidthProps) => {

    const [graphWidth, setGraphWidth] = useState("70vw");

    const calculateWidth = useCallback(() => {
        if (verticalMenuRef.current && modalRef.current) {
            const menuWidth = verticalMenuRef.current.offsetWidth;
            const menuMargin = parseFloat(
                getComputedStyle(verticalMenuRef.current).marginRight
            );

            const modalWidth = modalRef.current.offsetWidth;
            const modalMargin = parseFloat(
                getComputedStyle(modalRef.current).marginRight
            );

            // Calculate the total width including content, padding, border, and margin
            const totalWidth = menuWidth + menuMargin + modalWidth + modalMargin;

            // Set the graph width
            setGraphWidth(`calc(100vw - ${totalWidth}px - 2em)`);
        }
    }, [verticalMenuRef, modalRef]);


    useEffect(() => {
        // Recalculate width when the window is resized
        window.addEventListener("resize", calculateWidth);

        // Initial calculation when the component mounts
        calculateWidth();

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", calculateWidth);
        };
    }, [calculateWidth]);

    return graphWidth;
}