import { faAsterisk, faBriefcase, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import '../../styles/SelectProfessionalStep.css'
import clsx from 'clsx'
import { TSalonAccountType } from '../../../utils/types'


interface SelectProfessionalStepProps {
    selected: TSalonAccountType | undefined,
    onSelect: (accountType: TSalonAccountType) => void,
}


export const SelectProfessionalStep: React.FC<SelectProfessionalStepProps> = (
    { onSelect, selected }
) => {

    const [accountType, setAccountType] = useState<TSalonAccountType | undefined>(selected)

    const className = (type: typeof accountType) => {
        return clsx({
            "border professional-selector align-items-center cursor-pointer rounded px-4 w-100 py-4 pt-5 d-flex flex-column gap-4": true,
            "active": type === accountType
        })
    }


    React.useEffect(() => {
        if (accountType) {
            onSelect(accountType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountType])



    return (
        <div className='w-100'>
            <div className="d-flex justify-content-between gap-4 mt-5">
                <div className={className('independent')} onClick={() => setAccountType('independent')}>
                    <FontAwesomeIcon icon={faUser} size='3x' />
                    <h6>Independent</h6>
                </div>
                <div className={className('business_owner')} onClick={() => setAccountType('business_owner')}>
                    <FontAwesomeIcon icon={faBriefcase} size='3x' />
                    <h6>Salon</h6>
                </div>
                <div className={className('both')} onClick={() => setAccountType('both')}>
                    <FontAwesomeIcon icon={faAsterisk} size='3x' />
                    <h6>Both</h6>
                </div>
            </div>
        </div>
    )
}
