import * as Yup from "yup";


export const ContactUsInitialValues = {
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    message:''
}

export const contactUsValidation = Yup.object({
    firstName:Yup.string().required(),
    lastName:Yup.string().required(),
    email:Yup.string().email(),
    phone:Yup.string(),
    message:Yup.string()


})