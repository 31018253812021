import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ISalon } from "../../models/SalonX";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChair, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Stars } from "../Stars";
import Button from "../Button";
import { ModalLoader } from "../modal/commons/ModalLoader";
import { useSalon } from "../../context/SalonContext";
import SwitchSalonItem from "./SwitchSalonItem";

type SwitchSalonModalProps = {
  show: boolean;
  salons: ISalon[]
  onClose: () => void
};

const SwitchSalonModal: React.FC<SwitchSalonModalProps> = ({
  show,
  onClose,
  salons
}) => {

  const [q, setQ] = useState("");
  const [selectedSalon, setSelectedSalon] = useState<ISalon | null>(null);
  const [loading, setLoading] = useState(false);
  const { salon, setSalon } = useSalon();

  const handleSwitchSalon = () => {
    if (selectedSalon) {
      setLoading(true);
      localStorage.setItem('salonId', selectedSalon._id)
      setSalon(selectedSalon);
      window.location.reload();
    }
  }

  const filteredSalons = salons.filter((salon: ISalon) => {
    if (q.length > 0) {
      return salon.name?.toLocaleLowerCase().includes(q.trim().toLocaleLowerCase())
    }
    return true
  });

  React.useEffect(() => {
    setSelectedSalon(salon)
  }, [salon])


  return (
    <Modal
      show={show}
      centered
      onHide={onClose}
      size="lg"
      backdrop="static"
    >
      <Modal.Header title="Switch the current salon" closeButton={!loading}>
        <h5>Switch the current salon</h5>
      </Modal.Header>
      <Modal.Body>
        {loading && <ModalLoader label={<span className="fw-bold">we are setting the selected salon as current, please wait...</span>} />}
        <div className="mx-3 mb-4">
          <div
            className="input-group d-flex m-0 mb-4 p-0 w-100 border mb-2 align-items-center"
            aria-autocomplete="none"
          >
            <button
              style={{ backgroundColor: "white", height: "45px" }}
              className="btn border-0"
              type="button"
            >
              <i className="fa-solid fa-search"></i>
            </button>
            <input
              value={q ?? ""}
              onChange={(e) => setQ(e.target.value)}
              placeholder="Search your salon..."
              type="text"
              className="form-control border-0  no-decoration fw-500"
            />
          </div>

          <div
            className="switch-salon-items-container mt-5 d-flex flex-column gap-3">
            {
              filteredSalons.map((salon: ISalon) => {
                return <SwitchSalonItem
                  key={salon._id}
                  salon={salon}
                  selected={selectedSalon?._id === salon._id}
                  onClick={setSelectedSalon} />
              })

            }
            {filteredSalons.length === 0 &&
              <div style={{ userSelect: "none" }} className="d-flex flex-column justify-content-center align-items-center gap-4">
                <FontAwesomeIcon style={{ color: "var(--textPrimaryColor)" }} icon={faChair} size="5x" className="text-muted" />
                <div className="text-muted">
                  No salon found !
                </div>
              </div>
            }
          </div>

          <div className=" mt-5">
            <Button
              onClick={handleSwitchSalon}
              style={{ height: "45px" }}
              disabled={!selectedSalon || loading || selectedSalon._id === salon?._id}
              className="set-as-current-btn primary w-100 br-6" content={"Set as current"} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(SwitchSalonModal);