import Button from "./Button";
import "../styles/Note.css";
import slugify from "slugify";
import { useNavigate } from "react-router-dom";
import { Stars } from "./Stars";

interface NoteProps {
  stars: number;
  profile?: string;
  name?: string;
  id: string;
}

const Note: React.FC<NoteProps> = ({ stars, profile, name, id }) => {
  const navigate = useNavigate();
  const slug = slugify(name + "-" + id);

  return (
    <div className="Review" key={id}>
      <div className="d-flex justify-content-between gap-3 review_container">
        <div className="d-flex justify-content-between align-items-center gap-5 bg-white p-3 br-6">
          <div className="d-flex justify-content-start gap-3">
            <div className="profilePictureNote shadow">
              <img
                src={
                  profile !== undefined
                    ? `${process.env.REACT_APP_SOCKET_BASE_URL}/${profile}`
                    : require("../assets/images/profile.jpg")
                }
                alt="profile"
              />
            </div>
            <div>
              <h5 className="fw-500">{name}</h5>
              <div className="mt-1 d-flex justify-content-center align-items-center">
                <div className="starsContainer">
                  <Stars canUpdate={false} stars={stars} notice={stars} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <span className="small text-muted">Edit</span>
          </div>
        </div>

        <div className="d-flex review_btn">
          <Button
            className="br-6"
            mode={"outlined"}
            content={<span className="mx-1">Tip</span>}
            onClick={()=>{navigate(`/tip-settings`)}}
          />
          <Button
            className="br-6"
            mode={"primary"}
            content={<span className="mx-1">Book</span>}
            onClick={() => {
              navigate(`/see-professional-account/${slug}`);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Note;
