import React, { useEffect, useRef, useState } from "react";
import Button from "../Button";
import Service, { IService } from "../../models/Service";
import { formatUSPrice, formatTime } from "../../utils/utils";
import { ServiceListSkeleton } from "../../skeletons/ServiceListSkeleton";
import "../../styles/ServicePromotionPage.css";

interface ServicePromotionListProps {
  services?: Service[] | null;
  searchable?: boolean;
  scrollable?: boolean;
  show?: boolean;
  onseclectable?: boolean;
  onSelect?: (services: Service[]) => void;
}

const ServicePromotionsList: React.FC<ServicePromotionListProps> = ({
  services,
  onSelect,
  searchable = true,
  scrollable = true,
  onseclectable = true,
  show = false,
}) => {
  const [q, setQ] = useState<string | null>(null);
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);

  const inputCheckRef = useRef<HTMLInputElement>(null);

  const filteredServices =
    services &&
    services.filter((service) =>
      q === null
        ? true
        : service.service_detail?.name.toLowerCase().includes(q.toLowerCase())
    );

  const hightlightServiceName = (name: string, search: string) => {
    const parts = name.split(new RegExp(`(${search})`, "gi"));
    return parts.map((part, index) => {
      if (part.toLowerCase() === search.toLowerCase()) {
        return (
          <span key={index} className="text-white bg-primary">
            {part}
          </span>
        );
      } else {
        return part;
      }
    });
  };
  const handleCheck = () => {
    if (inputCheckRef.current) {
      setIsCheck(inputCheckRef.current.checked);
    }
  };

  const handleSelectService = (service: Service) => {
    setSelectedServices((prevSelected) =>
      prevSelected.some((s) => s._id === service._id)
        ? prevSelected.filter((s) => s._id !== service._id)
        : [...prevSelected, service]
    );
    //handleCheck();
  };

  const handleSelectAll = () => {
    if (!filteredServices?.length) {
      return;
    }
    const nonPromotedServices = filteredServices.filter(
      (service) => !(service.promote && service.promote.isPromote)
    );

    if (selectedServices.length === nonPromotedServices.length) {
      setSelectedServices([]);
    } else {
      setSelectedServices(nonPromotedServices);
    }
  };

  return (
    <>
      {searchable && (
        <div className="d-flex justify-content-between p-0 m-0 align-items-center">
          <h5>{"             "}</h5>
          <div
            className="input-group d-flex m-0 mb-2 align-items-center w-50"
            aria-autocomplete="none"
          >
            {/* <div
            className="w-100 bg-body-secondary"
            style={{ height: 250, objectFit: "cover", width: "100%" }}
          >
            <img
              src={require("../assets/images/banner-promotion.png")}
              alt="banner-promotion"
              className="w-100 h-100"
            />
          </div> */}
            <button
              style={{
                backgroundColor: "white",
                height: "45px",
                zIndex: "1",
              }}
              className="btn border-0"
              type="button"
            >
              <i className="fa-solid fa-search"></i>
            </button>
            <input
              value={q ?? ""}
              onChange={(e) => setQ(e.target.value)}
              placeholder="Search for service..."
              type="text"
              className="form-control border-0 no-decoration fw-500"
            />
          </div>
        </div>
      )}

      <div
        className={`d-flex flex-column mt-2 see-pro-service-list ${
          scrollable && "scrollable"
        }`}
        //style={{ maxWidth: 450 }}
      >
        {!services && <ServiceListSkeleton />}
        {services && services.length > 0 && (
          <>
            <div className="d-flex justify-content-end ">
              <Button
                onClick={handleSelectAll}
                className="br-6 btn-checkAll"
                type="button"
                mode="outlined"
                content="Select All"
              />
            </div>
            {filteredServices?.map((service, index) => (
              <div
                key={index}
                className={`d-flex justify-content-between align-items-center w-100 mb-2 mt-2 position-relative p-2 rounded-4 checked-box ${
                  isCheck ? "box-check" : ""
                }`}
              >
                <div className="d-flex align-items-center ">
                  {service.promote && service.promote.isPromote === true ? (
                    <></>
                  ) : (
                    <input
                      //ref={inputCheckRef}
                      type="checkbox"
                      checked={selectedServices.some(
                        (s) => s._id === service._id
                      )}
                      disabled={service.promote?.isPromote === true}
                      onChange={() => handleSelectService(service)}
                      className="p-4"
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        width: 40,
                        height: 40,
                      }}
                    />
                  )}
                  <div className="d-flex flex-column ml-2 relative position-relative">
                    <h5 className="text-primary">
                      {hightlightServiceName(
                        service.service_detail?.name,
                        q ?? ""
                      )}
                    </h5>
                    <div className="professional_service__description">
                      {service.description}
                    </div>
                    <span className="text-muted">
                      {formatUSPrice(service.price)} for{" "}
                      {formatTime(service.duration as unknown as number)}
                    </span>
                  </div>
                  {service.promote && service.promote.isPromote ? (
                    <span className=" badge-promtion-no">Promoted</span>
                  ) : (
                    <span className="text-success badge-promtion">
                      Not Promoted
                    </span>
                  )}
                </div>
              </div>
            ))}
          </>
        )}
        {services && services.length === 0 && (
          <div
            style={{ minHeight: "50vh" }}
            className="w-100 d-flex justify-content-center align-items-center small text-muted flex-column fs-6"
          >
            <i className="fas fa-search-minus fa-3x mb-2"></i>
            <h4>Oops! No Results Found</h4>
            <span className="text-center">
              We couldn't find anything based on your search.
            </span>
          </div>
        )}
        {services &&
          services.length !== 0 &&
          filteredServices?.length === 0 && (
            <div
              className={`d-flex user-select-none text-muted gap-3 align-items-center justify-content-center mt-5`}
            >
              <i className="fas fa-search-minus fa-3x mb-2"></i>
              <div className="text-center fs-4">
                No service matches your search. Check your spelling or maybe
                <br />
                you didn't add it to your service list.
              </div>
            </div>
          )}
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button
          onClick={() => onSelect && onSelect(selectedServices)}
          className="br-6"
          type="button"
          content="Add promotion"
        />
      </div>
    </>
  );
};

export default ServicePromotionsList;
