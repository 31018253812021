import React, { CSSProperties, useEffect, useState } from "react";
import { useUser } from "../../hooks/UseUser";
import { Alert, Tooltip } from "react-bootstrap";
import AcceptBookRequest from "../modal/AcceptBookRequest";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import ModalLoader from "../loader/ModalLoader";
import { AnyAction } from "@reduxjs/toolkit";
import {
  acceptRequestAppointment,
  rejectRequestAppointment,
} from "../../redux/slices/memberSlice";
import RejectBookRequest from "../modal/RejectBookRequest";

type AppointmentRequestItemProps = {
  appointmentRequestData: {
    selectedMember: any;
    memberBookData: any;
  };
  key: any;
  handleRefetchMembers: () => void;
  setSelectedMember: (selectedMember: any) => void;
};

const AppointmentRequestItem: React.FC<AppointmentRequestItemProps> = ({
  appointmentRequestData,
  key,
  handleRefetchMembers,
  setSelectedMember,
}) => {
  const user = useUser();
  const dispatch = useDispatch();
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log("appointmentRequest", appointmentRequestData);

  useEffect(() => {
    console.log("appointmentRequest", appointmentRequestData);
  }, []);

  const handleAcceptBookRequest = () => {
    setLoading(true);

    const bookData = { appointmentRequestData, key };

    dispatch(acceptRequestAppointment(bookData) as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        handleRefetchMembers();
        setSelectedMember(res.data);
      })
      .catch((err: any) => {
        const errors = err.errors;
        if (err.status) {
          if (err.status === 500) {
            return toast.error(errors.message, {
              duration: 7000,
            });
          } else {
            if (errors.code === "INSUFFICIENT_BALANCE") {
              return toast.error(
                "Insufficient Balance! Please add funds to your account to book the selected services.",
                {
                  duration: 7000,
                }
              );
            }
            return toast.error(errors.message, {
              duration: 7000,
            });
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRejectBookRequest = () => {
    setLoading(true);

    const bookData = { appointmentRequestData, key };

    dispatch(rejectRequestAppointment(bookData) as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        toast.success("We have successful rejected the request");
        handleRefetchMembers();
        setSelectedMember(res.data);
      })
      .catch((err: any) => {
        const errors = err.errors;
        if (err.status) {
          if (err.status === 500) {
            return toast.error(errors.message, {
              duration: 7000,
            });
          } else {
            if (errors.code === "INSUFFICIENT_BALANCE") {
              return toast.error(
                "Insufficient Balance! Please add funds to your account to book the selected services.",
                {
                  duration: 7000,
                }
              );
            }
            return toast.error(errors.message, {
              duration: 7000,
            });
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      key={key}
      style={{
        width: "100%",
        // height: "m",
        padding: "15px",
        overflowX: "hidden",
        // backgroundColor: "yellow",
        border: "2px solid grey",
      }}
    >
      {loading && <ModalLoader />}

      <div className="rce-mbox-body">
        <div className="rce-mbox-forward rce-mbox-forward-right"></div>
        <div className="d-flex justify-content-between rce-mbox-title rce-mbox-title--clear">
          <span className="text-uppercase">
            {user?._id === appointmentRequestData.selectedMember.userId._id
              ? "Here are the details of the appointment sent to the member"
              : `${appointmentRequestData.selectedMember.userId.first_name} wants you to make an appointment for : `}
          </span>
          <Tooltip id="pending-custom-appointment-tooltip" />
          <div style={{ textDecoration: "none !important" } as CSSProperties}>
            {/* <img
              style={{ width: "100px" }}
              alt=""
              className="img-fluid rounded"
              src={
                `${
                  user?._id === data.userId._id
                    ? data.userMemberId.profile
                    : data.userId.profile
                }` ??
                "https://cdn.pixabay.com/photo/2018/08/23/22/29/girl-3626901_960_720.jpg"
              }
            /> */}
          </div>
        </div>
        <div className="rce-mbox-text left d-flex flex-column gap-1">
          <div className="d-flex align-items-center">
            <h6 className="text-underline">
              {user?._id === appointmentRequestData.selectedMember.userId._id
                ? appointmentRequestData.memberBookData.day
                : appointmentRequestData.memberBookData.day}
            </h6>
          </div>
          <div className="d-flex gap-1 align-items-center w-100 fw-500 mb-2">
            <div className="d-flex align-items-center gap-1">
              <span>
                {user?._id === appointmentRequestData.selectedMember.userId._id
                  ? appointmentRequestData.memberBookData.startTime
                  : appointmentRequestData.memberBookData.startTime}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        {user?._id === appointmentRequestData.selectedMember.userId._id ? (
          <Alert className="alert-info" key={"info"} variant={"info"}>
            <i className="fa fa-triangle-exclamation fa-0.4x"></i>
            We are waiting for {
              appointmentRequestData.selectedMember.userName
            }{" "}
            to make the payment for your
          </Alert>
        ) : (
          <div className="d-flex justify-content-start align-items-end">
            <button
              onClick={() => setOpenAcceptModal(true)}
              className={`LatricePendingAppointment__button accept-button`}
            >
              <i className="fas fa-check"></i> Accept
            </button>
            <button
              onClick={() => setOpenRejectModal(true)}
              className={`LatricePendingAppointment__button refuse-button`}
            >
              <i className="fas fa-times"></i> Reject
            </button>
          </div>
        )}
      </div>
      <AcceptBookRequest
        onAccept={handleAcceptBookRequest}
        show={openAcceptModal}
        onClose={() => setOpenAcceptModal(false)}
      />
      <RejectBookRequest
        onReject={handleRejectBookRequest}
        show={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
      />
    </div>
  );
};

export default AppointmentRequestItem;
