import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppointmentGraph } from "../components/appointment/AppointmentGraph";
import MainNav from "../components/navigation/MainNav";
import VerticalMenu from "../components/VerticalMenu";
import "../styles/ClientProfilePage.css";
import styles from "../styles/AppointmentPage.module.css";
import Button from "../components/Button";
import Bookmark from "../components/account/Bookmark";
import Cancellation from "../components/account/Cancellation";
import { AddAppointmentModal } from "../components/appointment/AddAppointmentModal";
import AppointmentItem from "../components/appointment/AppointmentItem";
import AuthLayout from "../components/auth/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  AppointmentState,
  cancelAppointment,
  getAppointments,
} from "../redux/slices/appointmentSlice";
import { AnyAction } from "@reduxjs/toolkit";
import DefaultLoader from "../components/loader/DefaultLoader";
import moment from "moment";
import { IAppointment } from "../models/Appointment";
import {
  AppointmentCancellationState,
  getAppointmentCancellations,
} from "../redux/slices/appointmentCancellationSlice";
import { filterAppointment } from "../utils/utils";
import Dispute from "../components/account/Dispute";
import { ProjectState, getProjects } from "../redux/slices/projectSlice";
import { useGraphWidth } from "../hooks/useGraphWidth";
import { IProject } from "../models/Project";
import ProjectItem from "../components/appointment/ProjectItem";
import Footer from "../components/Footer";

const AppointmentPage = () => {
  const [activeMenu, setActiveMenu] = useState(0);
  const [openAddAppointmentModal, setOpenAddAppointmentModal] = useState(false);
  const [selectedCalendarDate, setSelectedCalendarDate] =
    useState<moment.Moment>(moment());

  const verticalMenuItems = [
    {
      faIcon: "fa-solid fa-calendar-days",
      title: "MY BOOKINGS",
    },
    {
      faIcon: "fa-solid fa-calendar-xmark",
      title: "Cancellation",
    },
    {
      faIcon: "fa-solid fa-bookmark",
      title: "Bookmark",
    },
    {
      faIcon: "fa-solid fa-triangle-exclamation",
      title: "Dispute",
    },
    // {
    //   faIcon: "fa fa-book",
    //   title: "Report  ",
    // },
  ];

  const handleVerticalMenuItemClick = (
    item: any,
    key: SetStateAction<number>
  ) => {
    setActiveMenu(key);
  };

  const verticalMenuRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const appointmentData = filterAppointment(
    useSelector<unknown, AppointmentState>((state: any) => state.appointments)
      .appointments,
    "confirmed"
  );

  const projectsData = useSelector<unknown, ProjectState>(
    (state: any) => state.projects
  ).projects;

  console.log("PROJECT DATA", projectsData);
  console.log("APPOINTMENT DATA", appointmentData);

  const appointmentDataCancelled = filterAppointment(
    useSelector<unknown, AppointmentState>((state: any) => state.appointments)
      .appointments,
    "cancelled"
  );

  const appointmentCancellationData = useSelector<
    unknown,
    AppointmentCancellationState
  >((state: any) => state.appointmentCancellation).appointmentCancellations;

  console.log("appointmentCancellationData", appointmentCancellationData);

  const toLocalTimeZone = useCallback((appointments: IAppointment[] | null) => {
    const timezone = moment.tz.guess();

    return appointments === null
      ? null
      : appointments.map((appointment) => {
          return {
            ...appointment,
            startedAt: moment
              .utc(appointment.startedAt, "HH:mm")
              .tz(timezone)
              .format("HH:mm A"),
            endedAt: moment
              .utc(appointment.endedAt, "HH:mm")
              .tz(timezone)
              .format("HH:mm A"),
            startTime: moment
              .utc(appointment.startTime, "HH:mm")
              .tz(timezone)
              .format("HH:mm A"),
            endTime: moment
              .utc(appointment.endTime, "HH:mm")
              .tz(timezone)
              .format("HH:mm A"),
          };
        });
  }, []);

  const handleChangeCalendarDate = (date: moment.Moment) => {
    setSelectedCalendarDate(date);
  };

  const handleCancelAppointment = (appointment: IAppointment): Promise<any> => {
    return dispatch(
      cancelAppointment(appointment) as unknown as AnyAction
    ).unwrap();
  };

  const graphWidth = useGraphWidth({ verticalMenuRef, modalRef });

  useEffect(() => {
    if (!appointmentData) {
      dispatch(getAppointments("normal") as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log(res);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }

    if (!appointmentCancellationData) {
      dispatch(getAppointmentCancellations() as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log(res);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }

    if (!projectsData) {
      dispatch(getProjects("accepted") as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log(res);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      console.log(projectsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      <MainNav navType={"connectedNav"} isSearch={true} />
      <div className="contain m-0 p-0 p-lg-0">
        <div
          className={`${styles["appointment_page"]} container_grid p-0 m-0 w-100`}
        >
          <div
            ref={verticalMenuRef}
            className={`${styles["verticalMenu__container"]} border-end m-md-1`}
          >
            <VerticalMenu
              active={activeMenu}
              items={verticalMenuItems}
              onItemClick={handleVerticalMenuItemClick}
            />
          </div>
          {activeMenu === 0 && (
            <div className="d-flex w-100">
              <div className="col p-0 m-0 mx-auto">
                <div className="mx-auto w-100 position-relative">
                  <Button
                    disabled={appointmentData === null}
                    onClick={() =>
                      setOpenAddAppointmentModal(!openAddAppointmentModal)
                    }
                    className="br-6 ms-auto mt-2"
                    mode={"primary"}
                    content={<span className="mx-3">Manage</span>}
                  />
                  {appointmentData === null && (
                    <DefaultLoader title="We are fetching your appointments" />
                  )}
                  <AppointmentGraph
                    appointments={toLocalTimeZone(appointmentData) ?? []}
                    appointmentRender={(value) => (
                      <AppointmentItem appointment={value} />
                    )}
                    startAt={selectedCalendarDate}
                    maxWidth={graphWidth}
                    projects={projectsData ?? []}
                    projectRender={(project: IProject) => {
                      return <ProjectItem project={project} />;
                    }}
                  />
                </div>
              </div>
              <div
                ref={modalRef}
                className={`${styles["AddAppointmentModal__container"]} ${
                  !openAddAppointmentModal
                    ? styles["AddAppointmentModal__container--closed"]
                    : ""
                } rounded`}
              >
                <AddAppointmentModal
                  onClose={() =>
                    setOpenAddAppointmentModal(!openAddAppointmentModal)
                  }
                  open={false}
                  appointments={toLocalTimeZone(appointmentData) ?? []}
                  projects={projectsData}
                  onChange={handleChangeCalendarDate}
                  onCancel={handleCancelAppointment}
                />
              </div>
            </div>
          )}

          {/** BOOKMARK */}

          {activeMenu === 2 && (
            <div className={`d-flex p-2 w-100 `}>
              <Bookmark />
            </div>
          )}

          {/** BOOKMARK */}

          {/** CANCELLATION */}

          {activeMenu === 1 && (
            <div className={`d-flex p-2 w-100`}>
              <Cancellation appointments={appointmentCancellationData?.filter((appointment)=>appointment.status!=="pending") ?? []} />
            </div>
          )}

          {activeMenu === 3 && (
            <div className={`d-flex p-2 w-100`}>
              <Dispute
                appointments={(appointmentCancellationData ?? []).filter(
                  (item) =>{
                    console.log("item",item);
                  return  item.status !== "cancelled" && item.newDateProposed != null
                  }
                )}
              />
            </div>
          )}

          {/** CANCELLATION */}
        </div>
      </div>
      <Footer />
    </AuthLayout>
  );
};

export default AppointmentPage;
