import moment from "moment";

interface TimeOptionsOptions {
  interval?: number;
}

const useTimeOptionsEdit = ({ interval = 15 }: TimeOptionsOptions = {}) => {
  const generateOptions = (
    maxTime?: string | null,
    minTime?: string | null,
    shiftData?: any
  ) => {
    const startTime = moment();
    const endTime = moment("11:59 PM", "hh:mm A");
    const data: string[] = [];
    console.log(
      "shiftData",
      // shiftData.day.format("ddd, DD MMM"),
      // shiftData.element.timeOffs
      shiftData.element.timeOffs[parseInt(moment(shiftData.source.day).format("d")) - 1]?.startTime
    );

    console.log("parseInt(moment(shiftData.source.day).format())",parseInt(moment(shiftData.source.day).format("d")))

    let currentTime = startTime
      .clone()
      .startOf("hour")
      .add(Math.round(startTime.minute() / 30) * 30, "minutes");

    while (currentTime.isSameOrBefore(endTime)) {
      data.push(currentTime.format("hh:mm A"));
      currentTime.add(interval, "minutes");
    }
    const filterData = data.filter((time) => {
      const currentTime = moment(time, "hh:mm A");
      // console.log("the currentTime", currentTime);
      return !(
        currentTime.isBetween(
          moment(
            shiftData.element.timeOffs[parseInt(moment(shiftData.source.day).format("d")) - 1]
              ?.startTime,
            "hh:mm A"
          ),
          moment(
            shiftData.element.timeOffs[parseInt(moment(shiftData.source.day).format("d")) - 1]?.endTime,
            "hh:mm A"
          )
        ) ||
        currentTime.isSame(
          moment(
            shiftData.element.timeOffs[parseInt(moment(shiftData.source.day).format("d")) - 1]
              ?.startTime,
            "hh:mm A"
          )
        ) ||
        currentTime.isSame(
          moment(
            shiftData.element.timeOffs[parseInt(moment(shiftData.source.day).format("d")) - 1]?.endTime,
            "hh:mm A"
          )
        )
      );
    });

    console.log("dataTime", data);

    console.log("filteData", filterData);

    const options = filterData.map((item) => {
      const currentTime = moment(item, "hh:mm A");

      if (
        (maxTime &&
          currentTime.isAfter(moment(maxTime, "hh:mm A"), "minute")) ||
        (minTime && currentTime.isBefore(moment(minTime, "hh:mm A"), "minute"))
      ) {
        return null;
      }

      return (
        <option value={item} key={item}>
          {item}
        </option>
      );
    });

    return options.filter((option) => option !== null) as JSX.Element[];
  };

  return { generateOptions };
};

export default useTimeOptionsEdit;
