import React from "react";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";

interface QrCodeProps {
  value: string;
}

function QrCodePage({ value }: QrCodeProps) {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <h3 className="text-primary text-center ">Your profile qr code</h3>
      <h6 className="mb-4">Lorem Psomen aradakem siacillis grrisss</h6>
      <div className="mb-8">
        <QRCode
          value={value}
          size={256}
          viewBox={`0 0 256 256`}
          fgColor="btn-primary"
        />
      </div>
      <div>
        <div></div>
      </div>
    </div>
  );
}

export default QrCodePage;
