import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AppointmentService } from "../../services/AppointmentService";
import { IAppointment } from "../../models/Appointment";
import { acceptAppointmentCancellation } from "./appointmentCancellationSlice";

export interface AppointmentState {
  appointments: IAppointment[] | null;
  errors: any;
}

const initialState: AppointmentState = {
  appointments: null,
  errors: null,
};

export const addAppointment = createAsyncThunk<
  Partial<IAppointment>,
  Partial<IAppointment>
>(
  "appointments/addAppointment",
  async (appointment: Partial<IAppointment>, { rejectWithValue }) => {
    try {
      const res = await AppointmentService.addAppointment(appointment);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const getAppointments = createAsyncThunk<
  Partial<IAppointment>[],
  "normal" | "dashboard"
>(
  "appointments/getAppointments",
  async (mode = "normal", { rejectWithValue }) => {
    try {
      const res = await AppointmentService.getAppointments(mode);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const getLastAAppointment = createAsyncThunk<Partial<IAppointment>[]>(
  "appointments/getLastAppointment",
  async (appointment, { rejectWithValue }) => {
    try {
      const res = await AppointmentService.getLastAAppointment();
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const cancelAppointment = createAsyncThunk<
  Partial<IAppointment>,
  Partial<IAppointment>
>("appointments/cancel", async (appointment, { rejectWithValue }) => {
  try {
    const res = await AppointmentService.cancelAppointment(appointment);
    return res.data;
  } catch (error: any) {
    return rejectWithValue({
      errors: error.response.data.errors,
      status: error.response.status,
    });
  }
});
export const disputeAppointment = createAsyncThunk<
  Partial<IAppointment>,
  { appointment: IAppointment; newDateProposal: string }
>("appointments/dispute", async (data, { rejectWithValue }) => {
  try {
    console.log("data", data);
    const res = await AppointmentService.disputeAppointment(data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue({
      errors: error.response.data.errors,
      status: error.response.status,
    });
  }
});

export const markAppointmentAsStarted = createAsyncThunk<
  Partial<IAppointment>,
  string
>("appointments/mark-as-read", async (appointmentId, { rejectWithValue }) => {
  try {
    const res = await AppointmentService.markAsStarted(appointmentId);
    return res.data;
  } catch (error: any) {
    return rejectWithValue({
      errors: error.response.data.errors,
      status: error.response.status,
    });
  }
});
export const markAppointmentAsEnded = createAsyncThunk<
  Partial<IAppointment>,
  string
>("appointments/mark-as-ended", async (appointmentId, { rejectWithValue }) => {
  try {
    const res = await AppointmentService.markAsEnded(appointmentId);
    return res.data;
  } catch (error: any) {
    return rejectWithValue({
      errors: error.response.data.errors,
      status: error.response.status,
    });
  }
});

const appointmentSlice = createSlice({
  name: "appointments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addAppointment.fulfilled, (state, action) => {
        if (state.appointments) {
          const appointments = state.appointments.slice();
          appointments.push(action.payload as any);
          state.appointments = appointments;
        } else {
          state.appointments = [action.payload as any];
        }
        state.errors = null;
      })
      .addCase(getAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload as any;
        state.errors = null;
      })
      .addCase(cancelAppointment.fulfilled, (state, action) => {
        console.log(action.payload);
        const appointments = state.appointments?.slice().map((appointment) => {
          if (appointment._id === action.payload.appointment) {
            return { ...appointment, status: "cancelled" };
          }
          return appointment;
        });
        state.appointments = appointments as any;
      })
      .addCase(disputeAppointment.fulfilled, (state, action) => {
        console.log(action.payload);
        const appointments = state.appointments?.slice().map((appointment) => {
          if (appointment._id === action.payload.appointment) {
            return { ...appointment, status: "cancelled" };
          }
          return appointment;
        });
        state.appointments = appointments as any;
      })
      .addCase(acceptAppointmentCancellation.fulfilled, (state, action) => {
        if (state.appointments) {
          const appointments = state.appointments
            ?.slice()
            .map((appointment) => {
              if (appointment._id === action.payload._id) {
                return { ...appointment, ...(action.payload as IAppointment) };
              }
              return appointment;
            });
          state.appointments = appointments;
        }
        state.errors = null;
      })
      .addCase(markAppointmentAsStarted.fulfilled, (state, action) => {
        if (state.appointments) {
          const appointments = state.appointments
            ?.slice()
            .map((appointment) => {
              if (appointment._id === action.payload._id) {
                return { ...appointment, ...(action.payload as IAppointment) };
              }
              return appointment;
            });
          state.appointments = appointments;
        }
        state.errors = null;
      })
      .addCase(markAppointmentAsEnded.fulfilled, (state, action) => {
        if (state.appointments) {
          const appointments = state.appointments
            ?.slice()
            .map((appointment) => {
              if (appointment._id === action.payload._id) {
                return { ...appointment, ...(action.payload as IAppointment) };
              }
              return appointment;
            });
          state.appointments = appointments;
        }
        state.errors = null;
      });
  },
});

export default appointmentSlice.reducer;
