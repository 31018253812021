import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './../../styles/ProjectCounter.css';



export type ProjectCounterProps = {
    count?: string | number
}

export const ProjectCounter: React.FC<ProjectCounterProps> = ({ count, ...props }) => {


    return (
        <div className="project-counter user-select-none d-flex gap-2 px-2 justify-content-center align-items-center">
            <span>{count ?? "?"}</span>
            <FontAwesomeIcon icon={faClipboardList} className='mb-1' />
        </div>
    )
}