import axios from "axios";

const getServiceNames = () => {
  return axios
    .get(`services/categories`)
    .then((data) => {
      console.log("All service categorie data " , data)
      return data;
    });
};

export const ServiceNameService = {
  getServiceNames,
};
