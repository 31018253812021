import React, { useState } from "react";
import { Modal } from "react-bootstrap";

type ConfirmMarkAsEndedModal = {
  show: boolean;
  onClose: () => void;
  handleConfirmMarkAsEnded: (data: string) => void;
};

const ConfirmMarkAsEndedModal: React.FC<ConfirmMarkAsEndedModal> = ({
  show,
  onClose,
  handleConfirmMarkAsEnded,
}) => {
  const [inputData, setInputData] = useState("");

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    handleConfirmMarkAsEnded(inputData);
  };
  const handleChangeInput = (e: any) => {
    setInputData(e.target.value);
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      centered
      size="lg"
      onHide={handleClose}
    >
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-1 p-3 justify-content-center align-items-center">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%" }}
          >
            <img
              src={require("../../assets/images/valide.png")}
              alt=""
              style={{ width: "100px" }}
            />
          </div>
          <div className="d-flex flex-column gap-2 p-3 justify-content-center align-items-center">
            <h4 className="fw-500  " style={{ color: "#0f2359" }}>
              Mark this appointment as ended
            </h4>
            <p className=" text-center " style={{ color: "" }}>
              Scan your Qrcode or enter the code we sent to your customer to mark the end of the
              appointment
            </p>
          </div>
          <div className="d-flex flex-column gap-2 p-3 justify-content-center align-items-center">
            {/* <input type="text" onChange={handleChangeInput} /> */}
            <input
              placeholder="Enter code"
              onChange={handleChangeInput}
              value={inputData}
              type="number"
              className="form-control fw-500"
              id="code"
              name="code"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className="d-flex flex-row gap-3 p-3 justify-content-center  align-items-center "
          style={{ width: "100%" }}
        >
          <button
            className=""
            style={{
              padding: "10px",
              paddingLeft: "30px",
              paddingRight: "30px",
              borderRadius: "5px",
              border: "none",
              color: "#5961ea",
              backgroundColor: "#e1e2fc",
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              padding: "10px",
              paddingLeft: "30px",
              paddingRight: "30px",
              borderRadius: "5px",
              border: "none",
              color: "red",
              backgroundColor: "#ffe9e8",
            }}
            onClick={handleConfirm}
          >
            Valide
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmMarkAsEndedModal;
