import moment, { Moment } from 'moment';
import React from 'react';

const useWeekSelector = () => {
    const initialWeekDate = moment();

    const defaultStartDate = initialWeekDate.clone().startOf('week');
    const defaultEndDate = initialWeekDate.clone().endOf('week');

    const [startDate, setStartDate] = React.useState<Moment>(defaultStartDate);
    const [endDate, setEndDate] = React.useState<Moment>(defaultEndDate);

    const handlePrevWeek = () => {
        setStartDate(startDate.clone().subtract(1, 'week'));
        setEndDate(endDate.clone().subtract(1, 'week'));
    };

    const handleNextWeek = () => {
        setStartDate(startDate.clone().add(1, 'week'));
        setEndDate(endDate.clone().add(1, 'week'));
    };

    const resetCurrent = () => {
        setStartDate(moment().startOf('week'));
        setEndDate(moment().endOf('week'));
    };

    const getAllDates = React.useMemo((): Moment[] => {
        return Array(7)
            .fill(0)
            .map((_, key) => startDate.clone().add(key + 1, 'day'));
    }, [startDate]);

    return {
        startDate,
        setStartDate,
        endDate,
        handlePrevWeek,
        handleNextWeek,
        resetCurrent,
        weekDates: getAllDates,
    };
};

export default useWeekSelector;