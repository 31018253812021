import React, { useEffect, useState } from "react";
import { ISalon } from "../../models/SalonX";
import { useUser } from "../../hooks/UseUser";
import { isUserProfessional } from "../../models/User";
import { useSelector } from "react-redux";
import {
  ManagerState,
  selectManagerBySalonId,
  selectManagersWithoutSalon,
} from "../../redux/slices/managerSlice";
import ManageSalon from "./ManageSalon";

type SalonContainerProps = {};

const SalonContainer: React.FC<SalonContainerProps> = ({}) => {
  const [q, setQ] = useState<string | null>(null);
  const [salons, setSalons] = useState<ISalon[] | null>(null);
  const [salonToManage, setSalonToManage] = useState<ISalon | null>(null);
  const user = useUser();
  const managerForSalon = useSelector((state: { managers: ManagerState }) =>
    selectManagerBySalonId(state, salonToManage?._id as string)
  );
  const UserManagers = useSelector(selectManagersWithoutSalon);

  const filtered =
    salons &&
    salons.filter((salon) =>
      q === null
        ? true
        : salon.name && salon.name.toLowerCase().includes(q.toLowerCase())
    );

  useEffect(() => {
    if (isUserProfessional(user)) {
      setSalons(user.salons);
    }
  }, [user]);

  return (
    <div>
      {salons && salons.length === 0 ? (
        <div>Lorem ipsum dolorsit amet</div>
      ) : salonToManage === null ? (
        <div>
          <div
            className=" px-0 input-group d-flex m-0 col-md-6 col-12 mb-4 align-items-center"
            aria-autocomplete="none"
          >
            <button
              style={{
                backgroundColor: "white",
                height: "45px",
                zIndex: "1",
              }}
              className="btn border-0"
              type="button"
            >
              <i className="fa-solid fa-search"></i>
            </button>
            <input
              value={q ?? ""}
              onChange={(e) => setQ(e.target.value)}
              placeholder="Search"
              type="text"
              className="form-control border-0  no-decoration fw-500"
            />
          </div>
          <div className="grid-container gap-3">
            {filtered &&
              filtered.map((salon, index) => (
                <div
                  key={index}
                  className="salon-container bg-white border  p-2  cursor-pointer"
                  onClick={() => setSalonToManage(salon)}
                >
                  <div
                    className="w-100 position-relative"
                    style={{ height: "250px", marginBottom: "75px" }}
                  >
                    <img
                      src={
                        salon.cover ?? require("../../assets/images/cover.jpg")
                      }
                      alt=""
                      className="img rounded w-100 h-100"
                    />
                    <div className=" position-absolute top-100 start-50 translate-middle">
                      <img
                        src={
                          salon.profile ??
                          require("../../assets/images/profile.jpg")
                        }
                        alt=""
                        className="img rounded-circle profile-img"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                    <span className="text-black fw-bold">{salon.name ?? ""}</span>
                    <span className=" text-center text-truncate w-100 px-1">
                      {salon.address ?? "No location provided"}
                    </span>
                    <div className="d-flex gap-3 mt-1">
                      <span className="text-black fw-bold">
                        Some text
                        <i className="ms-2 fa-solid fa-chair"></i>
                      </span>
                      <span className=" fw-bold">{salon.seating_places ?? ""}</span>
                    </div>
                    <div className="manage-btn rounded-pill p-2 mt-2 px-5 text-center">
                      Manage
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <ManageSalon
          setSalonToManage={setSalonToManage}
          salonToManage={salonToManage}
          managerForSalon={managerForSalon}
          UserManagers={UserManagers}
        />
      )}
    </div>
  );
};

export default SalonContainer;
