import './../styles/SpinnerLoader.css';



const SpinnerLoader = ()=>{
    return <div className="BookNowModal__container__loader d-flex justify-content-center align-items-center">
    <div className="spinner"></div>
  </div>
}

export default SpinnerLoader;