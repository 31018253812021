import GoogleMapReact from 'google-map-react';
import React, { useState } from 'react';

interface MapMarkerProps {
    center: {
        lat: number;
        lng: number;
    };
    infoWindowText: string;
    dimensions: Pick<React.CSSProperties, 'width' | 'height'>
    zoom?: number;
}

export const MapMarker = (props: MapMarkerProps) => {
    const [marker, setMarker] = useState<any>(null);
    const [infoWindow, setInfoWindow] = useState<any>(null);

    const handleApiLoaded = (map: any, maps: any) => {
        if (map && maps) {
            const cityCircle = new maps.Circle({
                strokeColor: '#e10984',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#e10984',
                fillOpacity: 0.35,
                map,
                center: props.center,
                radius: 1 * 1609.34,
            });

            const circleBounds = cityCircle.getBounds();
            map.fitBounds(circleBounds);

            // Create a marker and add it to the map
            const marker = new maps.Marker({
                position: props.center,
                map,
                title: 'Your Location',
            });

            // Set the marker state to allow manipulation later
            setMarker(marker);

            // Create an info window and associate it with the marker
            const infoWindow = new maps.InfoWindow({
                content: props.infoWindowText,
            });

            marker.addListener('click', () => {
                // Close any previously opened info windows
                if (infoWindow && infoWindow.getMap() === null) {
                    infoWindow.open(map, marker);
                }
            });

            infoWindow.open(map, marker);

            // Set the info window state
            setInfoWindow(infoWindow);
        }
    };

    return (
        <div style={props.dimensions}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: process.env.REACT_APP_SERVER_GOOGLE_MAP_API_KEY as string,
                }}
                defaultCenter={props.center}
                defaultZoom={props.zoom ?? 11}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            ></GoogleMapReact>
        </div>
    );
};
