import "./../styles/InputStars.css";
import { useEffect, useState } from "react";

interface InputStarsOptions {
  defaultValue: number;
  maxValue?: number;
  onChange?: (value: number) => void;
}

const InputStars: React.FC<InputStarsOptions> = ({
  defaultValue,
  onChange,
  maxValue = 5,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleClick = (value: number) => {
    setValue(value);
    if (onChange) onChange(value);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="InputStars w-100 d-flex justify-content-start gap-2 cursor-pointer">
      {Array(maxValue)
        .fill(0)
        .map((_, k) => {
          const stars = k + 1;
          return (
            <i
              data-stars={stars}
              onClick={() => {
                handleClick(stars);
              }}
              key={`stars-${stars}`}
              className={`fa fa-star text-muted ${
                value >= stars ? "active" : ""
              }`}
            />
          );
        })}
    </div>
  );
};

export default InputStars;
