import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IProject } from "../../models/Project";
import { ProjectService } from "../../services/ProjectService";

export interface ProjectState {
  projects: IProject[] | null;
  errors: any;
}

const initialState: ProjectState = {
  projects: null,
  errors: null,
};

export const getProjects = createAsyncThunk<IProject[], IProject['status'] | undefined>(
  "project/getProject",
  async (status) => {
    const res = await ProjectService.getProjects(status);
    return res.data;
  }
);

export const acceptProject = createAsyncThunk<IProject, IProject>(
  "project/accept",
  async (project) => {
    const res = await ProjectService.acceptProject(project);
    return res.data;
  }
);

export const deleteProject = createAsyncThunk<IProject, IProject>(
  "project/delete",
  async (project) => {
    const res = await ProjectService.acceptProject(project);
    return res.data;
  }
);

export const postProject = createAsyncThunk<IProject, FormData>(
  "project/post",
  async (project: FormData) => {
    const res = await ProjectService.postProject(project);
    return res.data;
  }
);

const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjects.fulfilled, (state, action) => {
        state.projects = action.payload;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        const projects = state.projects?.filter((project) => project._id !== action.payload._id);
        state.projects = projects as IProject[];
      })
  },
});

export default projectSlice.reducer;
