import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Footer from "../components/Footer";
import MainNav from "../components/navigation/MainNav";
import UpcomingHours, { DayTimes } from "../components/UpcomingHours";
import "../styles/ManageAvailabilityPage.css";
import Icons from "../components/reusable/Icons";
import {
  AvailabilityState,
  addAvailability,
  getAvailabilities,
} from "../redux/slices/availabilitySlice";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import Availability from "../models/Availability";
import {
  findRemovedDayTimes,
  findTimeDifferences,
  timeToTimezone,
} from "../utils/utils";
import PulseLoader from "../components/loader/PulseLoader";
import AvailabilityChangesModal from "../components/modal/AvailabilityChangesModal";
import { AuthState } from "../redux/slices/authSlice";
import ManageFreeTime from "../components/ManageFreeTime";
import { WorkingHours } from "../components/availability/WorkingHours";

export type USATimezone =
  | "US/Eastern"
  | "US/Central"
  | "US/Mountain"
  | "US/Pacific"
  | "US/Alaska"
  | "US/Hawaii"
  | "US/Samoa";

export const USATimezones = [
  { value: "US/Pacific", label: "US/Pacific (GMT-8)" },
  { value: "US/Mountain", label: "US/Mountain (GMT-7)" },
  { value: "US/Central", label: "US/Central (GMT-6)" },
  { value: "US/Eastern", label: "US/Eastern (GMT-5)" },
  { value: "US/Alaska", label: "US/Alaska (GMT-9)" },
  { value: "US/Hawaii", label: "US/Hawaii (GMT-10)" },
  { value: "US/Arizona", label: "US/Arizona (GMT-7)" },
  { value: "US/Indiana-Starke", label: "US/Indiana-Starke (GMT-6)" },
  { value: "Africa/Douala", label: "Africa/Douala (GMT+1)" },
  {
    value: "US/Indiana/Indianapolis",
    label: "US/Indiana/Indianapolis (GMT-5)",
  },
  { value: "US/Michigan", label: "US/Michigan (GMT-5)" },
];

const ManageAvailabilityOldPage = () => {
  const availabilityData = useSelector<unknown, AvailabilityState>(
    (state: any) => state.availabilities
  ).availabilities;
  const authData = useSelector<unknown, AuthState>(
    (state: any) => state.auth
  ).currentUser;
  const submitBtnRef = useRef<HTMLButtonElement>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // toggle management containers visibility
  const [active, setActive] = useState("schedule");
  const [availabilitiesTimes, setAvailabilitiesTimes] = useState<DayTimes>({});
  const [upcomingTimes, setUpcomingTimes] = useState<DayTimes>({});
  const [onSaveAvailability, setOnSaveAvailability] = useState(false);
  const [canSaveAvailability, setCanSaveAvailability] = useState(false);

  const [defaultAvailabilities, setDefaultAvailabilities] =
    useState<DayTimes | null>(null);
  const [defaultTimezone, setDefaultTimezone] =
    useState<USATimezone>("US/Pacific");
  const [timezone, setTimezone] = useState<USATimezone | null>(defaultTimezone);
  const [availabilityChanges, setAvailabilityChanges] = useState<{
    timezone: USATimezone;
    initial: DayTimes;
    edited: DayTimes;
    removed: DayTimes;
  }>({
    timezone: defaultTimezone,
    initial: {},
    edited: {},
    removed: {},
  });

  const handleSave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (submitBtnRef?.current) {
      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = "loading...";
      setTimeout(() => {
        navigate("/business-profile");
      }, 2000);
    }
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    navigate("/business-profile");
  };

  const handleAvailabilityChange = (times: DayTimes) => {
    // Check if there is a difference between
    // defaultAvailabilities and times
    const dayTimesChanges = findTimeDifferences(
      defaultAvailabilities ?? {},
      times
    );
    const dayTimesRemoved = findRemovedDayTimes(
      defaultAvailabilities ?? {},
      times
    );

    const canSave =
      JSON.stringify(dayTimesChanges) !== JSON.stringify({}) ||
      JSON.stringify(dayTimesRemoved) !== JSON.stringify({});

    if (canSave) {
      const initialDayTimesChanged: DayTimes = {};

      if (defaultAvailabilities) {
        Object.keys(dayTimesChanges).forEach((day) => {
          if (defaultAvailabilities[day] !== undefined) {
            initialDayTimesChanged[day] = defaultAvailabilities[day];
          }
        });
        Object.keys(dayTimesRemoved).forEach((day) => {
          if (defaultAvailabilities[day] !== undefined) {
            initialDayTimesChanged[day] = defaultAvailabilities[day];
          }
        });
      }

      setAvailabilityChanges({
        timezone: (timezone ?? defaultTimezone) as USATimezone,
        initial: initialDayTimesChanged,
        edited: dayTimesChanges,
        removed: dayTimesRemoved,
      });
    }

    setCanSaveAvailability(canSave);

    setAvailabilitiesTimes(times);
  };

  const handleChangeTimezone = (e: any) => {
    setTimezone(e.target.value);
    setAvailabilityChanges({
      ...availabilityChanges,
      timezone: e.target.value,
    });
  };

  const handleUpcomingHourChange = (times: DayTimes) => {
    setUpcomingTimes(times);
  };

  const handleSaveAvailability = () => {
    setOnSaveAvailability(true);
  };

  const handleAvailabilityChangesCancel = () => {
    setOnSaveAvailability(false);
  };

  const handleAvailabilityChangeSave = (changes: {
    edited: DayTimes;
    removed: DayTimes;
    timezone: USATimezone;
  }) => {
    return dispatch(
      addAvailability(changes as any) as unknown as AnyAction
    ).unwrap();
  };

  useEffect(() => {
    if (authData !== null && availabilityData === null) {
      dispatch(getAvailabilities() as unknown as AnyAction)
        .unwrap()
        .then((res: Partial<Availability>[]) => {
          const availabilities: DayTimes = {};
          res.forEach((availability: Partial<Availability>) => {
            availabilities[availability.day as string] = {
              start: timeToTimezone(
                availability.startTime as string,
                availability.timezone
              ),
              end: timeToTimezone(
                availability.endTime as string,
                availability.timezone
              ),
            };
          });
          const timezone: USATimezone = (
            res.length > 0 ? res[0].timezone : "US/Pacific"
          ) as USATimezone;
          setDefaultTimezone(timezone);
          setDefaultAvailabilities(availabilities);
        });
    }
  }, [availabilityData, authData, dispatch]);

  // useEffect(() => {
  //   console.log("my availabilities", defaultAvailabilities);
  // }, []);

  useEffect(() => {
    const availabilities: DayTimes = {};
    if (availabilityData) {
      availabilityData
        .slice()
        .forEach((availability: Partial<Availability>) => {
          availabilities[availability.day as string] = {
            start: timeToTimezone(
              availability.startTime as string,
              availability.timezone
            ),
            end: timeToTimezone(
              availability.endTime as string,
              availability.timezone
            ),
          };
        });
      const timezone: USATimezone = (
        availabilityData.length > 0
          ? availabilityData[0].timezone
          : "US/Pacific"
      ) as USATimezone;
      setDefaultTimezone(timezone);
      setDefaultAvailabilities(availabilities);
      setAvailabilityChanges({
        timezone: defaultTimezone,
        initial: {},
        edited: {},
        removed: {},
      });
      setCanSaveAvailability(false);
    }
  }, [availabilityData, dispatch]);

  return (
    <>
      <MainNav navType={"connectedNav"} isSearch={false} />
      <div className="container">
        <div className="management_icons p-3 justify-content-between align-items-center">
          <i
            onClick={() => setActive("schedule")}
            className={
              "fa-solid fa-calendar-days" +
              (active === "schedule" ? " active_icon" : "")
            }
          ></i>
          <i
            onClick={() => setActive("hours")}
            className={
              "fa-solid fa-clock" + (active === "hours" ? " active_icon" : "")
            }
          ></i>
          <i
            onClick={() => setActive("more")}
            className={
              "fa-solid fa-list-check" +
              (active === "more" ? " active_icon" : "")
            }
          ></i>
        </div>
        <div className="w-100 d-flex gap-3 availability_container">
          <div
            className={
              "w-100 flex-column gap-2" +
              (active === "schedule" ? " active" : " availability-div")
            }
          >
            <div className="w-100 d-flex flex-column gap-2">
              <h4 className="fw-500 text-primary ms-2">Manage Schedule</h4>
              <div className="d-flex justify-content-between align-items-center mx-2 mb-3">
                <span className="fw-500 text-black">
                  Timezone{" "}
                  <i className="ms-1 fa fa-info-circle text-primary"></i>
                </span>
                <Button
                  disabled={!canSaveAvailability}
                  className="br-6"
                  ref={submitBtnRef as any}
                  onClick={handleSaveAvailability}
                  mode={"primary"}
                  content={<span className="mx-3">Save</span>}
                />
              </div>
            </div>
            <div className="ManageShedule">
              <div className="p-2">
                <div className="mb-3">
                  <select
                    onChange={handleChangeTimezone}
                    style={{ fontSize: "1em !important" }}
                    className="form-select form-select-lg"
                    name=""
                    id=""
                  >
                    {USATimezones.map((timezone, index) => (
                      <option key={index} value={timezone.value}>
                        {timezone.label}
                      </option>
                    ))}
                  </select>
                </div>
                {defaultAvailabilities !== null ? (
                  <UpcomingHours
                    onChange={handleAvailabilityChange}
                    values={defaultAvailabilities}
                  />
                ) : (
                  <PulseLoader />
                )}
              </div>
            </div>
          </div>

          <div className="VerticalDivider"></div>

          <div
            className={
              "w-100 flex-column gap-2" +
              (active === "hours" ? " active" : " availability-div")
            }
          >
            <ManageFreeTime />
          </div>

          <div className="VerticalDivider"></div>

          <div
            className={
              "w-100 flex-column" + (active === "more" ? " active" : "")
            }
          >
            <div className="ManageSchedule d-flex mx-auto flex-column gap-2">
              <div className="w-100 d-flex flex-column gap-2">
                <h4 className="fw-500 text-primary ms-2">More Manage</h4>
                <div className="d-flex justify-content-between mx-2">
                  <span className="fw-500 text-black">
                    Connect{" "}
                    <i className="ms-1 fa fa-info-circle text-primary"></i>
                  </span>
                </div>
                <div className="GoogleCalendarSync mt-2 d-flex border align-items-center justify-content-between p-2">
                  <div className="text-muted">Google Calendar Sync</div>
                  <div className="WeekSliderArrow">
                    <i className="fa fa-angle-right fa-2x"></i>
                  </div>
                </div>
                <div className="d-flex justify-content-between mx-2 mt-3">
                  <span className="fw-500 text-black">
                    Manage vacation Time{" "}
                    <i className="ms-1 fa fa-info-circle text-primary"></i>
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column gap-2">
                {Array(5)
                  .fill(0)
                  .map(() => {
                    return (
                      <div className="AddNewVacation p-3 text-muted border justify-content-center align-items-center text-center">
                        Add New vaction
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-5 gap-2">
              <Button
                mode="outlined"
                className="br-6"
                onClick={handleCancel}
                content={<span className="mx-2">Cancel</span>}
              />
              <Button
                className="br-6"
                ref={submitBtnRef as any}
                onClick={handleSave}
                mode={"primary"}
                content={<span className="mx-3">Save</span>}
              />
            </div>
          </div>
          <div className="availability_icons" style={{ alignSelf: "center" }}>
            {/* <Icons /> */}
          </div>
        </div>
      </div>
      {onSaveAvailability && (
        <AvailabilityChangesModal
          availabilityChanges={availabilityChanges}
          onCancel={handleAvailabilityChangesCancel}
          onSave={handleAvailabilityChangeSave}
        />
      )}
      {/* <Footer /> */}
    </>
  );
};

export default ManageAvailabilityOldPage;
