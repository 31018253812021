import { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import { SearchBarHelp } from "../components/help/SearchBarHelp";
import MainNav from "../components/navigation/MainNav";
import PulseLoader from "../components/loader/PulseLoader";
import "../styles/HelpPage.css";
import { accordeonData, accordionDatas } from "../utils/data";
import Accordion from "../components/accordion/Accordion";
import { useUser } from "../hooks/UseUser";
import { ContactHelp } from "../components/help/ContactHelp";
import { HorizontalMenu } from "../components/help/HorizontalMenu";

export default function HelpPage() {
  const [show, setShow] = useState(false);
  const [activeMenu, setActiveMenu] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const currentUser = useUser();

  const handleMenuItemClick = (item: any, key: any) => {
    setActiveMenu(key);
  };
  const handleShowData = () => {
    setShow(!show);
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setShow(show);
    });
  }, []);

  useEffect(() => {
    inputRef.current?.addEventListener("keydown", handleShowData);
    document.addEventListener("click", () => {
      setShow(show);
    });
  }, [inputRef]);

  const data = [
    {
      title: "Did you have a question",
      image: require("../assets/images/alpha.png"),
    },
    {
      title: "Contact a custumer client",
      image: require("../assets/images/card.png"),
    },
  ];

  return (
    <div
      className={
        "d-flex flex-column justify-content-between h-100  main-container "
      }
    >
      <MainNav navType={"connectedNav"} isSearch={true} />
      <section
        className={
          " w-100 d-flex flex-column align-content-center p-4 container" +
          (show ? "bg-main-section bg-white" : "")
        }
        style={{ height: "60vh" }}
      >
        <h1 className=" text-center m-4 ">Hello, How can i help you ?</h1>
        <div className="w-100">
          <SearchBarHelp
            helps={accordionDatas}
            visible={show}
            onShow={handleShowData}
            inputRef={inputRef}
          />
        </div>
        <>
          {!show && (
            <>
              <p className=" text-center text-category-help ">
                Or Choose the Category to quickly find the help you need
              </p>
              <HorizontalMenu
                active={activeMenu}
                items={data}
                onItemClick={handleMenuItemClick}
              />
            </>
          )}
        </>
      </section>
      <>
        {!show && (
          <section className="section-content  w-100">
            <h1 className=" text-center m-4 text-primary ">
              {activeMenu === 0 && " Frequently Asked Question"}
              {activeMenu === 1 && "Contact a customer service"}
            </h1>
            {activeMenu === 0 && (
              <>
                <p className=" text-center text-paraph ">
                  These are the frequently asked questions about
                  <strong> Latrice</strong>. Can't find what you're looking for?
                </p>
                <p className=" text-center text-paraph text-paraph-bottom">
                  Contact customer service by just clicking on the customer
                  service section above
                </p>
              </>
            )}
            <main className=" mt-4 ">
              {/* {AccordionData.map((accordion) => (
            <div className="section-accordion" key={accordion.id}>
              <div
                onClick={() => btnOnClick(accordion.id)}
                className="btn-accordion-show"
              >
                <p>{accordion.title}</p>
                {!show ? (
                  <i className="fa-solid fa-chevron-down"></i>
                ) : (
                  <i className="fa-solid fa-xmark"></i>
                )}
              </div>
              {show && accordion.id !== currentIdx ? (
                <div className="box-accordion-description">
                  {accordion.description}
                </div>
              ) : (
                ""
              )}
            </div>
          ))} */}
              <div className="comment-content">
                {currentUser !== null ? (
                  <div>
                    {activeMenu === 0 && <Accordion items={accordeonData} />}
                    {activeMenu === 1 && <ContactHelp />}
                  </div>
                ) : (
                  <PulseLoader />
                )}
              </div>
            </main>
          </section>
        )}
      </>
      <Footer />
    </div>
  );
}
