import { DiscussionSystemProps } from "./LatriceDiscussionMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import PulseLoader from "../loader/PulseLoader";
import User from "../../models/User";
import { ProfessionalService } from "../../services/ProfessionalService";
import VerticalUserSelector from "./VerticalUserSelector";
import slugify from "slugify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  editCustomAppointment,
  reassignCustomAppointment,
} from "../../redux/slices/customAppointmentSlice";
import { AnyAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

interface AppointmentReassignProps {
  className?: string;
  onReassign: (value: boolean) => void;
}

export const AppointmentReassign = (
  props: AppointmentReassignProps & DiscussionSystemProps
) => {
  const [findingAlternative, setFindingAlternative] = React.useState(false);
  const [onFinding, setOnFinding] = React.useState(false);
  const [onReassign, setOnReassign] = React.useState(false);

  const [alternativeProfessionals, setAlternativeProfessionals] =
    React.useState<Partial<User>[] | null>(null);
  const [selectedProfessional, setSelectedProfessional] =
    React.useState<Partial<User> | null>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFindAlternativeProfessional = () => {
    setFindingAlternative(true);
    setOnFinding(true);
    props.onReassign(true);

    console.log("props", props);

    ProfessionalService.findAlternativeToAppointment(props.appointment._id)
      .then((res) => {
        const professionals = res.map((pro: any) => {
          const user = pro.user;
          delete user["user"];

          return { ...pro, ...user, _id: pro._id };
        });
        setAlternativeProfessionals(professionals);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setOnFinding(false);
      });
  };

  const handleSelectProfessional = (user: Partial<User>) => {
    console.log("user i selected", user);
    setSelectedProfessional(user);
  };

  const handleSeeProfile = () => {
    if (selectedProfessional) {
      const { first_name, last_name, _id } = selectedProfessional;
      const url =
        "/see-professional-account/" +
        slugify(
          `${first_name?.toLowerCase()}-${last_name?.toLowerCase()}-${_id}`
        );
      navigate(url);
    }
  };

  const handleReassign = () => {
    setOnReassign(true);

    const formData = new FormData();
    formData.append("id", props.appointment._id);
    formData.append("type", "reassign");

    dispatch(
      reassignCustomAppointment({
        customAppointmentId: props.appointment._id,
        professionalId: selectedProfessional
          ? (selectedProfessional._id as string)
          : "",
      }) as unknown as AnyAction
    )
      .unwrap()
      .then((res: any) => {
        console.log(res);
        toast.success(
          `Your appointment has been reassigned to ${selectedProfessional?.first_name}.`
        );
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(
          "Something went wrong during reassignment, please try again."
        );
      });
  };

  return (
    <div className={props.className ?? ""}>
      {!findingAlternative && (
        <button
          onClick={handleFindAlternativeProfessional}
          className="AppointmentReassign__button"
        >
          <FontAwesomeIcon icon={faExchangeAlt} />
          Reassign this appointment
        </button>
      )}

      {(onFinding || onReassign) && (
        <div>
          <PulseLoader />
          {onReassign && (
            <div>
              Please wait until we reassign appointment to{" "}
              {selectedProfessional?.first_name}
            </div>
          )}
        </div>
      )}

      {!onFinding &&
        (!alternativeProfessionals ||
          alternativeProfessionals.length === 0) && <div></div>}

      {alternativeProfessionals &&
        alternativeProfessionals.length > 0 &&
        !onReassign && (
          <div className="d-flex flex-column justify-content-center align-items-center gap-2 w-100">
            <h6>Please select another professional</h6>
            <VerticalUserSelector
              users={alternativeProfessionals as User[]}
              onSelect={handleSelectProfessional}
            />
            {selectedProfessional && (
              <div className="d-flex justify-content-start align-items-end">
                <button
                  onClick={handleReassign}
                  className={`LatricePendingAppointment__button accept-button`}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} /> Reassign to{" "}
                  {selectedProfessional.first_name}
                </button>
                <button
                  onClick={handleSeeProfile}
                  className={`LatricePendingAppointment__button price-low-button`}
                >
                  <FontAwesomeIcon icon={faUser} /> See{" "}
                  {selectedProfessional.first_name}'s profile
                </button>
              </div>
            )}
          </div>
        )}
    </div>
  );
};
