import React from 'react'
import MonthPicker from '../../reusable/MonthPicker'
import SlidePicker from '../../SlidePicker'
import { Moment } from 'moment'
import Time from '../../reusable/Time'
import moment from 'moment'


interface DateTimeStepProps {
    selectedTime: Moment | undefined,
    selectedDate: Moment | undefined,
    onMonthChange: (month: Moment) => void,
    onSelectDay: (month: Moment) => void,
    onSelectTime: (time: Moment) => void,
    selectedMonthDates: Moment[],
}


export const DateTimeStep: React.FC<DateTimeStepProps> = (
    { selectedDate, selectedMonthDates, onMonthChange, onSelectDay, onSelectTime, selectedTime }
) => {


    const getPossibleStartTime = () => {
        const startTime = moment("08:00", "hh:mm");
        const endTime = moment("17:00", "hh:mm");
        const intervalMinutes = 30;
        const timeArray = [];
    
        // Initialize the current time to the start time
        let currentTime = startTime.clone();
    
        // Loop until the current time reaches or exceeds the end time
        while (currentTime.isBefore(endTime)) {
          timeArray.push(currentTime.clone());
    
          // Add the interval in minutes to the current time
          currentTime.add(intervalMinutes, "minutes");
        }
    
        return timeArray;
      };



    return (
        <div>
            <div className="mb-3">
                <label htmlFor="" className="form-label text-primary fw-500">
                    Select a date *
                </label>
                <div className="d-flex flex-column justify-content-center">
                    <div className="mb-3 d-flex justify-content-center">
                        <MonthPicker onChange={onMonthChange} />
                    </div>
                    <div className="w-100">
                        <SlidePicker
                            data={selectedMonthDates}
                            selected={selectedDate}
                            maxShow={7}
                            keyExtractor={(item) => item.format("YYYY-MM-DD")}
                            separator={<span className="mx-1"></span>}
                            renderItem={(item) => (
                                <div className="dateItem d-flex flex-column fw-bold p-2 br-6 btn-secondary">
                                    <div>{item.format("ddd")}</div>
                                    <div>{item.date()}</div>
                                </div>
                            )}
                            onSelectItem={onSelectDay}
                        />
                    </div>
                </div>
            </div>
            <div>
                <label htmlFor="" className="form-label text-primary fw-500">
                    What Time ?
                </label>
                <div className="mb-3 d-flex justify-content-center w-100">
                    {/* <Time value={selectedTime} onChange={onSelectTime} /> */}
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="" className="form-label text-primary fw-500">
                    Select a startTime? *
                </label>
                <div className="d-flex flex-column justify-content-center">
                    <div className="w-100">
                        <SlidePicker
                            data={getPossibleStartTime()}
                            selected={selectedTime}
                            keyExtractor={(item) => item.format("YYYY-MM-DD-HH-mm-A")}
                            separator={<span className="mx-1"></span>}
                            renderItem={(item) => (
                                <div className="hourItem gap-1 d-flex flex-row fw-bold p-2 br-6 btn-secondary">
                                    <div>{item.format("h:mm A")}</div>
                                </div>
                            )}
                            onSelectItem={onSelectTime}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}