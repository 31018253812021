import React, { CSSProperties, useEffect, useState } from "react";
import Button from "../Button";
import "./../../styles/FamilyFriend.css";
import AddMemberModal from "../modal/AddMemberModal";
import Member from "../Member";
import { MemberService } from "../../services/MemberService";
import { useDispatch, useSelector } from "react-redux";
import {
  MemberState,
  acceptMember,
  getMember,
  rejectMember,
  deleteMember,
} from "../../redux/slices/memberSlice";
import { AnyAction } from "@reduxjs/toolkit";
import MemberType from "../../models/Member";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowLeft, faTrash } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import ModalLoader from "../loader/ModalLoader";
import { useUser } from "../../hooks/UseUser";
import { Tooltip } from "react-bootstrap";
import AppointmentRequestItem from "../reusable/AppointmentRequestItem";
import { Modal } from "react-bootstrap";
import MemberNoExist from "../modal/MemberNoExist";

// ../../assets/images/family.png

interface FamilyFriendProps {}

const FamilyFriend = ({}: FamilyFriendProps) => {
  const members = useSelector<unknown, MemberState>(
    (state: any) => state.myMember
  ).members;

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [addedMember, setAddedMember] = useState<MemberType[]>([]);
  const [refetchMember, setRefetchMember] = useState(false);
  const [selectedMember, setSelectedMember] = useState<MemberType | null>(null);
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState<MemberType | null>(null);
  const [showMemberNotExist, setShowMemberNotExist] = useState(false);

  useEffect(() => {
    dispatch(getMember() as unknown as AnyAction);
  }, [refetchMember]);

  useEffect(() => {
    if (members) {
      setAddedMember(members);
    }
  }, [members]);

  const handleRefetchMembers = () => {
    console.log("i refetch my data");
    setRefetchMember(!refetchMember);
  };

  const handleMemberDetails = (member: MemberType) => {
    setSelectedMember(member);
  };
  const HandleAcceptMember = (member: MemberType) => {
    setLoading(true);
    dispatch(acceptMember(member) as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        toast.success("We have successful accepted");
        handleRefetchMembers();
      })
      .catch((err: any) => {
        console.log("error", err);

        toast.error("something went wrong");
      })

      .finally(() => {
        setLoading(false);
      });
  };
  const HandleRejectMember = (member: MemberType) => {
    dispatch(rejectMember(member) as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        toast.success("We have successful accepted");
        handleRefetchMembers();
      })
      .catch((err: any) => {
        console.log("error", err);

        toast.error("something went wrong");
      })

      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteMember = (member: MemberType) => {
    setMemberToDelete(member);
    setShowDeleteModal(true);
  };

  const confirmDeleteMember = () => {
    if (memberToDelete) {
      setLoading(true);
      dispatch(deleteMember(memberToDelete) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          toast.success("Member successfully deleted");
          handleRefetchMembers();
        })
        .catch((err: any) => {
          console.log("error", err);
          toast.error("Something went wrong while deleting the member");
        })
        .finally(() => {
          setLoading(false);
          setShowDeleteModal(false);
          setMemberToDelete(null);
        });
    }
  };

  const handleSelectMember = (member: any) => {
    console.log("member", member);

    setSelectedMember((selectedMember: any) => {
      // Créer une nouvelle copie de selectedMember avec les modifications nécessaires
      return {
        ...selectedMember,
        appoitmentRequestData: member.appoitmentRequestData,
      };
    });
  };

  const removeModal = () => {
    setShow(false);
    setShowMemberNotExist(true);
  };

  return (
    <div style={{ position: "relative" }}>
      <div>
        <h3 className="text-primary text-center">Family & Friends</h3>
      </div>

      {addedMember.length ? (
        <div
          className="  gap-3  mt-5 "
          style={{
            flexWrap: "wrap",
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          {addedMember.map((member, key) => (
            <div
              key={key}
              style={{
                maxWidth: "30%",
                width: "100%",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <Member
                data={member}
                handleMemberDetails={handleMemberDetails}
                onAccept={HandleAcceptMember}
                onReject={HandleRejectMember}
              />
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => handleDeleteMember(member)}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  color: "red",
                  cursor: "pointer",
                }}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="d-flex flex-column gap-3 align-items-center justify-content-center mt-5">
          <div className="  d-flex justify-content-center align-items-center flex-column">
            <img
              style={{ height: "200px", width: "200px", objectFit: "contain" }}
              src={require("../../assets/images/family.png")}
              alt="Add a memeber"
            />
            <div className="d-flex">
              <h2 className="text-center fw-500 text-dark">
                Add your family or friends
              </h2>
              <i className="fa fa-info-circle text-primary ms-2"></i>
            </div>
          </div>
          <div>{/* <img src={} alt=""/> */}</div>
          <div className="text-center d-flex align-items-center justify-content-center">
            <p style={{ width: "70%" }}>
              Link an account to schedule appointments on behalf of others, a
              child, family member,partner or friends. You will be able to
              manage their appointments, receive notifications, and make
              payments remotely.
            </p>
          </div>
        </div>
      )}

      <div className="d-flex flex-column gap-3 align-items-center justify-content-center mt-5">
        <div
          className="d-flex justify-content-start align-items-start"
          style={{ width: "80%" }}
        >
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setShow(true)}
            className="text-primary fw-500 align-items-center d-flex gap-1"
          >
            <i className="fa fa-plus fa-2x"></i> Add Member
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-end w-100 flex-column gap-3 ">
          {/* <Button style={{}} className="learnMoreBtn" content="Learn More" />
          <Button style={{}} className="learnMoreBtn" content="Learn More" /> */}
          <span className="bg-primary d-flex align-items-center justify-content-center learnBtn "></span>
          <span className="bg-yellow d-flex align-items-center justify-content-center learnBtn "></span>
        </div>
      </div>

      {selectedMember && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100%",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            background: "#ffffff",

            border: "2px solid grey",
            borderRadius: "10px",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
              width: "100%",
              // backgroundColor: "grey",
              color: "white",
              zIndex: "100",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <FontAwesomeIcon
              onClick={() => setSelectedMember(null)}
              className="cursor-pointer"
              style={{ color: "black" }}
              icon={faLongArrowLeft}
            />
            <div>
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <img
                  className="shadow"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    scale: "0.9",
                  }}
                  src={
                    `${
                      user?._id === selectedMember.userId._id
                        ? selectedMember.userMemberId.profile
                        : selectedMember.userId.profile
                    }` ??
                    "https://cdn.pixabay.com/photo/2018/08/23/22/29/girl-3626901_960_720.jpg"
                  }
                  alt=""
                />
                <span style={{}}>{selectedMember.userName}</span>
              </div>
            </div>
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingLeft: "15px",
              paddingRight: "15px",
              marginTop: "10px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div className="rce-mbox-body">
              <div className="rce-mbox-forward rce-mbox-forward-right"></div>
              <div className="d-flex justify-content-between rce-mbox-title rce-mbox-title--clear">
                <span className="text-uppercase">
                  {selectedMember.status == "pending"
                    ? "You have a pending member request"
                    : "You have a confirmed member request"}
                </span>
                <Tooltip id="pending-custom-appointment-tooltip" />
                <div
                  style={{ textDecoration: "none !important" } as CSSProperties}
                >
                  <img
                    style={{ width: "100px" }}
                    alt=""
                    className="img-fluid rounded"
                    src={
                      `${
                        user?._id === selectedMember.userId._id
                          ? selectedMember.userMemberId.profile
                          : selectedMember.userId.profile
                      }` ??
                      "https://cdn.pixabay.com/photo/2018/08/23/22/29/girl-3626901_960_720.jpg"
                    }
                  />
                </div>
              </div>
              <div className="rce-mbox-text left d-flex flex-column gap-1">
                <div className="d-flex align-items-center">
                  <h6 className="text-underline">
                    {user?._id === selectedMember.userId._id
                      ? selectedMember.userName
                      : selectedMember.userId.first_name}{" "}
                  </h6>
                </div>
                <div className="d-flex gap-1 align-items-center w-100 fw-500 mb-2">
                  <div className="d-flex align-items-center gap-1">
                    <span>
                      {user?._id === selectedMember.userId._id
                        ? selectedMember.email
                        : selectedMember.userId.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
                marginTop: "15px",
                gap: "10px",
              }}
            >
              {selectedMember.appoitmentRequestData.map(
                (appointmentRequestData, index) => (
                  <AppointmentRequestItem
                    appointmentRequestData={appointmentRequestData}
                    key={index}
                    handleRefetchMembers={() => handleRefetchMembers()}
                    setSelectedMember={handleSelectMember}
                  />
                )
              )}
            </div>
          </div>
        </div>
      )}

      <AddMemberModal
        handleRefetchMembers={handleRefetchMembers}
        open={show}
        onClose={() => setShow(false)}
        onFailled = {()=>removeModal()}

      />
      <MemberNoExist
        handleRefetchMembers={handleRefetchMembers}
        open={showMemberNotExist}
        onClose={() => setShowMemberNotExist(false)}
      />
      {loading && <ModalLoader />}

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <div>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this member?</Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setShowDeleteModal(false)}
              content="Cancel"
            />
            <Button onClick={confirmDeleteMember} content="Confirm" />
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

export default FamilyFriend;
