import { useState, FC, useRef, ChangeEvent } from "react";
import AuthLayout from "../components/auth/AuthLayout";
import { ISalon } from "../models/SalonX";
import CreateSalonStep1 from "../components/form/create-salon/CreateSalonStep1";
import StepList from "../components/reusable/StepList";
import StepIndicator from "../components/reusable/StepIndicator";
import { CONSTANTS } from "../utils/constants";
import CreateSalonStep2 from "../components/form/create-salon/CreateSalonStep2";
import CreateSalonStep3 from "../components/form/create-salon/CreateSalonStep3";
import CreateSalonStep4 from "../components/form/create-salon/CreateSalonStep4";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { AddSalon } from "../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import ModalLoader from "../components/loader/ModalLoader";

type Props = {};

const CreateSalonPage: FC = (props: Props) => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [salonValues, setSalonValues] = useState<Partial<ISalon>>({
    name: "",
    address: "",
    manager_id: "",
    accept_custom_orders: false,
    wheelchair_accessible: false,
    kids_friendly: false,
    kids_service: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    const newValue =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : value;

    setSalonValues({
      ...salonValues,
      [name]: newValue,
    });
  };

  const handleNextStep = () => {
    // e.preventDefault();
    // e.stopPropagation();
    if (step !== 3) {
      setStep(step + 1);
    } else {
      if (submitBtnRef.current) {
        setLoading(true);
        submitBtnRef.current.setAttribute("disabled", "true");
        submitBtnRef.current.innerHTML = "loading...";

        dispatch(AddSalon(salonValues as ISalon) as unknown as AnyAction)
          .unwrap()
          .then((res: any) => {
            toast.success("Salon Added successfully");
            const salonId = res.salon._id;
            window.location.href = `/setup-business/manage-chairs/${salonId}`;
          })
          .catch((err: any) => {
            console.log("err",err);
            toast.error(err);
          })
          .finally((res: any) => {
            if (submitBtnRef.current) {
              submitBtnRef.current.removeAttribute("disabled");
              submitBtnRef.current.innerHTML = "Done";
            }
            setSalonValues({});
            setLoading(false);
          });
      }
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <CreateSalonStep1 salonValues={salonValues} onChange={handleChange} />
        );
      case 1:
        return (
          <CreateSalonStep2
            salonValues={salonValues}
            setSalonValues={setSalonValues}
          />
        );
      case 2:
        return (
          <CreateSalonStep3
            salonValues={salonValues}
            setSalonValues={setSalonValues}
          />
        );
      case 3:
        return (
          <CreateSalonStep4
            salonValues={salonValues}
            setSalonValues={setSalonValues}
            onChange={handleChange}
          />
        );
      default:
        return null;
    }
  };

  const isValid = () => {
    switch (step) {
      case 0:
        return !!salonValues.name?.trim();
      case 1:
        return true;
      case 2:
        return !!salonValues.manager_id?.trim();
      case 3:
        return true;
      default:
        return false;
    }
  };

  return (
    <AuthLayout>
      <div className="w-100 p-0 m-0">
        {loading && <ModalLoader />}
        <div className="d-flex justify-content-between align-items-center mt-2 py-3 px-4 create-salon-header">
          <i
            className="fa-solid fa-x text-black cursor-pointer"
            onClick={() => navigate("/admin/dashboard")}
          ></i>
          <div className="d-flex align-items-center text-black gap-4">
            {step !== 0 && (
              <span className="cursor-pointer" onClick={handlePrevStep}>
                Back
              </span>
            )}
            {step === 2 && (
              <span onClick={handleNextStep} className="cursor-pointer">
                Skip
              </span>
            )}
            <button
              ref={submitBtnRef}
              disabled={!isValid()}
              onClick={handleNextStep}
              className="button-27 py-2 px-5 rounded"
            >
              {step <3 ? "Next":"Save"}
            </button>
          </div>
        </div>
        <div className="container p-lg-0">
          <div className="d-flex w-100">
            <div className="justify-content-between flex-row p-0 m-0 d-none d-lg-flex col-3 col-md-4 col-lg-4 col-xl-3">
              <div className="d-flex flex-column align-items-center h-100">
                <StepList
                  activeStep={step}
                  steps={CONSTANTS.CREATE_SALON_STEPS}
                />
              </div>
              <StepIndicator
                activeStep={step}
                steps={CONSTANTS.CREATE_SALON_STEPS}
              />
            </div>

            {renderStepContent()}
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default CreateSalonPage;
