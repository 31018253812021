import React from "react";
import "./../styles/Profile.css";
import Button from "./Button";
import { Stars } from "./Stars";
import { useNavigate } from "react-router-dom";

export interface ProfileProps {
  name: string;
  profile: string;
  cover: string[];
  title: string;
  location: string;
  stars: number;
  notice: number;
  blow_dry: boolean;
  hair_wash: boolean;
  undo_hair: boolean;
  onEditProfile: () => void;
  onEditCover: () => void;
}

const Profile: React.FC<ProfileProps> = ({
  name,
  profile,
  cover,
  title,
  location,
  stars,
  notice,
  blow_dry,
  hair_wash,
  undo_hair,
  onEditProfile,
  onEditCover,
}: ProfileProps) => {
  const navigate = useNavigate();
  return (
    <div className="Profile d-flex flex-column w-100">
      <div className="ProfileCover  d-flex flex-column align-items-center">
        <div className="ProfileCoverContainer w-100  d-flex justify-content-between gap-1">
          <img
            className={"Business_ProfileCoverImage w-100 rounded"}
            src={cover[0]}
            alt={name}
          />
        </div>
        <div className="BUsinessProfilePicture border shadow">
          <img src={`${profile}`} alt={name} />
          <div
            onClick={onEditProfile}
            className="d-flex align-items-center justify-content-center btn bg-white z-3 add-pic"
          >
            <i className="fa-solid fa-pen text-black"></i>
          </div>
        </div>
        <div className="ProfileAddCover">
          <button
            onClick={onEditCover}
            style={{ borderRadius: "1rem" }}
            className={"mt-1 btn bg-white"}
          >
            EDIT COVER <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div className="ProfileMeta d-flex flex-column justify-content-center text-center">
        <h4 className="text-black fw-500 mt-5">{name}</h4>
        <span className="text-muted small">
          <i className="fa-solid fa-location-dot"></i> {location}
        </span>
        <span className="mt-1 d-flex justify-content-center align-items-center">
          <div className="starsContainer">
            <Stars canUpdate={false} stars={stars} notice={notice} />
          </div>
        </span>
      </div>
      <div className="mt-4">
        <div className="d-flex flex-column w-60">
          <div className="d-flex justify-content-between professional_service align-items-center w-100">
            <div className="d-flex flex-column">
              <h5 className="text-black">Blow dry</h5>
              <div className="professional_service__description">
                {blow_dry ? "Yes" : "No"}
              </div>
              <span className="text-muted">
                {/* {formatUSPrice(service.price)} for{" "} */}
                {/* {formatTime(service.duration as unknown as number)} */}
              </span>
            </div>
          </div>
          <hr className="w-50" />
        </div>
        <div className="d-flex flex-column w-60">
          <div className="d-flex justify-content-between professional_service align-items-center w-100">
            <div className="d-flex flex-column">
              <h5 className="text-black">Hair wash</h5>
              <div className="professional_service__description">
                {hair_wash ? "Yes" : "No"}
              </div>
              <span className="text-muted">
                {/* {formatUSPrice(service.price)} for{" "} */}
                {/* {formatTime(service.duration as unknown as number)} */}
              </span>
            </div>
          </div>
          <hr className="w-50" />
        </div>
        <div className="d-flex flex-column w-60">
          <div className="d-flex justify-content-between professional_service align-items-center w-100">
            <div className="d-flex flex-column">
              <h5 className="text-black">Undo hair</h5>
              <div className="professional_service__description">
                {undo_hair ? "Yes" : "No"}
              </div>
              <span className="text-muted">
                {/* {formatUSPrice(service.price)} for{" "} */}
                {/* {formatTime(service.duration as unknown as number)} */}
              </span>
            </div>
          </div>
          <hr className="w-50" />
        </div>
      </div>

      <div
        style={{ gap: "10%", marginTop: "2rem" }}
        className="d-flex profile_btns p-2"
      >
        <Button
          className="btn_outlined"
          mode="outlined"
          onClick={() => navigate("/earnings")}
          content={
            <div className="d-flex align-items-center gap-2">
              <div>View Insights</div>
              <i
                style={{
                  fontWeight: "900",
                }}
                className="fa-regular fa-circle-info text-primary"
              ></i>
            </div>
          }
        />
        <Button
          mode={"primary"}
          content={
            <div
              className="d-flex align-items-center gap-2"
              onClick={() => navigate("/service-promotions")}
            >
              <div>Promote Profile</div>
              <i
                style={{
                  fontWeight: "900",
                }}
                className="fa-regular fa-circle-info"
              ></i>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Profile;
