import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AccountSetting,
  ChangePassword,
  FamilyFriend,
  Favorites,
  Location,
  Review,
} from "../components/account";
import VerticalMenu from "../components/VerticalMenu";
import "../styles/ClientProfilePage.css";
import { AccountBalance } from "../components/account/AccountBalance";
import { useUser } from "../hooks/UseUser";
import AuthLayout from "../components/auth/AuthLayout";
import { useSalon } from "../context/SalonContext";
import MainNav from "../components/navigation/MainNav";
import PulseLoader from "../components/loader/PulseLoader";
import Button from "../components/Button";
import { AnyAction } from "@reduxjs/toolkit";
import { updateUser } from "../redux/slices/authSlice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import ImageEditorModal from "../components/modal/ImageEditorModal";
import { formatName } from "../utils/utils";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AuthService } from "../services/AuthService";
import Footer from "../components/Footer";
import QrCodePage from "../components/account/QrCode";
import UnsaveData from "../components/modal/UnsavaData";

type TLocation = {
  location: string | null;
};

const ClientProfilePage = () => {
  const defaultPosition = {
    center: {
      lat: 7.369722,
      lng: 12.35472,
    },
    zoom: 11,
  };
  const currentUser = useUser();
  const { salon, setSalon } = useSalon();
  const [areaRadius, setAreaRadius] = useState<string | null>("1");
  const [address, setAddress] = useState<string | null>(null);
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState<typeof defaultPosition | null>(null);
  const [initialPosition, setInitialPosition] = useState<
    typeof defaultPosition | null | TLocation
  >(null);
  const [openEditorModal, setOpenEditorModal] = useState(false);
  const [btnLocation, setBtnLocation] = useState(true);
  const changeLocationBtnRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [pendingMenuChange, setPendingMenuChange] = useState<number | null>(
    null
  );

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
  );

  const [activeMenu, setActiveMenu] = useState(0);

  const verticalMenuItems1 = [
    {
      faIcon: "fa fa-cog",
      title: "Account Setting",
    },
    {
      faIcon: "fa fa-location-dot",
      title: "Location",
    },
    {
      faIcon: "fa fa-heart",
      title: "Favorites",
    },
    {
      faIcon: "fa fa-pen-to-square",
      title: "Reviews",
    },
    {
      faIcon: "fa fa-users",
      title: "Family & Friends",
    },
    {
      faIcon: "fa fa-lock",
      title: "Change Password",
    },
    {
      faIcon: "fa-solid fa-money-bill-wave",
      title: "Account Balance",
    },
    {
      faIcon: "fa-solid fa-qrcode",
      title: "Your Qr code",
    },
  ];

  const verticalMenuItems2 = [
    {
      faIcon: "fa fa-cog",
      title: "Account Setting",
    },
    {
      faIcon: "fa fa-location-dot",
      title: "Location",
    },
    {
      faIcon: "fa fa-heart",
      title: "Favorites",
    },
    {
      faIcon: "fa fa-pen-to-square",
      title: "Reviews",
    },
    {
      faIcon: "fa fa-users",
      title: "Family & Friends",
    },
    {
      faIcon: "fa fa-lock",
      title: "Change Password",
    },
    {
      faIcon: "fa-solid fa-money-bill-wave",
      title: "Account Balance",
    },
  ];

  const handleVerticalMenuItemClick = (item: any, key: any) => {
    if (isLocationChanged) {
      setShowUnsavedModal(true);
      setPendingMenuChange(key);
    } else {
      setActiveMenu(key);
    }
  };

  const handleChangeLocation = () => {
    if (changeLocationBtnRef.current) {
      changeLocationBtnRef.current.innerHTML = "loading...";

      const formData = new FormData();
      formData.set("address", address as string);
      if (position?.center.lat) {
        formData.set("lat", position?.center.lat as unknown as string);
        formData.set("lng", position?.center.lng as unknown as string);
      }

      formData.set("service_radius", areaRadius as string);

      console.log("The form location data", formData);

      dispatch(updateUser(formData) as unknown as AnyAction)
        .unwrap()
        .then((response: any) => {
          console.log("response", response);
          toast.success("We have successfully updated your location");
          setIsLocationChanged(false);
        })
        .catch((error: any) => {
          console.log(error);
          toast.error("Something went wrong, please try again.");
        })
        .finally(() => {
          changeLocationBtnRef.current!!.innerHTML = "Save";
          setBtnLocation(true);
        });
    }
  };

  const handleDiscardChanges = () => {
    setIsLocationChanged(false);
    setShowUnsavedModal(false);
    if (pendingMenuChange !== null) {
      setActiveMenu(pendingMenuChange);
      setPendingMenuChange(null);
    }
  };

  const handleSaveChanges = () => {
    handleChangeLocation();
    setShowUnsavedModal(false);
    if (pendingMenuChange !== null) {
      setActiveMenu(pendingMenuChange);
      setPendingMenuChange(null);
    }
  };

  const defaultLocation = {
    center: {
      ...(currentUser?.location
        ? {
            lng: currentUser.location.coordinates[0],
            lat: currentUser.location.coordinates[1],
          }
        : { lat: 0, lng: 0 }),
    },
    zoom: 11,
  };

  const handleUpload = async (formData: FormData) => {
    const imageName = "profile";
    formData.append(imageName, formData.get("file") as File);
    formData.delete("file");

    return dispatch(updateUser(formData) as unknown as AnyAction).unwrap();
  };

  useEffect(() => {
    if (currentUser && salon) {
      console.log("The current user", currentUser);
      console.log("valeur du salon", salon);
    }
  }, [currentUser, salon]);

  console.log("currentUser", currentUser);

  return (
    <Elements stripe={stripePromise}>
      <AuthLayout>
        <MainNav navType={"connectedNav"} isSearch={true} />
        <div className="container p-lg-0">
          <div className="d-flex container_grid">
            <div className="d-flex flex-column left_vertical">
              <div className="child_one d-flex flex-column border-end p-0 m-0">
                <div className={"side_bar "}>
                  <div className="pic_container gap-3 align-items-center pt-4 pb-4">
                    <div className="profilePictureClient shadow bg-white position-relative">
                      <img
                        src={
                          currentUser?.profile ??
                          require("../assets/images/profile.jpg")
                        }
                        alt={""}
                      />
                      <div
                        style={{
                          right: "0px",
                          top: "70px",
                        }}
                        onClick={() => setOpenEditorModal(true)}
                        className="d-flex cursor-pointer align-items-center bg-primary justify-content-center z-3 p-1 rounded-circle position-absolute"
                      >
                        <i className="fa-solid fa-plus text-white"></i>
                      </div>
                    </div>
                    <div className="profile_info w-100">
                      {currentUser !== null ? (
                        <div className="w-100">
                          <h4 className="text-black fw-500">
                            {formatName(
                              currentUser.first_name,
                              currentUser.last_name
                            )}
                          </h4>
                          <span className="text-muted small">
                            <i className="fa-solid fa-location-dot"></i>{" "}
                            {currentUser?.address ?? "No location provided"}
                          </span>
                        </div>
                      ) : (
                        <div className="w-100 d-flex flex-column gap-2 justify-content-start">
                          <div className="shimmer__line shimmer shimmer__shadow ms-0 border-0 p-1"></div>
                          <div className="shimmer__line shimmer shimmer__shadow ms-0 border-0 p-1 "></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <CardElement /> */}
              <VerticalMenu
                active={activeMenu}
                items={salon ? verticalMenuItems1 : verticalMenuItems2}
                onItemClick={handleVerticalMenuItemClick}
              />
            </div>
            <div className="col p-3 pt-4 menu">
              {currentUser !== null ? (
                <div className="menu_container">
                  {activeMenu === 0 && <AccountSetting />}
                  {activeMenu === 1 && (
                    <div className="d-flex flex-column">
                      <Location
                        salon={salon}
                        user={currentUser}
                        containerStyle={{ height: "50vh" }}
                        onLocationSelect={(latLng, address) => {
                          setAddress(address);
                          setPosition((pos: any) => {
                            return { ...pos, center: latLng };
                          });
                          setBtnLocation(false);
                          setIsLocationChanged(true);
                        }}
                        showRadius={false}
                        radius={"1"}
                        onRadiusChange={setAreaRadius}
                        {...(currentUser?.address
                          ? {
                              defaultAddress: currentUser.address,
                              defaultLocation: defaultLocation,
                            }
                          : {})}
                      />
                      <div className="ms-auto">
                        <Button
                          ref={changeLocationBtnRef}
                          type="submit"
                          disabled={btnLocation}
                          onClick={handleChangeLocation}
                          content={<span className="mx-3">Save</span>}
                        />
                      </div>
                    </div>
                  )}
                  {activeMenu === 2 && <Favorites />}
                  {activeMenu === 3 && <Review />}
                  {activeMenu === 4 && <FamilyFriend />}
                  {activeMenu === 5 && <ChangePassword />}
                  {activeMenu === 6 && <AccountBalance />}
                  {activeMenu === 7 && salon && (
                    <QrCodePage value={`${salon?.qrcodePing}`} />
                  )}
                </div>
              ) : (
                <PulseLoader />
              )}
            </div>
          </div>
          <ImageEditorModal
            onUpload={handleUpload}
            message={"CLICK TO UPLOAD OR DROP A PROFILE IMAGE"}
            onClose={() => setOpenEditorModal(false)}
            show={openEditorModal}
            rounded={true}
            ratio={1}
            title={"Edit profile image"}
          />
            <UnsaveData
          submitIsChangeBtnRef={changeLocationBtnRef}
          onSave={handleSaveChanges}
          message="Do you really want to exit without saving your changes?"
          onCancel={handleDiscardChanges}
          open={showUnsavedModal}
          onClose={() => {
            setIsLocationChanged(false);
            setShowUnsavedModal(false);
            if (pendingMenuChange !== null) {
              setActiveMenu(pendingMenuChange);
              setPendingMenuChange(null);
            }
          }}
        />
        </div>
      
        <Footer />
      </AuthLayout>
    </Elements>
  );
};

export default ClientProfilePage;
