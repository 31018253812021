import MainNav from "../components/navigation/MainNav";
import "./../styles/BusinessProfilePage.css";
import { useEffect, useState } from "react";
import "../styles/ServiceMenu.css";
import Icons from "../components/reusable/Icons";
import toast from "react-hot-toast";
import {
  ServiceNameState,
  getServiceNames,
} from "../redux/slices/serviceNameSlice";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import {
  ServiceState,
  getServices,
  clearErrors,
  getUserServices,
} from "../redux/slices/serviceSlice";
import AuthLayout from "../components/auth/AuthLayout";
import Service from "../models/Service";
import ServicesList from "../components/serviceMenu/ServicesList";
import AddService from "../components/serviceMenu/AddService";

const ServiceMenuPage = () => {
  const [showAddService, setShowAddService] = useState(false);

  const dispatch = useDispatch();

  const serviceNameData = useSelector<unknown, ServiceNameState>(
    (state: any) => state.serviceNames
  ).serviceNames;

  const services = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).services;
  const userServices = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).userServices;
  const servicesErrors = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).errors;

  useEffect(() => {
    if (servicesErrors) {
      let errorMessage;
      let categoryError;
      if (servicesErrors.status === 409) {
        errorMessage = servicesErrors.errors.message;
      } else if (servicesErrors.status === 422) {
        errorMessage = servicesErrors.errors.traveling_expenses;
        categoryError = servicesErrors.errors.service_categorie;
      }
      if (!categoryError) toast.error(errorMessage);
      if (!errorMessage) {
        toast.error(categoryError);
      }
    }
    return () => {
      dispatch(clearErrors());
    };
  }, [servicesErrors]);

  useEffect(() => {
    if (!serviceNameData) {
      dispatch(getServiceNames("") as unknown as AnyAction);
    }
    if (!services) {
      dispatch(getServices("") as unknown as AnyAction);
    }
    dispatch(getUserServices("") as unknown as AnyAction);
  }, []);


useEffect(()=>{

  console.log("The user service are " , userServices)

},[])

  
  return (
    <AuthLayout>
      <MainNav navType={"connectedNav"} />
      <div className="BusinessProfilePage">
        <div className="d-flex container p-lg-0 gap-2">
          <div className="w-100 mt-5 d-flex flex-column gap-4 h-100">
            <div className="w-100 bg-white rounded add-service-header border p-3 d-flex justify-content-between align-items-center">
              <h5 className="m-0 text-primary">Your services</h5>
              <div
                onClick={() => {
                  setShowAddService(true);
                }}
                className="d-flex align-items-center gap-2 add-service"
              >
                <span className="text-primary hidden-sm">
                  Add A New Service
                </span>
                <button className="btn-secondary rounded-circle border-0  p-2 btn-add-service">
                  <i className="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>
            <AddService
              setShowAddService={setShowAddService}
              showAddService={showAddService}
            />
            <ServicesList services={userServices as Service[]} />
          </div>
          <div className="menuPage_icon" style={{ alignSelf: "center" }}>
            <Icons />
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ServiceMenuPage;
