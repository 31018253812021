import React from 'react'
import { DiscussionSystemProps } from '../../LatriceDiscussionMessage'
import { PendingDiscussionBase } from './PendingDiscussionBase'
import { Alert } from 'react-bootstrap'

export const PendingDiscussionUser = (props: DiscussionSystemProps) => {
    const appointmentUpdatedAt = (new Date(props.appointment.updatedAt)).getTime();
    const pendingResponseTimeout = 6 * 60 * 60 * 1000;

    return (
        <PendingDiscussionBase
            refererDate={appointmentUpdatedAt}
            duration={pendingResponseTimeout}
            elapsedTime={0}
            {...props}
            alertContainer={
                <Alert className='alert-info--bordered' key={'info'} variant={'info'}>
                    <i className="fa fa-triangle-exclamation fa-1x"></i>
                    You have requested an custom appointment.
                    We are currently awaiting the professional's response to proceed further.
                </Alert>
            } />
    )
}
