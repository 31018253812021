import moment from "moment";
import React from "react";
import MonthPicker from "../reusable/MonthPicker";
import SlidePicker from "../SlidePicker";

type DatePickerProps = {
  setSelectedMonth: (date: moment.Moment) => void;
  selectedMonthDates: any[];
  selectedDate: any;
  handleSelectDay: (selectedDate: moment.Moment) => void;
};

const DatePicker: React.FC<DatePickerProps> = ({
  setSelectedMonth,
  selectedMonthDates,
  selectedDate,
  handleSelectDay,
}) => {
  return (
    <div className="d-flex flex-column justify-content-center">
      <div className="mb-3 d-flex justify-content-center">
        <MonthPicker onChange={setSelectedMonth} />
      </div>
      <div className="w-100">
        <SlidePicker
          data={selectedMonthDates}
          selected={selectedDate}
          maxShow={7}
          keyExtractor={(item) => item.format("YYYY-MM-DD")}
          separator={<span className="mx-1"></span>}
          renderItem={(item) => (
            <div className="dateItem d-flex flex-column fw-bold p-2 br-6 btn-secondary">
              <div>{item.format("ddd")}</div>
              <div>{item.date()}</div>
            </div>
          )}
          onSelectItem={handleSelectDay}
        />
      </div>
    </div>
  );
};

export default DatePicker;
