import moment from "moment";
import React from "react";
import { TSalonAccountType } from "../../../utils/types";
import { formatUSPrice } from "../../../utils/utils";
import { AdditionalInfoStepProps } from "./AdditionnalInfoStep";
import { TProjectService } from "../../../models/Project";

export type ValidationStepProps = {
  onPost: () => void;
  errors?: string[];
  projectDetails: {
    numberOfProjects: number;
    date: moment.Moment;
    time: moment.Moment;
    target: TSalonAccountType;
    totalPrice: number;
    additionalInfos?: AdditionalInfoStepProps;
    addedServices: TProjectService[];
  };
  editState?: boolean;
};

const ValidationStep: React.FC<ValidationStepProps> = ({
  onPost,
  editState,
  errors,
  projectDetails,
}) => {
  const handlePostProject = () => {
    onPost();
  };

  return (
    <div className="mt-5 d-flex flex-column align-items-cen ter gap-4">
      {errors && errors.length > 0 && (
        <div className="alert alert-danger pb-0" role="alert">
          <ul>
            {errors.map((error) => {
              return <li>{error}</li>;
            })}
          </ul>
        </div>
      )}

      <div>
        <p className="fs-6 text-black">
          Before you proceed, let's review the details of your upcoming project.
          You're on the verge of posting a project that includes{" "}
          <strong>{projectDetails.numberOfProjects} services</strong>. This
          project is scheduled for{" "}
          <strong>{projectDetails.date.format("DD MMM YYYY")}</strong>, starting
          at <strong>{projectDetails.time.format("H:mm A")}</strong> the total
          price is <strong>{formatUSPrice(projectDetails.totalPrice)}</strong>.
          <br />
          {projectDetails.target === "both" &&
            "Both salon and independent accounts are eligible to accept this project."}
          {projectDetails.target === "independent" &&
            "Only independent accounts are eligible to accept this project."}
          {projectDetails.target === "business_owner" &&
            "Only Salon accounts are eligible to accept this project."}
        </p>
        <p className="fs-6 text-black">
          <strong>
            {projectDetails.additionalInfos?.specialEvent
              ? `In addition, you would also like to have your hair done for a special event and for it , your will pay ${projectDetails.additionalInfos?.additionalExpense}$`
              : ""}
          </strong>
          <p>
            You are located at{" "}
            {projectDetails.additionalInfos?.location?.address}
          </p>
          <br />
          <p>
            {projectDetails?.additionalInfos?.driveToYourPlace
              ? `You want the service provider to come to you , and the price of the trip is ${projectDetails?.additionalInfos?.travelingExpenses}$ `
              : "You will go to the service provider"}
          </p>
          <p>
            Here are the services you have selected: <br />
          </p>
          {projectDetails.addedServices.map((service) => (
            <p>
              <strong>
                - Your service is{service.name} and the price is {service.price}
              </strong>
            </p>
          ))}
        </p>
        <p className="fs-6 text-black">
          Before you make it official, take a moment to double-check the
          details. Ensure that the date, time, and services align with your
          preferences. Once you're confident that everything is in order, you
          can confidently click the "Post the Project" button below.
        </p>
        <p className="fs-6 text-black">
          If you need to make any changes, feel free to go back and adjust the
          details. We're here to help you make the most out of your experience
          on our platform. Thank you for choosing us, and we look forward to
          connecting you with the perfect service providers!
        </p>
      </div>
      <button
        className="working-table-primary-button ms-auto"
        onClick={() => handlePostProject()}
      >
        {editState ? "Edit this project" : ""} Post this project
      </button>
    </div>
  );
};

export default ValidationStep;
