const ServiceLoader = () => {
    return <div className="d-flex flex-column w-100">
        <div className="d-flex justify-content-between professional_service align-items-center w-100">
            <div className="d-flex flex-column gap-2">
                <h5 className="text-black">
                    <div style={{ width: '200px', height: '20px', borderWidth: '1px', borderRadius: '0px' }} className="shimmer p-0 m-0 shimmer__line shimmer__shadow"></div>
                </h5>
                <span className="text-muted">
                    <div style={{ width: '100px', height: '17px', borderWidth: '1px', borderRadius: '0px'  }} className="shimmer p-0 m-0 shimmer__line shimmer__shadow"></div>
                </span>
                <span className="text-muted">
                    <div style={{ width: '120px', height: '17px', borderWidth: '1px', borderRadius: '0px'  }} className="shimmer p-0 m-0 shimmer__line shimmer__shadow"></div>
                </span>
            </div>
            <div>
                <div style={{ width: '100px', height: '38px', borderWidth: '1px', borderRadius: '6px'  }} className="shimmer d-flex  align-items-center justify-content-center shimmer__square shimmer__shadow text-muted user-select-none">Book</div>
            </div>
        </div>
    </div>
}
export default ServiceLoader;