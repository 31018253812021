import "./../styles/Nuggest.css";
import { Stars } from "./Stars";

const Nuggest = ({ image, title, localisation, stars, notice }) => {
  return (
    <div
      className="Nuggest d-flex flex-row shadow m-0 mt-3"
      // style={{ maxWidth: "400px" }}
    >
      <div className="object-fit-cover h-100  overflow-hidden  ">
        <img
          width={"190px"}
          height={"170px"}
          src={image}
          alt="salon image"
          className=" object-fit-cover overflow-hidden  "
        />
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column justify-content-around p-3">
          <h4 className="text-black fw-500">{title}</h4>
          <span className="text-muted small">
            <i className="fa-solid fa-location-dot"></i> {localisation}
          </span>
          <span className="mt-1 d-flex justify-content-center align-items-center">
            <div className="starsContainer">
              {/* {Array(stars)
                .fill(0)
                .map(() => (
                  <i className="fa fa-star text-muted" aria-hidden="true"></i>
                ))} */}
              <Stars stars={stars} notice={notice} />
            </div>
            {/* <div className="ms-2">
              <span className="fw-bold">{stars}</span>
              <span className="text-muted small ms-1">({notice})</span>
            </div> */}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Nuggest;
