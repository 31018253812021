import React, { ReactNode, RefObject, useRef, useState } from "react";
import "../../styles/SearchBar.css";
import AccordionItem from "../accordion/AccordionItem";
import { vi } from "date-fns/locale";

interface DataItem {
  id: number; // Assuming id is a number, adjust if it's a different type
  icon: string;
  title: string;
  content: ReactNode;
}

interface SupportItemProps {
  supports: DataItem[] | null | undefined;
  searchable?: boolean;
  visible: boolean;
  inputRef: RefObject<HTMLInputElement>;
  onShow: () => void;
  onSelect?: (support: DataItem) => void;
}

export const SearchBar: React.FC<SupportItemProps> = ({
  supports,
  inputRef,
  searchable = true,
  visible = true,
  onShow,
}) => {
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<
    DataItem[] | null | undefined
  >(supports);
  const [currentIdx, setCurrentIdx] = useState(-1);
  const [isShowResult, setShowIsResult] = useState(false);
  const [maxItems, setMaxItems] = useState(4);

  // const filteredSupport =
  //   supports &&
  //   supports
  //     .filter((support) =>
  //       searchTerm === null
  //         ? true
  //         : support.title.toLowerCase().includes(searchTerm.toLocaleUpperCase())
  //     )
  //     .slice(0, maxItems);

  const filteredSupport = (searchTerm: string) => {
    const filteredData = supports
      ?.filter((support) =>
        searchTerm === null
          ? true
          : support.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .slice(0, maxItems);
    setFilteredData(filteredData);
  };

  const btnOnClick = (index: number) => {
    setCurrentIdx((currentValue) => (currentValue !== index ? index : -1));
  };

  const handleShowMore = () => {
    setMaxItems(maxItems + 2);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
    filteredSupport(value);
    if (value === "") {
      setShowIsResult(isShowResult);
    } else {
      setShowIsResult(!isShowResult);
    }
  };

  const hightlightSupportTilte = (title: string, search: string) => {
    const parts = title.split(new RegExp(`(${search})`, "gi"));
    return parts.map((part, index) => {
      if (part.toLowerCase() === search.toLowerCase()) {
        return (
          <span key={index} className="text-white bg-primary">
            {part}
          </span>
        );
      } else {
        return part;
      }
    });
  };

  return (
    <>
      {searchable && (
        <div className="input-container">
          <div className="input-content">
            <div className="icon-content">
              <i className=" fa fa-search"></i>
            </div>
            <input
              type="search"
              className="InputSearch"
              placeholder="search for a question, keywords or topics"
              value={searchTerm ?? ""}
              onChange={handleInputChange}
              onClick={onShow}
              ref={inputRef}
            />
          </div>
        </div>
      )}
      {filteredData &&
        (visible ? (
          <div className="data-support-result">
            {filteredData.length > 0 ? (
              <div className="search-result">
                {filteredData
                  .map((item: DataItem) => (
                    <AccordionItem
                      key={item.id}
                      data={item}
                      isOpen={item.id === currentIdx}
                      btnOnClick={() => btnOnClick(item.id)}
                    />
                  ))
                  .slice(0, maxItems)}

                {filteredData.length < maxItems && (
                  <button onClick={handleShowMore} className="btn-result">
                    Voir plus
                  </button>
                )}
              </div>
            ) : (
              <div
                style={{ minHeight: "50vh" }}
                className="w-100 d-flex justify-content-center align-items-center small text-muted flex-column fs-6"
              >
                <i className="fas fa-search-minus fa-3x mb-2"></i>
                <h4>Oops! No Results Found</h4>
                <span className="text-center">
                  We couldn't find anything based on your search.
                </span>
              </div>
            )}
          </div>
        ) : (
          ""
        ))}
    </>
  );
};
