import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChair, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Stars } from "../Stars";
import Button from "../Button";
import { ModalLoader } from "../modal/commons/ModalLoader";
import SwitchMemberItem from "../reusable/SwitchMemberItem";
import MemberType from "../../models/Member";
import "./../../styles/BusinessProfilePage.css";
import { useDispatch } from "react-redux";
import { sendAppointmentRequest } from "../../redux/slices/memberSlice";
import { AnyAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

type SelectMemberModalProps = {
  show: boolean;
  members: MemberType[];
  memberBookData: any;
  onSendBookRequest: () => void;
  onClose: () => void;
};

const SelectMemberModal: React.FC<SelectMemberModalProps> = ({
  show,
  onClose,
  onSendBookRequest,
  members,
  memberBookData,
}) => {
  const [q, setQ] = useState("");
  const [selectedMember, setSelectedMember] = useState<MemberType | null>(null);
  const [loading, setLoading] = useState(false);
  const [member, setMember] = useState<MemberType | null>(null);

  const dispatch = useDispatch();
  const handleSelectMemberForAppointment = () => {
    if (selectedMember) {
      //   localStorage.setItem("memberId", selectedMember._id);
      setMember(selectedMember);

      const data = {
        selectedMember,
        memberBookData,
      };
      console.log("here is appointment i send to member", data);
      if (selectedMember.status === "accepted") {
        dispatch(sendAppointmentRequest(data) as unknown as AnyAction)
          .unwrap()
          .then((res: any) => {
            //   props.onBook();
            onClose();
            onSendBookRequest();
            setLoading(true);
          })
          .catch((err: any) => {
            console.log("error", err);
            // const errors = err.errors;
            // if (err.status) {
            //   if (err.status === 500) {
            //     return toast.error(errors.message, {
            //       duration: 7000,
            //     });
            //   } else {
            //     if (errors.code === "INSUFFICIENT_BALANCE") {
            //       return toast.error(
            //         "Insufficient Balance! Please add funds to your account to book the selected services.",
            //         {
            //           duration: 7000,
            //         }
            //       );
            //     }
            //     return toast.error(errors.message, {
            //       duration: 7000,
            //     });
            //   }
            // }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error("We expect the customer to accept you as a member");
      }
    }
  };

  const filteredMembers = members.filter((member: MemberType) => {
    if (q.length > 0) {
      return member.userName
        ?.toLocaleLowerCase()
        .includes(q.trim().toLocaleLowerCase());
    }
    return true;
  });

  React.useEffect(() => {
    setSelectedMember(member);
    console.log("the selected member is", member);
  }, [member]);

  useEffect(() => {
    console.log("list of all member in the store", members);
  }, []);

  return (
    <Modal show={show} centered onHide={onClose} size="xl" backdrop="static">
      <Modal.Header title="Switch the current salon" closeButton={!loading}>
        <h5>Select the person who will pay for this service or</h5>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <ModalLoader
            label={
              <span className="fw-bold">
                we are setting the selected salon as current, please wait...
              </span>
            }
          />
        )}
        <div className="mx-3 mb-4">
          <div
            className="input-group d-flex m-0 mb-4 p-0 w-100 border mb-2 align-items-center"
            aria-autocomplete="none"
          >
            <button
              style={{ backgroundColor: "white", height: "45px" }}
              className="btn border-0"
              type="button"
            >
              <i className="fa-solid fa-search"></i>
            </button>
            <input
              value={q ?? ""}
              onChange={(e) => setQ(e.target.value)}
              placeholder="Search your salon..."
              type="text"
              className="form-control border-0  no-decoration fw-500"
            />
          </div>

          <div className="switch-salon-items-container mt-5 d-flex flex-column gap-3">
            {filteredMembers.map((member: MemberType) => {
              return (
                <SwitchMemberItem
                  key={member._id}
                  member={member}
                  selected={selectedMember?._id === member._id}
                  onClick={setSelectedMember}
                />
              );
            })}
            {filteredMembers.length === 0 && (
              <div
                style={{ userSelect: "none" }}
                className="d-flex flex-column justify-content-center align-items-center gap-4"
              >
                <FontAwesomeIcon
                  style={{ color: "var(--textPrimaryColor)" }}
                  icon={faChair}
                  size="5x"
                  className="text-muted"
                />
                <div className="text-muted">No salon found !</div>
              </div>
            )}
          </div>

          <div className=" mt-5">
            <Button
              onClick={handleSelectMemberForAppointment}
              style={{ height: "45px" }}
              disabled={
                !selectedMember || loading || selectedMember._id === member?._id
              }
              className="set-as-current-btn primary w-100 br-6"
              content={"Send your appoitmet"}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(SelectMemberModal);
