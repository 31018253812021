import { AnyAction } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUser } from "../../hooks/UseUser";
import {
  AuthState,
  deleteBookmark,
  getUserBookmarks,
} from "../../redux/slices/authSlice";
import Favorite from "../Favorite";
import { Selector } from "../Selector";
import { ISalon } from "../../models/SalonX";
import SearchResultItem from "../page/home/search/SearchResultItem";
import toast from "react-hot-toast";
import IUser from "../../models/User";

export interface BookmarkOptions {}

const Bookmark: React.FC<BookmarkOptions> = () => {
  const currentUser = useUser();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [reRrender, setReRender] = useState(false);
  const [showBookmark, setShowBookmark] = useState<any[]>([]);
  const dispatch = useDispatch();

  if (currentUser === null) return null;

  const bookmarks = currentUser.bookmarks;

  console.log(bookmarks);

  const handleDelete = () => {
    toast.remove();
    toast.loading("deleting  your bookmark");
    const data = {
      elementKey: selectedItems,
    };
    dispatch(deleteBookmark(data) as unknown as AnyAction)
      .unwrap()
      .then((data: any) => {
        setSelectedAll(false);
        setSelectedItems([]);
        setReRender(!reRrender);
        toast.remove();
        toast.success("Your bookmark has been deleted.");
        setReRender(!reRrender);
      })
      .catch((err: any) => {
        toast.error("Something went wrong, please try again");
        console.log(err);
      });
  };

  const handleSelect = (value: boolean, key: number) => {
    let updatedSelectedItems = selectedItems.slice(); // Make a copy of selectedItems

    if (selectedAll) {
      // If selectedAll is true, remove key from updatedSelectedItems
      updatedSelectedItems = updatedSelectedItems.filter(
        (itemKey) => itemKey !== key
      );
    } else {
      // Otherwise, update selectedItems as before
      if (value) {
        if (!updatedSelectedItems.includes(key)) {
          updatedSelectedItems.push(key);
          console.log("updatedSelectedItems not all", updatedSelectedItems);
        }
      } else {
        updatedSelectedItems = updatedSelectedItems.filter(
          (itemKey) => itemKey !== key
        );
      }
    }

    setSelectedItems(updatedSelectedItems);
    setSelectedAll(updatedSelectedItems.length === bookmarks.length);
  };

  const handleSelectAll = () => {
    let updatedSelectedItems: number[] = [];
    let updatedSelectedAll = !selectedAll;

    if (!selectedAll) {
      updatedSelectedItems = bookmarks.map((_: any, index: number) => index);
      console.log("updatedSelectedItems", updatedSelectedItems);
    }

    setSelectedItems(updatedSelectedItems);
    setSelectedAll(updatedSelectedAll);
  };

  return (
    <div className="Favorites w-100 ">
      <div className="d-flex justify-content-between">
        <div>
          <h3 className="text-primary">Bookmark</h3>
          <h5>More details bookmark profile</h5>
        </div>
        <div className="d-flex align-items-center">
          <div className="form-check form-check-inline d-flex gap-1 align-items-center">
            <input
              onClick={(e) => {
                handleSelectAll();
              }}
              className="form-check-input mb-1"
              type="checkbox"
              id="selectAll"
              value="option1"
              checked={selectedAll}
            />
            <label className="form-check-label" htmlFor="selectAll">
              Select all
              {selectedItems.length > 0
                ? ` (${selectedItems.length} items)`
                : ""}
            </label>
            <button
              onClick={handleDelete}
              className={
                "btn btn-primary " +
                (selectedItems.length > 0 ? "d-flex" : "d-none")
              }
            >
              Delete
            </button>
          </div>
        </div>
      </div>

      <div className="d-flex FavoritesContainer gap-3 flex-wrap w-100">
        {bookmarks.map((bookmark: any, key: number) => {
          return (
            <Selector
              key={key}
              selected={selectedItems.includes(key)}
              selectedAll={selectedAll}
              onSelect={(value) => {
                handleSelect(value, key);
              }}
              child={
                <Favorite
                  id={bookmark._id}
                  cover={bookmark.cover}
                  profile={bookmark.profile}
                  name={`${bookmark.name}`}
                  localization={bookmark.address}
                  stars={0}
                  notice={0}
                  available={{ state: "available", value: true }}
                  account_type={bookmark.account_type as string}
                />
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default Bookmark;
