import Modal from "react-bootstrap/Modal";
import "../styles/ImageEditorModal.css";
import { formatFileSize, log } from "../../utils/utils";
import { useState } from "react";
import { toast } from "react-hot-toast";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { PixelCrop, type Crop } from "react-image-crop";
import { Tab, Tabs } from "react-bootstrap";

export interface ImageEditorModalProps {
  show: boolean;
  title: string;
  message?: string;
  ratio?: number;
  rounded?: boolean;
  onClose: () => void;  
  onUpload: (formData: FormData) => Promise<any>;
}

const ImageEditorModal: React.FC<ImageEditorModalProps> = ({
  title,
  show = false,
  rounded = false,
  message,
  ratio = 16 / 9,
  onClose,
  onUpload,
}: ImageEditorModalProps) => {
  const [label, setLabel] = useState<{
    name: string | null;
    size: string | null;
    type: string | null;
    url: string | null;
    width: string | null;
    height: string | null;
  }>({
    name: null,
    size: null,
    type: null,
    url: null,
    width: null,
    height: null,
  });

  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const [activeTab, setActiveTab] = useState<"editor" | "preview">("editor");

  const handleTabSelect = (selectedTab: typeof activeTab) => {
    setActiveTab(selectedTab);
  };

  const handleCropChange = (value: PixelCrop) => {
    setCrop(value);
    setTimeout(() => {
      handleApplyCrop(label.url);
    }, 20);
  };

  const handleApplyCrop = (imageUrl: string | null) => {
    if (!imageUrl) return;
    const canvas = document.createElement("canvas");
    const image = new Image();

    image.src = imageUrl;
    image.onload = () => {
      if (!crop) return;
      canvas.width = crop.width;
      canvas.height = crop.height;

      const context = canvas.getContext("2d");
      if (!context || !crop) return;
      context.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob(
        (blob: Blob | null) => {
          if (blob) {
            setFile(blob);
            setCanValidate(true);
          }
        },
        "image/jpeg",
        1
      );

      setFileUrl(canvas.toDataURL());
    };
  };

  const [error, setError] = useState<string | null>(null);
  const [canValidate, setCanValidate] = useState<boolean>(false);
  const [onValidate, setOnValidate] = useState<boolean>(false);

  const [file, setFile] = useState<Blob | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const handleValidate = async () => {
    setOnValidate(true);
    toast.remove();
    toast.loading("We are uploading your image...");
    const formData = new FormData();
    formData.append("file", file as Blob);
    try {
      await onUpload(formData);
      toast.remove();
      toast.success(`The image has been successfully uploaded!`, {
        position: "bottom-right",
      });
      handleClose();
    } catch (error) {
      toast.remove();
      setError(error as string);
      setError("An error occurred");
      log(error);
    }
    setOnValidate(false);
    setCanValidate(true);
  };

  function getImageDimensions(url: string) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.addEventListener("load", () => {
        const dimensions = {
          width: img.width,
          height: img.height,
        };

        resolve(dimensions);
      });

      img.addEventListener("error", () => {
        reject(new Error("Failed to load image"));
      });

      img.src = url;
    });
  }

  const handleSelectImage = () => {
    if (onValidate) return;
    const input = document.createElement("input");
    input.setAttribute("type", "file");

    setLabel({
      name: null,
      size: null,
      type: null,
      url: null,
      height: null,
      width: null,
    });
    setError(null);

    const reader = new FileReader();

    input.onchange = (e: any) => {
      const file = e.target.files[0];
      setFile(file);
      setLabel((lastLabel) => {
        return {
          ...lastLabel,
          name: file.name,
          size: file.size,
          type: file.type,
        };
      });
      reader.readAsDataURL(file);
    };

    reader.onloadend = (e: any) => {
      getImageDimensions(e.target.result)
        .then((dimensions: any) => {
          const { width, height } = dimensions;
          setLabel((lastLabel) => {
            return {
              ...lastLabel,
              url: e.target.result,
              width,
              height,
            };
          });
          if (ratio - width / height > 0.05) {
            setError("Image dimensions you provided are not correct.");
            setCanValidate(false);
          } else {
            setCanValidate(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
    };

    input.click();
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: any) => {};

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;

    // Check if there are files in the dropped data
    if (files.length > 0) {
      const file = files[0];

      // Check if the dropped item is a file
      if (file instanceof File) {
        setLabel({
          name: null,
          size: null,
          type: null,
          url: null,
          height: null,
          width: null,
        });
        setError(null);

        const reader = new FileReader();

        setFile(file);
        setLabel((lastLabel) => {
          return {
            ...lastLabel,
            name: file.name,
            size: String(file.size), // Convert to string
            type: file.type,
          };
        });
        reader.onloadend = (e: any) => {
          getImageDimensions(e.target.result)
            .then((dimensions: any) => {
              const { width, height } = dimensions;

              setLabel((lastLabel) => {
                return {
                  ...lastLabel,
                  url: e.target.result,
                  width,
                  height,
                };
              });
              if (ratio - width / height > 0.05) {
                setError("Image dimensions you provided are not correct.");
                setCanValidate(false);
              } else {
                setCanValidate(true);
              }
            })
            .catch((error) => {
              console.error("Error:", error.message);
            });
        };

        reader.readAsDataURL(file);
      } else {
        // Handle the case where the dropped item is not a File
        setError("Dropped item is not a valid image file.");
      }
    }
  };

  const style = {
    width: `${rounded ? "200px" : "100%"}`,
    height: `${rounded ? 200 : 400}px`,
    borderRadius: rounded ? "100%" : "none",
  };

  const handleClose = () => {
    setError(null);
    setCanValidate(false);
    setLabel({
      name: null,
      size: null,
      type: null,
      url: null,
      width: null,
      height: null,
    });
    onClose();
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      centered
      size="lg"
      onHide={handleClose}
    >
      <Modal.Header closeButton={!onValidate}>
        <div className="text-black fw-500 fs-5">{title}</div>
      </Modal.Header>
      <Modal.Body className="d-flex m-1 align-items-center flex-column justify-content-center gap-2">
        <Tabs
          className="w-100"
          activeKey={activeTab}
          onSelect={(e) => handleTabSelect(e as any)}
        >
          <Tab className="w-100" eventKey="editor" title="Editor">
            <>
              {!label.url ? (
                <div
                  onDragEnter={handleDragEnter}
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onClick={handleSelectImage}
                  style={{
                    ...style,
                    maxWidth: "100%",
                    maxHeight: "200px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                  className={
                    "ImageEditorModal__container p-0 mx-auto d-flex align-items-center bg-light text-muted justify-content-center align-items-center" +
                    (onValidate ? " ImageEditorModal__disabled" : "")
                  }
                >
                  <div className="flex flex-column gap-2">
                    <i className="fa-solid fa-cloud-arrow-up fa-2x"></i>
                    <div className="small-text fw-bold text-black">
                      Drag and drop to upload
                    </div>
                    <div className="text-primary small-text">or browse</div>
                  </div>
                </div>
              ) : (
                <div className="p-2">
                  <ReactCrop crop={crop} onChange={handleCropChange}>
                    <img alt="" src={label.url} />
                  </ReactCrop>
                </div>
              )}

              {label.url && (
                <div
                  style={{
                    // width: `${ratio * 200}px`,
                    maxWidth: "100%",
                  }}
                  className="d-flex justify-content-start gap-2 align-items-center rounded text-light"
                >
                  <div className="border bg-light text-muted p-1">
                    {label.type}
                  </div>
                  <div
                    style={{ whiteSpace: "nowrap" }}
                    className="border bg-light text-muted p-1"
                  >
                    {label.size && formatFileSize(Number(label.size))}
                  </div>
                  <div className="border bg-light text-muted p-1">
                    {label.width}x{label.height}
                  </div>
                </div>
              )}
            </>
          </Tab>

          <Tab className="w-100" eventKey="preview" title="Preview">
            <img src={fileUrl ?? label.url ?? ""} />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer className="border-top">
        <div className="mx-3 w-100 my-1 d-flex justify-content-center align-items-center gap-3">
          {label && (
            <button
              disabled={onValidate ? true : !canValidate}
              onClick={handleSelectImage}
              className="working-table-outline-button"
            >
              Select another
            </button>
          )}
          <button
            disabled={onValidate ? true : !canValidate}
            onClick={handleValidate}
            className="working-table-primary-button"
          >
            <span className="text-white">
              {onValidate ? (
                "uploading..."
              ) : (
                <div>
                  <i className="fa fa-check-circle me-1" />
                  validate
                </div>
              )}
            </span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageEditorModal;
