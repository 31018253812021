import React, { useEffect, useState, KeyboardEvent } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import './../../styles/GoogleAutocomplete.css';

export type GoogleAutocompleteLocation = {
    latLng: { lat: number; lng: number },
    address: string
}

interface GoogleAutocompleteProps {
    apiKey?: string;
    onSelectLocation: (location: GoogleAutocompleteLocation) => void;
}

const GoogleAutocomplete: React.FC<GoogleAutocompleteProps> = ({ apiKey, onSelectLocation }) => {
    const [showPlacesList, setShowPlacesList] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<GoogleAutocompleteLocation | null>(
        null
    );
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = useGoogle({
        apiKey,
    });

    const autocompleteRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowDown') {
            setSelectedIndex((prevIndex) =>
                prevIndex < placePredictions.length - 1 ? prevIndex + 1 : 0
            );
        } else if (event.key === 'ArrowUp') {
            setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : placePredictions.length - 1));
        } else if (event.key === 'Enter' && selectedIndex !== -1) {
            handleSelectPrediction(placePredictions[selectedIndex].place_id);
        }
    };

    const handleSelectPrediction = (placeId: string) => {

        placesService?.getDetails(
            {
                placeId,
            },
            (placeDetails: any) => {
                const location = {
                    latLng: {
                        lat: placeDetails?.geometry?.location?.lat(),
                        lng: placeDetails?.geometry?.location?.lng(),
                    },
                    address: placeDetails?.formatted_address || '',
                };

                setSelectedLocation(location);
                if (inputRef.current) {
                    inputRef.current.value = location.address;
                }
            }
        );
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (autocompleteRef.current && !autocompleteRef.current.contains(event.target as Node)) {
            setShowPlacesList(false);
        }
    };

    const renderItem = (item: any) => {
        const selected = placePredictions.length > 0 && selectedIndex !== -1
            && item.place_id === placePredictions[selectedIndex].place_id

        return (
            <div
                className={`d-flex align-items-center gap-2 google-place-autocomplete__places__items ${selected ? 'selected' : ''}`}
                onClick={() => handleSelectPrediction(item.place_id)}
            >
                <div className='map_icon'>
                    <i className="fa fa-location-dot"></i>
                </div>
                <div className="d-flex flex-column">
                    <div className='fw-500'>{item.description.split(',').slice(2).join(',')}</div>
                    <div className='fw-400'>{item.description}</div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (selectedLocation) {
            onSelectLocation(selectedLocation);
            setShowPlacesList(false);
        }
    }, [selectedLocation]);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className='google-place-autocomplete d-flex flex-column w-100' ref={autocompleteRef}>
            <InputGroup className="input-group justify-content-start d-flex align-items-center bg-transparent w-100">
                <i className="fa-solid fa-location-crosshairs ms-2 fs-5"></i>
                <Form.Control
                    className='google-place-autocomplete__input ms-1'
                    type="text"
                    placeholder="Where..."
                    onKeyDown={handleKeyDown}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        getPlacePredictions({ input: evt.target.value });
                        setShowPlacesList(true);
                    }}
                    ref={inputRef}
                />
            </InputGroup>

            {showPlacesList && placePredictions.length !== 0 && (
                <div className='google-place-autocomplete__places w-100'>
                    {placePredictions.map((item: any, index: number) => {
                        return (
                            <div onMouseEnter={() => setSelectedIndex(index)} className='d-flex flex-column' key={item.place_id}>
                                {renderItem(item)}
                                <div
                                    style={{ width: '95%' }}
                                    className={`${index !== placePredictions.length - 1 ? ' mx-auto border-bottom' : ''}`}
                                ></div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default GoogleAutocomplete;