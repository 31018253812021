import { SetStateAction, useEffect, useRef, useState } from "react";
import MainNav from "../components/navigation/MainNav";
import VerticalMenu from "../components/VerticalMenu";
import "../styles/ClientProfilePage.css";
import styles from "../styles/AppointmentPage.module.css";
import Button from "../components/Button";
import Bookmark from "../components/account/Bookmark";
import Cancellation from "../components/account/Cancellation";
import AppointmentItem from "../components/appointment/AppointmentItem";
import AuthLayout from "../components/auth/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  AppointmentState,
  cancelAppointment,
  getAppointments,
} from "../redux/slices/appointmentSlice";
import { AnyAction } from "@reduxjs/toolkit";
import DefaultLoader from "../components/loader/DefaultLoader";
import moment from "moment";
import { IAppointment } from "../models/Appointment";
import {
  AppointmentCancellationState,
  getAppointmentCancellations,
} from "../redux/slices/appointmentCancellationSlice";
import { filterAppointment } from "../utils/utils";
import Dispute from "../components/account/Dispute";
import { AppointmentViewer } from "../components/appointment/AppointmentViewer";
import { GroupAppointmentModal } from "../components/appointment/GroupAppointmentModal";
import { DatePeriod } from "../components/appointment/AppointmentGraph";
import AppointmentDetailModal from "../components/modal/AppointmentDetailModal";
import Footer from "../components/Footer";

const AppointmentPageAdmin = () => {
  const [activeMenu, setActiveMenu] = useState(0);
  const [openAddAppointmentModal, setOpenAddAppointmentModal] = useState(false);
  const [graphWidth, setGraphWidth] = useState("70vw");
  const [selectedCalendarDate, setSelectedCalendarDate] =
    useState<moment.Moment>(moment());
  const [datePeriod, setDatePeriod] = useState<DatePeriod>("day");

  const verticalMenuItems = [
    {
      faIcon: "fa-solid fa-calendar-check",
      title: "Dates",
    },
    // {
    //   faIcon: "fa-solid fa-calendar-days",
    //   title: "Package Deals",
    // },
    // {
    //   faIcon: "fa-solid fa-calendar-minus",
    //   title: "Freeze your calendar",
    // },
    {
      faIcon: "fa-solid fa-calendar-xmark",
      title: "Cancelled Appointments",
    },
    {
      faIcon: "fa fa-triangle-exclamation",
      title: "Disputes",
    },
    // {
    //   faIcon: "fa fa-book",
    //   title: "Report",
    // },
  ];

  const handleVerticalMenuItemClick = (
    item: any,
    key: SetStateAction<number>
  ) => {
    setActiveMenu(key);
  };

  const verticalMenuRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const appointmentData = filterAppointment(
    useSelector<unknown, AppointmentState>((state: any) => state.appointments)
      .appointments,
    "confirmed"
  );
  const appointmentDataCancelled = filterAppointment(
    useSelector<unknown, AppointmentState>((state: any) => state.appointments)
      .appointments,
    "cancelled"
  );

  const appointmentCancellationData = useSelector<
    unknown,
    AppointmentCancellationState
  >((state: any) => state.appointmentCancellation).appointmentCancellations;

  const [selectedAppointment, setSelectedAppointment] =
    useState<IAppointment | null>(null);

  const toLocalTimeZone = (appointments: IAppointment[] | null) => {
    console.log(appointments);
    const timezone = moment.tz.guess();

    return appointments === null
      ? null
      : appointments.map((appointment) => {
          return {
            ...appointment,
            startedAt: moment
              .utc(appointment.startedAt, "HH:mm")
              .tz(timezone)
              .format("HH:mm A"),
            endedAt: moment
              .utc(appointment.endedAt, "HH:mm")
              .tz(timezone)
              .format("HH:mm A"),
            startTime: moment
              .utc(appointment.startTime, "HH:mm")
              .tz(timezone)
              .format("HH:mm A"),
            endTime: moment
              .utc(appointment.endTime, "HH:mm")
              .tz(timezone)
              .format("HH:mm A"),
          };
        });
  };

  const handleChangeCalendarDate = (date: moment.Moment) => {
    setSelectedCalendarDate(date);
  };

  const handleCancelAppointment = (appointment: IAppointment): Promise<any> => {
    return dispatch(
      cancelAppointment(appointment) as unknown as AnyAction
    ).unwrap();
  };

  const handleSelectAppointment = (appointment: IAppointment | null) => {
    setSelectedAppointment(appointment);
  };

  useEffect(() => {
    const calculateWidth = () => {
      if (verticalMenuRef.current && modalRef.current) {
        const menuWidth = verticalMenuRef.current.offsetWidth;
        const menuMargin = parseFloat(
          getComputedStyle(verticalMenuRef.current).marginRight
        );

        const modalWidth = modalRef.current.offsetWidth;
        const modalMargin = parseFloat(
          getComputedStyle(modalRef.current).marginRight
        );

        // Calculate the total width including content, padding, border, and margin
        const totalWidth = menuWidth + menuMargin + modalWidth + modalMargin;

        // Set the graph width
        setGraphWidth(`calc(100vw - ${totalWidth}px - 2em)`);
      }
    };

    // Recalculate width when the window is resized
    window.addEventListener("resize", calculateWidth);

    // Initial calculation when the component mounts
    calculateWidth();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", calculateWidth);
    };
  }, []);

  useEffect(() => {
    if (!appointmentData) {
      dispatch(getAppointments("dashboard") as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log(res);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    if (!appointmentCancellationData) {
      dispatch(getAppointmentCancellations() as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          console.log(res);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, []);

  
  return (
    <AuthLayout>
      <MainNav navType={"connectedNav"} isSearch={true} />
      <div className="contain m-0 p-0 p-lg-0">
        <div
          className={`${styles["appointment_page"]} container_grid p-0 m-0 w-100`}
        >
          <div
            ref={verticalMenuRef}
            className={`${styles["verticalMenu__container"]} border-end m-md-1`}
          >
            <VerticalMenu
              active={activeMenu}
              items={verticalMenuItems}
              onItemClick={handleVerticalMenuItemClick}
            />
          </div>
          {activeMenu === 0 && (
            <div className="d-flex w-100">
              <div className="col p-0 m-0 mx-auto">
                <div className="mx-auto w-100 position-relative">
                  <Button
                    disabled={appointmentData === null}
                    onClick={() =>
                      setOpenAddAppointmentModal(!openAddAppointmentModal)
                    }
                    className="br-6 ms-auto mt-2"
                    mode={"primary"}
                    content={<span className="mx-3">Manage</span>}
                  />
                  {appointmentData === null && (
                    <DefaultLoader title="We are fetching your appointments" />
                  )}
                  <AppointmentViewer
                    appointments={toLocalTimeZone(appointmentData) ?? []}
                    appointmentRender={(value) => (
                      <AppointmentItem
                        appointment={value}
                        mode={"group"}
                        period={datePeriod}
                      />
                    )}
                    startAt={selectedCalendarDate}
                    maxWidth={graphWidth}
                    onDatePeriodChange={setDatePeriod}
                  />
                </div>
              </div>
              <div
                ref={modalRef}
                className={`${styles["AddAppointmentModal__container"]} ${
                  !openAddAppointmentModal
                    ? styles["AddAppointmentModal__container--closed"]
                    : ""
                } rounded`}
              >
                <GroupAppointmentModal
                  mode={datePeriod}
                  onSelect={handleSelectAppointment}
                  onClose={() =>
                    setOpenAddAppointmentModal(!openAddAppointmentModal)
                  }
                  open={false}
                  appointments={toLocalTimeZone(appointmentData) ?? []}
                />
              </div>
            </div>
          )}

          {/** BOOKMARK */}

          {activeMenu === 2 && (
            <div className={`d-flex p-2`}>
              <Bookmark />
            </div>
          )}

          {/** BOOKMARK */}

          {/** CANCELLATION */}

          {activeMenu === 1 && (
            <div className={`d-flex p-2 w-100`}>
              <Cancellation appointments={appointmentCancellationData ?? []} />
            </div>
          )}

          {activeMenu === 3 && (
            <div className={`d-flex p-2 w-100`}>
              <Dispute
                appointments={(appointmentCancellationData ?? []).filter(
                  (item) =>
                    item.newDateProposed !== null && item.status !== "cancelled"
                )}
              />
            </div>
          )}

          {/** CANCELLATION */}
        </div>
      </div>
      {selectedAppointment !== null && (
        <AppointmentDetailModal
          backdrop="static"
          centered
          onHide={() => {
            setSelectedAppointment(null);
          }}
          show={selectedAppointment !== null}
          appointment={selectedAppointment}
          onCancel={handleCancelAppointment}
          onCancelCallback={() => {
            setSelectedAppointment(null);
          }}
        />
      )}
      <Footer />
    </AuthLayout>
  );
};

export default AppointmentPageAdmin;
