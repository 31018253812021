import React from "react"
import styles from './styles/ProfileImage.module.css'
import LazyLoadedImage from "./image/LazyLoadedImage"

export interface ProfileImageOptions {
    image: string
}

const ProfileImage: React.FC<ProfileImageOptions> = ({ image }) => {
    return <div className={styles.ProfileImage}>
        <LazyLoadedImage src={image} alt={""} shimmerElement={<div className="shimmer shimmer__circle"></div>} />
    </div>
}

export {
    ProfileImage
}