import React, { FC, useState } from "react";
import Manager from "../../models/Manager";
import { formatName } from "../../utils/utils";
import EditManagerModal from "../modal/EditManagerModal";
import DropDown from "../reusable/DropDown";

type ManagerContainerProps = {
  manager: Manager;
  index: number;
};

const ManagerContainer: FC<ManagerContainerProps> = ({ manager, index }) => {
  const [managerToDelete, setManagerToDelete] = useState<Manager | null>(null);
  const [show, setShow] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedManager, setEditedManager] =
    useState<Partial<Manager | null>>(null);

  const handleEdit = (manager: Manager) => {
    // setEditingIndex(index);
    setEditedManager({
      ...editedManager,
      _id: manager._id,
      first_name: manager.user.first_name,
      last_name: manager.user.last_name,
      email: manager.email,
    });
    setShow(true);
  };

  const actions = [
    <button
      onClick={() => setShow(true)}
      className="bg-transparent small-text d-flex align-items-center gap-2 border-0 rounded"
    >
      <i className="fa-solid fa-pen"></i>
      <span>Edit</span>
    </button>,
    <button
      onClick={() => setManagerToDelete(manager)}
      className=" bg-transparent d-flex align-items-center gap-2 small-text border-0 rounded"
    >
      <i className="fa-regular fa-trash-can "></i>
      <span>Delete</span>
    </button>,
  ];
  return (
    <tr className="px-0  w-100 bg-transparent py-2  align-items-center">
      <th className="" scope="row">
        {index}
      </th>
      <td className="d-flex align-items-center gap-2 text-black fw-bold  text-truncate">
        <img
          src={
            manager.user.profile ?? require("../../assets/images/profile.jpg")
          }
          style={{ height: "40px" }}
          alt=""
          className="rounded-circle"
        />
        <span className="">
          {formatName(manager.user.first_name, manager.user.last_name)}
        </span>
      </td>
      <td className=" text-black text-truncate">
        {manager.linked_salon_id
          ? manager.linked_salon_id.name
          : "No salon assigned"}
      </td>
      <td className="text-truncate gap-2 align-items-center">
        <i className="fa-solid fa-envelope small-text text-primary"></i>
        <span className=" text-black  ms-2">{manager.user.email}</span>
      </td>
      <td className="text-truncate gap-2 align-items-center">
        <i className="fa-solid fa-phone small-text text-primary "></i>
        <span className=" w-100 ms-2">{manager.user.phone}</span>
      </td>
      <td className="">
        <DropDown
          title={<i className="fa-solid fa-ellipsis text-black"></i>}
          items={actions}
          closeOnClickOutside
        />
      </td>
      <EditManagerModal
        manager={manager}
        show={show}
        onClose={() => {
          setShow(false);
          setEditedManager([]);
        }}
        onEdit={function (formData: FormData): void {
          throw new Error("Function not implemented.");
        }}
      />
    </tr>
  );
};

export default ManagerContainer;
