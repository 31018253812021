import { useEffect, useState } from "react";
import User from "../../models/User";
import { AppointmentStatus, IAppointment } from "../../models/Appointment";
import LatriceDisputeModal from "../modal/LatriceDisputeModal";
import { useDispatch } from "react-redux";
import { editCustomAppointment } from "../../redux/slices/customAppointmentSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { PendingDiscussionUser } from "./discussion-system-state/user/PendingDiscussionUser";
import { PendingDiscussionProfessional } from "./discussion-system-state/user/PendingDiscussionProfessional";
import { DisputedDiscussionUser } from "./discussion-system-state/user/DisputedDiscussionUser";
import { DisputedDiscussionProfessional } from "./discussion-system-state/user/DisputedDiscussionProfessional";
import { AcceptedDiscussionUser } from "./discussion-system-state/user/AcceptedDiscussionUser";
import { AcceptedDiscussionProfessional } from "./discussion-system-state/user/AcceptedDiscussionProfessional";
import { RejectedDiscussionBase } from "./discussion-system-state/user/RejectedDiscussionBase";

export const SYSTEM_PENDING_RESPONSE_DURATION = 0 * 60 * 60 * 1000; // 24 * 60 * 60 * 100 // 24 hours in ms

export type RestrictedAppointStatus = Extract<
  AppointmentStatus,
  "pending" | "disputed" | "accepted" | "rejected"
>;

interface LatriceDiscussionMessagesProps {
  selectedUser: User;
  userData: User;
  appointment: IAppointment & { status: RestrictedAppointStatus };
}

export type DiscussionSystemProps = {
  appointmentInitiator?:boolean;
  appointment: IAppointment;
};

export type DiscussionSystemActionType =
  | "accept"
  | "price_too_low"
  | "bad_date"
  | "bad_duration"
  | "bad_description"
  | "price_too_low & bad_date"
  | "refuse";

const LatriceDiscussionMessages: React.FC<LatriceDiscussionMessagesProps> = ({
  selectedUser,
  userData,
  appointment,
}) => {
  const [responseTimeoutId, setResponseTimeoutId] = useState<NodeJS.Timeout>(
    0 as unknown as NodeJS.Timeout
  );
  // Initialize with the current elapsed time
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [pendingDiscussionPossibleAction, setPendingDiscussionPossibleAction] =
    useState<DiscussionSystemActionType | null>(null);

  const dispatch = useDispatch();

  // Check whether the connected user is the initiator
  // of the current appointment
  const isInitiatorOfAppointment = userData._id === appointment.user._id;
  console.log("appointment in latriceDiscussionMessage ", appointment);
  const handlePendingAction = (action: DiscussionSystemActionType) => {
    setPendingDiscussionPossibleAction(action);
  };

  const handleDisputedAction = (action: DiscussionSystemActionType) => {
    console.log("action", action);
    setPendingDiscussionPossibleAction(action);
  };

  const handleConfirm = (data: FormData): Promise<any> => {
    data.append("id", appointment._id);
    data.append("type", pendingDiscussionPossibleAction as string);
    console.log("data", data);
    return dispatch(
      editCustomAppointment({
        customAppointment: data,
        currentUserIsInitiatorOfAppointment: isInitiatorOfAppointment,
      }) as unknown as AnyAction
    ).unwrap();
  };

  type StatusComponentMap = {
    [key in RestrictedAppointStatus]: {
      user: JSX.Element;
      professional: JSX.Element;
    };
  };

  const statusComponentMap: StatusComponentMap = {
    pending: {
      user: <PendingDiscussionUser appointmentInitiator={isInitiatorOfAppointment} appointment={appointment} />,
      professional: (
        <PendingDiscussionProfessional
          appointment={appointment}
          onPendingAction={handlePendingAction}
        />
      ),
    },
    disputed: {
      user: (
        <DisputedDiscussionUser
          appointment={appointment}
          OnDisputedAction={handleDisputedAction}
        />
      ),
      professional: (
        <DisputedDiscussionProfessional appointment={appointment} />
      ),
    },
    accepted: {
      user: (
        <AcceptedDiscussionUser
          appointment={appointment}
          OnDisputedAction={handleDisputedAction}
        />
      ),
      professional: (
        <AcceptedDiscussionProfessional appointment={appointment} />
      ),
    },
    rejected: {
      user: (
        <RejectedDiscussionBase
          appointmentInitiator={isInitiatorOfAppointment}
          appointment={appointment}
        />
      ),
      professional: (
        <RejectedDiscussionBase
          appointmentInitiator={isInitiatorOfAppointment}
          appointment={appointment}
        />
      ),
    },
  };

  const component =
    statusComponentMap[appointment.status][
      isInitiatorOfAppointment ? "user" : "professional"
    ];

  useEffect(() => {
    console.log("my appointments", appointment);
    console.log("my userData", userData);
    console.log("my selectedUser", selectedUser);
  }, []);

  return (
    <div>
      {component}
      {pendingDiscussionPossibleAction && (
        <LatriceDisputeModal
          title={"Confirmation"}
          type={pendingDiscussionPossibleAction}
          onHide={() => setPendingDiscussionPossibleAction(null)}
          onConfirm={handleConfirm}
          appointmentStatus={appointment.status}
        />
      )}
    </div>
  );
};

export default LatriceDiscussionMessages;
