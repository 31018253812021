import React, { useState } from 'react'
import { TMenuItem, Menu } from './Menu';
import { IProject } from '../../../models/Project';
import { EmptyDashboard } from './ProfessionalContainer';
import ProjectCard from '../ProjectCard';

export type RegularContainerProps = {
    styles: any;
    projects: IProject[],
    onDeleteProject: (project: IProject) => void
}

const REGULAR_MENU_ITEM = [
    { label: "Pending", id: "pending" },
    { label: "Accepted", id: "accepted" },
    { label: "Expired", id: "expired" },
    // { label: "Disputed", id: "disputed" },
];

export const RegularContainer: React.FC<RegularContainerProps> = ({ styles, projects, ...props }) => {

    const [activeMenu, setActiveMenu] = useState<TMenuItem>(REGULAR_MENU_ITEM[0]);

    const handleChangeActiveMenu = (menu: TMenuItem) => {
        setActiveMenu(menu);
    };



    const filteredProjects = projects.filter((project: IProject) => {
        return project.status === activeMenu.id;
    })

    const handleDeleteProject = (project: IProject) => {
        props.onDeleteProject(project);
    }


    return (
        <div className="d-flex flex-column justify-content-between gap-3">
            <div className="d-flex">
                <Menu
                    menuItems={REGULAR_MENU_ITEM}
                    styles={styles}
                    activeMenu={activeMenu}
                    onChangeActiveMenu={handleChangeActiveMenu}
                />
                <div
                    className={`${styles["PostProjectModal__Header"]} d-flex justify-content-between gap-5 align-items-center`}
                >
                </div>
            </div>
            <div style={{ minHeight: '400px' }} className="d-flex flex-column mb-3 align-items-center justify-content-center border-top">
                {filteredProjects.length !== 0 &&
                    <div style={{ minHeight: '400px' }} className='w-100 mt-2  h-100 d-flex flex-column gap-3'>
                        {
                            filteredProjects.map((project) => {
                                return <ProjectCard
                                    key={project._id}
                                    mode={activeMenu.id as any}
                                    onDelete={handleDeleteProject}
                                    type='regular'
                                    project={project}
                                    
                                />
                            })
                        }
                    </div>}
                {filteredProjects.length === 0 && (
                    <EmptyDashboard />
                )}
            </div>
        </div>
    )
}