import React, { CSSProperties, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

interface ModalProps {
  open?: boolean;
  backdrop?: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  className?: string;
  children?: React.ReactNode;
}

const CustomModal: React.FC<ModalProps> = ({
  open = true,
  backdrop = false,
  showCloseButton = true,
  onClose = null,
  onOpen = null,
  className,
  children,
}) => {
  const [_open, setOpen] = useState<boolean>(open);

  const closeModal = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const openModal = () => {
    setOpen(true);
    if (onOpen) onOpen();
  };

  useEffect(() => {
    // open the modal after rendering
    if (open) {
      openModal();
    } else {
      closeModal();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const modalStyle: CSSProperties = {
    backdropFilter: `blur(${50})px`, // Apply blur effect
    maxHeight: '90vh',
    overflow: 'auto'
  };

  return (
    <>
      <Modal
        show={_open}
        backdrop={_open && backdrop ? "static" : undefined}
        keyboard={!_open}
        centered
        onHide={closeModal}
        className={className}
        >
        <Modal.Header closeButton={showCloseButton} className="border-0">
        </Modal.Header>
        <Modal.Body
        style={modalStyle}
        >{children}</Modal.Body>
      </Modal>
    </>
  );
};

export default CustomModal;
