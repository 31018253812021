import axios from "axios";
import { DayTimes } from "../components/UpcomingHours";
import { USATimezone } from "../pages/ManageAvailabilityPage";
import moment from "moment";

const getAvailabilities = () => {
  return axios
    .get(`availabilities`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "x-salon-id": `${localStorage.getItem("salonId")}`,
      },
    })
    .then((data) => {
      console.log("all my availabilities", data);
      return data;
    });
};

const addAvailability = (changes: {
  edited: DayTimes;
  removed: DayTimes;
  timezone: USATimezone;
}) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}availabilities`,
    method: "POST",
    data: changes,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};
const createBulkAvailability = (changes: {
  edited: DayTimes;
  removed: DayTimes;
  timezone: USATimezone;
  repeatUntil: moment.Moment[];
}) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}availabilities/create-bulk`,
    method: "POST",
    data: changes,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

const removeAvailability = (dayTimes: DayTimes) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}availabilities`,
    method: "DELETE",
    data: Object.keys(dayTimes),
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

export const AvailabilityService = {
  getAvailabilities,
  addAvailability,
  removeAvailability,
  createBulkAvailability,
};
