import MainNav from "../components/navigation/MainNav";
import "./../styles/BusinessProfilePage.css";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import Profile from "../components/Profile";
import AuthLayout from "../components/auth/AuthLayout";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { deletePortfolioImage, updateSalon } from "../redux/slices/authSlice";
import ProfileLoader from "../components/loader/ProfileLoader";
import ImageEditorModal from "../components/modal/ImageEditorModal";
import { AnyAction } from "@reduxjs/toolkit";
import { useUser } from "../hooks/UseUser";
import SlidePicker from "../components/SlidePicker";
import ReactSimpleImageViewer from "react-simple-image-viewer";
import toast from "react-hot-toast";
import ConfirmDeleteModal from "../components/reusable/ConfirmDeleteModal";
import { useSalon } from "../context/SalonContext";
import SwitchSalonModal from "../components/reusable/SwitchSalonModal";
import { isUserBusinessOwner } from "../models/User";
import {
  ServiceState,
  getServices,
  getUserServices,
} from "../redux/slices/serviceSlice";
import {
  ServiceNameState,
  getServiceNames,
} from "../redux/slices/serviceNameSlice";
import ServiceContainer from "../components/serviceMenu/ServiceContainer";
import { ISalonProfile } from "./SeeProfessionalAccountPage";
import { ProfessionalService } from "../services/ProfessionalService";
import socket from "../socket/socket";
import { Tooltip } from "react-bootstrap";
import Availability from "../models/Availability";
import moment from "moment";
import { CONSTANTS } from "../utils/constants";
import { timeToTimezone } from "../utils/utils";
import ServiceList from "../components/seeProAccount/ServiceList";
import { ISalon } from "../models/SalonX";
import Footer from "../components/Footer";

const BusinessProfilePage = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const serviceNameData = useSelector<unknown, ServiceNameState>(
    (state: any) => state.serviceNames
  ).serviceNames;

  const services = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).services;
  const userServices = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).userServices;

  const navigate = useNavigate();

  const [portfolioToDelete, setPortfolioToDelete] = useState<number | null>(
    null
  );

  const dispatch = useDispatch();

  const currentUser = useUser();

  const [imageEditor, setImageEditor] = useState({
    open: false,
    editFor: "profile",
    title: "Change",
    message: "ADD A 400x400 IMAGE",
    rounded: false,
    ratio: 16 / 9,
  });

  const [allProfessionalData, setAllProfessionalData] =
    useState<ISalonProfile | null>(null);

  const { salon, setSalon } = useSalon();
  const [switchSalon, setSwitchSalon] = useState(false);

  const handleEditProfile = () => {
    setImageEditor((editorValues) => {
      return {
        ...editorValues,
        editFor: "profile",
        open: true,
        message: "CLICK TO UPLOAD OR DROP A PROFILE IMAGE",
        title: "Edit profile image",
        ratio: 1,
        rounded: true,
      };
    });
  };

  const handleEditCover = () => {
    setImageEditor((editorValues) => {
      return {
        ...editorValues,
        editFor: "cover",
        message: "CLICK TO UPLOAD OR DROP A COVER IMAGE",
        open: true,
        title: "Edit cover image",
        ratio: 7.5 / 5,
        rounded: false,
      };
    });
  };

  const handlePortofolio = () => {
    setImageEditor((editorValues) => {
      return {
        ...editorValues,
        editFor: "portfolio",
        message: "CLICK TO UPLOAD OR DROP AN IMAGE",
        open: true,
        title: "Add image to portfolio",
        ratio: 7.5 / 5,
        rounded: false,
      };
    });
  };

  const handleToggleModal = (open: boolean) => {
    setImageEditor((editorValues) => {
      return { ...editorValues, open };
    });
  };

  const handleUpload = async (formData: FormData) => {
    const imageName = imageEditor.editFor;
    formData.append(imageName, formData.get("file") as File);
    formData.delete("file");

    return dispatch(updateSalon(formData) as unknown as AnyAction).unwrap();
  };

  const handleDeletePortfolio = () => {
    if (portfolioToDelete !== null) {
      toast.remove();
      toast.loading("deleting portfolio image");
      const id = portfolioToDelete;
      setPortfolioToDelete(null);
      toast.remove();
      dispatch(deletePortfolioImage(id) as unknown as AnyAction)
        .unwrap()
        .then(() => {
          toast.remove();
          toast.success("Your portfolio image has been deleted.");
        })
        .catch((err: any) => {
          toast.error("Error during deletion, please try again.");
          console.log(err);
        });
    }
  };

  const handleSwitchCurrentSalon = () => {
    setSwitchSalon(true);
  };

  useEffect(() => {
    // console.log("The service for user" , userServices )
    // console.log("The salon" , salon )
    console.log("The currentUser", currentUser);
    getAllProfessionalData();
  }, []);

  useEffect(() => {
    socket().on("SET_AVAILABLE", (...args) => {
      const { available, professionalId } = args[0];
      setAllProfessionalData((professional: any) => {
        if (professional)
          return { ...professional, available: available as number | boolean };
      });
    });
    return () => {
      socket().off("NEW_NOTIFICATION");
    };
  }, []);

  const getAllProfessionalData = () => {
    ProfessionalService.getProfessionalById(salon?._id as string)
      .then((res: ISalonProfile) => {
        console.log("response after getting professionalData", res);
        setAllProfessionalData(res);
      })
      .catch((err: any) => {
        // Add
        console.log(err);
      });
  };

  const renderAvailabilities = (
    availabilities: Availability[]
    // unavailabilities: Availability[]
  ) => {
    const sortedAvailabilities = availabilities
      .slice()
      .sort((a: Availability, b: Availability) =>
        moment(a.day).diff(moment(b.day))
      );
    // const sortedUnAvailabilities =
    //   unavailabilities !== undefined
    //     ? unavailabilities
    //         .slice()
    //         .sort((a: Availability, b: Availability) =>
    //           moment(a.day).diff(moment(b.day))
    //         )
    //     : [];

    if (sortedAvailabilities.length === 0) {
      return (
        <div className="d-flex user-select-none flex-column text-muted gap-3 align-items-center justify-content-center">
          <i className="fa fa-search text-muted fa-2x"></i>
          <div className="text-center">
            We couldn't find current week's availabilities.
            <br />
            But you can probably book services.
          </div>
        </div>
      );
    }

    // const getFreeTime = (day: string) => {
    //   const unAvailabilities = sortedUnAvailabilities.filter((av) => {
    //     return av.day === day;
    //   });
    //   if (unAvailabilities.length === 1) return unAvailabilities[0];
    //   return null;
    // };

    return (
      <div className="d-flex flex-column gap-2">
        {/* <Tooltip id="unAvailabilityTooltip" /> */}
        {sortedAvailabilities.map((availability, key) => {
          // const unAvailability = getFreeTime(availability.day);

          return (
            <div
              key={uuidv4()}
              className="d-flex  justify-content-between align-items-center"
            >
              <div
                className="d-flex align-items-end gap-2"
                key={availability.day}
              >
                <div className="fw-500 text-black">
                  {CONSTANTS.WEEK_DAYS[moment(availability.day).day()]}
                </div>
                <div>
                  {timeToTimezone(availability.startTime)} -
                  {" " + timeToTimezone(availability.endTime)}
                </div>
              </div>
              {/* {unAvailability && allProfessionalData && (
                <div
                  data-tooltip-id="unAvailabilityTooltip"
                  data-tooltip-place="top"
                  data-tooltip-content={`Will be unavailable during this period.
                    `}
                  className="unAvailabilityBadge d-flex  gap-2 align-items-center border p-1"
                >
                  <div className="unAvailabilityBadge__dot"></div>
                  <div className="unAvailabilityBadge__interval t ext-muted">
                    {timeToTimezone(unAvailability.startTime)} -
                    {" " + timeToTimezone(unAvailability.endTime)}
                  </div>
                  <i className="fa fa-lock" />
                </div>
              )} */}
            </div>
          );
        })}
      </div>
    );
  };

  const renderSalonList = (salons: any) => {
    return (
      <div>
        {salons.map((salon: any) => (
          <div key={salon._id} className="d-flex flex-column w-100">
            <div className="d-flex justify-content-between professional_service align-items-center w-100">
              <div className="d-flex flex-column">
                <h5 className="text-black">{salon.name}</h5>
                <div className="professional_service__description">
                  {salon.address}
                </div>
                <span className="text-muted">
                  {/* {formatUSPrice(service.price)} for{" "} */}
                  {/* {formatTime(service.duration as unknown as number)} */}
                </span>
              </div>
            </div>
            <hr className="w-100" />
          </div>
        ))}
      </div>
    );
  };

  return (
    <AuthLayout redirect={true}>
      <MainNav navType={"connectedNav"} isSearch={false} />
      <div className="BusinessProfilePage">
        <div
          style={{ marginTop: "4rem" }}
          className="d-flex justify-content-center business_profile gap-4 container-md p-lg-0"
        >
          <div className="d-flex ProfileLeftSide justify-content-center">
            {!salon ? (
              <ProfileLoader />
            ) : (
              <Profile
                cover={[salon.cover ?? "https://via.placeholder.com/450x200"]}
                profile={salon.profile ?? "https://via.placeholder.com/100x100"}
                name={`${salon.name}`}
                location={salon.address ?? "Location not provided"}
                stars={salon.reviewsStats.averageStars}
                notice={salon.reviewsStats.reviewCount}
                title={""}
                blow_dry={salon.blow_dry}
                hair_wash={salon.hair_wash}
                undo_hair={salon.undo_hair}
                onEditProfile={handleEditProfile}
                onEditCover={handleEditCover}
              />
            )}
          </div>
          <div className="d-flex flex-column ProfileRightSide gap-4">
            <div>
              <div className="d-flex justify-content-between">
                <h4
                  style={{ position: "relative" }}
                  className="fw-bold text-primary"
                >
                  About Yourself
                  <i className="fa-regular fa-circle-info info-icon"></i>
                </h4>
                <button
                  onClick={() => navigate("/business-info")}
                  className="d-flex justify-content-center align-items-center gap-2 btn btn-primary b-0 rounded px-3 py-1 small-text mb-1 text-white"
                >
                  Update details
                </button>
              </div>

              {/* <NavLink to={"/business-info"} className="">
                <span className="deeplink">
                  Tell Clients A Little Bit About Yourself
                </span>
              </NavLink> */}
              <p className="">
                {salon?.bio ? (
                  <span className="deeplink">{salon.bio}</span>
                ) : (
                  <NavLink to={"/business-info"} className="">
                    <span className="deeplink">
                      Tell Clients A Little Bit About Yourself
                    </span>
                  </NavLink>
                )}
              </p>
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <h4
                  style={{ position: "relative" }}
                  className="fw-bold text-primary"
                >
                  Portfolio{" "}
                  <i className="fa-regular fa-circle-info info-icon"></i>
                </h4>
                <button
                  onClick={handlePortofolio}
                  className="d-flex justify-content-center align-items-center gap-2 btn btn-primary b-0 rounded px-3 py-1 small-text mb-1 text-white"
                >
                  <i className="fa-regular fa-image"></i> Add
                </button>
              </div>
              <div
                className="d-flex flex-row gap-2 portofolio"
                style={{
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                }}
              >
                {(salon?.portfolio ?? []).length !== 0 ? (
                  <SlidePicker
                    data={(salon?.portfolio ?? []).map(
                      (p: string, key: number) => {
                        return { image: p, key };
                      }
                    )}
                    maxShow={4}
                    onSelectItem={(item: any) => {
                      setImageIndex(item.key as number);
                      setShowModal(true);
                    }}
                    deletable
                    renderDeletableItem={(item: any) => {
                      return (
                        <div
                          style={{
                            fontSize: "1.3rem",
                            zIndex: "10",
                          }}
                        >
                          <i className="fa-solid fa-circle-xmark text-dark"></i>
                        </div>
                      );
                    }}
                    onDeleteItem={(item: any) => {
                      setPortfolioToDelete(item.key);
                    }}
                    renderItem={function (item: any): ReactNode {
                      return (
                        <div
                          style={{ height: "100%", width: "100%" }}
                          className="position-relative border"
                        >
                          <img
                            style={{ height: "6rem", width: "100%" }}
                            className="rounded img-fluid"
                            src={item.image}
                            alt=""
                          />
                        </div>
                      );
                    }}
                    keyExtractor={function (item: any): string {
                      return item.key as string;
                    }}
                    separator={<span className="mx-1"></span>}
                  />
                ) : (
                  <div>
                    <small className="text-muted d-flex align-items-center">
                      <div className="d-flex flex-column">
                        <span>
                          Your portfolio is a great way to showcase your work.
                        </span>
                        <span>
                          Let's get started by adding your first portfolio
                          entry.
                        </span>
                      </div>
                    </small>
                  </div>
                )}
              </div>
            </div>
            <div>
              <h4
                style={{ position: "relative" }}
                className="fw-bold text-primary"
              >
                Service <i className="fa-regular fa-circle-info info-icon"></i>
              </h4>

              {userServices?.length ? (
                <div>
                  {/* <ServiceContainer services = {userServices} /> */}
                  <ServiceList
                    services={userServices as any}
                    scrollable={false}
                  />
                </div>
              ) : (
                <NavLink to={"/service-menu"}>
                  <span className="deeplink">Add service</span>
                </NavLink>
              )}
            </div>
            <div className="">
              <div>
                <div className="d-flex justify-content-between">
                  <h4
                    style={{ position: "relative" }}
                    className="fw-bold text-primary"
                  >
                    Availabilities
                    <i className="fa-regular fa-circle-info info-icon"></i>
                  </h4>
                  <button
                    onClick={() => navigate("/manage-availability")}
                    className="d-flex justify-content-center align-items-center gap-2 btn btn-primary b-0 rounded px-3 py-1 small-text mb-1 text-white"
                  >
                    Add Availabilities
                  </button>
                </div>

                {currentUser?.availability?.length ? (
                  <div className="d-flex flex-column">
                    {renderAvailabilities(currentUser?.availability as any)}
                  </div>
                ) : (
                  <NavLink to={"/manage-availability"}>
                    <span className="deeplink">Add Your Availability </span>
                  </NavLink>
                )}
              </div>
            </div>
            {isUserBusinessOwner(currentUser) && (
              <div className="d-flex justify-content-between">
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <h4
                      style={{ position: "relative" }}
                      className="fw-bold text-primary"
                    >
                      Salons
                      <i className="fa-regular fa-circle-info info-icon"></i>
                    </h4>
                    <button
                      onClick={handleSwitchCurrentSalon}
                      className="d-flex btn-dark justify-content-center align-items-center gap-2 btn btn-primary b-0 rounded px-3 py-1 small-text mb-1 text-white"
                    >
                      <i className="fa fa-gear mt-1"></i> Change current salon
                    </button>
                  </div>

                  <div className="d-flex justify-content-between w-100">
                    {currentUser.salons.length ? (
                      <div className="d-flex flex-column">
                        {renderSalonList(currentUser.salons as any)}
                      </div>
                    ) : (
                      <NavLink to={"/admin/dashboard"}>
                        <span className="deeplink">Manage your salons</span>
                      </NavLink>
                    )}

                    {/*                     
                    <NavLink to={"/admin/dashboard"}>
                      <span className="deeplink">Manage your salons</span>
                    </NavLink> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
      <ImageEditorModal
        onUpload={handleUpload}
        message={imageEditor.message}
        onClose={() => handleToggleModal(false)}
        show={imageEditor.open}
        rounded={imageEditor.rounded}
        ratio={imageEditor.ratio}
        title={imageEditor.title}
      />
      {salon && showModal && (
        <ReactSimpleImageViewer
          src={salon.portfolio}
          currentIndex={imageIndex}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setShowModal(false)}
        />
      )}
      <ConfirmDeleteModal
        show={portfolioToDelete !== null}
        setServiceToDelete={() => {
          setPortfolioToDelete(null);
        }}
        handleServiceDelete={handleDeletePortfolio}
        text1={"Do you really want to delete this file?"}
        text2={"If you delete it you won't be able to recover it"}
      />
      {switchSalon && isUserBusinessOwner(currentUser) && (
        <SwitchSalonModal
          salons={currentUser.salons}
          onClose={() => setSwitchSalon(false)}
          show={switchSalon}
        />
      )}
    </AuthLayout>
  );
};

export default BusinessProfilePage;
