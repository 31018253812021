import React, { useCallback } from "react";

export type TimeOfDay = "morning" | "afternoon" | "evening";
export type mutedRangesType = {
  morning: boolean;
  afternoon: boolean;
  evening: boolean;
};

export interface TimeProps {
  onChange?: (value: TimeOfDay) => void;
  value: TimeOfDay | null;
  mutedRanges?: mutedRangesType;
}

const Time: React.FC<TimeProps> = React.memo(
  ({
    onChange,
    value = null,
    mutedRanges = {
      morning: false,
      afternoon: false,
      evening: false,
    },
  }) => {

    const handleClick = useCallback(
      (index: TimeOfDay) => () => {
        if (mutedRanges[index]) return;
        if (typeof onChange === "function") {
          onChange(index);
        }
      },
      [mutedRanges, onChange]
    );


    return (
      <div className="WhatTime d-flex rounded">
        <div
          onClick={handleClick("morning")}
          className={`${mutedRanges?.morning
              ? "muted"
              : value === "morning"
                ? " border active rounded-start"
                : ""
            } `}
        >
          Morning
        </div>
        <div
          onClick={handleClick("afternoon")}
          className={` ${mutedRanges?.afternoon
              ? "muted"
              : value === "afternoon"
                ? " border active "
                : ""
            } `}
        >
          Afternoon
        </div>
        <div
          onClick={handleClick("evening")}
          className={`${mutedRanges?.evening
              ? "muted"
              : value === "evening"
                ? " border active rounded-end"
                : ""
            } `}
        >
          Evening
        </div>
      </div>
    );
  }
);

export default Time;
