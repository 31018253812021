import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

interface SelectSizeProps {
  onChange: (sizes: string[]) => void;
  onBlur: (e: any) => void;
  name: string;
  id?: string;
  selectedSizes: string[];
  isProject?: boolean;
}

const SelectSize: React.FC<SelectSizeProps> = ({
  onChange,
  onBlur,
  name,
  selectedSizes,
  id,
  isProject,
}) => {
  const [selectedSize, setSelectedSize] = useState<string>("");
  const [selectedLength, setSelectedLength] = useState<string[]>([]);
  // Define the available sizes and labels
  const sizes = [
    { value: "micro", label: "Micro" },
    { value: "small", label: "Small" },
    { value: "medium", label: "Medium" },
    { value: "large", label: "Large" },
    { value: "jumbo", label: "Jumbo" },
    { value: "N/A", label: "Not applicable" },
  ];
  const lengths = [
    { value: "shoulder-length", label: "Shoulder length" },
    { value: "bra-strap-length", label: "Bra strap length" },
    { value: "mid-back-length", label: "Mid back length" },
    { value: "waist-length", label: "Waist length" },
    { value: "butt-length", label: "Butt length" },
  ];

  // Toggle the selection of a size
  const toggleSize = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;
    console.log("yess value", name, value);
    if (name === "size") {
      setSelectedSize(value);
      onChange(
        selectedLength.length == 0
          ? [value]
          : [value, selectedLength?.join(",")]
      );
    } else {
      if (isProject) {
        setSelectedLength([value]);
        onChange(selectedSize === "" ? [value] : [selectedSize, value]);
      } else {
        console.log("checked", checked);
        setSelectedLength((prev) => {
          if (checked) {
            const updateLength = [...prev, value];
            console.log("that is the new length after adding", updateLength);
            onChange(
              selectedSize === ""
                ? [updateLength.join(",")]
                : [selectedSize, updateLength.join(",")]
            );
            return updateLength;
          } else {
            const updateLength = prev.filter((item) => item !== value);
            console.log("that is the new length after removing", updateLength);

            onChange(
              selectedSize === ""
                ? [updateLength.join(",")]
                : [selectedSize, updateLength.join(",")]
            );

            return updateLength;
          }
        });
        onChange(
          selectedSize === ""
            ? [selectedLength.join(",")]
            : [selectedSize, selectedLength.join(",")]
        );
      }
    }
  };

  return (
    <div className="dropdown form-control p-0 rounded d-flex">
      {/* Dropdown input */}
      <div
        className="input-group rounded size-input-container"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        role="button"
      >
        <input
          {...(id ? { id } : {})}
          type="text"
          className="form-control border border-0 bg-transparent text-black"
          disabled
          data-bs-offset="10,20"
          placeholder={
            selectedSizes.length > 0
              ? `${selectedSize}  ${
                  isProject
                    ? `${selectedLength[0]}`
                    : `${selectedLength.join(",")}`
                }`
              : "Select service size/ Length*"
          }
        />
        <span className="input-group-text border border-0 bg-transparent">
          <i className="fa-solid fa-angle-down"></i>
        </span>
      </div>

      {/* Dropdown menu */}
      <ul className="dropdown-menu">
        {/* Size options */}
        <div className="d-flex gap-3">
          <div className="d-flex flex-column">
            {sizes.map((size) => (
              <label
                key={size.value}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="dropdown-item">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={`mr-2 ${
                      selectedSizes.includes(size.value) ? "text-success" : ""
                    }`}
                  />
                  {size.label}
                </span>
                <input
                  type="radio"
                  name="size"
                  value={size.value}
                  onChange={toggleSize}
                  onBlur={onBlur}
                  // checked={selectedSizes.includes(size.value)}
                />
              </label>
            ))}
          </div>
          {/* Length options */}
          <div className="d-flex flex-column">
            {lengths.map((length) => (
              <label
                key={length.value}
                className="d-flex align-items-center justify-content-center"
              >
                <span className="dropdown-item">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={`mr-2 ${
                      selectedSizes.includes(length.value) ? "text-success" : ""
                    }`}
                  />
                  {length.label}
                </span>
                <input
                  type={isProject ? "radio" : "checkbox"}
                  name="length"
                  value={length.value}
                  onChange={toggleSize}
                  onBlur={onBlur}
                  // checked={selectedLength.includes(length.value)}
                />
              </label>
            ))}
          </div>
        </div>
        {/* Clear selection button */}
        <button
          className="btn btn-outline-danger mt-2 mx-auto"
          onClick={() => onChange([])}
        >
          Clear Selection
        </button>
      </ul>
    </div>
  );
};

export default SelectSize;
