import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ChairService } from "../../services/ChairService";
import { TWorkingTableElement } from "../../components/availability/WorkingTableElement";
import { addAvailability, addBulkAvailability } from "./availabilitySlice";
import Availability from "../../models/Availability";
import moment from "moment";
import {
  addBulkUnAvailability,
  addUnAvailability,
} from "./unAvailabilitySlice";

export interface ChairsState {
  chairs: TWorkingTableElement[] | null;
}

const initialState: ChairsState = {
  chairs: null,
};

export const getChairs = createAsyncThunk<
  TWorkingTableElement[],
  moment.Moment
>("chairs/getChairs", async (startDate?) => {
  const res = await ChairService.getSalonChairs(startDate ?? moment());
  console.log("all chairs", res);
  return res.data;
});

export const addChair = createAsyncThunk<
  TWorkingTableElement,
  Partial<TWorkingTableElement>
>(
  "chairs/addChair",
  async (chair: Partial<TWorkingTableElement>, { rejectWithValue }) => {
    try {
      const res = await ChairService.addChair(chair);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const editChair = createAsyncThunk<
  TWorkingTableElement,
  Partial<TWorkingTableElement>
>(
  "chairs/editChair",
  async (chair: Partial<TWorkingTableElement>, { rejectWithValue }) => {
    try {
      const res = await ChairService.editChair(chair);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

const chairSlice = createSlice({
  name: "chairs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChairs.fulfilled, (state, action) => {
        state.chairs = action.payload;
      })
      .addCase(addChair.fulfilled, (state, action) => {
        const newChair = state.chairs?.slice();
        newChair?.push(action.payload as TWorkingTableElement);
        if (newChair) state.chairs = newChair;
      })
      .addCase(editChair.fulfilled, (state, action) => {
        if (state.chairs) {
          const index = state.chairs.findIndex(
            (chair) => chair._id === action.payload._id
          );
          if (index !== -1) {
            state.chairs[index] = {
              ...state.chairs[index],
              ...action.payload,
            };
          }
        }
      })
      .addCase(addAvailability.fulfilled, (state, action) => {
        let edited: Availability | undefined;
        let removed: Availability | undefined;

        if (
          action.payload.edited &&
          Array.isArray(action.payload.edited) &&
          action.payload.edited.length > 0
        ) {
          edited = action.payload.edited[0] as Availability;
        }

        if (
          action.payload.removed &&
          Array.isArray(action.payload.removed) &&
          action.payload.removed.length > 0
        ) {
          removed = action.payload.removed[0] as Availability;
        }

        if (!state.chairs) {
          return state;
        }

        const { chairs } = state;

        const updatedChairs = chairs.map((chair) => {
          // Update edited chair shifts
          if (chair._id === edited?.chairId) {
            let editedWeekDay = moment(edited.day, "YYYY-MM-DD").weekday() - 1;
            editedWeekDay =
              editedWeekDay < 0 ? chair.shifts.length - 1 : editedWeekDay;

            let updatedShifts = chair.shifts.map((shift, key) => {
              if (editedWeekDay === key) {
                return edited;
              }
              return shift;
            });

            return {
              ...chair,
              shifts: updatedShifts,
            };
          }

          // Delete removed chair
          if (chair._id === removed?.chairId) {
            let removedWeekDay =
              moment(removed.day, "YYYY-MM-DD").weekday() - 1;
            removedWeekDay =
              removedWeekDay < 0 ? chair.shifts.length - 1 : removedWeekDay;

            let updatedShifts = chair.shifts.map((shift, key) => {
              if (removedWeekDay === key) {
                return null;
              }
              return shift;
            });

            return {
              ...chair,
              shifts: updatedShifts,
            };
          }

          return chair;
        });

        state.chairs = updatedChairs as any;
      })
      .addCase(addBulkAvailability.fulfilled, (state, action) => {
        if (!state.chairs) {
          return state;
        }

        const { chairs } = state;

        const updatedChairs = chairs.map((chair) => {
          // Find all matching edited availabilities for the current chair
          const matchingEditedAvailabilities = action.payload.edited.filter(
            (editedAvailability: any) =>
              chair._id === editedAvailability.chairId
          );

          // Update shifts for each matching availability
          let updatedShifts = chair.shifts.slice(); // Create a copy of shifts array
          matchingEditedAvailabilities.forEach((editedAvailability: any) => {
            let editedWeekDay =
              moment(editedAvailability.day, "YYYY-MM-DD").weekday() - 1;
            editedWeekDay =
              editedWeekDay < 0 ? chair.shifts.length - 1 : editedWeekDay;

            updatedShifts = updatedShifts.map((shift, key) => {
              return editedWeekDay === key ? editedAvailability : shift;
            });
          });

          return {
            ...chair,
            shifts: updatedShifts,
          };
        });

        state.chairs = updatedChairs as any;
      })
      .addCase(addUnAvailability.fulfilled, (state, action) => {
        let edited: Availability | undefined;
        let removed: Availability | undefined;

        if (
          action.payload.edited &&
          Array.isArray(action.payload.edited) &&
          action.payload.edited.length > 0
        ) {
          edited = action.payload.edited[0] as Availability;
        }

        if (
          action.payload.removed &&
          Array.isArray(action.payload.removed) &&
          action.payload.removed.length > 0
        ) {
          removed = action.payload.removed[0] as Availability;
        }

        if (!state.chairs) {
          return state;
        }

        const { chairs } = state;

        const updatedChairs = chairs.map((chair) => {
          // Update edited chair shifts
          if (chair._id === edited?.chairId) {
            let editedWeekDay = moment(edited.day, "YYYY-MM-DD").weekday() - 1;
            editedWeekDay =
              editedWeekDay < 0 ? chair.timeOffs.length - 1 : editedWeekDay;

            let updatedTimeOffs = chair.timeOffs.map((timeOff, key) => {
              if (editedWeekDay === key) {
                return edited;
              }
              return timeOff;
            });

            return {
              ...chair,
              timeOffs: updatedTimeOffs,
            };
          }

          // Delete removed chair
          if (chair._id === removed?.chairId) {
            let removedWeekDay =
              moment(removed.day, "YYYY-MM-DD").weekday() - 1;
            removedWeekDay =
              removedWeekDay < 0 ? chair.timeOffs.length - 1 : removedWeekDay;

            let updatedTimeOffs = chair.timeOffs.map((timeOff, key) => {
              if (removedWeekDay === key) {
                return null;
              }
              return timeOff;
            });

            return {
              ...chair,
              timeOffs: updatedTimeOffs,
            };
          }

          return chair;
        });

        state.chairs = updatedChairs as any;
      })
      .addCase(addBulkUnAvailability.fulfilled, (state, action) => {
        if (!state.chairs) {
          return state;
        }

        const { chairs } = state;

        const updatedChairs = chairs.map((chair) => {
          // Find all matching edited availabilities for the current chair
          const matchingEditedUnAvailabilities = action.payload.edited.filter(
            (editedUnAvailability: any) =>
              chair._id === editedUnAvailability.chairId
          );

          // Update shifts for each matching availability
          let updatedTimeOffs = chair.timeOffs.slice(); // Create a copy of shifts array
          matchingEditedUnAvailabilities.forEach(
            (editedUnAvailability: any) => {
              let editedWeekDay =
                moment(editedUnAvailability.day, "YYYY-MM-DD").weekday() - 1;
              editedWeekDay =
                editedWeekDay < 0 ? chair.timeOffs.length - 1 : editedWeekDay;

              updatedTimeOffs = updatedTimeOffs.map((timeOff, key) => {
                return editedWeekDay === key ? editedUnAvailability : timeOff;
              });
            }
          );

          return {
            ...chair,
            timeOffs: updatedTimeOffs,
          };
        });

        state.chairs = updatedChairs as any;
      });
  },
});

export const selectChairs = (state: { chairs: ChairsState }) => state.chairs;

export default chairSlice.reducer;
