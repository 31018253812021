import User from "../../models/User";
import { useState } from "react";
import "../styles/VerticalUserSelector.css";

export interface VerticalUserSelectorProps {
  users: User[];
  onSelect: (user: User) => void;
  renderMode?: "horizontal" | "vertical";
}

const VerticalUserSelector: React.FC<VerticalUserSelectorProps> = ({
  users,
  renderMode = "horizontal",
  onSelect,
}) => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [showUsers, setShowUsers] = useState(false);

  const headUser = selectedUser ?? users[0];

  const handleOnSelect = (user: User) => {
    setSelectedUser(user);
    setShowUsers(false);
    onSelect(user);
  };

  const handleShowUsers = () => {
    setShowUsers(!showUsers);
  };

  return (
    <div className={"d-flex justify-content-end gap-2 align-items-center "}>
      {renderMode === "horizontal" ? (
        users.map((user, key) => {
          return (
            <div
              key={user._id}
              onClick={(e: any) => {
                handleOnSelect(user);
              }}
              className={`selectUserItem rounded d-flex flex-column justify-content-center align-items-center ${user._id === (selectedUser && selectedUser._id)
                ? "selectUserItem--active"
                : ""
                }`}
            >
              <div className="selectUserItem__imgContent border">
                <img className="img-fluid" alt={user.first_name} src={user.profile ?? ""} />
              </div>
              <div>
                {user.first_name} {user.last_name}
              </div>
            </div>
          );
        })
      ) : (
        <div
          className={
            "d-flex justify-content-end gap-2 align-items-center flex-column  position-relative"
          }
        >
          <div
            onClick={(e: any) => {
              handleShowUsers();
            }}
            className={`selectUserItem rounded d-flex flex-column justify-content-center align-items-center selectUserItem--active
                
            `}
          >
            <div className="selectUserItem__imgContent border">
              <img
                className="img-fluid"
                alt={headUser.first_name}
                src={headUser.profile ?? ""}
              />
            </div>
            <div>
              {headUser.first_name} {headUser.last_name}
            </div>
          </div>

          <div className={"users_list rounded" + (showUsers ? " display" : "")}>
            {users
              .filter((user) => user !== selectedUser)
              .map((user, key) => {
                return (
                  <div
                    key={user._id}
                    onClick={(e: any) => {
                      handleOnSelect(user);
                    }}
                    className={`selectUserItem rounded d-flex flex-column justify-content-center align-items-center`}
                  >
                    <div className="selectUserItem__imgContent border">
                      <img
                        className="img-fluid"
                        alt={user.first_name}
                        src={user.profile ?? ""}
                      />
                    </div>
                    <div>
                      {user.first_name} {user.last_name}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default VerticalUserSelector;
