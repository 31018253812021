import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import styles from "../styles/AppointmentViewer.module.css";
import React, {
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { getDatesRange } from "../../utils/utils";
import { IAppointment } from "../../models/Appointment";
import moment from "moment";
import SelectPeriod from "./SelectPeriod";
import {
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export type DatePeriod = "day" | "week" | "month";


export interface AppointmentGraphOptions {
  maxWidth: string | number;
  maxHeight?: string | number;
  startAt: moment.Moment;
  appointments?: IAppointment[];
  appointmentRender: (appointment: IAppointment) => React.JSX.Element;
  onDatePeriodChange?: (period: DatePeriod) => void
}

/**
 * Show a 2D graph based on appointments options
 *
 * this component will be used to show in two dimensions
 * appointments according to her time.
 *
 * x = appointment date (ex: 10 May)
 *
 * y = appointment start date (ex: 06:30 AM)
 *
 * @author Mentalists
 * @param options
 * @returns
 */
const AppointmentViewer: React.FC<AppointmentGraphOptions> = ({
  startAt,
  appointments,
  appointmentRender,
  maxWidth,
  maxHeight,
  onDatePeriodChange,
}) => {
  const options = { startAt, appointments, appointmentRender };

/*   console.log(appointments); */

  const [activeRangeDate, setActiveRangeDate] = useState<moment.Moment>(options.startAt);

  const [activeDates, setActiveDates] = useState<moment.Moment[]>([]);
  const [activeHours, setActiveHours] = useState<string[]>([]);

  const [datePeriod, setDatePeriod] = useState<DatePeriod>("day");
  const [rangeDates, setRangeDates] = useState<moment.Moment[]>([]);


  const handlePrevPeriod = () => {
    const daysToAdd = datePeriod === "week" ? -7 : -30;
    const currentDate = moment(activeRangeDate).add(daysToAdd, 'days');
    setActiveRangeDate(currentDate);
  };

  const handleNextPeriod = () => {
    const daysToAdd = datePeriod === "week" ? 7 : 30;
    const currentDate = moment(activeRangeDate).add(daysToAdd, 'days');
    setActiveRangeDate(currentDate);
  };

  const AppointmentRender = (appointment: IAppointment, interval: moment.Moment[]) => {
    let findAppointmentInterval: moment.Moment | undefined;

    const filteredInterval = interval.filter((value) => {
      const startAt = moment(`${appointment.day} ${appointment.startedAt}`, 'YYYY-MM-DD HH:mm');
      return startAt.diff(value, 'days') === 0;
    });

    if (filteredInterval.length > 0) {
      findAppointmentInterval = filteredInterval[0];

      let appointmentDay = findAppointmentInterval.day() + 1;
      let appointmentDate = findAppointmentInterval.date() + 1;

      const startAt = moment(`${appointment.day} ${appointment.startTime}`, 'YYYY-MM-DD HH:mm A');

      const timeDetails = {
        time: startAt.format("A"),
        hour: startAt.format("HH"),
        minute: startAt.format("mm"),
      };

      // convert day or date to graph column
      const column = (datePeriod === "day" ? 1 : (datePeriod === "week" ? appointmentDay : appointmentDate - 1));
      const row = Number(timeDetails.hour);

      return (
        <div
          key={`${appointment._id}`}
          style={{ gridColumn: `${column + 1}`, gridRow: `${row}` }}
          className={`${styles["AppointmentGraph__Item"]} ${datePeriod === 'day' ? 'd-flex justify-content-start' : ' d-flex justify-content-start'}`}
        >
          {options.appointmentRender(appointment)}
        </div>
      );
    }
  };

  const isActiveDate = (date: moment.Moment) => {
    return activeDates.indexOf(date) !== -1;
  };

  const isActiveHour = (hour: string) => {
    return activeHours.indexOf(hour) !== -1;
  };

  useEffect(() => {
    setActiveRangeDate(options.startAt);
  }, [options.startAt])

  useEffect(() => {
    const dates = getDatesRange(activeRangeDate, datePeriod);
    setRangeDates(dates);

    // Extract active Date & Hours for user highlighting
    const _dates: SetStateAction<moment.Moment[]> = [];
    const _hours: SetStateAction<string[]> = [];
    dates.forEach((date) => {
      options.appointments?.forEach((appointment) => {
        let startedAt = moment(`${appointment.day} ${appointment.startTime}`, 'YYYY-MM-DD HH:mm');
        let endedAt = moment(`${appointment.day} ${appointment.endTime}`, 'YYYY-MM-DD HH:mm');

        if (startedAt.isSame(date, 'day')) {
          _dates.push(date);

          let startHour = Number(startedAt.format("h"));
          let endHour = Number(endedAt.format("h"));

          for (let i = 0; i < endHour - startHour + 1; i++) {
            const currentDate = moment(startedAt).add(i, 'hours');

            const meridian = currentDate.format("a");
            const hour = Number(currentDate.format("h"));

            _hours.push(`${hour} ${meridian?.toUpperCase()}`);
          }
        }
      });
    });

    setActiveDates(_dates);
    setActiveHours(_hours);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRangeDate, datePeriod, options.appointments, options.startAt]);

  const handleChangePeriod = (value: DatePeriod) => {
    setDatePeriod(value as any);
    if (onDatePeriodChange) onDatePeriodChange(value)
  }

  const handleDayActiveChange = (activeDay: moment.Moment) => {
    setActiveRangeDate(activeDay);
  }

  return (
    <div className={`${styles.AppointmentGraph}`} style={{ maxHeight, maxWidth }}>
      <div
        className={`${styles["AppointmentGraph__Header"]} d-flex flex-column`}
      >
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div className="d-flex gap-5">
            <div className="">
              <div className={`${styles["AppointmentGraph__Header__Period"]} rounded d-flex justify-content-around gap-2`}>
                {['Day', 'Week', 'Month'].map((period) => {
                  return <div onClick={() => handleChangePeriod(period.toLocaleLowerCase() as any)} className={`${styles["AppointmentGraph__Header__Period__item"]} m-1 rounded
                ${period.toLocaleLowerCase() === datePeriod ? styles["AppointmentGraph__Header__Period__item--active"] : ''}`}>
                    {period}
                  </div>
                })}
              </div>
              <div>
                <div className="d-flex justify-content-start gap-1 align-items-center">
                  <label htmlFor="" className="text-muted fw-500">Loop</label>
                  <input style={{ accentColor: 'var(--primaryColor)' }} type="range" min={20} className="w-100" />
                </div>
              </div>
            </div>
            <div>
              <div className="filter gap-2 d-flex align-items-center border p-2 rounded fw-bold">
                <FontAwesomeIcon icon={faFilter} className="fa-1x" />
                <span>Filter</span>
              </div>
            </div>
          </div>

          <div
            className={`${styles["AppointmentGraph__PeriodSelector"]} d-flex align-items-center gap-2`}
          >
            <SelectPeriod mode={datePeriod} onChange={handleDayActiveChange} onSelect={() => { }} />

          </div>

        </div>
        <hr />
        <div
          style={{
            gridTemplateColumns: `repeat(${datePeriod === 'day' ? 9 : rangeDates.length + 1}, 1fr)`,
          }}
          className={`${styles["AppointmentGraph__Grid"]} mt-2`}
        >
          {rangeDates.length > 1 && rangeDates.map((date, key) => {
            let currentDate = date;
            let active = isActiveDate(currentDate)
              ? styles["AppointmentGraph__Grid__Abscissa--active"]
              : "";

            return (
              <div
                data-tooltip-id="abscissaTooltip"
                data-tooltip-place="top"
                data-tooltip-content={currentDate.format("DD MMMM yyyy")}
                style={{ gridColumn: key + 2 }}
                className={`${styles["AppointmentGraph__Grid__Abscissa"]} ${active}
                                    d-flex flex-column align-items-center gap-0 justify-content-center`}
                key={`${currentDate.format("YYYY-MM-DD")}-${key}`}
              >
                <span>{currentDate.format("DD")}</span>
                <span>{currentDate.format("ddd")}</span>
              </div>
            );
          })}
          <Tooltip id="abscissaTooltip" />
          {Array(24)
            .fill(0)
            .map((_, key) => {
              const label = key < 12 ? `${key + 1} AM` : `${key - 11} PM`;
              let active = isActiveHour(label)
                ? styles["AppointmentGraph__Grid__Order--active"]
                : "";
              return (
                <div
                  className={`${styles["AppointmentGraph__Grid__Order"]} ${active}`}
                  key={label}
                  style={{ grid: `${key + 1} 1`, maxWidth: '50px !important' }}
                >
                  {label}
                </div>
              );
            })}

          {/* Render All appointments on the Graph */}

          {options.appointments?.map((value) => {
            return AppointmentRender(value, rangeDates);
          })}
        </div>
      </div>
    </div>
  );
};

export { AppointmentViewer };
