import React, { useState } from "react"; // Replace this with the actual button component you are using
import Button from "../Button";
import Service, { IService } from "../../models/Service";
import { formatUSPrice, formatTime } from "../../utils/utils";
import { ServiceListSkeleton } from "../../skeletons/ServiceListSkeleton";
import PromotionModal from "./PromotionModal";

interface ServicePromotionListProps {
  services?: IService[] | null;
  searchable?: boolean;
  scrollable?: boolean;
  show?: boolean;
  onseclectable?: boolean;
  onSelect?: (service: Service) => void;
}

const ServicePromotionList: React.FC<ServicePromotionListProps> = ({
  services,
  onSelect,
  searchable = true,
  scrollable = true,
  onseclectable = true,
  show = false,
}) => {
  const [q, setQ] = useState<string | null>(null);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [currentService, setCurrentService] = useState<Service | null>(null);
  const [serviceToDelete, setServiceToDelete] = useState<Service | null>(null);
  const [editedService, setEditedService] = useState<Partial<Service>>();
  const [isShow, setIsShow] = useState<boolean>(show);

  const filteredServices =
    services &&
    services?.filter((service) =>
      q === null
        ? true
        : service.service_detail?.name.toLowerCase().includes(q.toLowerCase())
    );
  const hightlightServiceName = (name: string, search: string) => {
    const parts = name.split(new RegExp(`(${search})`, "gi"));
    return parts.map((part, index) => {
      if (part.toLowerCase() === search.toLowerCase()) {
        return (
          <span key={index} className="text-white bg-primary">
            {part}
          </span>
        );
      } else {
        return part;
      }
    });
  };

  const handleEditSevice = (index: number, service: Service) => {
    console.log("hello eow", index, service);
    setEditingIndex(index);
    setCurrentService(service);
    setEditedService({
      ...editedService,
      _id: service._id,
      name: service.service_detail.name,
      size: service.size,
      duration: service.duration,
      price: service.price,
      description: service.description,
    });
  };

  const openModal = (index: number) => {};
  const handleSelectService = (service: Service) => {
    setEditedService(service);
  };
  const handleClose = () => {
    setIsShow(true);
  };
  return (
    <>
      {searchable && (
        <div className="d-flex justify-content-between p-0 m-0 align-items-center professional_services">
          <h5>Services provided</h5>
          <div
            className="input-group d-flex m-0  mb-2 align-items-center w-50"
            aria-autocomplete="none"
          >
            <button
              style={{
                backgroundColor: "white",
                height: "45px",
                zIndex: "1",
              }}
              className="btn border-0"
              type="button"
            >
              <i className="fa-solid fa-search"></i>
            </button>
            <input
              value={q ?? ""}
              onChange={(e) => setQ(e.target.value)}
              placeholder="Search for service..."
              type="text"
              className="form-control border-0  no-decoration fw-500"
            />
          </div>
        </div>
      )}

      <div
        className={`d-flex flex-column mt-2 see-pro-service-list ${
          scrollable && "scrollable"
        }`}
      >
        {!services && <ServiceListSkeleton />}

        <>
          {services &&
            Object.values(
              filteredServices?.reduce((acc: any, service, index) => {
                const serviceWithIndex = { ...service, index };
                if (!acc[service.service_detail.name]) {
                  acc[service.service_detail.name] = [serviceWithIndex];
                } else {
                  acc[service.service_detail.name].push(serviceWithIndex);
                }
                return acc;
              }, {})
            ).map((servicesWithSameName: any, groupIndex) => (
              <div key={groupIndex} className="d-flex flex-column w-100">
                <div
                  className="d-flex justify-content-between professional_service align-items-center w-100"
                  key={servicesWithSameName[0]?._id}
                  onClick={() => onSelect && onSelect(servicesWithSameName[0])}
                >
                  <div className="d-flex flex-column">
                    <h5 className="text-black">
                      {hightlightServiceName(
                        servicesWithSameName[0]?.service_detail?.name,
                        q ?? ""
                      )}
                    </h5>
                    <div className="professional_service__description">
                      {servicesWithSameName[0]?.description}
                    </div>
                    <span className="text-muted">
                      {formatUSPrice(servicesWithSameName[0]?.price)} for{" "}
                      {formatTime(
                        servicesWithSameName[0]?.duration as unknown as number
                      )}
                    </span>
                  </div>

                  {onseclectable && (
                    <div>
                      <Button
                        onClick={() => {
                          handleEditSevice(
                            servicesWithSameName[0].index,
                            servicesWithSameName[0]
                          );
                        }}
                        className="br-6"
                        type="button"
                        onDoubleClick={handleClose}
                        content={<span className="mx-3">Add promotion</span>}
                      />
                    </div>
                  )}
                </div>
                <hr className="w-100" />
              </div>
            ))}
          {services && services.length === 0 && (
            <div
              style={{ minHeight: "50vh" }}
              className="w-100 d-flex justify-content-center align-items-center small text-muted flex-column fs-6"
            >
              <i className="fas fa-search-minus fa-3x mb-2"></i>
              <h4>Oops! No Results Found</h4>
              <span className="text-center">
                We couldn't find anything based on your search.
              </span>
            </div>
          )}
          {services &&
            services.length !== 0 &&
            filteredServices?.length === 0 && (
              <div className="d-flex user-select-none text-muted gap-3 align-items-center justify-content-center mt-5">
                <i className="fas fa-search-minus fa-3x mb-2"></i>
                <div className="text-center fs-4">
                  No service matches your search. Check your spelling or maybe
                  <br />
                  you didn't add it to your service list.
                </div>
              </div>
            )}
          {/* {services && (
            <PromotionModal
              service={services as any}
              show={isShow}
              onClose={() => handleClose}
              onSelect={handleSelectService}
            />
          )} */}
        </>
      </div>
    </>
  );
};

export default ServicePromotionList;
