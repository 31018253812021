import { DiscussionSystemProps } from "../../LatriceDiscussionMessage";
import { DisputedDiscussionBase } from "./DisputedDiscussionBase";
import { Alert } from "react-bootstrap";

export const DisputedDiscussionProfessional = (
  props: DiscussionSystemProps
) => {
  return (
    <DisputedDiscussionBase
      appointment={props.appointment}
      alertContainer={
        <Alert
          className="alert-warning--bordered"
          key={"warning"}
          variant={"warning"}
        >
          <i className="fa fa-triangle-exclamation fa-1x"></i>
          You have requested a change for{" "}
          {[
            props.appointment.disputeReasons.badDate ? (
              <strong>date</strong>
            ) : null,
            props.appointment.disputeReasons.badDuration ? (
              <strong>duration</strong>
            ) : null,
            props.appointment.disputeReasons.badDescription ? (
              <strong>description</strong>
            ) : null,
            props.appointment.disputeReasons.priceTooLow ? (
              <strong>price</strong>
            ) : null,
          ]
            .filter((item) => item !== null)
            .reduce((prev, curr, index) => (
              <>
                {prev}
                {index > 0 ? " and " : ""}
                {curr}
              </>
            ))}
          . We are currently awaiting the user's response to proceed further.
        </Alert>
      }
    />
  );
};
