import Button from "../Button";

const ProfileLoader = () => {
  return (
    <div className="Profile d-flex flex-column">
      <div className="ProfileCover d-flex flex-column align-items-center">
        <div className="ProfileCoverContainer d-flex justify-content-between gap-1 w-100">
          <div
            style={{ height: "200px" }}
            className="cover_img  w-100 position-relative d-flex gap-1"
          >
            <div
              style={{ borderRadius: "0px" }}
              className="shimmer shimmer__square h-100 w-100"
            ></div>
            <div
              style={{
                width: "75px",
                height: "75px",
                boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
                border: "2px solid white",
              }}
              className="profile_img position-absolute shimmer shimmer__circle top-100 start-50 translate-middle"
            ></div>
          </div>
        </div>

        <div className="ProfileAddCover">
          <button
            disabled
            style={{ borderRadius: "1rem" }}
            className={"mt-1 btn bg-white"}
          >
            EDIT COVER <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div
        style={{ height: "190px" }}
        className="ProfileMeta d-flex flex-column justify-content-center text-center mt-3"
      >
        <h4 className="text-black fw-500">
          <div
            style={{ borderRadius: "0px", height: "10px", width: "50%" }}
            className="shimmer shimmer__line mx-auto rounded"
          ></div>
        </h4>
        <span className="text-muted small">
          <div
            style={{ borderRadius: "0px", width: "70%", height: "10px" }}
            className="shimmer shimmer__line mx-auto rounded"
          ></div>
        </span>
        <span className="mt-1 d-flex justify-content-center align-items-center mt-2">
          <div className="starsContainer">
            <div
              style={{ borderRadius: "0px", width: "140px", height: "10px" }}
              className="shimmer shimmer__line mx-auto rounded"
            ></div>
          </div>
        </span>
      </div>
      <div
        style={{ gap: "10%", marginTop: "4rem" }}
        className="d-flex profile_btns p-2 mt-auto"
      >
        <Button
          disabled
          className="btn_outlined"
          mode={"outlined"}
          content={"View Insights"}
        />
        <Button
          disabled
          mode={"primary"}
          content={
            <div>
              Promote Profile
              <i
                style={{
                  position: "absolute",
                  right: "20px",
                  fontWeight: "900",
                }}
                className="fa-regular fa-circle-info"
              ></i>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ProfileLoader;
