import React, { useState, useEffect } from "react";
import { ISalon } from "../../../models/SalonX";
import { formatName } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getManagers,
  selectManagersWithoutSalon,
} from "../../../redux/slices/managerSlice";
import { AnyAction } from "@reduxjs/toolkit";

type CreateSalonStep3Props = {
  salonValues: Partial<ISalon>;
  setSalonValues: (values: Partial<ISalon>) => void;
};

const CreateSalonStep3: React.FC<CreateSalonStep3Props> = ({
  salonValues,
  setSalonValues,
}) => {
  const dispatch = useDispatch();
  const UserManagers = useSelector(selectManagersWithoutSalon);

  const isValid = salonValues.manager_id?.trim() !== "";

  useEffect(() => {
    dispatch(getManagers("") as unknown as AnyAction);
    console.log("UserManager", UserManagers);
  }, []);
  return (
    <form className="w-100 p-3 ps-4 mt-3">
      <span className="mb-4">Step 3/4</span>
      <h3 className="text-black mb-3">Assign a manager to your new business</h3>
      <span className=" mb-3">
        If you did not create any manager yet, don't worry! You can skip this
        step and assign a manager to your business later
      </span>
      <hr />
      <div
        className="flex-column gap-1 rounded salon-input w-100 my-5 "
        aria-autocomplete="none"
      >
        <select
          className="form-select salon-input py-2"
          aria-label="Default select example"
          onChange={(e) =>
            !isValid
              ? setSalonValues({
                  ...salonValues,
                  manager_id: e.target.value,
                })
              : {}
          }
        >
          <option value="" selected>
            Assign A manager
          </option>
          {UserManagers?.map((manager) => (
            <option key={manager._id} value={manager._id}>
              {formatName(
                manager?.user?.first_name ?? "",
                manager?.user?.last_name ?? ""
              )}
            </option>
          ))}
        </select>
      </div>
    </form>
  );
};

export default CreateSalonStep3;
