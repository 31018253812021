export const slides = [
  {
    image:
      "https://cdn.pixabay.com/photo/2020/12/08/19/12/woman-5815354_960_720.jpg",
    text1: "Welcome back to you, let's search with you",
    text2: "An endless list of verified professionals",
    icon1: "fa-solid fa-magnifying-glass-location",
    icon2: "fa-solid fa-users",
  },
  {
    image:
      "https://images.pexels.com/photos/3151296/pexels-photo-3151296.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    text1: "Find the best professional who is near you",
    text2: "Save your time, be sublime in time",
    icon1: "fa-solid fa-magnifying-glass",
    icon2: "fa-regular fa-clock",
  },
  {
    image:
      "https://images.pexels.com/photos/2089801/pexels-photo-2089801.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load",
    text1: "A platform that connects you with the best",
    text2: "We check and verify for you according to your wishes",
    icon1: "fa-solid fa-users",
    icon2: "fa-solid fa-check-double",
  },
];
