import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import ModalLoader from "../../loader/ModalLoader";
import { IChair } from "../../../models/Chair";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { addChair } from "../../../services/ChairService";
import { useSalon } from "../../../context/SalonContext";

type AddChairModalProps = {
  show: boolean;
  onClose: () => void;
  setChairs: (chairs: IChair[]) => void;
  chairs: IChair[];
};

const AddChairModal: FC<AddChairModalProps> = ({
  show,
  onClose,
  setChairs,
  chairs,
}) => {
  const salon = useSalon();

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const salonId = salon.salon?._id ?? location.pathname.split("/").pop();
  const [chairData, setChairData] = useState<Partial<IChair>>({
    active: false,
    salonId,
    label: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setChairData({
      ...chairData,
      [name]: value,
    });
  };

  const handleAdd = () => {
    if (chairData.label !== "") {
      setLoading(true);
      addChair(chairData)
        .then((res: any) => {
          toast.success("Seat created succesfully!");
          const newChair = chairs?.slice();
          newChair?.push(res.data as IChair);
          if (newChair) setChairs(newChair);
        })
        .catch((err: any) => {
          console.log("the error", err);
          toast.error(
            "An error occured, This chair should already exist , try adding another label"
          );
        })
        .finally(() => {
          setLoading(false);
          onClose();
          setChairData({
            label: "",
            salonId,
          });
        });
    } else {
      toast.remove();
      toast.error("You need to add a label");
    }
  };

  // console.log("here is the chair i want to add", chairs, salon);
  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={() => {
        onClose();
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <div className="text-black fw-500 fs-5">Add a new seat</div>
      </Modal.Header>
      {loading && <ModalLoader />}
      <Modal.Body className="border-top border-bottom">
        <div className="w-100 px-3 py-4">
          <div className="d-flex p-0 flex-column salon-input gap-1 mb-3">
            <span className="text-primary">
              Label *<i className="ms-2 fa-solid fa-tag text-primary"></i>
            </span>
            <input
              type="text"
              className="bg-white border rounded"
              placeholder="label"
              id="label"
              name="label"
              onChange={handleChange}
              value={chairData?.label}
              required
            />
          </div>
          <div className="d-flex p-0 flex-column salon-input gap-1 ">
            <span className="text-primary">
              Notes
              <i className="ms-2 fa-regular fa-note-sticky text-primary"></i>
            </span>
            <textarea
              placeholder="Add a note"
              name="note"
              id="note"
              className="border rounded p-2"
              // onChange={handleChange}
              cols={30}
              maxLength={150}
              // value={newService.description}
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <div className="mx-3 my-1 d-flex justify-content-end align-items-center gap-3">
          <button onClick={onClose} className="working-table-outline-button">
            Cancel
          </button>
          <button onClick={handleAdd} className="working-table-primary-button">
            Add
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddChairModal;
