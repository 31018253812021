import React, { useEffect, useState } from "react";
import { ISalon } from "../../models/SalonX";
import { useUser } from "../../hooks/UseUser";
import { isUserProfessional } from "../../models/User";
import { useSelector } from "react-redux";
import {
  ManagerState,
  selectManagerBySalonId,
  selectManagersWithoutSalon,
} from "../../redux/slices/managerSlice";
import ManageSalon from "./ManageSalon";
import { AuthState } from "../../redux/slices/authSlice";
import ManageIndependentSalon from "./ManageIndependentSalon";

type SalonContainerProps = {};

const IndependentSalon: React.FC<SalonContainerProps> = ({}) => {
  const [q, setQ] = useState<string | null>(null);
  const [salon, setSalon] = useState<ISalon | null>(null);
  const [salonToManage, setSalonToManage] = useState<ISalon | null>(null);
  const user = useUser();
  const managerForSalon = useSelector((state: { managers: ManagerState }) =>
    selectManagerBySalonId(state, salonToManage?._id as string)
  );
  const currentUser = useSelector<unknown, AuthState>(
    (state: any) => state.auth
  ).currentUser;

  useEffect(() => {
    if (isUserProfessional(user)) {
      setSalon(user.salons[0]);
    }
  }, [user]);
  console.log("yess sthe user", user);
  return (
    <div>
      {salon ? (
        <ManageIndependentSalon
          setSalon={setSalon}
          salon={salon}
          user={currentUser!!}
        />
      ) : (
        <div>Lorem ipsum dolorsit amet</div>
      )}
    </div>
  );
};

export default IndependentSalon;
