import axios from "axios";
import { IProject } from "../models/Project";

const getProjects = (status: IProject["status"] | undefined) => {
  return axios
    .get(`projects${status ? `?status=${status}` : ""}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const acceptProject = (project: IProject) => {
  return axios
    .get(`projects/accept/${project._id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "x-salon-id": `${localStorage.getItem("salonId")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const cancelProject = (project: IProject, type: "client" | "professional") => {
  return axios
    .get(`projects/cancel/${project._id}?cancelledBy=${type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "x-salon-id": `${localStorage.getItem("salonId")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const deleteProject = (project: IProject) => {
  return axios
    .get(`projects/delete/${project._id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "x-salon-id": `${localStorage.getItem("salonId")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const postProject = (project: FormData) => {
  return axios
    .post(`projects`, project, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const editProject = (project: FormData ,id:String) => {
  return axios
    .patch(`projects/${id}`, project, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
console.log("data",data)
      return data;
    });
};

//  const editProject = (project: FormData,id:String) => {
//   return axios({
//     url: `projects/${id}`,
//     method: "PATCH",
//     data: project,
//     headers: {
//         Authorization: `Bearer ${localStorage.getItem("authToken")}`,
// },
//   }).then((data) => {
//     return data;
//   });
// };

export const ProjectService = {
  getProjects,
  acceptProject,
  deleteProject,
  postProject,
  editProject,
  cancelProject,
};
