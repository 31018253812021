import axios from "axios";
import { ISalon } from "../models/SalonX";
import { error } from "console";

const getSalons = () => {
  return axios
    .get(`salons`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const addSalon = (salon: Partial<ISalon>) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}salons`,
    method: "POST",
    data: salon,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

const getSalonName = () => {
  return axios
    .get(`unauthenticated/salons/names`)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getAllNuggets = () => {
  return axios
    .get(`unauthenticated/salons/nuggets`)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const getSalonByRating = () => {
  return axios
    .get(`unauthenticated/salons/rating`)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const applyPromotion = () => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}salons/promotions`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

export const SalonService = {
  getSalons,
  addSalon,
  getSalonName,
  getSalonByRating,
  getAllNuggets,
  applyPromotion,
};
