import React from 'react'
import { ISearchData } from '../../../../utils/types'
import SearchResultItem, { SearchResultItemProps } from './SearchResultItem'
import Button from '../../../Button'
import CardLoader from '../../../loader/CardLoader'
import PulseLoader from '../../../loader/PulseLoader'


type ResultContainerProps = {
    loading: boolean
    isDataLoading: boolean
    canLoadMore: boolean
    onLoadMore: () => void
} & Pick<ISearchData, 'results'>


export const ResultContainer: React.FC<ResultContainerProps> = ({
    results,
    canLoadMore,
    onLoadMore,
    loading,
    isDataLoading,
    ...props
}) => {

    const renderProfessionals = (salons: ISearchData['results']) => {
        if (!salons) return null
        return salons.map(
            (salon: SearchResultItemProps) => {

                return <SearchResultItem
                    key={salon._id}
                    {...salon}
                />
            }
        )
    }

    const renderLoader = (numberOfItem: number) => {
        return Array(numberOfItem).fill(0).map((_, key) => {
            return <CardLoader key={key} />
        })
    }


    return (
        <div>
            <div className="HomePagePro d-flex flex-wrap gap-5 my-5 justify-content-center">
                {
                    loading ? renderLoader(10) : renderProfessionals(results)
                }
            </div>

            {(!loading && isDataLoading) ? <PulseLoader /> : null}

            <div
                className={
                    "LoadMoreContainer align-items-center mb-5 justify-content-center" +
                    (canLoadMore
                        ? " d-none"
                        : " d-flex")
                }
            >
                {(!loading && !isDataLoading) && (
                    <Button
                        onClick={onLoadMore}
                        className="br-6 fw-500"
                        mode="primary"
                        content={<span className="mx-3">Load more</span>}
                    />
                )}
            </div>
        </div>
    )
}
