import React, { useEffect } from "react"; // Make sure to import the Time component or replace it with your time picker component
import moment from "moment";
import Time, { TimeOfDay } from "../../reusable/Time";
import SlidePicker from "../../SlidePicker";
import { filterByTimeOfDay } from "../helper/helper";

interface TimeSelectionFormProps {
  onTimeChange: (time: moment.Moment) => void;
  times: moment.Moment[];
}

const TimeSelectionForm: React.FC<TimeSelectionFormProps> = ({
  onTimeChange,
  times = [],
}) => {
  const [selectedTime, setSelectedTime] = React.useState<moment.Moment>();

  const [timeOfDay, setTimeOfDay] = React.useState<TimeOfDay | null>(null);

  // filter times by timeOfDay
  const filteredTimes = timeOfDay ? filterByTimeOfDay(timeOfDay, times) : times;

  const handleTimeChange = (time: moment.Moment) => {
    setSelectedTime(time);
    onTimeChange(time);
  };

  React.useEffect(() => {
    if (times.length > 0) setSelectedTime(times[0]);

    return () => {
      setTimeOfDay(null);
    };
  }, [times]);

  useEffect(() => {
    console.log("here is my availabily time ", times);
  }, []);

  return (
    <div className="mb-3">
      <label htmlFor="" className="form-label text-primary fw-500">
        What Time? *
      </label>
      <div className="mb-3 d-flex justify-content-center w-100">
        <div className="WhatTime d-flex flex-row justify-content-center">
          <Time value={timeOfDay} onChange={setTimeOfDay} />
        </div>
      </div>
      {filteredTimes.length > 0 ? (
        <SlidePicker
          data={filteredTimes}
          selected={selectedTime}
          keyExtractor={(item) => item.format("YYYY-MM-DD-HH-mm-A")}
          separator={<span className="mx-1"></span>}
          renderItem={(item) => (
            <div className="hourItem gap-1 d-flex flex-row fw-bold p-2 br-6 btn-secondary">
              <div>{item.format("h:mm")}</div>
              <div>{item.format("A")}</div>
            </div>
          )}
          onSelectItem={handleTimeChange}
        />
      ) : (
        <div className="small text-muted d-flex flex-column gap-2 justify-items-center align-items-center w-100">
          <i className="fa fa-calendar-days fa-3x"></i>
          <span className="col-md-9 text-center">
            Sorry, there are no available slots. Please select another day
          </span>
        </div>
      )}
    </div>
  );
};

export default TimeSelectionForm;
