import React, { ImgHTMLAttributes, Suspense } from 'react';

interface LazyLoadedImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    shimmerElement?: JSX.Element
}

const LazyLoadedImage: React.FC<LazyLoadedImageProps> = ({
    src,
    alt,
    shimmerElement = <div className='shimmer shimmer__square'></div>,
    ...rest
}) => {

    return <Suspense fallback={shimmerElement} >
        <img src={src} alt={alt} {...rest} />
    </Suspense>
};

export default LazyLoadedImage;