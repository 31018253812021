"use client";

import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import convertToSubcurrency from "../../utils/convertToSubcurrency";
import { AccountService } from "../../services/AccountService";
import { useDispatch } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import { formatUSPrice } from "../../utils/utils";
import toast from "react-hot-toast";
import { depositFunds } from "../../redux/slices/accountSlice";

const CheckoutPage = ({
  amount,
  setAmount,
  onBack,
}: {
  amount: number;
  setAmount: (amount: string) => void;
  onBack: () => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Number(amount) > 0) {
      AccountService.createPaymentIntent(amount)
        .then((data: any) => {
          console.log("data", data);
          setClientSecret(data.clientSecret);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [amount]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();

    if (!submitError) {
    }

    if (submitError) {
      setErrorMessage("error");
      return;
    } else {
      dispatch(depositFunds({ amount: amount }) as unknown as AnyAction)
        .unwrap()
        .then(async () => {
          onBack();
          toast.success(
            `${formatUSPrice(
              amount
            )} has been successfully added to your balance !`,
            { duration: 15000 }
          );

          const { error } = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
              return_url: `https://dev-latrice.evenafro.ca/client-profile`,
            },
          });

          console.log("the error", error);
        })
        .catch((error: any) => {
          console.log(error);
          toast.error("Error: unable to perform the transaction !", {
            duration: 10000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }

  };

  const handleAmountChange = (e: any) => {
    const newAmount = e.target.value; // Supprime les caractères non numériques
    // setAmount(e.target.value);

    if (Number(newAmount) <= 0) {
      setErrorMessage("Veuillez entrer un montant supérieur à 0.");
    } else {
      setAmount(newAmount);
      setErrorMessage("");
    }
  };

  if (!clientSecret || !stripe || !elements) {
    return (
      <div className="flex items-center justify-center">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white p-2 rounded-md">
      {clientSecret && (
        <div>
          <div>
            <label htmlFor="amount" className="form-label">
              Amount to add
            </label>
            <input
              id="amount"
              min={10}
              max={1000}
              step={5}
              type="number"
              className="border rounded p-2 px-3 small-text w-100"
              placeholder="Enter amount"
              onChange={handleAmountChange}
            />
          </div>
          <PaymentElement />
        </div>
      )}

      {errorMessage && <div>{errorMessage}</div>}

      <button
        disabled={!stripe || loading}
        className="text-white w-full p-5 bg-black mt-2 rounded-md font-bold disabled:opacity-50 disabled:animate-pulse"
      >
        {!loading ? `Pay $${amount}` : "Processing..."}
      </button>
    </form>
  );
};

export default CheckoutPage;
