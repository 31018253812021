import React, { useState } from "react";
import { ISalon } from "../../../models/SalonX";

type CreateSalonStep1Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  salonValues: Partial<ISalon>;
};

const CreateSalonStep1: React.FC<CreateSalonStep1Props> = ({
  onChange,
  salonValues,
}) => {
  return (
    <form className="w-100 p-3 ps-4 mt-3">
      <span className="mb-4">Step 1/4</span>
      <h3 className="text-black mb-3">Let's start with your business name</h3>
      <span className=" mb-3">
        Please fill in the details below so that we can have necessary
        information about your salon
      </span>
      <hr />
      <div className="d-flex p-0 flex-column salon-input gap-1 my-5">
        <span className="text-primary">Enter your business name</span>
        <input
          type="text"
          className="bg-white border rounded"
          placeholder="business name"
          name="name"
          id="name"
          value={salonValues.name}
          onChange={onChange}
        />
      </div>
    </form>
  );
};

export default CreateSalonStep1;
