import { faPlus, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "react-calendar/dist/Calendar.css";
import Button from "../Button";
import styles from "../styles/AddAppointmentModal.module.css";
import "../styles/Calendar.css";
import { IAppointment } from "../../models/Appointment";
import { Appointment } from "./Appointment";
import { IAppointmentCancellation } from "../../models/AppointmentCancellation";

export interface AddAppointmentModalOptions {
  open?: boolean;
  appointments: IAppointmentCancellation[];
  onSelect: (value: IAppointmentCancellation | null) => void;
}

const NoCancelledAppointments: React.FC = () => {
  return (
    <div className="d-flex flex-column align-items-center w-100 justify-content-center h-100 user-select-none">
      <FontAwesomeIcon icon={faTriangleExclamation} size="4x" className="text-secondary mb-3 text-muted" />
      <div className="text-muted w-75 text-center">No canceled appointments at this time.</div>
    </div>
  );
};

const ListAppointmentModal: React.FC<AddAppointmentModalOptions> = ({
  open = false,
  appointments,
  onSelect,
}) => {
  const [selectedAppointment, setSelectedAppointment] =
    useState<IAppointmentCancellation | null>(null);

  const handleSelectAppointment = (value: IAppointmentCancellation) => {
    if (value === selectedAppointment) {
      setSelectedAppointment(null);
      onSelect(null);
    } else {
      setSelectedAppointment(value);
      onSelect(value);
    }
  };

  return (
    <div
      className={`${styles.AddAppointmentModal} bg-white p-3 ${open ? `${styles["AddAppointmentModal--open"]}` : ``
        } d-flex justify-content-center p-2`}
    >
      <div className={`d-flex flex-column gap-2 w-100`}>
        <button
          className={`${styles["AddAppointmentModal__btnTutorial"]} ms-auto tutorial`}
        >
          Calendar Tutorial
        </button>
        <div style={{ minHeight: '50vh', maxHeight:'60vh' }} className="position-relative">
          {
            appointments.length > 0 ? <>
              <h5 className="fw-bold">Your appointments</h5>
              <div className="fw-500 mb-4">Discover the cancelled appointments</div>
              <div className={`d-flex flex-column gap-2 h-75 overflow-auto`}>
                {appointments.map((appointment) => (
                  <Appointment
                    key={appointment._id}
                    onClick={() => handleSelectAppointment(appointment)}
                    selected={appointment._id === selectedAppointment?._id}
                    mode="nocancel"
                    appointment={appointment.appointment}
                    onCancel={function (appointment: IAppointment): Promise<any> {
                      throw new Error("Function not implemented.");
                    }} />
                ))}
              </div>
            </> :
              <NoCancelledAppointments />
          }
          <div className="mt-2 position-absolute  w-100 bottom-0">
            <Button
              className="btn-primary br-6 py-2 w-100"
              content={
                <>
                  <FontAwesomeIcon icon={faPlus} /> Take New Appointment
                </>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ListAppointmentModal };
