import React from "react";
import MainNav from "../components/navigation/MainNav";
import Footer from "../components/Footer";

type Props = {};

const ErrorPage = (props: Props) => {
  return (
    <div className="d-flex flex-column justify-content-between" style={{ height: '100vh' }}>
      <MainNav navType={"homeNav"} silent />
      <div className="my-auto text-dark user-select-none fw-bold d-flex flex-column align-items-center justify-content-center">
        <div className="fs-2">404 Page Not Found</div>
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default ErrorPage;
