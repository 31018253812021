

const TermOfService = ({ title, content }) => {

    return <div className="TermOfService">
        <h3 className="text-primary mb-3">{title}</h3>
        <div style={{ maxHeight: '320px', overflowY: 'auto' }}>
            {content}
        </div>
    </div>

}

export default TermOfService;