import React, { useEffect } from "react";
import {
  ChatSearchArea,
  Loader,
  NoAvailableDiscussion,
} from "./ChatSearchArea";
import LatriceRecentChat from "./RecentChat";
import { ChatList } from "./ChatList";

interface ChatUserListProps {
  discussions: any;
  isVisible: boolean;
  handleOpenLatriceDiscussion: (discussionSystem: any) => void;
  handleOpenDiscussion: (discussion: any) => void;
  onLoadingDiscussionMessages: boolean;
  setQ: (q: string | null) => void;
}

const ChatUserList: React.FC<ChatUserListProps> = ({
  discussions,
  isVisible,
  handleOpenLatriceDiscussion,
  handleOpenDiscussion,
  onLoadingDiscussionMessages,
  setQ,
}) => {
  const renderCustomAppointments = () => {
    const { system } = discussions;

    if (system?.customAppointments?.length !== 0) {
      return (
        <LatriceRecentChat
          onClick={() => handleOpenLatriceDiscussion(system)}
          lastMessage={`You have ${system.customAppointments.length} pending custom appointment.`}
          unread={0}
          time={"just now"}
        />
      );
    }

    return null;
  };

  const renderUserDiscussions = () => {
    const { user } = discussions;

    if (user?.length !== 0) {
      return (
        <ChatList
          data={user as any}
          onSelectDiscussion={handleOpenDiscussion}
        />
      );
    }

    return <NoAvailableDiscussion />;
  };

  const renderLoading = () => (
    <div className="h-100 w-100 d-flex flex-column">
      <Loader />
    </div>
  );

  useEffect(() => {
    console.log("The discussions", discussions);
  }, []);

  return (
    <div
      className={`d-flex flex-column ${
        isVisible ? "UserContainer" : "user_container"
      }`}
      style={{ width: "100%", overflowY: "auto" }}
    >
      <ChatSearchArea onChange={setQ} />
      <div className={`RecentChats h-100 ${isVisible ? "conversations" : ""}`}>
        {discussions === null ? (
          renderLoading()
        ) : (
          <>
            {renderCustomAppointments()} {renderUserDiscussions()}
          </>
        )}
      </div>
    </div>
  );
};

export default ChatUserList;
