import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import UpcomingHours, { DayTimes } from "../components/UpcomingHours";
import "../styles/ManageAvailabilityPage.css";
import { AvailabilityState, addAvailability, getAvailabilities } from "../redux/slices/availabilitySlice";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import Availability from "../models/Availability";
import { findRemovedDayTimes, findTimeDifferences, timeToTimezone } from "../utils/utils";
import PulseLoader from "../components/loader/PulseLoader";
import AvailabilityChangesModal from "../components/modal/AvailabilityChangesModal";
import { AuthState } from "../redux/slices/authSlice";
import { UnAvailabilityState, addUnAvailability, getUnAvailabilities } from "../redux/slices/unAvailabilitySlice";
import UnAvailabilityChangesModal from "./modal/UnAvailabilityChangesModal";

export type USATimezone =
    | 'US/Eastern'
    | 'US/Central'
    | 'US/Mountain'
    | 'US/Pacific'
    | 'US/Alaska'
    | 'US/Hawaii'
    | 'US/Samoa';

export const USATimezones = [
    { value: 'US/Pacific', label: 'US/Pacific (GMT-8)' },
    { value: 'US/Mountain', label: 'US/Mountain (GMT-7)' },
    { value: 'US/Central', label: 'US/Central (GMT-6)' },
    { value: 'US/Eastern', label: 'US/Eastern (GMT-5)' },
    { value: 'US/Alaska', label: 'US/Alaska (GMT-9)' },
    { value: 'US/Hawaii', label: 'US/Hawaii (GMT-10)' },
    { value: 'US/Arizona', label: 'US/Arizona (GMT-7)' },
    { value: 'US/Indiana-Starke', label: 'US/Indiana-Starke (GMT-6)' },
    { value: 'US/Indiana/Indianapolis', label: 'US/Indiana/Indianapolis (GMT-5)' },
    { value: 'US/Michigan', label: 'US/Michigan (GMT-5)' },
];

const ManageFreeTime = () => {
    const availabilityData = useSelector<unknown, UnAvailabilityState>((state: any) => state.unavailabilities).unavailabilities;
    const authData = useSelector<unknown, AuthState>((state: any) => state.auth).currentUser;
    const submitBtnRef = useRef<HTMLButtonElement>();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // toggle management containers visibility
    const [active, setActive] = useState("schedule");
    const [availabilitiesTimes, setAvailabilitiesTimes] = useState<DayTimes>({});
    const [upcomingTimes, setUpcomingTimes] = useState<DayTimes>({});
    const [onSaveAvailability, setOnSaveAvailability] = useState(false);
    const [canSaveAvailability, setCanSaveAvailability] = useState(false);

    const [defaultAvailabilities, setDefaultAvailabilities] = useState<DayTimes | null>(null);
    const [defaultTimezone, setDefaultTimezone] = useState<USATimezone>('US/Pacific');
    const [timezone, setTimezone] = useState<USATimezone | null>(defaultTimezone);
    const [availabilityChanges, setAvailabilityChanges] = useState<{
        timezone: USATimezone,
        initial: DayTimes,
        edited: DayTimes,
        removed: DayTimes
    }>({
        timezone: defaultTimezone,
        initial: {},
        edited: {},
        removed: {}
    });

    const handleSave = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (submitBtnRef && submitBtnRef.current) {
            submitBtnRef.current.setAttribute("disabled", "true");
            submitBtnRef.current.innerHTML = "loading...";
            setTimeout(() => {

                navigate("/business-profile");
            }, 2000);
        }
    };

    const handleCancel = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        navigate("/business-profile");
    };

    const handleAvailabilityChange = (times: DayTimes) => {
        // Check if there is a difference between 
        // defaultAvailabilities and times
        const dayTimesChanges = findTimeDifferences(defaultAvailabilities ?? {}, times);
        const dayTimesRemoved = findRemovedDayTimes(defaultAvailabilities ?? {}, times);

        const canSave = JSON.stringify(dayTimesChanges) !== JSON.stringify({}) ||
            JSON.stringify(dayTimesRemoved) !== JSON.stringify({});

        if (canSave) {

            const initialDayTimesChanged: DayTimes = {};

            if (defaultAvailabilities) {
                Object.keys(dayTimesChanges)
                    .forEach((day) => {
                        if (defaultAvailabilities[day] !== undefined) {
                            initialDayTimesChanged[day] = defaultAvailabilities[day];
                        }
                    });
                Object.keys(dayTimesRemoved)
                    .forEach((day) => {
                        if (defaultAvailabilities[day] !== undefined) {
                            initialDayTimesChanged[day] = defaultAvailabilities[day];
                        }
                    });
            }

            setAvailabilityChanges({
                timezone: (timezone ?? defaultTimezone) as USATimezone,
                initial: initialDayTimesChanged,
                edited: dayTimesChanges,
                removed: dayTimesRemoved
            });

        }

        setCanSaveAvailability(canSave);

        setAvailabilitiesTimes(times);

    }

    const handleChangeTimezone = (e: any) => {
        setTimezone(e.target.value);
        setAvailabilityChanges({ ...availabilityChanges, timezone: e.target.value })
    }

    const handleUpcomingHourChange = (times: DayTimes) => {
        setUpcomingTimes(times);
    }

    const handleSaveAvailability = () => {
        setOnSaveAvailability(true);
    }

    const handleAvailabilityChangesCancel = () => {
        setOnSaveAvailability(false);
    }

    const handleAvailabilityChangeSave = (changes: { edited: DayTimes, removed: DayTimes, timezone: USATimezone }) => {
        return dispatch(addUnAvailability(changes) as unknown as AnyAction).unwrap();
    }


    useEffect(() => {
        if (authData !== null && availabilityData === null) {
            dispatch(getUnAvailabilities() as unknown as AnyAction).unwrap().then((res: Partial<Availability>[]) => {
                const availabilities: DayTimes = {};
                res.forEach((availability: Partial<Availability>) => {
                    availabilities[availability.day as string] = {
                        start: timeToTimezone(availability.startTime as string, availability.timezone),
                        end: timeToTimezone(availability.endTime as string, availability.timezone)
                    };
                });
                const timezone: USATimezone = (res.length > 0 ? res[0].timezone : 'US/Pacific') as USATimezone;
                setDefaultTimezone(timezone);
                setDefaultAvailabilities(availabilities);
            });
        }
    }, [availabilityData, authData, dispatch])

    useEffect(() => {
        const availabilities: DayTimes = {};
        if (availabilityData) {
            availabilityData.slice().forEach((availability: Partial<Availability>) => {
                availabilities[availability.day as string] = {
                    start: timeToTimezone(availability.startTime as string, availability.timezone),
                    end: timeToTimezone(availability.endTime as string, availability.timezone)
                };
            })
            const timezone: USATimezone = (availabilityData.length > 0 ? availabilityData[0].timezone : 'US/Pacific') as USATimezone;
            setDefaultTimezone(timezone);
            setDefaultAvailabilities(availabilities);
            setAvailabilityChanges({
                timezone: defaultTimezone,
                initial: {},
                edited: {},
                removed: {}
            });
            setCanSaveAvailability(false);
        }
    }, [availabilityData, dispatch])


    return (
        <>
            <div className="w-100 d-flex flex-column gap-2">
                <h4 className="fw-500 text-primary ms-2">Breaktime hours</h4>
                <div className="d-flex justify-content-between align-items-center mx-2 mb-3">
                    <span className="fw-500 text-black">
                        Timezone{" "}
                        <i className="ms-1 fa fa-info-circle text-primary"></i>
                    </span>
                    {/* <span className="fw-500 text-black">Edit Availability</span> */}
                    <Button
                        disabled={!canSaveAvailability}
                        className="br-6"
                        ref={submitBtnRef as any}
                        onClick={handleSaveAvailability}
                        mode={"primary"}
                        content={<span className="mx-3">Save</span>}
                    />
                </div>
            </div>
            <div className="ManageShedule">
                <div className='p-2'>
                    <div className="mb-3">
                        <select onChange={handleChangeTimezone} style={{ fontSize: '1em !important' }} className="form-select form-select-lg" name="" id="">
                            {USATimezones.map((timezone, index) => (
                                <option key={index} value={timezone.value}>
                                    {timezone.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    {defaultAvailabilities !== null ?
                        <UpcomingHours onChange={handleAvailabilityChange} values={defaultAvailabilities} />
                        :
                        <PulseLoader />
                    }
                </div>
            </div>
            {onSaveAvailability && <UnAvailabilityChangesModal
                unAvailabilityChanges={availabilityChanges}
                onCancel={handleAvailabilityChangesCancel}
                onSave={handleAvailabilityChangeSave}
            />}
        </>
    );
};

export default ManageFreeTime;
