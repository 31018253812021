import React, { useState, ChangeEvent, useEffect } from "react";
import { ISalon } from "../../models/SalonX";
import { Stars } from "../Stars";
import Manager from "../../models/Manager";
import { formatName } from "../../utils/utils";
import { InputBoolean } from "../input";
import ConfirmDeleteModal from "../reusable/ConfirmDeleteModal";
import { useDispatch } from "react-redux";
import { adminUpdateSalon } from "../../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { UnassignManager, getManagers } from "../../redux/slices/managerSlice";
import ModalLoader from "../loader/ModalLoader";
import IUser from "../../models/User";

type ManageSalonProps = {
  setSalon: (arg: ISalon | null) => void;
  salon: ISalon;
  user: IUser;
};

const ManageIndependentSalon: React.FC<ManageSalonProps> = ({
  setSalon,
  salon,
  user,
}) => {
  const [formModified, setFormModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [managerToUnAssign, setManagerToUnAssign] = useState<Manager | null>(
    null
  );
  const [formValues, setFormValues] = useState<Partial<ISalon>>({
    _id: salon._id,
  });
  const dispatch = useDispatch();

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    const newValue =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : value;

    setFormValues((prevVal) => ({
      ...prevVal,
      [name]: newValue,
    }));
    setFormModified(true);
  };
  const handleEdit = () => {
    setLoading(true);
    dispatch(adminUpdateSalon(formValues) as unknown as AnyAction)
      .unwrap()
      .then(() => {})
      .finally(() => {
        toast.success("Salon info succesfully updated!");
        setSalon({ ...salon, ...formValues });
        setFormValues({ _id: salon._id });
        setLoading(false);
      });
  };
  const handleUnassignManager = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (managerToUnAssign?._id) {
      setLoading(true);
      dispatch(UnassignManager(managerToUnAssign._id) as unknown as AnyAction)
        .unwrap()
        .then(() => {
          toast.loading("processing...");
        })
        .catch((err: any) => {
          const error = err;
          toast.error(`Failed to unassign: ${error.status}`);
        })
        .finally(() => {
          setTimeout(() => {
            toast.remove();
            toast.success("Successfully unassigned");
            setManagerToUnAssign(null);
          }, 2000);
          setLoading(false);
        });
    }
  };

  return (
    <div className="row m-0 mt-3 flex-column ">
      {loading && <ModalLoader />}
 
      <div className="col-sm bg-white p-3 pt-4 mt-3 ">
        <div className="justify-content-between d-flex flex-wrap gap-2 salon-container-header align-items-start">
          <div className="d-flex gap-2 align-items-center">
            <img
              src={salon.profile ?? require("../../assets/images/profile.jpg")}
              style={{
                height: "150px",
                width: "150px",
                objectFit: "cover",
              }}
              alt=""
              className="rounded-circle border-white border"
            />
            <div className="d-flex flex-column ">
              <span className="fw-bold text-black fs-5 ">{salon.name}</span>
              <span className="">{salon.address}</span>
              <Stars
                canUpdate={false}
                stars={salon.reviewsStats.averageStars}
                notice={salon.reviewsStats.reviewCount}
              />
            </div>
          </div>
          <div className="d-flex flex-column gap-3">
            <button
              onClick={() =>
                (window.location.href = `/setup-business/manage-chairs/${salon._id}`)
              }
              className="rounded-pill p-1 px-2 button-27"
            >
              Manage seat allocations
            </button>
          </div>
        </div>
        <div className=" my-3">
          <i className="fa-solid fa-pen text-muted"></i>
          <span className="ms-2 text-muted ">
            Edit the necessary input fields to update information about this
            salon{" "}
          </span>
        </div>
        {/* <div className="d-flex gap-4 mt-3 flex-wrap ">
          <div className=" gap-1 d-flex align-items-start flex-column ">
            <div className=" d-flex ">
              <span className="text-black">Salon Name :</span>
              <span className="ms-2">{salon.name}</span>
            </div>
            <div className=" d-flex flex-column w-100">
              <input
                autoComplete="off"
                type="text"
                name="name"
                className="border rounded bg-transparent p-2"
                id="name"
                value={formValues.name ?? salon.name}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className=" gap-1 d-flex align-items-start flex-column ">
            <div className=" d-flex ">
              <span className="text-black">Salon Manager :</span>
              <span className="ms-2">
                {user
                  ? `${formatName(
                      user.first_name,
                      user.last_name
                    )}`
                  : "No manager"}
              </span>
            </div>
            {managerToUnAssign === null && managerForSalon ? (
              <div
                onClick={() => setManagerToUnAssign(managerForSalon)}
                className="d-flex align-items-center cursor-pointer"
              >
                <i className=" fa-solid fa-square-minus me-2 text-primary "></i>
                <span className="text-primary">unassign manager</span>
              </div>
            ) : (
              <div className=" d-flex flex-column w-100">
                <select
                  name="manager_id"
                  className="border rounded bg-transparent p-2"
                  id="manager_id"
                  style={{ height: "45px" }}
                  onChange={handleChange}
                  value={formValues.manager_id}
                >
                  <option selected disabled>
                    Choose manager
                  </option>
                  {UserManagers?.map((manager) => (
                    <option key={manager._id} value={manager._id}>
                      {formatName(
                        manager.user.first_name,
                        manager.user.last_name
                      )}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div> */}
        <div className="d-flex gap-4 my-4">
          <label className="list-group-item ">
            <input
              className="form-check-input me-1"
              type="checkbox"
              name="blow_dry"
              checked={formValues.blow_dry ?? salon.blow_dry}
              onChange={handleChange}
            />
            Blowdry
          </label>
          <label className="list-group-item">
            <input
              className="form-check-input me-1"
              type="checkbox"
              checked={formValues.hair_wash ?? salon.hair_wash}
              name="hair_wash"
              onChange={handleChange}
            />
            Hairwash
          </label>
          <label className="list-group-item">
            <input
              className="form-check-input me-1"
              checked={formValues.undo_hair ?? salon.undo_hair}
              type="checkbox"
              name="undo_hair"
              onChange={handleChange}
            />
            Undo hair
          </label>
        </div>
        <div className="d-flex flex-wrap gap-3">
          <InputBoolean
            title={
              <span className="text-primary">
                Kids service?{" "}
                <i className="fa-solid text-muted fa-children"></i>
              </span>
            }
            value={formValues.kids_service ?? salon.kids_service}
            name={"kids_service"}
            onChange={(val: boolean | null) => {
              setFormModified(true);
              setFormValues({
                ...formValues,
                kids_service: val ?? false,
              });
            }}
          />
          <InputBoolean
            title={
              <span className="text-primary">
                WheelChair accessible?{" "}
                <i className="fa-solid text-muted fa-wheelchair-move"></i>
              </span>
            }
            value={
              formValues.wheelchair_accessible ?? salon.wheelchair_accessible
            }
            name={"wheelchair_accessible"}
            onChange={(val: boolean | null) => {
              setFormModified(true);
              setFormValues({
                ...formValues,
                wheelchair_accessible: val ?? false,
              });
            }}
          />
          <InputBoolean
            title={
              <span className="text-primary">
                Accept custom orders?{" "}
                <i className="fa-regular text-muted fa-calendar-check"></i>
              </span>
            }
            value={
              formValues.accept_custom_orders ?? salon.accept_custom_orders
            }
            name={"accept_custom_orders"}
            onChange={(val: boolean | null) => {
              setFormModified(true);
              setFormValues({
                ...formValues,
                accept_custom_orders: val ?? false,
              });
            }}
          />
          <InputBoolean
            title={
              <span className="text-primary">
                Kids friendly?{" "}
                <i className="fa-solid text-muted fa-child-reaching"></i>
              </span>
            }
            value={formValues.kids_friendly ?? salon.kids_friendly}
            name={"kids_friendly"}
            onChange={(val: boolean | null) => {
              setFormModified(true);
              setFormValues({
                ...formValues,
                kids_friendly: val ?? false,
              });
            }}
          />
        </div>
        <div className="d-flex justify-content-between align-items-center mt-5">
          <span className="">Lorem ipsum, dolor sit amet consectetur</span>
          <button
            disabled={!formModified}
            onClick={handleEdit}
            className="button-57 rounded  px-3 p-2"
          >
            <span>Done</span>
            <span>Save changes</span>
          </button>
        </div>
      </div>
      <ConfirmDeleteModal
        show={managerToUnAssign !== null}
        setServiceToDelete={() => setManagerToUnAssign(null)}
        handleServiceDelete={handleUnassignManager}
        text1={`Do you really want to unassign ${managerToUnAssign?.user.first_name} to this salon?`}
        text2={"This action can not be..."}
        btnText1="unassign"
        btnText2="unassign Anyway"
      />
    </div>
  );
};

export default ManageIndependentSalon;
