import axios from "axios";
import { IAppointment } from "../models/Appointment";

const getAppointments = (mode: "normal" | "dashboard" = "normal") => {
  return axios
    .get(`appointments`, {
      params: { mode },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "x-salon-id": `${localStorage.getItem("salonId")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const getLastAAppointment = () => {
  return axios
    .get(`appointments`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "x-salon-id": `${localStorage.getItem("salonId")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const addAppointment = (service: Partial<IAppointment>) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}appointments`,
    method: "POST",
    data: service,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

const cancelAppointment = (appointment: Partial<IAppointment>) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}appointments/cancel/${appointment._id}`,
    method: "PUT",
    data: appointment,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};
const disputeAppointment = (data: {
  appointment: IAppointment;
  newDateProposal: string;
}) => {
  console.log("data", data);
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}appointments/dispute/${data.appointment._id}`,
    method: "PUT",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

const markAsStarted = (appointmentId: string) => {
  return axios({
    url: `appointments/mark-as-started/${appointmentId}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

const markAsEnded = (appointmentId: string) => {
  return axios({
    url: `appointments/mark-as-ended/${appointmentId}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data;
  });
};

export const AppointmentService = {
  getAppointments,
  getLastAAppointment,
  addAppointment,
  cancelAppointment,
  disputeAppointment,
  markAsStarted,
  markAsEnded,
};
