import React from "react";
import styles from "../styles/PulseLoader.module.css";


export interface PulseLoaderProps {
    title?: string;
}


const PulseLoader: React.FC<PulseLoaderProps> = ({ title = '' }) => {
    return <div className={`${styles["PulseLoader"]} d-flex flex-column align-items-center justify-content-center`}>
        <div className={`${styles.dots}`}></div>
        <div className="text-muted">{title}</div>
    </div>
}

export default PulseLoader;