import React from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';

export const TransactionHistorySkeleton = () => {


    const loader = <div
        style={{ height: "18px", marginBottom: "1rem" }}
        className="shimmer mt-3 shimmer__line w-75 mx-0 "
    ></div>

    const columns: TableColumn<number>[] = [
        {
            name: 'ID',
            cell: (row: number, rowIndex: number) => loader,
            sortable: true,
        },
        {
            name: 'Amount',
            cell: (row: number) => loader,
            sortable: true,
        },
        {
            name: 'Type',
            cell: (row: number) => loader,
            sortable: true,
        },
        {
            name: 'Date',
            cell: (row: number) => loader,
            sortable: true,
        },
    ];


    return (
        <DataTable
            className='w-100'
            title={
                <div className='d-flex justify-content-between'>
                    <div>Transaction History</div>
                </div>
            }
            paginationRowsPerPageOptions={[10]}
            columns={columns}
            data={Array(5).fill(0)}
            pagination
            highlightOnHover
        />
    );
}