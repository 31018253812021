import React, { useRef } from "react";
import Service from "../../models/Service";
import SelectSize from "../SelectSize";
import { useDispatch } from "react-redux";
import { editService } from "../../redux/slices/serviceSlice";
import { AnyAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

type EditServiceFormProps = {
  service: Service;
  editedService: Partial<Service>;
  currentService: Service | null;
  setCurrentService: (value: any) => void;
  setEditedService: (value: any) => void;
  setEditingIndex: (value: any) => void;
  setLoading: (value: boolean) => void;
};

const EditServiceForm: React.FC<EditServiceFormProps> = ({
  service,
  editedService,
  currentService,
  setCurrentService,
  setEditedService,
  setEditingIndex,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const submitBtnRef = useRef<any>();
  const closeBtnRef = useRef<any>();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setEditedService({
      ...editedService,
      [name]: value,
    });
  };

  const handleClose = () => {
    setEditingIndex(null);
    setEditedService({});
  };

  const handleSave = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    // if no changes are made return
    const changesMade =
      editedService &&
      currentService && // Check if currentService is available
      (editedService.name !== currentService?.service_detail.name ||
        editedService.price !== currentService?.price ||
        !Object.is(editedService.size, currentService?.size) ||
        !Object.is(editedService.duration, currentService?.duration) ||
        !Object.is(editedService.description, currentService?.description));

    if (!changesMade) {
      toast.error("You need to make changes to update");
      return;
    }
    if(editedService.duration && editedService.duration <= 4){
      toast.error("Duration must be at least 5 minutes");
      return;
    }

    setLoading(true);
    if (submitBtnRef) {
      submitBtnRef.current.setAttribute("disabled", "true");
      closeBtnRef.current.setAttribute("disabled", "true");
      dispatch(editService(editedService as Service) as unknown as AnyAction)
        .unwrap()
        .then(() => {
          toast.success("Service edited successfully!");
        })
        .catch((err: any) => {
          toast.error(
            "An error occured during edition, please try again later"
          );
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            if (submitBtnRef.current && closeBtnRef.current) {
              submitBtnRef.current.removeAttribute("disabled");
              closeBtnRef.current.removeAttribute("disabled");
            }
          }, 2000);
          handleClose();
          setCurrentService(null);
        });
    }
  };

  return (
    <div className=" align-items-center">
      <div className=" d-flex mb-2 text-black fw-bold">
        <span>Service name : </span>{" "}
        <span className="text-primary">{service.service_detail.name}</span>
      </div>
      <div className="d-flex gap-2 flex-wrap">
        <label className="d-flex flex-column ">
          <span>name : </span>
          <input
            autoComplete="off"
            type="text"
            name="name"
            id="name"
            placeholder="name"
            className="rounded border p-2"
            value={editedService?.name}
            onChange={handleChange}
          />
        </label>
        <label className="d-flex flex-column ">
          <span>price:</span>
          <input
            autoComplete="off"
            type="number"
            name="price"
            id="price"
            step={5}
            min={0}
            placeholder="Enter a new price"
            className="rounded border p-2"
            value={editedService.price}
            onChange={handleChange}
          />
        </label>
        <label className="d-flex flex-column ">
          <span>size :</span>
          <SelectSize
            onChange={(sizes: string[]) => {
              setEditedService({
                ...editedService,
                size: sizes,
              });
            }}
            onBlur={() => ""}
            name={"size"}
            selectedSizes={editedService?.size as string[]}
          />
        </label>
        <label className="d-flex flex-column ">
          <span>duration :</span>
          <input
            autoComplete="off"
            className="rounded border edit-input p-2 "
            type="number"
            name="duration"
            id="duration"
            min={5}
            placeholder="duration"
            value={editedService.duration}
            onChange={handleChange}
          />
        </label>
        <label className="d-flex flex-column ">
          <span>description :</span>
          <textarea
            className="rounded border edit-input p-2 "
            name="description"
            id="description"
            placeholder="description"
            value={editedService?.description}
            onChange={handleChange}
          />
        </label>
        <div className=" d-flex gap-2 ">
          <button
            ref={submitBtnRef}
            onClick={handleSave}
            className="border-0 bg-transparent align-self-center"
            disabled={service === editedService}
          >
            <i className="fa-solid fa-check text-primary fs-5"></i>
          </button>

          <button
            ref={closeBtnRef}
            onClick={handleClose}
            className="border-0 bg-transparent align-self-center"
          >
            <i className="fa-solid fa-xmark fs-5"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditServiceForm;
