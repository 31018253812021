import React from 'react';

interface Step {
    title: string;
}

interface StepIndicatorProps<T> {
    activeStep: number;
    steps: (T & Step)[];
}

const StepIndicator: React.FC<StepIndicatorProps<object>> = ({ activeStep, steps }) => {
    return (
        <div className='d-flex flex-column h-100 justify-content-start'>
            {steps.map((step, key, arr) => (

                <div
                    key={step.title}
                    className="d-flex flex-column align-items-center"
                >
                    <div
                        style={{
                            width: "3px",
                            height: "59px",
                            backgroundColor:
                                activeStep === key
                                    ? "var(--primaryColor)"
                                    : "var(--borderColor)",
                        }}
                    ></div>
                    <div
                        style={{
                            width: "15px",
                            height: "15px",
                            borderRadius: "100%",
                            background:
                                activeStep === key
                                    ? "var(--primaryColor)"
                                    : activeStep > key
                                        ? "var(--yellowColor)"
                                        : "var(--whiteColor)",
                        }}
                        className={
                            activeStep === key || activeStep > key ? "" : "border"
                        }
                    ></div>
                    {key === arr.length - 1 && (
                        <div
                            style={{
                                width: "3px",
                                height: "90px",
                                background: "var(--borderColor)",
                            }}
                        ></div>
                    )}
                </div>

            ))}
        </div>
    );
};

export default StepIndicator;