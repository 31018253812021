import "../styles/ClientProfilePage.css";
import { HTMLAttributes, useEffect, useState } from "react";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import MainNav from "../components/navigation/MainNav";
import { useSalon } from "../context/SalonContext";
import { ProfessionalService } from "../services/ProfessionalService";
import "../styles/tip-page.css";
// import "../styles/ClientProfilePage.css";

import { ISalonProfile } from "./SeeProfessionalAccountPage";
import { ISalon } from "../models/SalonX";
import { ISearchData } from "../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChair } from "@fortawesome/free-solid-svg-icons";
import SwitchSalonItem from "../components/reusable/SwitchSalonItem";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { formatUSPrice } from "../utils/utils";
import { StripeElementChangeEvent, loadStripe } from "@stripe/stripe-js";
import toast from "react-hot-toast";
import { AnyAction } from "@reduxjs/toolkit";
import { sendTipsFunds } from "../redux/slices/accountSlice";
import { useDispatch } from "react-redux";
import DefaultLoader from "../components/loader/DefaultLoader";

export interface TransfertAccountModalOptions
  extends HTMLAttributes<HTMLDivElement> {
  showTransfert?: boolean;
}

const TipSettingsPage: React.FC<TransfertAccountModalOptions> = ({
  showTransfert = false,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState(0);
  const [isCardValid, setIsCardValid] = useState(false);

  const [q, setQ] = useState("");
  const [selectedSalon, setSelectedSalon] = useState<ISalon | null>(null);

  const { salon, setSalon } = useSalon();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [allProfessionalData, setAllProfessionalData] =
    useState<ISalonProfile | null>(null);
  const [professionalsData, setProfessionalsData] = useState<ISearchData>({
    size: 8,
    page: 1,
    totalPages: null,
    results: null,
  });

  const getAllProfessionalData = () => {
    ProfessionalService.getProfessionals(
      professionalsData.page,
      professionalsData.size
    )
      .then((res) => {
        if (professionalsData.results) {
          const results = [
            ...professionalsData.results.slice(),
            ...res.results,
          ];
          console.log("salon", results);
          setProfessionalsData({
            ...professionalsData,
            results,
            page: professionalsData.page + 1,
            totalPages: res.totalPages,
          });
        } else {
          setProfessionalsData({
            ...professionalsData,
            results: res.results,
            page: professionalsData.page + 1,
            totalPages: res.totalPages,
          });
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };

  useEffect(() => {
    // console.log("The service for user" , userServices )
    // console.log("The salon" , salon )
    // console.log("The currentUser", currentUser);
    getAllProfessionalData();
  }, []);

  const filteredSalons = professionalsData.results?.filter((salon: ISalon) => {
    if (q.length > 0) {
      return salon.name
        ?.toLocaleLowerCase()
        .includes(q.trim().toLocaleLowerCase());
    }
    return true;
  });

  const handleData = (data: ISalon) => {
    console.log("salon data i tipsSetting", data);
    setSelectedSalon(data);
  };

  console.log("professionalsData", professionalsData);

  const handleAmountChange = (e: any) => {
    setAmount(e.target.value);
  };

  const handleCardChange = (event: StripeElementChangeEvent) => {
    setIsCardValid(event.complete);
  };

  const handleStripePayement = async (e: any, stripe: any, elements: any) => {
    e.preventDefault();
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    const { token, errors } = await stripe.createToken(cardElement);

    if (error) {
      toast.error("Error: " + error.message);
    } else {
      const data = {
        paymentMethod,
        amount,
        token,
        selectedSalon,
      };
      toast.remove();
      dispatch(sendTipsFunds(data) as unknown as AnyAction)
        .unwrap()
        .then(() => {
          toast.success(
            `${formatUSPrice(
              amount
            )} has been transferred with success !`,
            { duration: 15000 }
          );
        })
        .catch((error: any) => {
          console.log(error);
          toast.error("Error: unable to perform the transaction !", {
            duration: 10000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleSendFunds = () => {
    setLoading(true);
  };

  return (
    <div >
      <MainNav navType={"connectedNav"} isSearch={true} />
      <div
        // style={{
        //   minHeight: "800px",
        // }}
        className="tip__container"
      
      >
        {(loading || stripe === null) && (
          <DefaultLoader title="Please wait..." />
        )}

        <div
          className=" tip__container__block1 "
        
        >

          <div
            className=" tips__container__left__1 "
             style={{
          minHeight: "200px",
        }}
          >
            1
          </div>

          <div className="tips__container__left__2" style={{ height: "50%", width: "100%" }}>
            <div className="custom-card-element d-flex flex-column align-items-center p-2 gap-3">
              <div className="d-flex w-100 p-2"></div>
              <form
                className="w-100 mx-5"
                onSubmit={(event) =>
                  handleStripePayement(event, stripe, elements)
                }
              >
                <div className="w-100 pt-3 gap-3 d-flex flex-column">
                  <div>
                    <label htmlFor="amount" className="form-label">
                      Amount to add
                    </label>
                    <input
                      id="amount"
                      min={10}
                      max={1000}
                      step={5}
                      type="number"
                      className="border rounded p-2 px-3 small-text w-100"
                      placeholder="Enter amount"
                      onChange={handleAmountChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="card" className="form-label">
                      Card details
                    </label>
                    <CardElement id="card" onChange={handleCardChange} />
                  </div>
                </div>
                <div className="p-2 d-flex justify-content-end w-100">
                  <button
                    onClick={handleSendFunds}
                    disabled={!isCardValid || amount === 0 || !selectedSalon}
                    className="pay-btn rounded p-2 mt-3 small-text"
                  >
                    Send {formatUSPrice(amount)}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="tip__container__block2 "
          style={{
          
          }}
        >
          <div></div>
          <div>
            <div>
              <h1
                className=""
                style={{ fontFamily: "fantasy", fontSize: "18px" }}
              >
                Select the salon
              </h1>
              <p
                className=""
                style={{
                  fontFamily: "cursive",
                  fontWeight: "lighter",
                  fontSize: "18px",
                }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
                quia nostrum voluptate dolor quas perferendis et. Pariatur
                reprehenderit commodi asperiores modi voluptatibus, corporis
                quod cumque iste a eveniet repellendus maxime delectus vel
                expedita repellat! Delectus facere sed, atque rem dolores
                deserunt nostrum animi nisi quibusdam.
              </p>
            </div>
            <div>
              <div
                className="input-group d-flex m-0 mb-4 p-0 w-100 border mb-2 align-items-center"
                aria-autocomplete="none"
              >
                <button
                  style={{ backgroundColor: "white", height: "45px" }}
                  className="btn border-0"
                  type="button"
                >
                  <i className="fa-solid fa-search"></i>
                </button>
                <input
                  value={q ?? ""}
                  onChange={(e) => setQ(e.target.value)}
                  placeholder="Search your salon..."
                  type="text"
                  className="form-control border-0  no-decoration fw-500"
                />
              </div>

              <div className="switch-salon-items-container mt-5 d-flex flex-column gap-3">
                {filteredSalons?.map((salon: ISalon) => {
                  return (
                    <SwitchSalonItem
                      key={salon._id}
                      salon={salon}
                      selected={selectedSalon?._id === salon._id}
                      onClick={handleData}
                    />
                  );
                })}
                {filteredSalons?.length === 0 && (
                  <div
                    style={{ userSelect: "none" }}
                    className="d-flex flex-column justify-content-center align-items-center gap-4"
                  >
                    <FontAwesomeIcon
                      style={{ color: "var(--textPrimaryColor)" }}
                      icon={faChair}
                      size="5x"
                      className="text-muted"
                    />
                    <div className="text-muted">No salon found !</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const SendTips: React.FC<TransfertAccountModalOptions> = (props) => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
  );

  return (
    <Elements stripe={stripePromise}>
      <TipSettingsPage {...props} showTransfert />
    </Elements>
  );
};

export default SendTips;
