import MainNav from "../components/navigation/MainNav";
import "./../styles/LoginPage.css";
import { NavLink } from "react-router-dom";
import { LegacyRef, useRef, useState } from "react";
import { useFormik } from "formik";
import { validationSchema } from "../components/validation/LoginValidation";
import { log } from "../utils/utils";
import User from "../models/User";
import { useDispatch } from "react-redux";
import { passwordReset } from "../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import ImageSlider from "../components/reusable/imageSlider/ImageSlider";
import { slides } from "../components/reusable/imageSlider/Slides";

type Props = {};

const ForgotPasswordPage = (props: Props) => {
  const dispatch = useDispatch();
  const submitBtnRef = useRef<HTMLButtonElement>();
  const [isALert, showALert] = useState(false);

  const baseURL = window.location.origin;
  const resetURL = baseURL + "/reset-password";

  const formik = useFormik({
    initialValues: {
      email: "",
      reset_url: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      log(JSON.stringify(values, null, 2));
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (submitBtnRef.current) {
      const data: Pick<User, 'email'> & { reset_url: string } = {
        email: formik.values.email,
        reset_url: resetURL,
      };
      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = "processing...";

      dispatch(passwordReset(data) as unknown as AnyAction).then(() => {
        setTimeout(() => {
          if (submitBtnRef.current) {
            submitBtnRef.current.removeAttribute("disabled");
            submitBtnRef.current.innerHTML = "Recover my account";
          }
          showALert(true);
        }, 2000);
        showALert(false);
      });
    }
  };
  const formSubmit = (e: any) => {
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0) {
      handleSubmit(e);
    }
  };

  return (
    <>
      <MainNav navType={"homeNav"} />
      <div className="d-flex p-0 m-0 Login mx-0">
        <div className="position-relative loginLeftSide">
          <ImageSlider slides={slides} />
        </div>
        <div className="mx-auto p-0 m-0 col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 loginRightSide align-items-start align-items-lg-center mt-5 mt-lg-0">
          <div className="container p-lg-0 my-3">
            <div className="d-flex flex-column justify-content-around">
              <div className="row d-flex align-items-center mx-0 mx-lg-5">
                <div className="col-12 col-lg-10 col-xl-6">
                  <div
                    className={
                      "alert alert-success  gap-3 justify-content-center align-items-center" +
                      (isALert ? " d-flex" : " d-none")
                    }
                    role="alert"
                  >
                    <i className="fa-regular fa-circle-check"></i>
                    <div>
                      Please check your email for a link to create a new
                      password. If you do not receive the email within a few
                      minutes, check your spam or junk folder.
                    </div>
                  </div>
                  <h6 className="mt-3">
                    Please enter your email to recover your account
                  </h6>

                  <div>
                    {formik.errors.message ? (
                      <div className="alert alert-danger d-flex gap-2 align-items-center">
                        <i className="fa fa-info-circle"></i>
                        <div>{formik.errors.message}</div>
                      </div>
                    ) : null}
                    <form onSubmit={handleSubmit}>
                      <input
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email ?? ""}
                        required
                        type="email"
                        className="form-control fw-500 mb-3"
                        placeholder="Email"
                        id="email"
                        name="email"
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div style={{ color: "var(--primaryColor)" }}>
                          {formik.errors.email}
                        </div>
                      ) : null}
                      <div className="d-flex">
                        <button
                          ref={submitBtnRef as LegacyRef<HTMLButtonElement>}
                          className="btn btn-primary px-4 w-100"
                          type="submit"
                          onClick={formSubmit}
                        >
                          recover my account
                        </button>
                      </div>
                    </form>
                    <div className="small mt-5">
                      Protected by reCAPTCHA and subject to the{" "}
                      <NavLink
                        className="nav-link nav-link-underline-hover active nav-link-inline"
                        to={"/forgot-password"}
                      >
                        Latrice privacy policy{" "}
                      </NavLink>
                      and{" "}
                      <NavLink
                        className="nav-link nav-link-inline nav-link-underline-hover active"
                        to={"/forgot-password"}
                      >
                        Terms of service.
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
