import React, { useEffect } from "react";
import "./../styles/SearchResult.css";
import { LikeButton } from "./button/LikeButton";
import { ProfileImage } from "./ProfileImage";
import { Stars } from "./Stars";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { JSX } from "react/jsx-runtime";
import LazyLoadedImage from "./image/LazyLoadedImage";
import { useNavigate } from "react-router-dom";
import { ISearchResult } from "../utils/types";
import { formatUSPrice } from "../utils/utils";
import slugify from "slugify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRoad } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { updateUser } from "../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

export interface SearchResultOptions {
  salon: ISearchResult;
}

const SearchResult: React.FC<SearchResultOptions> = ({ salon }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const NextArrow = (
    props: JSX.IntrinsicAttributes &
      React.ClassAttributes<HTMLButtonElement> &
      React.ButtonHTMLAttributes<HTMLButtonElement>
  ) => {
    return (
      <button
        type="button"
        data-role="none"
        className="slick-arrow slick-next"
        {...props}
      />
    );
  };

  const PrevArrow = ({
    ...props
  }: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLButtonElement> &
    React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
      <button
        type="button"
        data-role="none"
        className="slick-arrow slick-prev"
        {...props}
      />
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const defaultImage = "https://via.placeholder.com/200x200";
  const cardImages = salon.portfolio.slice(4);
  cardImages.push(...Array(4 - cardImages.length).fill(defaultImage));

  const slug = slugify(salon.name?.split(" ").join("-") + "-" + salon._id, {
    lower: true,
  });

  const HandleFavoriesItem = () => {
    const formData = new FormData();
    formData.append("favorites", salon._id);

    dispatch(updateUser(formData) as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        const favorites: any[] = res.updatedUser.favorites;
        const favoriteStatus = res.favoriteStatus;
        console.log("favories content", favorites);
        console.log("favories status", favoriteStatus);
        const test = favorites?.filter((item) => item === salon._id);
        if (favoriteStatus) {
          toast.success("Your already added to your favories");
        } else {
          toast.success("Added to your favories");
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    console.log("salon informaton", salon);
  }, []);

  return (
    <div className="SearchResult d-flex gap-1 align-items-center justify-content-between rounded bg-white shadow-sm p-2">
      <div className="SearchResult_container d-flex justify-content-between align-items-center gap-2 w-100">
        <div className="slideImages_container  align-items-center">
          <Slider {...settings} className="customSlider">
            {salon.portfolio.map((image) => {
              return (
                <div className="customSlider__container" key={image}>
                  <LazyLoadedImage
                    style={{ height: "300px" }}
                    className="mx-auto"
                    src={image ?? require("../assets/images/profile.jpg")}
                  />
                </div>
              );
            })}
          </Slider>
        </div>

        <div className="d-flex flex-column w-100">
          <div className="d-flex justify-content-between align-items-center gap-3">
            <div className="d-flex gap-2 align-items-center">
              <ProfileImage
                image={
                  salon?.profile ?? require("../assets/images/profile.jpg")
                }
              />
              <div className="d-flex flex-column justify-items-center">
                <div className="d-flex gap-2">
                  <div className="d-flex align-items-center gap-3">
                    <strong className="text-black">{salon?.name}</strong>
                  </div>
                  <Stars
                    canUpdate={false}
                    stars={salon?.reviewsStats.averageStars ?? 0}
                    notice={salon?.reviewsStats.reviewCount ?? 0}
                  />
                </div>
                <div className="d-flex gap-2">
                  <span className="text-muted small">
                    <i className="fa-solid fa-location-dot"></i>{" "}
                    {salon?.address ?? "No location available"}
                  </span>
                  <small className="text-muted">
                    <FontAwesomeIcon icon={faRoad} />
                    <span> {Number(salon.distance).toFixed(2)}</span> mi
                  </small>
                </div>
              </div>
            </div>
            <LikeButton handleFavories={HandleFavoriesItem} />
          </div>
          <hr className="col-10 mx-auto" />
          <div className="servicesList d-flex justify-content-start gap-1">
            <div
              className={"servicesList__all"}
              onClick={() => navigate(`/see-professional-account/${slug}`)}
            >
              See All services
            </div>
            <div
              className={
                "btn btn-primary" +
                (salon.services && salon.services.length === 0
                  ? " "
                  : " d-none")
              }
            >
              No services available
            </div>
            {salon.services &&
              salon.services.map((service) => {
                return (
                  <div
                    key={service._id}
                    className="servicesList__item d-flex gap-1 justify-content-between"
                  >
                    <span>{service?.service_detail.name}</span>
                    <span>.</span>
                    <span>{service?.duration}</span>
                    <span>Mins</span>
                    <span>.</span>
                    <span> {formatUSPrice(service?.price)}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export { SearchResult };
