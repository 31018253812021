import React from "react";
import ProfileInfo from "../../reusable/ProfileInfo";
import ServiceList from "../../seeProAccount/ServiceList";
import { IService } from "../../../models/Service";import { IBookMakable } from '../../BookmarkButton'
import { ISalonProfile } from "../../../pages/SeeProfessionalAccountPage";
import { ISalon } from "../../../models/SalonX";


interface IServiceSelectorProps extends IBookMakable {
  onSelect: (service: IService) => void;
  onBack: () => void;
  salon: ISalonProfile;
}

export const ServiceSelector: React.FC<IServiceSelectorProps> = ({
  onBack,
  onSelect,
  salon, ...props
}) => {
  const [q, setQ] = React.useState<string | null>(null);
  const filteredServices: IService[] = (salon.services ?? []).filter(
    (service: IService) => {
      return (service.service_detail?.name ?? "")
        .toLocaleLowerCase()
        .includes((q ?? "").toLocaleLowerCase());
    }
  );

  
  return (
    <div className="container">
      <div>
        <i
          onClick={(e) => {
            onBack();
          }}
          className="backButton fa fa-long-arrow-left"
        ></i>
      </div>
      <div className="d-flex align-items-center flex-column m-0 p-0 w-100 professional_services">
        <div className="w-100 mb-4">
          
                    <ProfileInfo
                        salon={salon as ISalon}
                        onBookmark={props.onBookmark}
                        bookMarked={props.bookMarked}
                    />
        
                </div>
        <div
          className="input-group d-flex m-0 mb-4 p-0 w-100 border mb-2 align-items-center"
          aria-autocomplete="none"
        >
          <button
            style={{ backgroundColor: "white", height: "45px" }}
            className="btn border-0"
            type="button"
          >
            <i className="fa-solid fa-search"></i>
          </button>
          <input
            value={q ?? ""}
            onChange={(e) => setQ(e.target.value)}
            placeholder="Search for service..."
            type="text"
            className="form-control border-0  no-decoration fw-500"
          />
        </div>
      </div>
      <div className="d-flex flex-column mt-2">
        <ServiceList services={filteredServices ?? []} onSelect={onSelect} />
      </div>
    </div>
  );
};
