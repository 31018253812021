import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { IProject } from "../../models/Project";
import {
  calculateDistance,
  formatUSPrice,
  timeToTimezone,
  toProjectLabel,
} from "../../utils/utils";
import moment from "moment";
import { useSalon } from "../../context/SalonContext";
import "./../styles/ProjectCard.css";
import { isUserProfessional } from "../../models/User";
import { useUser } from "../../hooks/UseUser";
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";
import EditProjectModal from "../modal/EditProjectModal";

import JustMapLocation from "../map/JustMapLocation";

type ProjectCardModeProps =
  | {
      mode: "pending";
      type: "professional";
    }
  | {
      mode: "accepted";
      type: "professional";
    }
  | {
      mode: "pending";
      type: "regular";
    };

type ProjectCardProps = {
  project: IProject;
  onAccept?: (project: IProject) => void;
  onDelete?: (project: IProject) => void;
  onCancel?: (project: IProject) => void;
} & ProjectCardModeProps;

const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  mode,
  type,

  ...props
}) => {
  const [markerPosition, setMarkerPosition] = useState<any>(null);
  const [markerCircle, setMarkerCircle] = useState<any>(null);
  const [markerInfo, setMarkerInfo] = useState<any>(null);
  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const [position, setPosition] = useState<typeof Location | null>(null);

  const updateMapLoadedElements = (map: any, maps: any, center: any) => {
    if (markerPosition) {
      markerPosition.setPosition(center);
    }
    if (markerCircle) {
      markerCircle.setRadius(1 * 1609.34);
      markerCircle.setCenter(center);
    }
  };

  const user = useUser();
  const userIsProfessional = isUserProfessional(user);

  const salon = useSalon();
  const [showDelete, setShowDelete] = useState(false);
  const [showCancel, setShowCancel] = useState(false);

  const [distance, setDistance] = useState(0);
  const [seeDetails, setSeeDetails] = useState<boolean>(false);
  const [editForm, setShowEditForm] = useState(false);

  const [myProduct, setMyProduct] = useState<IProject>({
    ...project,
  });

  useEffect(() => {
    setMyProduct(project);
  }, []);

  const handleAccept = () => {
    if (props.onAccept) {
      props.onAccept(myProduct);
    }
  };

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel(myProduct);
    }
  };

  const handleDelete = () => {
    if (props.onDelete) {
      props.onDelete(myProduct);
    }
  };

  const handleOpenDetailPage = () => {
    console.log("test click on hangleOpenDetailPage");
    setSeeDetails(true);
  };

  useEffect(() => {
    if (salon.salon && myProduct.drive_to_your_place) {
      setDistance(
        calculateDistance(
          salon.salon.location.coordinates[1],
          salon.salon.location.coordinates[0],
          (myProduct as any).location.coordinates[1],
          (myProduct as any).location.coordinates[0]
        ) * 0.621371
      );
    }
  }, []);
  console.log("projects data", project);

  const HandleUpdateProject = (data: IProject) => {
    console.log("voici les nouvelles donnees du project", data);
    setMyProduct(data);
  };

  const myLocation = {
    center: {
      lat: myProduct.location.coordinates[0],
      lng: myProduct.location.coordinates[1],
    },
    zoom: 9,
  };

  const handleApiLoaded = (gMap: any, gMaps: any) => {
    // put marker in current position
    if (!map) setMap(gMap);
    if (!maps) setMaps(gMaps);

    if (position && gMap && gMaps) {
      const center = { lat: myLocation.center.lat, lng: myLocation.center.lng };

      if (markerPosition) {
        return updateMapLoadedElements(gMap, gMaps, center);
      }

      const infoWindow = new gMaps.InfoWindow({
        content: "Your position",
        ariaLabel: "Position",
        disableAutoPan: true,
      });

      setMarkerInfo(infoWindow);

      let marker = new gMaps.Marker({
        position: center,
        map: gMap,
        title: "Your position",
      });

      infoWindow.open({
        anchor: marker,
        map: gMap,
      });

      marker.addListener("click", () => {
        infoWindow.open({
          anchor: marker,
          map: gMap,
        });
      });

      setMarkerPosition(marker);

      if (markerPosition) {
        markerPosition.setPosition({
          lat: myLocation.center.lat,
          lng: myLocation.center.lat,
        });
      }

      const cityCircle = new gMaps.Circle({
        strokeColor: "#e10984",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#e10984",
        fillOpacity: 0.35,
        map: gMap,
        center: center,
        radius: 1,
        // editable: true,
      });

      setMarkerCircle(cityCircle);

      gMap.panTo(marker.getPosition());

      const circleBounds = cityCircle.getBounds();

      gMap.fitBounds(circleBounds);
      gMap.setZoom(13);
    }
  };

  return (
    <div>
      {!seeDetails && (
        <div
          style={{ position: "relative" }}
          className={`project-card  w-100 rounded p-2  ${
            myProduct.UserType == "business_owner"
              ? "business-card"
              : myProduct.UserType == "independant"
              ? "independant-card"
              : "regular-card"
          }`}
        >
          {(myProduct.UserType == "independant" ||
            myProduct.UserType == "business_owner") && (
            <div
              className=" mb-2 mt-2 pb-2 pt-2 "
              style={{
                backgroundColor: "#cac0c021",
                border: "2px solid #beb6b636",
                borderRadius: "5px",
                maxWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                position: "absolute",
                right: "20px",
                top: "0",
              }}
            >
              <span
                className="text-black"
                style={{
                  fontSize: "14px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              >
                Hiring a pro
              </span>
            </div>
          )}

          {myProduct.UserType == "independant" && type !== "regular" && (
            <div
              className=" mb-2 mt-2 pb-2 pt-2 "
              style={{
                backgroundColor: "#cac0c021",
                border: "2px solid #beb6b636",
                borderRadius: "5px",
                maxWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                className="text-black"
                style={{ fontSize: "14px", marginRight: "10px" }}
              >
                help a stylist{" "}
              </span>
              <FontAwesomeIcon icon={faCar} style={{ fontSize: "12px" }} />
            </div>
          )}
          {myProduct.UserType == "business_owner" && (
            <div
              className=" mb-2 mt-2 pb-2 pt-2 "
              style={{
                backgroundColor: "#cac0c021",
                border: "2px solid #beb6b636",
                borderRadius: "5px",
                maxWidth: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                className="text-black"
                style={{ fontSize: "14px", marginRight: "10px" }}
              >
                help a salon
              </span>
              <FontAwesomeIcon icon={faCar} style={{ fontSize: "12px" }} />
            </div>
          )}

          <div className="d-flex justify-content-between gap-3">
            {myProduct.image && (
              <div className="d-flex align-items-center">
                <img
                  className=""
                  style={{ width: "120px", height: "120px" }}
                  src={myProduct.image as string}
                  alt=""
                />
              </div>
            )}
            <div className="flex-fill">
              <div className="d-flex flex-column h-100 justify-content-between">
                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="text-black fw-bold">
                      {toProjectLabel(myProduct)}
                    </h5>
                    <div
                      style={{ padding: ".3em" }}
                      className="text-small text-black rounded text-black text-black d-flex align-items-center justify-content-between gap-1"
                    >
                      <span>
                        {moment(myProduct.day).format("ddd DD/MM/YY")}
                      </span>
                      <span>-</span>
                      <span>{timeToTimezone(myProduct.startTime)}</span>
                    </div>
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="d-flex gap-2 align-items-center">
                      {myProduct.professional_type === "both" ? (
                        <>
                          <small className="border bg-white rounded p-1">
                            salon
                          </small>
                          <small className="border rounded bg-white p-1">
                            independent
                          </small>
                        </>
                      ) : (
                        <>
                          {" "}
                          <small className="border rounded p-1">
                            {myProduct.professional_type}
                          </small>
                        </>
                      )}
                    </div>
                    <div className="mb-1">.</div>
                    <small className="text-black fw-bold text-leading-2">
                      {formatUSPrice(myProduct.price)}
                    </small>
                    <small className="text-black fw-bold text-leading-2">
                      {myProduct.help_percentage} help percentage
                    </small>
                    <div className="ms-auto">
                      {type === "professional" && mode === "pending" && (
                        <button
                          onClick={handleAccept}
                          style={{ fontWeight: "500" }}
                          className="text-primary btn_outlined fw-400 rounded py-1 px-2"
                        >
                          Accept
                        </button>
                      )}
                      {mode === "accepted" && (
                        <button
                          onClick={() => setShowCancel(true)}
                          style={{ fontWeight: "500" }}
                          className="bg-white fw-400 rounded py-1 px-2"
                        >
                          Cancel
                        </button>
                      )}
                      {type === "regular" && mode === "pending" && (
                        <button
                          onClick={() => setShowDelete(true)}
                          className="border pending-btn"
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </button>
                      )}
                    </div>
                    <div>
                      {type === "regular" && mode === "pending" && (
                        <button
                          onClick={() => setShowEditForm(true)}
                          className="border pending-btn"
                        >
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                      )}
                    </div>

                    {showCancel && (
                      <div
                        style={{ right: "36px" }}
                        className="position-absolute bg-white border shadow rounded p-2 d-flex flex-column gap-1"
                      >
                        <span>Do you really want to cancel this project ?</span>{" "}
                        <div className="justify-content-center d-flex gap-2 col-12">
                          <button
                            onClick={handleCancel}
                            className="btn-secondary border rounded col-3 "
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => setShowCancel(false)}
                            className="btn_outlined border rounded col-3 "
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}

                    {showDelete && (
                      <div
                        style={{ right: "36px" }}
                        className="position-absolute bg-white border shadow rounded p-2 d-flex flex-column gap-1"
                      >
                        <span>Do you really want to delete this project ?</span>{" "}
                        <div className="justify-content-center d-flex gap-2 col-12">
                          <button
                            onClick={handleDelete}
                            className="btn-secondary border rounded col-3 "
                          >
                            Delete
                          </button>
                          <button
                            onClick={() => setShowDelete(false)}
                            className="btn_outlined border rounded col-3 "
                          >
                            cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className="my-2"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                    }}
                  >
                    {myProduct.description}
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div>{moment(project.createdAt).fromNow()}</div>
                  {project.drive_to_your_place && (
                    <>
                      <div className="text-muted">|</div>
                      <div className="d-flex gap-1 align-items-center">
                        <FontAwesomeIcon icon={faCar} />
                        <span className="text-small font-monospace">
                          {distance.toFixed(2)} miles
                        </span>
                        <span className="text-small font-monospace">
                          for {formatUSPrice(distance * 5)}
                        </span>
                      </div>
                    </>
                  )}
                  {project.is_special_event && (
                    <>
                      <div className="text-muted">|</div>
                      <div className="d-flex gap-1 align-items-center">
                        <FontAwesomeIcon icon={faCircleCheck} />
                        <span className="text-small font-monospace">
                          extra event
                        </span>
                        <span className="text-small font-monospace">
                          for {formatUSPrice(project.event_extra_fee as number)}
                        </span>
                      </div>
                    </>
                  )}
                  <div>
                    {" "}
                    <span
                      className="text-primary hover-underline deleteAccountBtn"
                      onClick={handleOpenDetailPage}
                    >
                      Detail page
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {seeDetails && (
        <div style={{ width: "100%", height: "100%", padding: "8px" }}>
          <div className="d-flex gap-2 align-items-center mb-2">
            <FontAwesomeIcon
              onClick={() => setSeeDetails(false)}
              className="cursor-pointer"
              icon={faLongArrowLeft}
            />
            {/* <span className="fs-5">Add services </span> */}
          </div>
          <div className="d-flex justify-content-between gap-3">
            {myProduct.image && (
              <div className="d-flex align-items-center">
                <img
                  className=""
                  style={{ width: "120px", height: "120px" }}
                  src={myProduct.image as string}
                  alt=""
                />
              </div>
            )}
            <div className="flex-fill">
              <div className="d-flex flex-column h-100 justify-content-between">
                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="text-black fw-bold">
                      {toProjectLabel(myProduct)}
                    </h5>
                    <div
                      style={{ padding: ".3em" }}
                      className="text-small text-black rounded text-black text-black d-flex align-items-center justify-content-between gap-1"
                    >
                      <span>{moment(project.day).format("ddd DD/MM/YY")}</span>
                      <span>-</span>
                      <span>{timeToTimezone(myProduct.startTime)}</span>
                    </div>
                  </div>

                  <div className="d-flex gap-2 align-items-center">
                    <div className="d-flex gap-2 align-items-center">
                      {myProduct.professional_type === "both" ? (
                        <>
                          <small className="border bg-white rounded p-1">
                            salon
                          </small>
                          <small className="border rounded bg-white p-1">
                            independent
                          </small>
                        </>
                      ) : (
                        <>
                          {" "}
                          <small className="border rounded p-1">
                            {myProduct.professional_type}
                          </small>
                        </>
                      )}
                    </div>
                    <div className="mb-1">.</div>
                    <small className="text-black fw-bold text-leading-2">
                      {formatUSPrice(myProduct.price)}
                    </small>
                    <div className="ms-auto">
                      {type === "professional" && mode === "pending" && (
                        <button
                          onClick={handleAccept}
                          style={{ fontWeight: "500" }}
                          className="text-primary btn_outlined fw-400 rounded py-1 px-2"
                        >
                          Accept
                        </button>
                      )}
                      {mode === "accepted" && (
                        <button
                          onClick={() => setShowCancel(true)}
                          style={{ fontWeight: "500" }}
                          className="bg-white fw-400 rounded py-1 px-2"
                        >
                          Cancel
                        </button>
                      )}
                      {type === "regular" && mode === "pending" && (
                        <button
                          onClick={() => setShowDelete(true)}
                          className="border pending-btn"
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </button>
                      )}
                    </div>

                    {showCancel && (
                      <div
                        style={{ right: "36px" }}
                        className="position-absolute bg-white border shadow rounded p-2 d-flex flex-column gap-1"
                      >
                        <span>Do you really want to cancel this project ?</span>{" "}
                        <div className="justify-content-center d-flex gap-2 col-12">
                          <button
                            onClick={handleCancel}
                            className="btn-secondary border rounded col-3 "
                          >
                            Yes
                          </button>
                          <button
                            onClick={() => setShowCancel(false)}
                            className="btn_outlined border rounded col-3 "
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}

                    {showDelete && (
                      <div
                        style={{ right: "36px" }}
                        className="position-absolute bg-white border shadow rounded p-2 d-flex flex-column gap-1"
                      >
                        <span>Do you really want to delete this project ?</span>{" "}
                        <div className="justify-content-center d-flex gap-2 col-12">
                          <button
                            onClick={handleDelete}
                            className="btn-secondary border rounded col-3 "
                          >
                            Delete
                          </button>
                          <button
                            onClick={() => setShowDelete(false)}
                            className="btn_outlined border rounded col-3 "
                          >
                            cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className="my-2"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                    }}
                  >
                    {myProduct.description}
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div>{moment(project.createdAt).fromNow()}</div>
                  {project.drive_to_your_place && (
                    <>
                      <div className="text-muted">|</div>
                      <div className="d-flex gap-1 align-items-center">
                        <FontAwesomeIcon icon={faCar} />
                        <span className="text-small font-monospace">
                          {distance.toFixed(2)} miles
                        </span>
                        <span className="text-small font-monospace">
                          for {formatUSPrice(distance * 5)}
                        </span>
                      </div>
                    </>
                  )}
                  {project.is_special_event && (
                    <>
                      <div className="text-muted">|</div>
                      <div className="d-flex gap-1 align-items-center">
                        <FontAwesomeIcon icon={faCircleCheck} />
                        <span className="text-small font-monospace">
                          extra event
                        </span>
                        <span className="text-small font-monospace">
                          for {formatUSPrice(project.event_extra_fee as number)}
                        </span>
                      </div>
                    </>
                  )}
                  <div>
                    {" "}
                    <span
                      className="text-primary hover-underline deleteAccountBtn"
                      onClick={handleOpenDetailPage}
                    >
                      Detail page
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" d-flex flex-column gap-3 mt-4">
            {myProduct.services.map((service, key) => {
              return (
                <div
                  key={key}
                  className="d-flex position-relative cursor-pointer align-items-center border border-black justify-content-between rounded p-3"
                >
                  <div className="d-flex flex-column justify-content-between">
                    <h5 className="text-black">{service.name}</h5>
                    <div className="text-muted">{service.size.join()}</div>
                  </div>
                  <div className="text-black">
                    {formatUSPrice(service.price)}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-2" style={{ height: "30vh", width: "100%" }}>
            <JustMapLocation
              showRadius={false}
              showSearch={false}
              containerStyle={{ height: "30vh" }}
              onLocationSelect={(latLng, address) => {
                console.log("console.log()");
              }}
              radius={"1"}
              defaultAddress={myProduct.address}
              defaultLocation={myLocation}
            />
          </div>
        </div>
      )}

      {editForm && (
        <EditProjectModal
          backdrop={true}
          onClose={() => setShowEditForm(false)}
          project={project}
          updateProduct={HandleUpdateProject}
        />
      )}
    </div>
  );
};

export default ProjectCard;
