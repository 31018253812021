import React from 'react'

export const ModalLoader = ({ label = "" }: { label?: any }) => {
    return (
        <div style={{ backdropFilter: 'blur(1px)' }} className="BookNowModal__container__loader user-select-none d-flex flex-column justify-content-center align-items-center">
            <div className="spinner"></div>
            <div>{label}</div>
        </div>
    )
}
