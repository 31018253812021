import moment, { Moment } from "moment";
import React from "react";

const useYearSelector = () => {
  const initialDate = moment();

  const [currentYear, setCurrentYear] = React.useState<number>(
    initialDate.year()
  );
  const [startDate, setStartDate] = React.useState<Moment>(
    initialDate.clone().startOf("year").startOf("week")
  );
  const [endDate, setEndDate] = React.useState<Moment>(
    initialDate.clone().startOf("year").endOf("week")
  );

  const handlePrevWeek = () => {
    setStartDate((prev) => prev.clone().subtract(1, "week"));
    setEndDate((prev) => prev.clone().subtract(1, "week"));
  };

  const handleNextWeek = () => {
    setStartDate((prev) => prev.clone().add(1, "week"));
    setEndDate((prev) => prev.clone().add(1, "week"));
  };

  const resetCurrentWeek = () => {
    setStartDate(moment().startOf("week"));
    setEndDate(moment().endOf("week"));
  };

  const handlePrevYear = () => {
    setCurrentYear((prev) => prev - 1);
    const newStartDate = moment()
      .year(currentYear - 1)
      .startOf("year")
      .startOf("week");
    const newEndDate = newStartDate.clone().endOf("week");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleNextYear = () => {
    setCurrentYear((prev) => prev + 1);
    const newStartDate = moment()
      .year(currentYear + 1)
      .startOf("year")
      .startOf("week");
    const newEndDate = newStartDate.clone().endOf("week");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const getAllDates = React.useMemo((): Moment[] => {
    return Array(7)
      .fill(0)
      .map((_, key) => startDate.clone().add(key, "day"));
  }, [startDate]);

  return {
    currentYear,
    startDate,
    endDate,
    handlePrevWeek,
    handleNextWeek,
    resetCurrentWeek,
    handlePrevYear,
    handleNextYear,
    weekDates: getAllDates,
  };
};

export default useYearSelector;
