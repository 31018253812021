import axios from "axios";
import convertToSubcurrency from "../utils/convertToSubcurrency";

const getTransactions = () => {
  return axios
    .get(`account/transactions`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((res) => {
      console.log("res", res);

      return res.data;
    });
};

const getBalance = () => {
  return axios
    .get(`account/balance`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((res) => {
      console.log("res", res);
      return res.data;
    });
};

const depositFunds = (data: object) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}account/deposit`,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    console.log(data);
    return data;
  });
};

const createPaymentIntent = (amount: number) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}account/create-payment-intent`,
    method: "POST",
    data: { amount: convertToSubcurrency(amount) },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data.data;
  });
};
const confirmPaymentIntent = (clientSecret: any) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}account/confirm-payment-intent`,
    method: "POST",
    data: { clientSecret: clientSecret },
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    return data.data;
  });
};

const transfertunds = (data: object) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}account/transfert`,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    console.log(data);
    return data;
  });
};

const sendTipsFunds = (data: object) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}account/sendTips`,
    method: "POST",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  }).then((data) => {
    console.log(data);
    return data;
  });
};

const deleteAccount = () => {
  return axios
    .delete(`account/delete-account`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((res) => {
      return res.data;
    });
};

export const AccountService = {
  deleteAccount,
  getTransactions,
  getBalance,
  depositFunds,
  transfertunds,
  sendTipsFunds,
  createPaymentIntent,
  confirmPaymentIntent,
};
