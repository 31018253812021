import moment from "moment-timezone";
import { TimeSlot } from "../models/TimeSlot";
import { AppointmentStatus, IAppointment } from "../models/Appointment";
import { DayTimes } from "../components/UpcomingHours";
import { IProject } from "../models/Project";

export const getDatesRange = (
  initialDate = moment(),
  period: "day" | "week" | "month" = "week"
) => {
  let datesRange: moment.Moment[] = [];

  let startDate = moment(initialDate).startOf(period);
  let endDate = moment(initialDate).endOf(period);

  let currentDate = moment(startDate);
  while (currentDate.isSameOrBefore(endDate)) {
    datesRange.push(currentDate.clone());
    currentDate.add(1, "day");
  }

  return datesRange;
};

export const weekStartDate = (initialDate = moment()) => {
  const dayOfWeek = initialDate.day();
  const diff = initialDate.date() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  return moment(initialDate).date(diff);
};

export const monthStartDate = (initialDate = moment()) => {
  return moment(initialDate).startOf("month");
};

export function log(...args: any[]) {
  if (process.env.NODE_ENV !== "production") {
    console.log(...args);
  }
}

export function formatFileSize(bytes: number) {
  if (bytes < 1024) {
    return bytes + " B";
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + " kB";
  } else {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  }
}

export function formatUSPrice(price: number): string {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(price);
}

// export function formatTime(minutes: number): string {
//     const duration = moment.duration(minutes, 'minutes');

//     const hours = Math.floor(duration.asHours());
//     const formattedDuration = duration.format('H [h[s]] m [min[s]]');

//     return formattedDuration;
// }

export function formatTime(minutes: number): string {
  if (minutes < 60) {
    return `${minutes} min${minutes !== 1 ? "s" : ""}`;
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (remainingMinutes === 0) {
      return `${hours} hr${hours !== 1 ? "s" : ""}`;
    } else {
      return `${hours} hr${hours !== 1 ? "s" : ""} ${remainingMinutes} min${
        remainingMinutes !== 1 ? "s" : ""
      }`;
    }
  }
}

// Function to format the time range
export function formatTimeRange(
  startTime: string,
  endTime: string,
  timezone: string = moment.tz.guess()
) {
  const formattedStartTime = moment(startTime, "HH:mm").format("h:mm A");
  const formattedEndTime = moment(endTime, "HH:mm").format("h:mm A");
  return `${formattedStartTime} - ${formattedEndTime}`;
}

export function findTimeDifferences(
  defaultDayTimes: DayTimes,
  updatedDayTimes: DayTimes
): DayTimes {
  const differences: DayTimes = {};

  for (const day in updatedDayTimes) {
    if (
      !defaultDayTimes[day] || // Check if the day is not in defaultDayTimes
      defaultDayTimes[day].start !== updatedDayTimes[day].start || // Check start time changes
      defaultDayTimes[day].end !== updatedDayTimes[day].end // Check end time changes
    ) {
      differences[day] = updatedDayTimes[day];
    }
  }

  return differences;
}

export function formatSecondsToTime(totalSeconds: number): string {
  const hours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  const formattedTime = `${hours}h:${minutes}min:${seconds}s`;

  return formattedTime;
}

export function findRemovedDayTimes(
  defaultTimes: DayTimes,
  updatedTimes: DayTimes
): DayTimes {
  const removedDayTimes: DayTimes = {};

  for (const date in defaultTimes) {
    if (!updatedTimes[date]) {
      removedDayTimes[date] = defaultTimes[date];
    }
  }

  return removedDayTimes;
}

export function timeToTimezone(
  time: string,
  timezone: string = moment.tz.guess()
) {
  // return time
  return moment.utc(time, "HH:mm").tz(timezone).format("h:mm A");
}

export function groupTimeSlotsByDate(
  timeSlots: TimeSlot[],
  timezone: string = moment.tz.guess()
): { [date: string]: Omit<TimeSlot, "date">[] } {
  const groupedTimeSlots: { [date: string]: Omit<TimeSlot, "date">[] } = {};

  timeSlots.forEach((slot) => {
    const { date, isAvailable, time } = slot;
    if (groupedTimeSlots[date]) {
      groupedTimeSlots[date].push({
        time: moment.utc(time, "HH:mm").tz(timezone).format("h:mm A"),
        isAvailable,
      });
    } else {
      groupedTimeSlots[date] = [
        {
          time: moment.utc(time, "HH:mm").tz(timezone).format("h:mm A"),
          isAvailable,
        },
      ];
    }
  });

  return groupedTimeSlots;
}

export const filterAppointment = (
  appointments: IAppointment[] | null,
  status: AppointmentStatus
) => {
  if (appointments === null) return null;
  return appointments.filter((appointment) => {
    return appointment?.status === status;
  });
};

export const toServiceLabel = (
  appointment: IAppointment,
  simplify: boolean = true
) => {
  const services = appointment.service;
  if (!simplify) {
    return appointment.service.reduce((prev, next, key, services) => {
      if (key === services.length - 1 && key !== 0) {
        return `${prev},${next.service_detail.name}`;
      } else if (key === 0) {
        return `${next.service_detail.name}`;
      }
      return `${prev},${next.service_detail.name}`;
    }, "");
  }
  if (services.length === 1) return services[0].service_detail.name;
  else if (services.length === 2)
    return `${services[0].service_detail.name},${services[1].service_detail.name}`;
  else
    return `${services[0].service_detail.name},${
      services[1].service_detail.name
    } +${services.length - 2}`;
};

export const toProjectLabel = (project: IProject, simplify: boolean = true) => {
  const services = project.services;
  if (!simplify) {
    return project.services.reduce((prev, next, key, services) => {
      if (key === services.length - 1 && key !== 0) {
        return `${prev},${next.name}`;
      } else if (key === 0) {
        return `${next.name}`;
      }
      return `${prev},${next.name}`;
    }, "");
  }
  if (services.length === 1) return services[0].name;
  else if (services.length === 2)
    return `${services[0].name},${services[1].name}`;
  else return `${services[0].name},${services[1].name} +${services.length - 2}`;
};

export function formatMinutes(minutes: number) {
  if (minutes < 60) {
    return `${minutes}min`;
  } else {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return remainingMinutes === 0
      ? `${hours}h`
      : `${hours}h${remainingMinutes}min`;
  }
}

interface ShareData {
  title?: string;
  text?: string;
  url?: string;
}

export async function shareContent(shareData: ShareData) {
  if (navigator.share) {
    try {
      await navigator.share(shareData);
      console.log("Shared successfully");
    } catch (error) {
      console.error("Error sharing:", error);
    }
  } else {
    console.log("navigator.share API is not available");
  }
}

export function numberToHexColor(): string {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return `${color}`;
}

export function generateColorFromMongooseId(id: string): string {
  // Get the first 6 characters from the ObjectId
  const objectIdSubstring = id.substring(0, 6);

  // Convert the substring to a color format (e.g., #RRGGBB)
  const color = `#${objectIdSubstring}`;

  return color;
}

export const formatTimeAgo = (date: any) => {
  const now = moment();
  const targetDate = moment(date);

  const secondsAgo = now.diff(targetDate, "seconds");
  const minutesAgo = now.diff(targetDate, "minutes");
  const hoursAgo = now.diff(targetDate, "hours");
  const daysAgo = now.diff(targetDate, "days");
  const weeksAgo = now.diff(targetDate, "weeks");
  const monthsAgo = now.diff(targetDate, "months");

  if (monthsAgo > 0) {
    return `${monthsAgo}m`;
  } else if (weeksAgo > 0) {
    return `${weeksAgo}w`;
  } else if (daysAgo > 0) {
    return `${daysAgo}d`;
  } else if (hoursAgo > 0) {
    return `${hoursAgo}h`;
  } else if (minutesAgo > 0) {
    return `${minutesAgo}min`;
  } else if (secondsAgo > 0) {
    return `${secondsAgo}s`;
  } else {
    return "now";
  }
};

export function calculateTravelExpenses(
  professionalLng: number,
  professionalLat: number,
  customerLng: number,
  customerLat: number
): number {
  // Earth radius in miles
  const earthRadius = 3958.8; // Approximately 3958.8 miles or 6371 km

  // Convert degrees to radians
  const degToRad = (degrees: number) => (degrees * Math.PI) / 180;

  // Calculate the differences in latitude and longitude
  const deltaLat = degToRad(customerLat - professionalLat);
  const deltaLng = degToRad(customerLng - professionalLng);

  // Calculate the distance using the Haversine formula
  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(degToRad(professionalLat)) *
      Math.cos(degToRad(customerLat)) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  // Calculate total expenses
  const costPerMile = 5; // $5 per mile
  const totalExpenses = distance * costPerMile;

  return totalExpenses;
}

export function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number {
  const R = 6371; // Earth radius in kilometers

  const toRadians = (angle: number): number => {
    return (angle * Math.PI) / 180;
  };

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distance in kilometers
  return distance;
}

export const getAllDatesInMonth = (
  selectedMonth: moment.Moment
): moment.Moment[] => {
  const startOfMonth = moment(selectedMonth).startOf("month");
  const endOfMonth = moment(selectedMonth).endOf("month");
  const currentDate = moment().startOf("day"); // Get the current date with time set to midnight
  const datesInMonth: moment.Moment[] = [];

  let currentDay = startOfMonth.clone();
  while (currentDay.isSameOrBefore(endOfMonth, "day")) {
    // Check if the current day is before the current date
    if (currentDay.isSameOrAfter(currentDate, "day")) {
      datesInMonth.push(currentDay.clone());
    }
    currentDay.add(1, "day");
  }

  return datesInMonth;
};

export const getAllDatesInWeek = (
  selectedDate: moment.Moment
): moment.Moment[] => {
  const startOfWeek = moment(selectedDate).startOf("week");
  const endOfWeek = moment(selectedDate).endOf("week");
  const currentDate = moment().startOf("day"); // Get the current date with time set to midnight
  const datesInWeek: moment.Moment[] = [];

  let currentDay = startOfWeek.clone();
  while (currentDay.isSameOrBefore(endOfWeek, "day")) {
    // Check if the current day is before the current date
    if (currentDay.isSameOrAfter(currentDate, "day")) {
      datesInWeek.push(currentDay.clone());
    }
    currentDay.add(1, "day");
  }

  return datesInWeek;
};

export function formatName(firstName: string, lastName: string) {
  // Ensure the first name is in lowercase except for the first letter, which is capitalized
  const formattedFirstName =
    firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  // Capitalize the first letter of the last name and keep the rest in lowercase
  const formattedLastName =
    lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
  return `${formattedFirstName} ${formattedLastName}`;
}
