import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Review from "../../models/Review";
import { ReviewService } from "../../services/ReviewService";

export interface ReviewState {
  reviews: Review[] | null;
}

const initialState: ReviewState = {
  reviews: null,
};

export const getUserReviews = createAsyncThunk<Review[], string>(
  "reviews/list",
  async () => {
    const res = await ReviewService.getUserReviews();
    console.log("All reviews" , res)
    return res;
  }
);

export const AddUserReviews = createAsyncThunk<any , any>(
  "reviews/add",
  async (review, { rejectWithValue }) => {
    try {
      const res = await ReviewService.addUserReviews(review);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserReviews.fulfilled, (state, action) => {
      state.reviews = action.payload;
    });
  },
});

export default reviewsSlice.reducer;
