import { useCallback, useEffect, useRef, useState } from "react";
import Button from "../Button";
import AuthLayout from "../auth/AuthLayout";
import { useFormik } from "formik";
import { useUser } from "../../hooks/UseUser";
import { validationSchema } from "../validation/InfoPageValidation";
import toast from "react-hot-toast";
import { log } from "../../utils/utils";
import { updateUser } from "../../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import moment from "moment";
import IUser, { IBasicUser } from "../../models/User";
import ModalLoader from "../loader/ModalLoader";
import { isEqual } from "lodash";
import ConfirmDeleteUserModal from "../reusable/ConfirmDeleteUserModal";
import {
  useLocation,
  useNavigate,
  unstable_useBlocker as useBlocker,
  unstable_BlockerFunction as BlockerFunction,
} from "react-router-dom";
import { deleteAccount } from "../../redux/slices/accountSlice";
import UnsaveData from "../modal/UnsavaData";
import { AddPromotion } from "../promotion/AddPromotion";
import { InvitePromotion } from "../promotion/InvitePromotion";

interface UserDataProps {
  first_name: string | undefined;
  last_name: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  send_message: boolean | undefined;
  birthDay: string | undefined;
}

interface AccountSettingProps {
  isEdited?: boolean;
}
const AccountSetting = ({ isEdited = false }: AccountSettingProps) => {
  const user = useUser();
  const mainRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [edited, setEdited] = useState(isEdited);
  const [currentUserData, setCurrentUserData] = useState<UserDataProps>({
    first_name: user?.first_name,
    last_name: user?.last_name,
    phone: user?.phone,
    email: user?.email,
    send_message: user?.send_message,
    birthDay: user?.birthDay,
  });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isProfessional = Object.hasOwn(user ?? {}, "service_radius");

  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const submitIsChangeBtnRef = useRef<HTMLButtonElement>(null);

  const formik = useFormik<Partial<IUser>>({
    initialValues: {
      first_name: user?.first_name as string,
      last_name: user?.last_name as string,
      phone: user?.phone as string,
      email: user?.email as string,
      send_message: user?.send_message,
      birthDay: user?.birthDay,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      log(JSON.stringify(values, null, 2));
    },
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("test 1");
    if (submitIsChangeBtnRef.current) {
      console.log("test 2");

      setLoading(true);
      // formik.handleSubmit();
      if (formik.errors) {
        // toast.error("Please fill in all the required fields")
        setLoading(false);
      }
      console.log(formik.errors);
      if (Object.keys(formik.errors).length === 0) {
        console.log("test 3");
        const formData = new FormData();
        const keysToInclude: Array<keyof IBasicUser> = [
          "email",
          "phone",
          "first_name",
          "last_name",
          "birthDay",
          "send_message",
        ];

        keysToInclude.forEach((key) => {
          const value = formik.values[key];
          if (value) formData.append(key, value as string);
        });

        submitIsChangeBtnRef.current.setAttribute("disabled", "true");
        submitIsChangeBtnRef.current.innerHTML = "loading...";
        console.log("Here is the user form data", formData);
        dispatch(updateUser(formData) as unknown as AnyAction)
          .unwrap()
          .then(() => {
            toast.success("Successfully Saved!");
            setIsShow(isShow);
          })
          .catch((error: any) => {
            toast.error("An error occured, please try again later");
          })
          .finally(() => {
            if (submitIsChangeBtnRef.current) {
              submitIsChangeBtnRef.current.removeAttribute("disabled");
              submitIsChangeBtnRef.current.innerHTML = "Save";
            }
            setLoading(false);
            setIsShow(!isShow);
          });
      }
    } else if (submitBtnRef.current) {
      console.log("test 2");

      setLoading(true);
      // formik.handleSubmit();
      if (formik.errors) {
        // toast.error("Please fill in all the required fields")
        setLoading(false);
      }
      console.log(formik.errors);
      if (Object.keys(formik.errors).length === 0) {
        console.log("test 3");
        const formData = new FormData();
        const keysToInclude: Array<keyof IBasicUser> = [
          "email",
          "phone",
          "first_name",
          "last_name",
          "birthDay",
          "send_message",
        ];

        keysToInclude.forEach((key) => {
          const value = formik.values[key];
          if (value) formData.append(key, value as string);
        });

        submitBtnRef.current.setAttribute("disabled", "true");
        submitBtnRef.current.innerHTML = "loading...";
        console.log("Here is the user form data", formData);
        dispatch(updateUser(formData) as unknown as AnyAction)
          .unwrap()
          .then(() => {
            toast.success("Successfully Saved!");
            setIsShow(isShow);
          })
          .catch((error: any) => {
            toast.error("An error occured, please try again later");
          })
          .finally(() => {
            if (submitBtnRef.current) {
              submitBtnRef.current.removeAttribute("disabled");
              submitBtnRef.current.innerHTML = "Save";
            }
            setLoading(false);
            setIsShow(!isShow);
          });
      }
    }
  };

  useEffect(() => {
    if (user) {
      formik.setValues(user);
    }
  }, [user]);

  const toDate = (value: string) => {
    const date = new Date(value);
    return moment(
      `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`,
      "YYYY-MM-DD"
    ).format("YYYY-MM-DD");
  };

  const isDeepEqual = isEqual(formik.values, user);
  const shoudlBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      !isDeepEqual && currentLocation.pathname !== nextLocation.pathname,
    [!isDeepEqual]
  );
  const blocker = useBlocker(shoudlBlock);

  useEffect(() => {
    console.log("The difference", formik.values, user);
    console.log("Did i make change ?", !isDeepEqual);
    setHasChange(!isDeepEqual);
  }, [formik.values]);

  useEffect(() => {
    if (!isDeepEqual) {
      setIsShow(!isShow);
    }
  }, [isDeepEqual]);

  const handleDeleteModal = () => {
    setShowDeleteModal(true);
  };
  const handleDiscardChanges = () => {
    setCurrentUserData(currentUserData);
    blocker.state === "blocked" && blocker.reset();
    setIsShow(!isShow);
  };

  const handleOnClosed = () => {
    blocker.state === "blocked" && blocker.reset();
    // setIsShow(!isShow);
  };

  const handleLogout = async () => {
    toast.loading("Deleting your account ...");
    dispatch(deleteAccount() as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        window.localStorage.removeItem("salonId");
        window.localStorage.removeItem("authToken");
        navigate("/");
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        toast.remove();
      });
  };

  const handleInvite = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AuthLayout>
      <div className="AccountSetting" ref={mainRef}>
        {loading && <ModalLoader />}
        <h3 className="text-primary">Account Details</h3>
        <form>
          <div className="mb-3">
            <label htmlFor="" className="form-label text-black fw-500">
              First Name
            </label>
            <input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values?.first_name ?? ""}
              type="text"
              name="first_name"
              id="first_name"
              className="form-control w-100"
              aria-describedby="helpId"
            />
          </div>
          {formik.touched.first_name && formik.errors.first_name ? (
            <div style={{ color: "var(--primaryColor)" }}>
              {formik.errors.first_name}
            </div>
          ) : null}
          <div className="mb-3">
            <label htmlFor="" className="form-label text-black fw-500">
              Last Name
            </label>
            <input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name ?? ""}
              type="text"
              name="last_name"
              id="last_name"
              className="form-control w-100"
              aria-describedby="helpId"
            />
          </div>
          {formik.touched.last_name && formik.errors.last_name ? (
            <div style={{ color: "var(--primaryColor)" }}>
              {formik.errors.last_name}
            </div>
          ) : null}
          <div className="mb-3">
            <label htmlFor="email" className="fw-500">
              Email address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              disabled
              className="form-control w-100"
              placeholder="email"
              aria-describedby="helpId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email ?? ""}
            />
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div style={{ color: "var(--primaryColor)" }}>
              {formik.errors.email}
            </div>
          ) : null}

          <div className="mb-3">
            <label htmlFor="phone" className="fw-500">
              Phone Number
            </label>
            <input
              placeholder="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone ?? ""}
              type="phone"
              className="form-control fw-500"
              id="phone"
              name="phone"
            />
          </div>
          {formik.touched.phone && formik.errors.phone ? (
            <div style={{ color: "var(--primaryColor)" }}>
              {formik.errors.phone}
            </div>
          ) : null}
          <div className="mb-3">
            <label htmlFor="birthday" className="fw-500">
              Birthday
            </label>
            <input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={toDate(formik.values.birthDay as unknown as string)}
              type="date"
              className="form-control fw-500"
              id="birthDay"
              name="birthDay"
            />
          </div>
          <div className="form-check form-switch">
            <input
              style={{ height: "20px !important", width: "40px !important" }}
              className="form-check-input me-2"
              type="checkbox"
              role="switch"
              id="smsNotification"
              name="send_message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.send_message}
            />
            <label
              className="form-check-label fw-500"
              htmlFor="smsNotification"
            >
              SMS NOTIFICATIONS
            </label>
          </div>

          <div className="small fw-500">
            Yes send me automated appointment reminders & info about my bookings
            (message & data rates may apply).
          </div>

          {/* {user && (
            <div className="d-flex gap-3">
              <label htmlFor="timezone" className="fw-500">
                Invite your friends and earn money on latrice
              </label>{" "}
              <button
                className="btn-primary mx-3"
                onClick={() => handleInvite()}
                style={{
                  height: "2rem",
                  width: "6rem",
                  alignSelf: "end",
                  marginBottom: "1rem",
                }}
              >
                Invite now
              </button>
            </div>
          )} */}
          <div className="d-flex flex-column justify-content-between mt-2">
            <div className="d-flex justify-content-between my-3">
              <span
                className="text-primary hover-underline deleteAccountBtn"
                onClick={handleDeleteModal}
              >
                Delete account
              </span>

              {isProfessional && (
                <span
                  className="text-primary hover-underline deleteAccountBtn"
                  onClick={() => handleDeleteModal}
                >
                  Switch your account type
                </span>
              )}
            </div>
            <Button
              type="submit"
              style={{
                height: "2rem",
                width: "6rem",
                alignSelf: "end",
                marginBottom: "1rem",
              }}
              ref={submitBtnRef}
              onClick={handleSubmit}
              disabled={!hasChange}
              mode={"primary"}
              content={<span className="mx-3">Save</span>}
            />
          </div>
        </form>
        {blocker.state === "blocked" && !isShow && (
          <UnsaveData
            onSave={handleSubmit}
            message="Do you really want to exit without saving your changes?"
            onCancel={handleDiscardChanges}
            open={!isShow}
            onClose={handleOnClosed}
            submitIsChangeBtnRef={submitIsChangeBtnRef}
          />
        )}
      </div>

      {/* <InvitePromotion onClose={handleClose} show={true} user={user as IUser} /> */}

      <ConfirmDeleteUserModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        handleLogout={handleLogout}
      />
    </AuthLayout>
  );
};

export default AccountSetting;
