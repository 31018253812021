import RecentChatLoader from "../loader/RecentChatLoader"

export interface ChatSearchAreaProps {
    onChange: (value: string) => void
}


export const ChatSearchArea = (props: ChatSearchAreaProps) => {

    return <div style={{ zIndex: '2' }} className="input-group searchArea position-sticky top-0" aria-autocomplete="none">
        <button className="btn" type="button">
            <i className={"fa fa-search"}></i>
        </button>
        <input
            placeholder="Search"
            required
            type="text"
            className="form-control fw-500"
            onChange={(e) => props.onChange(e.target.value)}
        />
    </div>

}


export const Loader = () => {
    return <>
        <RecentChatLoader />
        <RecentChatLoader />
        <RecentChatLoader />
    </>
}

export const NoAvailableDiscussion = () => {
    return <div className="h-100 user-select-none text-muted d-flex flex-column align-items-center justify-content-center">
        <i className="fa fa-envelope fa-3x"></i>
        <span>No available discussions !</span>
    </div>
}