import React from "react";
import { ChatList as ChatListElements } from "react-chat-elements";

export interface ChatDiscussion {
    with: {
        user: {
            profile: string;
            first_name: string;
            last_name: string;
        };
        profile: string;
        first_name: string;
        last_name: string;
    };
    lastMessage?: string;
    lastDate: string;
    unread: number;
}

export interface ChatListItem {
    id: string;
    avatar: string;
    alt: string;
    title: string;
    subtitle: React.ReactNode | string;
    date: string;
    discussion: ChatDiscussion;
}

export interface ChatListProps {
    data: ChatDiscussion[];
    onSelectDiscussion: (discussion: ChatDiscussion) => void;
}

export const ChatList: React.FC<ChatListProps> = ({ data, onSelectDiscussion }) => {
    const chatList: ChatListItem[] = [];

    data.forEach((discussion, key) => {
        const chatWith = discussion.with;
        const isUser = chatWith.user === null || chatWith.user === undefined;

        if (chatWith) {
            chatList.push({
                id: key.toString(),
                avatar: !isUser ? chatWith.user.profile : chatWith.profile,
                alt: isUser
                    ? `${chatWith.first_name} ${chatWith.last_name}`
                    : `${chatWith.user.first_name} ${chatWith.user.last_name}`,
                title: isUser
                    ? `${chatWith.first_name} ${chatWith.last_name}`
                    : `${chatWith.user.first_name} ${chatWith.user.last_name}`,
                subtitle:
                    discussion.lastMessage ?? (
                        <div className="text-muted">
                            Start the chat with {(chatWith.user ?? chatWith).first_name}{" "}
                            <i className="fas fa-hand-paper smallHandRotation"></i>
                        </div>
                    ),
                date: discussion.lastDate,
                discussion,
            });
        }
    });

    return <ChatListElements
        className='chat-list'
        id='ChatList'
        onClick={(discussion: any) => onSelectDiscussion(discussion)}
        lazyLoadingImage=''
        dataSource={chatList as any} />
};

