import React, { useState } from "react";
import styles from "./../styles/LikeButton.module.css";
// import { Player } from "@lottiefiles/react-lottie-player";
import likeJson from "../../assets/animation/like.json";
import Lottie from "react-lottie";

export interface LikeButtonProps {
  handleFavories: () => void;
}

const LikeButton: React.FC<LikeButtonProps> = ({ handleFavories }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const playAnimationOnce = () => {
    setIsPlaying(true);
    handleFavories();

    setTimeout(() => {
      setIsPlaying(false);
    }, 1000);
  };

  const onAnimationComplete = () => {
    setIsPlaying(false);
  };

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: likeJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <button
        onClick={playAnimationOnce}
        style={{ border: "none", background: "none" }}
      >
        <Lottie
          options={defaultOptions}
          height={50}
          width={50}
          isStopped={!isPlaying}
          isPaused={!isPlaying}
        />
      </button>
    </div>
  );
};

export { LikeButton };
