import { NavLink } from 'react-router-dom';
import './../styles/Footer.css'

const Footer = () => {
    return <div className="Footer text-light py-4 mt-5">
        <div className="d-flex container flex-column justify-content-between gap-5">
            <div className='d-flex justify-content-between flex-column flex-md-row gap-3 mt-3'>
                <div style={{ flex: 1 }} className="">
                    <h2 className=''>LATRICE</h2>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </div>
                </div>
                <div className='d-flex flex-row flex-md-column gap-1 align-items-start align-items-md-center' style={{ flex: 1 }}>
                    <NavLink
                        exact="true"
                        className="nav-link text-white mx-lg-3 w-25"
                        to={"/status"}
                    >
                        Status
                    </NavLink>
                    <NavLink
                        exact="true"
                        className="nav-link text-white mx-lg-3 w-25"
                        to={"/privacy"}
                    >
                        Privacy
                    </NavLink>
                    <NavLink
                        exact="true"
                        className="nav-link text-white mx-lg-3 w-25"
                        to={"/terms"}
                    >
                        Terms
                    </NavLink>
                    <NavLink
                        exact="true"
                        className="nav-link text-white mx-lg-3 w-25"
                        to={"/cookies"}
                    >
                        Cookies
                    </NavLink>
                    <NavLink
                        exact="true"
                        className="nav-link text-white mx-lg-3 w-25"
                        to={"/terms"}
                    >
                        Contact Us
                    </NavLink>
                </div>
                <div className='d-flex flex-column gap-3' style={{ flex: 1 }}>
                    <h2 className=''>Applications</h2>
                    <div className='d-flex flex-column gap-3'>
                        <div className='d-flex justify-content-center w-100 gap-4'>
                            <img className='storeLogo' src={'../assets/get-on-appstore.png'} alt="App store" />
                            <img className='storeLogo' src={'../assets/get-on-playstore.png'} alt="Play store" />
                        </div>
                        <div className='d-flex justify-content-center gap-3 align-items-center w-100'>
                            <i className="footerSocialIcon fa-brands fa-square-facebook fs-2"></i>
                            <i className="footerSocialIcon fa-brands fa-square-youtube fs-2"></i>
                            <i className="footerSocialIcon fa-brands fa-square-instagram fs-2"></i>
                            <i className="footerSocialIcon fa-brands fa-linkedin fs-2"></i>

                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center align-items-end text-muted'>
                @2024 Latrice, Inc. All rights reserved.
            </div>
        </div>
    </div>
}

export default Footer;