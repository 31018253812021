import React from 'react'
import { Modal } from 'react-bootstrap'
import { ModalLoader } from '../../modal/commons/ModalLoader'
import useTimeOptions from '../../../hooks/useTimeOptions'
import moment from 'moment'
import { AddShiftModalProps, ShiftTimeInterval } from './AddShiftModal'
import { TElementEditable, TElementType } from '../WorkingTable'
import { formatTime } from '../../../utils/utils'


type DeleteShiftModalProps = {
    type: TElementType,
    data: TElementEditable
    onDelete: AddShiftModalProps['onSave']

} & Pick<AddShiftModalProps, 'onHide' | 'timezone'>

export const DeleteShiftModal: React.FC<DeleteShiftModalProps> = ({ ...props }) => {

    const [shiftInterval, setShiftInterval] = React.useState<ShiftTimeInterval>({
        startTime: moment(props.data.source.startTime, 'h:mm A').format('hh:mm A'),
        endTime: moment(props.data.source.endTime, 'h:mm A').format('hh:mm A'),
    });

    const [loading, setLoading] = React.useState(false);
    const timeOptions = useTimeOptions({ interval: 30 });
    const [error, setError] = React.useState([]);

    const handleDelete = async () => {
        setError([]);
        setLoading(true);
        await props.onDelete(shiftInterval, props.timezone).then(() => {
            props.onHide();
        }).catch((err) => {
            console.log(err)
            setError(err.errors);
        }).finally(() => {
            setLoading(false)
        });
    }


    return (
        <Modal backdrop='static' onHide={props.onHide} show={true} centered  >
            {loading && <ModalLoader />}
            <Modal.Header closeButton>
                <div className='text-black fw-500 fs-5'>
                    Delete {props.type.split('O').join(" o").toLowerCase()}
                </div>
            </Modal.Header>
            <Modal.Body className='border-top border-bottom'>
                {error.length > 0 &&
                    <div style={{ borderRadius: '0' }} className='alert alert-danger w-100 p-0 m-0 pt-1'>
                        <ul className=''>
                            {error.map ? error.map((message) => {
                                return <li>{message}</li>
                            }) : error.toString()}
                        </ul>
                    </div>
                }
                <div className='mx-3 my-4 working-table-add-shift-container gap-3'>
                    <div className="">
                        This will delete time off on <span className='fw-500 text-black'>{moment(props.data.source.day, 'YYYY-MM-DD').format('ddd, DD MMM YYYY')}</span> from <span className='fw-500 text-black'>{props.data.source.startTime}</span> till <span className='fw-500 text-black'>{props.data.source.endTime}</span>.
                    </div>
                </div>
            </Modal.Body>
            <Modal.Body>
                <div className='d-flex justify-content-end mx-3 my-3'>
                    <div className='d-flex justify-content-end align-items-center gap-3'>
                        <button onClick={() => props.onHide()} className='working-table-outline-button'>Cancel</button>
                        <button onClick={() => handleDelete()} className='working-table-danger-button'>Delete</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}