import React, { ButtonHTMLAttributes, forwardRef, LegacyRef, ReactNode } from 'react';
import './../styles/Button.css';

export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'content'> {
    mode?: "primary" | "default" | "outlined" | "neutral";
    rounded?: boolean;
    content?: ReactNode | null;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ content = null, ...props }: ButtonProps, ref: LegacyRef<HTMLButtonElement>) => {
    const {
        mode = "primary",
        rounded = false,
        className,
        style,
        ...params
    } = props;

    return (
        <button style={style} ref={ref} {...params} className={`Button btn shadow btn-${mode} ${rounded ? 'btn-rounded' : ''} ${className}`}>
            {content ?? props.children}
        </button>
    );
});

export default Button;
