import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthState } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import PulseLoader from "../loader/PulseLoader";
import { PROFESSIONAL_ROUTES, UNAUTHENTICATED_ROUTES } from "../navigation";
import { SalonProvider } from "../../context/SalonContext";
import { isUserProfessional } from "../../models/User";

interface AuthRouteProps {
  children: React.ReactNode;
}

const AuthRoute: React.FC<AuthRouteProps> = ({ children }) => {
  const currentUser = useSelector<unknown, AuthState>(
    (state: any) => state.auth
  ).currentUser;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const canNavigate = (routes: string[], path: string): boolean => {
    return routes.some((route) => {
      return path.startsWith(route);
    });
  };

  useEffect(() => {
    const path = window.location.pathname;

    if (!currentUser) {
      if (!canNavigate(UNAUTHENTICATED_ROUTES, path)) {
        navigate("/");
      } else {
        setLoading(false);
      }
    } else {
      if (!isUserProfessional(currentUser)) {
        if (canNavigate(PROFESSIONAL_ROUTES, path)) {
          navigate("/");
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    setLoaded(!loading);
  }, [loading]);

  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    // Load Google Maps API asynchronously
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_SERVER_GOOGLE_MAP_API_KEY}&libraries=places`;
    script.async = true;
    script.onload = () => {
      setGoogleMapsLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <SalonProvider>
      {loaded && googleMapsLoaded ? (
        <>{children}</>
      ) : (
        <div
          style={{ width: "100vw", height: "100vh" }}
          className="d-flex align-items-center justify-content-center"
        >
          <PulseLoader />
        </div>
      )}
    </SalonProvider>
  );
};

export default AuthRoute;
