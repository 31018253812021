import React, { CSSProperties, useEffect } from "react";
import {
  DiscussionSystemActionType,
  DiscussionSystemProps,
  SYSTEM_PENDING_RESPONSE_DURATION,
} from "../../LatriceDiscussionMessage";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import {
  formatSecondsToTime,
  timeToTimezone,
  formatUSPrice,
} from "../../../../utils/utils";
import withTIMER, { WithTimerProps } from "../../../../hoc/withTimer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { AppointmentReassignPending } from "../../AppointmentReassignPending";

export type PendingDiscussionBase = {
  onPendingAction?: (action: DiscussionSystemActionType) => void;
  actionContainer?: JSX.Element;
  alertContainer?: JSX.Element;
};

export const PendingDiscussionBase = withTIMER(
  (props: DiscussionSystemProps & PendingDiscussionBase & WithTimerProps) => {
    const deductColor = (duration: number, elapsedTime: number): string => {
      const percent = 100 - Math.round((elapsedTime / duration) * 100);
      if (percent > 70) {
        return "var(--primaryColor)";
      } else if (percent > 45) {
        return "var(--yellowColor)";
      }
      return "red";
    };

    const [timerIsActive, setTimerActive] = React.useState(true);

    React.useEffect(() => {
      if (props.duration <= props.elapsedTime) setTimerActive(false);
    }, [props.duration, props.elapsedTime]);

    const [onReassign, setOnReassign] = React.useState(false);

    return (
      <div
        style={
          {
            "--responseDuration": props.duration,
            "--elapsedTime": props.elapsedTime,
            "--elapsedTimeColor": `${deductColor(
              props.duration,
              props.elapsedTime
            )}`,
          } as CSSProperties
        }
        className={`w-75 LatricePendingAppointment 
        ${!timerIsActive ? " LatricePendingAppointment--disabled" : ""}`}
      >
        <div
          data-expiration-text={`This appointment has expired due to timeout and it's no longer available !`}
          className="rce-mbox"
        >
          <div className="rce-mbox-body">
            <div className="rce-mbox-forward rce-mbox-forward-right"></div>
            <div className="d-flex justify-content-between rce-mbox-title rce-mbox-title--clear">
              <span className="text-uppercase">
                You have a pending custom appointment
              </span>
              <Tooltip id="pending-custom-appointment-tooltip" />
              <span
                data-tooltip-content={
                  "Time remaining to accept this appointment."
                }
                data-tooltip-id="pending-custom-appointment-tooltip"
                style={
                  {
                    color: `${deductColor(props.duration, props.elapsedTime)}`,
                    textDecoration: "none !important",
                  } as CSSProperties
                }
              >
                {timerIsActive ? (
                  formatSecondsToTime(
                    Math.round((props.duration - props.elapsedTime) / 1000)
                  )
                ) : (
                  <span>timeout ! this appointment has been expired.</span>
                )}
              </span>
            </div>
            <div className="rce-mbox-text left d-flex flex-column gap-1">
              <img
                style={{ width: "100px" }}
                alt=""
                className="img-fluid rounded"
                src={props.appointment.photo}
              />
              <div className="d-flex align-items-center">
                <h6 className="text-underline">
                  The service is similar to {props.appointment.serviceName}
                </h6>
                <span className="small">({props.appointment.size})</span>
              </div>
              <div className="d-flex gap-1 align-items-center w-100 fw-500 mb-2">
                <div className="d-flex align-items-center gap-1">
                  <i className="fa fa-calendar" />
                  <span>
                    {moment(props.appointment.day, "YYYY-MM-DD").format(
                      "DD MMM YYYY"
                    )}
                  </span>
                  <span>{timeToTimezone(props.appointment.startTime)}</span>
                </div>
                <div className="ms-auto">
                  {formatUSPrice(props.appointment.price)}
                </div>
              </div>
              <p>{props.appointment.description}</p>
            </div>
          </div>
          {
            // ALERT CONTAINER
            props.alertContainer
          }
          <div className="d-flex justify-content-between align-items-end">
            {
              // ACTION CONTAINER
              props.actionContainer
            }
            <div
              className="rce-mbox-time non-copiable ms-auto"
              data-text={moment
                .utc(props.appointment.createdAt)
                .tz(moment.tz.guess())
                .fromNow()}
            >
              <span className="rce-mbox-status"></span>
            </div>
          </div>

          {!props.appointmentInitiator ? null : (
              <AppointmentReassignPending
                onReassign={(value: boolean) => setOnReassign(value)}
                className="mt-2"
                appointment={props.appointment}
              />
            )}
        </div>
      </div>
    );
  }
);
