import React, { ReactNode, useState } from "react";
import AccordionItem from "./AccordionItem";
import "../../styles/Accordion.css";

type AccordionData = {
  icon: string;
  title: string;
  content: string;
};

export default function Accordion({ items }: { items: Array<AccordionData> }) {
  const [currentIdx, setCurrentIdx] = useState(-1);
  const btnOnClick = (index: number) => {
    setCurrentIdx((currentValue) => (currentValue !== index ? index : -1));
  };
  return (
    <ul className="accordion">
      {items.map((item, idx) => (
        <AccordionItem
          key={idx}
          data={item}
          isOpen={idx === currentIdx}
          btnOnClick={() => btnOnClick(idx)}
        />
      ))}
    </ul>
  );
}
