import { useSelector } from "react-redux";
import { AuthState } from "../redux/slices/authSlice";

export const useUser = () => {
  const user = useSelector<unknown, AuthState>(
    (state: any) => state.auth
  ).currentUser;

  return user;
};
