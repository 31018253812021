import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export type ValidationSuccessStepProps = {
  editState?: boolean;
};

const ValidationSuccessStep: React.FC<ValidationSuccessStepProps> = ({
  editState,
}) => {
  return (
    <div className="d-flex gap-5 flex-column justify-content-center align-items-center">
      <h2>
        {editState
          ? "Project Successfully Edited!"
          : "Project Successfully Posted!"}
      </h2>
      <FontAwesomeIcon icon={faCheckCircle} size="7x" />
      <p className="fs-6 text-justify">
        {editState
          ? `Congratulations! Your project has been successfully Edited. Get ready to receive offers from professionals very quickly.

          You'll have the opportunity to compare offers, review profiles, and choose the service providers that best suit your needs.

          Whether you're looking for quality, pricing, or specific expertise, you'll have the freedom to make informed decisions.

          Our platform is designed to empower you in your decision-making process. Feel free to accept or reject offers based on your preferences.

          Thank you for choosing us, and we wish you a seamless and rewarding experience with the service providers.`
          : `Congratulations! Your project has been successfully posted. Get ready to receive offers from professionals very quickly.

          You'll have the opportunity to compare offers, review profiles, and choose the service providers that best suit your needs.

          Whether you're looking for quality, pricing, or specific expertise, you'll have the freedom to make informed decisions.

          Our platform is designed to empower you in your decision-making process. Feel free to accept or reject offers based on your preferences.

          Thank you for choosing us, and we wish you a seamless and rewarding experience with the service providers.`}
      </p>
    </div>
  );
};

export default ValidationSuccessStep;
