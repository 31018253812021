import React from 'react'
import { DiscussionSystemProps } from '../../LatriceDiscussionMessage'
import { DisputedDiscussionBase } from './DisputedDiscussionBase'
import { Alert } from 'react-bootstrap';
import { AcceptedDiscussionBase } from './AcceptedDiscussionBase';


export type DisputedDiscussionUser = Required<Pick<DisputedDiscussionBase, 'OnDisputedAction'>>;


export const AcceptedDiscussionUser = (props: DiscussionSystemProps & DisputedDiscussionUser) => {

    if (props.appointment.acceptedByClient) {
        console.log(props.appointment);
        return <AcceptedDiscussionBase
            appointment={props.appointment}
            alertContainer={
                <Alert className='alert-success--bordered' key={'success'} variant={'success'}>
                    <i className="fa fa-triangle-exclamation fa-1x"></i>
                    You have accepted the appointment.
                    The appointment has been scheduled and the chat with the professional will be open as soon as possible.
                </Alert>
            }
        />
    }


    return <AcceptedDiscussionBase
        appointment={props.appointment}
        alertContainer={
            <Alert className='alert-warning--bordered' key={'warning'} variant={'warning'}>
                <i className="fa fa-triangle-exclamation fa-1x"></i>
                We are currently awaiting for your approbation to proceed further.
            </Alert>
        }
        actionContainer={
            <div className='d-flex justify-content-start align-items-end'>
                <button onClick={() => { props.OnDisputedAction('accept') }} className={`LatricePendingAppointment__button accept-button`}>
                    <i className='fas fa-check'></i> Accept this appointment
                </button>
                <button onClick={() => { props.OnDisputedAction('refuse') }} className={`LatricePendingAppointment__button refuse-button`}>
                    <i className='fas fa-times'></i> Refuse this appointment
                </button>
            </div>
        }
    />

}
