import axios from "axios";

const getAvailableDiscussion = () => {
  return axios
    .get(`messages`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "x-salon-id": `${localStorage.getItem("salonId")}`,
      },
    })
    .then((res) => {
      return res.data;
    });
};

  const getDiscussionMessages = () => {
    return axios
      .get(`messages/all-messages`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          "x-salon-id": `${localStorage.getItem("salonId")}`,
        },
      })
      .then((res) => {
        return res.data;
      });
  };

export const DiscussionService = {
  getAvailableDiscussion,
  getDiscussionMessages
};
