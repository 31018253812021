import React from "react";
import '../../styles/ChatInput.css';

export interface ChatInputProps {
  onSendMessage: (message: string) => void;
  disabled: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, disabled }) => {
  const [message, setMessage] = React.useState("");
  const attachFileContainerRef = React.useRef<HTMLDivElement>(null);
  const attachFileBtnRef = React.useRef<HTMLButtonElement>(null);

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (message.trim() === "") {
      return; // Don't send empty messages
    }

    onSendMessage(message);
    setMessage(""); // Clear the input field
  };

  const canSendMessage = message?.trim()?.length > 0;

  const handleOpenFileAppended = (e: any) => {
    if (attachFileContainerRef.current) {
      attachFileContainerRef.current.classList.toggle('expanded')
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && canSendMessage) {
      handleSendMessage();
    }
  }

  React.useEffect(() => {
    document.onclick = (ev: MouseEvent) => {
      if (!attachFileBtnRef.current?.contains(ev.target as HTMLElement)) {
        attachFileContainerRef.current?.classList.remove('expanded');
      }
    }
  }, [])


  return (
    <div className="ChatInput chatBox d-flex align-items-center justify-content-center w-100 m-0">
      <div className="input-group d-flex m-0 mx-3 mb-2 align-items-center">
        {false && <div ref={attachFileContainerRef} className="ChatInput__attachFileContainer ">
          <div className="ChatInput__attachFileContainer__actionBtnContainer">
            <i className="fa fa-image fa-1x"></i>
            <i className="fa fa-video fa-1x"></i>
          </div>

          <button
            ref={attachFileBtnRef}
            onClick={handleOpenFileAppended}
            className="btn sendMessageBtn ChatInput__appendFilesBtn"
            type="button"
            id="attachFile"
          >
            <i className="fa fa-paperclip"></i>
          </button>

        </div>}
        <input
          autoFocus={true}
          placeholder="Type message..."
          type="text"
          className="form-control ChatInput__input no-decoration fw-500 border"
          value={message}
          onChange={handleMessageChange}
          disabled={disabled}
          onKeyDown={handleKeyDown}
        />
        <button
          disabled={!canSendMessage}
          className="btn sendMessageBtn"
          type="button"
          onClick={handleSendMessage}
        >
          <i className="fa-2x fa-brands fa-telegram"></i>
        </button>
      </div>
    </div>
  );
};

export default ChatInput; 