import { useEffect, useRef, useState } from "react";
import Button from "../Button";
import Member from "../Member";
import { useDispatch, useSelector } from "react-redux";
import { MemberState, getMember } from "../../redux/slices/memberSlice";
import { AnyAction } from "@reduxjs/toolkit";

const RemotePayment = () => {
  const members = useSelector<unknown, MemberState>(
    (state: any) => state.myMember
  ).members;
  const dispatch = useDispatch();
  const submitBtnRef = useRef();
  const [addedMember, setAddedMember] = useState<any[]>([]);
  const [refetchMember, setRefetchMember] = useState(false);

  useEffect(() => {
    dispatch(getMember() as unknown as AnyAction);
  }, [refetchMember]);

  useEffect(() => {
    if (members) {
      setAddedMember(members);
    }
  }, [members]);

  const handleRefetchMembers = () => {
    setRefetchMember(!refetchMember);
  };

  return (
    <div className="AccountSetting">
      <div className="d-flex">
        <h3 className="text-primary mb-3">Mandated by</h3>
        <i className="fa fa-info-circle text-primary"></i>
      </div>
      <h6 className="text-black mb-3">Members</h6>
      {addedMember.length && (
        <div className="d-flex flex-column gap-3 align-items-center justify-content-center mt-5 ">
          {addedMember.map((member, key) => (
            // <Member data={member} key={key} />
            <div>teste</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RemotePayment;
