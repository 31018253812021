import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { generatePromoCode } from "../../hooks/usePromoCode";
import "../styles/PromoCodeGenerator.css";

const PromoCodeGenerator: React.FC = () => {
  const [promoCode, setPromoCode] = useState<string>("");
  const [copied, setCopied] = useState<boolean>(false);

  const handleGenerateCode = () => {
    const newPromoCode = generatePromoCode(12);
    setPromoCode(newPromoCode);
    setCopied(false);
  };

  const handleCopyCode = () => {
    if (promoCode) {
      navigator.clipboard.writeText(promoCode).then(() => {
        setCopied(true);
      });
    }
  };

  return (
    <div className="promo-code-generator">
      <Button
        onClick={handleGenerateCode}
        className="mb-3 btn-secondary border-0 "
      >
        Generate Promo Code
      </Button>
      {promoCode && (
        <div className="promo-code-display d-flex">
          <div>
            <h5>Your Promo Code:</h5>
            <code>{promoCode}</code>
          </div>
          <FontAwesomeIcon
            icon={faCopy}
            className="ms-3"
            style={{ cursor: "pointer" }}
            onClick={handleCopyCode}
          />
          {copied && <span className="ms-2 text-success">Copied!</span>}
        </div>
      )}
    </div>
  );
};

export default PromoCodeGenerator;
