import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IAppointment } from "../../models/Appointment";
import Availability from "../../models/Availability";
import { AvailabilityService } from "../../services/AvailabilityService";
import { DayTimes } from "../../components/UpcomingHours";
import { USATimezone } from "../../pages/ManageAvailabilityPage";
import moment from "moment";

export interface AvailabilityState {
  availabilities: Availability[] | null;
  errors: any;
}

const initialState: AvailabilityState = {
  availabilities: null,
  errors: null,
};

export const addAvailability = createAsyncThunk<
  Partial<IAppointment>,
  {
    edited: DayTimes;
    removed: DayTimes;
    timezone: USATimezone;
    chairId: string;
  }
>(
  "availabilities/addAvailability",
  async (
    changes: {
      edited: DayTimes;
      removed: DayTimes;
      timezone: USATimezone;
      chairId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await AvailabilityService.addAvailability(changes);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);
export const addBulkAvailability = createAsyncThunk<
  Partial<IAppointment>,
  {
    edited: DayTimes;
    removed: DayTimes;
    timezone: USATimezone;
    chairId: string;
    repeatUntil: moment.Moment[];
  }
>(
  "availabilities/addBulkAvailability",
  async (
    changes: {
      edited: DayTimes;
      removed: DayTimes;
      timezone: USATimezone;
      chairId: string;
      repeatUntil: moment.Moment[];
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await AvailabilityService.createBulkAvailability(changes);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const removeAvailability = createAsyncThunk<
  Partial<Availability>,
  DayTimes
>(
  "availabilities/removeAvailability",
  async (dayTimes: DayTimes, { rejectWithValue }) => {
    try {
      const res = await AvailabilityService.removeAvailability(dayTimes);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const getAvailabilities = createAsyncThunk<Partial<IAppointment>[]>(
  "availabilities/getAvailabilities",
  async (_, { rejectWithValue }) => {
    try {
      const res = await AvailabilityService.getAvailabilities();
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

const availabilitySlice = createSlice({
  name: "availabilities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addAvailability.fulfilled, (state, action) => {
        if (state.availabilities) {
          let availabilities =
            state.availabilities.slice() as Partial<Availability>[];
          const { edited, removed } = action.payload as {
            edited: Partial<Availability>[];
            removed: Partial<Availability>[];
          };
          const availabilitiesIds = state.availabilities.map((av) => av._id);
          const removedIds = removed.map((av) => av._id);
          const editedIds = edited.map((av) => av._id);

          // Removing availabilities
          availabilities = availabilities.filter(
            (av) => !removedIds.includes(av._id)
          );

          // Updating edited availabilities
          availabilities = availabilities.map((av) => {
            if (editedIds.includes(av._id)) {
              const availability = edited.find((x) => x._id === av._id);
              if (availability) {
                return availability;
              }
            }
            return av;
          });

          // Adding new availabilities
          editedIds.forEach((id) => {
            if (id && !availabilitiesIds.includes(id)) {
              const availability = edited.find((x) => x._id === id);
              if (availability) {
                availabilities.push(availability);
              }
            }
          });

          state.availabilities = availabilities as any;
        } else {
          state.availabilities = [action.payload as any];
        }
        state.errors = null;
      })
      .addCase(addBulkAvailability.fulfilled, (state, action) => {
        if (state.availabilities) {
          let availabilities =
            state.availabilities.slice() as Partial<Availability>[];
          const { edited, removed } = action.payload as {
            edited: Partial<Availability>[];
            removed: Partial<Availability>[];
          };
          const availabilitiesIds = state.availabilities.map((av) => av._id);
          const removedIds = removed.map((av) => av._id);
          const editedIds = edited.map((av) => av._id);

          // Removing availabilities
          availabilities = availabilities.filter(
            (av) => !removedIds.includes(av._id)
          );

          // Updating edited availabilities
          availabilities = availabilities.map((av) => {
            if (editedIds.includes(av._id)) {
              const availability = edited.find((x) => x._id === av._id);
              if (availability) {
                return availability;
              }
            }
            return av;
          });

          // Adding new availabilities
          editedIds.forEach((id) => {
            if (id && !availabilitiesIds.includes(id)) {
              const availability = edited.find((x) => x._id === id);
              if (availability) {
                availabilities.push(availability);
              }
            }
          });

          state.availabilities = availabilities as any;
        } else {
          state.availabilities = [action.payload as any];
        }
        state.errors = null;
      })
      .addCase(getAvailabilities.fulfilled, (state, action) => {
        state.availabilities = action.payload as any;
        state.errors = null;
      })
      .addCase(removeAvailability.fulfilled, (state, action) => {
        if (state.availabilities) {
          let availabilities =
            state.availabilities.slice() as Partial<Availability>[];
          const incomingAvailabilitiesIds = (
            action.payload as Partial<Availability>[]
          ).map((av) => av._id);
          availabilities.filter((av) => {
            return !incomingAvailabilitiesIds.includes(av._id);
          });
          state.availabilities = availabilities as any;
          state.errors = null;
        }
      });
  },
});

export default availabilitySlice.reducer;
