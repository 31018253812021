import React, { useEffect, useRef, useState } from "react";
import { ISalon } from "../../models/SalonX";
import { useUser } from "../../hooks/UseUser";
import { isUserProfessional } from "../../models/User";
import { useDispatch, useSelector } from "react-redux";
import {
  ManagerState,
  selectManagerBySalonId,
  selectManagersWithoutSalon,
} from "../../redux/slices/managerSlice";
import ManageSalon from "./ManageSalon";
import ModalLoader from "../loader/ModalLoader";
import { InputBoolean } from "../input";
import Form from "react-bootstrap/Form";
import CustomDropdownPrice from "../promotion/CustomDropdownPrice";
import { percentageValues } from "../../utils/data";
import { useLatriceUser } from "../../hooks/useLatriceUser";
import toast from "react-hot-toast";
import { AdminSettingService } from "../../services/AdminSettingService";

type PromotionReferralLinkSettingProps = {};

const PromotionReferralLinkSetting: React.FC<
  PromotionReferralLinkSettingProps
> = ({}) => {
  const dispatch = useDispatch();
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const LatriceUser = useLatriceUser();

  const [loading, setLoading] = useState(false);
  const [searchPercentage, setSearchPercentage] = useState<string>(
    LatriceUser ? LatriceUser.coupon!!.percent.toString() : ""
  );
  const [data, setData] = useState<{
    activatePromotion: boolean | undefined;
    percentage: number | undefined;
    nbPerson: number | undefined;
    validityDate: string | undefined;
  }>({
    activatePromotion: LatriceUser ? LatriceUser.coupon?.actived : false,
    percentage: LatriceUser ? LatriceUser.coupon?.percent : 0,
    nbPerson: LatriceUser ? LatriceUser.coupon?.quota_max : 0,
    validityDate: undefined,
  });
  const renderPercentage = (item: any) => <span>{item.value}</span>;

  const keyExtractor = (item: any) => item.id;

  // const latriceUser =useLatriceUser()

  const handleChangeStatus = () => {
    setData((prevData) => ({
      ...prevData,
      activatePromotion: !prevData.activatePromotion,
    }));
  };

  const handlePercentageChange = (percentage: number) => {
    setData((prevData) => ({
      ...prevData,
      percentage: percentage,
    }));
  };

  const handleChangeNumberSponsor = (nbPerson: number) => {
    setData((prevData) => ({
      ...prevData,
      nbPerson: nbPerson,
    }));
  };

  const handleSendData = async () => {
    console.log("sending data", data);
    setLoading(true);

    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute("disabled", "true");
      await AdminSettingService.referralLinkPromotion(data)
        .then(async (result: any) => {
          toast.success("Update with success");
          console.log("The result", result);
        })
        .catch((err: any) => {
          toast.error(
            "An error occured during edition, please try egain later"
          );
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            if (submitBtnRef.current) {
              submitBtnRef.current.removeAttribute("disabled");
            }
          }, 2000);
        });
    }
  };

  return (
    <div
      className="row m-0 mt-3 flex-column "
      style={{ border: "2px solid black" }}
    >
      {loading && <ModalLoader />}
      <div className="col-sm bg-white p-3 pt-4 mt-3 ">
        <h2 className=" fw-bold text-black fs-5">Promotion on referral link</h2>

        <div className=" my-3">
          <i className="fa-solid fa-pen text-muted"></i>
          <span className="ms-2 text-muted ">
            Modify promotional information on referral link
          </span>
        </div>
        <div className="d-flex gap-4 mt-3 flex-wrap ">
          <div className=" gap-1 d-flex align-items-start flex-column ">
            <div className=" d-flex ">
              <span className="text-black">Desactivate this promotion</span>
            </div>
            <div className=" d-flex flex-column w-100 mt-3">
              <Form.Check
                type="switch"
                className="mark-button"
                label=""
                onClick={handleChangeStatus}
                checked={data.activatePromotion}
              />
            </div>
          </div>
        </div>

        <div className="d-flex gap-4 mt-3  ">
          <div className=" gap-1 d-flex align-items-start flex-column ">
            <div className=" d-flex ">
              <span className="text-black">
                Change promotion percentage <br /> on newcomer
              </span>
            </div>

            <div className="mt-3">
              <CustomDropdownPrice
                searchItem={searchPercentage}
                setSearchItem={setSearchPercentage}
                items={percentageValues}
                onChange={(item) => {
                  if (typeof item === "string") {
                    handlePercentageChange(Number(item));

                    return item;
                  }
                  const percentage = parseFloat(item.value.replace("%", ""));
                  console.log("here is the percentage", percentage);
                  handlePercentageChange(percentage);
                }}
                keyExtractor={keyExtractor}
                render={renderPercentage}
                placeholder="Select or enter a percentage"
                name="percentage"
                type="number"
              />
            </div>
          </div>
          <div className=" gap-1 d-flex align-items-start flex-column ">
            <div className=" d-flex ">
              <span className="text-black">
                Change the number of people <br /> to sponsor
              </span>
            </div>

            <div className="mt-3">
              <input
                autoComplete="off"
                // onBlur={handleBlur}
                // onFocus={handleFocus}
                type="number"
                className="w-100 border border-1 rounded-2 p-3 h-100 fs-6"
                onChange={(e) => {
                  const nbPerson = parseInt(e.target.value);
                  handleChangeNumberSponsor(nbPerson);
                }}
                value={data.nbPerson}
                placeholder="Change de service number"
                name="nbrService"
                id="nbrService"
                min="0"
                disabled={false}
              />
            </div>
          </div>
        </div>
        {/* <div className="d-flex gap-4 mt-3  ">
          <div className=" gap-1 d-flex align-items-start flex-column ">
            <div className=" d-flex ">
              <span className="text-black">Change reduction percentage</span>
            </div>

            <div className="mt-3">
              <CustomDropdownPrice
                searchItem={searchPercentage}
                setSearchItem={setSearchPercentage}
                items={percentageValues}
                onChange={(item) => {
                  if (typeof item === "string") {
                    handlePercentageChange(Number(item));

                    return item;
                  }
                  const percentage = parseFloat(item.value.replace("%", ""));
                  console.log("here is the percentage", percentage);
                  handlePercentageChange(percentage);
                }}
                keyExtractor={keyExtractor}
                render={renderPercentage}
                placeholder="Select or enter a percentage"
                name="percentage"
                type="number"
              />
            </div>
          </div>
        </div> */}

        <div className="d-flex justify-content-between align-items-center mt-5">
          <span className="">Lorem ipsum, dolor sit amet consectetur</span>
          <button
            disabled={false}
            type="submit"
            ref={submitBtnRef}
            onClick={handleSendData}
            className="button-57 rounded  px-3 p-2"
          >
            <span>Done</span>
            <span>Save changes</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromotionReferralLinkSetting;
