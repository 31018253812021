import "../../styles/HorizontaMenu.css";

interface DataMenu {
  title: string;
  image: string;
}

interface HorizontalMenuProps {
  items: DataMenu[];
  active: number;
  onItemClick: (a: any, b: number) => void;
}

export const HorizontalMenu = ({
  items,
  active = 0,
  onItemClick,
}: HorizontalMenuProps) => {
  return (
    <div className=" w-100 d-flex justify-content-center align-content-center  gap-3 ">
      {items.map((card, key) => {
        return (
          <div
            key={key}
            onClick={() => {
              onItemClick(card, key);
            }}
            className={
              "card-container-menu " + (key === active ? "active" : "")
            }
          >
            <div className="card-image">
              <img src={card.image} alt={card.title} />
            </div>
            <span className=" card-image-span"> {card.title}</span>
          </div>
        );
      })}
    </div>
  );
};
