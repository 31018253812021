import React from "react";

type Props = {
  inputClassName?: string;
  btnBorder?: string;
  className?: string;
  placeholder: string;
  searchItem: string | null;
  setSearchItem: (value: string) => void;
};

const SearchInput = (props: Props) => {
  return (
    <div
      className={`px-0 d-flex m-0 input-group align-items-center ${
        props.className ?? ""
      }`}
      aria-autocomplete="none"
    >
      <button
        style={{
          backgroundColor: "white",
          height: "45px",
          zIndex: "1",
          borderRadius: props.btnBorder,
        }}
        className="btn border-0"
        type="button"
      >
        <i className="fa-solid fa-search"></i>
      </button>
      <input
        value={props.searchItem ?? ""}
        onChange={(e) => props.setSearchItem(e.target.value)}
        placeholder={props.placeholder}
        type="text"
        className={`${props.inputClassName} form-control border-0 no-decoration fw-500`}
      />
    </div>
  );
};

export default SearchInput;
