import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import SpinnerLoader from "../loader/SpinnerLoader";
import { toast } from "react-hot-toast";
import { MemberType } from "../validation/FamilyFriendValidations";

type IRejectBookRequest = {
  show: boolean;
  onReject: () => void;
  onClose: () => void;
};
const RejectBookRequest: React.FC<IRejectBookRequest> = ({
  show,
  onReject,
  onClose,
}) => {
  const handleReject = () => {
    onReject();
    onClose();
  };

  return (
    <Modal
      style={{ backdropFilter: "blur(2px)" }}
      backdrop="static"
      onHide={onClose}
      show={show}
      centered
    >
      {/* {loading && <SpinnerLoader />} */}
      <Modal.Header closeButton>
        <Modal.Title> Reject this book request</Modal.Title>
      </Modal.Header>
      <Modal.Body className="container my-2 p-lg-0">
        <p className=" ">
          Do you really want to Reject the request for the appointment ?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleReject} variant="outline-dark" className="w-25">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectBookRequest;
