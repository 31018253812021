import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import MemberType from "../../models/Member";
import { MemberService } from "../../services/MemberService";

export interface MemberState {
  members: any;
  errors: any;
}

const initialState: MemberState = {
  members: null,
  errors: null,
};

// export const getAllMembers = createAsyncThunk<MemberType>(
//   "members/getAllMembers",
//   async () => {
//     const res = await MemberService.getMembers();
//     console.log("redux members data", res.data);
//     return res.data;
//   }
// );
export const getMember = createAsyncThunk<MemberType>(
  "myMember/getMember",
  async () => {
    const res = await MemberService.getMember();
    console.log("redux members data on self account", res.data);
    return res.data;
  }
);
export const acceptMember = createAsyncThunk<any, any>(
  "myMember/acceptMember",
  async (member) => {
    const res = await MemberService.acceptMember(member);
    console.log("redux members data on self account", res.data);
    return res.data;
  }
);
export const rejectMember = createAsyncThunk<any, any>(
  "myMember/rejectMember",
  async (member) => {
    const res = await MemberService.rejectMember(member);
    console.log("redux members data on self account", res.data);
    return res.data;
  }
);

export const getMembernames = createAsyncThunk<MemberType>(
  "myMember/getMemberNames",
  async () => {
    const res = await MemberService.getMemberNames();
    console.log("redux members data on self account", res.data);
    return res.data;
  }
);
export const addMember = createAsyncThunk<any, any>(
  "myMember/addMember",
  async (member, { rejectWithValue }) => {
    try {
      const res = await MemberService.createMember(member);
      return res;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const deleteMember = createAsyncThunk<any, any>(
  "myMember/deleteMember",
  async (member, { rejectWithValue }) => {
    try {
      const res = await MemberService.deleteMember(member);
      return res;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const sendAppointmentRequest = createAsyncThunk<any, any>(
  "myMember/sendAppointment",
  async (data, { rejectWithValue }) => {
    try {
      const res = await MemberService.sendAppointmentRequest(data);
      return res;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);
export const acceptRequestAppointment = createAsyncThunk<any, any>(
  "myMember/acceptRequestAppointment",
  async (data, { rejectWithValue }) => {
    try {
      const res = await MemberService.acceptRequestAppointment(data);
      return res;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);
export const rejectRequestAppointment = createAsyncThunk<any, any>(
  "myMember/rejectRequestAppointment",
  async (data, { rejectWithValue }) => {
    try {
      const res = await MemberService.rejectRequestAppointment(data);
      return res;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

const memberSlice = createSlice({
  name: "myMember",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMember.fulfilled, (state, action) => {
        state.members = action.payload;
        state.errors = null;
      })
      .addCase(addMember.fulfilled, (state, action) => {
        // state.members = action.payload;
        state.errors = null;
      })
      .addCase(getMembernames.fulfilled, (state, action) => {
        state.members = action.payload;
        state.errors = null;
      })
      .addCase(acceptMember.fulfilled, (state, action) => {
        state.members.find((member: MemberType) => {
          if (member._id === action.payload.data._id) {
            console.log("member with the same id", member);
            member.status = action.payload.data.status;
            console.log("member after editing", member);
          }
        });
        state.errors = null;
      })
      .addCase(rejectMember.fulfilled, (state, action) => {
        state.members.find((member: MemberType) => {
          if (member._id === action.payload.data._id) {
            console.log("member with the same id", member);
            member.status = action.payload.data.status;
            console.log("member after editing", member);
          }
        });
        state.errors = null;
      })
      .addCase(sendAppointmentRequest.fulfilled, (state, action) => {
        state.errors = null;
      })
      .addCase(acceptRequestAppointment.fulfilled, (state, action) => {
        console.log("all my member", state.members);
        console.log(
          "action.payload in reject request appointment",
          action.payload
        );

        state.members.find((member: any) => {
          if (member._id === action.payload.data._id) {
            console.log("member with same id", member);
            member.appoitmentRequestData =
              action.payload.data.appoitmentRequestData;
            console.log("member after editing", member);
          }
        });
        state.errors = null;
      })
      .addCase(rejectRequestAppointment.fulfilled, (state, action) => {
        console.log("all my member", state.members);
        console.log(
          "action.payload in reject request appointment",
          action.payload
        );

        state.members.find((member: any) => {
          if (member._id === action.payload.data._id) {
            console.log("member with same id", member);
            member.appoitmentRequestData =
              action.payload.data.appoitmentRequestData;
            console.log("member after editing", member);
          }
        });

        state.errors = null;
      });
  },
});

export const selectMembers = (state: { members: MemberState }) => state.members;

export default memberSlice.reducer;
