import { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useFormik } from "formik";

import {
  initialValues,
  validationSchema,
} from "../../validation/RegisterValidation";
import { AxiosResponse } from "axios";
import User, { IUserRegistrationPro, TAccountType } from "../../../models/User";
import { AuthService } from "../../../services/AuthService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSuitcase,
  faCircleInfo,
  faTriangleExclamation,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../modal/Modal";
import { toBeRequired } from "@testing-library/jest-dom/matchers";
import PasswordErrors from "../../modal/PasswordErrors";
import PhoneNumberValidation from "../../reusable/PhoneNumberValidation ";

interface RegisterProStep0Props {
  onNextStep: () => void;
  onPrevStep?: () => void;
}

const RegisterProStep0 = ({
  onNextStep,
  onPrevStep,
}: RegisterProStep0Props): JSX.Element => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const [showModalInfo, setShowModalInfo] = useState<boolean | null>(false);
  const [show, setShow] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Here is the data of form", values);
    },
  });

  const formSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0) {
      handleSubmit(e);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (submitBtnRef.current && accountType) {
      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = "loading...";

      const user: IUserRegistrationPro = {
        first_name: formik.values.firstname as string,
        last_name: formik.values.lastname as string,
        email: formik.values.email as string,
        phone: formik.values.phone as string,
        password: formik.values.password as string,
        account_type: accountType,
        pro: true,
      };

      AuthService.registerPro(user)
        .then((response: AxiosResponse) => {
          // Handle the resolved value (response) here
          if (response?.headers?.authorization) {
            const authHeader = response.headers.authorization as string;
            const authHeaderToken = authHeader.replace("Bearer ", "");
            localStorage.setItem("password", user.password);
            localStorage.setItem("email", user.email);
            localStorage.setItem("authToken", authHeaderToken);
            localStorage.setItem("salonId", response.data.salonId);
            localStorage.setItem("accountType", user.account_type);
            localStorage.setItem(
              "lastSendBackOTPDate",
              `${Math.floor(Date.now() / 1000)}`
            );

            onNextStep();
          }
        })
        .catch((error: any) => {
          // Handle any errors that occurred during registration
          console.log(error);
          if (error && error.response && error.response.data) {
            formik.setErrors(error.response.data.errors);
          }
          if (submitBtnRef.current) {
            submitBtnRef.current.setAttribute("disabled", "true");
          }
        })
        .finally(() => {
          if (submitBtnRef.current) {
            submitBtnRef.current.removeAttribute("disabled");
            submitBtnRef.current.innerHTML = "Next step";
          }
        });
    }
  };

  const [accountType, setAccountType] = useState<TAccountType | null>(null);

  const [showModal, setShowModal] = useState(false);
  const [showbtn, setShowBtn] = useState(false);
  const passwordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    handleDisplayError();
  }, [passwordRef]);

  const handleDisplayError = () => {
    if (
      (formik.touched.password && formik.errors.password) ||
      (formik.touched.confirmPassword && formik.errors.confirmPassword)
    ) {
      setShow(true);
      setShowBtn(!showbtn);
    }
  };

  const handleSelectAccountType = (type: typeof accountType) => {
    if (type) {
      setAccountType(type);
      setTimeout(() => {
        setShowModal(true);
      }, 3500);
    }
  };

  const handleOpenModalInfo = () => {
    setShowModalInfo(true);
  };

  const isFormValid:boolean = formik.isValid && Object.keys(formik.touched).length>0 && termsAccepted

  return (
    <>
      <form onSubmit={formSubmit} className="w-100">
        {!accountType ? (
          <div className="account-type-selector d-flex flex-column align-items-center gap-4">
            <div className="w-100 text-center">
              Which Kind Of Account Do You Want To Create ?{" "}
              <button
                className="btn-info"
                onClick={() => handleOpenModalInfo()}
              >
                {" "}
                <FontAwesomeIcon icon={faCircleInfo} color="#e10984" />
              </button>
            </div>
            <div className="d-flex flex-column flex-md-row w-100 gap-2">
              <button
                onClick={() => handleSelectAccountType("business_owner")}
                className="account-type-selector__action"
              >
                <FontAwesomeIcon icon={faSuitcase} />
                <span>business owner</span>
              </button>
              <button
                onClick={() => handleSelectAccountType("independent")}
                className="account-type-selector__action"
              >
                <FontAwesomeIcon icon={faUserTie} />
                <span>independent</span>
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div
              className="alert alert-primary d-flex align-items-center gap-2"
              role="alert"
            >
              <FontAwesomeIcon icon={faTriangleExclamation} />
              <p className="mb-0">
                You are going to create an account as
                {accountType === "independent" ? " an " : " a "}
                <span
                  onClick={() => setAccountType(null)}
                  className="text-underline account-type-selector__change-btn"
                >
                  {accountType}
                </span>
                .
              </p>
            </div>
          </div>
        )}
        <div
          style={{ maxHeight: `${accountType ? "100vh" : "0px"}` }}
          className="register-form-container"
        >
          <div className="d-flex flex-column">
            <div className="form-floating mt-2">
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                required
                type="email"
                className="form-control fw-500"
                id="email"
                name="email"
              />
              <label htmlFor="email">Email address</label>
            </div>
            {formik.touched.email && formik.errors.email ? (
              <div style={{ color: "var(--primaryColor)" }}>
                {formik.errors.email}
              </div>
            ) : null}
            <div className="row">
              <div className="col-6">
                <div className="input-group mt-2">
                  <input
                    placeholder="Owner's first name*"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstname}
                    required
                    type="text"
                    className="form-control fw-500"
                    id="firstname"
                    name="firstname"
                  />
                </div>
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div style={{ color: "var(--primaryColor)" }}>
                    {formik.errors.firstname}
                  </div>
                ) : null}
              </div>
              <div className="col-6">
                <div className="input-group mt-2">
                  <input
                    placeholder="Owner's last name*"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastname}
                    required
                    type="text"
                    className="form-control fw-500"
                    id="lastname"
                    name="lastname"
                  />
                </div>
                {formik.touched.lastname && formik.errors.lastname ? (
                  <div style={{ color: "var(--primaryColor)" }}>
                    {formik.errors.lastname}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="input-group my-3" aria-autocomplete="none">
              <PhoneNumberValidation
                onChangeValue={(value: string) => {
                  formik.setFieldValue("phone", value);
                  console.log("value", value);
                }}
              />
            </div>
            {formik.touched.phone && formik.errors.phone ? (
              <div style={{ color: "var(--primaryColor)" }}>
                {formik.errors.phone}
              </div>
            ) : null}

            <div className="input-group my-2" aria-autocomplete="none">
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                required
                type={passwordVisible ? "text" : "password"}
                className="form-control fw-500"
                id="password"
                placeholder="password*"
                name="password"
                ref={passwordRef}
              />
              <button
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="btn"
                type="button"
                id="togglePasswordVisibility"
              >
                <i
                  className={passwordVisible ? "fa fa-eye-slash" : "fa fa-eye"}
                ></i>
              </button>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div style={{ color: "var(--primaryColor)" }}>
                {formik.errors.password}
                <PasswordErrors
                  message={formik.errors.password}
                  open={!show}
                  onClose={() => setShow(false)}
                />
              </div>
            ) : null}
            <div className="input-group my-2" aria-autocomplete="none">
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                required
                type={confirmPasswordVisible ? "text" : "password"}
                className="form-control fw-500"
                id="confirmPassword"
                placeholder="confirm password*"
                name="confirmPassword"
              />
              <button
                onClick={() =>
                  setConfirmPasswordVisible(!confirmPasswordVisible)
                }
                className="btn"
                type="button"
                id="togglePasswordVisibility"
              >
                <i
                  className={
                    confirmPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"
                  }
                ></i>
              </button>
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div style={{ color: "var(--primaryColor)" }}>
                {formik.errors.confirmPassword}
              </div>
            ) : null}
            <div className="form-group form-check">
              <input
                required
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                By clicking create an account, I agree I have read and accepted
                the Terms of Use and Privacy policy.
              </label>
            </div>
            <div className="small mt-3">
              Protected by reCAPTCHA and subject to the{" "}
              <NavLink
                className="nav-link nav-link-underline-hover active nav-link-inline"
                to={"/forgot-password"}
              >
                Latrice privacy policy
              </NavLink>{" "}
              and{" "}
              <NavLink
                className="nav-link nav-link-inline nav-link-underline-hover active"
                to={"/forgot-password"}
              >
                Terms of service.
              </NavLink>
            </div>
            <div className="d-flex justify-content-end align-items-end mt-4">
              <div>
                <button
                  ref={submitBtnRef}
                  type="submit"
                  className="btn btn-primary px-4"
                  onClick={() => handleDisplayError}
                  disabled={!isFormValid}
                >
                  Next step
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>

      {showModalInfo && (
        <CustomModal
          open={showModalInfo}
          onClose={() => setShowModalInfo(false)}
          onOpen={() => setShowModalInfo(true)}
          className=" "
        >
          <div className="px-5 w-100 h-auto d-flex flex-row justify-content-start align-items-center">
            <div className="info_img_container">
              <img
                src={require("../../../assets/images/info.png")}
                alt=""
                className=" info_img "
              />
            </div>
            <div>
              <span className="font-light">Explanation</span>
              <h1 className=" font-weight-bold small-text">
                Different types of account
              </h1>
            </div>
          </div>

          <div
            style={{ maxHeight: "500px ", marginTop: "30px" }}
            className="px-5  mt-10 bg-red text-black"
          >
            <div
              style={{ minHeight: "100px " }}
              className="    d-flex justify-content-center gap-2 align-items-center"
            >
              <div
                style={{ paddingTop: "20px" }}
                className="  dot_info_container    d-flex justify-content-center align-items-start"
              >
                <span className=" dot_info" />
              </div>
              <div className=" explain_info_container ">
                <h1 className="  small-text fw-bold text-black ">
                  Business Owner
                </h1>

                <p className="small  text-black fw-400">
                  With a business account, you can publish your services, but
                  you can also add as many salons as you like.
                </p>
              </div>
            </div>
            <div
              style={{ minHeight: "100px " }}
              className="    d-flex justify-content-center gap-2 align-items-center"
            >
              <div
                style={{ paddingTop: "20px" }}
                className="  dot_info_container    d-flex justify-content-center align-items-start"
              >
                <span className=" dot_info" />
              </div>
              <div className=" explain_info_container ">
                <h1 className=" fw-bold  small-text text-black ">
                  Independent account
                </h1>

                <p className="small  text-black fw-400">
                  The independent account is linked to a person. They can
                  publish their services like anyone else, but can only own one
                  salon.
                </p>
              </div>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default RegisterProStep0;
