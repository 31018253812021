import * as Yup from "yup";
import "yup-phone-lite"; //adds phone methos to yup

const phoneRegExp = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

export interface ProfileValues {
  email: string;
  first_name: string;
  last_name: string;
  account_type?: string;
  speciality: string;
  bio: string;
  phone: string;
}

export interface BusinessInfoValues {
  email: string;
  personal_number: string;
  phone_number: string;
  number_shown: string;
  serviceRadius?: string;
  salon_number?: string;
  salon_location?: string;
  location?: string;
  lat?: number;
  lng?: number;
}

export const businessInfoInitialValues = {
  name: "",
  bio: "",
  phone_number: "",
  personal_number: "",
};

export const validationSchema = Yup.object({
  //   email: Yup.string().email().required("Required"),
  first_name: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Required"),

  last_name: Yup.string()
    .max(20, "Must be 20 characters or less")
    .required("Required"),
  // bio: Yup.string().required("Required"),
  phone: Yup.string().matches(phoneRegExp, 'Invalid phone number').required("A phone number is required"),
});

export const businessValidationSchema = Yup.object({
  name: Yup.string().optional().max(30, "Must be 30 characters or less"),
  bio: Yup.string().optional().max(350, "Must be 30 characters or less"),
  personal_number: Yup.string()
    .nullable()
    .optional()
    .matches(phoneRegExp, 'Invalid phone number'),

  phone_number: Yup.string()
    .nullable()
    .optional()
    .matches(phoneRegExp, 'Invalid phone number'),
});
