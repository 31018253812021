import React from "react";
import "../styles/RecentChat.css";
import CertificationBadge from "./CertificationBadge";

export interface LatriceRecentChatProps {
  lastMessage: string;
  unread: number;
  time: string;
  onClick: (e: any) => void;
}

const LatriceRecentChat: React.FC<LatriceRecentChatProps> = ({
  lastMessage,
  unread,
  time,
  onClick,
}) => {
  return (
    <div onClick={onClick} className="rce-citem">
      <div className="rce-citem-avatar">
        <div className="rce-avatar-container circle large">
          <img
            alt="kursat_avatar"
            src={require("../../assets/images/latrice.png")}
            className="rce-avatar"
          />
        </div>
      </div>
      <div className="rce-citem-body">
        <div className="rce-citem-body--top">
          <div className="rce-citem-body--top-title d-flex gap-1 fw-500">
            Latrice <CertificationBadge />
          </div>
          <div className="rce-citem-body--top-time">{time}</div>
        </div>
        <div className="rce-citem-body--bottom">
          <div className="rce-citem-body--bottom-title">{lastMessage}</div>
          <div className="rce-citem-body--bottom-tools"></div>
          <div className="rce-citem-body--bottom-tools-item-hidden-hover"></div>
          <div className="rce-citem-body--bottom-status">
            <span>1</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatriceRecentChat;
