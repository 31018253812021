import React, { CSSProperties, useState } from "react";
import MapSelection, { MapSelectionProps } from "../map/MapSelection";
import User from "../../models/User";
import { ISalon } from "../../models/SalonX";

export interface LocationProps extends MapSelectionProps {
  user: User;
  salon: ISalon | null;
  showRadius?: boolean;
  containerStyle: CSSProperties;
  onLocationSelect: (
    latLng: { lat: number; lng: number },
    address: string
  ) => void;
}

const Location: React.FC<LocationProps> = ({
  defaultLocation,
  onLocationSelect,
  user,
  salon,
  containerStyle,
  showRadius = true,
  ...props
}) => {
  const defaultPosition = {
    center: {
      lat: 7.369722,
      lng: 12.35472,
    },
    zoom: 11,
  };
  const [areaRadius, setAreaRadius] = useState<string | null>("1");
  const [address, setAddress] = useState<string | null>(null);
  const [position, setPosition] = useState<typeof defaultPosition | null>(null);

  const handleLocationSelect = (
    latLng: { lat: number; lng: number },
    address: string
  ) => {
    setPosition((pos: any) => {
      return { ...pos, center: latLng, zoom: 9 };
    });
    onLocationSelect(latLng, address);
    setAddress(address);
  };

  return (
    <div className="Location">
      <MapSelection
        {...(defaultLocation
          ? {
              defaultLocation: defaultLocation,
              defaultAddress: salon?.address as string,
            }
          : {})}
        containerStyle={{ height: "50vh" }}
        onLocationSelect={handleLocationSelect}
        showRadius={showRadius}
        radius={"1"}
        onRadiusChange={setAreaRadius}
      />
    </div>
  );
};

export default Location;
