import { io } from "socket.io-client";

const URL = process.env.REACT_APP_SOCKET_BASE_URL as string;

export const socket = () => io(`${URL}`, {
    auth: {
        token: `${localStorage.getItem('authToken')}`,
        salonId: `${localStorage.getItem('salonId')}`
    }
});

// Add an event listener for the 'connect' event
socket().on("connect", () => {
    console.log("Socket connected");
});

// Add an event listener for the 'disconnect' event
socket().on("disconnect", () => {
    console.log("Socket disconnected");
});

export default socket;