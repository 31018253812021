import { navigationLinks3, restrictUserRoutes } from ".";
import { useUser } from "../../hooks/UseUser";
import { isUserProfessional } from "../../models/User";
import NavigationLink from "./NavigationLink";

const NavbarSearch = () => {
  const user = useUser();

  return (
    <div className="navbar-nav ml-md-auto justify-content-center">
      {restrictUserRoutes(navigationLinks3, isUserProfessional(user)).map(
        (link, index) => (
          <NavigationLink
            key={index}
            className="nav-link text-white mx-lg-3"
            to={link.to}
            label={link.label}
          />
        )
      )}
    </div>
  );
};

export default NavbarSearch;
