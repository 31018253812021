import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useUser } from "../../hooks/UseUser";
import DropDown from "../reusable/DropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCalendar,
  faCalendarCheck,
  faClock,
  faComment,
  faCommentDots,
  faDashboard,
  faLifeRing,
  faPeopleGroup,
  faQuestionCircle,
  faSignOut,
  faSyncAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { useSalon } from "../../context/SalonContext";
import SwitchSalonModal from "../reusable/SwitchSalonModal";
import {
  isUserBusinessOwner,
  isUserIndependent,
  isUserManager,
} from "../../models/User";
import NavigationLink from "./NavigationLink";

type UserOptionsProps = {
  handleLogout: (value: any) => void;
  className?: string;
};

const UserOptions: React.FC<UserOptionsProps> = ({
  handleLogout,
  className,
}) => {
  const navigate = useNavigate();

  const user = useUser();
  const { salon } = useSalon();
  const isProfessional =
    isUserManager(user) || isUserIndependent(user) || isUserBusinessOwner(user);

  const filteredItemsForProfessional = isProfessional
    ? [
        <div
          key={uuidv4()}
          onClick={() => navigate("/business-profile")}
          className="d-flex gap-2 align-items-center"
        >
          <FontAwesomeIcon icon={faBuilding} />
          <span>{user?.professional && "Business Profile"}</span>
        </div>,

        <div
          key={uuidv4()}
          onClick={() => navigate("/admin/manage-appointment")}
          className="d-flex gap-2 align-items-center"
        >
          <FontAwesomeIcon icon={faCalendarCheck} />
          <span style={{ whiteSpace: "nowrap" }}>SET APPOINTMENTS</span>
        </div>,
        <div
          key={uuidv4()}
          onClick={() => navigate("/manage-availability")}
          className="d-flex gap-2 align-items-center"
        >
          <FontAwesomeIcon icon={faClock} />
          <span>Availability</span>
        </div>,
      ]
    : [];

  const filteredItemsForIndependent = isUserIndependent(user)
    ? [
        <div>
          <div
            key={uuidv4()}
            onClick={() => navigate("/independent/dashboard")}
            className="d-flex gap-2 align-items-center"
          >
            <FontAwesomeIcon icon={faDashboard} />
            <span>Service setting</span>
          </div>
        </div>,
      ]
    : [];

  const filteredItemsForBusinessOwner = isUserBusinessOwner(user)
    ? [
        <div>
          <div
            key={uuidv4()}
            onClick={() => handleSwitchSalon()}
            className="d-flex gap-2 align-items-center"
          >
            <FontAwesomeIcon icon={faSyncAlt} />
            <span>Switch Salon</span>
          </div>
        </div>,
        <div>
          <div
            key={uuidv4()}
            onClick={() => navigate("/admin/dashboard")}
            className="d-flex gap-2 align-items-center"
          >
            <FontAwesomeIcon icon={faDashboard} />
            <span>Admin Dashboard</span>
          </div>
        </div>,
        <div
          key={uuidv4()}
          onClick={() => navigate("/admin/managers")}
          className="d-flex gap-2 align-items-center"
        >
          <FontAwesomeIcon icon={faPeopleGroup} />
          <span>Managers </span>
        </div>,
      ]
    : [];

  const items = [
    <div key={uuidv4()} className="d-flex flex-column user_account gap-2">
      <div className="account_info rounded p-2">
        <div className="small-text">Currently in</div>
        <div className="small-text fw-bold">
          {user?.first_name} {user?.last_name}
        </div>
        <div className="small-text fst-italic">{user?.email}</div>
      </div>
    </div>,
    <div
      key={uuidv4()}
      onClick={() => navigate("/client-profile")}
      className=" d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faUser} />
      <span>Profile</span>
    </div>,
    <div
      key={uuidv4()}
      onClick={() => navigate("/message")}
      className=" d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faCommentDots} />
      <span>Message</span>
    </div>,
    ...filteredItemsForBusinessOwner,
    <div
      key={uuidv4()}
      onClick={() => navigate("/appointments")}
      className=" d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faCalendar} />
      <span>MY BOOKINGS</span>
    </div>,
    ...filteredItemsForProfessional,
    ...filteredItemsForIndependent,
    <div
      key={uuidv4()}
      onClick={() => navigate("/help")}
      className=" d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faLifeRing} />
      <span>Get Help</span>
    </div>,

    <div
      key={uuidv4()}
      onClick={() => navigate("/support")}
      className="d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faQuestionCircle} />
      <span>Support</span>
    </div>,

    <div
      key={uuidv4()}
      onClick={handleLogout}
      className="d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faSignOut} />
      <span>Logout</span>
    </div>,
  ];

  const itemsSalon = [
    <div key={uuidv4()} className="d-flex flex-column user_account gap-2">
      <div className="account_info rounded p-2">
        <div className="small-text">Currently in</div>
        <div className="small-text fw-bold">{salon?.name}</div>
        <div className="small-text fst-italic">{salon?.email}</div>
      </div>
    </div>,
    <div
      key={uuidv4()}
      onClick={() => navigate("/client-profile")}
      className=" d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faUser} />
      <span>Profile</span>
    </div>,
    <div
      key={uuidv4()}
      onClick={() => navigate("/message")}
      className=" d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faCommentDots} />
      <span>Message</span>
    </div>,
    ...filteredItemsForBusinessOwner,
    <div
      key={uuidv4()}
      onClick={() => navigate("/appointments")}
      className=" d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faCalendar} />
      <span>MY BOOKINGS</span>
    </div>,
    ...filteredItemsForProfessional,
    <div
      key={uuidv4()}
      onClick={() => navigate("/help")}
      className=" d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faLifeRing} />
      <span>Get Help</span>
    </div>,

    <div
      key={uuidv4()}
      onClick={() => navigate("/support")}
      className="d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faQuestionCircle} />
      <span>Support</span>
    </div>,

    <div
      key={uuidv4()}
      onClick={handleLogout}
      className="d-flex gap-2 align-items-center"
    >
      <FontAwesomeIcon icon={faSignOut} />
      <span>Logout</span>
    </div>,
  ];

  const [switchSalon, setSwitchSalon] = useState(false);

  const handleSwitchSalon = () => {
    setSwitchSalon(true);
  };

  return (
    <div className="">
      <div className="d-lg-none text-white ">
        <hr />
        {isUserBusinessOwner(user) ? (
          <>
            <NavigationLink
              to={"/admin/dashboard"}
              label={"Admin Dashboard"}
              className={"nav-link"}
            />
            <NavigationLink
              to={"/admin/managers"}
              label={"Managers"}
              className={"nav-link"}
            />
            <div
              onClick={() => handleSwitchSalon()}
              className="nav-link cursor-pointer"
            >
              <span>Switch Salon</span>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="nav-link" onClick={() => navigate("/help")}>
          <span>Get Help</span>
        </div>
        <NavigationLink
          to={"/support"}
          label={"Support"}
          className={"nav-link"}
        />
        <div onClick={handleLogout} className="nav-link cursor-pointer">
          <span>Logout</span>
        </div>
      </div>
      {isUserBusinessOwner(user) ? (
        <DropDown
          addEffect={true}
          title={
            <img
              style={{ height: "25px", width: "25px", objectFit: "cover" }}
              src={user?.profile ?? require("../../assets/images/profile.jpg")}
              alt=""
              className="rounded-circle border-white border border-2 ms-2 d-none d-lg-block"
            />
          }
          closeOnClickOutside
          items={itemsSalon}
        />
      ) : (
        <DropDown
          addEffect={true}
          title={
            <img
              style={{ height: "25px", width: "25px", objectFit: "cover" }}
              src={salon?.profile ?? require("../../assets/images/profile.jpg")}
              alt=""
              className="rounded-circle border-white border border-2 ms-2 d-none d-lg-block"
            />
          }
          closeOnClickOutside
          items={items}
        />
      )}
      {/* <DropDown
        addEffect={true}
        title={
          <img
            style={{ height: "25px", width: "25px", objectFit: "cover" }}
            src={user?.profile ?? require("../../assets/images/profile.jpg")}
            alt=""
            className="rounded-circle border-white border border-2 ms-2 d-none d-lg-block"
          />
        }
        closeOnClickOutside
        items={items}
      /> */}

      {switchSalon && isUserBusinessOwner(user) && (
        <SwitchSalonModal
          salons={user.salons}
          onClose={() => setSwitchSalon(false)}
          show={switchSalon}
        />
      )}
    </div>
  );
};

export default UserOptions;
