import { ReactNode, useState, useRef } from "react";
import Button from "../Button";
import SubscriptionModal from "./SubscriptionModal";
import { SalonService } from "../../services/SalonService";
import toast from "react-hot-toast";
import ModalLoader from "../loader/ModalLoader";

export interface CardPromotionProps {
  title: string | ReactNode;
  icon?: string;
  type?: string;
  status?: "standard" | "diamond" | "gold";
  description: ReactNode;
  onSubmit?: () => void;
  setShowModal: () => void;
  activateSuscription: boolean|undefined;
}

export const CardPromotion = ({
  title,
  description,
  onSubmit,
  status,
  icon,
  type,
  setShowModal,
  activateSuscription,
}: CardPromotionProps) => {
  const handleShowModal = () => {
    console.log("test");
    setShowModal();
  };
  return (
    <div className={"card-promotion bg-white h-auto"}>
      <div className="card-first-bg">
        <div className="w-100 pt-2 ">
          {status === "standard" && <span className=" status">Standard</span>}
          {status === "diamond" && <span className=" status">diamond</span>}
          {status === "gold" && (
            <span className=" status btn-secondary text-black">
              Latrice Gold
            </span>
          )}
          <p className="title-desc">promotion available for {type}</p>
        </div>
        <div className="box-tilte-card">
          <h4 className="title-card">{title}</h4>
          <span>Per Months</span>
        </div>
      </div>
      <hr />
      <div className="w-100">
        <p className=" fw-bold ">What's include?</p>
        {description}
      </div>
      <div className="w-100">
        <button
          onClick={handleShowModal}
          className="p-2 w-100 cursor-pointer"
          style={{ cursor: "pointer", zIndex: "100" }}
        >
          {activateSuscription ? "unsubscribe" : "subscribe"}
        </button>
      </div>
    </div>
  );
};
