import React from "react";
import { Modal } from "react-bootstrap";

type ConfirmDeleteModalProps = {
  show: boolean;
  setServiceToDelete: (value: any) => void;
  handleServiceDelete: (value: any) => void;
  text1: string;
  text2: string;
  btnText1?: string;
  btnText2?: string;
};

const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  setServiceToDelete,
  show,
  handleServiceDelete,
  text1,
  text2,
  btnText1,
  btnText2,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => {
        setServiceToDelete(null);
      }}
      centered
    >
      <Modal.Body>
        <div className="d-flex flex-column gap-3 p-3 justify-content-center align-items-center">
          <div className="d-flex flex-column gap-1">
            <div className="fs-6 text-black fw-bold text-center ">{text1}</div>
            <div className="text-muted text-center ">{text2}</div>
          </div>
          <div className=" d-flex gap-3 mt-2">
            <button
              onClick={() => {
                setServiceToDelete(null);
              }}
              className="button-57 p-2 rounded px-3"
            >
              <span className="text ">Cancel</span>
              <span>Close Modal</span>
            </button>
            <button
              onClick={handleServiceDelete}
              className="button-57 p-2 rounded px-3 "
            >
              <span className="text ">{btnText1 ?? "Delete"}</span>
              <span>{btnText2 ?? "Delete Anyway"}</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDeleteModal;
