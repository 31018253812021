import React, { useRef } from "react";
import Footer from "../components/Footer";
import MainNav from "../components/navigation/MainNav";
import "../styles/Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChair,
  faEnvelope,
  faGlobe,
  faMapLocation,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import {
  ContactUsInitialValues,
  contactUsValidation,
} from "../components/validation/ContactUsValidation";
import { ContactService } from "../services/ContactService";
import toast from "react-hot-toast";

export const ContactPage = () => {
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  //formik
  const formik = useFormik({
    initialValues: ContactUsInitialValues,
    validationSchema: contactUsValidation,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      console.log(values);
    },
  });

  const formSubmit = (e: any) => {
    console.log("est test");
    e.preventDefault();
    e.stopPropagation();
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0) {
      handleSubmit(e);
      formik.resetForm();
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = "loading...";

      const data = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        phone: formik.values.phone,
        message: formik.values.message,
      };

      ContactService.AddContact(data)
        .then((res) => {
          toast.success("We'll get back to you shortly");
        })
        .catch((err) => {
          toast.error("Something went wrong. Please try again");
          console.log(err);
        })
        .finally(() => {
          if (submitBtnRef.current) {
            submitBtnRef.current.removeAttribute("disabled");
            submitBtnRef.current.innerHTML = "Submit";
          }
        });
    }
  };

  return (
    <div style={{ height: "100vh" }} className="flex flex-column">
      <MainNav navType="homeNav" />

      <div
        className="  contact_hero_section d-flex flex-row justify-content-start align-items-center"
        style={{ height: "200px", width: "100%" }}
      >
        <div className="container">
          <h1>Contact Us</h1>
        </div>
      </div>

      <div className=" contact_container container">
        <div
          className="contact_form_section"
          style={{
            display: "flex",
            height: "100%",
            minWidth: "300px",
            flexDirection: "column",
            alignItems: "start",
            backgroundColor: "",
            flexGrow: "1",
            width: "50%",
          }}
        >
          <h1 className="contact_title_1">We'd Love to Hear From You</h1>
          <p className="contact_description_1 mt-3 fw-light text-black fst-normal ">
            We are here to help and answer any question you might have. <br />{" "}
            We look forward to hearing from you.
          </p>

          <div style={{ width: "100%" }}>
            <form onSubmit={formSubmit} style={{ width: "80%" }}>
              <div
                className="gap-4 mb-2"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className=" w-100">
                  <input
                    style={{ width: "100%", maxWidth: "400px" }}
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Name"
                  />
                </div>
                <div className="w-100">
                  <input
                    style={{ width: "100%", maxWidth: "400px" }}
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="last Name"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div
                className="gap-4 mb-3"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="w-100">
                  <input
                    style={{ width: "100%", maxWidth: "400px" }}
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Email adresse"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="w-100">
                  <input
                    style={{ width: "100%", maxWidth: "400px" }}
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="Phone number"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div
                className="gap-4 mb-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="w-100">
                  <textarea
                    className="form-control w-100 "
                    placeholder="Your message"
                    style={{ height: "200px" }}
                    id="textarea"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  <div className="mt-3 ">
                    <button
                      ref={submitBtnRef}
                      className="btn btn-primary px-5 "
                      style={{ backgroundColor: "blue", color: "white" }}
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="contact_info_section"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            backgroundColor: "",
            justifyContent: "center",
            height: "100%",
            flexGrow: "1",
            width: "30%",
          }}
        >
          <div className=" " style={{ backgroundColor: "" }}>
            <h2 className="contact_title_2 pb-3">Contact Us.</h2>
            <div className="contact_barre"></div>
            <div className=" d-flex flex-column gap-2 mt-5">
              <div className="d-flex">
                <p>
                  <FontAwesomeIcon
                    style={{ color: "var(--textPrimaryColor)" }}
                    icon={faMapLocation}
                    size="1x"
                    className="text-muted"
                  />
                </p>
                <p style={{ marginLeft: "1rem" }}>
                  New york , United State of America
                </p>
              </div>
              <div className="d-flex">
                <p>
                  <FontAwesomeIcon
                    style={{ color: "var(--textPrimaryColor)" }}
                    icon={faPhone}
                    size="1x"
                    className="text-muted"
                  />
                </p>
                <p style={{ marginLeft: "1rem" }}>+1 315 638 4345</p>
              </div>
              <div className="d-flex">
                <p>
                  <FontAwesomeIcon
                    style={{ color: "var(--textPrimaryColor)" }}
                    icon={faGlobe}
                    size="1x"
                    className="text-muted"
                  />
                </p>
                <p style={{ marginLeft: "1rem" }}>www.latrice.com</p>
              </div>
              <div className="d-flex">
                <p>
                  <FontAwesomeIcon
                    style={{ color: "var(--textPrimaryColor)" }}
                    icon={faEnvelope}
                    size="1x"
                    className="text-muted"
                  />
                </p>
                <p style={{ marginLeft: "1rem" }}>sales@latrice.com</p>
              </div>
            </div>
            {/* <div className="mt-3">Button</div> */}
          </div>
        </div>
      </div>

      <div style={{}}>
        <Footer />
      </div>
    </div>
  );
};
