import {
  forwardRef,
  useState,
  useEffect,
  DOMAttributes,
  MouseEventHandler,
} from "react";
import "../../styles/InputBoolean.css";

interface InputBooleanProps {
  title: any;
  name?: string;
  value?: boolean | null;
  onChange?: (value: boolean | null) => void;
  userIsProfessional?: boolean;
}

const InputBoolean = forwardRef<HTMLDivElement, InputBooleanProps>(
  ({ title, name, value = null, onChange = null, userIsProfessional }, ref) => {
    const yesId = Math.random().toString(36).slice(2);
    const noId = Math.random().toString(36).slice(2);
    const [_value, setValue] = useState<boolean | null>(value);
    const [selected, setSelected] = useState<boolean | null>(value);

    const handleDoubleClick = (val: boolean | null) => {
      setValue(null); // Set value to null on double-click
      setSelected(null); // Set selected to null
      if (onChange) {
        onChange(null); // Trigger onChange with null
      }
    };

    const handleOnChange = (val: boolean | null) => {
      if (_value === val) {
        handleDoubleClick(val); // If the same value is clicked again, treat it as a double-click
      } else {
        setValue(val);
        setSelected(val);
        if (onChange) {
          onChange(val);
        }
      }
    };

    const handleDismissChange = (val: boolean | null) => {
      setValue(val);
      if (onChange) {
        onChange(val === null);
      }
    };

    useEffect(() => {
      setValue(value);
    }, [value]);

    return (
      <div className="InputBoolean d-flex flex-column" ref={ref}>
        <div className="InputBoolean--Title">{title}</div>
        <div className="d-flex gap-3">
          <div className="d-flex flex-column justify-content-center">
            <input
              onClick={() => handleOnChange(true)}
              className="form-check-input "
              type="radio"
              // name={name}
              id={yesId}
              checked={_value === true}
              onDoubleClick={() => handleDismissChange(null)}
            />
            <label
              className={`form-check-label ${
                _value === true ? "text-primary" : ""
              }`}
              htmlFor={yesId}
            >
              Yes
            </label>
          </div>
          {!userIsProfessional && (
            <div className="d-flex flex-column justify-content-center">
              <input
                onClick={() => handleOnChange(false)}
                className="form-check-input"
                type="radio"
                // name={name}
                id={noId}
                checked={_value === false}
                onDoubleClick={() => handleDismissChange(null)}
              />
              <label
                className={`form-check-label ${
                  _value === false ? "text-primary" : ""
                }`}
                htmlFor={noId}
              >
                No
              </label>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default InputBoolean;
