import { useEffect } from "react";
import Note from "../Note";
import { useDispatch, useSelector } from "react-redux";
import { ReviewState, getUserReviews } from "../../redux/slices/reviewSlice";
import { AnyAction } from "@reduxjs/toolkit";
import ReviewCardLoader from "../loader/ReviewCardLoader";

const Review = () => {
  const dispatch = useDispatch();

  const reviews = useSelector<unknown, ReviewState>(
    (state: any) => state.reviews
  ).reviews;

  useEffect(() => {
    dispatch(getUserReviews("") as unknown as AnyAction);
  }, []);

  useEffect(() => {
    console.log("My revies", reviews);
  }, [reviews]);

  const renderProfessionals = (reviews: any[]) => {
    if (!reviews) return null;
    return reviews.map((review: any) => {
      return (
        <Note
          name={`${review.salonId.name}`}
          profile={review.salonId.profile}
          stars={review.rating}
          id={review.salonId._id}
        />
      );
    });
  };

  const renderLoader = (numberOfItem: number) => {
    return Array(numberOfItem)
      .fill(0)
      .map((_, key) => {
        return <ReviewCardLoader key={key} />;
      });
  };

  return (
    <div>
      <h3 className="text-primary text-center">Your Reviews</h3>
      <h6 className="text-center">Share your experience after an appointment</h6>
      <div
        className="d-flex FavoritesContainer gap-3 flex-wrap"
        style={{ marginTop: "30px" }}
      >
        {reviews ? renderProfessionals(reviews) :renderLoader(3)}
  
        {reviews?.length === 0 && <h5>No reviews yet</h5>}
      </div>
    </div>
  );
};

export default Review;
