import { IProject } from "../models/Project";
import Salon from "../models/Salon";
import { ISalon } from "../models/SalonX";
import Service from "../models/Service";

export type IReviewStat = { averageStars: number; reviewCount: number };

export type ISalonWithReview = ISalon & {
  reviewsStats: IReviewStat;
};

export type ISearchResult = ISalonWithReview & {
  distance: number;
  services: Service[];
  salon: Salon[];
  // user: {
  //   first_name: string;
  //   last_name: string;
  //   cover: string;
  //   profile: string;
  // }
};

export type TSalonAccountType = "independent" | "business_owner" | "both";

export type ISearchData = {
  size: number;
  page: number;
  totalPages: number | null;
  results: ISearchResult[] | null;
};

export type INugget = {
  size: number;
  page: number;
  latitude?: number;
  longitude?: number;
  totalPages: number | null;
  results: ISearchResult[] | null;
};

export type USATimezone =
  | "US/Eastern"
  | "US/Central"
  | "US/Mountain"
  | "US/Pacific"
  | "US/Alaska"
  | "US/Hawaii"
  | "US/Samoa";

export const USATimezones = [
  { value: "US/Pacific", label: "US/Pacific (GMT-8)" },
  { value: "US/Mountain", label: "US/Mountain (GMT-7)" },
  { value: "US/Central", label: "US/Central (GMT-6)" },
  { value: "US/Eastern", label: "US/Eastern (GMT-5)" },
  { value: "US/Alaska", label: "US/Alaska (GMT-9)" },
  { value: "US/Hawaii", label: "US/Hawaii (GMT-10)" },
  { value: "US/Arizona", label: "US/Arizona (GMT-7)" },
  { value: "US/Indiana-Starke", label: "US/Indiana-Starke (GMT-6)" },
  { value: "Africa/Douala", label: "Africa/Douala (GMT+1)" },
  {
    value: "US/Indiana/Indianapolis",
    label: "US/Indianapolis (GMT-5)",
  },
  { value: "US/Michigan", label: "US/Michigan (GMT-5)" },
];
