import React, { useState } from "react";
import Modal from "../modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Countdown from "./CountDown";

interface ModalWelcomeProps {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function ModalWelcome({ show = false, setShowModal }: ModalWelcomeProps) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const handleClose = () => setShowModal(false);
  return (
    <>
      <Modal open={show} onClose={handleClose}>
        <div className="w-100 h-100 d-flex justify-content-center align-items-center object-cover relative">
          <img
            src={require("../../assets/images/latrice-welcome.png")}
            alt="Latrice image promtion"
            className="img-fluid w-100 h-100 object-cover"
            onLoad={handleImageLoad}
          />
          <p
            className="position-absolute text-center text-black fs-5 text-uppercase"
            style={{ top: "77%", fontWeight: "bold" }}
          >
            20% off
          </p>
        </div>
      </Modal>
      {!imageLoaded && (
        <img
          src={require("../../assets/images/latrice-welcome.png")}
          alt="Latrice image promotion"
          style={{ display: "none" }}
          onLoad={handleImageLoad}
        />
      )}
    </>
  );
}

export default ModalWelcome;
