import React from "react";
import { Modal } from "react-bootstrap";
import { ModalLoader } from "../../modal/commons/ModalLoader";
import moment from "moment";
import { AddShiftModalProps, ShiftTimeInterval } from "./AddShiftModal";
import { TElementEditable, TElementType } from "../WorkingTable";
import { formatTime } from "../../../utils/utils";
import useTimeOptionsEdit from "../../../hooks/useTimeOptionsEdit";

type EditShiftModalProps = {
  type: TElementType;
  data: TElementEditable;
} & Pick<AddShiftModalProps, "onHide" | "onSave" | "timezone">;

export const EditShiftModal: React.FC<EditShiftModalProps> = ({ ...props }) => {
  const [shiftInterval, setShiftInterval] = React.useState<ShiftTimeInterval>({
    startTime: moment(props.data.source.startTime, "h:mm A").format("hh:mm A"),
    endTime: moment(props.data.source.endTime, "h:mm A").format("hh:mm A"),
  });

  const [loading, setLoading] = React.useState(false);
  const timeOptions = useTimeOptionsEdit({ interval: 30 });
  const [error, setError] = React.useState([]);

  const startTimeOptions = timeOptions.generateOptions(
    shiftInterval.endTime,
    null,
    props.data
  );
  const endTimeOptions = timeOptions.generateOptions(
    null,
    shiftInterval.startTime,
    props.data
  );

  console.log("data", props);

  console.log("startTimeOptions", shiftInterval);

  const duration =
    moment(shiftInterval.endTime, "hh:mm A").diff(
      moment(shiftInterval.startTime, "hh:mm A"),
      "minutes"
    ) / 60.0;

  const handleSave = async () => {
    setError([]);
    setLoading(true);
    await props
      .onSave(shiftInterval, props.timezone)
      .then(() => {
        props.onHide();
      })
      .catch((err) => {
        console.log(err);
        setError(err.errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      backdrop="static"
      onHide={props.onHide}
      show={true}
      centered
      size="lg"
    >
      {loading && <ModalLoader />}
      <Modal.Header closeButton>
        <div className="text-black fw-500 fs-5">
          Edit {props.data.element.label}'s{" "}
          {props.type.split("O").join(" o").toLowerCase()}{" "}
          {moment(props.data.source.day, "DD-MM-YYYY").format("ddd, DD MMM")}
        </div>
      </Modal.Header>
      <Modal.Body className="border-top border-bottom">
        {error.length > 0 && (
          <div
            style={{ borderRadius: "0" }}
            className="alert alert-danger w-100 p-0 m-0 pt-1"
          >
            <ul className="">
              {error.map
                ? error.map((message) => {
                    return <li>{message}</li>;
                  })
                : error.toString()}
            </ul>
          </div>
        )}
        <div className="mx-3 my-4 working-table-add-shift-container gap-3">
          <div className="d-flex gap-4">
            <div className="mb-3 w-50">
              <label htmlFor="" className="form-label fw-500 text-black">
                Start time
              </label>
              <select
                value={shiftInterval.startTime}
                onChange={(e) =>
                  setShiftInterval((prev) => ({
                    ...prev,
                    startTime: e.target.value,
                  }))
                }
                className="form-select form-select-lg"
              >
                {startTimeOptions}
              </select>
            </div>
            <div className="mt-auto mb-4 fw-500 text-black">-</div>
            <div className="mb-3 w-50">
              <label htmlFor="" className="form-label fw-500 text-black">
                End time
              </label>
              <select
                value={shiftInterval.endTime}
                onChange={(e) =>
                  setShiftInterval((prev) => ({
                    ...prev,
                    endTime: e.target.value,
                  }))
                }
                className="form-select form-select-lg"
              >
                {endTimeOptions}
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <span className="fw-500 text-black">
              {formatTime(duration * 60)}
            </span>
          </div>
          <small
            style={{ color: "#757676" }}
            className="mt-2 fw-400 text-muted"
          >
            You are editing this day's{" "}
            {props.type.split("O").join(" o").toLowerCase()} on{" "}
            <span className="text-primary cursor-pointer text-decoration-underline">
              {props.timezone}
            </span>{" "}
            timezone.
          </small>
        </div>
      </Modal.Body>
      <Modal.Body>
        <div className="d-flex justify-content-end mx-3 my-3">
          {/* <div>
                        <button onClick={() => props.onHide()} className='working-table-empty-button'>Delete</button>
                    </div> */}

          <div className="d-flex justify-content-end align-items-center gap-3">
            <button
              onClick={() => props.onHide()}
              className="working-table-outline-button"
            >
              Cancel
            </button>
            <button
              onClick={() => handleSave()}
              className="working-table-primary-button"
            >
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
