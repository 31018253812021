import React, { useEffect, useState } from "react";
import AuthLayout from "../auth/AuthLayout";
import ConnectedNavBar from "./ConnectedNavBar";
import NavbarHome from "./NavbarHome";
import NavigationLink from "./NavigationLink";
import { useDispatch } from "react-redux";
import { getNotifications } from "../../redux/slices/notificationSlice";
import { AnyAction } from "@reduxjs/toolkit";
import NavbarToggleButton from "./NavbarToggleButton";
import { useUser } from "../../hooks/UseUser";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import MakeReviewModal from "../reusable/MakeReviewModal";
import socket from "../../socket/socket";
import { EVENT_CODE } from "../../socket/event";
import { IAppointment } from "../../models/Appointment";
import { InvitePromotion } from "../promotion/InvitePromotion";
import IUser from "../../models/User";

type MainNavProps = {
  navType: "homeNav" | "connectedNav";
  isSearch?: boolean;
  silent?: boolean;
};

const MainNav: React.FC<MainNavProps> = ({
  navType,
  isSearch = false,
  silent = false,
}) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [makeReview, setMakeReview] = useState(false);
  //const [makePromotion, setMakePromotion] = useState(false);
  const [myAppoitment, setMyAppoitment] = useState<IAppointment>();

  const user = useUser();
  // const handleClose = () => {
  //   setMakePromotion(false);
  // };

  useEffect(() => {
    if (user?.coupon?.actived === true) {
      const timer = setTimeout(() => {
        //setMakePromotion(true);
      }, 30000);
      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    console.log("simple test");
    socket().on(EVENT_CODE.MAKE_REVIEW, (appointment) => {
      console.log(
        "here is the args after emitting the make review on backend",
        appointment
      );
      setMyAppoitment(appointment);

      setMakeReview(true);
    });
  });

  const logo = (
    <img
      src={require("../../assets/images/latrice.png")}
      className="img-fluid"
      width="80"
      height="80"
      alt="Latrice"
    />
  );

  const contentToRender = (
    <Navbar expand={"lg"} className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">{logo}</Navbar.Brand>
        <Navbar.Toggle
          onClick={() => setShow(true)}
          aria-controls={`offcanvasNavbar-expand-${"lg"}`}
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${"lg"}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${"lg"}`}
          placement="end"
          onHide={() => setShow(false)}
          show={show}
        >
          <Offcanvas.Header>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"lg"}`}>
              <h3 className="text-white">Menu</h3>
            </Offcanvas.Title>
            <i
              onClick={() => setShow(false)}
              className="fa fa-xmark text-white fa-lg"
            ></i>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className=" flex-grow-1">
              {navType === "homeNav" ? (
                // this is the navbar on simple pages(eg. homepage)
                <NavbarHome connected={user !== null} />
              ) : user ? (
                // this is the navbar on other pages(eg. appointments that requires authentication)
                <ConnectedNavBar search={isSearch} />
              ) : null}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );

  useEffect(() => {
    dispatch(getNotifications("") as unknown as AnyAction);
  }, []);

  return (
    <>
      {!silent ? (
        <AuthLayout>{contentToRender}</AuthLayout>
      ) : (
        <>{contentToRender}</>
      )}

      {makeReview && (
        <MakeReviewModal
          appointment={myAppoitment}
          show={makeReview}
          onClose={() => setMakeReview(false)}
          handleMakeReviewFunction={() => console.log("test")}
        />
      )}
      {/* {makePromotion && (
        // <InvitePromotion
        //   onClose={handleClose}
        //   show={makePromotion}
        //   user={user as IUser}
        // />
      )} */}
    </>
  );
};

export default MainNav;
