import React, { useEffect, useState } from "react";
import "../styles/AdminDashboard.css";
import Manager from "../models/Manager";
import { redirect, useNavigate } from "react-router-dom";
import AuthLayout from "../components/auth/AuthLayout";
import MainNav from "../components/navigation/MainNav";
import Footer from "../components/Footer";
import MoreSettingDashboard from "../components/adminDashboard/MoreSettingDashboard";
import { useLatriceUser } from "../hooks/useLatriceUser";

type AdminDashboardProps = {};

const MoreSettingPage: React.FC<AdminDashboardProps> = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();

  const LatriceUser = useLatriceUser();
  console.log("latriceUser value", LatriceUser);

  useEffect(() => {
    if (LatriceUser) {
      console.log("latriceUser", LatriceUser);
      setIsAuthorized(true);
    } else {
      navigate("/");
    }
  }, [LatriceUser, navigate]);

  if (!isAuthorized) {
    return null; // or a loading spinner if you prefer
  }

  return (
    <AuthLayout>
      <div style={{ height: "100vh" }}>
        <MainNav navType={"connectedNav"} />
        <div
          className=" d-flex flex-column  mt-md-3 container "
          style={{ height: "auto" }}
        >
          <MoreSettingDashboard />
        </div>
        <Footer />
      </div>
    </AuthLayout>
  );
};

export default MoreSettingPage;
