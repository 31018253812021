import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IAppointment } from "../../models/Appointment";
import Availability, { UnAvailability } from "../../models/Availability";
import { DayTimes } from "../../components/UpcomingHours";
import { USATimezone } from "../../pages/ManageAvailabilityPage";
import { UnAvailabilityService } from "../../services/UnAvailabilityService";

export interface UnAvailabilityState {
  unavailabilities: UnAvailability[] | null;
  errors: any;
}

const initialState: UnAvailabilityState = {
  unavailabilities: null,
  errors: null,
};

export const addUnAvailability = createAsyncThunk<
  Partial<IAppointment>,
  { edited: DayTimes; removed: DayTimes; timezone: USATimezone }
>(
  "unavailabilities/addAvailability",
  async (
    changes: { edited: DayTimes; removed: DayTimes; timezone: USATimezone },
    { rejectWithValue }
  ) => {
    try {
      const res = await UnAvailabilityService.addUnAvailability(changes);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const removeUnAvailability = createAsyncThunk<
  Partial<Availability>,
  DayTimes
>(
  "unavailabilities/removeAvailability",
  async (dayTimes: DayTimes, { rejectWithValue }) => {
    try {
      const res = await UnAvailabilityService.removeUnAvailability(dayTimes);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const getUnAvailabilities = createAsyncThunk<Partial<IAppointment>[]>(
  "unavailabilities/getunavailabilities",
  async (_, { rejectWithValue }) => {
    try {
      const res = await UnAvailabilityService.getUnAvailabilities();
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const addBulkUnAvailability = createAsyncThunk<
  Partial<IAppointment>,
  {
    edited: DayTimes;
    removed: DayTimes;
    timezone: USATimezone;
    chairId: string;
    repeatUntil: moment.Moment;
  }
>(
  "unavailabilities/addBulkUnAvailability",
  async (
    changes: {
      edited: DayTimes;
      removed: DayTimes;
      timezone: USATimezone;
      chairId: string;
      repeatUntil: moment.Moment;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await UnAvailabilityService.createBulkUnAvailability(changes);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

const unAvailabilitySlice = createSlice({
  name: "unavailabilities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUnAvailability.fulfilled, (state, action) => {
        if (state.unavailabilities) {
          let unavailabilities =
            state.unavailabilities.slice() as Partial<Availability>[];
          const { edited, removed } = action.payload as {
            edited: Partial<Availability>[];
            removed: Partial<Availability>[];
          };
          const availabilitiesIds = state.unavailabilities.map((av) => av._id);
          const removedIds = removed.map((av) => av._id);
          const editedIds = edited.map((av) => av._id);

          // Removing availabilities
          unavailabilities = unavailabilities.filter(
            (av) => !removedIds.includes(av._id)
          );

          // Updating edited availabilities
          unavailabilities = unavailabilities.map((av) => {
            if (editedIds.includes(av._id)) {
              const availability = edited.find((x) => x._id === av._id);
              if (availability) {
                return availability;
              }
            }
            return av;
          });

          // Adding new availabilities
          editedIds.forEach((id) => {
            if (id && !availabilitiesIds.includes(id)) {
              const availability = edited.find((x) => x._id === id);
              if (availability) {
                unavailabilities.push(availability);
              }
            }
          });

          state.unavailabilities = unavailabilities as any;
        } else {
          state.unavailabilities = [action.payload as any];
        }
        state.errors = null;
      })
      .addCase(getUnAvailabilities.fulfilled, (state, action) => {
        state.unavailabilities = action.payload as any;
        state.errors = null;
      })
      .addCase(removeUnAvailability.fulfilled, (state, action) => {
        if (state.unavailabilities) {
          let unavailabilities =
            state.unavailabilities.slice() as Partial<Availability>[];
          const incomingUnAvailabilitiesIds = (
            action.payload as Partial<Availability>[]
          ).map((av) => av._id);
          unavailabilities.filter((av) => {
            return !incomingUnAvailabilitiesIds.includes(av._id);
          });
          state.unavailabilities = unavailabilities as any;
          state.errors = null;
        }
      });
  },
});

export default unAvailabilitySlice.reducer;
