import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useUser } from "../../hooks/UseUser";
import styles from "./../styles/PostProjectModalOptions.module.css";
import "./../styles/PostProjectModalOptions.module.css";

import PostProjectModal from "./PostProjectModal";
import { RegularContainer } from "../project/cart/RegularContainer";
import {
  ProfessionalContainer,
  TProjectFilter,
} from "../project/cart/ProfessionalContainer";
import { IProject } from "../../models/Project";
import socket from "../../socket/socket";
import { EVENT_CODE } from "../../socket/event";
import { ModalLoader } from "./commons/ModalLoader";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { ProjectService } from "../../services/ProjectService";
import { isUserProfessional } from "../../models/User";
import { ProjectState, getProjects } from "../../redux/slices/projectSlice";
import { AnyAction } from "@reduxjs/toolkit";
import React from "react";
import { useSalon } from "../../context/SalonContext";

export interface CartModalOptions extends HTMLAttributes<HTMLDivElement> {
  show?: boolean;
  onBack: () => void;
}

function toSnakeCase(camelCaseString: string): string {
  return camelCaseString.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();
}

const CartModal: React.FC<CartModalOptions> = ({
  show = false,
  className,
  ...props
}: CartModalOptions) => {
  const user = useUser();
  const salon = useSalon();
  const [dashboardProjects, setDashboardProjects] = useState<IProject[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const socketIo = useRef(socket());
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<string[]>();

  const handleAcceptProject = (project: IProject) => {
    setErrors(undefined);
    setLoading(true); // Accepting project
    ProjectService.acceptProject(project)
      .then((res: any) => {
        toast.success("The project has been successfully accepted.", {
          duration: 5000,
        });
        socketIo.current.emit(EVENT_CODE.ASK_PROJECT_ITEMS, {
          filteredFilter: {},
          salon,
        });
        refetchProjects();
      })
      .catch((error: any) => {
        // Match error code and show specialized message error
        const errorMessage =
          error?.response?.data?.errors?.message ??
          "Error during accepting project, please try again.";
        setErrors([errorMessage]);
      }); // .finally(() => { setLoading(false) });
  };

  const handleCancelProject = (project: IProject) => {
    setErrors(undefined);
    setLoading(true); // Accepting project
    const cancelledBy = activeMenu.id !== "cart" ? "professional" : "client";
    ProjectService.cancelProject(project, cancelledBy)
      .then((res: any) => {
        toast.success("The project has been successfully cancelled.", {
          duration: 5000,
        });
        socketIo.current.emit(EVENT_CODE.ASK_PROJECT_ITEMS, {
          filteredFilter: {},
          salon,
        });
        refetchProjects();
      })
      .catch((error: any) => {
        // Match error code and show specialized message error
        const errorMessage =
          error?.response?.data?.errors?.message ??
          "Error during accepting project, please try again.";
        setErrors([errorMessage]);
      }); // .finally(() => { setLoading(false) });
  };

  const handleDeleteProject = (project: IProject) => {
    setErrors(undefined);
    setLoading(true); // Accepting project
    ProjectService.deleteProject(project)
      .then((res: any) => {
        toast.success("The project has been successfully deleted.", {
          duration: 5000,
        });
        socketIo.current.emit(EVENT_CODE.ASK_PROJECT_ITEMS, {
          filteredFilter: {},
          salon,
        });
        refetchProjects();
      })
      .catch((error: any) => {
        // Match error code and show specialized message error
        const errorMessage =
          error?.response?.data?.errors?.message ??
          "Error during deleting project, please try again.";
        setErrors([errorMessage]);
      }); // .finally(() => { setLoading(false) });
  };

  const handleFilterChange = (filter: Partial<TProjectFilter>) => {
    console.log("filter data", filter);
    setLoading(true);

    const filterEntries = Object.entries(filter)
      .filter(([_, value]) => value)
      .map(([key, value]) => {
        return [toSnakeCase(key), value];
      });
    console.log("filter entries", filterEntries);
    const filteredFilter = Object.fromEntries(filterEntries);
    console.log("filter filter", filteredFilter);

    socketIo.current.emit(EVENT_CODE.ASK_PROJECT_ITEMS, {
      filteredFilter: filteredFilter,
      salon,
    });
  };

  useEffect(() => {
    // Store the current value of socketIo.current in a local variable
    const currentSocketIo = socketIo.current;

    const handleReceiveProjectItems = (...args: any[]) => {
      const projects: IProject[] = args[0];
      const myProjects = projects.filter(
        (project) => project.user !== user?._id
      );
      setDashboardProjects(myProjects);
      console.log("here is the custom project data", projects);
      console.log(
        "here is the custom project data without my project",
        myProjects
      );
      setLoading(false);
    }; // Add event listener

    currentSocketIo.on(
      EVENT_CODE.SEND_PROJECT_ITEMS,
      handleReceiveProjectItems
    ); // Initial socket event emission

    currentSocketIo.emit(EVENT_CODE.ASK_PROJECT_ITEMS, {
      filteredFilter: {},
      salon,
    });

    // const timeoutId = setInterval(() => {
    //   currentSocketIo.emit(EVENT_CODE.ASK_PROJECT_ITEMS, {
    //     filteredFilter: {},
    //     salon,
    //   });
    // }, 5000); // Cleanup function

    return () => {
      // Remove event listener using the local variable
      currentSocketIo.off(
        EVENT_CODE.SEND_PROJECT_ITEMS,
        handleReceiveProjectItems
      );
      // clearTimeout(timeoutId);
      setErrors(undefined);
    };
  }, [socketIo]);

  const menuItems = [
    { label: "Cart", id: "cart" },
    { label: "Projects", id: "projects" },
  ];

  const userIsProfessional = isUserProfessional(user);

  const [activeMenu, setActiveMenu] = useState(
    menuItems[userIsProfessional ? 1 : 0]
  );

  const handleChangeActiveMenu = (menuId: string) => {
    const menu = menuItems.find((m) => m.id === menuId);
    if (menu) {
      setActiveMenu(menu);
    }
  };

  const projects = useSelector<unknown, ProjectState>(
    (state: any) => state.projects
  ).projects;

  const refetchProjects = () => {
    setLoading(true);
    dispatch(getProjects() as unknown as AnyAction)
      .unwrap()
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (activeMenu.id === "cart" && !projects) {
      setLoading(true);
      dispatch(getProjects() as unknown as AnyAction)
        .unwrap()
        .finally(() => {
          setLoading(false);
        });
    }
  }, [activeMenu.id, dispatch, projects]);

  return (
    <Modal
      dialogClassName="full-height-modal"
      show={show}
      onHide={props.onBack}
      backdrop="static"
      size="xl"
      keyboard={false}
      className={`${styles.PostProjectModal} ${className}`}
      {...props}
    >
      <Modal.Header closeButton={true}></Modal.Header>
      <Modal.Body className="mx-3">
        {/* {loading && <ModalLoader />} */}
        <div className="">
          <div className="d-flex justify-content-between">
            <div className={``}>
              <h3>
                {activeMenu.id !== "cart"
                  ? "Professional Dashboard"
                  : "Regular Dashboard"}
              </h3>
            </div>
            {userIsProfessional && (
              <div className="d-flex gap-2">
                <div>
                  <button className="btn-primary rounded border-0 text-white gap-2 fw-bold small-text mb-3 d-flex align-items-center p-2">
                    <i className="fa-solid fa-hand"></i>
                    <span>Ask for help</span>
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => handleChangeActiveMenu("cart")}
                    className={` ${
                      activeMenu.id == "cart" ? styles.buttonRegular : null
                    } rounded border-0 text-white gap-2 fw-bold small-text mb-3 d-flex align-items-center p-2 `}
                  >
                    <i className="fa-solid fa-hand"></i>
                    <span>Regular</span>
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => handleChangeActiveMenu("projects")}
                    className={` ${
                      activeMenu.id == "cart" ? null : styles.buttonPro
                    } rounded border-0 text-white gap-2 fw-bold small-text mb-3 d-flex align-items-center p-2`}
                  >
                    <i className="fa-solid fa-hand"></i>
                    <span>Professional</span>
                  </button>
                </div>
              </div>
            )}
          </div>
          {errors && errors.length > 0 && (
            <div className="alert alert-danger pb-0" role="alert">
              <ul>
                {errors.map((error) => {
                  return <li>{error}</li>;
                })}
              </ul>
            </div>
          )}
          <div className="dashboard-container">
            {activeMenu.id === "projects" && (
              <ProfessionalContainer
                onFilterChange={handleFilterChange}
                onAcceptProject={handleAcceptProject}
                onDeleteProject={handleDeleteProject}
                onCancelProject={handleCancelProject}
                projects={dashboardProjects}
                styles={styles}
              />
            )}
                       {" "}
            {activeMenu.id === "cart" && (
              <RegularContainer
                projects={projects ?? []}
                onDeleteProject={handleDeleteProject}
                styles={styles}
              />
            )}
                     {" "}
          </div>
                 {" "}
        </div>
             {" "}
      </Modal.Body>
           {" "}
      {isOpen && (
        <PostProjectModal
          onClose={() => {
            setIsOpen(false);
          }}
          backdrop={true}
        />
      )}
    </Modal>
  );
};

export { CartModal };
