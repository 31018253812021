import { useEffect, useState } from "react";
import {
  AccountSetting,
  ChangePassword,
  FamilyFriend,
  Favorites,
  Location,
  Review,
} from "../components/account";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { PaymentMethod, RemotePayment } from "../components/payment";
import VerticalMenu from "../components/VerticalMenu";
import "../styles/ClientProfilePage.css";
import MainNav from "../components/navigation/MainNav";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountState,
  getBalance,
  getTransactions,
} from "../redux/slices/accountSlice";
import { AnyAction } from "redux";
import { RecentPayment } from "../components/payment/RecentPayment";
import "./../styles/Payment.css";
import {
  getAllCardPaymentMethod,
  PaymentMethodState,
} from "../redux/slices/paymentMethodSlice";
import { IPaymentMethod } from "../models/PaymentMethod";

const PaymentPage = () => {
  const balance = useSelector<unknown, AccountState>(
    (state: any) => state.account
  ).balance;
  const transactions = useSelector<unknown, AccountState>(
    (state: any) => state.account
  ).transactions;

  // const [cardImage, setCardImage] = useState<string >("");

  const [maskedCardNumber, setMaskedCardNumber] = useState("");
  const [cardType, setCardType] = useState("");
  const [cardCredit, setCardCredit] = useState<IPaymentMethod[]>([]);
  const [refetchCard, setRefetchCard] = useState(false);

  const dispatch = useDispatch();
  const cardPaymentMethod = useSelector<unknown, PaymentMethodState>(
    (state: any) => state.paymentMethod
  ).paymentMethod;

  useEffect(() => {
    dispatch(getAllCardPaymentMethod() as unknown as AnyAction);
  }, [refetchCard, dispatch]);

  // const handleRefetchCard = () => {
  //   console.log("i refetch my data");
  //   setRefetchCard(!refetchCard);
  // };

  //function to determine card type
  const detectCardType = (cardNumber: string) => {
    const visaRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const masterCardRegex = /^5[1-5][0-9]{14}$/;
    // const amexRegex = /^3[47][0-9]{13}$/;
    // const discoverRegex = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
    // const jcbRegex = /^35(?:2[89]|[3-8][0-9])[0-9]{12}$/;
    // const dinersRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
    // const unionPayRegex = /^62[0-9]{14,17}$/;
    // const maestroRegex = /^5[0678]\d{14,16}$/;

    if (visaRegex.test(cardNumber)) {
      return "Visa";
    } else if (masterCardRegex.test(cardNumber)) {
      return "MasterCard";
    }
  };

  //function to mask card number

  // const hanleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const input = e.target.value.replace(/\s+/g, "");
  //   setCardNumber(input);

  //   const detectedType = detectCardType(input);
  //   setCardType(detectedType);

  //   const masked = maskCardNumber(input);
  //   setMaskedCardNumber(masked);
  // };
  const handleMaskCardNumber = (cardNumber: string) => {
    const lastFourDigits = cardNumber.slice(-4);
    return `**** **** **** ${lastFourDigits}`;
  };

  const handleMask = (cardCredit: IPaymentMethod[]) => {
    const cardNumberMasked =
      cardCredit &&
      cardCredit[0].card &&
      cardCredit[0].card[0].cardNumber.replace(/\s+/g, "");

    const detectedTyped = detectCardType(cardNumberMasked as string);

    const masked = handleMaskCardNumber(cardNumberMasked as string);
    setMaskedCardNumber(masked);

    setCardType(detectedTyped as string);

    return masked;
  };

  const [activeMenu, setActiveMenu] = useState(0);
  const verticalMenuItems = [
    {
      faIcon: "fa-solid fa-credit-card",
      title: "Payment Method",
    },
    {
      faIcon: "fa-regular fa-credit-card",
      title: "Remote Payment",
    },
    {
      faIcon: "fa fa-history",
      title: "Payment History",
    },
  ];

  const handleVerticalMenuItemClick = (item: object, key: number) => {
    setActiveMenu(key);
  };

  useEffect(() => {
    if (!balance) dispatch(getBalance() as unknown as AnyAction);
    if (!transactions) dispatch(getTransactions("") as unknown as AnyAction);
  }, [balance, transactions, dispatch]);

  useEffect(() => {
    if (cardPaymentMethod) setCardCredit(cardPaymentMethod);
    console.log("Card Details", cardPaymentMethod);
  }, [cardPaymentMethod]);

  console.log("my transaction", cardCredit);
  useEffect(() => {
    console.log("my transaction", transactions);
  }, [transactions]);

  console.log("", cardCredit);

  return (
    <>
      <MainNav navType={"connectedNav"} isSearch={true} />
      <div
        className="container p-lg-0 position-relative "
        style={{ height: "80vh" }}
      >
        <div className="row container_grid">
          <div className="col d-flex flex-column border-end p-0 m-0">
            <div className="w-75 side_bar">
              <div className="card_container gap-3 align-items-center justify-content-center pt-4 pb-4">
                {/* <h3 className="text-uppercase text-black">no card</h3> */}
                {cardCredit.length > 0 ? (
                  <div className="cardBox  relative">
                    <img
                      src={
                        cardType === "Visa"
                          ? require("../assets/images/cardVisa.png")
                          : require("../assets/images/cardBancaire.png")
                      }
                      alt="no card"
                      className="img-fluid"
                    />

                    <p className="nameCard">
                      {/* {cardPaymentMethod.card[0].cardHolderName} */}
                      {cardCredit[0].card &&
                        cardCredit[0].card[0]?.cardHolderName}
                    </p>
                    <p className="numberCard">
                      {cardCredit[0].card &&
                        handleMaskCardNumber(cardCredit[0].card[0].cardNumber)}
                      {/* {cardCredit[0].card && handleMask(cardCredit[0])} */}
                    </p>
                    <p className="expiryCard">**/**</p>
                  </div>
                ) : (
                  <div className="cardBox  relative">
                    <p>no card</p>
                  </div>
                )}
              </div>
              <VerticalMenu
                active={activeMenu}
                items={verticalMenuItems}
                onItemClick={handleVerticalMenuItemClick}
              />
            </div>
          </div>
          <div className="col pt-4 menu h-100 ">
            <div className="w-75 mx-auto">
              {transactions && (
                <>
                  {activeMenu === 0 && <PaymentMethod />}
                  {activeMenu === 1 && <RemotePayment />}

                  {activeMenu === 2 && (
                    <RecentPayment transactions={transactions} />
                  )}
                  {activeMenu === 3 && <Review />}
                  {activeMenu === 4 && <FamilyFriend />}
                  {activeMenu === 5 && <ChangePassword />}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentPage;
