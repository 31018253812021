import { ReactNode, RefObject, useState } from "react";
import "../../styles/SearchBar.css";
import AccordionItem from "../accordion/AccordionItem";

interface DataItem {
  id: number;
  icon: string;
  title: string;
  content: ReactNode;
}

interface HelpItemProps {
  helps: DataItem[] | null | undefined;
  searchable?: boolean;
  visible: boolean;
  inputRef: RefObject<HTMLInputElement>;
  onShow: () => void;
  onSelect?: (helpItem: DataItem) => void;
}

export const SearchBarHelp: React.FC<HelpItemProps> = ({
  helps,
  inputRef,
  searchable = true,
  visible = true,
  onShow,
}) => {
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<
    DataItem[] | null | undefined
  >(helps);
  const [currentIdx, setCurrentIdx] = useState(-1);
  const [isShowResult, setShowResult] = useState(false);
  const [maxItems, setMaxItems] = useState(4);

  const filteredHelps = (searchTerm: string) => {
    const filterData = helps
      ?.filter((helpData) =>
        searchTerm === null
          ? true
          : helpData.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .slice(0, maxItems);

    setFilteredData(filterData);
  };

  const btnOnClick = (index: number) => {
    setCurrentIdx((currentValue) => (currentValue !== index ? index : -1));
  };

  const handleShowMore = () => {
    setMaxItems(maxItems + 2);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
    filteredHelps(value);
    if (value === "") {
      setShowResult(isShowResult);
    } else {
      setShowResult(!isShowResult);
    }
  };
  return (
    <>
      {searchable && (
        <div className="input-container">
          <div className="input-content">
            <div className="icon-content">
              <i className=" fa fa-search"></i>
            </div>
            <input
              type="search"
              className="InputSearch"
              placeholder="Ask a question..."
              value={searchTerm ?? ""}
              onChange={handleInputChange}
              onClick={onShow}
              ref={inputRef}
            />
            {/* <button className=" rounded btn-search">Search</button> */}
          </div>
        </div>
      )}
      {filteredData &&
        (visible ? (
          <div className="data-support-result">
            {filteredData.length > 0 ? (
              <div className="search-result">
                {filteredData
                  .map((item: DataItem) => (
                    <AccordionItem
                      key={item.id}
                      data={item}
                      isOpen={item.id === currentIdx}
                      btnOnClick={() => btnOnClick(item.id)}
                    />
                  ))
                  .slice(0, maxItems)}

                {filteredData.length < maxItems && (
                  <button onClick={handleShowMore} className="btn-result">
                    Voir plus
                  </button>
                )}
              </div>
            ) : (
              <div
                style={{ minHeight: "50vh" }}
                className="w-100 d-flex justify-content-center align-items-center small text-muted flex-column fs-6"
              >
                <i className="fas fa-search-minus fa-3x mb-2"></i>
                <h4>Oops! No Results Found</h4>
                <span className="text-center">
                  We couldn't find anything based on your search.
                </span>
              </div>
            )}
          </div>
        ) : (
          ""
        ))}
    </>
  );
};
