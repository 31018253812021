import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Manager from "../../models/Manager";
import { useSelector } from "react-redux";
import { selectSalonsWithoutManagers } from "../../redux/slices/authSlice";

type EditManagerModalProps = {
  show: boolean;
  manager: Manager;
  onClose: () => void;
  onEdit: (formData: FormData) => void;
};

const EditManagerModal: React.FC<EditManagerModalProps> = ({
  show = false,
  manager,
  onClose,
  onEdit,
}: EditManagerModalProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confPasswordVisible, setConfPasswordVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [managerToUnAssign, setManagerToUnAssign] = useState<Manager | null>(
    null
  );
  // const managerForSalon = useSelector((state: { managers: ManagerState }) =>
  //   selectManagerBySalonId(state, manager.linked_salon_id._id as string)
  // );

  const salons = useSelector((state: any) => {
    return selectSalonsWithoutManagers(state.auth, state.managers);
  });

  const handleClear = () => {
    onClose();
  };

  const handleClose = () => {
    setError(null);

    onClose();
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={handleClose}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <div className="text-black fw-500 fs-5">Edit manager</div>
      </Modal.Header>
      <Modal.Body className="border-top border-bottom">
        <div className="d-flex mx-3 py-4 align-items-center flex-column justify-content-center gap-2">
          <div className="salon-grid justify-content-between w-100">
            <div className="d-flex p-0 flex-column salon-input gap-1 ">
              <span className="text-primary">
                Firstname
                <i className="ms-2 fa-solid fa-user text-muted"></i>
              </span>
              <input
                type="text"
                className="bg-white border rounded"
                placeholder="some text"
                value={manager.user.first_name}
              />
            </div>
            <div className="d-flex p-0 flex-column salon-input gap-1 ">
              <span className=" text-primary">
                Lastname
                <i className="ms-2 fa-solid fa-user text-muted"></i>
              </span>
              <input
                type="text"
                className="bg-white border rounded"
                value={manager.user.last_name}
                placeholder="some text"
              />
            </div>
            <div className="d-flex p-0 flex-column salon-input gap-1 mt-3">
              <span className=" text-primary">
                Phone
                <i className="ms-2 fa-solid fa-phone text-muted"></i>
              </span>
              <input
                type="text"
                className="bg-white border rounded"
                value={manager.user.phone}
                placeholder="phone"
              />
            </div>
            <div className="d-flex p-0 flex-column salon-input gap-1 mt-3">
              <span className=" text-muted text-primary">
                Email
                <i className="ms-2 fa-solid fa-envelope text-muted"></i>
              </span>
              <input
                type="text"
                className="bg-white border rounded"
                value={manager.user.email}
                disabled
              />
            </div>
          </div>
          <div className="w-100">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
          <div className="w-100">
            <span className="text-primary">Salon assigned : </span>
            {manager.linked_salon_id ? (
              <span className="text-primary">
                {manager.linked_salon_id.name}
              </span>
            ) : (
              <select
                className="form-select salon-input py-2"
                aria-label="Default select example"
                name="salon"
                id="salon"
              >
                <option selected>choose a salon</option>
                {salons &&
                  salons.map((salon) => (
                    <option value={salon._id}>{salon.name}</option>
                  ))}
              </select>
            )}
          </div>
          <div
            className="d-flex flex-column gap-1 w-100 salon-input"
            aria-autocomplete="none"
          ></div>
          <div
            className="d-flex flex-column gap-1 w-100 salon-input"
            aria-autocomplete="none"
          >
            <span className="text-primary">
              Lorem ipsum dolor *
              <i className="ms-2 text-muted fa-solid fa-lock"></i>
            </span>
            <div className="input-group" aria-autocomplete="none">
              <input
                required
                type={passwordVisible ? "text" : "password"}
                className="form-control fw-500"
                id="password"
                placeholder="password*"
                name="password"
              />
              <button
                onClick={() => setPasswordVisible(!passwordVisible)}
                className="btn"
                type="button"
                id="togglePasswordVisibility"
              >
                <i
                  className={passwordVisible ? "fa fa-eye-slash" : "fa fa-eye"}
                ></i>
              </button>
            </div>
          </div>
          <div
            className="d-flex flex-column gap-1 w-100 salon-input"
            aria-autocomplete="none"
          >
            <span className="text-primary">
              Lorem ipsum dolor *
              <i className="ms-2 text-muted fa-solid fa-lock"></i>
            </span>
            <div className="input-group" aria-autocomplete="none">
              <input
                required
                type={confPasswordVisible ? "text" : "password"}
                className="form-control fw-500"
                id="password"
                placeholder="password*"
                name="password"
              />
              <button
                onClick={() => setConfPasswordVisible(!confPasswordVisible)}
                className="btn"
                type="button"
                id="togglePasswordVisibility"
              >
                <i
                  className={
                    confPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"
                  }
                ></i>
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-top-0">
        <div className="mx-3 my-1 d-flex justify-content-end align-items-center gap-3">
          <button onClick={onClose} className="working-table-outline-button">
            Cancel
          </button>
          <button
            onClick={handleClear}
            className="working-table-primary-button"
          >
            Done
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditManagerModal;
