import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export type TServiceDetailsData = {
    description: string | null,
    file: File | null,
}

interface ServiceDetailsStepProps {
    defaultValue: TServiceDetailsData,
    onChange: (value: TServiceDetailsData) => void
}


export const ServiceDetailsStep: React.FC<ServiceDetailsStepProps> = (
    { defaultValue, onChange }
) => {

    const [data, setData] = useState<TServiceDetailsData>(defaultValue);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            onChange({ ...data, file })
            const imageDataURL = URL.createObjectURL(file);
            setSelectedImage(imageDataURL);
        }
    };

    const openFileExplorer = () => {
        if (inputRef.current) inputRef.current.click();
    }

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange({ ...data, description: e.target.value })
    }


    React.useEffect(() => {
        if (defaultValue.file) {
            setSelectedImage(URL.createObjectURL(defaultValue.file));
        }
    }, [defaultValue])




    return (
        <div>
            <div className='d-flex gap-1 align-items-center small small-text'>
                <FontAwesomeIcon icon={faInfoCircle} />
                <span>This step is optional</span>
            </div>
            <div className="mt-3">
                <label htmlFor="description" className="form-label text-primary fw-500">
                    Service details
                </label>
                <textarea
                    onChange={handleTextChange}
                    ref={textAreaRef}
                    id='description'
                    className="form-control"
                    name="description"
                    rows={6}
                    defaultValue={data.description ?? ""}
                    placeholder="Please describe the hairstyle or service you're looking for. Include any specific preferences, details, or inspirations. The more information you provide, the better our hairstylists can meet your needs."
                ></textarea>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleInputChange}
                    style={{ display: "none" }}
                    ref={inputRef}
                    hidden
                />
            </div>
            {selectedImage ? (
                <div className="position-relative d-flex justify-content-center border rounded p-4 mt-4 py-3">
                    <img
                        className=''
                        src={selectedImage}
                        alt="Selected"
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                    <div
                        style={{
                            right: "-15px",
                            top: "15px",
                        }}
                        className="position-absolute border d-flex align-items-center justify-center bg-white  translate-middle rounded-circle m-2 p-2"
                        onClick={openFileExplorer}
                    >
                        <i className="fa-solid fa-pen"></i>
                    </div>
                </div>
            ) : (
                <div onClick={openFileExplorer} style={{ borderStyle: 'dashed !important' }} className="d-flex p-4 mx-auto rounded cursor-pointer flex-column border mt-4 py-3 gap-1 align-items-center">
                    <div className="text-primary fw-500 small-text">
                        Upload an illustration image
                    </div>
                    <FontAwesomeIcon size='4x' icon={faCloudArrowUp} className='text-primary' />
                </div>
            )}
        </div>
    )
}
