import { IAvailability } from "./Availability";
import Review from "./Review";
import { ILocation, ISalon } from "./SalonX";

export type NumberShown = "salon_number" | "personal_number";
export type TAccountType = "independent" | "business_owner" | "manager";

export type IBasicUser = {
  _id?: string;
  first_name: string;
  last_name: string;
  email: string;
  password?: string;
  phone: string;
  account_type?: string;
  birthDay: string;
  cover: string | null;
  profile: string | null;
  address: string | null;
  location: ILocation;
  send_message?: boolean;
  percentage_promotion: {
    is_promotion: boolean;
    promotion_rate: number;
  };
  coupon?: {
    actived: boolean;
    link: string;
    quota_max: number;
    percent: number;
    referralCode: string;
    user_sending_coupon?: [
      {
        promotion_code: string;
        quota: number;
        user_use_coupon: string[];
      }
    ];
    expiration_date?: Date;
  };
  reviews: Review[];
  favorites: Review[];
  bookmarks: Review[];
  createdAt?: string;
  updatedAt?: string;
  availability: IAvailability[];
};

export type IUserBusinessOwner = IBasicUser & {
  professional: IProfessional & { account_type: "business_owner" };
  salons: ISalon[];
};

export type IUserManager = IBasicUser & {
  professional: IProfessional & { account_type: "manager" };
  salons: ISalon[];
};

export type IUserIndependent = IBasicUser & {
  professional: IProfessional & { account_type: "independent" };
  salons: ISalon[];
};

export type IUserProfessional = IBasicUser & {
  professional: IProfessional;
  salons: ISalon[];
};

export type IProfessional = {
  _id?: string;
  user: string;
  account_type: TAccountType;
};

type IUser = IBasicUser | IUserIndependent | IUserManager | IUserBusinessOwner;

export type IUserRegistration = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
};

export type IUserRegistrationPro = IUserRegistration & {
  account_type: TAccountType;
  pro: true;
};

export default IUser;

// Utility function to check if a user is of type IBasicUser
export const isUserBasic = (user: any): user is IBasicUser => {
  return (
    user?.first_name &&
    user?.last_name &&
    user?.email &&
    user?.phone &&
    user?.birthDay &&
    user?.location &&
    user?.reviews &&
    user?.favorites
  );
};

// Utility function to check if a user is of type IUserBusinessOwner
export const isUserBusinessOwner = (user: any): user is IUserBusinessOwner => {
  return (
    user &&
    "professional" in user &&
    user.professional &&
    user.professional.account_type === "business_owner" &&
    "salons" in user
  );
};

// Utility function to check if a user is of type IUserManager
export const isUserManager = (user: any): user is IUserManager => {
  return (
    user &&
    "professional" in user &&
    user.professional &&
    user.professional.account_type === "manager" &&
    "salons" in user
  );
};

// Utility function to check if a user is of type IUserIndependent
export const isUserIndependent = (user: any): user is IUserIndependent => {
  return (
    user &&
    "professional" in user &&
    user.professional &&
    user.professional.account_type === "independent" &&
    "salons" in user
  );
};

export const isUserProfessional = (user: any): user is IUserProfessional => {
  return (
    user && "professional" in user && user.professional && "salons" in user
  );
};
