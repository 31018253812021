import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PaymentMethodService } from "../../services/PayementMethod";

export interface PaymentMethodState {
  paymentMethod: any;
  errors: any;
}

const initialState: PaymentMethodState = {
  paymentMethod: null,
  errors: null,
};

export const getAllCardPaymentMethod = createAsyncThunk<any>(
  "payment-method/getAllCardPaymentMethod",
  async () => {
    const res = await PaymentMethodService.getAllCardPaymentMethod();
    console.log("redux payment method data", res.data);
    return res.data;
  }
);

export const addCard = createAsyncThunk<any, any>(
  "payment-method/add-card",
  async (card: any, { rejectWithValue }) => {
    try {
      const res = await PaymentMethodService.addCard(card);
      return res;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const updatePaymentMethod = createAsyncThunk<any, any>(
  "payment-method/update-card",
  async (paymentMethod: any, { rejectWithValue }) => {
    try {
      const res = await PaymentMethodService.updatePaymentMethod(paymentMethod);
      return res;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

const paymentMethodSlice = createSlice({
  name: "payment-method",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCardPaymentMethod.fulfilled, (state, action) => {
        state.paymentMethod = action.payload;
        state.errors = null;
      })
      .addCase(addCard.fulfilled, (state, action) => {
        state.paymentMethod = action.payload;
        state.errors = null;
      })
      .addCase(updatePaymentMethod.fulfilled, (state, action) => {
        state.paymentMethod = action.payload;
        state.errors = null;
      });
  },
});

export const selectPaymentMethod = (state: {
  paymentMethod: PaymentMethodState;
}) => state.paymentMethod;

export default paymentMethodSlice.reducer;
