import React from "react";
import {
  DiscussionSystemActionType,
  DiscussionSystemProps,
} from "../../LatriceDiscussionMessage";
import { PendingDiscussionBase } from "./PendingDiscussionBase";

type PendingDiscussionProfessionalProps = {
  onPendingAction: (action: DiscussionSystemActionType) => void;
};

export const PendingDiscussionProfessional = (
  props: DiscussionSystemProps & PendingDiscussionProfessionalProps
) => {
  const appointmentUpdatedAt = new Date(props.appointment.updatedAt).getTime();
  const pendingResponseTimeout = 6 * 60 * 60 * 1000;

  return (
    <PendingDiscussionBase
      refererDate={appointmentUpdatedAt}
      duration={pendingResponseTimeout}
      elapsedTime={0}
      {...props}
      actionContainer={
        <div className="d-flex justify-content-start align-items-end">
          <button
            onClick={() => {
              props.onPendingAction("accept");
            }}
            className={`LatricePendingAppointment__button accept-button`}
          >
            <i className="fas fa-check"></i> Accept
          </button>
          <button
            onClick={() => {
              props.onPendingAction("price_too_low");
            }}
            className={`LatricePendingAppointment__button price-low-button`}
          >
            <i className="fas fa-dollar-sign"></i> Price too low
          </button>
          <button
            onClick={() => {
              props.onPendingAction("bad_date");
            }}
            className={`LatricePendingAppointment__button bad-date-button`}
          >
            <i className="fas fa-calendar-times"></i> Bad date
          </button>
          <button
            onClick={() => {
              props.onPendingAction("bad_duration");
            }}
            className={`LatricePendingAppointment__button bad-date-button`}
          >
            <i className="fas fa-calendar-times"></i> Change duration
          </button>
          <button
            onClick={() => {
              props.onPendingAction("bad_description");
            }}
            className={`LatricePendingAppointment__button bad-date-button`}
          >
            <i className="fas fa-calendar-times"></i> Change description
          </button>
          <button
            onClick={() => {
              props.onPendingAction("price_too_low & bad_date");
            }}
            className={`LatricePendingAppointment__button bad-date-button`}
          >
            <i className="fas fa-calendar-times"></i> Price too low & Baddate
          </button>
          <button
            onClick={() => {
              props.onPendingAction("refuse");
            }}
            className={`LatricePendingAppointment__button refuse-button`}
          >
            <i className="fas fa-times"></i> Refuse
          </button>
        </div>
      }
    />
  );
};
