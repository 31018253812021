import React from "react";
import DiscussionLoader from "../loader/DiscussionLoader";
import PulseLoader from "../loader/PulseLoader";

interface ChatContentProps {
  selectedDiscussion: any;
  onLoadingDiscussionMessages: boolean;
  renderDiscussionListMessages: (discussionId: string) => React.ReactNode;
  renderLatriceDiscussionMessages: (selected: any) => React.ReactNode;
}

const ChatContent: React.FC<ChatContentProps> = ({
  selectedDiscussion,
  onLoadingDiscussionMessages,
  renderDiscussionListMessages,
  renderLatriceDiscussionMessages,
}) => {
  console.log("all discussion in chatContent page", selectedDiscussion);

  let contentToRender;

  if (!onLoadingDiscussionMessages) {
    if (!selectedDiscussion.meta.system) {
      contentToRender = (
        <div className="RenderLatriceDiscussionMessages h-100">
          {renderDiscussionListMessages(selectedDiscussion)}
        </div>
      );
    } else {
      contentToRender = (
        <div className="RenderLatriceDiscussionMessages h-100">
          {renderLatriceDiscussionMessages(selectedDiscussion)}
        </div>
      );
    }
  } else {
    contentToRender = (
      <DiscussionLoader
        title={
          <div className="d-flex align-items-center">
            loading discussion's messages
            <div style={{ transform: "scale(.8)", filter: "grayscale(1)" }}>
              <PulseLoader />
            </div>
          </div>
        }
      />
    );
  }

  return (
    <div
      className={`chatContent ${
        onLoadingDiscussionMessages
          ? "d-flex align-items-center justify-content-center"
          : ""
      }`}
      style={{ width: "100%", height: "100%", overflowY: "auto" }}
    >
      {contentToRender}
    </div>
  );
};

export default ChatContent;
