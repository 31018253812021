
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBusinessTime, faCalendarDay, faCalendarMinus, faCalendarPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { DayTimes } from '../UpcomingHours';
import DefaultLoader from '../loader/DefaultLoader';
import { toast } from 'react-hot-toast';
import { USATimezone, USATimezones } from '../../utils/types';

export interface AvailabilityChangesModalProps {
    availabilityChanges: {
        timezone: USATimezone
        initial: DayTimes,
        edited: DayTimes,
        removed: DayTimes
    };
    onCancel: () => void;
    onSave: ({ edited, removed, timezone }: { edited: DayTimes, removed: DayTimes, timezone: USATimezone }) => Promise<any>;
    // onEditSuccess: () => void;
}


const AvailabilityChangesModal: React.FC<AvailabilityChangesModalProps> = ({ availabilityChanges, onSave, onCancel }: AvailabilityChangesModalProps) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string[] | null>(null)

    const handleCancel = (e: any) => {
        onCancel();
    }

    const handleSave = (e: any) => {
        setError(null);
        setLoading(true);
        onSave({ edited: availabilityChanges.edited, removed: availabilityChanges.removed, timezone: availabilityChanges.timezone })
            .then((res) => {
                toast.success('Your availabilities has been updated successfully !');
                onCancel();
            }).catch((err) => {
                console.log(err);
                setError(err.errors);
                toast.error('Something went wrong during updating availabilities, please check errors below.')
            }).finally(() => {
                setLoading(false);
            });
    }

    return <Modal size='lg' backdrop='static' show={true} centered>
        {loading && <DefaultLoader title='we proceed your changes...' />}
        <Modal.Header>
            <Modal.Title> <FontAwesomeIcon icon={faBusinessTime} /> {'Validate changes'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {error &&
                <div style={{ borderRadius: '0' }} className='alert alert-danger w-100 p-0 m-0 pt-1'>
                    <ul className=''>
                        {error.map ? error.map((message) => {
                            return <li>{message}</li>
                        }) : error.toString()}
                    </ul>
                </div>
            }
            <div className="container mx-1 my-3">
                <div className="d-flex gap-1">
                    <h5 className='text-underline mb-3'>Summary of your changes</h5>
                    <div>
                        <span className="badge rounded-pill text-bg-secondary">
                            {(USATimezones.filter((zone) => {
                                return zone.value === availabilityChanges.timezone;
                            })).map((zone) => {
                                return zone.label
                            })}
                        </span>
                    </div>
                </div>

                <div className='d-flex gap-2 flex-column'>
                    {Object.keys(availabilityChanges.edited).map((day) => {
                        if (availabilityChanges.initial[day] !== undefined) {
                            return <div className='d-flex gap-2 align-items-center'>
                                <span className='badge bg-info d-flex justify-content-center align-items-center gap-1'><FontAwesomeIcon icon={faCalendarDay} /> EDITED</span>
                                <div className='d-flex align-items-center gap-1'>
                                    <span>{day}</span>
                                    <span className='text-muted text-decoration-line-through'>{availabilityChanges.initial[day].start}</span>
                                    <span className='text-muted text-decoration-line-through'>{availabilityChanges.initial[day].end}</span>
                                </div>
                                <FontAwesomeIcon icon={faArrowRight} />
                                <div className='d-flex gap-1'>
                                    <div className='d-flex gap-1 fw-500'>
                                        <span>{availabilityChanges.edited[day].start}</span>
                                        <span>-</span>
                                        <span>{availabilityChanges.edited[day].end}</span>
                                    </div>
                                </div>
                            </div>
                        } else {
                            return <div className='d-flex gap-2'>
                                <span className='badge bg-success d-flex justify-content-center align-items-center gap-1'> <FontAwesomeIcon icon={faCalendarPlus} /> NEW</span>
                                <div className='d-flex gap-1 align-items-center fw-500'>
                                    <span>{day}</span>
                                    <FontAwesomeIcon icon={faArrowRight} />
                                    <div className='d-flex gap-1'>
                                        <span>{availabilityChanges.edited[day].start}</span>
                                        <span>-</span>
                                        <span>{availabilityChanges.edited[day].end}</span>
                                    </div>
                                </div>
                            </div>

                        }

                    })}
                    {Object.keys(availabilityChanges.removed).map((day) => {
                        return <div className='d-flex gap-2'>
                            <span className='badge bg-danger d-flex justify-content-center gap-1 align-items-center'> <FontAwesomeIcon icon={faCalendarMinus} /> REMOVED</span>
                            <div className='d-flex gap-1 align-items-center text-decoration-line-through text-muted'>
                                <span>{day}</span>
                                <span>{availabilityChanges.initial[day].start}</span>
                                <span>{availabilityChanges.initial[day].end}</span>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button disabled={loading} variant="outline-danger" onClick={handleCancel}>
                cancel
            </Button>
            <Button disabled={loading} variant="outline-success" onClick={handleSave}>
                <FontAwesomeIcon icon={faCheckCircle} /> save changes
            </Button>
        </Modal.Footer>
    </Modal>
}

export default AvailabilityChangesModal;