import React, { useEffect, useState } from "react";
import useYearSelector from "../../hooks/useYearSelector";
import "../styles/WeekSelector.css";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useWeekSelector from "../../hooks/useWeekSelector";
import moment from "moment";
interface FilterStatsProps {
  filters: {
    day: string;
    week: string;
    month: string;
    year: string;
    status: string;
  };
  className?: string;
  setFilters: React.Dispatch<
    React.SetStateAction<{
      day: string;
      week: string;
      month: string;
      year: string;
      status: string;
    }>
  >;
}

const FilterStats: React.FC<FilterStatsProps> = ({
  filters,
  className,
  setFilters,
}) => {
  const [activeFilter, setActiveFilter] = useState<string>("");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const date = moment(value);

    setActiveFilter(name);

    if (name === "day") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        day: value,
        week: date.startOf("week").format("YYYY-MM-DD"),
        month: date.format("YYYY-MM"),
        year: date.format("YYYY"),
      }));
    } else if (name === "week") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        day: date.startOf("week").format("YYYY-MM-DD"),
        week: value,
        month: date.format("YYYY-MM"),
        year: date.format("YYYY"),
      }));
    } else if (name === "month") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        day: date.startOf("month").format("YYYY-MM-DD"),
        week: date.startOf("month").startOf("week").format("YYYY-MM-DD"),
        month: value,
        year: date.format("YYYY"),
      }));
    } else if (name === "year") {
      setFilters((prevFilters) => ({
        ...prevFilters,
        day: date.startOf("year").format("YYYY-MM-DD"),
        week: date.startOf("year").startOf("week").format("YYYY-MM-DD"),
        month: date.startOf("year").format("YYYY-MM"),
        year: value,
      }));
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        [name]: value,
      }));
    }
  };

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const {
    startDate,
    endDate,
    setStartDate,
    handleNextWeek,
    handlePrevWeek,
    weekDates,
  } = useWeekSelector();

  const yearSelector = useYearSelector();

  const handleOpenPicker = () => {
    setOpenDatePicker(true);
  };

  const handlePickDate = (date: Date | null) => {
    if (!date) return;

    const pickedStartDate = moment(date).startOf("week");
    setStartDate(pickedStartDate);
    setFilters((prevFilters) => ({
      ...prevFilters,
      day: pickedStartDate.format("YYYY-MM-DD"),
      week: pickedStartDate.format("YYYY-MM-DD"),
      month: pickedStartDate.format("YYYY-MM"),
      year: pickedStartDate.format("YYYY"),
    }));

    setOpenDatePicker(false);
  };

  const isDisabled = (filterName: string): boolean =>
    !!activeFilter && activeFilter !== filterName;

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      week: startDate.format("YYYY-MM-DD"),
    }));
  }, [startDate, setFilters]);

  return (
    <div className={`week-selector cursor-pointer ${className ?? ""}`}>
      <div className="week-selector__container d-flex justify-content-between h-100 gap-2">
        <div
          className="form-group"
        >
          <label htmlFor="day">Day</label>
          <input
            type="date"
            id="day"
            name="day"
            value={filters.day}
            onChange={handleInputChange}
            className="form-control"
            disabled={isDisabled("day")}
            style={{ backgroundColor: isDisabled("day") ? "#F8D7DA" : "" }}
          />
        </div>
        <div className=" form-group week-selector cursor-pointer">
          <label htmlFor="week">Week</label>
          <div className="week-selector__container d-flex justify-content-between h-100">
            <div
              className="week-selector__button prev border"
              onClick={handlePrevWeek}
            >
              <i className="fa fa-chevron-left" />
            </div>
            <div className="h-100">
              <ReactDatePicker
                className="h-100"
                autoFocus
                onClickOutside={() => setOpenDatePicker(false)}
                open={openDatePicker}
                onInputClick={handleOpenPicker}
                customInput={
                  <div className="week-selector__current h-100 border px-5">
                    <div>{`${weekDates[0].format(
                      "D MMM"
                    )} - ${weekDates[6].format("D MMM, YYYY")}`}</div>
                  </div>
                }
                dayClassName={(date: Date) => {
                  if (
                    moment(date).isBetween(
                      startDate,
                      startDate.clone().add(8, "day"),
                      "minute",
                      "()"
                    )
                  ) {
                    return "text-primary fw-bold border rounded border-primary bg-white";
                  }
                  return "";
                }}
                onChange={(date) => {
                  handlePickDate(date);
                }}
                disabled={isDisabled("week")}
              />
            </div>
            <div
              className="week-selector__button next border"
              onClick={handleNextWeek}
            >
              <i className="fa fa-chevron-right" />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="month">Month</label>
          <input
            type="month"
            id="month"
            name="month"
            value={filters.month}
            onChange={handleInputChange}
            className="form-control"
            disabled={isDisabled("month")}
            style={{ backgroundColor: isDisabled("month") ? "#F8D7DA" : "" }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="year">Year</label>
          <input
            type="number"
            id="year"
            name="year"
            step={1}
            min={2020}
            defaultValue={yearSelector.currentYear}
            value={filters.year}
            onChange={handleInputChange}
            className="form-control"
             disabled={isDisabled("year")}
            style={{ backgroundColor: isDisabled("year") ? "#F8D7DA" : "" }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="status">Status</label>
          <select
            id="status"
            name="status"
            value={filters.status}
            onChange={handleInputChange}
            className="form-control"
          >
            <option value="">All</option>
            <option value="confirmed">Confirmed</option>
            <option value="pending">Pending</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default FilterStats;
