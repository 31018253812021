import ServiceLoader from "../components/loader/ServiceLoader";

export const ServiceListSkeleton = () => {
  return (
    <div>
      <ServiceLoader />
      <hr className="w-100" />
      <ServiceLoader />
      <hr className="w-100" />
      <ServiceLoader />
      <hr className="w-100" />
      <ServiceLoader />
      <hr className="w-100" />
      <ServiceLoader />
      <hr className="w-100" />
      <ServiceLoader />
    </div>
  );
};
