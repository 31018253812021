import React, { useState, useRef, useEffect, ReactNode } from "react";

type SimpleDropdownProps = {
  name: string | ReactNode;
  children: ReactNode[];
  btnClass?: string;
};

const SimpleDropdown: React.FC<SimpleDropdownProps> = ({
  name,
  children,
  btnClass,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close the dropdown when the user clicks outside of it
  const handleClickOutside = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  // Add a click event listener to the document
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={
        "position-relative d-flex justify-content-center align-items-center"
      }
    >
      <div className={btnClass} onClick={toggleDropdown}>
        {name}
      </div>
      {isOpen && (
        <div
          style={{ zIndex: 2, top: "2.4rem" }}
          ref={dropdownRef}
          className="dropdown d-flex flex-column gap-2 position-absolute p-3"
        >
          {children.map((child, index) => (
            <div key={index}>{child}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SimpleDropdown;
