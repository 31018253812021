import React from "react";
import moment, { Moment } from "moment";

type CalendarMultipleSelectionProps = {
  selectedDates: Moment[]; // Changer selectedDate à selectedDates
  setSelectedDates: (dates: Moment[]) => void; // Changer setSelectedDate à setSelectedDates
};

const CalendarMultipleSelection: React.FC<CalendarMultipleSelectionProps> = ({
  selectedDates,
  setSelectedDates,
}) => {
  const [calendarVisible, setCalendarVisible] = React.useState(false);
  const [clickedDate, setClickedDate] = React.useState<Moment | null>(null);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const getDaysInMonth = (date: Moment) => {
    return date.daysInMonth();
  };

  const isSelected = (date: Moment) => {
    return selectedDates.some((selectedDate) =>
      selectedDate.isSame(date, "day")
    );
  };
  console.log("isSelected", isSelected);

  const renderCalendar = () => {
    const daysInMonth = getDaysInMonth(moment());
    const firstDayOfMonth = moment().date(1).day();

    const days: React.ReactNode[] = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const currentDate = moment().date(i);
      const isClickedDate = clickedDate
        ? currentDate.isSame(clickedDate, "day")
        : false;
      const isPassedDate = currentDate.isBefore(moment(), "day");
      const isSelectedDate = selectedDates.some((selectedDate) =>
        selectedDate.isSame(currentDate, "day")
      );

      days.push(
        <div
          key={i}
          className={`calendar-day ${isClickedDate && "clicked"} ${
            isPassedDate && "muted-day"
          } ${isSelectedDate && "selected"}`}
          onClick={() => !isPassedDate && handleDateSelect(moment().date(i))}
        >
          {i}
        </div>
      );
    }

    const blanks: React.ReactNode[] = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      blanks.push(<div key={-i} className="calendar-day blank"></div>);
    }

    const totalSlots = [...blanks, ...days];
    const rows: React.ReactNode[][] = [];
    let cells: React.ReactNode[] = [];

    totalSlots.forEach((day, i) => {
      if ((i % 7 !== 0 && i > 0) || i === 0) {
        cells.push(day);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(day);
      }
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });

    const calendarDays = rows.map((d, i) => {
      return (
        <div key={i} className="calendar-row d-flex justify-content-between">
          {d}
        </div>
      );
    });

    return (
      <div className="calendar-body">
        <div className="calendar-days d-flex">
          {daysOfWeek.map((day) => (
            <div key={day} className="calendar-day">
              <span className="fw-bold">{day}</span>
            </div>
          ))}
        </div>
        {calendarDays}
      </div>
    );
  };

  const handleDateSelect = (date: Moment) => {
    if (isSelected(date)) {
      // Si la date est déjà sélectionnée, la supprimer
      setSelectedDates(selectedDates.filter((d) => !d.isSame(date, "day")));
    } else {
      // Sinon, l'ajouter
      setSelectedDates([...selectedDates, date]);
    }
    setClickedDate(date);
  };

  const toggleCalendar = () => {
    setCalendarVisible(!calendarVisible);
  };

  return (
    <div className="custom-calendar-dropdown">
      <div className="selected-date position-relative" onClick={toggleCalendar}>
        <input
          className="w-100 rounded border bg-transparent p-3 cursor-pointer"
          type="text"
          value={
            selectedDates.length > 0
              ? selectedDates
                  .map((date) => date.format("ddd, DD MMM YYYY"))
                  .join(", ")
              : "Select dates"
          }
          readOnly
        />
        <span className="position-absolute translate-middle top-50 start-100">
          <i
            className={`fa-solid ${
              calendarVisible ? "fa-chevron-up" : "fa-chevron-down"
            }`}
          ></i>
        </span>
      </div>
      {calendarVisible && (
        <div className="calendar-dropdown mt-2 position-absolute bg-white border rounded p-4">
          <div className="calendar-header d-flex justify-content-around">
            <i
              onClick={() =>
                setSelectedDates(
                  selectedDates.map((date) => moment(date).subtract(1, "month"))
                )
              }
              className="fa-solid fa-chevron-left"
            ></i>
            <span className="fw-bold">{moment().format("MMMM YYYY")}</span>
            <i
              onClick={() =>
                setSelectedDates(
                  selectedDates.map((date) => moment(date).add(1, "month"))
                )
              }
              className="fa-solid fa-chevron-right"
            ></i>
          </div>
          {renderCalendar()}
        </div>
      )}
    </div>
  );
};

export default CalendarMultipleSelection;
