import React, { useEffect } from "react";
import "./../styles/WorkingTableElementItem.css";
import {
  TWorkingTableElement,
  TWorkingTableElementProps,
} from "./WorkingTableElement";
import { IAvailability } from "../../models/Availability";
import moment, { Moment } from "moment";

const PlusSquareIcon = () => (
  <i className="fa-regular fa-square-plus fs-5 rounded text-primary"></i>
);
const Banne = () => <i className="fas fa-ban fs-5 rounded text-danger"></i>;

export type WorkingTableElementItemProps = Pick<
  TWorkingTableElementProps,
  "element"
> & {
  shift: IAvailability | null;
  dateOfWeek: Moment;
  timeOff: IAvailability | null;
  onAddShift: (element: TWorkingTableElement) => void;
  onAddTimeOff: (element: TWorkingTableElement) => void;
  onEditShift: (shift: IAvailability) => void;
  onEditTimeOff: (timeOff: IAvailability) => void;
  onDeleteShift: (shift: IAvailability) => void;
  onDeleteTimeOff: (timeOff: IAvailability) => void;
};

type TOpenedMenu =
  | {
      shift: false;
      timeOff: true;
    }
  | {
      shift: true;
      timeOff: false;
    }
  | {
      shift: false;
      timeOff: false;
    };

const WorkingTableElementItem: React.FC<WorkingTableElementItemProps> = ({
  element,
  shift,
  timeOff,
  onAddShift,
  onEditShift,
  dateOfWeek,
  onAddTimeOff,
  onEditTimeOff,
  onDeleteShift,
  onDeleteTimeOff,
}) => {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [menuOpened, setMenuOpened] = React.useState<TOpenedMenu>({
    shift: false,
    timeOff: false,
  });
  const currentDate = moment();

  const isBefore = dateOfWeek.isBefore(currentDate, "day");
  useEffect(() => {
    console.log("All elements", element);
    console.log("date of the week ", dateOfWeek.format("YYYY:MM:DD"));
    console.log("dateNow", currentDate.format("YYYY:MM:DD"));
    console.log("isBefore", isBefore);
  }, []);

  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    const classNames = target.className.split(" ");

    const timeOffClass = "working-table-element__item__time_off";
    const shiftClass = "working-table-element__item__shift";

    if (classNames.includes(timeOffClass)) {
      setMenuOpened({ shift: false, timeOff: true });
    } else if (classNames.includes(shiftClass)) {
      setMenuOpened({ shift: true, timeOff: false });
    } else {
      setMenuOpened({ shift: false, timeOff: false });
    }

    setMenuIsOpen(true);
  };

  const handleClickOutside = (ev: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(ev.target as Node)) {
      setMenuIsOpen(false);
    }
  };

  const handleAddShift = (element: TWorkingTableElement) => {
    setTimeout(() => setMenuIsOpen(false), 1);
    onAddShift(element);
  };

  const handleAddTimeOff = (element: TWorkingTableElement) => {
    setTimeout(() => setMenuIsOpen(false), 1);
    onAddTimeOff(element);
  };

  const handleEditTimeOff = (shift: IAvailability) => {
    setTimeout(() => setMenuIsOpen(false), 1);
    onEditTimeOff(shift);
  };

  const handleEditShift = (shift: IAvailability) => {
    setTimeout(() => setMenuIsOpen(false), 1);
    console.log("shift i want to edit", shift);
    onEditShift(shift);
  };

  const handleDeleteTimeOff = (shift: IAvailability) => {
    setTimeout(() => setMenuIsOpen(false), 1);
    console.log("shift i want to delete", shift);
    onDeleteTimeOff(shift);
  };

  const handleDeleteShift = (shift: IAvailability) => {
    setTimeout(() => setMenuIsOpen(false), 1);
    console.log("shift i want to delete", shift);
    onDeleteShift(shift);
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={menuRef}
      className={`working-table-element__item ${!menuIsOpen ? "editable" : ""}`}
    >
      {!shift && !timeOff && (
        <>
          {isBefore ? (
            <div className="w-100 d-flex align-items-center justify-content-center h-100">
              <div className={`plus-icon`}>
                <Banne />
              </div>
            </div>
          ) : (
            <div
              onClick={handleOpenMenu}
              className="w-100 d-flex align-items-center justify-content-center h-100"
            >
              <div className={`plus-icon`}>
                <PlusSquareIcon />
              </div>
            </div>
          )}
        </>
      )}

      {(shift || timeOff) && (
        <div className="w-100 d-flex flex-column gap-2">
          {timeOff && (
            <div
              onClick={handleOpenMenu}
              className={`working-table-element__item__time_off ${
                menuIsOpen ? "active" : ""
              } rounded p-2 w-100 fw-500`}
            >
              {timeOff.startTime} - {timeOff.endTime}
            </div>
          )}
          {shift && (
            <div
              onClick={handleOpenMenu}
              className={`working-table-element__item__shift ${
                menuIsOpen ? "active" : ""
              } rounded p-2 w-100 fw-500`}
            >
              {shift.startTime} - {shift.endTime}
            </div>
          )}
        </div>
      )}

      <div
        className={`working-table-element__item__dropdown ${
          menuIsOpen ? "active" : ""
        } shadow bg-white d-flex flex-column`}
      >
        {!shift && !timeOff && (
          <>
            <div onClick={() => handleAddShift(element)}>Add shift</div>
            <div onClick={() => handleAddTimeOff(element)}>Add time off</div>
          </>
        )}

        {shift && !timeOff && (
          <>
            <div onClick={() => handleEditShift(shift)}>Edit shift</div>
            <div onClick={() => handleAddTimeOff(element)}>Add time off</div>
            <div
              onClick={() => handleDeleteShift(shift)}
              className="text-danger"
            >
              Delete shift
            </div>
          </>
        )}

        {timeOff && !shift && (
          <>
            <div onClick={() => handleAddShift(element)}>Add shift</div>
            <div onClick={() => handleEditTimeOff(timeOff)}>Edit time off</div>
            <div
              onClick={() => handleDeleteTimeOff(timeOff)}
              className="text-danger"
            >
              Delete time off
            </div>
          </>
        )}

        {timeOff && shift && menuOpened.shift && (
          <>
            <div onClick={() => handleEditShift(shift)}>Edit shift</div>
            <div
              onClick={() => handleDeleteShift(shift)}
              className="text-danger"
            >
              Delete shift
            </div>
          </>
        )}

        {timeOff && shift && menuOpened.timeOff && (
          <>
            <div onClick={() => handleEditTimeOff(timeOff)}>Edit time off</div>
            <div
              onClick={() => handleDeleteTimeOff(timeOff)}
              className="text-danger"
            >
              Delete time off
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WorkingTableElementItem;
