import React from "react";
import PortfolioLoader from "../../loader/PortfolioLoader";
import { v4 as uuidv4 } from "uuid";
import ReactSimpleImageViewer from "react-simple-image-viewer";

export type Props = {
  portfolio: string[] | null;
};

export const SalonPortfolio: React.FC<Props> = ({ portfolio }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(0);

  const renderLoader = () => {
    return Array(4)
      .fill(0)
      .map(() => {
        return <PortfolioLoader key={uuidv4()} />;
      });
  };

  const handleShowImage = (index: number) => {
    setImageIndex(index);
    setShowModal(true);
  };

  const renderPortfolio = () => {
    return Array(4)
      .fill(0)
      .map((_, key) => {
        if (portfolio && portfolio.length > key) {
          return (
            <div
              key={uuidv4()}
              className={`w-100 p-0 m-0 border position-relative`}
            >
              <img
                onClick={() => handleShowImage(key)}
                className="img-fluid"
                src={portfolio[key]}
                alt="Portfolio"
              />
              {key === 3 && portfolio.length > 4 && (
                <div
                  onClick={() => handleShowImage(4)}
                  className="see-more user-select-none"
                >
                  + {portfolio.length - 4} See More
                </div>
              )}
            </div>
          );
        }
        return (
          <div key={uuidv4()} className={`border no-image`}>
            <div
              style={{ backgroundColor: "#f6f7f8" }}
              className="border"
            ></div>
          </div>
        );
      });
  };

  return (
    <>
      <div className="professionalDetailsPhoto p-0 m-0 mb-3">
        {portfolio === null ? renderLoader() : renderPortfolio()}
      </div>
      {showModal && portfolio && (
        <ReactSimpleImageViewer
          src={portfolio}
          currentIndex={imageIndex}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};
