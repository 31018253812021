import React from 'react'
import { TProjectService } from '../../../models/Project'
import { faCircle, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatUSPrice } from '../../../utils/utils'


interface AddServiceStepProps {
    services: TProjectService[],
    onAdd: () => void,
    onRemoveService: (key: number) => void,
}


export const AddServiceStep: React.FC<AddServiceStepProps> = (
    { services, onAdd, onRemoveService }
) => {


    const handleRemove = (key: number) => {
        onRemoveService(key)
    }



    return (
        <div>
            <div className="d-flex">
                <button onClick={onAdd} className="modal_next_button  ms-auto d-flex gap-2 border p-2 px-4 rounded bg-transparent align-items-center">
                    <span>Add a service</span>
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            </div>
            {
                services.length === 0 ?
                    <div className='mt-4'>
                        <p className='user-select-none mt-5 mx-auto w-50 text-center fs-4 text-muted'>
                            Please add one or more services to continue
                        </p>
                    </div>
                    :
                    <div className=' d-flex flex-column gap-3 mt-4'>
                        {services.map((service, key) => {
                            return <div key={key} className='d-flex position-relative cursor-pointer align-items-center border border-black justify-content-between rounded p-3'>
                                <div onClick={() => handleRemove(key)} style={{ width: '30px', height: '30px', right: "-5px", top: "-13px" }} className="position-absolute text-center bg-black text-white border border-black d-flex align-items-center justify-content-center rounded-circle">
                                    X
                                </div>
                                <div className='d-flex flex-column justify-content-between'>
                                    <h5 className='text-black'>{service.name}</h5>
                                    <div className='text-muted'>{service.size.join()}</div>
                                </div>
                                <div className='text-black'>
                                    {formatUSPrice(service.price)}
                                </div>
                            </div>
                        })}
                    </div>
            }
        </div>
    )
}
