import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import SpinnerLoader from "../loader/SpinnerLoader";
import { toast } from "react-hot-toast";
import { MemberType } from "../validation/FamilyFriendValidations";

type IMemberReject = {
  show: boolean;
  onReject: () => void;
  onClose: () => void;
};
const RejectMemberModal: React.FC<IMemberReject> = ({
  show,
  onReject,
  onClose,
}) => {
  const handleReject = () => {
    onReject();
    onClose()

  };

  return (
    <Modal
      style={{ backdropFilter: "blur(2px)" }}
      backdrop="static"
      onHide={onClose}
      show={show}
      centered
    >
      {/* {loading && <SpinnerLoader />} */}
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          Reject
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="container my-2 p-lg-0">
        <p className=" ">Do you really want to reject ?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleReject} variant="outline-dark" className="w-25">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RejectMemberModal;
