import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CustomAppointmentService } from "../../services/CustomAppointmentService";
import { IAppointment } from "../../models/Appointment";

export interface CustomAppointmentState {
  customAppointments: IAppointment[] | null;
  errors: any;
}

const initialState: CustomAppointmentState = {
  customAppointments: null,
  errors: null,
};

export const addCustomAppointment = createAsyncThunk<
  Partial<IAppointment>,
  FormData
>(
  "customAppointments/addAppointment",
  async (customAppointment: FormData, { rejectWithValue }) => {
    try {
      const res = await CustomAppointmentService.addCustomAppointment(
        customAppointment
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export type EditCustomAppointmentProps = { customAppointment: FormData, currentUserIsInitiatorOfAppointment: boolean }

export const editCustomAppointment = createAsyncThunk<
  Partial<IAppointment>,
  EditCustomAppointmentProps
>(
  "customAppointments/editAppointment",
  async (props: EditCustomAppointmentProps, { rejectWithValue }) => {
    try {
      const res = await CustomAppointmentService.editCustomAppointment(
        props
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export type ReassignCustomAppointmentProps = {
  customAppointmentId: string, professionalId: string,
}
export const reassignCustomAppointment = createAsyncThunk<
  Partial<IAppointment>,
  ReassignCustomAppointmentProps
>(
  "customAppointments/editAppointment",
  async (props: ReassignCustomAppointmentProps, { rejectWithValue }) => {
    try {
      const res = await CustomAppointmentService.reassignCustomAppointment(
        props
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const editCustomAppointmentSocket = createAsyncThunk<
  Partial<IAppointment>,
  Partial<IAppointment>
>(
  "customAppointments/editAppointmentSocket",
  async (props: Partial<IAppointment>, { rejectWithValue }) => {
    return await Promise.resolve(props);
  }
);

export const addCustomAppointmentSocket = createAsyncThunk<
  Partial<IAppointment>,
  Partial<IAppointment>
>(
  "customAppointments/addAppointmentSocket",
  async (props: Partial<IAppointment>, { rejectWithValue }) => {
    return await Promise.resolve(props);
  }
);

export const getCustomAppointments = createAsyncThunk<Partial<IAppointment>[]>(
  "customAppointments/getAppointments",
  async (_, { rejectWithValue }) => {
    try {
      const res = await CustomAppointmentService.getCustomAppointments();
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const markCustomAppointmentAsStarted = createAsyncThunk<Partial<IAppointment>, string>(
  "customAppointments/mark-as-read",
  async (appointmentId, { rejectWithValue }) => {
    try {
      const res = await CustomAppointmentService.markAsStarted(appointmentId);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
)
export const markCustomAppointmentAsEnded = createAsyncThunk<Partial<IAppointment>, string>(
  "customAppointments/mark-as-ended",
  async (appointmentId, { rejectWithValue }) => {
    try {
      const res = await CustomAppointmentService.markAsEnded(appointmentId);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
)


const customAppointmentSlice = createSlice({
  name: "customAppointments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCustomAppointment.fulfilled, (state, action) => {
        if (state.customAppointments) {
          const customAppointments = state.customAppointments.slice();
          customAppointments.push(action.payload as any);
          state.customAppointments = customAppointments;
        } else {
          state.customAppointments = [action.payload as any];
        }
        state.errors = null;
      })
      .addCase(getCustomAppointments.fulfilled, (state, action) => {
        state.customAppointments = action.payload as any;
        state.errors = null;
      })
      .addCase(editCustomAppointment.fulfilled, (state, action) => {
        if (state.customAppointments) {
          const customAppointments = state.customAppointments.slice();
          const data = action.payload as any;
          customAppointments.forEach((appointment) => {
            if (appointment._id === data._id) return data;
            return appointment;
          });
          state.customAppointments = customAppointments;
        }

        state.errors = null;
      })
      .addCase(editCustomAppointmentSocket.fulfilled, (state, action) => {
        if (state.customAppointments) {

          let customAppointments = state.customAppointments.slice();
          const data = action.payload as any;
          const status = data.status;

          if (status === 'reassigned') {
            customAppointments = customAppointments.filter((appointment) => appointment._id !== data._id);
          } else {
            customAppointments = customAppointments.map((appointment) => {
              if (appointment._id === data._id) return data;
              return appointment;
            });
          }

          state.customAppointments = customAppointments;

        }

        state.errors = null;
      }).addCase(addCustomAppointmentSocket.fulfilled, (state, action) => {

        if (state.customAppointments) {
          const customAppointments = state.customAppointments.slice();
          const data = action.payload as any;
          if (!customAppointments.find((appointment) => appointment._id !== data._id)) {
            customAppointments.push(data)
            state.customAppointments = customAppointments;
          }
        }

        state.errors = null;
      });
  },
});

export default customAppointmentSlice.reducer;
