import User from "../../models/User";
import { Stars } from "../Stars";
import BookmarkButton, { IBookMakable } from "../BookmarkButton";
import { ISalon } from "../../models/SalonX";



export interface ProfileInfoProps extends IBookMakable {
  salon: ISalon;
}

const ProfileInfo = ({ bookMarked = false, ...props }: ProfileInfoProps) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex gap-2 align-items-center">
        <img
          className="profile_image"
          src={props.salon.profile}
          alt={`${props.salon.name}`}
        />
        <div className="d-flex flex-column">
          <h4 className="fw-500 text-black profile_name">{props.salon.name}</h4>
          <small className="text-muted">{props.salon.address}</small>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-center gap-2">
        <div className="d-flex justify-content-end mt-4">
          <Stars canUpdate={false} stars={props.salon.reviewsStats?.averageStars as number} notice={props.salon.reviewsStats?.reviewCount as number} />
        </div>
        <div className="d-flex justify-content-end">
          <BookmarkButton onClick={(e: any) => props.onBookmark && props.onBookmark(e)} ref={null} marked={bookMarked} />
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;