import React, { HTMLAttributes, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { StripeElementChangeEvent, loadStripe } from "@stripe/stripe-js";
import "../styles/TopUpAccountModal.css";
import { formatUSPrice } from "../../utils/utils";
import { ModalLoader } from "./commons/ModalLoader";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { depositFunds } from "../../redux/slices/accountSlice";
import { AnyAction } from "@reduxjs/toolkit";
import CheckoutPage from "../payment/CheckoutPage";
import convertToSubcurrency from "../../utils/convertToSubcurrency";

export interface TopUpAccountModalOptions
  extends HTMLAttributes<HTMLDivElement> {
  show?: boolean;
  onBack: () => void;
}

const TopUpAccountModal: React.FC<TopUpAccountModalOptions> = ({
  show = false,
  className,
  ...props
}: TopUpAccountModalOptions) => {
  const [amount, setAmount] = useState(20);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
  );

  // const handleStripePayment = async (e: any, stripe: any, elements: any) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const cardElement = elements.getElement(CardElement);
  //   // const { error, token } = await stripe.createToken(cardElement);
  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: "card",
  //     card: cardElement,
  //   });

  //   if (error) {
  //     toast.error("Error: " + error.message);
  //   } else {
  //     const data = {
  //       ...paymentMethod,
  //       amount,
  //     };
  //     console.log("here is data", data);
  //     toast.remove();
  //     dispatch(depositFunds(data) as unknown as AnyAction)
  //       .unwrap()
  //       .then(() => {
  //         props.onBack();
  //         toast.success(
  //           `${formatUSPrice(
  //             amount
  //           )} has been successfully added to your balance !`,
  //           { duration: 15000 }
  //         );
  //       })
  //       .catch((error: any) => {
  //         console.log(error);
  //         toast.error("Error: unable to perform the transaction !", {
  //           duration: 10000,
  //         });
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // };

  const handleAmountChange = (amount: string) => {
    console.log("amount a pass", amount);
    setAmount(Number(amount));
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "payment",
        amount: convertToSubcurrency(amount),
        currency: "usd",
      }}
    >
      <Modal
        size="lg"
        centered
        show={show}
        onHide={props.onBack}
        backdrop="static"
        keyboard={false}
        {...props}
      >
        <Modal.Header closeButton>
          <div className="d-flex gap-1 align-items-center">
            <i className="fa-solid fa-credit-card"></i>
            <span className="text-uppercase">Add Funds to Your Account</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <CheckoutPage amount={amount} setAmount={handleAmountChange} onBack = {()=>props.onBack()} />
        </Modal.Body>
      </Modal>
    </Elements>
  );
};

export default TopUpAccountModal;
