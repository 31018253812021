import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Salon from "../../models/Salon";
import { SalonService } from "../../services/SalonService";

export interface saloNameState {
  salonNames: Salon[] | null;
}

const initialState: saloNameState = {
  salonNames: null,
};

export const getSalonNames = createAsyncThunk<Salon[], string>(
  "salons/getsalonName",
  async () => {
    const res = await SalonService.getSalonName();
    return res.data;
  }
);

const salonNameSlice = createSlice({
  name: "salons",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSalonNames.fulfilled, (state, action) => {
      state.salonNames = action.payload;
    });
  },
});

export default salonNameSlice.reducer;
