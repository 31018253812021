import React, { Key, ReactNode, useEffect, useState } from "react";

export interface Item {
  id: string;
  value: string;
  [key: string]: any;
}

export type CustomDropdownProps<T, V> = {
  searchItem: string;
  setSearchItem: (value: string) => void;
  items: T[];
  onChange?: (item: T | string) => void;
  keyExtractor: (item: T) => V;
  render: (item: T) => ReactNode;
  onBlur?: (e: any) => void;
  disabled?: boolean;
  placeholder: string;
  name: string;
};

const CustomDropdown = <T extends Item, V extends unknown>({
  searchItem,
  setSearchItem,
  items,
  onChange,
  keyExtractor,
  render,
  onBlur,
  name,
  disabled,
  placeholder,
}: CustomDropdownProps<T, V>) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const filter = (items: any[]) => {
    let searchResults: T[] = [];

    if (!searchItem) {
      searchResults = items;
    } else {
      searchResults = items.filter((item: any) => {
        const itemName = item.name ? item.name.toLowerCase() : "";
        return itemName.includes(searchItem.toLowerCase());
      });
    }
    return searchResults;
  };

  const handleFocus = () => {
    setShowDropDown(true);
  };

  const handleDropdownClick = (item: T) => {
    if (onChange) {
      // console.log(searchItem);
      // if (isNaN(Number(searchItem))) {
      //   console.log(item.value);
      // }
      setSearchItem(item.value);
      onChange(item);
    }
    setShowDropDown(false);
  };

  const renderItems = (items: T[]) => {
    return filter(items).map((item) => (
      <div
        style={{ lineHeight: "1" }}
        onMouseDown={() => handleDropdownClick(item)}
        className="customDropdown__item mb-2"
        key={keyExtractor(item) as Key}
        role="button"
      >
        {/* <i style={{ fontSize: "0.4rem" }} className="fa-solid fa-circle"></i> */}
        <span>{render(item)}</span>
      </div>
    ));
  };

  const handleBlur = (e: any) => {
    setShowDropDown(false);
    if (onBlur) onBlur(e);
  };

  return (
    <div
      className="border d-flex border-0  flex-column gap-2 position-relative w-100 "
      style={{ height: "45px" }}
    >
      <input
        autoComplete="off"
        onBlur={handleBlur}
        onFocus={handleFocus}
        type="text"
        className="w-100 border border-1 rounded-2 p-3 h-100 fs-6"
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
          setSearchItem(e.target.value);
        }}
        value={searchItem}
        placeholder={placeholder}
        name={name}
        id={name}
        disabled={disabled}
      />
      <div
        className={"p-2 position-absolute bg-white border border-1 w-100"}
        style={{
          top: "3rem",
          display: showDropDown && filter(items).length > 0 ? "block" : "none",
          zIndex: "3",
          maxHeight: '400px',
          overflowY: 'auto'
        }}
      >
        {items && renderItems(items)}
      </div>
    </div>
  );
};

export default CustomDropdown;
