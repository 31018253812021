import { ReactNode, useState } from "react";
import Footer from "../components/Footer";
import SlidePicker from "../components/SlidePicker";
import { InputMedia } from "../components/input";
import MainNav from "../components/navigation/MainNav";
import { useSalon } from "../context/SalonContext";
import "./../styles/PhotoPage.css";
import ImageEditorModal from "../components/modal/ImageEditorModal";
import { useDispatch } from "react-redux";
import { deletePortfolioImage, updateSalon } from "../redux/slices/authSlice";
import { AnyAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import ReactSimpleImageViewer from "react-simple-image-viewer";
import ConfirmDeleteModal from "../components/reusable/ConfirmDeleteModal";

const PhotoPage = () => {
  const { salon, setSalon } = useSalon();
  const [imageIndex, setImageIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [portfolioToDelete, setPortfolioToDelete] = useState<number | null>(
    null
  );
  const dispatch = useDispatch();

  const [imageEditor, setImageEditor] = useState({
    open: false,
    editFor: "profile",
    title: "Change",
    message: "ADD A 400x400 IMAGE",
    rounded: false,
    ratio: 16 / 9,
  });

  const handlePortofolio = () => {
    setImageEditor((editorValues) => {
      return {
        ...editorValues,
        editFor: "portfolio",
        message: "CLICK TO UPLOAD OR DROP AN IMAGE",
        open: true,
        title: "Add image to portfolio",
        ratio: 7.5 / 5,
        rounded: false,
      };
    });
  };

  const handleToggleModal = (open: boolean) => {
    setImageEditor((editorValues) => {
      return { ...editorValues, open };
    });
  };

  const handleUpload = async (formData: FormData) => {
    const imageName = imageEditor.editFor;
    formData.append(imageName, formData.get("file") as File);
    formData.delete("file");

    return dispatch(updateSalon(formData) as unknown as AnyAction).unwrap();
  };

  const handleDeletePortfolio = () => {
    if (portfolioToDelete !== null) {
      toast.remove();
      toast.loading("deleting portfolio image");
      const id = portfolioToDelete;
      setPortfolioToDelete(null);
      toast.remove();
      dispatch(deletePortfolioImage(id) as unknown as AnyAction)
        .unwrap()
        .then(() => {
          toast.remove();
          toast.success("Your portfolio image has been deleted.");
        })
        .catch((err: any) => {
          toast.error("Error during deletion, please try again.");
          console.log(err);
        });
    }
  };

  return (
    <>
      <MainNav navType={"connectedNav"} isSearch={false} />
      <div className="container">
        <div className="w-100 d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center  w-100">
            <div
              style={{ width: "80%" }}
              className="d-flex flex-column align-items-start  justify-content-center ManagePhotos my-4"
            >
              <div className="d-flex">
                <h2 className="text-primary text-start mb-3">Manage Photos</h2>{" "}
                <i className="fa fa-info-circle ms-2"></i>
              </div>

              {/*  */}

              {/*  */}

              <InputMedia
                value={null}
                icon="fa fa-camera fa-2x"
                //@ts-ignore
                placeholder={<h6>Add Photos</h6>}
                onClick={handlePortofolio}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center  w-100">
            <div
              style={{ width: "80%" }}
              className="d-flex flex-column align-items-start  justify-content-center ManagePhotos my-4"
            >
              <div className="d-flex">
                <h2 className="text-primary text-start mb-3">Pinned Photos</h2>
              </div>
              <div className=" w-100   p-3 d-flex align-items-center justify-content-start gap-5 shadow text-black fw-500">
                {/*  */}

                {(salon?.portfolio ?? []).length !== 0 ? (
                  <SlidePicker
                    data={(salon?.portfolio ?? []).map(
                      (p: string, key: number) => {
                        return { image: p, key };
                      }
                    )}
                    maxShow={4}
                    onSelectItem={(item: any) => {
                      setImageIndex(item.key as number);
                      setShowModal(true);
                    }}
                    deletable
                    renderDeletableItem={(item: any) => {
                      return (
                        <div
                          style={{
                            fontSize: "2rem",
                            zIndex: "10",
                          }}
                        >
                          <i className="fa-solid fa-circle-xmark text-dark"></i>
                        </div>
                      );
                    }}
                    onDeleteItem={(item: any) => {
                      setPortfolioToDelete(item.key);
                    }}
                    renderItem={function (item: any): ReactNode {
                      return (
                        <div
                          style={{
                            height: "200px",
                            width: "100%",
                          }}
                          className="position-relative border"
                        >
                          <img
                            style={{
                              height: "100%",
                              width: "100%",
                              backgroundSize: "cover",
                            }}
                            className="rounded img-fluid"
                            src={item.image}
                            alt=""
                          />
                        </div>
                      );
                    }}
                    keyExtractor={function (item: any): string {
                      return item.key as string;
                    }}
                    separator={<span className="mx-1"></span>}
                  />
                ) : (
                  <div>
                    <small className="text-muted d-flex align-items-center">
                      <div className="d-flex flex-column">
                        <span>
                          Your portfolio is a great way to showcase your work.
                        </span>
                        <span>
                          Let's get started by adding your first portfolio
                          entry.
                        </span>
                      </div>
                    </small>
                  </div>
                )}

                {/*  */}
                {/* <div>
                  <i
                    style={{ rotate: "45deg" }}
                    className="fa-solid fa-2x fa-thumbtack"
                  ></i>
                </div>
                <div className="pinned_text">
                  You haven't pinned any of your photos yet! Just tap the pin
                  three favorite photos and the will be displayed first in your
                  gallery.
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ImageEditorModal
        onUpload={handleUpload}
        message={imageEditor.message}
        onClose={() => handleToggleModal(false)}
        show={imageEditor.open}
        rounded={imageEditor.rounded}
        ratio={imageEditor.ratio}
        title={imageEditor.title}
      />
      {salon && showModal && (
        <ReactSimpleImageViewer
          src={salon.portfolio}
          currentIndex={imageIndex}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setShowModal(false)}
        />
      )}
      <ConfirmDeleteModal
        show={portfolioToDelete !== null}
        setServiceToDelete={() => {
          setPortfolioToDelete(null);
        }}
        handleServiceDelete={handleDeletePortfolio}
        text1={"Do you really want to delete this file?"}
        text2={"If you delete it you won't be able to recover it"}
      />
    </>
  );
};

export default PhotoPage;
