import { useState } from "react";

import { Button, Modal } from "react-bootstrap";
import PaymentCard from "./reusable/PaymentCard";
import Payment from "../models/Payment";
import { ICardPaymentMethod, IPaymentMethod } from "../models/PaymentMethod";
import { useDispatch } from "react-redux";
import { addCard } from "../redux/slices/paymentMethodSlice";
import { AnyAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { PaymentInitialValues } from "./validation/PaymentMethod";
import { usePaymentInputs } from "react-payment-inputs";

export interface AddNewCardProps {
  Show?: boolean;
  HandleShow?: any;
}

const AddNewCard: React.FC<AddNewCardProps> = ({ Show, HandleShow }) => {
  // const handleSave = () => {
  //   if (typeof OnSave === "function") OnSave(payment);
  // };

  const dispatch = useDispatch();
  const [cardDetails, setCardDetails] = useState<ICardPaymentMethod>({
    cardHolderName: "",
    cardNumber: "",
    expirationDate: "",
    cvv: "",
  });
  const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setCardDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    //onEdit({ ...cardDetails, [name]: value });
    console.log(`Field: ${name}, Value: ${value}`); // Debugging
  };
  const handleSave = () => {
    if (!cardDetails) {
      toast.error("Please fill in all card details");
      return;
    }

    // Dispatch the action to add a new card
    dispatch(addCard(cardDetails) as unknown as AnyAction)
      .unwrap()
      .then((response: any) => {
        // Handle success, e.g., close modal or show success message
        console.log("card details data", cardDetails);
        console.log("Card added successfully:", response);
        toast.success("Card added successfully!");
        HandleShow(); // Close the modal
      })
      .catch((error: any) => {
        // Handle error, e.g., show error message
        console.error("Failed to add card:", error);
        toast.error("Failed to add card!");
      });
  };

  const handleEdit = (e: any) => {
    e.preventDefault();
    // if () onEdit(payment);
    const rnam: string = "John Doe";
    return rnam;
  };

  return (
    <div className="AddNewCArd">
      <Modal show={Show} onHide={HandleShow}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <PaymentCard
            onEdit={(payment) => setCardDetails(payment)}
            Location="AddCardPage"
            Open={true}
            Background="white"
            Color="black"
            payment={cardDetails}
          /> */}
          <form className="m-3">
            <div
              style={{ display: "flex" }}
              className="form-inputs justify-content-between flex-column"
            >
              <div className="mb-3">
                <label htmlFor="cardNUm" className="form-label">
                  Card Number
                </label>
                <input
                  {...getCardNumberProps({ onChange: handleChange })}
                  value={cardDetails.cardNumber || ""}
                  //onChange={handleChange}
                  style={{
                    background: "transparent",
                    border: "2px solid #dee2e6",
                    color: "black",
                  }}
                  onChange={handleChange}
                  name="cardNumber"
                  className="form-control rounded"
                  id="cardNum"
                  placeholder="*** *** *** ***"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="date" className="form-label">
                  Expiry Date
                </label>
                <input
                  {...getExpiryDateProps({ onChange: handleChange })}
                  value={cardDetails.expirationDate}
                  name="expirationDate"
                  style={{
                    background: "transparent",
                    border: "2px solid #dee2e6",
                    color: "black",
                  }}
                  className="form-control rounded"
                  onChange={handleChange}
                  id="date"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="cvc" className="form-label">
                  CVC
                </label>
                <input
                  {...getCVCProps({ onChange: handleChange })}
                  value={cardDetails.cvv}
                  name="cvv"
                  style={{
                    background: "transparent",
                    border: "2px solid #dee2e6",
                    color: "black",
                  }}
                  className="form-control rounded"
                  id="cvc"
                  // onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name on Card
                </label>
                <input
                  style={{
                    background: "transparent",
                    border: "2px solid #dee2e6",
                    color: "black",
                  }}
                  type="text"
                  className="form-control rounded"
                  id="name"
                  placeholder="John Doe"
                  name="cardHolderName"
                  value={cardDetails?.cardHolderName}
                  onChange={handleChange}
                />
              </div>
            </div>
            {meta.isTouched && meta.error && (
              <span style={{ color: "red" }}>Error: {meta.error}</span>
            )}

            <div
              style={{
                display: "none",
              }}
              className="form-buttons  gap-3 mt-4"
            >
              <button
                onClick={handleEdit}
                type="submit"
                className="btn btn-primary"
              >
                Edit
              </button>
              <button
                onClick={() => console.log("toggle")}
                className="btn btn-primary"
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={HandleShow}>
            Close
          </Button>
          <Button className="btn btn-primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddNewCard;
