const RecentChatLoader = () => {

    const a = <div className="w-100 d-flex gap-1 align-items-center justify-content-between rounded bg-white shadow-sm p-2" >
        <div className='d-flex justify-content-between  gap-2 w-100'>
            <div className="d-flex align-items-center">
                <div className="shimmer shimmer__square"></div>
            </div>
            <div className='d-flex flex-column w-100 gap-3' >
                <div className="d-flex gap-2 align-items-center">
                    <div className="">
                        <div style={{ width: '50px', height: '50px' }} className="shimmer shimmer__circle"></div>
                    </div>
                    <div className="d-flex flex-column gap-2 p-0 m-0 col-12">
                        <div className="d-flex gap-4 col-4">
                            <div className="shimmer shimmer__line"></div>
                            <div className="d-flex gap-2">
                                <div style={{ width: '15px', height: '15px' }} className="shimmer shimmer__circle"></div>
                                <div style={{ width: '15px', height: '15px' }} className="shimmer shimmer__circle"></div>
                                <div style={{ width: '15px', height: '15px' }} className="shimmer shimmer__circle"></div>
                            </div>
                        </div>
                        <div className="d-flex gap-2 col-2">
                            <div className="shimmer shimmer__line"></div>
                        </div>
                    </div>
                </div>
                <div className="d-flex gap-2">
                    <div style={{ width: '100px', height: '25px' }} className="shimmer shimmer__square"></div>
                    <div style={{ width: '100px', height: '25px' }} className="shimmer shimmer__square"></div>
                    <div style={{ width: '100px', height: '25px' }} className="shimmer shimmer__square"></div>
                    <div style={{ width: '100px', height: '25px' }} className="shimmer shimmer__square"></div>
                    <div style={{ width: '100px', height: '25px' }} className="shimmer shimmer__square"></div>
                </div>
            </div>

        </div>
    </div>

    return <div className="rce-citem">
        <div className="rce-citem-avatar">
            <div className="rce-avatar-container circle large">
                <div style={{ width: '50px', height: '50px' }} className="shimmer shimmer__circle rce-avatar"></div>
            </div>
        </div>
        <div className="rce-citem-body">
            <div className="rce-citem-body--top">
                <div className="rce-citem-body--top-title d-flex gap-1 fw-500">
                    <div style={{ width: '50px', height: '8px' }} className="shimmer shimmer__square"></div>
                </div>
                <div className="rce-citem-body--top-time">
                    <div style={{ width: '20px', height: '8px' }} className="shimmer shimmer__square"></div>
                </div>

            </div>
            <div className="rce-citem-body--bottom">
                <div className="rce-citem-body--bottom-title">
                    <div style={{ width: '75%', height: '8px' }} className="shimmer shimmer__square"></div>
                </div>
                <div className="rce-citem-body--bottom-tools"></div>
                <div className="rce-citem-body--bottom-tools-item-hidden-hover"></div>
                <div className="rce-citem-body--bottom-status">
                    <div style={{ width: '12px', height: '12px' }} className="shimmer shimmer__circle rce-avatar"></div>
                </div>
            </div>
        </div>
    </div>
}


export default RecentChatLoader;