import { AnyAction } from "@reduxjs/toolkit";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Notification } from "../models/Notification";
import { markAsRead } from "../redux/slices/notificationSlice";
import { formatTimeAgo } from "../utils/utils";
import "./styles/NotificationDrawer.css";

type DrawerProps = {
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  notifications: any[];
  unReadNotifications: number;
};

const NotificationDrawer: React.FC<DrawerProps> = ({
  isOpen,
  notifications,
  setIsOpen,
  unReadNotifications,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const [selected, setSelected] = useState<Notification>();
  const [activeList, setActivelist] = useState<"unread" | "all">("unread");

  const dispatch = useDispatch();

  const unRead = notifications?.filter((x) => !x.isRead) || [];
  const notificationList = activeList === "unread" ? unRead : notifications;

  const containerRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleClick = (notification: Notification) => {
    setIsClicked(true);
    setSelected(notification);

    if (!notification.isRead) {
      dispatch(markAsRead(notification._id) as unknown as AnyAction);
    }
  };

  // Add a click event listener to the document
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div
      ref={containerRef}
      className={`drawer  gap-3 d-flex flex-column ${isOpen ? "open" : ""}`}
    >
      <div className="d-flex align-items-center justify-content-between px-3 pt-3">
        {isClicked && (
          <button
            className="border-0 bg-transparent"
            onClick={() => setIsClicked(false)}
          >
            <i className="fa-solid fa-angle-left "></i>
          </button>
        )}
        {!isClicked && (
          <div className="position-relative d-flex align-items-center gap-1">
            <div className="d-flex gap-3 p-0 text-black">
              <div
                onClick={() => {
                  setActivelist("all");
                }}
                className={
                  "cursor-pointer fw-bold " +
                  (activeList === "all" ? "text-primary" : "")
                }
              >
                All notifications
              </div>
              <div
                className={
                  "cursor-pointer fw-bold  " +
                  (activeList === "unread" ? "text-primary" : "")
                }
                onClick={() => {
                  setActivelist("unread");
                }}
              >
                Unread
              </div>
            </div>

            <i role="button" className="fa-solid fa-bell text-black fs-5"></i>
            <div
              style={{
                height: "1rem",
                width: "1rem",
                border: "1px solid var(--primaryColor)",
                top: "0",
                right: "-10px",
              }}
              className="bg-white p-2 rounded-circle d-flex align-items-center small-text justify-content-center position-absolute "
            >
              {unReadNotifications}
            </div>
          </div>
        )}
        <button
          className="border-0 bg-transparent"
          onClick={() => setIsOpen(false)}
        >
          <i className="fa-solid fa-xmark  fs-5"></i>
        </button>
      </div>
      <div
        style={{ overflowY: "auto" }}
        className={`gap-4 px-3 d-flex flex-column ${
          isClicked ? "clicked_notification" : ""
        }`}
      >
        {notificationList &&
          !isClicked &&
          notificationList.map((notification: Notification, index) => (
            <div
              onClick={() => handleClick(notification)}
              className={
                "text-black d-flex flex-column align-items-center py-1 row notification" +
                (notification.isRead ? " read" : "")
              }
              key={index}
            >
              <div className="d-flex justify-content-between align-items-center w-100">
                <span>{notification.title}</span>
                {!notification.isRead && (
                  <i className="fa-solid fa-circle text-primary small-text"></i>
                )}
              </div>
              <div className="d-flex w-100 justify-content-between">
                <span
                  dangerouslySetInnerHTML={{ __html: notification.message }}
                  className={" small-text px-0 text-truncate "}
                ></span>
                <span
                  className={
                    "small-text px-0" +
                    (notification.isRead ? " read" : " text-primary")
                  }
                >
                  {formatTimeAgo(notification.createdAt)}
                </span>
              </div>
            </div>
          ))}
        {selected && isClicked && (
          <div className="d-flex flex-column gap-2">
            <div className="text-primary">{selected.title}</div>
            <div dangerouslySetInnerHTML={{ __html: selected.message }}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationDrawer;
