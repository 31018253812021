import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuthState, getCurrentUser } from "../../redux/slices/authSlice";
import TokenExpiredModal from "../modal/TokenExpiredModal";
import axios from "axios";
import { AnyAction } from "redux";
import { useNavigate } from "react-router-dom";
import {
  addCustomAppointmentSocket,
  editCustomAppointmentSocket,
} from "../../redux/slices/customAppointmentSlice";
import { IAppointment } from "../../models/Appointment";
import socket from "../../socket/socket";
import { Toaster } from "react-hot-toast";
import { useSalon } from "../../context/SalonContext";
import { ISalon } from "../../models/SalonX";
import { isUserProfessional } from "../../models/User";
import {
  ServiceState,
  getServices,
  getUserServices,
} from "../../redux/slices/serviceSlice";
import {
  ServiceNameState,
  getServiceNames,
} from "../../redux/slices/serviceNameSlice";
import { MemberState, getMember } from "../../redux/slices/memberSlice";
import { getSalonNames, saloNameState } from "../../redux/slices/salonSlice";

interface AuthLayoutProps {
  children: React.ReactNode;
  redirect?: boolean;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  redirect,
}: AuthLayoutProps) => {
  const currentUser = useSelector<unknown, AuthState>(
    (state: any) => state.auth
  ).currentUser;

  const members = useSelector<unknown, MemberState>(
    (state: any) => state.myMember
  ).members;

  const serviceNameData = useSelector<unknown, ServiceNameState>(
    (state: any) => state.serviceNames
  ).serviceNames;

  const salonsNameData = useSelector<unknown, saloNameState>(
    (state: any) => state.salonNames
  );

  const services = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).services;
  const userServices = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).userServices;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  const [showTokenExpiredModal, setShowTokenExpiredModal] = useState(false);
  const [tokenExpiredModalData, setTokenExpiredModalData] = useState({
    title: "",
    message: "",
    errorCode: "",
  });

  const { salon, setSalon } = useSalon();

  useEffect(() => {
    if (redirect && !authToken) {
      console.log("not authentified")
      navigate("/login");
    }

    const axiosInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          const requestUrl = error.config.url;

          console.log("connexion error", error);

          if (requestUrl.include("login")) {
            if (error?.response?.data?.errors) {
              const errors: { code: string; message: string } =
                error.response.data.errors;

              const pathName = window.location.pathname;
              if (pathName === "/") return;

              const RESTRICTED_PAGES_PATTERN =
                /^\/(register|login|register-pro|see-professional-account|search|forgot-password|reset-password|contact-us)/;

              if (!RESTRICTED_PAGES_PATTERN.test(pathName)) {
                setTokenExpiredModalData({
                  ...tokenExpiredModalData,
                  title: `Your session has expired`,
                  message: `Please login again to continue using this app.`,
                  errorCode: errors.code,
                });
                setShowTokenExpiredModal(true);
              }
            }
          }
        }
        return Promise.reject(error);
      }
    );

    if (!currentUser && authToken) {
      // Dispatch an action to fetch the current user data

      dispatch(getCurrentUser(authToken) as unknown as AnyAction);
      dispatch(getMember() as unknown as AnyAction);
    }

    if (!members && authToken) {
      dispatch(getMember() as unknown as AnyAction);
    }

    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, []);

  useEffect(() => {
    socket().on("EDIT_CUSTOM_APPOINTMENT", (...args) => {
      console.log("EDIT_CUSTOM_APPOINTMENT", args[0]);
      dispatch(
        editCustomAppointmentSocket(
          args[0] as Partial<IAppointment>
        ) as unknown as AnyAction
      );
    });

    socket().on("NEW_CUSTOM_APPOINTMENT", (...args) => {
      dispatch(
        addCustomAppointmentSocket(
          args[0] as Partial<IAppointment>
        ) as unknown as AnyAction
      );
      window.location.reload();
    });

    return () => {
      socket().disconnect();
    };
  }, []);

  useEffect(() => {
    if (isUserProfessional(currentUser)) {
      const salonId = localStorage.getItem("salonId");
      if (salonId) {
        const salon = currentUser.salons.find((salon: ISalon) => {
          return salon._id === salonId;
        });
        if (salon) {
          localStorage.setItem("salonId", salon._id);
          setSalon(salon);
        }
      } else {
        const salon = currentUser.salons[0];
        localStorage.setItem("salonId", salon._id);
        setSalon(salon);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!serviceNameData) {
      dispatch(getServiceNames("") as unknown as AnyAction);
    }
    if (!salonsNameData) {
      dispatch(getSalonNames("") as unknown as AnyAction);
    }
    if (!services) {
      dispatch(getServices("") as unknown as AnyAction);
    }
    dispatch(getUserServices("") as unknown as AnyAction);
  }, []);

  return (
    <>
      <Toaster position="bottom-right" />
      {children}
      {showTokenExpiredModal && (
        <TokenExpiredModal {...tokenExpiredModalData} />
      )}
    </>
  );
};

export default AuthLayout;
