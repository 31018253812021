export const userReviewDataGood = [
  {
    id: 1,
    title: "user good",
  },
  {
    id: 2,
    title: "user good he ?",
  },
  {
    id: 3,
    title: "user good heehe ?",
  },
  {
    id: 4,
    title: "user good",
  },
];
export const userReviewDataBad = [
  {
    id: 1,
    title: "user bad",
  },
  {
    id: 2,
    title: "user bad yo ?",
  },
];

export const salonReviewDataGood = [
  {
    id: 1,
    title: "salon good ?",
  },
  {
    id: 2,
    title: "salon good",
  },

];
export const salonReviewDataBad = [
  {
    id: 1,
    title: "salon so bad ?",
  },
  {
    id: 2,
    title: "bad salon",
  },
  {
    id: 3,
    title: "salon bad",
  },

];

export const data = [
  {
    id: 1,
    title: "Lorem ipsum dolor",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: "fa-solid fa-comments text-dark fa-2x",
  },
  {
    id: 2,
    title: "Lorem ipsum dolor",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
    icon: "fa-solid fa-bullhorn text-primary fa-2x",
  },
  {
    id: 3,
    title: "Latrice support forum",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    icon: "fa-solid fa-users text-warning fa-2x",
  },
];
export const percentageValues = [
  { id: "1", value: "5%" },
  { id: "2", value: "10%" },
  { id: "3", value: "15%" },
  { id: "4", value: "20%" },
  { id: "5", value: "25%" },
  { id: "6", value: "30%" },
  { id: "7", value: "35%" },
  { id: "8", value: "40%" },
  { id: "9", value: "45%" },
  { id: "10", value: "50%" },
  { id: "11", value: "55%" },
  { id: "12", value: "60%" },
  { id: "13", value: "65%" },
  { id: "14", value: "70%" },
  { id: "15", value: "75%" },
  { id: "16", value: "80%" },
];



export const accordeonData = [
  {
    id: 1,
    icon: "fa-regular fa-bookmark",
    title: "How do I book an appointment?",
    content:
      "To book an appointment, it's very simple, you have 03 options. You can go to the home page and select the salon of your choice (you can choose the personalized hairstyles) then you choose the service that suits you best. the second way to do it is to create a personalized service, suppose you have an idea of the hairstyle you want to have and that it is not listed in our system, it is easy for you to select the salon of your choice and you choose a personalized service and then you wait for the salon to accept or reject your request in the end the last way is to create a post where you describe the hairstyle you wanted to do according to your amount",
  },
  {
    id: 2,
    icon: "fas fa-edit",
    title: " How do I update my profile information ?",
    content:
      "The method to update your profile information depends on the platform you're using. Here are some general steps that might apply. Log in to your account on the platform> Find the profile section. This might be labeled as 'Profile'> Locate the specific information you want to update. This could be your name, email address, password, phone number, bio, profile picture, etc. Different platforms might have these options organized differently > Make your changes in the designated fields",
  },
  {
    id: 3,
    icon: "fa-solid fa-list",
    title: "How do I view my payment history ?",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, reiciendis temporibus. Sequi aperiam suscipit porro. Quisquam sapiente necessitatibus at quidem, numquam quam impedit eius. In beatae voluptatum neque ipsum placeat!",
  },
  {
    id: 4,
    icon: "fa-solid fa-clock-rotate-left",
    title: "How do I cancel or reschedule an appointment?",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, reiciendis temporibus. Sequi aperiam suscipit porro. Quisquam sapiente necessitatibus at quidem, numquam quam impedit eius. In beatae voluptatum neque ipsum placeat!",
  },
];
export const accordionDataBusiness = [
  {
    id: 1,
    icon: "fa-light fa-address-card",
    title: "How do I manage my profile?",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, reiciendis temporibus. Sequi aperiam suscipit porro. Quisquam sapiente necessitatibus at quidem, numquam quam impedit eius. In beatae voluptatum neque ipsum placeat!",
  },
  {
    id: 2,
    icon: "fa-solid fa-list",
    title: "  How do I view my appointment history?",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, reiciendis temporibus. Sequi aperiam suscipit porro. Quisquam sapiente necessitatibus at quidem, numquam quam impedit eius. In beatae voluptatum neque ipsum placeat!",
  },
  {
    id: 3,
    icon: "fa-light fa-stars",
    title: " Do you have a loyalty program?",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, reiciendis temporibus. Sequi aperiam suscipit porro. Quisquam sapiente necessitatibus at quidem, numquam quam impedit eius. In beatae voluptatum neque ipsum placeat!",
  },
];

export const accordionDatas = [
  {
    id: 1,
    icon: "fa-regular fa-bookmark",
    title: "How do I book an appointment?",
    content:
      "To book an appointment, it's very simple, you have 03 options. You can go to the home page and select the salon of your choice (you can choose the personalized hairstyles) then you choose the service that suits you best. the second way to do it is to create a personalized service, suppose you have an idea of the hairstyle you want to have and that it is not listed in our system, it is easy for you to select the salon of your choice and you choose a personalized service and then you wait for the salon to accept or reject your request in the end the last way is to create a post where you describe the hairstyle you wanted to do according to your amount",
  },
  {
    id: 2,
    icon: "fas fa-edit",
    title: " How do I update my profile information",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, reiciendis temporibus. Sequi aperiam suscipit porro. Quisquam sapiente necessitatibus at quidem, numquam quam impedit eius. In beatae voluptatum neque ipsum placeat!",
  },
  {
    id: 3,
    icon: "fa-solid fa-list",
    title: "How do I view my payment history",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, reiciendis temporibus. Sequi aperiam suscipit porro. Quisquam sapiente necessitatibus at quidem, numquam quam impedit eius. In beatae voluptatum neque ipsum placeat!",
  },
  {
    id: 4,
    icon: "fa-solid fa-clock-rotate-left",
    title: "How do I cancel or reschedule an appointment?",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, reiciendis temporibus. Sequi aperiam suscipit porro. Quisquam sapiente necessitatibus at quidem, numquam quam impedit eius. In beatae voluptatum neque ipsum placeat!",
  },
  {
    id: 5,
    icon: "fa-light fa-address-card",
    title: "How do I manage my profile?",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, reiciendis temporibus. Sequi aperiam suscipit porro. Quisquam sapiente necessitatibus at quidem, numquam quam impedit eius. In beatae voluptatum neque ipsum placeat!",
  },
  {
    id: 6,
    icon: "fa-solid fa-list",
    title: "  How do I view my appointment history?",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, reiciendis temporibus. Sequi aperiam suscipit porro. Quisquam sapiente necessitatibus at quidem, numquam quam impedit eius. In beatae voluptatum neque ipsum placeat!",
  },
  {
    id: 7,
    icon: "fa-light fa-stars",
    title: " Do you have a loyalty program?",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium, reiciendis temporibus. Sequi aperiam suscipit porro. Quisquam sapiente necessitatibus at quidem, numquam quam impedit eius. In beatae voluptatum neque ipsum placeat!",
  },
];
