import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "../styles/DiscountSeason.css";
import CountDown from "./CountDown";

interface DiscountSeasonProps {
  open: boolean;
  isValid?: boolean;
  onClose: () => void;
  targetDate: Date;
}

export const DiscountSeason = ({
  open = false,
  onClose,
  targetDate,
}: DiscountSeasonProps) => {
  return (
    <>
      <div className="discount-container">
        <div className="discount-text">
          <p className="discount-text-paraph">
            <span className="fw-bold">Enjoy 20% 🎉🎉</span> off our hair salon
            booking and other services on Latrice. <br />
            <CountDown targetDate={new Date(targetDate)} />
          </p>
        </div>
        <div className="discount-icon">
          <FontAwesomeIcon icon={faXmark} onClick={onClose} />
        </div>
      </div>
    </>
  );
};
