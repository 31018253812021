import React, { useRef, useState } from "react";
import ModalLoader from "../loader/ModalLoader";
import Form from "react-bootstrap/Form";
import CustomDropdownPrice from "../promotion/CustomDropdownPrice";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { AdminSettingService } from "../../services/AdminSettingService";
import { useLatriceUser } from "../../hooks/useLatriceUser";
import { useSalon } from "../../context/SalonContext";

const PromotionSalonSetting = () => {
  const dispatch = useDispatch();
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const LatriceUser = useLatriceUser();
  const [searchService, setSearchService] = useState("");
  const [searchReward, setSearchReward] = useState("");
  const salon = useSalon();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{
    activatePromotion: boolean | undefined;
    rewardPriceIndependent: number | undefined;
    rewardPriceSalon: number | undefined;
    nbrServiceIndependent: number | undefined;
    nbrServiceSalon: number | undefined;
  }>({
    activatePromotion: salon
      ? salon.salon?.salon_rewards?.activatePromotion
      : false,
    rewardPriceIndependent: salon
      ? salon.salon?.salon_rewards?.rewardPriceIndependent
      : undefined,
    rewardPriceSalon: salon
      ? salon.salon?.salon_rewards?.rewardPriceSalon
      : undefined,
    nbrServiceIndependent: salon
      ? salon.salon?.salon_rewards?.nbrServiceIndependent
      : undefined,
    nbrServiceSalon: salon
      ? salon.salon?.salon_rewards?.nbrServiceSalon
      : undefined,
  });
  const handleChangeStatus = () => {
    setData((prevData) => ({
      ...prevData,
      activatePromotion: !prevData.activatePromotion,
    }));
  };

  const handleSendData = async () => {
    console.log("sending data", data);
    setLoading(true);

    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute("disabled", "true");
      await AdminSettingService.salonRewardPromotion(data)
        .then(async (result: any) => {
          toast.success("Update with success");
          console.log("The result", result);
        })
        .catch((err: any) => {
          toast.error(
            "An error occured during edition, please try egain later"
          );
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            if (submitBtnRef.current) {
              submitBtnRef.current.removeAttribute("disabled");
            }
          }, 2000);
        });
    }
  };

  const keyExtractor = (item: any) => item.id;
  const renderRewardPrice = (item: any) => <span>{item.value}</span>;
  const renderNbrService = (item: any) => <span>{item.value}</span>;

  const handleChangeRewardIndependent = (rewardPrice: number) => {
    console.log("reward price independent", rewardPrice);

    setData((prevData) => ({
      ...prevData,
      rewardPriceIndependent: rewardPrice,
    }));
  };
  const handleChangeNumberServiceIndependent = (nbrService: number) => {
    console.log("nbrService independent", nbrService);

    setData((prevData) => ({
      ...prevData,
      nbrServiceIndependent: nbrService,
    }));
  };

  const handleChangeRewardPriceSalon = (rewardPrice: number) => {
    console.log("reward price salon", rewardPrice);

    setData((prevData) => ({
      ...prevData,
      rewardPriceSalon: rewardPrice,
    }));
  };
  const handleChangeNumberServiceSalon = (nbrService: number) => {
    console.log("nbrService salon", nbrService);

    setData((prevData) => ({
      ...prevData,
      nbrServiceSalon: nbrService,
    }));
  };

  return (
    <div
      className="row m-0 mt-3 flex-column "
      style={{ border: "2px solid black" }}
    >
      {loading && <ModalLoader />}
      <div className="col-sm bg-white p-3 pt-4 mt-3 ">
        <h2 className=" fw-bold text-black fs-5">
          Promotions that apply to salons
        </h2>

        <div className=" my-3">
          <i className="fa-solid fa-pen text-muted"></i>
          <span className="ms-2 text-muted ">
            Modify promotional information on salons
          </span>
        </div>
        <div className="d-flex gap-4 mt-3 flex-wrap ">
          <div className=" gap-1 d-flex align-items-start flex-column ">
            <div className=" d-flex ">
              <span className="text-black">Desactivate this promotion</span>
            </div>
            <div className=" d-flex flex-column w-100 mt-3">
              <Form.Check
                type="switch"
                className="mark-button"
                label=""
                onClick={handleChangeStatus}
                checked={data.activatePromotion}
              />
            </div>
          </div>
        </div>
        <div className="d-flex gap-4 mt-3  ">
          <div className=" gap-1 d-flex align-items-start flex-column ">
            <div className=" d-flex ">
              <span className="text-black">
                Change the numbers of service for independent
              </span>
            </div>
            <div className=" d-flex flex-column w-100 mt-1">
              <input
                autoComplete="off"
                // onBlur={handleBlur}
                // onFocus={handleFocus}
                type="number"
                className="w-100 border border-1 rounded-2 p-3 h-100 fs-6"
                onChange={(e) => {
                  const nbrService = parseInt(e.target.value);
                  handleChangeNumberServiceIndependent(nbrService);
                }}
                value={data.nbrServiceIndependent}
                placeholder="Change de service number"
                name="nbrServiceIndependent"
                id="nbrServiceIndependent"
                min="0"
                disabled={false}
              />
            </div>
          </div>
          <div className=" gap-1 d-flex align-items-start flex-column ">
            <div className=" d-flex ">
              <span className="text-black">
                Changing the reward amount for independent
              </span>
            </div>
            <div className=" d-flex flex-column w-100 mt-1">
              <input
                autoComplete="off"
                // onBlur={handleBlur}
                // onFocus={handleFocus}
                type="number"
                className="w-100 border border-1 rounded-2 p-3 h-100 fs-6"
                onChange={(e) => {
                  const rewardPrice = parseInt(e.target.value);
                  handleChangeRewardIndependent(rewardPrice);
                }}
                value={data.rewardPriceIndependent}
                placeholder="Change de reward price for independent"
                name="rewardPriceIndependent"
                min="0"
                id="rewardPriceIndependent"
                disabled={false}
              />
            </div>
          </div>
        </div>
        <div className="d-flex gap-4 mt-3  ">
          <div className=" gap-1 d-flex align-items-start flex-column ">
            <div className=" d-flex ">
              <span className="text-black">
                Change the numbers of service for salon
              </span>
            </div>
            <div className=" d-flex flex-column w-100 mt-1">
              <input
                autoComplete="off"
                // onBlur={handleBlur}
                // onFocus={handleFocus}
                type="number"
                className="w-100 border border-1 rounded-2 p-3 h-100 fs-6"
                onChange={(e) => {
                  const nbrService = parseInt(e.target.value);
                  handleChangeNumberServiceSalon(nbrService);
                }}
                value={data.nbrServiceSalon}
                placeholder="Change de service number"
                name="nbrServiceSalon"
                id="nbrServiceSalon"
                min="0"
                disabled={false}
              />
            </div>
          </div>
          <div className=" gap-1 d-flex align-items-start flex-column ">
            <div className=" d-flex ">
              <span className="text-black">
                Changing the reward amount for salon
              </span>
            </div>
            <div className=" d-flex flex-column w-100 mt-1">
              <input
                autoComplete="off"
                // onBlur={handleBlur}
                // onFocus={handleFocus}
                type="number"
                className="w-100 border border-1 rounded-2 p-3 h-100 fs-6"
                onChange={(e) => {
                  const rewardPrice = parseInt(e.target.value);
                  handleChangeRewardPriceSalon(rewardPrice);
                }}
                value={data.rewardPriceSalon}
                placeholder="Change de reward price"
                name="rewardPriceSalon"
                min="0"
                id="rewardPriceSalon"
                disabled={false}
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-5">
          <span className="">Lorem ipsum, dolor sit amet consectetur</span>
          <button
            disabled={false}
            type="submit"
            ref={submitBtnRef}
            onClick={handleSendData}
            className="button-57 rounded  px-3 p-2"
          >
            <span>Done</span>
            <span>Save changes</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromotionSalonSetting;
