import React, { useEffect, useRef, useState } from "react";
import MainNav from "../components/navigation/MainNav";
import { NavLink, useLocation } from "react-router-dom";
import Stepper from "../components/Stepper";
import OtpInput from "react18-input-otp";
import Nuggest from "../components/Nugget";
import Button from "../components/Button";
import User, { IUserRegistration } from "../models/User";
import { AuthService } from "../services/AuthService";
import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import { log } from "../utils/utils";
import ImageSlider from "../components/reusable/imageSlider/ImageSlider";
import { slides } from "../components/reusable/imageSlider/Slides";
import {
  initialValues,
  validationSchema,
} from "../components/validation/RegisterValidation";
import PhoneNumberValidation from "../components/reusable/PhoneNumberValidation ";
import ModalWelcome from "../components/promotion/ModalWelcome";

const RegisterPage: React.FC = () => {
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const confirmBtnRef = useRef<HTMLButtonElement>(null);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpTimer, setOtpTimer] = useState(0);
  const [onSendBackOTP, setOnSendBackOTP] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeStep, setActiveStep] = useState(
    localStorage.getItem("lastSendBackOTPDate") === null ? 0 : 1
  );

  const registerSteps = ["account", "validation", "started"];
  const location = useLocation();

  const param = new URLSearchParams(location.search);
  const referralCode = param.get("referralCode");
  if (referralCode) {
    localStorage.setItem("referralCode", referralCode);
    console.log("referralCode", referralCode);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = "loading...";

      const user: IUserRegistration = {
        first_name: formik.values.firstname as string,
        last_name: formik.values.lastname as string,
        email: formik.values.email as string,
        phone: formik.values.phone as string,
        password: formik.values.password as string,
      };

      AuthService.register(user)
        .then((response: AxiosResponse) => {
          // Handle the resolved value (response) here
          if (response?.headers?.authorization) {
            const authHeader = response.headers.authorization as string;
            const authHeaderToken = authHeader.replace("Bearer ", "");
            localStorage.setItem("password", user.password);
            localStorage.setItem("email", user.email);
            localStorage.setItem("authToken", authHeaderToken);
            localStorage.setItem(
              "lastSendBackOTPDate",
              `${Math.floor(Date.now() / 1000)}`
            );

            startTimer();

            setActiveStep((prevStep) => {
              const nextStep = prevStep + 1;
              return nextStep > registerSteps.length - 1 ? 0 : nextStep;
            });
          }
        })
        .catch((error: any) => {
          // Handle any errors that occurred during registration
          console.log(error);
          if (error?.response?.data) {
            formik.setErrors(error.response.data.errors);
          }
        })
        .finally(() => {
          if (submitBtnRef.current) {
            submitBtnRef.current.removeAttribute("disabled");
            submitBtnRef.current.innerHTML = "Next step";
          }
        });
    }
  };

  // handle validation with formik and yup
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      log(JSON.stringify(values, null, 2));
    },
  });

  const formSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0) {
      handleSubmit(e);
    }
  };

  const handleConfirm = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (confirmBtnRef.current) {
      confirmBtnRef.current.setAttribute("disabled", "true");
      confirmBtnRef.current.innerHTML = "loading...";

      const password = localStorage.getItem("password") as string;
      const email = localStorage.getItem("email") as string;

      AuthService.verifyOtpCode({ email, password, otp: Number(otp) })
        .then((data) => {
          localStorage.removeItem("password");
          localStorage.removeItem("email");
          localStorage.removeItem("lastSendBackOTPDate");
          localStorage.removeItem("referralCode");
          setActiveStep((prevStep) => {
            const nextStep = prevStep + 1;
            return nextStep > registerSteps.length - 1 ? 0 : nextStep;
          });
        })
        .catch((error) => {
          formik.setErrors(error.response.data.errors);
        })
        .finally(() => {
          if (confirmBtnRef.current) {
            confirmBtnRef.current.removeAttribute("disabled");
            confirmBtnRef.current.innerHTML = "Confirm your registration";
          }
        });
    }
  };

  // Function to start the timer
  const startTimer = () => {
    const OTP_TIMEOUT = 60; // Timeout in seconds
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const lastSendBackOTPDate = localStorage.getItem("lastSendBackOTPDate");
    const timeDifference = currentTime - Number(lastSendBackOTPDate);

    if (timeDifference < OTP_TIMEOUT) {
      const remainingTime = OTP_TIMEOUT - timeDifference;
      setOtpTimer(remainingTime);
    } else {
      setOtpTimer(0);
    }
  };

  const handleResendOTP = () => {
    if (otpTimer > 0 || onSendBackOTP) return;
    // Handle the logic to resend the OTP validation code
    // ...
    const email = localStorage.getItem("email");
    const password = localStorage.getItem("password");
    AuthService.resendOtpCode({
      email: email as string,
      password: password as string,
    })
      .then(() => {
        // Update the timer after resending the OTP
        setOtpTimer(60); // Set the timer to 60 seconds
        localStorage.setItem(
          "lastSendBackOTPDate",
          `${Math.floor(Date.now() / 1000)}`
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setOnSendBackOTP(false);
      });
  };

  useEffect(() => {
    startTimer(); // Start the timer on component mount
  }, []);

  useEffect(() => {
    // Update the timer every second
    if (otpTimer > 0) {
      const timerId = setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
      return () => clearTimeout(timerId); // Clear the timer on component unmount
    }
  }, [otpTimer]);

  useEffect(() => {
    if (activeStep === 2 && !localStorage.getItem("modalShown")) {
      const timer = setTimeout(() => {
        setShowModal(true);
        localStorage.setItem("modalShown", "true");
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [activeStep]);



  const isFormValid =
    formik.isValid && Object.keys(formik.touched).length > 0 && termsAccepted;

  return (
    <>
      <MainNav navType={"homeNav"} />
      <div className="p-0 m-0 Login d-flex w-100">
        {activeStep !== -2 && (
          <div className="position-relative loginLeftSide">
            <ImageSlider slides={slides} />
          </div>
        )}
        <div className="d-flex flex-column w-100">
          <div
            className={
              " " + (activeStep === 2 ? "loginRightSide--autoHeight" : "")
            }
          >
            <div className="p-lg-0 my-0 my-lg-12 ">
              <div className="d-flex flex-column justify-content-around">
                <div className="row d-flex align-items-center mx-0 mx-lg-5">
                  <div className="col-12 col-xl-10 col-xxl-9">
                    {activeStep === 0 && (
                      <div className="d-flex justify-content-end mt-0 mt-lg-5">
                        <span>
                          <NavLink
                            className="nav-link nav-link-inline nav-link-underline-hover fw-500"
                            to={"/login"}
                          >
                            Already have an account?{" "}
                          </NavLink>
                        </span>
                      </div>
                    )}
                    <div className="mt-2">
                      <h1 className="text-black d-inline">Step By Step </h1>
                      <span className="small">
                        {activeStep === 0 && <> Please Fill In Your Details</>}
                        {activeStep !== 0 && <> It's almost there</>}
                      </span>
                    </div>
                    <Stepper
                      steps={registerSteps}
                      active={activeStep}
                      className="my-3"
                    />
                    <div>
                      {activeStep === 0 && (
                        <form onSubmit={formSubmit}>
                          <div className="form-floating mt-2">
                            <input
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email ?? ""}
                              required
                              type="email"
                              className="form-control fw-500"
                              id="email"
                              name="email"
                            />
                            {formik.errors.email ? (
                              <div style={{ color: "var(--primaryColor)" }}>
                                {formik.errors.email}
                              </div>
                            ) : null}
                            <label htmlFor="email">Email address</label>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div className="input-group mt-2">
                                <input
                                  placeholder="First name*"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.firstname ?? ""}
                                  required
                                  type="text"
                                  className="form-control fw-500"
                                  id="firstname"
                                  name="firstname"
                                />
                              </div>
                              {formik.errors.firstname ? (
                                <div style={{ color: "var(--primaryColor)" }}>
                                  {formik.errors.firstname}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-6">
                              <div className="input-group mt-2">
                                <input
                                  placeholder="Last name*"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.lastname ?? ""}
                                  required
                                  type="text"
                                  className="form-control fw-500"
                                  id="lastname"
                                  name="lastname"
                                />
                              </div>
                              {formik.errors.lastname ? (
                                <div style={{ color: "var(--primaryColor)" }}>
                                  {formik.errors.lastname}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div
                            className="input-group my-1 col-6"
                            aria-autocomplete="none"
                          >
                            <div>
                              <PhoneNumberValidation
                                onChangeValue={(value: string) => {
                                  formik.setFieldValue("phone", value);
                                  console.log("value", value);
                                }}
                              />
                            </div>
                          </div>
                          {formik.errors.phone ? (
                            <div style={{ color: "var(--primaryColor)" }}>
                              {formik.errors.phone}
                            </div>
                          ) : null}

                          <div
                            className="input-group my-2"
                            aria-autocomplete="none"
                          >
                            <input
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password ?? ""}
                              required
                              type={passwordVisible ? "text" : "password"}
                              className="form-control fw-500"
                              id="password"
                              name="password"
                              placeholder="password"
                            />

                            <button
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                              className="btn"
                              type="submit"
                              id="togglePasswordVisibility"
                            >
                              <i
                                className={
                                  passwordVisible
                                    ? "fa fa-eye-slash"
                                    : "fa fa-eye"
                                }
                              ></i>
                            </button>
                          </div>
                          {formik.touched.password && formik.errors.password ? (
                            <div style={{ color: "var(--primaryColor)" }}>
                              {formik.errors.password}
                            </div>
                          ) : null}

                          <div
                            className="input-group my-2"
                            aria-autocomplete="none"
                          >
                            <input
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.confirmPassword}
                              required
                              type={
                                confirmPasswordVisible ? "text" : "password"
                              }
                              className="form-control fw-500"
                              id="confirmPassword"
                              placeholder="confirm password*"
                              name="confirmPassword"
                            />
                            <button
                              onClick={() =>
                                setConfirmPasswordVisible(
                                  !confirmPasswordVisible
                                )
                              }
                              className="btn"
                              type="button"
                              id="togglePasswordVisibility"
                            >
                              <i
                                className={
                                  confirmPasswordVisible
                                    ? "fa fa-eye-slash"
                                    : "fa fa-eye"
                                }
                              ></i>
                            </button>
                          </div>
                          {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword ? (
                            <div style={{ color: "var(--primaryColor)" }}>
                              {formik.errors.confirmPassword}
                            </div>
                          ) : null}
                          <div className="form-group form-check">
                            <input
                              required
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheck1"
                              checked={termsAccepted}
                              onChange={(e) =>
                                setTermsAccepted(e.target.checked)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                            >
                              By clicking create an account, i agree i have read
                              and accepted the Terms of Use and Privacy policy.
                            </label>
                          </div>
                          <div className="small mt-3">
                            Protected by reCAPTCHA and subject to the{" "}
                            <NavLink
                              className="nav-link nav-link-underline-hover active nav-link-inline"
                              to={"/forgot-password"}
                            >
                              Latrice privacy policy{" "}
                            </NavLink>
                            and{" "}
                            <NavLink
                              className="nav-link nav-link-inline nav-link-underline-hover active"
                              to={"/forgot-password"}
                            >
                              Terms of service.
                            </NavLink>
                          </div>
                          <div className="d-flex justify-content-end align-items-end mt-4">
                            <div>
                              <button
                                ref={submitBtnRef}
                                type="submit"
                                className="btn btn-primary px-4"
                                disabled={!isFormValid}
                              >
                                Next step
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                      {activeStep === 1 && (
                        <form>
                          <h6 className="mt-5">
                            Please enter the validation code received in your
                            email address
                          </h6>
                          <div className="p-0 m-0 mt-5">
                            <OtpInput
                              value={otp}
                              onChange={(code: any) => {
                                setOtp(code);
                              }}
                              numInputs={5}
                              separator={<span className="m  x-3"></span>}
                              containerStyle={"d-flex justify-content-between"}
                              inputStyle={"w-100 mx-2 form-control"}
                            />
                            {formik.errors.otp ? (
                              <div style={{ color: "var(--primaryColor)" }}>
                                {formik.errors.otp}
                              </div>
                            ) : null}
                          </div>
                          <div className="small mt-3">
                            <span
                              onClick={handleResendOTP}
                              role={"button"}
                              className={`nav-link fw-500 ${
                                otpTimer > 0
                                  ? ""
                                  : "active nav-link-underline-hover"
                              }`}
                              style={{ cursor: "pointer" }}
                            >
                              {otpTimer > 0
                                ? `Resend OTP in ${otpTimer} seconds`
                                : onSendBackOTP
                                ? "sending..."
                                : "Send back the validation code"}
                            </span>
                          </div>
                          <div className="d-flex justify-content-end align-items-end mt-4">
                            <div>
                              <button
                                disabled={
                                  !(otp !== null && (otp + "").length === 5)
                                }
                                ref={confirmBtnRef}
                                type="button"
                                onClick={handleConfirm}
                                className="btn btn-primary px-4"
                              >
                                Confirm your registration
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container p-lg-0">
            {activeStep === 2 && (
              <div>
                <div className="d-flex justify-content-end">
                  <NavLink to={"/client-profile"}>
                    <Button
                      mode={"default"}
                      rounded={true}
                      content={
                        <i className="fa-sharp fa-solid fa-arrow-right"></i>
                      }
                      style={{ width: "40px", height: "40px" }}
                    />
                  </NavLink>
                </div>
                <h4 className="fw-bold text-black">Nuggets of our selection</h4>
                <div className="NuggestContainer d-flex flex-column flex-md-row">
                  {["", "", ""].map((_, key) => (
                    <Nuggest
                      key={key}
                      image={"https://via.placeholder.com/150"}
                      title={"Bryce Vaughn"}
                      localisation={"1841 Hardman Road, Vermont"}
                      stars={5.0}
                      notice={46}
                    />
                  ))}
                </div>
                <div className="d-flex justify-content-end my-4">
                  <NavLink to={"/client-profile"}>
                    <Button
                      mode={"primary"}
                      onClick={() => setShowModal(true)}
                      content={<span className="mx-3">Let's go</span>}
                    />
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalWelcome show={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default RegisterPage;
