import React, { useEffect, useState } from "react";
import moment from "moment";
import "../styles/ManageShedule.css";
import "../styles/UpcomingHours.css";

interface DateRange {
  value: moment.Moment;
  active: boolean;
}

const getThisWeekDatesRange = (initialDate = moment()): DateRange[] => {
  const weekStartDate = (initialDate: moment.Moment): moment.Moment => {
    const dayOfWeek = initialDate.day() === 0 ? 6 : initialDate.day() - 1;
    return initialDate.clone().subtract(dayOfWeek, "days");
  };

  const ranges: DateRange[] = [];

  for (let i = 0; i < 7; i++) {
    const currentDate = weekStartDate(initialDate).clone().add(i, "days");
    ranges.push({ value: currentDate, active: false });
  }

  return ranges;
};

export type DayTimes = { [key: string]: { start: string; end: string } };

interface UpcomingHoursProps {
  onChange: (times: DayTimes) => void;
  values?: DayTimes;
}

const UpcomingHours: React.FC<UpcomingHoursProps> = ({
  onChange,
  values = {},
}) => {
  const months = moment.months();

  const [currentWeekRange, setCurrentWeekRange] = useState<DateRange[]>([]);

  const getCurrentWeekLabel = (weeks: DateRange[]): string | null => {
    if (weeks.length < 2) return null;
    const startDate = weeks[0].value;
    const endDate = weeks[6].value;
    const label =
      `${months[startDate.month()]} ${startDate.date()} - ` +
      ` ${months[endDate.month()]} ${endDate.date()}, ${endDate.year()}`;
    return label;
  };

  const availableTimes = Array(24 * 2)
    .fill(0)
    .map((_, index) => {
      const hour = Math.floor(index / 2);
      const isAM = hour < 12;
      const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
      const minute = index % 2 === 0 ? "00" : "30";
      const period = isAM ? "AM" : "PM";

      return `${displayHour}:${minute} ${period}`;
    });

  const timeOptions = (
    data: string[],
    maxTime: string | null = null,
    minTime: string | null = null
  ) => {
    return data.map((item) => {
      if (maxTime) {
        const currentTime = moment(item, "hh:mm A");
        const maxAllowedTime = moment(maxTime, "hh:mm A");

        if (currentTime.isAfter(maxAllowedTime, "minute")) {
          return (
            <option value={item} key={item}>
              {item}
            </option>
          );
        }

        return null; // Exclude the option if it's after the maxAllowedTime
      } else if (minTime) {
        const currentTime = moment(item, "hh:mm A");
        const minAllowedTime = moment(minTime, "hh:mm A");

        if (currentTime.isBefore(minAllowedTime, "minute")) {
          return (
            <option value={item} key={item}>
              {item}
            </option>
          );
        }

        return null; // Exclude the option if it's after the maxAllowedTime
      }
      return (
        <option value={item} key={item}>
          {item}
        </option>
      );
    });
  };

  const [times, setTimes] = useState<DayTimes>(values);

  const handleEditStartTime = (range: DateRange, time: string) => {
    const timesClone = { ...times };
    const day = range.value.format("YYYY-MM-DD");
    timesClone[day] = { ...timesClone[day], start: time };
    setTimes(timesClone);
  };

  const handleEditEndTime = (range: DateRange, time: string) => {
    const timesClone = { ...times };
    const day = range.value.format("YYYY-MM-DD");
    timesClone[day] = { ...timesClone[day], end: time };
    setTimes(timesClone);
  };

  const handleDayActiveChange = (item: DateRange): void => {
    const dataClone = currentWeekRange.map((itemClone) =>
      moment(itemClone.value).date() === moment(item.value).date()
        ? { ...item, active: !item.active }
        : itemClone
    );
    if (item.active) {
      const timesClone = { ...times };
      const day = item.value.format("YYYY-MM-DD");
      delete timesClone[day];
      setTimes(timesClone);
      onChange(timesClone);
    }
    setCurrentWeekRange(dataClone);
  };

  const handleNextWeek = (): void => {
    const startDate = moment(currentWeekRange[6].value).add(1, "day").toDate();
    setCurrentWeekRange(
      getThisWeekDatesRange(moment(startDate)).map((range) => {
        if (times[range.value.format("YYYY-MM-DD")]) {
          range.active = true;
        }
        return range;
      })
    );
  };

  const handlePrevWeek = (): void => {
    const weekStart = moment(currentWeekRange[0].value)
      .subtract(2, "days")
      .toDate();
    setCurrentWeekRange(
      getThisWeekDatesRange(moment(weekStart)).map((range) => {
        if (times[range.value.format("YYYY-MM-DD")]) {
          range.active = true;
        }
        return range;
      })
    );
  };

  useEffect(() => {
    setCurrentWeekRange(
      getThisWeekDatesRange().map((range) => {
        if (times[range.value.format("YYYY-MM-DD")]) {
          range.active = true;
        }
        return range;
      })
    );
  }, []);

  useEffect(() => {
    onChange(times);
  }, [times]);



  return (
    <div className="ManageShedule">
      <div className="p-2">
        WE WILL ADD SEATING PLACE SELECTOR HERE !
        <div className="WeekSlider d-flex align-items-center justify-content-between p-2 px-3 border mb-3">
          <div className="WeekSliderArrow" onClick={handlePrevWeek}>
            <i className="fa fa-angle-left fa-2x"></i>
          </div>
          <div className="WeekSliderContainer d-flex justify-content-center align-items-center">
            {currentWeekRange.length > 0 &&
              getCurrentWeekLabel(currentWeekRange)}
          </div>
          <div className="WeekSliderArrow" onClick={handleNextWeek}>
            <i className="fa fa-angle-right fa-2x"></i>
          </div>
        </div>

        <div className="d-flex flex-column gap-2">
          {currentWeekRange.map((item, key) => {
            return (
              <div key={item.value.toLocaleString()}>
                <div className="UpcomingHourItem w-100">
                  <span
                    className={"fw-500" + (item.active ? " text-primary" : "")}
                  >
                    {item.value.format("dddd")}
                  </span>
                  <span className="small text-muted">
                    {item.value.format("MMM")} {item.value.format("DD")},{" "}
                    {item.value.format("YYYY")}
                  </span>
                  <div>
                    <div className="form-check form-switch d-flex justify-content-end">
                      <input
                        onChange={() => handleDayActiveChange(item)}
                        checked={item.active}
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`d-flex justify-content-between w-100 UpcomingHourItem__time ${item.active ? "UpcomingHourItem__time--open" : ""
                    }`}
                >
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      Start
                    </label>
                    <select
                      onChange={(e: any) =>
                        handleEditStartTime(item, e.target.value as string)
                      }
                      value={times[item.value.format("YYYY-MM-DD")]?.start}
                      className="form-select form-select-sm"
                      name=""
                      id=""
                    >
                      {timeOptions(
                        availableTimes,
                        null,
                        times[item.value.format("YYYY-MM-DD")]?.end
                      )}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label">
                      End
                    </label>
                    <select
                      onChange={(e: any) =>
                        handleEditEndTime(item, e.target.value as string)
                      }
                      value={times[item.value.format("YYYY-MM-DD")]?.end}
                      className="form-select form-select-sm"
                      name=""
                      id=""
                    >
                      {timeOptions(
                        availableTimes,
                        times[item.value.format("YYYY-MM-DD")]?.start
                      )}
                    </select>
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
        </div>

      </div>
    </div>
  );
};


export default UpcomingHours;

export { getThisWeekDatesRange };