import React from 'react';
import { IChair } from '../../../models/Chair';
import VerticalChairSelector, { VerticalChairSelectorProps } from '../../chat/VerticalChairSelector';

type ChairSelectionFormProps = {
    chairs: IChair[];
    onChairChange: (chair: IChair) => void;
} & Pick<VerticalChairSelectorProps, 'defaultSelectedChair'>


const ChairSelectionForm: React.FC<ChairSelectionFormProps> = ({
    chairs,
    onChairChange,
    defaultSelectedChair
}) => {

    const handleChange = (chair: IChair) => {
        onChairChange(chair)
    }



    return (
        <div className="mb-3">
            <label htmlFor="" className="form-label text-primary fw-500">
                With which chair? *
            </label>
            <div className="d-flex flex-column justify-content-center">

                <div className="w-100 d-flex justify-content-start overflow-auto">
                    <VerticalChairSelector defaultSelectedChair={defaultSelectedChair} chairs={chairs} onSelectChair={handleChange} />
                </div>
            </div>
        </div>
    );
};

export default ChairSelectionForm;