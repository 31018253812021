import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.css";

interface Slide {
  image: string;
  text1: string;
  text2: string;
  icon1: string;
  icon2: string;
}

interface ImageSliderProps {
  slides: Slide[];
}

const ImageSlider: React.FC<ImageSliderProps> = ({ slides }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 3000, // Adjust the interval between slides (in milliseconds)
    arrows: false, // Remove arrows
  };

  return (
    <div className="image-slider">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slider-item">
            <img src={slide.image} alt={`Slide ${index}`} />
            <div className="img-overlay"></div>
            <div className="slider-text">
              <div className="floatingMessage shadow mx-auto">
                <div className="icon btn-secondary">
                  <i className={slide.icon1}></i>
                </div>
                <div className="d-flex align-items-center justify-content-center ms-2">
                  {slide.text1}
                </div>
              </div>
              <div className="floatingMessage floatingMessage--inactive shadow mx-auto">
                <div className="icon btn-secondary">
                  <i className={slide.icon2}></i>
                </div>
                <div className="d-flex align-items-center justify-content-center ms-2">
                  {slide.text2}
                </div>
              </div>
            </div>
            <div className="slider-dots">
              <ul>
                {slides.map((_, dotIndex) => (
                  <li
                    key={dotIndex}
                    className={index === dotIndex ? "active" : ""}
                  ></li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
