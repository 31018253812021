import React from 'react';
import Button from '../Button';

interface Step {
  title: string;
  details: string;
  icon: string;
}

interface StepListProps {
  steps: Step[];
  activeStep: number;
}

const StepList: React.FC<StepListProps> = ({ steps, activeStep  }) => {
  return (
    <div>
      {steps.map(({ title, details, icon }, key) => (
        <div key={title} style={{ marginTop: '35px' }} className="d-flex justify-content-between gap-4 w-100">
          <div className="d-flex flex-column">
            

            <h6  className={`p-0 m-0 text-black cursor-pointer ${activeStep === key ? 'text-primary' : ''}`}>
              {title}
            </h6>
            <div className="small text-muted text-black fw-400">{details}</div>
          </div>
          <div>
            <Button
              className="text-white"
              style={{
                height: '40px',
                width: '40px',
                border: 'none',
                backgroundColor: activeStep === key ? 'var(--primaryColor)' : 'var(--yellowColor)',
              }}
              mode="default"
              rounded
              content={<i className={icon}></i>}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepList;