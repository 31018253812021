import React, { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import "../styles/AddMember.css";
import { useFormik } from "formik";
import {
  FamilyFriendInitialValues,
  FamilyFriendValidationSchema,
} from "../validation/FamilyFriendValidations";
import Member from "../../models/Member";
import { MemberService } from "../../services/MemberService";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { MemberState, addMember } from "../../redux/slices/memberSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { useUser } from "../../hooks/UseUser";
import { useMember } from "../../hooks/useMember";
import MemberNoExist from "./MemberNoExist";
import { result } from "lodash";

type Props = {
  open: boolean;
  onClose: () => void;
  onFailled:()=>void;
  handleRefetchMembers: () => void;
};

const AddMemberModal = ({ open, onClose,onFailled, handleRefetchMembers }: Props) => {
  const user = useUser();
  const errorMember = useMember();
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const styles = {
    borderRadius: "0.5rem",
    border: "1px solid #DCDBDD",
    background: "#fff",
    fontSize: "1rem",
    height: "3rem",
    padding: "0.2rem",
  };

  const formik = useFormik({
    initialValues: FamilyFriendInitialValues,
    validationSchema: FamilyFriendValidationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const formSubmit = (e: any) => {
    console.log("test test");
    e.preventDefault();
    e.stopPropagation();
    formik.handleSubmit();
    if (Object.keys(formik.errors).length === 0) {
      handleSubmit(e);
      // formik.resetForm();
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("test 1");

    if (submitBtnRef.current) {
      console.log("test 2");

      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = "loading...";

      console.log(formik.errors);

      const member: Partial<Member> = {
        relation: formik.values.relation,
        userName: formik.values.userName,
        email: formik.values.email,
      };

      console.log("Here is the member data", member);

      dispatch(addMember(member) as unknown as AnyAction)
        .unwrap()
        .then((res: any) => {
          toast.success("We have successful added this member");
          handleRefetchMembers();
          formik.resetForm();

          onClose && onClose();
        })
        .catch((err: any) => {
          console.log("error", err);
          // if (err.errors.code === "USER_MEMBER_NOT_EXIT") {
          if (err) {
            onFailled && onFailled();

            toast.error("This user didn't exit");
          } else {
            toast.error("something went wrong");
          }
        })

        .finally(() => {
          if (submitBtnRef.current) {
            submitBtnRef.current.removeAttribute("disabled");
            submitBtnRef.current.innerHTML = "Save";
          }
        });
    }
  };

  // Check if the form is valid
  const isFormValid = formik.isValid && Object.keys(formik.touched).length > 0;

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="add_member position-absolute top-50 start-50 translate-middle"
    >
      <div className="modal_container ">
        <h4 className="text-center text-black ">Add a member</h4>
        <form onSubmit={formSubmit}>
          <div className="d-flex gap-1 flex-column p-2">
            <h6 className="text-black ">Relation</h6>
            <div className="memeber_inputs d-flex flex-column gap-3">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <select
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.relation}
                  style={styles}
                  required
                  name="relation"
                  id="relation"
                  defaultValue={
                    "Selection The relationship between you and your member"
                  }
                >
                  {/* <option disabled ></option> */}
                  <option value="friend">Friend</option>
                  <option value="family">Family</option>
                </select>
                {formik.touched.relation && formik.errors.relation ? (
                  <div style={{ color: "var(--primaryColor)" }}>
                    {formik.errors.relation}
                  </div>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <input
                  style={styles}
                  type="text"
                  name="userName"
                  id="userName"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userName ?? ""}
                  placeholder="User name"
                />
                {formik.touched.userName && formik.errors.userName ? (
                  <div style={{ color: "var(--primaryColor)" }}>
                    {formik.errors.userName}
                  </div>
                ) : null}
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <input
                  style={styles}
                  type="text"
                  name="email"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email ?? ""}
                  id="email"
                  placeholder="Email"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div style={{ color: "var(--primaryColor)" }}>
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex justify-content-center my-4">
              <button
                ref={submitBtnRef}
                style={{ width: "9rem" }}
                disabled={!isFormValid}
                type="submit"
                className="btn btn-primary rounded-pill py-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
       
      </div>
    </Modal>
  );
};

export default AddMemberModal;
