import { useEffect, useState } from "react";
import Button from "../components/Button";
import Footer from "../components/Footer";
import MainNav from "../components/navigation/MainNav";
import { SearchResult } from "../components/SearchResult";
import { useSearchParams } from "react-router-dom";
import "./../styles/SearchPage.css";
import GoogleMapReact from "google-map-react";
import SearchBar, {
  SearchValuesOptions,
} from "../components/searchBar/SearchBar";
import SearchBarLoader from "../components/loader/SearchLoader";
import { ProfessionalService } from "../services/ProfessionalService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchMinus } from "@fortawesome/free-solid-svg-icons";
import { formatUSPrice } from "../utils/utils";
import slugify from "slugify";
import { ISearchData } from "../utils/types";
import { v4 as uuidv4 } from "uuid";
import Pagination from "../components/pagination/Pagination";
import PulseLoader from "../components/loader/PulseLoader";

const SearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [mapWidth, setMapWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchValues, setSearchValues] = useState<SearchValuesOptions>({
    service: searchParams.get("service"),
    salon: searchParams.get("salon"),
    where: searchParams.get("where"),
    lat: searchParams.get("lat"),
    lng: searchParams.get("lng"),
    price: searchParams.get("price"),
    account_type: searchParams.get("account_type"),
    drive_to_your_place: searchParams.get("drive_to_your_place"),
    day: searchParams.get("day"),
    wheelchair_accessible: searchParams.get("wheelchair_accessible"),
    kids_service: searchParams.get("kids_service"),
    kids_friendly: searchParams.get("kids_friendly"),
    blowdry: searchParams.get("blowdry"),
    hairwash: searchParams.get("hairwash"),
    undo_hair: searchParams.get("undo_hair"),
    rating: searchParams.get("rating"),
    bestMatch: searchParams.get("bestMatch"),
  });
  const [grid, setGrid] = useState(false);
  const [professionalData, setProfessionalData] = useState<ISearchData>({
    size: 3,
    page: 1,
    totalPages: null,
    results: null,
  });
  const [salonData, setSalonData] = useState<ISearchData>({
    size: 3,
    page: 1,
    totalPages: null,
    results: null,
  });
  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const [mapKey, setMapKey] = useState(uuidv4());
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const recordPage = 2;
  const lastPage = currentPageIndex * recordPage;
  const firstPage = lastPage - recordPage;
  const recors = professionalData.results?.slice(firstPage, lastPage);

  const getProfessionalData = (
    values: SearchValuesOptions,
    searchData: typeof professionalData
  ) => {
    if (map && maps) {
      maps.event.trigger(map, "resize");
    }
    setIsDataLoading(true);
    const currentPage = searchData.page;
    ProfessionalService.searchProfessionals({
      page: searchData.page as unknown as string,
      size: searchData.size as unknown as string,
      ...values,
    })
      .then((res) => {
        const updatedProfessionalData: ISearchData = {
          page: searchData.page,
          size: searchData.size,
          results: res.results,
          totalPages: res.totalPages,
        };
        if (currentPage === 1) {
          // If it's a new search, set page to 1
          setProfessionalData({
            ...updatedProfessionalData,
            page: 1,
          });
        } else {
          // If it's a load more action, increment the page
          if (professionalData.results) {
            const salons = [
              ...professionalData.results.slice(
                currentPage,
                professionalData.size
              ),
              ...res.results,
            ];
            setProfessionalData({
              ...professionalData,
              results: salons,
              page: currentPage + 1,
            });
          }
        }
        setMapKey(uuidv4());
        handleApiLoaded(map, maps);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
        setIsLoading(false);
        setIsDataLoading(false);
      });
  };

  const getSalonData = (
    values: SearchValuesOptions,
    searchData: typeof professionalData
  ) => {
    if (map && maps) {
      maps.event.trigger(map, "resize");
    }
    setIsDataLoading(true);
    const currentPage = searchData.page;
    ProfessionalService.searchSalons({
      page: searchData.page as unknown as string,
      size: searchData.size as unknown as string,
      ...values,
    })
      .then((res) => {
        const updatedSalonslData: ISearchData = {
          page: searchData.page,
          size: searchData.size,
          results: res.results,
          totalPages: res.totalPages,
        };
        if (currentPage === 1) {
          // If it's a new search, set page to 1
          setSalonData({
            ...updatedSalonslData,
            page: 1,
          });
        } else {
          // If it's a load more action, increment the page
          if (salonData.results) {
            const salons = [
              ...salonData.results.slice(currentPage, professionalData.size),
              ...res.results,
            ];
            setSalonData({
              ...salonData,
              results: salons,
              page: currentPage + 1,
            });
          }
        }
        setMapKey(uuidv4());
        handleApiLoaded(map, maps);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
        setIsLoading(false);
        setIsDataLoading(false);
      });
  };

  const handleLoadProfessionals = () => {
    if (
      professionalData.totalPages &&
      professionalData.page < professionalData.totalPages
    ) {
      // Increment the page number and fetch more data
      const nextPage = professionalData.page + 1;
      getProfessionalData(searchValues, {
        ...professionalData,
        page: nextPage,
      });
    }
  };

  // const handleLoadSalons = () => {
  //   if (salonData.totalPages && professionalData.page < salonData.totalPages) {
  //     // Increment the page number and fetch more data
  //     const nextPage = salonData.page + 1;
  //     getSalonData(searchValues, {
  //       ...salonData,
  //       page: nextPage,
  //     });
  //   }
  // };

  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 47.751076,
      lng: -120.740135,
    },
    zoom: 9,
  });

  const starsHTML = (stars: number, notice: number): string => {
    let output = `
    <div class="d-flex justify-content-center align-items-center flex-wrap">
      <div class="starsContainer">`;

    Array(5)
      .fill(0)
      .forEach((_, key) => {
        output += ` <i class="fa fa-star text-muted small-text ${
          key < stars ? " active" : ""
        }"></i>`;
      });

    output += `</div>
      <div class="ms-2">
        <span class="fw-bold">${stars}</span>
        <span class="text-muted small ms-1">(${notice})</span>
      </div>
    </div>`;

    return output;
  };

  const handleApiLoaded = (map: any, maps: any) => {
    // Put marker in current position
    if (map && maps) {
      setMap(map);
      setMaps(maps);
      const infoWindows = [];
      const bounds = new maps.LatLngBounds();

      if (professionalData?.results) {
        professionalData.results.forEach((salon) => {
          const marker = new maps.Marker({
            position: {
              lng: salon.location.coordinates[0],
              lat: salon.location.coordinates[1],
            },
            map,
            title: ``,
            background: "#000000",
          });

          const infoWindow = new maps.InfoWindow();

          if (salon.reviewsStats && salon.services && salon.portfolio) {
            const content = starsHTML(
              salon.reviewsStats.averageStars,
              salon.reviewsStats.reviewCount
            );
            const profileUrl =
              "see-professional-account/" +
              slugify(`${salon.name}-${salon._id}`, { lower: true });
            const portfolioLength = salon.portfolio.length;
            let randomPortfolio: string = `https://via.placeholder.com/150x250?text='${salon.name}'`;

            if (portfolioLength > 0) {
              randomPortfolio =
                salon.portfolio[
                  Math.round(Math.random() * (portfolioLength - 1))
                ];
            }

            const infoContent = `<div class='search-marker-container' style="display:flex;justify-content:center;align-items:center; flex-direction: column">
            <div class='d-flex flex-column search-marker-container__content'>
                <div class="d-flex flex-column">
                  <div class="d-flex justify-content-between gap-3">
                    <h6>${salon.name}</h6>
                    <span class='text-primary fs-6 fw-bold'>
                      ${formatUSPrice(salon.services[0].price)}
                    </span>
                  </div>
                  ${content}
                </div>
              </div>
              <a href='${profileUrl}' class='text-underline mt-2 border-0'>See the profile</a>
            </div>`;

            infoWindows.push(infoWindow); // Add the info window to the array

            // Open the info window when the marker is clicked
            infoWindow.setContent(infoContent);

            infoWindow.open(map, marker); // Added this line

            marker.addListener("click", () => {
              infoWindow.open(map, marker);
            });

            bounds.extend(marker.getPosition());
          }
        });
      }

      const center = bounds.getCenter();

      map.fitBounds(bounds);
      map.setCenter(center);
      if (searchValues.lat && searchValues.lng) {
        setDefaultProps((prev: any) => {
          return {
            ...prev,
            center: { lat: searchValues.lat, lng: searchValues.lng },
          };
        });
      }
    }
  };

  const Loader = () => {
    return (
      <>
        <SearchBarLoader />
        <SearchBarLoader />
        <SearchBarLoader />
        <SearchBarLoader />
      </>
    );
  };

  const handleSearch = (values: SearchValuesOptions) => {
    const filteredSearchString = Object.entries(values).reduce(
      (acc: any, [key, val]: any) => {
        if (val !== null && val !== "null" && val !== undefined) {
          acc[key] = val;
        }
        return acc;
      },
      {}
    );
    const searchString = new URLSearchParams(filteredSearchString).toString();

    setSearchParams(searchString);
    // Reset the page number to 1 for a new search
    setProfessionalData({
      ...professionalData,
      page: 1,
      results: null, // Clear the previous search results
    });

    setSalonData({
      ...professionalData,
      page: 1,
      results: null, // Clear the previous search results
    });

    setSearchValues(values);
    getProfessionalData(values, {
      size: 4,
      page: 1,
      totalPages: null,
      results: null,
    });

    // getSalonData(values, {
    //   size: 4,
    //   page: 1,
    //   totalPages: null,
    //   results: null,
    // });
  };

  useEffect(() => {
    getProfessionalData(searchValues, professionalData);
    //getSalonData(searchValues, salonData);
  }, []);

  useEffect(() => {
    const width = window.innerWidth;
    setMapWidth((5 / 10) * width);
  }, []);

  useEffect(() => {
    console.log(mapWidth, window.innerWidth);
  }, [mapWidth]);

  return (
    <div style={{ maxHeight: "auto", overflow: "hidden" }}>

      <MainNav navType={"homeNav"} />
      <div className="mx-1">
        <div className="d-flex SearchContainer">
          <div
            style={{
              height: "100vh",
              // width: `${mapWidth - 0}px`,
            }}
            className="d-flex flex-column gap-3 px-1 searchContainer-left"
          >
            <div className="d-flex flex-column">
              <SearchBar
                onLoadStart={() => setLoading(true)}
                onLoadEnd={() => {
                  setLoading(false);
                  setIsDataLoading(false);
                }}
                onSearch={handleSearch}
                onChange={handleSearch}
                location="searchPage"
              />
            </div>
            <div>
              {!loading && (
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className="d-flex justify-content-between gap-2 align-items-center">
                    <div className="d-flex gap-2 grid_list">
                      <i
                        className={
                          "fa-solid fa-border-all" +
                          (grid ? " text-primary" : "")
                        }
                        onClick={() => setGrid(true)}
                      ></i>
                      <i
                        className={
                          "fa-solid fa-list grid_view" +
                          (grid ? " " : " text-primary")
                        }
                        onClick={() => setGrid(false)}
                      >
                        {" "}
                      </i>
                    </div>
                    <div className="d-flex gap-1 small align-items-center">
                      <span className="fw-500 text-muted">Sort by:</span>
                      <span className="text-primary fw-500">
                        {searchValues.bestMatch}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex gap-1">
                    <span className="text-primary fw-500">
                      {professionalData.results?.length}
                    </span>
                    <span className="fw-500 text-muted">Available</span>
                  </div>
                </div>
              )}
              <div className="SearchContainer__ResultArea p-2 d-flex flex-column gap-2">
                {loading ? (
                  <div className="d-flex flex-column gap-2">
                    <Loader />
                  </div>
                ) : (
                  <div className={grid ? "search_container d-grid" : ""}>
                    {recors && recors.length > 0
                      ? recors.map((salon) => {
                          return (
                            <div className=" d-flex flex-column justify-content-center align-content-center ">
                              <SearchResult key={salon._id} salon={salon} />;
                            </div>
                          );
                        })
                      : null}
                    {recors &&
                    recors.length < recordPage + 1 &&
                    recors.length > 0 ? (
                      <div className=" w-100 d-flex justify-content-center align-content-center ">
                        <Pagination
                          currentPage={currentPageIndex}
                          lastPage={professionalData.size}
                          maxLength={2}
                          setCurrentPage={setCurrentPageIndex}
                        />
                      </div>
                    ) : null}
                  </div>
                )}

                {loading && isDataLoading ? (
                  <>
                    <SearchBarLoader />
                    <SearchBarLoader />
                  </>
                ) : (
                  !professionalData.results ||
                  (professionalData.results.length === null && (
                    <div className="SearchResult__noResults d-flex flex-column gap-2 text-muted">
                      <PulseLoader />
                    </div>
                  ))
                )}

                {!loading && isDataLoading ? (
                  <>
                    <SearchBarLoader />
                    <SearchBarLoader />
                  </>
                ) : (
                  !professionalData.results ||
                  (professionalData.results.length === 0 && (
                    <div className="SearchResult__noResults d-flex flex-column gap-2 text-muted">
                      <FontAwesomeIcon size="4x" icon={faSearchMinus} />
                      <span>It seems like there are no result right now !</span>
                    </div>
                  ))
                )}
              </div>
            </div>
            <div
              className={
                "LoadMoreContainer align-items-center mb-5 justify-content-center" +
                (professionalData.totalPages &&
                professionalData.page < professionalData.totalPages
                  ? " d-flex"
                  : " d-none")
              }
            >
              {!loading && !isDataLoading && (
                <Button
                  onClick={handleLoadProfessionals}
                  className="br-6 fw-500"
                  mode="primary"
                  content={<span className="mx-3">Load more</span>}
                />
              )}
            </div>
          </div>

          <div className="search_map">
            <div
              style={{
                height: "100vh",
                width: `${mapWidth - 0}px`, //9
              }}
            >
              <GoogleMapReact
                key={mapKey}
                bootstrapURLKeys={{
                  key: process.env
                    .REACT_APP_SERVER_GOOGLE_MAP_API_KEY as string,
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
                options={{
                  zoomControl: true,
                  scaleControl: true,
                  fullscreenControl: true,
                  panControl: true,
                  streetViewControl: true,
                  // mapTypeControl: true,
                }}
              >
                {/* <Marker lat={37.686493} lng={-97.26485300000002} text="My Marker" /> */}
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SearchPage;
