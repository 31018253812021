import React, { useEffect, useState } from 'react';
import { formatSecondsToTime } from '../../../utils/utils';

interface CountdownTimerProps {
    initialMinutes: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ initialMinutes }) => {
    const [remainingSeconds, setRemainingSeconds] = useState(initialMinutes * 60);

    useEffect(() => {
        const timer = setInterval(() => {
            if (remainingSeconds > 0) {
                setRemainingSeconds(remainingSeconds - 1);
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [remainingSeconds]);

    useEffect(() => {
        setRemainingSeconds(initialMinutes * 60);
    }, [initialMinutes]);


    return (
        <div>
            {formatSecondsToTime(remainingSeconds)}
        </div>
    );
};

export default CountdownTimer;
