import axios from "axios";
import { SearchValuesOptions } from "../components/searchBar/SearchBar";

/**
 *
 * @param salonId
 * @param startDate
 * @param duration in minutes
 * @param chairId
 * @returns
 */

const getProfessionalById = (salonId: string) => {
  return axios.get(`unauthenticated/professionals/${salonId}`).then((res) => {
    return res.data;
  });
};

const getProfessionalTimeSlots = (
  salonId: string,
  startDate: moment.Moment,
  duration: number = 0,
  chairId: string | null | undefined = undefined
) => {
  return axios
    .get(
      `unauthenticated/professionals/${salonId}/${startDate.format(
        "YYYY-MM-DD"
      )}/time_slots`,
      {
        ...(chairId
          ? { params: { chairId, duration } }
          : { params: { duration } }),
      }
    )
    .then((res) => {
      return res.data;
    });
};



const searchProfessionals = (values: SearchValuesOptions) => {
  // Create an object to store the query parameters
  const queryParams = {
    page: 1,
    size: 8,
    ...values,
  };

  // Remove null or undefined values from the queryParams object
  const filteredQueryParams = Object.entries(queryParams).reduce(
    (acc: any, [key, value]: any) => {
      if (value !== null && value !== "null" && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  // Convert the filtered query parameters into a query string
  const queryString = new URLSearchParams(filteredQueryParams).toString();

  return axios.get(`unauthenticated/services?${queryString}`).then((res) => {
    return res.data;
  });
};

const getProfessionals = (page: number = 1, size: number = 8) => {
  return axios
    .get(`unauthenticated/professionals?page=${page}&size=${size}`)
    .then((res) => {
      return res.data;
    });
};

const findAlternativeToAppointment = (appointmentId: string) => {
  return axios
    .get(`appointments/custom/alternative-to-appointment/${appointmentId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((res) => {
      return res.data;
    });
};

const searchSalons = (values: SearchValuesOptions) => {
  // Create an object to store the query parameters
  const queryParams = {
    page: 1,
    size: 8,
    ...values,
  };

  // Remove null or undefined values from the queryParams object
  const filteredQueryParams = Object.entries(queryParams).reduce(
    (acc: any, [key, value]: any) => {
      if (value !== null && value !== "null" && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  // Convert the filtered query parameters into a query string
  const queryString = new URLSearchParams(filteredQueryParams).toString();

  return axios
    .get(`unauthenticated/salons/names?${queryString}`)
    .then((res) => {
      return res.data;
    });
};

export const ProfessionalService = {
  getProfessionalById,
  getProfessionalTimeSlots,
  getProfessionals,
  searchProfessionals,
  findAlternativeToAppointment,
  searchSalons,
};
