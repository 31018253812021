import { useCallback, useEffect, useRef, useState } from "react";
import { ISalon } from "../../../../models/SalonX";
import { Stars } from "../../../Stars";
import "./../../../../styles/SearchResultItem.css";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";
import Button from "../../../Button";
import Countdown from "../../../promotion/CountDown";

export type SearchResultItemProps = Pick<
  ISalon,
  | "_id"
  | "name"
  | "cover"
  | "profile"
  | "address"
  | "reviewsStats"
  | "isPromote"
>;

const SearchResultItem = (props: SearchResultItemProps) => {
  const navigate = useNavigate();
  const [promote, setPromote] = useState(true);
  const slug = slugify(props.name?.split(" ").join("-") + "-" + props._id, {
    lower: true,
  });
  const itemRef = useRef<HTMLDivElement>(null);
  const targetDate = new Date();
  targetDate.setDate(targetDate.getDate() + 1);

  const extractAddressDetails = (address: string) => {
    const parts = address.split(",").map((part) => part.trim());
    let city = "";
    let state = "";
    let country = "";

    if (parts.length >= 3) {
      country = parts.pop() || "";
      state = parts.pop() || "";
      city = parts.pop() || "";
    } else if (parts.length === 2) {
      country = parts.pop() || "";
      state = parts.pop() || "";
    } else if (parts.length === 1) {
      country = parts.pop() || "";
    }

    return { city, state, country };
  };

  // const renderContentRender = (params: Pick<FavoriteProps, "available">) => {
  //     let contentToRender: React.ReactNode | null = null;

  //     if (
  //         typeof params.available.value === "number" &&
  //         params.available.value > 0
  //     ) {
  //         contentToRender = (
  //             <div className="d-flex justify-content-center gap-3 pt-2">
  //                 <div className="justify-content-center align-items-center">
  //                     <div className="fw-500 small">
  //                         <i className="fa-solid fa-gauge"></i> Coming soon
  //                     </div>
  //                     <div className="text-primary text-center small">
  //                         <CountdownTimer
  //                             initialMinutes={Math.round(params.available.value)}
  //                         />
  //                     </div>
  //                 </div>
  //                 <div className="text-muted text-primary">
  //                     <Button
  //                         style={{ fontSize: ".7em" }}
  //                         mode={"primary"}
  //                         content={<span className="mx-1">See availability</span>}
  //                         onClick={() => {
  //                             navigate(`/see-professional-account/${slug}`);
  //                         }}
  //                     />
  //                 </div>
  //             </div>
  //         );
  //     } else if (
  //         typeof params.available.value === "boolean" &&
  //         !params.available.value
  //     ) {
  //         contentToRender = (
  //             <div className="text-muted d-flex justify-content-center text-primary pt-2">
  //                 <Button
  //                     style={{ fontSize: ".7em" }}
  //                     mode={"primary"}
  //                     content={<span className="mx-1">See availability</span>}
  //                     onClick={() => {
  //                         navigate(`/see-professional-account/${slug}`);
  //                     }}
  //                 />
  //             </div>
  //         );
  //     } else if (
  //         typeof params.available.value === "boolean" &&
  //         params.available.value
  //     ) {
  //         contentToRender = (
  //             <div className="d-flex justify-content-center gap-3 pt-2">
  //                 <Button
  //                     style={{ fontSize: ".7em" }}
  //                     mode={"primary"}
  //                     content={<span className="mx-1">Book Now</span>}
  //                     onClick={() => {
  //                         navigate(`/see-professional-account/${slug}`);
  //                     }}
  //                 />
  //             </div>
  //         );
  //     }

  //     return contentToRender;
  // };

  const handleItemClick = useCallback(() => {
    navigate(`/see-professional-account/${slug}`);
    console.log("isPromote", props.isPromote);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (itemRef.current) {
      itemRef.current.addEventListener("click", handleItemClick);
    }
    console.log("isPromote", props.isPromote);
  }, [handleItemClick]);

  const { city, state, country } = extractAddressDetails(props.address);

  const newAddress = `${city}, ${state}, ${country}`;

  return (
    <div
      ref={itemRef}
      className="search-result-item border shadow w-100 overflow-hidden "
    >
      <div className="search-result-item__cover-container cursor-pointer bg-white position-relative">
        <img
          style={{}}
          src={`${
            props.cover ?? require("./../../../../assets/images/cover.jpg")
          }`}
          alt=""
          className="w-100"
        />
        <div className="search-result-item__cover-container__profile">
          <img
            src={`${
              props.profile ??
              require("./../../../../assets/images/profile.jpg")
            }`}
            alt=""
            className=""
          />
        </div>
      </div>
      <div className="search-result-item__content d-flex flex-column gap-1">
        <div className="search-result-item__content__safe-area w-100">
          <div
            style={{ letterSpacing: ".05em" }}
            className={`fs-5 w-100 text-center fw-600`}
          >
            {props.name}
          </div>
        </div>
        <div className="search-result-item__address d-flex text-muted justify-content-center align-items-center gap-2 w-100">
          <i className="fa-solid fa-location-dot"></i>
          <div>{newAddress ?? "No location provided"}</div>
        </div>
        <div className="d-flex justify-content-center">
          <Stars
            canUpdate={false}
            stars={props.reviewsStats.averageStars}
            notice={props.reviewsStats.reviewCount}
          />
        </div>
        {props.isPromote === true && (
          <div className="d-flex w-100 justify-content-center align-items-center gap-4">
            <div className="d-flex flex-column text-primary">
              <span className="text-primary">Promotion Available</span>
              {/* <Countdown targetDate={targetDate} /> */}
            </div>
            <Button
              // onClick={(e) => onSelect(service)}
              className="br-6"
              type={"button"}
              content={<span className="mx-3 text-white">View Now</span>}
            />
          </div>
        )}
      </div>
    </div>
  );

  // return (
  //     <div
  //         onClick={() => {
  //             navigate(`/see-professional-account/${slug}`);
  //         }}
  //         className="search-result-item Favorite d-flex flex-column shadow"
  //     >
  //         <div className="ProfileCover d-flex flex-column align-items-center  w-100">
  //             <div className="ProfileCoverContainer h-100 w-100">
  //                 <img
  //                     className={"ProfileCoverImage h-100 rounded-top"}
  //                     src={props.cover ?? require("../assets/images/cover.jpg")}
  //                     alt={props.name}
  //                 />
  //             </div>
  //             <div className="profilePicture shadow">
  //                 <img
  //                     src={props.profile ?? require("../assets/images/profile.jpg")}
  //                     alt={props.name}
  //                 />
  //             </div>
  //         </div>
  //         <div className="ProfileMeta mb-3 d-flex flex-column justify-content-center text-center">
  //             <h6
  //                 style={{
  //                     // color: props.account_type === "independent" ? "green" : "#a89331",
  //                 }}
  //                 className={` fw-bolder`}
  //             >
  //                 {props.name}
  //             </h6>
  //             <div className="text-muted  small-text">
  //                 <div className="Profile__location mx-4">
  //                     <i className="fa-solid fa-location-dot"></i>{" "}
  //                     {props.address ?? "No location provided"}
  //                 </div>
  //             </div>
  //             <div className="d-flex justify-content-center">
  //                 <Stars stars={props.reviewsStats.averageStars} notice={props.reviewsStats.reviewCount} />
  //             </div>
  //         </div>
  //         {/*
  //         {props.available.state !== "pending" &&
  //             renderContentRender({
  //                 available: props.available,
  //             })} */}

  //         {/* {props.available && (
  //             <div className="mt-auto">
  //                 <div
  //                     className={`available-state mt-3 available-state--${props.available.state} user-select-none d-flex justify-content-center align-items-center`}
  //                 >
  //                     <div className="available-state__container">
  //                         {props.available.state === "pending" && (
  //                             <span style={{ fontSize: ".8em" }} className="">
  //                                 <div>loading...</div>
  //                             </span>
  //                         )}
  //                         {props.available.state === "available" && (
  //                             <span style={{ fontSize: ".8em" }} className="text-uppercase ">
  //                                 <i className="fa fa-calendar-check"></i> Available
  //                             </span>
  //                         )}
  //                         {props.available.state === "offline" && (
  //                             <span style={{ fontSize: ".8em" }} className="text-uppercase">
  //                                 <i className="fa fa-power-off"></i> offline
  //                             </span>
  //                         )}
  //                         {props.available.state === "booked" && (
  //                             <span style={{ fontSize: ".8em" }} className="text-uppercase">
  //                                 <i className="fa fa-calendar-alt"></i> booked
  //                             </span>
  //                         )}
  //                         {props.available.state === "busy" && (
  //                             <span style={{ fontSize: ".8em" }} className="text-uppercase">
  //                                 <i className="fa fa-exclamation-circle"></i> busy
  //                             </span>
  //                         )}
  //                     </div>
  //                 </div>
  //             </div>
  //         )} */}
  //     </div>
  // );
};

export default SearchResultItem;
