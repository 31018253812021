import { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import "../styles/AddMember.css";
import { useMember } from "../../hooks/useMember";

type Props = {
  open: boolean;
  onClose: () => void;
  handleRefetchMembers: () => void;
};

const MemberNoExist = ({ open, onClose, handleRefetchMembers }: Props) => {
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const [addedMember, setAddedMember] = useState<any[]>([]);
  const errorMember = useMember();

  useEffect(() => {
    if (errorMember) {
      setAddedMember(errorMember);
    }
  }, [errorMember]);

  const handleClosed = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    handleRefetchMembers();
    onClose && onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="closed position-absolute top-50 start-50 translate-middle z-50"
    >
      <div className="d-flex justify-content-center flex-column  align-items-sm-center p-2">
        <div className="container-box">
          <h6 className="title-error">Friend and Family</h6>
          <p>
          The user you added does not yet have a Latrice account but your request has been sent to the member’s email. 
          </p>
          <div className="box-icon">
            <i className="fa-regular fa-envelope fa-2x"></i>
          </div>
        </div>
        {/* <button
          ref={submitBtnRef}
          style={{ width: "9rem" }}
          type="submit"
          className=" btn-secondary rounded-pill py-2"
          onClick={handleClosed}
        >
          close
        </button> */}
      </div>
    </Modal>
  );
};

export default MemberNoExist;
