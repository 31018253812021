import React, { useEffect, useState } from "react";
import "./../styles/ChatHeaderAction.css";
import moment from "moment";
import TimeRemaining from "./TimeRemaining";
import { IAppointment } from "../../models/Appointment";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import toast from "react-hot-toast";
import ConfirmMarkAsStartedModal from "../reusable/ConfirmMarkAsStartedModal";
import ConfirmMarkAsEndedModal from "../reusable/ConfirmMarkAsEndedModal";
import { useParams } from "react-router-dom";
import { useUser } from "../../hooks/UseUser";
import MakeReviewModal from "../reusable/MakeReviewModal";
import socket from "../../socket/socket";
import { EVENT_CODE } from "../../socket/event";

export interface ChatHeaderActionProps {
  targetDate: moment.Moment;
  appointment: IAppointment;
  onMarkAppointmentHasStarted: (appointment: IAppointment) => Promise<boolean>;
  onMarkAppointmentHasEnded: (appointment: IAppointment) => Promise<boolean>;
}


export const ChatHeaderAppointmentAction: React.FC<ChatHeaderActionProps> = ({
  targetDate,
  appointment,
  ...props
}) => {
  const [loading, setLoading] = React.useState(true);
  const [shouldStart, setShouldStart] = React.useState(false);
  const [shouldEnd, setShouldEnd] = React.useState(false);
  // const [makeReview, setMakeReview] = useState(false);
  const [confirmMarkAsStarted, setConfirmMarkAsStarted] = React.useState(false);
  const [confirmMarkAsEnded, setConfirmMarkAsEnded] = React.useState(false);

  const user = useUser();

  const handleMarkAsStarted = () => {
    console.log("appointment", appointment);
    if (confirmMarkAsStarted) return;
    setConfirmMarkAsStarted(true);
    toast.loading((t) => {
      return (
        <ConfirmMarkAsStartedModal
          show={true}
          onClose={() => setConfirmMarkAsStarted(false)}
          handleConfirmMarkAsStarted={handleConfirmMarkAsStarted}
        />
      );

      // <div>
      //     <span>
      //         You are going to mark this appointment as started, Please confirm it.
      //     </span>
      //     <div className='d-flex justify-content-end gap-2'>
      //         <button onClick={() => setConfirmMarkAsStarted(false)} className='btn btn-danger'>Cancel</button>
      //         <button onClick={handleConfirmMarkAsStarted} className='btn btn-dark'>Yes, I confirm.</button>
      //     </div>
      // </div>
    });
  };

  const handleConfirmMarkAsStarted = (code: any) => {
    toast.remove();
    toast.loading((t) => {
      return (
        <div>
          <span>Please wait, we are marking your appointment as started.</span>
        </div>
      );
    });

    if (appointment.salonQrCodePing === Number(code)) {
      props.onMarkAppointmentHasStarted(appointment).then((res) => {
        if (!res) {
          setConfirmMarkAsStarted(false);
          setTimeout(() => toast.error("Something went wrong."), 200);
        }
      });
    } else {
      setConfirmMarkAsStarted(false);
      setTimeout(() => toast.error("Something went wrong."), 200);
    }
  };

  const handleMarkAsEnded = () => {
    if (confirmMarkAsEnded) return;
    setConfirmMarkAsEnded(true);
    toast.loading((t) => {
      return (
        <ConfirmMarkAsEndedModal
          show={true}
          onClose={() => setConfirmMarkAsEnded(false)}
          handleConfirmMarkAsEnded={handleConfirmMarkAsEnded}
        />
      );
    });
  };

  const handleSocketReview = (appointment: IAppointment) => {
    console.log("appointment i sen via socket", appointment);
    // const receiverId = selectedDiscussion.meta.receiverId;
    socket().emit(EVENT_CODE.MAKE_REVIEW, appointment);
  };

  const handleConfirmMarkAsEnded = (code: any) => {
    toast.remove();
    toast.loading((t) => {
      return (
        <div>
          <span>Please wait, we are marking your appointment as ended.</span>
        </div>
      );
    });
    console.log("your code", appointment, code, typeof code);
    if (appointment.qrcodePing === Number(code)) {
      props.onMarkAppointmentHasEnded(appointment).then((res) => {
        if (!res) {
          setConfirmMarkAsEnded(false);
          setTimeout(() => toast.error("Something went wrong."), 200);
        } else {
          handleSocketReview(appointment);
          // setTimeout(() => {
          //   setMakeReview(true);
          // }, 3000);
        }
      });
    } else {
      setConfirmMarkAsEnded(false);
      setTimeout(() => toast.error("Your code is not valid."), 200);
    }
  };

  React.useEffect(() => {
    if (!confirmMarkAsEnded) toast.remove();
  }, [confirmMarkAsEnded]);

  React.useEffect(() => {
    if (!confirmMarkAsStarted) toast.remove();
  }, [confirmMarkAsStarted]);

  React.useEffect(() => {
    const id = setInterval(() => {
      setShouldStart(targetDate.isSameOrBefore(moment()));
      setShouldEnd(targetDate.isSameOrBefore(moment().subtract(15, "minutes")));

      console.log("shoulStart", targetDate.isSameOrBefore(moment()));
      console.log(
        "shoulEnd",
        targetDate.isSameOrBefore(moment().subtract(15, "minutes"))
      );

      setLoading(false);
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  const { myCode } = useParams();

  const sameUserMakeAppointment = user?._id === appointment.user;

  useEffect(() => {
    console.log("code i have after scanning of my qrcode", myCode);
    console.log("here is the user and the appoitment", user, appointment);
  }, []);

  return (
    <div className="chat-header-action user-select-none">
      {/* {appointment.startedAt && !shouldEnd && (
        <div className="d-flex align-items-center">
          <Spinner animation="grow" size="sm" /> running...
        </div>
      )} */}

      {!appointment.endedAt ? (
        <>
          {!shouldStart && !shouldEnd && !loading && (
            <TimeRemaining targetDate={targetDate} />
          )}

          {shouldStart && sameUserMakeAppointment && !appointment.startedAt && (
            <div>
              <Form.Check
                type="switch"
                className="mark-button"
                label="Mark As Started"
                onClick={handleMarkAsStarted}
                checked={confirmMarkAsStarted}
              />
            </div>
          )}

          {appointment.startedAt && sameUserMakeAppointment && (
            <Alert
              className="p-1 my-auto gap-1 d-flex align-items-center"
              key={"alert-dark"}
              variant={"info"}
            >
              <i className="fa fa-info-circle"></i>
              <span>started</span>
            </Alert>
          )}

          {shouldStart && !sameUserMakeAppointment && (
            <div>
              <Form.Check
                type="switch"
                className="mark-button"
                label="Mark As Ended"
                onClick={handleMarkAsEnded}
                checked={confirmMarkAsEnded}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {appointment.endedAt && !sameUserMakeAppointment ? (
            <Alert
              className="p-1 my-auto gap-1 d-flex align-items-center"
              key={"alert-dark"}
              variant={"info"}
            >
              <i className="fa fa-info-circle"></i>
              <span>terminated</span>
            </Alert>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2px",
              }}
            >
              <Alert
                className="p-1 my-auto gap-1 d-flex align-items-center "
                key={"alert-dark"}
                variant={"info"}
              >
                <i className="fa fa-info-circle"></i>
                <span>terminated</span>
              </Alert>
              {/* <button
                className="p-1 my-auto  d-flex align-items-center"
                style={{
                  backgroundColor: "rgba(12, 88, 12, 0.623)",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "4px",
                }}
                onClick={() => setMakeReview(true)}
              >
                <span style={{ color: "#fff" }}>Make a review</span>
              </button> */}
            </div>
          )}
        </>
      )}

      {/* {makeReview && (
        <MakeReviewModal
          appointment={appointment}
          show={makeReview}
          onClose={() => setMakeReview(false)}
          handleMakeReviewFunction={() => console.log("test")}
        />
      )} */}
    </div>
  );
};
