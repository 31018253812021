import React from "react";
import styles from "../styles/DefaultLoader.module.css";


export interface DefaultLoaderProps {
    title?: string;
}


const DefaultLoader: React.FC<DefaultLoaderProps> = ({ title = 'loading' }) => {
    return <div className={`${styles["DefaultLoader"]} d-flex flex-column align-items-center justify-content-center`}>
        <div className="spinner"></div>
        <span>{title}</span>
    </div>
}

export default DefaultLoader;