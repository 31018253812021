import React from "react";
import { formatMinutes, formatUSPrice } from "../../utils/utils";
import moment from "moment";
import { IAppointment } from "../../models/Appointment";
import styles from "../styles/AppointmentDetails.module.css";
import { CancelHandler } from "../CancelHandler";
import toast from "react-hot-toast";

type AppointmentDetailsProps = {
  appointment: IAppointment;
  show: boolean;
  handleShow: any;
  onCancel: (appointment: IAppointment) => Promise<any>;
};

const AppointmentDetails: React.FC<AppointmentDetailsProps> = ({ appointment, show, onCancel, handleShow }) => {

  // Helper function to calculate the duration of the appointment
  const toDuration = (appointment: IAppointment) => {
    const startTime = moment(`${appointment.day} ${appointment.startTime}`, 'YYYY-MM-DD HH:mm');
    const endTime = moment(`${appointment.day} ${appointment.endTime}`, 'YYYY-MM-DD HH:mm');
    return endTime.diff(startTime, 'minutes');
  }

  const handleCancelAppointment = (): Promise<any> => {
    return onCancel(appointment).then((res) => {
      toast.success('cancellation success')
    }).catch((err) => {
      if (err.errors && err.errors.message) {
        toast.error(err.errors.message);
      } else {
        toast.error('cancellation failed');
      }
      return Promise.reject(0);
    });
  }


  return (
    <div className={`${styles.AppointmentDetails} ${show ? styles['AppointmentDetails--open'] : ''} d-flex flex-column bg-white shadow-lg`}>
      {/* Loop through each service in the appointment */}
      {appointment.service.map((service) => {
        return (
          <>
            {/* Service details */}
            <div
              style={{ padding: "2rem 0 0.2rem 0" }}
              key={service._id}
              className="d-flex justify-content-between align-items-center px-4"
            >
              <div>
                <div className="fw-bold">{service.service_detail.name}</div>
                <div>
                  {/* Format the price and duration */}
                  {formatUSPrice(service.price)} for {formatMinutes(toDuration(appointment))}
                </div>
              </div>
              {/* <div className="CancelHandlerContainer cancelHandler">
                <CancelHandler
                  child={<button
                    style={{ fontSize: "13px", borderRadius: "1rem" }}
                    className="btn btn-primary cancelHandler"
                  >
                    Cancelation
                  </button>}
                  cancel={handleCancelAppointment}
                />

              </div> */}
            </div>

            <hr style={{ margin: "0" }} />
          </>
        );
      })}
    </div>
  );
};

export default AppointmentDetails;
