import React, { useState } from "react";
import { shareContent } from "../../utils/utils";
import toast from "react-hot-toast";
import "../styles/ShareLinkPromotion.css";
import { faCopy, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ShareLinkComponentProps {
  link: string;
  title?: string;
}

const ShareLinkPromotion: React.FC<ShareLinkComponentProps> = ({
  link,
  title = "Latrice Promotion",
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true);
      toast.success("Copied to clipboard!");
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handleShareLink = () => {
    shareContent({
      title: title,
      text: `Check out this promotion!`,
      url: link,
    });
  };

  return (
    <div className="share-link-container w-100 p-3">
      <label htmlFor="link-input bold">Link :</label>
      <input type="text" value={link} readOnly className="link-input" />
      <button
        onClick={handleCopyLink}
        className="button copy-button"
        aria-label="Copy link"
      >
        <FontAwesomeIcon
          icon={faCopy}
          className="ms-3"
          style={{ cursor: "pointer" }}
        />
      </button>
      <button
        onClick={handleShareLink}
        className="button share-button "
        aria-label="Share link"
      >
        <FontAwesomeIcon
          icon={faShare}
          className="ms-3"
          style={{ cursor: "pointer" }}
        />
      </button>
    </div>
  );
};

export default ShareLinkPromotion;
