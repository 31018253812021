import React from "react";
import { AppointmentCancelHandlerModalDialog } from "../CancelHandler";
import styles from "../styles/Appointment.module.css";
import { IAppointment } from "../../models/Appointment";
import moment from "moment";
import { formatUSPrice, toServiceLabel } from "../../utils/utils";
import { toast } from "react-hot-toast";
import slugify from "slugify";
import { Tooltip } from "react-tooltip";

export interface AppointmentOptions {
  appointment: IAppointment;
  selected?: boolean;
  mode?: "default" | "nocancel" | "other";
  onClick?: (event: any) => void;
  onCancel: (appointment: IAppointment) => Promise<any>;
}

const Appointment: React.FC<AppointmentOptions> = ({
  appointment,
  mode = "default",
  selected = false,
  onClick,
  onCancel
}) => {

  const handleCancelAppointment = (): Promise<any> => {
    return onCancel(appointment).then((res) => {
      toast.success('cancellation success')
      return Promise.resolve(0);
    }).catch((err) => {
      if (err.errors && err.errors.message) {
        toast.error(err.errors.message);
      } else {
        toast.error('cancellation failed');
      }
      return Promise.reject(0);
    });
  }


  const nameSlug = slugify(`${appointment?.salon?.name}`);
  const professionalLink = `/see-professional-account/${nameSlug}-${appointment?.salon?._id}`

  

  return (
    <div className={`${styles.Appointment} d-flex flex-column`}>
      <div
        onClick={onClick}
        className={`d-flex justify-content-between fw-bold ${selected ? "text-primary" : ""
          }`}
      >
        <div
          data-tooltip-id="serviceLabel"
          data-tooltip-place="top"
          data-tooltip-content={toServiceLabel(appointment)}
          className="w-25"
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {toServiceLabel(appointment)}
          <Tooltip id="serviceLabel" />
        </div>
        <div>
          {moment(appointment.startTime, 'HH:mm').format('h:mm A')}-
          {moment(appointment.endTime, 'HH:mm').format('h:mm A')}
        </div>
        <div className="fw-500">
          {formatUSPrice(appointment.price)}
        </div>
      </div>
      <div className={`d-flex justify-content-between align-items-end gap-4`}>
        <div
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          className="text-muted text-primary text-nowrap">
          <a target='__blank' className={styles.deepLinkProfile} href={professionalLink}>
            <span className="text-muted text-decoration-none me-2">with</span>
            <span className="text-decoration-underline">{appointment?.salon?.name}</span>
          </a>
        </div>
        {mode !== "nocancel" && (
          <AppointmentCancelHandlerModalDialog
            child={<div className={`text-danger`}>Cancel</div>}
            cancel={handleCancelAppointment}
            appointment={appointment}
          />
        )}
      </div>
    </div>
  );
};

export { Appointment };
