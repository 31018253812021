import { useEffect, useRef, useState } from "react";
import Button from "../../Button";
import toast from "react-hot-toast";
import { AuthService } from "../../../services/AuthService";
import { geocodeByLatLng } from "react-google-places-autocomplete";
import { useUser } from "../../../hooks/UseUser";
import MapSelection from "../../map/MapSelection";
import PulseLoader from "../../loader/PulseLoader";

interface RegisterProStep0Props {
  onNextStep: () => void;
  onPrevStep?: () => void;
}

const RegisterProStep3 = ({
  onNextStep,
  onPrevStep,
}: RegisterProStep0Props) => {
  const auth = useUser();

  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const [typeOfAccountStep, setTypeOfAccountStep] = useState(1);
  const [typeOfAccount, setTypeOfAccount] = useState<
    "independent" | "salon_owner" | null
  >(null);
  const [salonName, setSalonName] = useState<string | null>(null);
  const [areaRadius, setAreaRadius] = useState<string | null>("1");
  const [address, setAddress] = useState<string | null>(null);
  const [onUpload, setOnUpload] = useState(false);
  const [onLoading, setOnLoading] = useState(false);
  const [markerPosition, setMarkerPosition] = useState<any>(null);
  const [markerCircle, setMarkerCircle] = useState<any>(null);
  const [markerInfo, setMarkerInfo] = useState<any>(null);
  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const [position, setPosition] = useState<typeof defaultProps | null>(null);

  const prevTypeOfAccountStep = () => {
    setTypeOfAccountStep(1);
  };

  const handleSubmit = () => {
    if (typeOfAccountStep === 0) {
      setTypeOfAccountStep(1);
    } else if (typeOfAccountStep === 1) {
      const formData = new FormData();
      // formData.set('account_type', typeOfAccount as string)
      formData.set("name", salonName as string);
      toast.remove();
      setOnLoading(true);
      AuthService.updateSalon(formData)
        .then((res) => {
          toast.success("We have updated the provided information.");
          setTypeOfAccountStep(2);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong, please try again.");
        })
        .finally(() => setOnLoading(false));
    } else if (typeOfAccountStep === 2) {
      const formData = new FormData();
      formData.set("address", address as string);
      formData.set("lat", position?.center.lat as unknown as string);
      formData.set("lng", position?.center.lng as unknown as string);
      formData.set("service_radius", areaRadius as string);
      toast.remove();
      AuthService.updateSalon(formData)
        .then((res) => {
          toast.success("We have updated the provided information.");
          onNextStep();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong, please try again.");
        })
        .finally(() => setOnLoading(false));
    } else {
      onNextStep();
    }

    return false;
  };

  const defaultProps = {
    center: {
      lat: 7.369722,
      lng: 12.35472,
    },
    zoom: 9,
  };

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [mapWidth, setMapWidth] = useState<string>("300px");

  useEffect(() => {
    position && markerPosition && markerPosition.setPosition(position.center);
    markerInfo && address && markerInfo.setContent(address);
    if (markerCircle && position) {
      markerCircle.setRadius(Number(areaRadius) * 1609.34);
      markerCircle.setCenter(position.center);
    }
  }, [address, markerInfo, areaRadius, markerCircle]);

  useEffect(() => {
    if (map && markerPosition && markerCircle) {
      map.panTo(markerPosition.getPosition());
      const circleBounds = markerCircle.getBounds();
      map.fitBounds(circleBounds);
    }
  }, [position]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          let lat = pos.coords.latitude;
          let long = pos.coords.longitude;

          let currentPosition = {
            center: {
              lat: lat,
              lng: long,
            },
            zoom: 9,
          };

          setPosition(currentPosition);
          const geocodeByLatLng2 = async () => {
            try {
              const results = await geocodeByLatLng(currentPosition.center);
              if (typeof results === "object") {
                setAddress(results[0].formatted_address);
              }
            } catch (error) {
              console.error("Error", error);
            }
          };
          geocodeByLatLng2();
        },
        function (error) {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.log("The request to get user location timed out.");
              break;
            default:
              console.log("An unknown error occurred.");
              break;
          }
          setPosition(defaultProps);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      toast.error("Your browser doesn't support geolocation.");
    }
  }, []);

  useEffect(() => {
    const type_of_account = localStorage.getItem("accountType");
    console.log("type of account", type_of_account);
    if (type_of_account === "independent") {
      setTypeOfAccount("independent");
    } else {
      setTypeOfAccount("salon_owner");
    }
  }, []);

  useEffect(() => {
    if (mapContainerRef.current) {
      setMapWidth(`${mapContainerRef.current.clientWidth}px`);
    }
  }, [typeOfAccountStep]);

  // const Input = () =>{

  //   if(typeOfAccount === "independent"){
  //     return (

  //       <input
  //       style={{ width: "300px" }}
  //       onChange={(e) => setSalonName(e.target.value)}
  //       value={salonName ?? ""}
  //       placeholder="Enter your business or Brand name*"
  //       required
  //       type="text"
  //       className="form-control fw-500"
  //       id="salonName"
  //     />

  //     )
  //   }
  //   return (

  //     <input
  //     style={{ width: "300px" }}
  //     onChange={(e) => setSalonName(e.target.value)}
  //     value={salonName ?? ""}
  //     placeholder="Name of your salon*"
  //     required
  //     type="text"
  //     className="form-control fw-500"
  //     id="salonName"
  //   />

  //   )
  // }

  return (
    <div className="w-100 d-flex flex-row gap-5">
      {typeOfAccountStep === 1 && (
        <div className="d-flex flex-column flex-md-row align-items-center gap-5 justify-content-between w-100">
          <div className="mt-2 d-flex">
            {typeOfAccount === "independent" ? (
              <input
                style={{ width: "300px" }}
                onChange={(e) => setSalonName(e.target.value)}
                value={salonName ?? ""}
                placeholder="Enter your business or Brand name*"
                required
                type="text"
                className="form-control fw-500"
                id="salonName"
              />
            ) : (
              <input
                style={{ width: "300px" }}
                onChange={(e) => setSalonName(e.target.value)}
                value={salonName ?? ""}
                placeholder="Name of your salon*"
                required
                type="text"
                className="form-control fw-500"
                id="salonName"
              />
            )}

            {/* <Input /> */}
          </div>
          <div className="d-flex flex-column ms-auto ms-md-none align-items-center justify-content-center gap-2">
            <Button
              disabled={onLoading || (salonName ?? "").length < 3}
              ref={submitBtnRef as any}
              onClick={handleSubmit}
              mode={"primary"}
              style={{ width: "90px" }}
              content={
                <span className="mx-0" style={{ fontSize: ".8em" }}>
                  {onLoading ? "loading..." : "Next Step"}
                </span>
              }
            />
            <Button
              onClick={() => onPrevStep && onPrevStep()}
              mode={"primary"}
              style={{ width: "90px" }}
              content={
                <span className="mx-0" style={{ fontSize: ".8em" }}>
                  Back
                </span>
              }
            />
          </div>
        </div>
      )}
      {typeOfAccountStep === 2 && (
        <div
          ref={mapContainerRef}
          className="d-flex flex-column align-items-center justify-content-between w-100"
        >
          <>
            <MapSelection
              containerStyle={{ height: "50vh", width: mapWidth }}
              onLocationSelect={(latLng, address) => {
                setAddress(address);
                setPosition((pos: any) => {
                  return { ...pos, center: latLng };
                });
              }}
              radius={"1"}
              onRadiusChange={setAreaRadius}
            />
            <div className=" d-flex justify-content-around align-items-center w-100">
              <Button
                onClick={prevTypeOfAccountStep}
                mode={"primary"}
                style={{ width: "90px" }}
                content={
                  <span className="mx-0" style={{ fontSize: ".8em" }}>
                    Back
                  </span>
                }
              />
              <Button
                className="ms-auto"
                disabled={
                  (typeOfAccountStep === 2 && address === null) || onLoading
                }
                ref={submitBtnRef as any}
                onClick={handleSubmit}
                mode={"primary"}
                style={{ width: "90px" }}
                content={
                  <span className="mx-0" style={{ fontSize: ".8em" }}>
                    {onLoading ? "loading..." : "Next Step"}
                  </span>
                }
              />
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default RegisterProStep3;
