import { useEffect, useRef, useState } from "react";
import styles from "../../styles/AppointmentPage.module.css";
import AppointmentDetails from "./AppointmentDetails";
import { IAppointment } from "../../models/Appointment";
import moment from "moment";
import { toServiceLabel } from "../../utils/utils";
import { AnyAction } from "@reduxjs/toolkit";
import { cancelAppointment } from "../../redux/slices/appointmentSlice";
import { useDispatch } from "react-redux";
import { DatePeriod } from "./AppointmentGraph";

const AppointmentItem = ({ appointment, mode = null, period = 'day' }: { appointment: IAppointment, mode?: 'group' | null, period?: DatePeriod }) => {
  const [show, setShow] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const detailRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const handleShow = (value: boolean) => setShow(value);

  const handleCancelAppointment = (appointment: IAppointment): Promise<any> => {
    return dispatch(cancelAppointment(appointment) as unknown as AnyAction).unwrap();
  }

  const getAppointmentColor = (appointment: IAppointment) => {
    const item = document.querySelector(`[data-appointment-id='${appointment._id}']`) as HTMLDivElement;
    if (item) {
      return `${item.style.borderColor}`;
    }
    return 'var(--primaryColor)';

  }


  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {

      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        if (!(event.target as HTMLElement).classList.contains('cancelHandler')) {
          handleShow(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  return (
    <div data-appointment-id={`"${appointment._id}"`} ref={containerRef} style={{ position: "relative", userSelect: 'none' }}>
      <div
        onClick={() => setShow(!show)}
        style={{ borderLeft: `3px solid ${getAppointmentColor(appointment)}` }}
        className={`d-flex flex-column justify-content-center ${styles.AppointmentItem} ${show ? styles['AppointmentItem--active'] : ''}`}
      >

        {

        }

        <div className="d-flex gap-2 fw-normal">
          {moment(appointment.startTime, 'HH:mm').format('h:mm A')}
          <span>-</span>
          {moment(appointment.endTime, 'HH:mm').format('h:mm A')}
        </div>

        <div className={`fw-bold`}>{toServiceLabel(appointment)}</div>
        <div className={`fw-normal`}>{mode === 'group' ? `${appointment.user.first_name} ${appointment.user.last_name}` : appointment.salon.name}</div>

      </div>

      <div ref={detailRef}>
        <AppointmentDetails
          show={show}
          handleShow={setShow}
          appointment={appointment}
          onCancel={handleCancelAppointment}
        />
      </div>
    </div>
  );
};

export default AppointmentItem;
