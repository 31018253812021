import React, { useState } from "react";
import { ISalon } from "../../../models/SalonX";
import MapSelection from "../../map/MapSelection";

type CreateSalonStep2Props = {
  salonValues: Partial<ISalon>;
  setSalonValues: (values: Partial<ISalon>) => void;
};

const CreateSalonStep2: React.FC<CreateSalonStep2Props> = ({
  salonValues,
  setSalonValues,
}) => {
  const handleLocationSelect = (
    latLng: { lat: number; lng: number },
    address: string
  ) => {
    console.log("adressess", latLng, address);
    setSalonValues({
      ...salonValues,
      location: { type: "Point", coordinates: [latLng.lat, latLng.lng] },
      address: address,
    });
  };

  return (
    <form className="w-100 p-3 ps-4 mt-3">
      <span className="mb-4">Step 2/4</span>
      <h3 className="text-black mb-3">Where is your business Located ?</h3>
      <span className=" mb-3">
        Please fill in the details below so that we can have necessary
        information about your salon
      </span>
      <hr />
      <div
        className="flex-column gap-1 rounded salon-input w-100 my-5 "
        aria-autocomplete="none"
      >
        <MapSelection
          containerStyle={{ height: "300px" }}
          onLocationSelect={handleLocationSelect}
          radius={"1"}
          showRadius={false}
        />
      </div>
    </form>
  );
};

export default CreateSalonStep2;
