import React, { useState } from "react";
import DateSelectionForm from "./DateSelection";
import ServicesAddedContainer from "./ServicesAddedContainer";
import TimeSelectionForm from "./TimeSelection";
import { IBookingService } from "../../../models/Service";
import moment from "moment";
import { IBookMakable } from "../../BookmarkButton";
import { ISalonProfile } from "../../../pages/SeeProfessionalAccountPage";
import ProfileInfo from "../../reusable/ProfileInfo";
import { ISalon } from "../../../models/SalonX";
import ChairSelectionForm from "./ChairSelection";
import { IChair } from "../../../models/Chair";

export type TBookingForm = {
  salon: ISalonProfile;
  services: IBookingService[];
  showChairSelectionForm?: boolean;
  times: moment.Moment[];
  defaultValues?: {
    selectedDate?: moment.Moment;
    selectedTime?: moment.Moment;
  };
  onSelectDay: (day: moment.Moment) => void;
  onSelectTime: (day: moment.Moment) => void;
  onSelectChair?: (chair: IChair) => void;
  onRemoveService: (service: IBookingService) => void;
  onAddService: () => void;
  canAddService?: boolean;
} & IBookMakable;

export const BookingForm: React.FC<TBookingForm> = ({
  salon,
  services,
  onSelectTime,
  canAddService = false,
  ...props
}) => {
  const [selectedDay, setSelectedDay] = useState<moment.Moment | undefined>(
    props.defaultValues?.selectedDate
  );
  const [selectedTime, setSelectedTime] = useState<moment.Moment | undefined>(
    props.defaultValues?.selectedTime
  );

  const handleSelectDay = (day: moment.Moment) => {
    console.log("the selection day", day);
    setSelectedDay(day);
    props.onSelectDay(day);
  };

  const handleSelectTime = (time: moment.Moment) => {
    setSelectedTime(time);
    onSelectTime(time);
  };

  const handleChairChange = (chair: IChair) => {
    if (props.onSelectChair) props.onSelectChair(chair);
  };

  const anyChair = {
    label: "Any",
    _id: "any-chair-id",
    active: true,
    salonId: "",
  };

  return (
    <div className="BookNowModal__container__content w-100 px-3">
      <div className="mb-4 w-100">
        <ProfileInfo
          salon={salon as ISalon}
          onBookmark={props.onBookmark}
          bookMarked={props.bookMarked}
        />
      </div>

      <div className="d-flex flex-column gap-2">
        {props.showChairSelectionForm ? (
          <ChairSelectionForm
            onChairChange={handleChairChange}
            chairs={[anyChair, ...salon.chairs]}
            defaultSelectedChair={anyChair}
          />
        ) : null}
        <DateSelectionForm onDateChange={handleSelectDay} />
        {selectedDay && (
          <TimeSelectionForm
            times={props.times}
            onTimeChange={handleSelectTime}
          />
        )}
      </div>

      {selectedTime && (
        <>
          <ServicesAddedContainer
            selectedServices={services}
            onRemoveService={props.onRemoveService}
          />

          {canAddService ? (
            <div className="d-flex justify-content-start align-items-center fw-500 text-primary my-3">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => props.onAddService()}
              >
                <i className="fa fa-plus text-primary"></i> Add Service
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
