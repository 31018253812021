import { useEffect, useState } from "react";
import SalonContainer from "./SalonContainer";
import AddManagerModal from "../modal/AddManagerModal";
import { useUser } from "../../hooks/UseUser";
import { isUserIndependent, isUserProfessional } from "../../models/User";
import { useDispatch, useSelector } from "react-redux";
import { getManagers, selectManagers } from "../../redux/slices/managerSlice";
import { AnyAction } from "@reduxjs/toolkit";
import IndependentSalon from "./IndependentSalon";

type Props = {};

const IndependentDasboard = (props: Props) => {
  const [showManagerModal, setShowManagerModal] = useState(false);
  const user = useUser();
  const UserManagers = useSelector(selectManagers).managers;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getManagers("") as unknown as AnyAction);
  }, []);
  return (
    <div>
      <div className="main-container-header d-flex justify-content-between align-items-center mb-3">
        <div className="text-black fs-4 fw-bold">Business overview</div>
      </div>

      <div className="d-flex gap-2 align-items-center  mt-4 ">
        <img
          src={require("../../assets/images/hair.png")}
          className="img"
          style={{ height: "25px" }}
          alt=""
        />
        <span className="text-black fs-5 fw-bold">Your businesses</span>
      </div>
      <div className=" fs-6">
        Welcome to Your Salon Hub! Manage and elevate your salon empire
        effortlessly. Explore, edit, and oversee your collection of exquisite
        salons
      </div>
      <div className="my-2">
        <IndependentSalon />
      </div>
      <AddManagerModal
        show={showManagerModal}
        onClose={() => setShowManagerModal(false)}
        onCreate={function (formData: FormData): void {
          throw new Error("Function not implemented.");
        }}
      />
    </div>
  );
};

export default IndependentDasboard;
