import React, { ChangeEvent, useEffect, useState } from "react";
import { TMenuItem, Menu } from "./Menu";
import { IProject } from "../../../models/Project";
import { faBan, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProjectCounter } from "./ProjectCounter";
import DropDown from "../../reusable/DropDown";
import ProjectCard from "../ProjectCard";
import { useSalon } from "../../../context/SalonContext";
import { isUserBusinessOwner, isUserProfessional } from "../../../models/User";
import { useUser } from "../../../hooks/UseUser";

export type ProfessionalContainerProps = {
  styles: any;
  projects?: IProject[];
  onAcceptProject: (project: IProject) => void;
  onDeleteProject: (project: IProject) => void;
  onCancelProject: (project: IProject) => void;
  onFilterChange: (filter: Partial<TProjectFilter>) => void;
};

export type TProjectFilter = {
  drive_to_client: boolean;
  drive_to_your_place: boolean;
  custom_style: boolean;
  is_special_event: boolean;
  price: number;
  day: string;
  radius: number;
};

const PROFESSIONAL_MENU_ITEM = [
  { label: "Dashboard", id: "pending" },
  { label: "Accepted", id: "accepted" },
  // { label: "Disputed", id: "disputed" },
];

export const EmptyDashboard = () => {
  return (
    <div
      className="d-flex flex-column gap-2 align-items-center justify-content-center user-select-none"
      style={{ width: "400px", height: "400px" }}
    >
      <FontAwesomeIcon size="3x" icon={faBan} className="mr-2 text-muted" />
      <div className="fw-400">YOUR DASHBOARD IS EMPTY</div>
    </div>
  );
};

export const ProfessionalContainer: React.FC<ProfessionalContainerProps> = ({
  styles,
  projects = [],
  ...props
}) => {
  const salon = useSalon();

  const [activeMenu, setActiveMenu] = useState<TMenuItem>(
    PROFESSIONAL_MENU_ITEM[0]
  );

  useEffect(() => {
    console.log("current salon", salon);
  }, []);

  const handleChangeActiveMenu = (menu: TMenuItem) => {
    setActiveMenu(menu);
  };
  const user = useUser();
  const userIsBusinessOwner = isUserBusinessOwner(user);

  const [filter, setFilter] = useState<Partial<TProjectFilter>>({});

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setFilter((prev) => ({
      ...prev,
      price: Number(value),
    }));
  };

  const actions = [
    <label
      key={"actions-drive_to_client"}
      className="d-flex align-items-center gap-1"
    >
      <input
        className="form-check-input mt-0"
        type="checkbox"
        name="account_type"
        value="Independent"
        id="filterOption1"
        checked={filter.drive_to_client}
        onClick={(e: any) =>
          setFilter((prev) => ({
            ...prev,
            drive_to_client: !prev.drive_to_client,
          }))
        }
      />
      <span>Drive to client</span>
    </label>,
    <label
      key={"actions-is_special_event"}
      className="d-flex align-items-center gap-1"
    >
      <input
        className="form-check-input mt-0 "
        type="checkbox"
        name="event"
        value="day"
        id="filterOption6"
        checked={filter.drive_to_your_place}
        onClick={(e: any) =>
          setFilter((prev) => ({
            ...prev,
            drive_to_your_place: !prev.drive_to_your_place,
          }))
        }
      />
      <span>Drive to you</span>
    </label>,
    <label
      key={"actions-custom_style"}
      className="d-flex align-items-center gap-1"
    >
      <input
        className="form-check-input mt-0"
        type="checkbox"
        name="custom"
        value="custom"
        id="filterOption3"
        checked={filter.custom_style}
        onClick={(e: any) =>
          setFilter((prev) => ({ ...prev, custom_style: !prev.custom_style }))
        }
      />
      <span>Custom style</span>
    </label>,

    <label
      key={"actions-is_special_event"}
      className="d-flex align-items-center gap-1"
    >
      <input
        className="form-check-input mt-0 "
        type="checkbox"
        name="event"
        value="date"
        id="filterOption6"
        checked={filter.is_special_event}
        onClick={(e: any) =>
          setFilter((prev) => ({
            ...prev,
            is_special_event: !prev.is_special_event,
          }))
        }
      />
      <span>Special event/Travel</span>
    </label>,
    <label key={"actions-price"} className="d-flex align-items-center gap-1">
      <span>Price</span>
      <input
        style={{ height: "30px", width: "7rem" }}
        className="form-check-input mt-0 p-2 rounded"
        type="number"
        name="price"
        step={5}
        min={5}
        value={filter.price}
        id="filterOption4"
        // onChange={(e: any) =>
        //   setFilter((prev) => ({ ...prev, price: e.target.value }))
        // }
        onChange={handlePriceChange}
      />
    </label>,
    <label key={"actions-date"} className="d-flex align-items-center gap-1">
      <span>Date</span>
      <input
        style={{ height: "30px", width: "7rem" }}
        className="form-check-input mt-0 p-2 rounded"
        type="date"
        name="day"
        value={filter.day}
        id="filterOption4"
        onChange={(e: any) =>
          setFilter((prev) => ({ ...prev, day: e.target.value }))
        }
      />
    </label>,
    <label key={"actions-range"} className="d-flex flex-column ">
      <span>Service Radius</span>
      <input
        style={{
          height: "10px",
          width: "8rem",
          accentColor: "var(--primaryColor)",
        }}
        type="range"
        name="range"
        value={filter.radius}
        id="filterOption4"
        onChange={(e: any) =>
          setFilter((prev) => ({ ...prev, radius: e.target.value }))
        }
      />
    </label>,
  ];

  const filteredProjects = projects.filter((project: IProject) => {
    if (userIsBusinessOwner) {
      return (
        project.status === activeMenu.id &&
        project.UserType !== "business_owner"
      );
    }
    return project.status === activeMenu.id;
  });

  console.log("filteredProjects", filteredProjects);

  React.useEffect(() => {
    props.onFilterChange(filter);
  }, [filter]);

  return (
    <div className="d-flex flex-column justify-content-between gap-3">
      <div className="d-flex">
        <Menu
          menuItems={PROFESSIONAL_MENU_ITEM}
          styles={styles}
          activeMenu={activeMenu}
          onChangeActiveMenu={handleChangeActiveMenu}
        />
        <div
          className={`${styles["PostProjectModal__Header"]} w-100  d-flex justify-content-between gap-5 align-items-center`}
        >
          <div className="ms-auto">
            <div
              className="d-flex alert gap-1 p-2 border user-select-none"
              role="alert"
            >
              <div>
                <FontAwesomeIcon className="mt-1" icon={faInfoCircle} />
              </div>
              <span>
                Your are acting as <strong>{salon.salon?.name}</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="rounded">
          <DropDown
            closeOnClickOutside
            title={
              <>
                <i className={`fa-solid fa-sliders`}></i> Filters
              </>
            }
            items={actions}
          />
        </div>
        <ProjectCounter count={filteredProjects.length} />
      </div>
      <div
        style={{ maxHeight: "70vh", overflowY: "auto" }}
        className="d-flex flex-column mb-3 align-items-center border-top"
      >
        {filteredProjects.length === 0 && <EmptyDashboard />}

        {filteredProjects.length !== 0 &&
          activeMenu.id === "accepted" &&
          filteredProjects.map((project: IProject) => {
            return (
              <div key={project._id} className="my-2 w-100">
                <ProjectCard
                  key={project._id}
                  mode="accepted"
                  type="professional"
                  onCancel={props.onCancelProject}
                  project={project}
                />
              </div>
            );
          })}

        <div className="d-flex flex-column gap-2 mt-2 mb-2 w-100">
          {filteredProjects.length !== 0 &&
            activeMenu.id === "pending" &&
            filteredProjects.map((project: IProject) => {
              return (
                <ProjectCard
                  key={project._id}
                  mode="pending"
                  onAccept={props.onAcceptProject}
                  type="professional"
                  project={project}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
