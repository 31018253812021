import * as Yup from "yup";
export interface LoginValues {
  email: string;
  password: string;
  message?: string;
  otp?: number;
}

export const initialValues: LoginValues = {
  email: "",
  password: "",
};

export const validationSchema = Yup.object({
  reset_url: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Required"),
});
