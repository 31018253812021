import { useState, useEffect } from 'react';

export enum GeolocationPermissionState {
  Granted = 'granted',
  Prompt = 'prompt',
  Denied = 'denied',
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface PositionError {
  code: number;
  message: string;
}

export interface LocationState {
  coordinates: Coordinates | null;
  permissionState: GeolocationPermissionState | null;
  error: PositionError | null;
}

export interface GeolocationHook {
  locationState: LocationState;
  handlePermission: () => void;
}

const useGeolocation = (): GeolocationHook => {
  const [locationState, setLocationState] = useState<LocationState>({
    coordinates: null,
    permissionState: null,
    error: null,
  });

  const handlePermission = () => {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      setLocationState((prevState) => ({
        ...prevState,
        permissionState: result.state as GeolocationPermissionState,
      }));

      if (result.state === GeolocationPermissionState.Granted) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocationState((prevState) => ({
              ...prevState,
              coordinates: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
              error: null,
            }));
          },
          (error) => {
            setLocationState((prevState) => ({
              ...prevState,
              error,
            }));
          }
        );
      }
    });
  };

  useEffect(() => {
    handlePermission();
  }, []);

  return {
    locationState,
    handlePermission,
  };
};

export default useGeolocation;