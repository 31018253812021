import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ModalLoader } from "../modal/commons/ModalLoader";
import Service from "../../models/Service";
import { formatTime, formatUSPrice } from "../../utils/utils";
import PromoCodeGenerator from "./PromoCodeGenerator";
import { servicePromotionSchema } from "../validation/ServiceMenuValidation";
import { AnyAction } from "@reduxjs/toolkit";
import { createDispatchHook, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { addServicePromotion } from "../../redux/slices/serviceSlice";
import toast from "react-hot-toast";
import Button from "../Button";

export interface PromotionSeviceModalProps {
  show: boolean;
  service: Service;
  onClose: () => void;
  onSelect: (service: Service) => void;
}

function PromotionModal({
  service,
  show,
  onClose,
  onSelect,
}: PromotionSeviceModalProps) {
  const [loading, setLoading] = useState(false);
  const handlerClose = () => {};
  const dispatch = useDispatch();
  const formik = useFormik<Partial<Service>>({
    initialValues: {
      promote: {
        isPromote: false,
        price_promotion: 0,
        startTime: "",
        endTime: "",
      },
    },
    validationSchema: servicePromotionSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (!service) return;
      const updatedService = {
        ...service,
        promote: {
          isPromote: true,
          price_promotion: Number(values.promote?.price_promotion),
          startTime: new Date(values.promote?.startTime as string),
          endTime: new Date(values.promote?.endTime as string),
        },
      };
      try {
        // await dispatch(
        //   addServicePromotion(updatedService as Service) as unknown as AnyAction
        // ).unwrap();
        // toast.success("Promotion created successfully!");
      } catch (err) {
        toast.error(
          "An error occurred during edition, please try again later i don't"
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal backdrop="static" show={show} centered size="lg" onHide={onClose}>
      <Modal.Header>
        <div className="text-black fw-500 fs-5">Add Promotion</div>
      </Modal.Header>
      {loading && <ModalLoader />}
      <Modal.Body>
        <div className="popular-service align-items-start d-flex flex-column overflow-y-scroll ">
          <h5 className="text-break mb-4">Your popular services</h5>
          <div className="popular-service-container gap-3 d-flex  flex-column w-100">
            <div className="w-100  ">
              {service ? (
                <div className="service-details w-100  gap-4">
                  <div className="w-100 d-flex gap-4">
                    <div>
                      <label htmlFor="name">Service Name</label>
                      <input
                        id="name"
                        className="form-control w-100 flex-grow-1  "
                        type="text"
                        value={service.service_detail.name ?? "hf     "}
                        disabled
                      />
                    </div>
                    <div>
                      <label htmlFor="time">Service Time</label>
                      <input
                        id="time"
                        className="form-control w-100 flex-grow-1  "
                        type="text"
                        value={formatTime(
                          service?.duration as unknown as number
                        )}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="pt-3">
                    {service.size ? (
                      service.size.length > 1 ? (
                        <>
                          <label htmlFor="size">Service Size</label>
                          <span
                            aria-disabled
                            id="size"
                            className="form-control w-100"
                          >
                            {service.size[0]}, {service.size[1]}
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            aria-disabled
                            id="size"
                            className="form-control w-100"
                          >
                            {service.size[0]}
                          </span>
                        </>
                      )
                    ) : null}
                  </div>
                  <div className="pt-3">
                    <label htmlFor="description">Description</label>
                    <input
                      id="description"
                      className="form-control w-100 text-start flex-none  "
                      style={{ height: "100px" }}
                      type="text"
                      value={service.description ?? ""}
                      disabled
                    />
                  </div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="d-flex gap-2 pt-3">
                      <div>
                        <label htmlFor="price">initial Price</label>
                        <input
                          id="price"
                          className="form-control w-100  "
                          type="text"
                          value={formatUSPrice(service.price) ?? ""}
                          disabled
                        />
                      </div>
                      <div>
                        <label htmlFor="promote.price_promotion">
                          New Price
                        </label>
                        <input
                          id="promote.price_promotion"
                          autoComplete="off"
                          className="form-control w-100"
                          type="number"
                          step={4}
                          min={1}
                          required
                          {...formik.getFieldProps("promote.price_promotion")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="promote.price_promotion"
                          placeholder="New Price"
                        />
                      </div>
                    </div>
                    <div className="d-flex gap-2 pt-3 ">
                      <div className="w-100">
                        <label htmlFor="promote.startTime">
                          Start Promotion
                        </label>
                        <input
                          id="promote.startTime"
                          className="form-control w-100"
                          type="date"
                          {...formik.getFieldProps("promote.startTime")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Enter a new Date"
                          name="promote.startTime"
                        />
                      </div>
                      <div className="w-100">
                        <label htmlFor="promote.endTime">End Promotion</label>
                        <input
                          id="promote.endTime"
                          className="form-control w-100"
                          type="date"
                          {...formik.getFieldProps("promote.endTime")}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="promote.endTime"
                        />
                      </div>
                    </div>
                    {/* <div className="pt-3">
                    <span>Apply to all customers who have : </span>
                    <div
                      className="w-100ck m-3 d-flex justify-content-center align-items-center gap-4"
                      style={{ height: 80, border: "1px solid #333" }}
                    >
                      <div>
                        <label htmlFor="Fiend">Friend Family</label>
                        <input 
                        
                          type="checkbox"
                          name="Frind"
                          id="Friend"
                          checked={isCheck}
                          onClick={() => setIsCheck(!isCheck)}
                        />
                        {isCheck ? (
                          <p>Hello</p>
                        ) : // <CustomDropdown
                        //   items={
                        //     MemberNameData === null
                        //       ? []
                        //       : toCustomItem(MemberNameData as MemberType[])
                        //   }
                        //   keyExtractor={(item: Item) => item._id}
                        //   render={(item: Item) => item.value}
                        //   placeholder={"Select a Member"}
                        //   name={"Member"}
                        // />
                        null}
                      </div>
                      <div>
                        <label htmlFor="Fiend">Friend Family</label>
                        <input type="checkbox" name="Frind" id="Friend" />
                      </div>
                    </div>
                  </div> */}
                    <div className="d-flex">
                      <PromoCodeGenerator />
                    </div>

                    {/* <button
                      style={{ height: "40px" }}
                      className="btn btn-primary mt-4"
                      ref={submitBtnRef}
                      type="submit"
                      onClick={formik.handleSubmit}
                      disabled={selectedService === editedService}
                    >
                      Get promoted your profile
                    </button> */}
                    <Button
                      type="submit"
                      style={{
                        height: "40px",
                        alignSelf: "end",
                        marginBottom: "1rem",
                      }}
                      //ref={submitBtnRef}
                      disabled={formik.isSubmitting}
                      mode={"primary"}
                      content={"Save"}
                      className="mt-2"
                    />
                  </form>
                </div>
              ) : (
                <p>No service selected</p>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PromotionModal;
