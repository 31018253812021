import { FC, ReactNode, createContext, useContext, useMemo, useState } from "react";
import { ISalon } from "../models/SalonX";

type SalonContextProps = {
    salon: ISalon | null;
    setSalon: React.Dispatch<React.SetStateAction<ISalon | null>>;
}

const SalonContext = createContext<SalonContextProps | undefined>(undefined);


export const SalonProvider: FC<{ children: ReactNode }> = ({ children }) => {

    const [salon, setSalon] = useState<ISalon | null>(null);

    const contextValue = useMemo(() => {
        return { salon, setSalon }
    }, [salon, setSalon])

    return <SalonContext.Provider value={contextValue}>
        {children}
    </SalonContext.Provider>
}

export const useSalon = (): SalonContextProps => {
    const context = useContext(SalonContext)

    if(!context){
        throw new Error('useSalon must be used within SalonProvider')
    }

    return context;
}