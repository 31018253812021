import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import Service from "../../models/Service";
import { ServiceService } from "../../services/ServiceService";
import { errorToast } from "../../hooks/UseErrors";

export interface ServiceState {
  services: Service[] | null;
  userServices: Service[] | null;
  errors: any;
}

const initialState: ServiceState = {
  userServices: null,
  services: null,
  errors: null,
};

export const getServices = createAsyncThunk<Service[], string>(
  "services/getService",
  async () => {
    const res = await ServiceService.getServices();
    return res.data;
  }
);

export const getUserServices = createAsyncThunk<Service[], string>(
  "services/getUserService",
  async () => {
    const res = await ServiceService.getUSerServices();
    return res.data;
  }
);

export const addService = createAsyncThunk<Partial<Service>, Partial<Service>>(
  "services/addService",
  async (service: Partial<Service>, { rejectWithValue }) => {
    try {
      const res = await ServiceService.addService(service);
      return res.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const addServiceDetail = createAsyncThunk<
  Partial<Service>,
  Partial<Service>
>(
  "services/addServiceDetail",
  async (service: Partial<Service>, { rejectWithValue }) => {
    try {
      const res = await ServiceService.addServiceDetail(service);
      return res.data;
    } catch (error: any) {
      console.log(error.response.data);
      let errorMessage = error.response.data.errors;
      if (error.response.status === 409) {
        errorMessage = error.response.data.errors.message;
      } else if (error.response.status === 422) {
        errorMessage = error.response.data.errors.service_categorie;
      }
      errorToast(errorMessage);
      return rejectWithValue(error.response.data.errors);
    }
  }
);
export const addServicePromotion = createAsyncThunk<
  Partial<Service>,
  Service[]
>(
  "services/addServicePromotion",
  async (promotion: Service[], { rejectWithValue }) => {
    try {
      const res = await ServiceService.addServicePromotion(promotion);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const editService = createAsyncThunk<Partial<Service>, Partial<Service>>(
  "services/editService",
  async (service: Partial<Service>, { rejectWithValue }) => {
    try {
      const res = await ServiceService.editService(service);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const deleteService = createAsyncThunk<Partial<Service[]>, any>(
  "services/deleteService",
  async (id: any, { rejectWithValue }) => {
    try {
      const res = await ServiceService.deleteService(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
      });
    }
  }
);

export const clearErrors = createAction("services/clearErrors");

const serviceSlice = createSlice({
  name: "services",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServices.fulfilled, (state, action) => {
        state.services = action.payload;
      })
      .addCase(getUserServices.fulfilled, (state, action) => {
        state.userServices = action.payload;
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        state.userServices = action.payload as Service[];
      })
      .addCase(addService.fulfilled, (state, action) => {
        const newService = state.userServices?.slice();
        newService?.push(...(action.payload as Service[]));
        if (newService) state.userServices = newService;

        const services = state.services?.slice();
        services?.push(...(action.payload as Service[]));
        if (services) state.services = services;
        state.errors = null;
      })
      .addCase(addServiceDetail.fulfilled, (state, action) => {
        const newService = state.userServices?.slice();
        newService?.push(...(action.payload as Service[]));
        if (newService) state.userServices = newService;

        const services = state.services?.slice();
        services?.push(...(action.payload as Service[]));
        if (services) state.services = services;
        state.errors = null;
      })
      .addCase(editService.fulfilled, (state, action) => {
        // Assuming action.payload is the updated service
        if (state.userServices) {
          // Find the index of the service to be updated
          const index = state.userServices.findIndex(
            (service) => service._id === action.payload._id
          );
          if (index !== -1) {
            // Update the service in userServices
            state.userServices[index] = {
              ...state.userServices[index],
              ...action.payload,
              name: action.payload.name || "", // Provide a default value for name
            };
          }
        }
      })
      .addCase(addService.rejected, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(addServiceDetail.rejected, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(addServicePromotion.fulfilled, (state, action) => {
        // Update the service with the promotion
        if (state.userServices) {
          const index = state.userServices.findIndex(
            (service) => service._id === action.payload._id
          );
          if (index !== -1) {
            state.userServices[index] = {
              ...state.userServices[index],
              ...action.payload,
              promote: action.payload.promote,
            };
          }
        }
      })
      .addCase(addServicePromotion.rejected, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.errors = action.payload;
      })
      .addCase(clearErrors, (state, action) => {
        state.errors = null;
      });
  },
});

export default serviceSlice.reducer;
