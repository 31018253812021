import axios from "axios";
import Service from "../models/Service";

const getServices = () => {
  return axios
    .get(`services`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "x-salon-id": `${localStorage.getItem("salonId")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const addService = (service: Partial<Service>) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}services`,
    method: "POST",
    data: service,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

const addServiceDetail = (serviceDetail: Partial<Service>) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}services/categorie/detail`,
    method: "POST",
    data: serviceDetail,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

const getUSerServices = () => {
  return axios
    .get(`services/user-services`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "x-salon-id": `${localStorage.getItem("salonId")}`,
      },
    })
    .then((data) => {
      return data;
    });
};

const addServicePromotion = (service: Service[]) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}services/promotion`,
    method: "PATCH",
    data: service,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

const editService = (service: Partial<Service>) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}services`,
    method: "PATCH",
    data: service,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};
const deleteService = (id: any) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}services`,
    method: "DELETE",
    data: id,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      "x-salon-id": `${localStorage.getItem("salonId")}`,
    },
  }).then((data) => {
    return data;
  });
};

const getAllServiceNames = () => {
  return axios.get("unauthenticated/services/names").then((data) => {
    return data.data;
  });
};

export const ServiceService = {
  getServices,
  addService,
  addServiceDetail,
  addServicePromotion,
  getUSerServices,
  editService,
  deleteService,
  getAllServiceNames,
};
