import React, { ReactEventHandler, useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import MainNav from "../components/navigation/MainNav";

import "../styles/SearchBar.css";
import Accordion from "../components/accordion/Accordion";
import {
  accordeonData,
  accordionDataBusiness,
  accordionDatas,
  data,
} from "../utils/data";
import SearchBarFilter from "../components/support/SearchBarFilter";
import { SearchBar } from "../components/support/SearchBar";

export const SupportPage = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [showVisible, setShowVisible] = useState(false);
  const independentRef = useRef<HTMLLabelElement>(null);
  const businessRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    inputRef.current?.addEventListener("keydown", handleShowData);
  }, [inputRef]);

  const handleTab1 = () => {
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    setActiveTab("tab2");
  };
  const handleShowData = () => {
    setShowVisible(!showVisible);
  };

  return (
    <div
      className="d-flex flex-column justify-content-between h-100 "
      ref={mainRef}
    >
      <MainNav navType={"connectedNav"} isSearch={true} />
      <div
        className="  support_hero_section d-flex flex-row justify-content-start align-items-center"
        style={{ height: "300px", width: "100%" }}
      >
        <div className="container">
          <h1 className="title-hero-section">Support us</h1>
        </div>
      </div>

      <main className="support-container ">
        <h2 className="title-support">How can we help?</h2>

        <SearchBar
          supports={accordionDatas}
          visible={showVisible}
          onShow={handleShowData}
          inputRef={inputRef}
        />
        {!showVisible && (
          <>
            <div className="container-category">
              {data.map((item) => (
                <div key={item.id} className="box-category">
                  <div className=" icon-category">
                    <i className={item.icon}></i>
                  </div>
                  <div className=" text-category">
                    <h5 className="title-category">{item.title}</h5>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <div className="tabs">
                <input
                  type="radio"
                  id="independent"
                  name="cat_account"
                  value="Independent"
                  checked
                />
                <label
                  htmlFor="independent"
                  onClick={handleTab1}
                  ref={independentRef}
                  className={activeTab === "tab1" ? "active" : ""}
                >
                  Independent help
                </label>
                <input
                  type="radio"
                  id="business"
                  name="cat_account"
                  value="Busines"
                />
                <label
                  htmlFor="business"
                  onClick={handleTab2}
                  ref={businessRef}
                  className={activeTab === "tab2" ? "active" : ""}
                >
                  Business help
                </label>
              </div>
            </div>
            {activeTab === "tab1" ? (
              <div className="comment-container" id="independent">
                <div className="comment-content">
                  <h2 className="title-comment">Recurring question</h2>
                  <Accordion items={accordeonData} />
                </div>
              </div>
            ) : (
              <div className="comment-container" id="business">
                <div className="comment-content">
                  <h2 className="title-comment">Recurring business help</h2>
                  <Accordion items={accordionDataBusiness} />
                </div>
              </div>
            )}
          </>
        )}
      </main>
      <Footer />
    </div>
  );
};
