export interface CustomAppointment {
  professional: string;
  serviceName: string;
  description: string;
  price: number;
  size: string;
  drive_to_your_place: boolean;
  traveling_expenses: number;
  day: string;
  startTime: string;
  lat: number;
  lng: number;
  address: string;
  photo: Blob;
}

export const CustomAppointmentValues: Partial<CustomAppointment> = {
  serviceName: "",
  description: "",
  size: "",
  drive_to_your_place: false,
  traveling_expenses: 0,
  day: "",
  lat: 0,
  lng: 0,
};
