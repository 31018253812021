import { HTMLProps, useEffect, useState } from "react";
import Button from "../Button";
import Modal from "./Modal";
import "./../styles/RequestCustomModal.css";
import "./../styles/BookNowModal.css";
import { IBookingService, IService } from "../../models/Service";
import { getAllDatesInMonth, groupTimeSlotsByDate } from "../../utils/utils";
import moment from "moment";
import { ProfessionalService } from "../../services/ProfessionalService";
import { TimeSlotGroup } from "../../models/TimeSlot";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import LoginPage from "../../pages/LoginPage";
import { addAppointment } from "../../redux/slices/appointmentSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { ModalLoader } from "./commons/ModalLoader";
import { BookingForm } from "./commons/BookingForm";
import { TotalAndContinueSection } from "./commons/TotalAndContinueSection ";
import { ServiceSelector } from "./commons/ServiceSelector";
import { getPossibleStartTime, getSlotInterval } from "./helper/helper";
import { IBookMakable } from "../BookmarkButton";
import { ISalonProfile } from "../../pages/SeeProfessionalAccountPage";
import { IChair } from "../../models/Chair";
import QrCodePage from "../account/QrCode";
import SelectMemberModal from "./SelectMemberModal";
import { useMember } from "../../hooks/useMember";
import { selectMembers } from "../../redux/slices/memberSlice";
import { AddPromotion } from "../promotion/AddPromotion";
import { useUser } from "../../hooks/UseUser";

type BookNowModalProps = HTMLProps<HTMLDivElement> & {
  backdrop: boolean;
  service: IService;
  salon: ISalonProfile;
  onClose: () => void;
  onBook: () => void;
  onSendBookRequest: () => void;
} & IBookMakable;

const BookNowModal: React.FC<BookNowModalProps> = ({
  service,
  salon,
  ...props
}: BookNowModalProps) => {
  const currentUser = localStorage.getItem("authToken");
  const allMember = useMember();
  const user = useUser();

  useEffect(() => {
    console.log("all allMembers c", allMember);
  }, []);
  const [openMemberModal, setOpenMemberModal] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment());
  const [selectedDate, setSelectedDate] = useState<moment.Moment | undefined>(
    undefined
  );
  const [selectedTime, setSelectedTime] = useState<moment.Moment | undefined>(
    undefined
  );
  const [selectedChair, setSelectedChair] = useState<IChair | undefined>(
    undefined
  );

  const [selectedMonthDates, setSelectedMonthDates] = useState<moment.Moment[]>(
    []
  );
  const [selectedDateTimes, setSelectedDateTimes] = useState<moment.Moment[]>(
    []
  );
  const [memberBookData, setMemberBookData] = useState<any>(null);
  const [selectedServices, setSelectedServices] = useState<IBookingService[]>([
    { ...service, position: 0 },
  ]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalHours, setTotalHours] = useState<number>(0);
  const [onSelectService, setOnSelectService] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [timeSlots, setTimeSlots] = useState<TimeSlotGroup | null>(null);
  const [onLogin, setOnLogin] = useState(false);
  const [openModal, setOpenModal] = useState(!user?.coupon?.actived ?? false);

  const dispatch = useDispatch();

  const removeService = (service: IBookingService) => {
    handleLoading(true);
    const services = selectedServices
      .filter((item) => {
        return item.position !== service.position;
      })
      .map((item, key) => {
        return { ...item, position: key };
      });
    setSelectedServices(services);
    __handleSelectDay(selectedDate as moment.Moment, timeSlots, services);
    handleLoading(false);
  };

  const __handleSelectDay = (
    day: moment.Moment,
    _timeSlots: typeof timeSlots,
    defaultServices: IBookingService[] | null = null
  ) => {
    if (_timeSlots) {
      let currentDaySlots = _timeSlots[day.format("YYYY-MM-DD")];

      console.log("currentDaySlots",currentDaySlots)

      if (currentDaySlots) {
        currentDaySlots = currentDaySlots.filter((slot) => slot.isAvailable);
      } else {
        currentDaySlots = [];
      }

      if (currentDaySlots && currentDaySlots.length > 0) {
        let dates = currentDaySlots
          .slice()
          .map((slot) =>
            moment(
              `${day.format("YYYY-MM-DD")} ${slot.time}`,
              "YYYY-MM-DD HH:mm A"
            )
          );
        console.log("The current dates slot", dates);
        // dates = getPossibleStartTime(totalHours, dates);
        setSelectedDateTimes(dates);
        // setSelectedDate(dates[0]);

        let lastSlotPosition = 0;
        const daySlots = currentDaySlots.slice().filter((slot) => {
          const slotMoment = moment(
            `${day.format("YYYY-MM-DD")} ${slot.time}`,
            "YYYY-MM-DD HH:mm A"
          );
          return slotMoment.diff(dates[0], "minutes") >= 0;
        });

        let services: IBookingService[] = [];

        (defaultServices ?? selectedServices).forEach((service, key) => {
          const { time, position } = getSlotInterval(
            service,
            lastSlotPosition,
            daySlots.filter((slot) => {
              return (
                moment(
                  `${day.format("YYYY-MM-DD")} ${slot.time}`,
                  "YYYY-MM-DD HH:mm A"
                ).diff(selectedTime, "minutes") >= 0
              );
            })
          );
          if (time.start && time.end) {
            lastSlotPosition = position.end;
            services.push({
              ...service,
              startTime: time.start,
              endTime: time.end,
              position: key,
            });
          } else {
            services.push({ ...service, position: key });
          }
        });

        setSelectedServices(services);
      } else {
        setSelectedDateTimes([]);
      }
    }
    setSelectedDate(day);
    return 0;
  };

  const handleSelectDay = async (day: moment.Moment) => {
    handleLoading(true);
    const chairId =
      selectedChair?._id === "any-chair-id" ? null : selectedChair?._id;

    const durationInMinutes = totalHours === 0 ? service.duration : totalHours;
    console.log(
      "data in bookNowModal",
      chairId,
      durationInMinutes,
      moment(day),
      salon._id
    );
    await ProfessionalService.getProfessionalTimeSlots(
      salon._id,
      moment(day),
      durationInMinutes,
      chairId
    )
      .then(async (res) => {
        console.log("all time by currrent day", res);
        const slots = groupTimeSlotsByDate(res.slots);

        console.log("slots with correct timezome", slots);

        const chair = salon.chairs.find((chair) => chair._id === res.chairId);

        setSelectedChair(chair);
        setTimeSlots(slots);
        await selectDay(day, slots).then(() => {
          handleLoading(false);
        });
      })
      .catch(async (err) => {
        await selectDay(day, {}).then(() => {
          handleLoading(false);
        });
        toast.error("Something went wrong, please try again.", {
          duration: 7000,
        });
      });
  };

  const selectDay = async (day: moment.Moment, slots: typeof timeSlots) => {
    return new Promise((resolve) => {
      __handleSelectDay(day, slots);
      resolve({
        selectedDate,
        selectedDateTimes,
        selectedServices,
      });
    });
  };

  const handleSelectTime = async (time: moment.Moment) => {
    console.log("time", time);
    setSelectedTime(time);
  };

  const handleLoading = (state: boolean) => {
    setLoading(state);
  };

  const handleSelectService = (service: IService) => {
    if (loading) return;
    handleLoading(true);
    const prevServices = selectedServices.slice();
    prevServices.push({ ...service, position: prevServices.length });
    setSelectedServices(prevServices);
    setOnSelectService(false);
    // TODO: Refresh time slot when adding new service
    handleLoading(false);
  };

  const canContinue = (
    dateTimes: moment.Moment[],
    time: moment.Moment | undefined,
    service: IBookingService
  ): boolean => {
    if (dateTimes && dateTimes.length > 0 && service.startTime && time) {
      const lastTime = dateTimes.at(-1);
      if (lastTime) {
        return lastTime.diff(time, "minutes") >= 0;
      }
    }
    //setOpenModal(false);
    return false;
  };

  const handleCloseServiceSelector = () => {
    setOnSelectService(false);
  };

  const handleLogin = () => {
    window.location.href = "/login";
  };

  const handleRegister = () => {
    window.location.href = "/register";
  };

  const handleSelectMember = () => {
    const startTime = selectedServices[0].startTime as moment.Moment;

    const bookData = {
      day: selectedDate?.format("YYYY-MM-DD"),
      startTime: startTime.tz("UTC").format("H:mm"),
      salonId: salon._id,
      chairId:
        selectedChair?._id === "any-chair-id" ? null : selectedChair?._id,
      serviceIds: selectedServices.map((service) => service._id),
    };

    setMemberBookData(bookData);

    setOpenMemberModal(true);
  };

  const handleBookServices = () => {
    if (!currentUser) {
      toast.remove();
      return toast.success(
        (t) => (
          <div className="d-flex flex-column gap-2 justify-content-center">
            <p>
              You must login first before booking services. If you don't have an
              account you can{" "}
              <span
                role="button"
                onClick={handleRegister}
                className="createAccountLink"
              >
                create one here.
              </span>
            </p>
            <div className="d-flex gap-2 justify-content-end">
              <Button
                className="rounded mx-2"
                mode="default"
                onClick={handleLogin}
              >
                {" "}
                Login{" "}
              </Button>
            </div>
          </div>
        ),
        {
          icon: null,
        }
      );
    }

    const startTime = selectedServices[0].startTime as moment.Moment;

    const bookData = {
      day: selectedDate?.format("YYYY-MM-DD"),
      startTime: startTime.tz("UTC").format("H:mm"),
      salonId: salon._id,
      chairId:
        selectedChair?._id === "any-chair-id" ? null : selectedChair?._id,
      serviceIds: selectedServices.map((service) => service._id),
    };

    handleLoading(true);

    dispatch(addAppointment(bookData) as unknown as AnyAction)
      .unwrap()
      .then((res: any) => {
        props.onBook();
      })
      .catch((err: any) => {
        const errors = err.errors;
        if (err.status) {
          if (err.status === 500) {
            return toast.error(errors.message, {
              duration: 7000,
            });
          } else {
            if (errors.code === "INSUFFICIENT_BALANCE") {
              return toast.error(
                "Insufficient Balance! Please add funds to your account to book the selected services.",
                {
                  duration: 7000,
                }
              );
            }
            return toast.error(errors.message, {
              duration: 7000,
            });
          }
        }
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const handleSelectChair = (chair: IChair) => {
    setSelectedChair(chair);
  };

  useEffect(() => {
    const datesInMonth = getAllDatesInMonth(selectedMonth);
    setSelectedMonthDates(datesInMonth);
    return () => {
      setSelectedMonthDates([]);
    };
  }, [selectedMonth]);

  useEffect(() => {
    // setTotalPrice(
    //   selectedServices.slice().reduce((prev, curr) => prev + curr.price, 0)
    // );
    if (service.promote) {
      if (service.promote.isPromote === true) {
        setTotalPrice(
          selectedServices
            .slice()
            .reduce(
              (prev, curr) => prev + curr.promote.price_promotion ?? curr.price,
              0
            )
        );
      } else {
        setTotalPrice(
          selectedServices.slice().reduce((prev, curr) => prev + curr.price, 0)
        );
      }
    }
    setTotalHours(
      selectedServices
        .slice()
        .reduce((prev, curr) => prev + (curr.duration as unknown as number), 0)
    );
    // return () => {
    //   setTotalPrice(0);
    //   setTotalHours(0);
    // };
  }, [selectedServices]);

  useEffect(() => {
    setSelectedDateTimes(getPossibleStartTime(totalHours, selectedDateTimes));
    return () => {
      setSelectedDateTimes([]);
    };
  }, [totalHours]);

  useEffect(() => {
    if (selectedDate)
      __handleSelectDay(selectedDate as moment.Moment, timeSlots);
    return () => {
      setSelectedDate(undefined);
    };
  }, [selectedTime]);

  useEffect(() => {
    if (selectedDate) {
      handleSelectDay(selectedDate);
    }
    return () => {
      setSelectedDate(undefined);
    };
  }, [selectedChair]);

  return (
    <Modal {...props} className="modal-lg">
      <div className="BookNowModal__container">
        {loading && <ModalLoader />}
        {!onLogin && !onSelectService && (
          <>
            {" "}
            <div className="BookNowModal__container__content px-3">
              <BookingForm
                onRemoveService={removeService}
                onSelectTime={handleSelectTime}
                onSelectDay={handleSelectDay}
                services={selectedServices}
                salon={salon}
                times={selectedDateTimes}
                onAddService={() => setOnSelectService(true)}
                onSelectChair={handleSelectChair}
                canAddService
                bookMarked={props.bookMarked}
                onBookmark={props.onBookmark}
                showChairSelectionForm={salon.chairs.length >= 1}
                defaultValues={{
                  selectedDate,
                  selectedTime,
                }}
              />
            </div>
            {selectedTime && (
              <TotalAndContinueSection
                price={totalPrice}
                duration={totalHours}
                canContinue={canContinue(
                  selectedDateTimes,
                  selectedTime,
                  selectedServices[0]
                )}
                salon={salon}
                onContinue={handleBookServices}
                onSelectMember={handleSelectMember}
              />
            )}
          </>
        )}

        <SelectMemberModal
          members={allMember}
          memberBookData={memberBookData}
          show={openMemberModal}
          onClose={() => setOpenMemberModal(false)}
          onSendBookRequest={() => props.onSendBookRequest()}
        />

        {/* {!onLogin && onSelectService && (
          <QrcodeModal
            onClose={handleCloseQrCodeModal}
            salon={salon}
            onBack={handleCloseQrCodeModal}
          />
        )} */}

        {!onLogin && onSelectService && (
          <AddPromotion show={openModal} onClose={() => setOpenModal(false)} />
        )}

        {!onLogin && onSelectService && (
          <ServiceSelector
            salon={salon}
            onSelect={handleSelectService}
            onBack={handleCloseServiceSelector}
            bookMarked={props.bookMarked}
            onBookmark={props.onBookmark}
          />
        )}

        {onLogin && <LoginPage />}
      </div>
    </Modal>
  );
};

export default BookNowModal;
