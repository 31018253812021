export type TransactionType =
  | "deposit"
  | "depositTips"
  | "latrice_reward"
  | "refund"
  | "withdrawal"
  | "project"
  | "appointment"
  | "custom_appointment"
  | "refund_appointment"
  | "refund_custom_appointment";

export const POSITIVE_TRANSACTION_TYPES: TransactionType[] = [
  "deposit",
  "depositTips",
  "refund",
  "latrice_reward",
  "refund_appointment",
  "refund_custom_appointment",
];

export interface Transaction {
  _id?: any;
  account: string;
  paymentMethod: string;
  amount: number;
  type: TransactionType;
  created_at: Date;
  updated_at: Date;
}
