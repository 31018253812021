import React, { forwardRef, useEffect } from "react";
import { ISalonProfile } from "../../../pages/SeeProfessionalAccountPage";
import { SalonPortfolio } from "./SalonPortfolio";
import PulseLoader from "../../loader/PulseLoader";
import Availability from "../../../models/Availability";
import { Tooltip } from "react-tooltip";
import moment from "moment-timezone";
import { CONSTANTS } from "../../../utils/constants";
import { timeToTimezone } from "../../../utils/utils";
import { v4 as uuidv4 } from "uuid";
import BookmarkButton from "../../BookmarkButton";
import Button from "../../Button";
import { SalonPolicy } from "./SalonPolicy";
import MapSelection from "../../map/MapSelection";
import { AvailabilitySkeleton } from "../../../skeletons/AvailabilitySkeleton";

export type SalonProfileDetail = Pick<
  ISalonProfile,
  "location" | "availabilities" | "unavailabilities" | "portfolio"
>;

interface Props {
  salonProfileData: Partial<ISalonProfile>;
  actions: {
    bookmarked: boolean;
    onAddToBookmark: (e: any) => void;
    onShareProfile: (e: any) => void;
  };
}

const SalonDetail = forwardRef<HTMLButtonElement, Props>(
  ({ salonProfileData, actions }, ref) => {
    const renderAvailabilities = (
      availabilities: Availability[],
      unavailabilities: Availability[]
    ) => {
      const sortedAvailabilities = availabilities
        .slice()
        .sort((a: Availability, b: Availability) =>
          moment(a.day).diff(moment(b.day))
        );
      const sortedUnAvailabilities =
        unavailabilities !== undefined
          ? unavailabilities
              .slice()
              .sort((a: Availability, b: Availability) =>
                moment(a.day).diff(moment(b.day))
              )
          : [];

      if (sortedAvailabilities.length === 0) {
        return (
          <div className="d-flex user-select-none flex-column text-muted gap-3 align-items-center justify-content-center">
            <i className="fa fa-search text-muted fa-2x"></i>
            <div className="text-center">
              We couldn't find current week's availabilities.
              <br />
              But you can probably book services.
            </div>
          </div>
        );
      }

      const getFreeTime = (day: string) => {

        
        const unAvailabilities = sortedUnAvailabilities.filter((av) => {
          return av.day === day;
        });
        if (unAvailabilities.length === 1) return unAvailabilities[0];
        return null;
      };

  
      return (
        <div className="d-flex flex-column gap-2">
          <Tooltip id="unAvailabilityTooltip" />
          {sortedAvailabilities.map((availability, key) => {
            const unAvailability = getFreeTime(availability.day);

            return (
              <div
                key={uuidv4()}
                className="d-flex justify-content-between align-items-center"
              >
                <div
                  className="d-flex align-items-end gap-2"
                  key={availability.day}
                >
                  <div className="fw-500 text-black">
                    {CONSTANTS.WEEK_DAYS[moment(availability.day).day()]}
                  </div>
                  <div>
                    {timeToTimezone(availability.startTime)} -
                    {" " + timeToTimezone(availability.endTime)}
                  </div>
                </div>
                {unAvailability && salonProfileData && (
                  <div
                    data-tooltip-id="unAvailabilityTooltip"
                    data-tooltip-place="top"
                    data-tooltip-content={`Will be unavailable during this period.
                      `}
                    className="unAvailabilityBadge d-flex gap-2 align-items-center border p-1"
                  >
                    <div className="unAvailabilityBadge__dot"></div>
                    <div className="unAvailabilityBadge__interval t ext-muted">
                      {timeToTimezone(unAvailability.startTime)} -
                      {" " + timeToTimezone(unAvailability.endTime)}
                    </div>
                    <i className="fa fa-lock" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <div className="d-flex flex-column gap-3">
        <div className="d-flex justify-content-end gap-2">
          <BookmarkButton
            ref={ref}
            onClick={(e: any) => actions.onAddToBookmark(e)}
            marked={actions.bookmarked}
          />
          <Button
            onClick={actions.onShareProfile}
            style={{ height: "38px" }}
            className="border-0 shadow shareButton"
            mode="default"
            type={"button"}
            content={
              <div className="text-black mx-2">
                <i className="fa-solid fa-share"></i> SHARE
              </div>
            }
          />
        </div>

        <div className="d-flex flex-column bg-light br-6 p-2 pb-4 shadow">
          <SalonPortfolio portfolio={salonProfileData.portfolio ?? null} />

          <h6 className="text-black">Service Area</h6>
          <div
            className="mt-2 d-flex justify-content-center align-items-center"
            style={{
              height: "200px",
              minWidth: "100%",
            }}
          >
            {salonProfileData !== null ? (
              <>
                <MapSelection
                  defaultLocation={{
                    center: {
                      lng: salonProfileData.location?.coordinates[0] as number,
                      lat: salonProfileData.location?.coordinates[1] as number,
                    },
                    zoom: 11,
                  }}
                  defaultAddress={salonProfileData.address as string}
                  containerStyle={{ height: "200px", width: "400px" }}
                  radius={String(salonProfileData.service_radius ?? 1)}
                  onLocationSelect={() => {}}
                  showRadius={false}
                  showSearch={false}
                />
              </>
            ) : (
              <div>
                <PulseLoader />
              </div>
            )}
          </div>

          <h6 className="text-black mt-2">Business Hours</h6>
          <div className="d-flex flex-column">
            {salonProfileData?.availabilities &&
              renderAvailabilities(
                salonProfileData.availabilities as any,
                salonProfileData.unavailabilities as any
              )}
          </div>

          {salonProfileData === null && (
            <div className="d-flex flex-column justify-content-start gap-2">
              <AvailabilitySkeleton />
            </div>
          )}
        </div>

        <SalonPolicy />
      </div>
    );
  }
);

export default SalonDetail;
