import MainNav from "../components/navigation/MainNav";
import "./../styles/BusinessProfilePage.css";
import { useCallback, useEffect, useRef, useState } from "react";
import Button from "../components/Button";
import { useDispatch } from "react-redux";
import { updateSalon } from "../redux/slices/authSlice";
import { AnyAction } from "redux";
import AuthLayout from "../components/auth/AuthLayout";
import { log } from "../utils/utils";
import {
  businessInfoInitialValues,
  businessValidationSchema,
} from "../components/validation/InfoPageValidation";
import { useFormik } from "formik";
import { useUser } from "../hooks/UseUser";
import toast from "react-hot-toast";
import { isUserProfessional } from "../models/User";
import MapSelection from "../components/map/MapSelection";
import { useSalon } from "../context/SalonContext";
import { ISalon } from "../models/SalonX";
import { isEqual } from "lodash";
import {
  useNavigate,
  unstable_useBlocker as useBlocker,
  unstable_BlockerFunction as BlockerFunction,
} from "react-router-dom";
import UnsaveData from "../components/modal/UnsavaData";

const BusinessInfoPage = () => {
  const defaultPosition = {
    center: {
      lat: 7.369722,
      lng: 12.35472,
    },
    zoom: 11,
  };
  const [areaRadius, setAreaRadius] = useState<string | null>("1");
  const [address, setAddress] = useState<string | null>(null);
  const [position, setPosition] = useState<typeof defaultPosition | null>(null);
  const [show, setShow] = useState(false);

  const [hasChange, setHasChange] = useState(false);

  const user = useUser();
  const { salon, setSalon } = useSalon();

  const [numberShown, setNumberShown] =
    useState<ISalon["number_shown"]>("salon_number");

  const dispatch = useDispatch();

  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const submitIsChangeBtnRef = useRef<HTMLButtonElement>(null);


  useEffect(() => {
    if (submitBtnRef.current) {
      submitBtnRef.current.setAttribute("disabled", "true");
    }
  }, []);

  const formik = useFormik<Partial<ISalon>>({
    initialValues: businessInfoInitialValues,
    validationSchema: businessValidationSchema,
    onSubmit: (values) => {
      log(JSON.stringify(values, null, 2));
    },
  });

  const defaultLocation =
    salon !== null
      ? {
          center: {
            lat: salon.location.coordinates[0],
            lng: salon.location.coordinates[1],
          },
          zoom: 9,
        }
      : null;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (Object.keys(formik.errors).length === 0) {
      if (submitBtnRef.current) {
        const formData = new FormData();

        const keysToInclude: Array<keyof ISalon> = [
          "bio",
          "name",
          "phone_number",
          "personal_number",
          "number_shown",
        ];

        keysToInclude.forEach((key) => {
          const value = formik.values[key];
          if (value) {
            formData.append(key, value as string);
          }
        });

        if (address) {
          formData.set("address", address);
          if (position?.center.lat) {
            formData.set("lat", position?.center.lat as unknown as string);
            formData.set("lng", position?.center.lng as unknown as string);
          }
          formData.set("service_radius", areaRadius as string);
        }

        submitBtnRef.current.setAttribute("disabled", "true");
        submitBtnRef.current.innerHTML = "loading...";

        dispatch(updateSalon(formData) as unknown as AnyAction)
          .unwrap()
          .then(() => {
            toast.success("Business info has been updated !");
          })
          .catch((error: any) => {
            toast.error("Something went wrong !");
          })
          .finally(() => {
            if (submitBtnRef.current) {
              submitBtnRef.current.removeAttribute("disabled");
              submitBtnRef.current.innerHTML = `<span class="mx-3">Save</span>`;
            }
          });
      }
    }
  };

  useEffect(() => {
    if (salon) {
      console.log("valeur du salon", salon);
      formik.setValues(salon);
      setNumberShown(salon.number_shown);
    }
  }, [salon]);

  // if you are unsave data and you went to change your location pathname
  const isDeepEqual = isEqual(formik.values, salon);
  const shouldBlocker = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      !isDeepEqual && currentLocation.pathname !== nextLocation.pathname,
    [!isDeepEqual]
  );
  const blocker = useBlocker(shouldBlocker);

  const handleDiscardChanges = () => {
    blocker.state === "blocked" && blocker.reset();
    setShow(show);
  };

  const handleOnClosed = () => {
    blocker.state === "blocked" && blocker.reset();
    setShow(show);
  };

  useEffect(() => {
    if (salon) {
      console.log("The difference", formik.values, salon);

      console.log("Did i make change ?", !isDeepEqual);
      setHasChange(!isDeepEqual);
    }
  }, [formik.values]);

  useEffect(() => {
    if (!isDeepEqual) {
      setShow(!show);
    }
  }, [isDeepEqual]);

  return (
    <AuthLayout>
      <MainNav navType={"connectedNav"} isSearch={false} />
      <div className="row p-0 m-0 BusinessProfilePage">
        <div className="row p-0 m-0 BusinessProfilePage">
          <div className="container p-lg-0">
            <form className="w-100">
              <div className="d-flex my-4 gap-5 justify-content-center justify-content-md-center business_info">
                <div className="d-flex flex-column ProfileLeftSide justify-content-start">
                  <div className="mb-3">
                    <label htmlFor="" className="form-label text-black fw-500">
                      Business Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.name ?? ""}
                      className="form-control w-100"
                      placeholder="Salon name"
                      aria-describedby="helpId"
                    />
                  </div>
                  {formik.touched.name && formik.errors.name ? (
                    <div style={{ color: "var(--primaryColor)" }}>
                      {formik.errors.name}
                    </div>
                  ) : null}
                  <div className="mb-3">
                    <label htmlFor="" className="form-label text-black fw-500">
                      Tell us a bit more about your salon
                    </label>
                    <textarea
                      placeholder="A bit more about your salon..."
                      style={{ minWidth: "350px" }}
                      className="form-control"
                      name="bio"
                      id="bio"
                      rows={9}
                      maxLength={350}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values?.bio ?? ""}
                    ></textarea>
                    <p className="text-black m-0 small-text text-end my-1">
                      {formik.values.bio?.length} / 350 characters
                    </p>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label text-black fw-500">
                      Business Phone Number{" "}
                      <i
                        data-tip="some info"
                        className="fa text-primary fa-circle-info"
                      ></i>
                    </label>
                    <input
                      type="tel"
                      name="phone_number"
                      id="phone_number"
                      className="form-control w-100"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone_number ?? ""}
                      placeholder="phone number"
                      aria-describedby="helpId"
                    />
                  </div>
                  {formik.touched.phone_number && formik.errors.phone_number ? (
                    <div style={{ color: "var(--primaryColor)" }}>
                      {formik.errors.phone_number}
                    </div>
                  ) : null}
                  <div className="mb-3">
                    <label htmlFor="" className="form-label text-black fw-500">
                      Personal Phone Number{" "}
                      <i
                        data-tip="some info"
                        className="fa text-primary fa-circle-info"
                      ></i>
                    </label>
                    <input
                      type="tel"
                      name="personal_number"
                      id="personal_number"
                      className="form-control w-100"
                      placeholder="personal number"
                      aria-describedby="helpId"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.personal_number ?? ""}
                    />
                  </div>

                  {formik.touched.personal_number &&
                  formik.errors.personal_number ? (
                    <div style={{ color: "var(--primaryColor)" }}>
                      {formik.errors.personal_number}
                    </div>
                  ) : null}

                  <div className="mb-3">
                    <label htmlFor="" className="form-label text-black fw-500">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      disabled
                      className="form-control w-100"
                      value={user?.email ?? ""}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label text-black fw-500">
                      Number Shown on Profile{" "}
                      <i
                        data-tip="some info"
                        className="fa text-primary fa-circle-info"
                      ></i>
                    </label>
                    <div className="d-flex justify-content-between">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={numberShown === "salon_number"}
                          name="number_shown"
                          id="phone_number"
                          onClick={(e: any) => setNumberShown("salon_number")}
                          value={"phone_number"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="number_shown"
                        >
                          Salon Number
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={numberShown === "personal_number"}
                          name="number_shown"
                          id="personal_number"
                          onClick={(e: any) =>
                            setNumberShown("personal_number")
                          }
                          value={"personal_number"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="personnalPhoneNumber"
                        >
                          Personal Phone Number
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="" className="form-label text-black fw-500">
                      Account Type
                    </label>
                    <input
                      className="form-control w-100"
                      name="account_type"
                      id="account_type"
                      value={
                        isUserProfessional(user)
                          ? (user.professional.account_type as string)
                          : ""
                      }
                      disabled
                    />
                  </div>
                </div>
                {salon && (
                  <div className="d-flex gap-3 profileRight_container">
                    <div className="map_container d-flex flex-column w-100 ProfileRightSide B_info justify-content-start mt-lg-0">
                      <div className="mb-3 searchbar w-100">
                        <label
                          htmlFor="business_location"
                          className="form-label text-black fw-500 mt-2"
                        >
                          Business Location{" "}
                          <small className="text-muted">
                            ({salon.address ?? "No location provided"})
                          </small>
                        </label>
                        <MapSelection
                          {...(defaultLocation
                            ? {
                                defaultLocation: {
                                  center: {
                                    lng: salon.location.coordinates[0],
                                    lat: salon.location.coordinates[1],
                                  },
                                  zoom: 11,
                                },
                                defaultAddress: salon.address,
                              }
                            : {})}
                          containerStyle={{ height: "50vh" }}
                          onLocationSelect={(latLng, address) => {
                            setAddress(address);
                            setPosition((pos: any) => {
                              return { ...pos, center: latLng };
                            });
                          }}
                          radius={String(salon.service_radius ?? 1)}
                          onRadiusChange={setAreaRadius}
                        />
                      </div>
                      <div className="d-flex justify-content-end gap-2 mt-0">
                        {/* <Button
                          mode="outlined"
                          content={<span className="mx-2">Cancel</span>}
                        /> */}
                        <Button
                          type="submit"
                          ref={submitBtnRef}
                          onClick={handleSubmit}
                          disabled={!hasChange}
                          content={<span className="mx-3">Save</span>}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
        {blocker.state === "blocked" && !show && (
          <UnsaveData
            onSave={handleSubmit}
            message="Do you really want to exit without saving your changes?"
            onCancel={handleDiscardChanges}
            open={!show}
            onClose={handleOnClosed}
            submitIsChangeBtnRef={submitIsChangeBtnRef}
          />
        )}
      </div>
    </AuthLayout>
  );
};

export default BusinessInfoPage;
