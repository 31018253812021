import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Nuggest from "../components/Nugget";
import SearchBar, {
  SearchValuesOptions,
} from "../components/searchBar/SearchBar";
import "./../styles/HomePage.css";
import { ProfessionalService } from "../services/ProfessionalService";
import MainNav from "../components/navigation/MainNav";
import socket from "../socket/socket";
import { EVENT_CODE } from "../socket/event";
import { CONSTANTS } from "../utils/constants";
import { v4 as uuidv4 } from "uuid";
import { INugget, ISearchData } from "../utils/types";
import { ResultContainer } from "../components/page/home/search/ResultContainer";
import useGeolocation from "../hooks/useGeolocation";
import { SalonService } from "../services/SalonService";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { sq } from "date-fns/locale";
import { useUser } from "../hooks/UseUser";
import { geocodeByLatLng } from "react-google-places-autocomplete";
import toast from "react-hot-toast";
import { number } from "yup";
import { DiscountSeason } from "../components/promotion/DiscountSeason";
import { useDispatch, useSelector } from "react-redux";
import {
  ServiceNameState,
  getServiceNames,
} from "../redux/slices/serviceNameSlice";
import { AnyAction } from "@reduxjs/toolkit";
import {
  ServiceState,
  getServices,
  getUserServices,
} from "../redux/slices/serviceSlice";
import ModalWelcome from "../components/promotion/ModalWelcome";

export const Location = {
  center: {
    lat: 7.369722,
    lng: 12.35472,
  },
  zoom: 9,
};

type ILocationType = {
  center: [number, number];
};

const HomePage = () => {
  const dispatch = useDispatch();

  const serviceNameData = useSelector<unknown, ServiceNameState>(
    (state: any) => state.serviceNames
  ).serviceNames;

  const services = useSelector<unknown, ServiceState>(
    (state: any) => state.services
  ).services;

  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [askedAvailability, setAskedAvailability] = useState<string[]>([]);
  const [areaRadius, setAreaRadius] = useState<string | null>(null);
  const [markerPosition, setMarkerPosition] = useState<any>(null);
  const [markerCircle, setMarkerCircle] = useState<any>(null);
  const [markerInfo, setMarkerInfo] = useState<any>(null);
  const [map, setMap] = useState<any>(null);
  const [maps, setMaps] = useState<any>(null);
  const [position, setPosition] = useState<typeof Location | null>(null);
  const [professionalsData, setProfessionalsData] = useState<ISearchData>({
    size: 8,
    page: 1,
    totalPages: null,
    results: null,
  });
  // const [makeReview, setMakeReview] = useState(false);

  const [nugget, setNugget] = useState<INugget>({
    size: 3,
    page: 1,
    latitude: 7.369722,
    longitude: 12.35472,
    totalPages: null,
    results: null,
  });

  const recorsPage = nugget.size;
  const nuggets = nugget.results?.slice();
  const user = useUser();
  let userPosLon = user?.location.coordinates[0];
  let userPosLat = user?.location.coordinates[1];
  let resultPos: ILocationType;
  const userAddress = user?.address ?? "new york";

  const updateMapLoadedElements = (map: any, maps: any, center: any) => {
    if (markerPosition) {
      markerPosition.setPosition(center);
    }
    if (markerCircle) {
      markerCircle.setRadius(Number(areaRadius) * 16009.34);
      markerCircle.setCenter(center);
    }
  };

  const handleFilterByLocation = (gMap: any, gMaps: any) => {
    if (!map) setMap(gMap);
    if (!maps) setMaps(gMaps);

    if (user) {
      let latLng = {
        lag: user.location.coordinates[0],
        lng: user.location.coordinates[1],
      };
      setPosition((pos: any) => {
        return { ...pos, center: latLng, zoom: 9 };
      });
    }

    if (position && gMap && gMaps) {
      const center = { lat: userPosLat, lng: userPosLon };

      if (markerPosition) {
        return updateMapLoadedElements(gMap, gMaps, center);
      }

      const infoWindow = new gMaps.InfoWindow({
        content: "Your position",
        ariaLabel: "Position",
        disableAutoPan: true,
      });

      setMarkerInfo(infoWindow);

      let marker = new gMaps.Marker({
        position: center,
        map: gMap,
        title: "Your position",
      });

      infoWindow.open({
        anchor: marker,
        map: gMap,
      });

      marker.addListener("click", () => {
        infoWindow.open({
          anchor: marker,
          map: gMap,
        });
      });

      setMarkerPosition(marker);

      if (markerPosition) {
        markerPosition.setPosition({
          lat: position.center.lat,
          lng: position.center.lng,
        });
      }

      const cityCircle = new gMaps.Circle({
        // strokeColor: "#e10984",
        // strokeOpacity: 0.8,
        // strokeWeight: 2,
        // fillColor: "#e10984",
        // fillOpacity: 0.35,
        map: gMap,
        center: center,
        radius: Number(areaRadius),
        // editable: true,
      });

      setMarkerCircle(cityCircle);

      gMap.panTo(marker.getPosition());

      const circleBounds = cityCircle.getBounds();

      gMap.fitBounds(circleBounds);
      gMap.setZoom(13);
    }
  };

  const handleSearch = (value: SearchValuesOptions) => {
    const filteredSearchString = Object.entries(value).reduce(
      (acc: any, [key, val]: any) => {
        if (val !== null && val !== "null" && val !== undefined) {
          acc[key] = val;
        }
        return acc;
      },
      {}
    );
    const searchString = new URLSearchParams(filteredSearchString).toString();
    window.location.href = `/search${
      searchString.length === 0 ? "" : "?" + searchString
    }`;
  };

  const getNuggetData = () => {
    setIsDataLoading(true);
    SalonService.getAllNuggets()
      .then((res) => {
        if (nugget.results) {
          const results = [...nugget.results.slice(), ...res.results];
          setNugget({
            ...nugget,
            results,
            page: nugget.page,
            totalPages: nugget.totalPages,
          });
        } else {
          setNugget({
            ...nugget,
            results: res.results,
            page: nugget.page + 1,
            totalPages: nugget.totalPages,
          });
        }
        // if (user && nugget.results) {
        //   const results = [...nugget.results.slice(), ...res.results];
        //   setNugget({
        //     ...nugget,
        //     results,
        //     latitude: user.location.coordinates[0],
        //     longitude: user.location.coordinates[1],
        //     page: nugget.page,
        //     totalPages: nugget.totalPages,
        //   });
        // }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
        setIsDataLoading(false);
      });
  };

  const handlePickPosition = (userPos = userAddress) => {
    //variables
    // const userPos: string = userAddress;
    let tableElement: string[] = [];
    let tableResult = userPos.split("");
    let target = ",";
    let resutlPos: string = "";

    //search and extract spaces in sentences locations

    while (tableResult.length > 1) {
      for (let i in tableResult) {
        if (confirmEnding(tableResult[i], target)) {
          //tableResult.shift();
          tableResult[i].substring(0, tableResult[i].indexOf(target));
        }
      }
      tableElement = tableResult;
    }

    resutlPos = tableElement.join(" ");

    return resutlPos;
  };

  const confirmEnding = (el: string, target: string) => {
    return el.endsWith(target);
  };

  const salons =
    nuggets &&
    nuggets.filter((salon) => {
      if (salon.reviewsStats.averageStars >= 3) {
        return salon;
      }
    });

  const salonUser =
    nuggets &&
    nuggets.filter((salon) => {
      if (salon.reviewsStats.averageStars >= 3) {
        return salon;
      }
    });

  // const userSalon =
  //   nuggets &&
  //   nuggets.filter((salon) => {
  //     if (position) {
  //       setPosition(position);

  //       let lat = position.center.lat;
  //       let long = position.center.lng;

  //       resultPos.center = [lat, long];
  //       if (salon.location.coordinates[0]) {
  //         console.log("OMG", salon);
  //         return salon;
  //       }
  //     }
  //   });

  // const salonFindByLocation =  nuggets &&
  // nuggets.filter((salon) => {
  //   if (salon.location.coordinates.includes()) {
  //     return salon;
  //   }
  // });

  // useEffect(() => {
  //   if (nuggets && nuggets.length > 0) {
  //     nuggets.filter((salon) => {
  //       // const location = handlePickPosition(salon.address) ?? "helle world";
  //       //console.log(location);

  //       if (salon.reviewsStats.averageStars < 4) {
  //         //  handlePickPosition(userAddress);
  //         // console.log(handlePickPosition(userAddress));
  //         return salon;
  //       }
  //     });
  //   }
  // });

  const getProfessionalsData = () => {
    setIsDataLoading(true);
    ProfessionalService.getProfessionals(
      professionalsData.page,
      professionalsData.size
    )
      .then((res) => {
        if (professionalsData.results) {
          const results = [
            ...professionalsData.results.slice(),
            ...res.results,
          ];
          setProfessionalsData({
            ...professionalsData,
            results,
            page: professionalsData.page + 1,
            totalPages: res.totalPages,
          });
        } else {
          setProfessionalsData({
            ...professionalsData,
            results: res.results,
            page: professionalsData.page + 1,
            totalPages: res.totalPages,
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
        setIsDataLoading(false);
      });
  };

  const handleLoadProfessionals = () => {
    getProfessionalsData();
  };

  useEffect(() => {
    getProfessionalsData();
  }, []);

  useEffect(() => {
    getNuggetData();
  }, []);

  useEffect(() => {
    if (position) {
      setPosition(position);
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          let lat = pos.coords.latitude;
          let long = pos.coords.longitude;

          let currentPosition = {
            center: {
              lat: lat,
              lng: long,
            },
            zoom: 30,
          };
          setPosition(currentPosition);

          console.log("Mai", currentPosition);
        },
        function (error) {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.log("The request to get user location timed out.");
              break;
            default:
              console.log("An unknown error occurred.");
              break;
          }
          setPosition(Location);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      toast.error("Votre navigateur ne supporte pas la géolocalisation.");
    }
  }, []);

  useEffect(() => {
    handleFilterByLocation(map, maps);
    console.log("Hello world", handleFilterByLocation(map, maps));
  }, [position]);

  useEffect(() => {
    socket().on(EVENT_CODE.SET_PROFESSIONAL_AVAILABILITY_STATE, (...args) => {
      // const { available, professionalId } = args[0];
      // setProfessionalsData((data: ISearchData) => {
      //   let newProfessionals: ISearchData["results"] | null = null;
      //   if (data.results) {
      //     newProfessionals = data.results
      //       .slice()
      //       .map((salon: Pick<ISalon, 'name' | 'profile' | 'cover'|'_id'>) => {
      //         if (salon._id === professionalId) {
      //           return {
      //             ...professional,
      //             available: available,
      //           };
      //         }
      //         return professional;
      //       });
      //   }
      //   return { ...data, professionals: newProfessionals };
      // });
    });

    return () => {
      socket().disconnect();
    };
  }, []);

  // useEffect(() => {
  //   socket().on(EVENT_CODE.MAKE_REVIEW, (...args) => {
  //     console.log(
  //       "here is the args after emitting the make review on backend",
  //       args
  //     );

  //           setMakeReview(true);

  //   });

  //   return () => {
  //     socket().disconnect();
  //   };
  // }, []);

  // useEffect(() => {
  //   if (professionalsData.results) {
  //     professionalsData.res.forEach((professional) => {
  //       if (askedAvailability.includes(professional._id)) return;
  //       socket().emit(EVENT_CODE.ASK_FOR_AVAILABILITY, professional._id);
  //       const ids = askedAvailability.slice();
  //       ids.push(professional._id);
  //       setAskedAvailability(ids);
  //     });
  //   }
  // }, [askedAvailability, professionalsData.professionals]);

  const geo = useGeolocation();

  useEffect(() => {
    geo.handlePermission();
  }, []);

  useEffect(() => {
    if (!serviceNameData) {
      dispatch(getServiceNames("") as unknown as AnyAction);
    }
    if (!services) {
      dispatch(getServices("") as unknown as AnyAction);
    }
    dispatch(getUserServices("") as unknown as AnyAction);
  }, []);

  const calculateDistance = (lat1: any, lon1: any, lat2: any, lon2: any) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  };

  const salonsData =
    nuggets &&
    nuggets
      .filter((salon) => salon.reviewsStats.averageStars >= 3)
      .map((salon) => ({
        ...salon,
        distance: calculateDistance(
          userPosLat,
          userPosLon,
          salon.location.coordinates[1],
          salon.location.coordinates[0]
        ),
      }))
      .sort((a, b) => {
        if (b.reviewsStats.averageStars === a.reviewsStats.averageStars) {
          return a.distance - b.distance;
        }
        return b.reviewsStats.averageStars - a.reviewsStats.averageStars;
      });

  useEffect(() => {
    if (user?.coupon && user.coupon.actived && user.coupon.expiration_date) {
      if (user.coupon.expiration_date > new Date()) {
        setShow(true);
      }
      if (user.coupon.expiration_date === new Date()) {
        setShow(false);
      }
    }
  }, [user?.coupon]);

  return (
    <>
      {user?.coupon && show ? (
        <DiscountSeason
          open={show}
          onClose={() => setShow(false)}
          targetDate={user.coupon.expiration_date as Date}
        />
      ) : null}
      <MainNav navType="homeNav" />
      <div className="HomePageHeader d-flex justify-content-center">
        <div className="HomePageHeader__container d-flex flex-column justify-content-center align-items-center position-absolute">
          <h1 className="text-uppercase fs-1 text-center text-light fw-bold">
            find the best beauty professional ?
          </h1>
          <div className="d-flex flex-column">
            <SearchBar
              onSearch={handleSearch}
              onLoadEnd={() => 0}
              onLoadStart={() => 0}
              location="homePage"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <ResultContainer
          loading={loading}
          isDataLoading={isDataLoading}
          results={professionalsData.results}
          canLoadMore={
            professionalsData.totalPages !== null &&
            professionalsData.page > professionalsData.totalPages
          }
          onLoadMore={handleLoadProfessionals}
        />

        <div className="d-flex flex-column flex-lg-row mt-5 w-100">
          <div className="col-12 col-lg-6 p-3 mt-5">
            <img className="w-75" src="../assets/barber.png" alt="" />
          </div>
          <div className="d-flex flex-column gap-3  col-12 col-lg-6 mt-5">
            <h4 className="fw-bold text-black">
              A social network that connects expertise and customers
            </h4>
            <span className="fw-bold">Our commitment</span>
            <p>
              Latrice is a social network of beauty professionnals whose mission
              is to connect people who want to have a beauty service to
              professionals in the businesses, we found that people needing
              beauty services have difficulty finding the right professional to
              their problem, but especially in time. We provide a catalog of
              beauty professionals in all United States that are waiting for you
              to make an appointment and make the service within in time.
            </p>

            <span className="fw-bold">The guarantee at the customer's</span>
            <p>
              We guarantee transparency and reliable professionals previously
              selected by us, and a follow-up of the execution of the task at
              any time the customer can send his remarks or other information
              that will improve the customer experience with our providers.
            </p>

            <span className="fw-bold">The guarantee at the provider's</span>
            <p>
              We check the offers that are published that will guarantee your
              passive or time-based income on our platform. We remain available
              for any concerns to give more details about the operation of our
              platform.
            </p>
          </div>
        </div>

        <div className="d-flex flex-column flex flex-lg-row flex-lg-row-reverse mt-5 w-100">
          <div className="col-12 col-lg-6 p-3 mt-5 d-flex justify-content-end">
            <img alt="" className="w-75 ms-auto" src="../assets/barber2.png" />
          </div>
          <div className="d-flex flex-column gap-3  col-12 col-lg-6 mt-5">
            <h4 className="fw-bold text-black">
              A social network that connects expertise and customers
            </h4>

            <div className="d-flex align-items-center gap-2">
              <i
                style={{ color: "var(--yellowColor)" }}
                className="fa-solid fa-rectangle-list fa-2x"
              ></i>
              <div className="d-flex justify-content-between flex-column gap-1">
                <span className="fw-bold">Post a job</span>
                <p>
                  It's easy and free to post a job. Simply fill in a title, your
                  location, a brief description and a budget and the competitive
                  offers arrive in a few minutes.
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2">
              <i
                style={{ color: "var(--yellowColor)" }}
                className="fa-solid fa-user-check fa-2x"
              ></i>
              <div className="d-flex justify-content-between flex-column gap-1">
                <span className="fw-bold">Choose the hairdresser</span>
                <p>
                  No job is too big or to small.We have freelance hairdresser
                  for jobs of any size or any budget, throughout the United
                  States.
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center gap-2">
              <i
                style={{ color: "var(--yellowColor)" }}
                className="fa-solid fa-dollar-sign fa-2x"
              ></i>
              <div className="d-flex justify-content-between flex-column gap-1 ms-3">
                <span className="fw-bold">Pay Safely</span>
                <p>
                  Pay for the work only when it's completed and you are 100%
                  satisfied with the quality.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="SpecialistByCity bg-white br-6 shadow p-3 d-flex flex-column justify-content-center align-items-center">
          <h4 className="fw-bold text-black my-3 mb-4">
            Find your Beauty specialist by city
          </h4>
          <div className="SpecialistByCityList align-items-center justify-content-center gap-3 w-75 mb-4">
            {CONSTANTS.CITIES.map((city, id: number) => {
              return (
                <div key={uuidv4()} className="d-flex align-items-center gap-1">
                  <i className="fa fa-angle-right text-primary"></i>
                  <span> {city}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
