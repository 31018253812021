import React from "react";
import { Modal } from "react-bootstrap";

type ConfirmDeleteUserModalProps = {
  show: boolean;
  onClose: () => void;
  handleLogout: () => void;
};

const ConfirmDeleteUserModal: React.FC<ConfirmDeleteUserModalProps> = ({
  show,
  onClose,
  handleLogout,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      centered
      size="lg"
      onHide={handleClose}
    >
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-1 p-3 justify-content-center align-items-center">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: "100%" }}
          >
            <img
              src={require("../../assets/images/delete.png")}
              alt=""
              style={{ width: "100px" }}
            />
          </div>
          <div className="d-flex flex-column gap-2 p-3 justify-content-center align-items-center">
            <h4 className="fw-500  " style={{ color: "#0f2359" }}>
              Are you sure ?
            </h4>
            <p className=" text-center " style={{ color: "" }}>
              This action is irreversible and your account will be <br /> permanently
              deleted.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div
          className="d-flex flex-row gap-3 p-3 justify-content-center  align-items-center "
          style={{ width: "100%" }}
        >
          <button
            className=""
            style={{
              padding: "10px",
              paddingLeft: "30px",
              paddingRight: "30px",
              borderRadius: "5px",
              border: "none",
              color: "#5961ea",
              backgroundColor: "#e1e2fc",
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              padding: "10px",
              paddingLeft: "30px",
              paddingRight: "30px",
              borderRadius: "5px",
              border: "none",
              color: "red",
              backgroundColor: "#ffe9e8",
            }}
            onClick={handleLogout}
          >
            delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteUserModal;
