import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import "./../styles/SlidePicker.css";

type SlidePickerDeletableProps<T, K> = (
  | {
      deletable?: false;
      renderDeletableItem?: (item: T) => React.ReactNode;
    }
  | {
      deletable: true;
      renderDeletableItem: (item: T) => React.ReactNode;
    }
) & { onDeleteItem?: (item: T & { key: K }) => void };

type SlidePickerProps<T> = {
  data: T[];
  renderItem: (item: T) => React.ReactNode;
  keyExtractor: (item: T) => string;
  separator: React.ReactNode;
  maxShow?: number;
  selected?: T;
  onSelectItem?: (item: T) => void;
} & SlidePickerDeletableProps<T, number>;

const SlidePicker = <T extends object>({
  data,
  renderItem,
  keyExtractor,
  separator,
  selected,
  maxShow = 7,
  onSelectItem,
  ...props
}: SlidePickerProps<T>) => {
  const pickerContainerRef = useRef<HTMLDivElement>(null);
  const [selectedItem, setSelectedItem] = useState<T | null>(selected as T);
  const [activeItem, setActiveItem] = useState<T | null>(selectedItem);
  const [scrollAmount, setScrollAmount] = useState(200);

  const handleItemClick = (
    e: React.MouseEvent<HTMLDivElement> | null,
    item: T
  ) => {
    setActiveItem(item);
    if (onSelectItem) onSelectItem(item);
  };

  const handleDeleteItemClick = (
    e: React.MouseEvent<HTMLDivElement>,
    item: T,
    key: number
  ) => {
    if (props.onDeleteItem) {
      props.onDeleteItem({ ...item, key });
    }
  };

  const handleScrollLeft = () => {
    scrollPicker(-scrollAmount);
  };

  const handleScrollRight = () => {
    scrollPicker(scrollAmount);
  };

  const scrollPicker = useCallback(
    (amount: number) => {
      const containerWidth = pickerContainerRef.current?.clientWidth ?? 0;
      const scrollLeft = pickerContainerRef.current?.scrollLeft ?? 0;

      const nextScrollLeft = Math.min(
        scrollLeft + amount,
        containerWidth * (data.length - maxShow)
      );
      pickerContainerRef.current?.scrollTo({
        left: nextScrollLeft,
        behavior: "smooth",
      });
    },
    [data.length, maxShow]
  );

  const getItemSize = () => {
    const child = pickerContainerRef.current?.firstChild as HTMLElement;
    if (child)
      return {
        width: child.offsetWidth + 20,
        height: child.offsetHeight,
      };
    return { width: 0, height: 0 };
  };

  const renderPickerItems = (items: T[]) => {
    return items.map((item, index) => (
      <>
        {index !== 0 && index !== data.length && separator}
        <div
          id={`PickerItem-${data.length}-${keyExtractor(item)}`}
          style={{ minWidth: `${100 / maxShow}% !important` }}
          className={`PickerItem my-2 position-relative ${
            activeItem && keyExtractor(item) === keyExtractor(activeItem)
              ? " active"
              : ""
          }`}
          key={keyExtractor(item)}
        >
          {props.deletable && (
            <div
              style={{
                left: "85%",
                top: "-5%",
                zIndex: "10",
                // backgroundColor: "blue",
              }}
              onClick={(e) => handleDeleteItemClick(e, item, index)}
              className="position-absolute d-flex justify-content-center align-items-center"
            >
              {props.renderDeletableItem(item)}
            </div>
          )}
          <div onClick={(e) => handleItemClick(e, item)}>
            {renderItem(item)}
          </div>
        </div>
      </>
    ));
  };

  useEffect(() => {
    const itemSize = getItemSize().width;
    if (itemSize > 0) {
      setScrollAmount(itemSize * maxShow);
    }
  }, [maxShow, pickerContainerRef.current]);

  // Scroll left when data changes
  useEffect(() => {
    if (pickerContainerRef.current) {
      // Calculate the total width of items to be shown
      const totalWidth = data.length * (scrollAmount / maxShow);
      // Calculate the remaining width to be scrolled
      const remainingWidth =
        totalWidth - pickerContainerRef.current.clientWidth;
      // Scroll the container to the left
      scrollPicker(-remainingWidth);
    }
  }, [data, maxShow, scrollAmount, scrollPicker]);

  useEffect(() => {
    setTimeout(() => {
      if (selectedItem) {
        const selectedItemId = `PickerItem-${data.length}-${keyExtractor(
          selectedItem
        )}`;
        const selectedItemElement = document.getElementById(selectedItemId);

        if (selectedItemElement) {
          selectedItemElement.click();
        }
      }
    }, 500);
  }, []);

  // Add mousewheel event listener
  useEffect(() => {
    const container = pickerContainerRef.current;
    if (container) {
      const handleMouseWheel = (e: WheelEvent) => {
        e.preventDefault();
        const scrollAmount = e.deltaY;
        scrollPicker(scrollAmount);
      };

      container.addEventListener("wheel", handleMouseWheel);

      return () => {
        container.removeEventListener("wheel", handleMouseWheel);
      };
    }
  }, [scrollPicker]);

  useEffect(() => {
    // Scroll to the selected item when it changes
    if (pickerContainerRef.current && selectedItem) {
      const itemSize = getItemSize().width;
      const selectedItemId = `PickerItem-${data.length}-${keyExtractor(
        selectedItem
      )}`;
      const selectedItemElement = document.getElementById(selectedItemId);

      if (selectedItemElement) {
        // Calculate the scroll position to center the selected item
        const containerWidth = pickerContainerRef.current.clientWidth;
        const itemPosition = selectedItemElement.offsetLeft;
        const scrollPosition = itemPosition - containerWidth;

        pickerContainerRef.current.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  }, [selected, data, keyExtractor, getItemSize, selectedItem]);

  return (
    <div className="SlidePicker w-100">
      <div className="d-flex justify-content-center align-items-center w-100 SlidePickerContainer">
        <div onClick={handleScrollLeft} className="Arrow ArrowLeft">
          <i className="fa-solid fa-angle-left fa-2x"></i>
        </div>

        <div
          style={
            {
              "--itemWidth": `${100 / maxShow - 50 / (maxShow * maxShow)}%`,
            } as CSSProperties
          }
          ref={pickerContainerRef}
          className="PickerContainer w-100 d-flex"
        >
          {renderPickerItems(data)}
        </div>

        <div onClick={handleScrollRight} className="Arrow ArrowRight">
          <i className="fa-solid fa-angle-right fa-2x"></i>
        </div>
      </div>
    </div>
  );
};

export default SlidePicker;
