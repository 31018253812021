import "./../styles/Profile.css";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import slugify from "slugify";
import { Stars } from "./Stars";
import CountdownTimer from "./page/home/CountdownTimer";
import { AvailableState } from "../models/Availability";

interface FavoriteProps {
  id: string;
  name: string;
  profile: string | null;
  cover: string | null;
  localization: string;
  stars: number;
  notice: number;
  available: AvailableState;
  account_type: "independent" | "salon_owner" | string;
}

const Favorite = (props: FavoriteProps) => {
  const navigate = useNavigate();
  const slug = slugify(props.name + "-" + props.id).toLocaleLowerCase();

  const renderContentRender = (params: Pick<FavoriteProps, "available">) => {
    let contentToRender: React.ReactNode | null = null;

    if (
      typeof params.available.value === "number" &&
      params.available.value > 0
    ) {
      contentToRender = (
        <div className="d-flex justify-content-center gap-3 pt-2">
          <div className="justify-content-center align-items-center">
            <div className="fw-500 small">
              <i className="fa-solid fa-gauge"></i> Coming soon
            </div>
            <div className="text-primary text-center small">
              <CountdownTimer
                initialMinutes={Math.round(params.available.value)}
              />
            </div>
          </div>
          <div className="text-muted text-primary">
            <Button
              style={{ fontSize: ".7em" }}
              mode={"primary"}
              content={<span className="mx-1">See availability</span>}
              onClick={() => {
                navigate(`/see-professional-account/${slug}`);
              }}
            />
          </div>
        </div>
      );
    } else if (
      typeof params.available.value === "boolean" &&
      !params.available.value
    ) {
      contentToRender = (
        <div className="text-muted d-flex justify-content-center text-primary pt-2">
          <Button
            style={{ fontSize: ".7em" }}
            mode={"primary"}
            content={<span className="mx-1">See availability</span>}
            onClick={() => {
              navigate(`/see-professional-account/${slug}`);
            }}
          />
        </div>
      );
    } else if (
      typeof params.available.value === "boolean" &&
      params.available.value
    ) {
      contentToRender = (
        <div className="d-flex justify-content-center gap-3 pt-2">
          <Button
            style={{ fontSize: ".7em" }}
            mode={"primary"}
            content={<span className="mx-1">Book Now</span>}
            onClick={() => {
              navigate(`/see-professional-account/${slug}`);
            }}
          />
        </div>
      );
    }

    return contentToRender;
  };

  return (
    <div
      onClick={() => {
        navigate(`/see-professional-account/${slug}`);
      }}
      className="Profile Favorite d-flex flex-column shadow"
    >
      <div className="ProfileCover d-flex flex-column align-items-center position-relative w-100">
        <div className="ProfileCoverContainer h-100 w-100">
          <img
            className={"ProfileCoverImage h-100 rounded-top"}
            src={props.cover ?? require("../assets/images/cover.jpg")}
            alt={props.name}
          />
        </div>
        <div className="profilePicture shadow position-absolute top-100 start-50 translate-middle">
          <img
            src={props.profile ?? require("../assets/images/profile.jpg")}
            alt={props.name}
          />
        </div>
      </div>
      <div className="ProfileMeta mb-3 d-flex flex-column justify-content-center text-center">
        <h6
          style={{
            color: props.account_type === "independent" ? "green" : "#a89331",
          }}
          className={` fw-500`}
        >
          {props.name}
        </h6>
        <div className="text-muted small small-text">
          <div className="Profile__location mx-4">
            <i className="fa-solid fa-location-dot"></i>{" "}
            {props.localization ?? "No location available"}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <Stars canUpdate={false} stars={props.stars} notice={props.notice} />
        </div>
      </div>

      {props.available.state !== "pending" &&
        renderContentRender({
          available: props.available,
        })}

      {props.available && (
        <div className="mt-auto">
          <div
            className={`available-state mt-3 available-state--${props.available.state} user-select-none d-flex justify-content-center align-items-center`}
          >
            <div className="available-state__container">
              {props.available.state === "pending" && (
                <span style={{ fontSize: ".8em" }} className="">
                  <div>loading...</div>
                </span>
              )}
              {props.available.state === "available" && (
                <span style={{ fontSize: ".8em" }} className="text-uppercase ">
                  <i className="fa fa-calendar-check"></i> Available
                </span>
              )}
              {props.available.state === "offline" && (
                <span style={{ fontSize: ".8em" }} className="text-uppercase">
                  <i className="fa fa-power-off"></i> offline
                </span>
              )}
              {props.available.state === "booked" && (
                <span style={{ fontSize: ".8em" }} className="text-uppercase">
                  <i className="fa fa-calendar-alt"></i> booked
                </span>
              )}
              {props.available.state === "busy" && (
                <span style={{ fontSize: ".8em" }} className="text-uppercase">
                  <i className="fa fa-exclamation-circle"></i> busy
                </span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Favorite;
